const testingEnv = {
	MONNIFY_KEY: process.env.REACT_APP_MONNIFY_KEY,
	MONNIFY_CONTRACT_CODE: process.env.REACT_APP_MONNIFY_CONTRACT_KEY,
	AXIOS_BASE_URL: process.env.REACT_APP_AXIOS_BASE_URL,
	LANDING_PAGE: process.env.REACT_APP_OVALOOP_WEBSITE,
	FREE_SUBSCRIPTION_PLAN: process.env.REACT_APP_FREE_SUBSCRIPTION_PLAN_ID,
	PREMIUM_SUBSCRIPTION_PLAN: process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PLAN_ID,
	STORE_FRONT_URL: process.env.REACT_APP_STORE_FRONT_URL,
	MAIN_APP_URL: process.env.REACT_APP_MAIN_APP_URL,
	RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
}

const debuggingEnv = {
	MONNIFY_KEY: process.env.REACT_APP_MONNIFY_KEY,
	MONNIFY_CONTRACT_CODE: process.env.REACT_APP_MONNIFY_CONTRACT_KEY,
	AXIOS_BASE_URL: process.env.REACT_APP_DEBUGGING_BASE_API,
	LANDING_PAGE: process.env.REACT_APP_OVALOOP_WEBSITE,
	FREE_SUBSCRIPTION_PLAN: process.env.REACT_APP_FREE_SUBSCRIPTION_PLAN_ID,
	PREMIUM_SUBSCRIPTION_PLAN: process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PLAN_ID,
	STORE_FRONT_URL: process.env.REACT_APP_STORE_FRONT_URL,
	MAIN_APP_URL: process.env.REACT_APP_MAIN_APP_URL,
	RECAPTCHA_SITE_KEY: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
}

export default testingEnv
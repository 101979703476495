import {useEffect, useState} from "react";
import {Outlet, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {BackgroundPattern, RegisterLayout} from "./AuthStyles";
import {Icon, QuoteCreator} from "../../components";

import {CenterContent} from "../../styles/utilStyles";
import {LogoLight} from "../../assets/images";
import {Heading} from "../../styles/textStyles";
import {QuoteIcon} from "../../assets/icons";
import {useRegisterService} from "../../hooks/useServices/authenticationHooks";
import useAllowOnlyUnauthenticatedUsers from "../../hooks/useRouteProtection/useAllowOnlyUnauthenticatedUsers";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {globalReducerActions} from "../../reducers/globalReducer";
import env from "../../utils/env";
import {useCountries} from "../../hooks/useServices/formFieldHooks";

const registrationData = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  agreeToTermsAndConditions: false,
  business_name: "",
  country: "",
  phone: "",
  otp: "",

  countryName: ""

}

const RegisterRoute = () => {
  const [searchParams] = useSearchParams()
  const { packageId, period } = useParams()
  const { globalReducer } = useGlobalContext()
  useCountries()

  useEffect(() => {
    // window.location.href = "/login"
    // return
  
    if (!!packageId && !!period) {
      globalReducer({
        type: globalReducerActions.SET_PACKAGE_ID,
        packageId,
        period: period === "month" ? "monthly" : "yearly",
        referralCode: searchParams.get("referral_code") || ""
      })
    } else {
      window.open(`${env.LANDING_PAGE}/#choose_plan`, "_self")
    }
  }, [packageId, searchParams])

  
  useAllowOnlyUnauthenticatedUsers()
  const navigate = useNavigate()
  const registerMutation = useRegisterService()
  const [registerData, setRegisterData] = useState(registrationData)

  return (
    <RegisterLayout>
      {/* FIRST COLUMN - FORM SECTION */}
      <div className="logo_div">
        <img src={LogoLight} alt="Ovaloop logo" />
      </div>
      <Outlet context={{ setRegisterData, navigate, formValues: registerData, registerMutation }} />

      {/* SECOND COLUMN - QUOTE SECTION */}
      <BackgroundPattern className="quote_section">
        <div>
          <CenterContent>
            <Icon iconImg={QuoteIcon} bgDark />
            <Heading size={1.4} spread>
              "I've always had the issue of knowing the stock level of each product, keeping up with orders and knowing when to stock up can all be challenging. That has changed since I started using Ovaloop, with just a few clicks I can view it all at a glance. I now have peace of mind when I'm at my business.
                <br/> <br/>
              I recommend Ovaloop to every SME that wants a seamless business operation."
            </Heading>
            <QuoteCreator />
          </CenterContent>
        </div>
      </BackgroundPattern>
    </RegisterLayout>
  )
}

export default RegisterRoute
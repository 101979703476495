import uuid from "react-uuid";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  deleteReportDownloadableService,
  downloadAllReportService,
  downloadPurchaseReceiptService,
  downloadReceiptService,
  downloadReportService,
  getReportDownloadableService
} from "../../../services/reportServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import reportDownload, {receiptDownload} from "../../../utils/reportDownload";
import errorHandler from "../../../utils/errorHandler";
import ExcelView from "excel-viewer"
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import queryKeys from "../queryKeys";


const useReportMutation = () => {
  const errorLogger = useErrorLogger()
  const {toast} = useGlobalContext()
  
  return useMutation({
    mutationFn: (reportQuery) => {
      return downloadReportService(reportQuery)
    },
  
    onSuccess: res => {
      if (res.type === "excel") {
        new ExcelView("#report-excel-view", res.data, {
          theme: "light",
          themeBtn: false
        })
      } else {
        toast.success(res.data, "download later")
      }
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.viewReport,
      })
    }
  })
}

const useDownloadReportMutation = () => {
  const {toast} = useGlobalContext()
  const reportName = []
  
  return useMutation({
    mutationFn: (reportQuery) => {
      const {sort_by, report_type} = reportQuery
      reportName.push(sort_by, report_type, uuid())
      return downloadReportService(reportQuery)
    },
  
    onSuccess: (res) => {
      reportDownload(res.data, reportName.join("_"))
      toast.success("Report downloading...")
    },
  
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useDownloadAllReportMutation = () => {
  const { getBusinessId, toast } = useGlobalContext()
  const businessId = getBusinessId()
  const reportName = []
  
  return useMutation({
    mutationFn: ({ reportType, fromDate, toDate }) => {
      reportName.push(reportType, uuid())
      return downloadAllReportService(businessId, reportType, fromDate, toDate)
    },
    
    onSuccess: (res) => {
      reportDownload(res, reportName.join("_"))
      toast.success("Report downloading...")
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDownloadReceiptMutation = ({ successFn }) => {
  const { getBusinessId, toast } = useGlobalContext()
  const businessId = getBusinessId()
  const reportName = []
  
  return useMutation({
    mutationFn: (groupOrderId) => {
      reportName.push("order receipt", groupOrderId)
      return downloadReceiptService(businessId, groupOrderId)
    },
    
    onSuccess: (res) => {
      successFn?.()
      receiptDownload(res, reportName.join("_"))
      toast.success("Receipt downloading...")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useDownloadPurchaseOrderReceiptMutation = ({ successFn }) => {
  const { getBusinessId, toast } = useGlobalContext()
  const businessId = getBusinessId()
  const reportName = []
  
  return useMutation({
    mutationFn: (groupOrderId) => {
      reportName.push("order receipt", groupOrderId)
      return downloadPurchaseReceiptService(businessId, groupOrderId)
    },
    
    onSuccess: (res) => {
      successFn?.()
      receiptDownload(res, reportName.join("_"))
      toast.success("Receipt downloading...")
    },
  
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useReportDownloadableService = () => {
  return useQuery({
    queryKey: [queryKeys.REPORT_DOWNLOADABLE],
    queryFn: () => getReportDownloadableService()
  })
}


export const useDeleteReportDownloadableMutation = () => {
  const queryClient = useQueryClient()
  const {toast} = useGlobalContext()
  
  return useMutation({
    mutationFn: (id) => {
      return deleteReportDownloadableService(id)
    },
    
    onSuccess: () => {
      queryClient.refetchQueries({queryKey: [queryKeys.REPORT_DOWNLOADABLE]})
        .then().catch()
      toast.success("Report downloadable deleted")
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export {useReportMutation, useDownloadReportMutation, useDownloadReceiptMutation}
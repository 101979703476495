import {useReducer} from "react";


export const productReducerActionTypes = {
	ADD_PRODUCT_TO_BULK_DELETE: "ADD_PRODUCT_TO_BULK_DELETE",
	CLEAR_PRODUCT_FROM_BULK_DELETE: "CLEAR_PRODUCT_FROM_BULK_DELETE",
	ADD_PRODUCT_TO_MULTIPLE_TRANSFER: "ADD_PRODUCT_TO_MULTIPLE_TRANSFER",
	CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER: "CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER",
	ADD_PRODUCT_TO_PUBLISH_ORDER: "ADD_PRODUCT_TO_PUBLISH_ORDER",
	CLEAR_PRODUCT_FROM_PUBLISH_ORDER: "CLEAR_PRODUCT_FROM_PUBLISH_ORDER",
	ADD_PRODUCT_TO_RESTOCK: "ADD_PRODUCT_TO_RESTOCK",
	CLEAR_PRODUCT_FROM_RESTOCK: "CLEAR_PRODUCT_FROM_RESTOCK",
}

export const productReducerInitialValues = {
	bulkDelete: [],
	isItemToBulkDelete: false,
	multipleTransfer: [],
	isMultipleTransfer: false,
	publishProducts: [],
	isItemToPublish: false,
	restockProducts: [],
	isItemToRestock: false
}

const productReducer = (state, action) => {
	switch (action.type) {
		case productReducerActionTypes.ADD_PRODUCT_TO_BULK_DELETE:
			const isItemInBatch = state.bulkDelete.find(item => item === action.itemId)

			if(isItemInBatch) {
				const newBatch = state.bulkDelete.filter(item => item !== action.itemId)
				return {...state, bulkDelete: newBatch, isItemToBulkDelete: newBatch.length > 0 }
			}

			return {...state, bulkDelete: [...state.bulkDelete, action.itemId], isItemToBulkDelete: true}
			
		case productReducerActionTypes.CLEAR_PRODUCT_FROM_BULK_DELETE:
			return { ...state, bulkDelete: [], isItemToBulkDelete: false }
			
			
		// MULTIPLE TRANSFER
		case productReducerActionTypes.ADD_PRODUCT_TO_MULTIPLE_TRANSFER:
			const isProductInBatch = state.multipleTransfer.find(item => item.id === action.product.id)
			
			if(isProductInBatch) {
				const newBatch = state.multipleTransfer.filter(item => item.id !== action.product.id)
				return {...state, multipleTransfer: newBatch, isMultipleTransfer: newBatch.length > 0 }
			}
			
			return {...state, multipleTransfer: [...state.multipleTransfer, action.product], isMultipleTransfer: true}
		
		case productReducerActionTypes.CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER:
			return { ...state, multipleTransfer: [], isMultipleTransfer: false }
		
			
		// PUBLISH PRODUCTS
		case productReducerActionTypes.ADD_PRODUCT_TO_PUBLISH_ORDER: {
			const isItemInBatch = state.publishProducts.find(item => item === action.itemId)
			
			if(isItemInBatch) {
				const newBatch = state.publishProducts.filter(item => item !== action.itemId)
				return {...state, publishProducts: newBatch, isItemToPublish: newBatch.length > 0 }
			}
			
			return {...state, publishProducts: [...state.publishProducts, action.itemId], isItemToPublish: true}
		}
		
		// PUBLISH PRODUCTS
		case productReducerActionTypes.ADD_PRODUCT_TO_RESTOCK: {
			const isItemInBatch = state.restockProducts.find(item => item.id === action.product.id)
			
			if(isItemInBatch) {
				const newBatch = state.restockProducts.filter(item => item.id !== action.product.id)
				return {...state, restockProducts: newBatch, isItemToRestock: newBatch.length > 0 }
			}
			
			return {...state, restockProducts: [...state.restockProducts, action.product], isItemToRestock: true}
		}
		
		case productReducerActionTypes.CLEAR_PRODUCT_FROM_PUBLISH_ORDER:
			return { ...state, publishProducts: [], isItemToPublish: false }

		default:
			return state
	}
}

const useProductReducer = () => {
	return useReducer(productReducer, productReducerInitialValues)
}

export default useProductReducer


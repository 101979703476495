import {DetailsStyled} from "./settingsStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Form, Line, PasswordInput} from "../../../components";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useChangePasswordService} from "../../../hooks/useServices/authenticationHooks";
import {isPasswordRequirementsValid, isStringLengthValid, isStringsMatch} from "../../../utils/validator";


const formValues = {old_password: "", new_password: "", confirm_new_password: ""}


const Password = () => {
  const { toast } = useGlobalContext()
  const changePasswordMutation = useChangePasswordService()
  
  const handleChangePassword = (values) => {
    if(!isStringLengthValid(values.new_password, 8)) {
      toast.error("Password length must be at least 8 characters", values.new_password)
      return
    }

    if(!isPasswordRequirementsValid(values.new_password)) {
      toast.error("Password must contain at least an uppercase and lowercase letter, number and a symbol", values.new_password)
      return
    }

    if(!isStringsMatch(values.new_password, values.confirm_new_password)) {
      toast.error("New password and confirm password does not match")
      return
    }
    
    changePasswordMutation.mutate(values)
  }

  return (
    <DetailsStyled $avatarWidth={30} $formWidth={30}>
      <SpaceBetweenStyled className="pageHeader">
        <div>
          <Heading size={1.3} color="black">Password</Heading>
          <Paragraph color="secondary">Edit and rest password</Paragraph>
        </div>
      </SpaceBetweenStyled>

      <Line />

      <FlexStyled className="avatarAndForm">
        <section className="form_section">
          <Form values={formValues} onSubmit={handleChangePassword}>
            <SectionStyled>
              <PasswordInput name="old_password" label="Old Password" placeholder="Enter Current Password" />
              <PasswordInput name="new_password" label="New Password" placeholder="Enter New Password" />
              <PasswordInput name="confirm_new_password" label="Confirm New Password" placeholder="Confirm New Password" />
              <Button text="Save" />
            </SectionStyled>
          </Form>
        </section>
      </FlexStyled>
    </DetailsStyled>
  )
}

export default Password
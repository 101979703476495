const getInventoryServiceStructure = (data, {businessId: currentBusinessId}) => {
	const results = data?.results?.map(item => {
		let is_in = currentBusinessId === item.business
		let allowed_to_perform_action = false
		
		if (!!item.group_order_id) {
			is_in = false
		}
		
		if (!item.is_in && currentBusinessId === item.business) {
			is_in = false
		}

		if(!!item.is_in && (currentBusinessId === item.business) && !!item.group_order_id) {
			is_in = true
		}
		
		// check if it is the store that is receiving the inventory and the status is still pending
		if((currentBusinessId === item.business) && !item.staff_action_name && item.status === "pending") {
			allowed_to_perform_action = true
		}
		
		const currentQuantity = () => {
			if (item.quantity === 0) return item.quantity
			if (is_in) return `+${item.quantity}`
			if (!is_in) return `-${item.quantity}`
		}
		
		return {
			...item,
			is_in,
			allowed_to_perform_action,
			quantity: currentQuantity()
		}
	})
	
	return {...data, results}
}


export default getInventoryServiceStructure
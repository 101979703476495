import {useMutation, useQuery, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {
	assignStaffToWipService,
	completeWipService,
	getWipAnalyticsService,
	getWipByStaffIdService
} from "../../../services/wipServices";
import {useParams} from "react-router-dom";
import orderQueryKey from "../useOrderServices/orderQueryKey";
import staffQueryKey from "../useStaffServices/staffQueryKey";
import errorHandler from "../../../utils/errorHandler";
import {getBusinessIdFromStorage} from "../../../utils";


export const wipQueryKeys = {
	all: ["WIP_ANALYTICS", getBusinessIdFromStorage()],
	wipAnalytics: ["WIP_ANALYTICS", getBusinessIdFromStorage()],
	wipAnalyticsForStaff: (staffId, search) => ["WIP_ANALYTICS", getBusinessIdFromStorage(), staffId, search]
}

const useWipAnalyticsQuery = () => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery({
		queryKey: wipQueryKeys.all,
		queryFn: () => getWipAnalyticsService({ business_id: businessId })
	})
}

const useWipByStaffIdQuery = (staffIdByProps, searchValue) => {
	const { staffId } = useParams()
	
	const id = staffId || staffIdByProps

	return useQuery({
		queryKey: wipQueryKeys.wipAnalyticsForStaff(id, searchValue),
		queryFn: () => getWipByStaffIdService(id, searchValue)
	})
}


const useAssignStaffToWipMutation = ({ successFn }) => {
	const { groupOrderId } = useParams()
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useMutation({
		mutationFn: ({ id, data }) => {
			return assignStaffToWipService(id,{ ...data, business: businessId })
		},

		onSuccess: () => {
			successFn()
			toast.success("Staff assigned to Stage!!")
			queryClient.refetchQueries({ queryKey: wipQueryKeys.all })
			queryClient.refetchQueries({ queryKey: staffQueryKey.all })
			queryClient.invalidateQueries({ queryKey: orderQueryKey.byGroupOrderId(groupOrderId) })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useCompleteWipMutation = ({ successFn }) => {
	const { groupOrderId } = useParams()
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useMutation({
		mutationFn: ({ id, data }) => {
			return completeWipService(id,{ ...data, business: businessId })
		},

		onSuccess: () => {
			successFn()
			toast.success("Wip Stage completed!!", "success")
			queryClient.refetchQueries({ queryKey: wipQueryKeys.all })
			queryClient.refetchQueries({ queryKey: staffQueryKey.all })
			queryClient.invalidateQueries({ queryKey: orderQueryKey.byGroupOrderId(groupOrderId) })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export { useWipAnalyticsQuery, useWipByStaffIdQuery, useAssignStaffToWipMutation, useCompleteWipMutation }
import axios from "../../axios";


const getBusinessSettingsService = async (businessId) => {
  try {
    const { data } = await axios.get(`/settings/?business_id=${businessId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const updateBusinessSettingsService = async (id, body) => {
  try {
    const { data } = await axios.patch(`/settings/${id}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getBusinessSettingsService, updateBusinessSettingsService }
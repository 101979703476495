import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {restructureGetTips} from "./restructureTips";

export const getTipsService = async (queryParams) => {
  const url = handleQueryParams("/tips/", queryParams)
  
  try {
    const { data } = await axios.get(url)
    return restructureGetTips(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const createTipService = async (body) => {
  try {
    const { data } = await axios.post("/tips/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const deleteTipService = async (tipId) => {
  try {
    const { data } = await axios.patch(`/tips/${tipId}/`, { status: "cancelled" })
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
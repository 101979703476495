import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, Form, Input, Select} from "../../../index";
import {Heading} from "../../../../styles/textStyles";
import {FlexColumnStyled, FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useFormikContext} from "formik";
import {Fragment, useEffect, useRef, useState} from "react";
import {useSellableProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {useSubCategoriesByCategoryIdQuery} from "../../../../hooks/useServices/useSubCategoryServices";
import {useCustomerQuery} from "../../../../hooks/useServices/useCustomerServices";
import {
  discountAndTaxTypesEnum,
  taxCalculationTypes,
  taxSelectTypes
} from "../../../../data/selectField/discountAndTaxSelectTypes";
import MultiSelectInput from "../../../Forms/MultiSelectInput";

const EditTaxModal = ({onClose, handleSubmit, mutation, formValues}) => {
  const {} = useCustomerQuery()
  const {} = useCategoriesQuery()
  const {} = useSellableProductByBusinessIdQuery()
  const [selectedMappings, setSelectedMapping] = useState(formValues.selectedMapping)
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Edit: {formValues.title}</Heading>
        <Form values={formValues}
              onSubmit={(values) => handleSubmit({...values, mapping_id: selectedMappings})}>
          <Input label="Title" placeholder="Enter tax title" name="title"/>
          
          <Select
            options={taxSelectTypes}
            name="tax_type"
            placeholder="Select type of type"
            label="Tax Type"
            displayKey="name"
            valueKey="value"
            displayName="tax_type_name"
          />
          
          <MappingIDComp setSelectedMapping={setSelectedMapping} selectedMapping={selectedMappings}/>
          
          <Select
            options={taxCalculationTypes}
            name="calculation_type"
            placeholder="Select calculation type"
            label="Calculation type"
            displayKey="name"
            valueKey="value"
            displayName="calculation_type_name"
          />
          
          <Input label="Tax Value" type="number" placeholder="Enter tax value" name="value"/>
          
          <FlexColumnStyled $gap={0.4} className="fullWidth">
            <CheckBox checked={!!formValues.active} optional name="active" labelProp="Activate Tax"/>
            {/*<CheckBox optional name="is_enforced" labelProp="Enforce tax"/>*/}
          </FlexColumnStyled>
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditTaxModal


const MappingIDComp = ({setSelectedMapping, selectedMapping}) => {
  const {values} = useFormikContext()
  
  const isLoadRef = useRef(0)
  const [searchValue, setSearchValue] = useState("")
  const [categoryId, setCategoryId] = useState("")
  
  const {data: allProducts, isLoading: isProductsLoading} = useSellableProductByBusinessIdQuery(searchValue)
  const {data: allCategories, isLoading: isCategoriesLoading} = useCategoriesQuery(searchValue)
  const {
    data: allSubCategories,
    isLoading: isSubCategoriesLoading
  } = useSubCategoriesByCategoryIdQuery(categoryId)
  const {data: allCustomers, isLoading: isCustomersLoading} = useCustomerQuery(searchValue)
  
  useEffect(() => {
    if (isLoadRef.current === 2) {
      setSelectedMapping([])
      setSearchValue("")
      return
    }
    
    isLoadRef.current += 1
  }, [values.discount_type])
  
  return (
    <>
      {(values.tax_type === discountAndTaxTypesEnum.product) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isProductsLoading}
          options={allProducts?.results}
          displayName="name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select Product(s)"
          placeholder="Select products to add tax to"
        />
      )}
      
      {(values.tax_type === discountAndTaxTypesEnum.category) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isCategoriesLoading}
          options={allCategories?.results}
          displayName="name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select categories"
          placeholder="Select categories to add tax to"
        />
      )}
      
      {(values.tax_type === discountAndTaxTypesEnum.sub_category) && (
        <Fragment>
          <Select
            name="category_name"
            displayKey="name"
            valueKey="id"
            options={allCategories?.results}
            placeholder="Select Category"
            updateFn={(category) => setCategoryId(category.id)}
          />
          
          <MultiSelectInput
            className="fullWidth"
            onSearch={setSearchValue}
            isLoading={isSubCategoriesLoading}
            options={allSubCategories?.results}
            displayName="name"
            selectedValues={selectedMapping}
            setSelected={setSelectedMapping}
            label="Select Sub Categories"
            placeholder="Select sub categories to add tax to"
          />
        </Fragment>
      )}
      
      {(values.tax_type === discountAndTaxTypesEnum.customer) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isCustomersLoading}
          options={allCustomers?.results}
          displayName="full_name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select customer(s)"
          placeholder="Select customer(s) to add tax to"
        />
      )}
    </>
  )
}
import {useUserProfile} from "../../useServices/userHooks";
import env from "../../../utils/env";
import {useBusinessByIdQuery} from "../../useServices/useStoreServices";
import {useEffect} from "react";
import {useQueryClient} from "react-query";
import {desktopOfflineQueryKeys} from "../useDesktopServicesFromLocalDB";

const useDesktopOfflineModeAllowed = () => {
  const {data: userProfile} = useUserProfile()
  const {data: currentBusiness} = useBusinessByIdQuery()
  const queryClient = useQueryClient()
  
  useEffect(() => {
    if (!currentBusiness) {
      queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.business()}).then().catch()
    }
    
    if (!userProfile || !userProfile?.[0]) {
      queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.user()}).then().catch()
    }
  }, [currentBusiness, userProfile])
  
  // allow on offline mode if user account is a premium account
  return !!window?.require && !!currentBusiness?.id && !!userProfile && userProfile?.[0]?.package_details?.package_id === env.PREMIUM_SUBSCRIPTION_PLAN
}

export default useDesktopOfflineModeAllowed
import {Button} from "../index";
import {AddCircle, ArrowLeft} from "iconsax-react";
import {Heading, Paragraph} from "../../styles/textStyles";
import {useActionPermission} from "../../hooks/usePermissions";
import {FlexStyled, PageHeaderWithButtonStyled} from "../../styles/utilStyles";
import {useTheme} from "styled-components";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const PageHeaderWithButton = ({ title, buttonText, buttonClick, Icon, permissionCode, isBack, buttonDisabled = false, extra, noBackText, isLoading, buttonMaxWidth, buttonText2, permissionCode2, Icon2, buttonClick2 }) => {
  const theme = useTheme()
  const { navigate, isOffline } = useGlobalContext()
  const showButton = useActionPermission(permissionCode)

  return (
    <PageHeaderWithButtonStyled $buttonMaxWidth={buttonMaxWidth}>
      {isBack && (
        <FlexStyled className="navigation_section">
          <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
          <FlexStyled>
            {!noBackText && <Paragraph noTop color="menuGray">Back to</Paragraph>}
            <Heading color="black">{title}</Heading>
            {!!extra && <Paragraph color="black">{extra}</Paragraph>}
          </FlexStyled>
        </FlexStyled>
      )}

      {!isBack && <Heading color="black" size={1.7}>{title}</Heading>}
      <FlexStyled className="button_actions">
        {!!buttonText && <Button isLoading={isLoading} disabled={buttonDisabled || isOffline} text={buttonText} onClick={buttonClick} Icon={Icon || AddCircle} permissionCode={permissionCode} />}
        {!!buttonText2 && <Button isLoading={isLoading} disabled={buttonDisabled || isOffline} text={buttonText2} onClick={buttonClick2} Icon={Icon2 || AddCircle} permissionCode={permissionCode2} />}
      </FlexStyled>
      
      <div className="mobile_view">
        {showButton && <Icon color="#7C7C7A" onClick={buttonClick} />}
      </div>
    </PageHeaderWithButtonStyled>
  )
}

export default PageHeaderWithButton
import {Fragment, memo, useEffect, useRef, useState} from "react";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {Button, CheckBox, Form, Input, Select, TextEditor} from "../../../index";
import {productFormDefaultValues} from "../../../../data/defaultFormValues";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useSubCategoriesByCategoryIdQuery} from "../../../../hooks/useServices/useSubCategoryServices";
import {commissionAndDiscountTypeOptions, unitMeasurementOptions} from "../../../../data/selectField/product";
import {useModal} from "../../../../hooks";
import CropImage from "../../CropImage";
import {useFormikContext} from "formik";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {useFromModalConnector} from "../../../../hooks/useModalConnector";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {globalReducerActions} from "../../../../reducers/globalReducer";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {useProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";
import uuid from "react-uuid";
import {useNestedSubCategoriesQuery} from "../../../../hooks/useServices/useNestedSubCategoryServices";
import {MAX_NESTED_SUB_CATEGORY_LEVEL} from "../../../../utils/constants";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {
	comboProductAutomaticCostPriceCalculationAndPrefillInputField,
	handleChangingOfProductOnComboProductAutomaticCostPriceCalculationAndPrefill,
	handleDeleteProductOnComboProductAutomaticCostPriceCalculationAndPrefill
} from "../../../../utils/formatForms/product";
import {
	handleFormatCustomUnitMeasurementForFormSubmission,
	handleFormatProductAttachmentForFormSubmission,
	handleFormatProductForProductLookUp,
	handleFormatSalesAttachmentForFormSubmission
} from "../../../../utils/products";
import {
	useProductionUnitMeasurementQuery
} from "../../../../hooks/useServices/useProductionServices/useProductionUnitMeasurementServices";


const CreateProductModal = ({ onClose, handleSubmit, mutation }) => {
	const {globalState, globalReducer} = useGlobalContext()
	const businessSettings = useBusinessSettingQuery()
	const {numberFormatter} = useDataTypeFormatter()
	const [sellingPriceError, setSellingPriceError] = useState("")
	
	const [isShowExtraLookupFields, setIsShowExtraLookupFields] = useModal()
	const [showExtraMeasurement, setShowExtraMeasurement] = useModal()
	const [categoriesId, setCategoriesId] = useState({
		categoryId: "",
		subCategoryId: "",
		nested_category_1: "",
		nested_category_2: ""
	})
	
	const [isShowCommission, setIsShowCommission] = useModal(!!globalState.modalConnector.fromModalFormValues.category)
	const { isSuccess: isSubCategorySuccess, data: allSubCategories } = useSubCategoriesByCategoryIdQuery(globalState.modalConnector.fromModalFormValues.category || categoriesId.categoryId)
	const { isSuccess: isNestedSubCat1Success, data: allNestedSubCat1  } = useNestedSubCategoriesQuery({ sub_category: categoriesId.subCategoryId, level: 1 })
	const { isSuccess: isNestedSubCat2Success, data: allNestedSubCat2  } = useNestedSubCategoriesQuery({ parent: categoriesId.nested_category_1, level: 2 })
	const { isSuccess: isNestedSubCat3Success, data: allNestedSubCat3  } = useNestedSubCategoriesQuery({ parent: categoriesId.nested_category_2, level: 3 })
	const { data: unitMeasurement } = useProductionUnitMeasurementQuery()
	
	const handleClose = () => {
		onClose()
		globalReducer({
			type: globalReducerActions.CLEAR_MODAL_CONNECTOR
		})
	}

	const handleSubmitFn = (data) => {
		const formattedValuesForOtherProductLookUp = handleFormatProductForProductLookUp(data, isShowExtraLookupFields)
		const formattedValuesForUnitMeasurement = handleFormatCustomUnitMeasurementForFormSubmission(formattedValuesForOtherProductLookUp)
		const formattedValuesForSalesAttachment = handleFormatSalesAttachmentForFormSubmission(showExtraMeasurement ? formattedValuesForUnitMeasurement : formattedValuesForOtherProductLookUp)
		const formattedValuesForProductsAttachment = handleFormatProductAttachmentForFormSubmission(formattedValuesForSalesAttachment)
		
		const formValues = {
			unit_increment: 1,
			type: "general_product",
			...formattedValuesForProductsAttachment,
			sales_margin: Number(formattedValuesForProductsAttachment.sales_margin || 0).toFixed(2)
		}
		
		handleSubmit(formValues)
	}
	
	const AddUnitMeasurement = () => {
		const { values } = useFormikContext()
		const fromModalConnector = useFromModalConnector()
		
		const handleCreateUnitMeasurement = () => {
			fromModalConnector({
				fromModalFormValues: values,
				toModalURL: "/dashboard/set-up/unit-measurement/",
				toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
				fromModalName: modalNames.CREATE_PRODUCT_MODAL,
			})
		}
		
		return (
			<FlexStyled $gap={1}>
				<label className="underline" onClick={handleCreateUnitMeasurement}>Create +</label>
				<label className="underline" onClick={setShowExtraMeasurement}>{ showExtraMeasurement ? "Remove All -" : "Add Extra Measurement +" }</label>
			</FlexStyled>
		)
	}
	
	const getProductFormValues = () => {
		if(!businessSettings.use_sales_margin) return productFormDefaultValues
		
		return {
			...productFormDefaultValues,
			sales_margin: Number(businessSettings?.sales_margin),
			sales_margin_format: Number(businessSettings?.sales_margin)
		}
	}
	
	const handleCostPriceChange = (value, fieldName, formikValues, setFormikField) => {
		if(businessSettings.use_sales_margin) {
			const salesDifferenceBasedOnSalesMargin = (Number(value) * Number(formikValues.sales_margin)) / 100
			const newSellingPrice = (Number(value) + salesDifferenceBasedOnSalesMargin).toFixed(2)
			setFormikField("selling_price", newSellingPrice)
			setFormikField("selling_price_format", numberFormatter(newSellingPrice))
		}
	}
	
	const handleSellingPriceChange = (value, fieldName, formikValues, setFormikField) => {
		if (businessSettings.use_sales_margin) {
			const salesMarginPercentage = (((Number(value) - Number(formikValues.cost_price)) / Number(formikValues.cost_price)) * 100).toFixed(2)
			setFormikField("sales_margin", salesMarginPercentage)
			setFormikField("sales_margin_format", numberFormatter(salesMarginPercentage))
		}
		
		if (Number(formikValues.cost_price) > Number(value)) {
			setSellingPriceError('Sales price less than cost')
		} else {
			setSellingPriceError("")
		}
	}
	
	const handleSalesMarginChange = (value, fieldName, formikValues, setFormikField) => {
		if(businessSettings.use_sales_margin) {
			const salesDifferenceBasedOnSalesMargin = (Number(formikValues.cost_price) * Number(value)) / 100
			const newSellingPrice = (Number(formikValues.cost_price) + salesDifferenceBasedOnSalesMargin).toFixed(2)
			setFormikField("selling_price", newSellingPrice)
			setFormikField("selling_price_format", numberFormatter(newSellingPrice))
		}
	}
	

	return (
		<ModalLayoutStyled onClick={handleClose}>
			<CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={70}>
				<CloseSquare size={28} onClick={handleClose} />
				<Heading size={1.3} color="black">Create Product</Heading>

				<Form values={getProductFormValues()} enableReinitialize modalName={modalNames.CREATE_PRODUCT_MODAL} onSubmit={handleSubmitFn}>
					<Fragment>
						<Input label="Name" placeholder="Enter product name" name="name"/>
						
						<Input
							SideInfo={() => (
								<label className="underline" onClick={setIsShowExtraLookupFields}>Add Extra Lookup +</label>)}
							optional
							label="SKU/Barcode"
							placeholder="Enter SKU/Barcode"
							name="sku"
						/>
						
						{isShowExtraLookupFields &&
							<AddExtraProductSKULookUp
								isShowExtraLookupFields={isShowExtraLookupFields}
								setIsShowExtraLookupFields={setIsShowExtraLookupFields}
							/>
						}
						
						<Category setCategoryId={setCategoriesId}/>
						<SubCategory allSubCategories={allSubCategories} setSubCategoryId={setCategoriesId}
						             isSubCategorySuccess={isSubCategorySuccess}/>
						
						<SalesAttachment/>
						
						{allNestedSubCat1?.results?.length > 0 && (
							<NestedSubCategory level={1} name="nested_category_1" isSubCategorySuccess={isNestedSubCat1Success}
							                   allSubCategories={allNestedSubCat1} setNestedSubCategoryId={setCategoriesId}/>
						)}
						{allNestedSubCat2?.results?.length > 0 && (
							<NestedSubCategory level={2} name="nested_category_2" clearKeyName="nested_category_1"
							                   isSubCategorySuccess={isNestedSubCat2Success} allSubCategories={allNestedSubCat2}
							                   setNestedSubCategoryId={setCategoriesId}/>
						)}
						{allNestedSubCat3?.results?.length > 0 && (
							<NestedSubCategory level={3} name="nested_category_3" clearKeyName="nested_category_2" isSubCategorySuccess={isNestedSubCat3Success} allSubCategories={allNestedSubCat3} />
						)}
						
						<Select
							SideInfo={AddUnitMeasurement}
							options={[...(unitMeasurement || []), ...unitMeasurementOptions]}
							displayKey="name"
							valueKey="name"
							label="Unit Measurement"
							placeholder="Select unit measurement"
							name="unit_measurement"
							displayName="unit_measurement"
						/>
						
						<Input
							type="number"
							formatNumber
							name="cost_price"
							label="Cost Price"
							placeholder="Enter cost price"
							onChange={handleCostPriceChange}
						/>
						
						{businessSettings?.use_sales_margin && (
							<Input
								optional
								formatNumber
								type="number"
								name="sales_margin"
								label="Sales margin Percentage"
								placeholder="Enter sales margin in percentage"
								onChange={handleSalesMarginChange}
							/>
						)}
						
						<Input
							type="number"
							formatNumber
							label="Selling Price"
							placeholder="Enter selling price"
							name="selling_price"
							onChange={handleSellingPriceChange}
							errorColor="error"
							error={sellingPriceError}
						/>
						
						<Input
							optional
							type="number"
							formatNumber
							name="stock_unit"
							label="Available Stock Unit"
							placeholder="Enter the available stock unit"
						/>
						
						{showExtraMeasurement &&
							<CustomerUnitMeasurement unitMeasurements={[...(unitMeasurement || []), ...unitMeasurementOptions]}/>}
						
						<Input
							optional
							type="number"
							formatNumber
							name="stock_low_level"
							label="Restock order level"
							placeholder="Enter level at which stock shows low"
						/>
						
						{businessSettings?.tables && (
							<Input
								optional
								type="number"
								formatNumber
								name="prepare_time"
								label="Average time taken to prepare"
								placeholder="Enter time taken in minutes"
							/>
						)}

						<CheckBox checked={productFormDefaultValues.sellable} className="fullWidth" optional name="sellable" labelProp="Sellable" />
						<CheckBox checked={productFormDefaultValues.use_stock} className="fullWidth" optional name="use_stock" labelProp="Keep stock unit" />
						{businessSettings.commission && (
							<CheckBox checked={isShowCommission} className="fullWidth" optional name="show_commission" afterCheck={setIsShowCommission} labelProp="Add commission to product" />
						)}
						
						{isShowCommission && (
							<Fragment>
								<Select
									options={commissionAndDiscountTypeOptions}
									displayKey="name"
									valueKey="name"
									label="Commission Type"
									placeholder="Enter commission type"
									name="commission_type"
									displayName="commission_type_name"
								/>
								<Input
									type="number"
									formatNumber
									label="Commission Value"
									placeholder="Enter value"
									name="commission_value"
								/>
							</Fragment>
						)}

						<CropImage optional width={400} height={600} className="fullWidth" name="image" placeholder="Select Product Image" />
						
            <TextEditor name="product_description" />

            <FlexStyled>
							<Button isLoading={mutation.isLoading} text="Save" type="submit" />
						</FlexStyled>
					</Fragment>
				</Form>

			</CreateStoreModalStyled>
		</ModalLayoutStyled>
	)
}

const Category = memo(({ setCategoryId }) => {
	const {values, setFieldValue} = useFormikContext()
	const fromModalConnector = useFromModalConnector()
	const [search, setSearch] = useState("")
	const {isSuccess: isCategorySuccess, data: allCategories, isLoading: isCategoryLoading} = useCategoriesQuery(search)
	
	const handleSubCategoryQuery = (category) => {
		setCategoryId({
			categoryId: category.id,
			subCategoryId: "",
			nested_category_1: "",
			nested_category_2: ""
		})
		
		setFieldValue("nested_category_1", "")
		setFieldValue("nested_category_2", "")
		setFieldValue("nested_category_3", "")
		setFieldValue("nested_category_1_name", "")
		setFieldValue("nested_category_2_name", "")
		setFieldValue("nested_category_3_name", "")
	}
	
	const handleCreateCategory = () => {
		fromModalConnector({
			fromModalFormValues: values,
			toModalURL: "/dashboard/set-up/categories/",
			toModalName: modalNames.CREATE_CATEGORY_MODAL,
			fromModalName: modalNames.CREATE_PRODUCT_MODAL,
		})
	}
	
	const CreateCategory = () => (
		<label className="underline" onClick={handleCreateCategory}>Create +</label>
	)
	
	return (
		<Select
			isSearchLoading={isCategoryLoading}
			updateQueryResult={setSearch}
			SideInfo={CreateCategory}
			displayKey="name"
			valueKey="id"
			name="category"
			displayName="category_name"
			label="Category"
			placeholder="Select Category"
			updateFn={handleSubCategoryQuery}
			options={isCategorySuccess ? allCategories.results : []}
		/>
	)
})


const SubCategory = memo(({ isSubCategorySuccess, allSubCategories, setSubCategoryId }) => {
	const { values, setFieldValue } = useFormikContext()
	const { toast } = useGlobalContext()
	const fromModalConnector = useFromModalConnector()
	
	const handleSubCategoryQuery = (subCategory) => {
		setSubCategoryId(prev => ({
			...prev,
			subCategoryId: subCategory.id,
			nested_category_1: "",
			nested_category_2: ""
		}))
		
		setFieldValue("nested_category_1", "")
		setFieldValue("nested_category_2", "")
		setFieldValue("nested_category_3", "")
		setFieldValue("nested_category_1_name", "")
		setFieldValue("nested_category_2_name", "")
		setFieldValue("nested_category_3_name", "")
	}

	const handleCreateSubCategory = () => {
		if(!values.category || !values.category_name) {
			toast.error("Please select category first")
			return
		}

		fromModalConnector({
			fromModalFormValues: values,
			fromModalName: modalNames.CREATE_PRODUCT_MODAL,
			toModalName: modalNames.CREATE_SUBCATEGORY_MODAL,
			toModalURL: `/dashboard/set-up/categories/${values.category_name}/${values.category}`,
		})
	}
	
	const CreateSubCategory = () => (
		<label className="underline" onClick={handleCreateSubCategory}>Create +</label>
	)
	
	return (
		<Select
			optional
			valueKey="id"
			displayKey="name"
			name="sub_category"
			label="Sub-Category"
			clearCondition="category"
			displayName="sub_category_name"
			placeholder="Select sub-category"
			SideInfo={CreateSubCategory}
			updateFn={handleSubCategoryQuery}
			options={isSubCategorySuccess ? allSubCategories.results : []}
		/>
	)
})


const NestedSubCategory = memo(({ isSubCategorySuccess, allSubCategories, setNestedSubCategoryId, level, name, clearKeyName }) => {
	const { values, setFieldValue } = useFormikContext()
	
	useEffect(() => {
		setFieldValue(name, "")
		setFieldValue(`${name}_name`, "")
		
		if(level === MAX_NESTED_SUB_CATEGORY_LEVEL) return
		
		setNestedSubCategoryId(prev => ({
			...prev,
			[name]: "",
		}))
	}, [values[clearKeyName]])
	
	const handleSubCategoryQuery = (subCategory) => {
		if(level === MAX_NESTED_SUB_CATEGORY_LEVEL) return
		
		setNestedSubCategoryId && setNestedSubCategoryId(prev => ({ ...prev, [name]: subCategory.id }))
	}
	
	return (
		<Select
			optional
			valueKey="id"
			displayKey="name"
			name={name}
			label={`Sub-category ${Number(level) + 1}`}
			clearCondition={clearKeyName}
			displayName={`${name}_name`}
			updateFn={handleSubCategoryQuery}
			placeholder={`Select sub category ${Number(level) + 1}`}
			options={isSubCategorySuccess ? allSubCategories.results : []}
		/>
	)
})


const SalesAttachment = memo(() => {
	const { data } = useProductByBusinessIdQuery()
	const { setFieldValue, values } = useFormikContext()
	const { numberFormatter } = useDataTypeFormatter()
	const formikValuesRef = useRef({})
	const [isSalesAttachment, setIsSalesAttachment] = useModal()
	const addedProductsToCostPrice = useRef([])
	
	useEffect(() => {
		formikValuesRef.current = values
	}, [values])
	
	
	const handleSelectedProduct = (product, _, productFieldName) => {
	
		handleChangingOfProductOnComboProductAutomaticCostPriceCalculationAndPrefill({
			setFieldValue,
			formikValuesRef,
			numberFormatter,
			addedProductsToCostPrice,
			productID: product.id,
			productCostPrice: Number(product.cost_price),
			productFieldID: productFieldName?.split("_")?.[3] || "main"
		})
		
		setFieldValue(`${product.id}_cost_price`, Number(product.cost_price))
	}
	
	const handleUpdateProductCostPrice = (quantity, fieldName) => {
		comboProductAutomaticCostPriceCalculationAndPrefillInputField({
			quantity,
			fieldName,
			setFieldValue,
			formikValuesRef,
			numberFormatter,
			addedProductsToCostPrice,
		})
	}
	
	const SalesAttachmentForm = () => {
		const id = uuid()
		
		const SelectComp = () => (
			<Select
				valueKey="id"
				displayKey="name"
				name={`sales_attachment_name_${id}`}
				label="Product"
				displayName={`sales_attachment_display_${id}`}
				placeholder="Select product"
				options={data?.results}
				updateFn={handleSelectedProduct}
			/>
		)
		
		const InputComp = () => (
			<Input
				type="number"
				formatNumber
				name={`sales_attachment_quantity_${id}`}
				label="Quantity"
				placeholder="Enter quantity"
				onChange={handleUpdateProductCostPrice}
				clearCondition={`sales_attachment_name_${id}`}
				disabled={!formikValuesRef.current[`sales_attachment_name_${id}`]}
			/>
		)
		
		return { id, SelectComp, InputComp }
	}
	
	const [moreProducts, setMoreProducts] = useState([])
	
	const addExtraProduct = () => {
		setMoreProducts([...moreProducts, SalesAttachmentForm()])
	}
	
	const handleDelete = (id) => {
		const newProducts = moreProducts.filter(products => products.id !== id)
		setMoreProducts(newProducts)
		
		setFieldValue(`sales_attachment_name_${id}`, "")
		setFieldValue(`sales_attachment_display_${id}`, "")
		setFieldValue(`sales_attachment_quantity_${id}`, "")
		
		handleDeleteProductOnComboProductAutomaticCostPriceCalculationAndPrefill({
			id,
			setFieldValue,
			addedProductsToCostPrice,
			numberFormatter,
			formikValuesRef
		})
	}
	
	return (
		<div className="fullWidth">
			<CheckBox checked={isSalesAttachment} className="fullWidth" optional name="show_sales_attachment" afterCheck={setIsSalesAttachment} labelProp="Create a combo product" />
			
			{isSalesAttachment && (
				<Fragment>
					<SpaceBetweenStyled className="fullWidth">
						<Select
							valueKey="id"
							displayKey="name"
							name="sales_attachment"
							label="Product"
							displayName="sales_attachment_display"
							placeholder="Select product"
							options={data?.results}
							updateFn={handleSelectedProduct}
						/>
						
						<Input
							type="number"
							formatNumber
							name="sales_quantity"
							label="Quantity"
							placeholder="Enter quantity"
							onChange={handleUpdateProductCostPrice}
							clearCondition="sales_attachment"
							disabled={!formikValuesRef.current[`sales_attachment`]}
						/>
						
						<Trash size={20} color="white" />
					</SpaceBetweenStyled>
					
					{moreProducts?.map(({ id, SelectComp, InputComp }) => (
						<SpaceBetweenStyled className="fullWidth" key={id}>
							<SelectComp />
							<InputComp />
							<Trash size={20} color="red" onClick={handleDelete.bind(this, id)} />
						</SpaceBetweenStyled>
					))}
					
					<Button onClick={addExtraProduct} type="button" text="Add more product" className="small" small />
						<br/>
				</Fragment>
			)}
		
		</div>
	)
})

const CustomerUnitMeasurement = memo(({ unitMeasurements }) => {
	const { setFieldValue, values } = useFormikContext()
	const { currencyFormatter } = useDataTypeFormatter()
	const fromModalConnector = useFromModalConnector()
	
	const handleCreateUnitMeasurement = () => {
		fromModalConnector({
			fromModalFormValues: values,
			toModalURL: "/dashboard/set-up/unit-measurement/",
			toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
			fromModalName: modalNames.CREATE_PRODUCT_MODAL,
		})
	}
	
	const CreateUnitMeasurementComp = () => {
		return <label className="underline" onClick={handleCreateUnitMeasurement}>Create +</label>
	}
	
	const handleSetCostPriceForUnitMeasurement = (id = "main", quantity, _, product) => {
		const totalCostForUnitMeasurement = Math.ceil(Number(product.cost_price) * Number(quantity))
		setFieldValue(`unit_measurement_cost_price_${id}`, `Cost Price: ${currencyFormatter(totalCostForUnitMeasurement)}`)
	}
	
	const handleSetErrorForSellingPriceForUnitMeasurement = (id = "main", sellingPrice, _, product) => {
		const quantity = id === "main" ? Number(product.unit_measurement_quantity) : Number(product[`unit_measurement_quantity_${id}`])
		const totalCostForUnitMeasurement = Math.ceil(Number(product.cost_price) * Number(quantity))
		
		if (totalCostForUnitMeasurement > Math.ceil(Number(sellingPrice))) {
			setFieldValue(`unit_measurement_selling_price_error_${id}`, `Sales price is less than cost`)
		} else {
			setFieldValue(`unit_measurement_selling_price_error_${id}`, '')
		}
	}
	
	const ProductionAttachmentForm = () => {
		const id = uuid()
		
		const SelectComp = () => (
			<Select
				SideInfo={CreateUnitMeasurementComp}
				options={unitMeasurements}
				displayKey="name"
				valueKey="name"
				label="Unit Measurement"
				placeholder="Select unit measurement"
				name={`unit_measurement_name_${id}`}
				displayName={`unit_measurement_display_${id}`}
			/>
		)
		
		const InputComp = ({values}) => (
			<Input
				type="number"
				formatNumber
				name={`unit_measurement_quantity_${id}`}
				label="Quantity"
				placeholder="Enter quantity per measurement"
				onChange={handleSetCostPriceForUnitMeasurement.bind(this, id)}
				error={values[`unit_measurement_cost_price_${id}`]}
			/>
		)
		
		const InputComp2 = ({ values }) => (
			<Input
				type="number"
				formatNumber
				name={`unit_measurement_price_${id}`}
				label="Sales Price"
				placeholder="Enter sales price per measurement"
				onChange={handleSetErrorForSellingPriceForUnitMeasurement.bind(this, id)}
				error={values[`unit_measurement_selling_price_error_${id}`]}
				errorColor="error"
			/>
		)
		
		return { id, SelectComp, InputComp, InputComp2 }
	}
	
	const [moreProducts, setMoreProducts] = useState([])
	
	const addExtraProduct = () => {
		setMoreProducts([...moreProducts, ProductionAttachmentForm()])
	}
	
	const handleDelete = (id) => {
		const newProducts = moreProducts.filter(products => products.id !== id)
		setMoreProducts(newProducts)
		
		setFieldValue(`unit_measurement_name_${id}`, "")
		setFieldValue(`unit_measurement_display_${id}`, "")
		setFieldValue(`unit_measurement_quantity_${id}`, "")
		setFieldValue(`unit_measurement_price_${id}`, "")
	}
	
	return (
		<div className="fullWidth add_extra_3">
			<Fragment>
				<FlexStyled className="fullWidth three_form_cols space_between">
					<Select
						SideInfo={CreateUnitMeasurementComp}
						options={unitMeasurements}
						displayKey="name"
						valueKey="name"
						label="Unit Measurement"
						placeholder="Select unit measurement"
						name="unit_measurement_name"
						displayName="unit_measurement_display"
					/>
					
					<Input
						type="number"
						formatNumber
						name="unit_measurement_quantity"
						label="Quantity"
						placeholder="Enter quantity per measurement"
						error={values[`unit_measurement_cost_price_main`]}
						onChange={handleSetCostPriceForUnitMeasurement.bind(this, "main")}
					/>
					
					<Input
						type="number"
						formatNumber
						name="unit_measurement_price"
						label="Sales Price"
						placeholder="Enter sales price per measurement"
						error={values[`unit_measurement_selling_price_error_main`]}
						errorColor="error"
						onChange={handleSetErrorForSellingPriceForUnitMeasurement.bind(this, "main")}
					/>
				</FlexStyled>
				
				{moreProducts?.map(({ id, SelectComp, InputComp, InputComp2 }) => (
					<FlexStyled className="fullWidth three_form_cols" key={id}>
						<SelectComp/>
						<InputComp values={values}/>
						<InputComp2 values={values}/>
						<Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)}/>
					</FlexStyled>
				))}
				
				<Button onClick={addExtraProduct} type="button" text="Add more measurement" className="small add_extra" small/>
				<br/>
			</Fragment>
		
		</div>
	)
})


export const AddExtraProductSKULookUp = ({isShowExtraLookupFields, setIsShowExtraLookupFields, isEditProduct}) => {
	const {setFieldValue, values} = useFormikContext()
	const {toast} = useGlobalContext()
	const [moreProducts, setMoreProducts] = useState([])
	
	useEffect(() => {
		if (isShowExtraLookupFields && !values.sku) {
			toast.error("Please put in your first sku to add more")
			setIsShowExtraLookupFields()
		}
	}, [isShowExtraLookupFields, values])
	
	const SKULookupFormComponent = (customId) => {
		const id = customId || uuid()
		
		const ExtraSKULookUpField = ({count, handleDelete, className}) => (
			<Input
				SideInfo={() => (
					<label className="underline" onClick={() => handleDelete(id, count)}>Delete -</label>
				)}
				className={className}
				label={`SKU/Barcode (${count})`}
				placeholder={`Add extra sku lookup (${count})`}
				name={`sku_${id}`}
			/>
		)
		
		return {id, ExtraSKULookUpField}
	}
	
	useEffect(() => {
		if (isEditProduct && values?.productLookUpUUIDs?.length > 0) {
			const productLookups = values.productLookUpUUIDs.map(lookupId => {
				return SKULookupFormComponent(lookupId)
			})
			
			setMoreProducts(productLookups)
		}
	}, [isEditProduct])
	
	const addExtraSKUField = () => {
		setMoreProducts([...moreProducts, SKULookupFormComponent()])
	}
	
	const handleDelete = (id) => {
		const newProducts = moreProducts.filter(products => products.id !== id)
		setMoreProducts(newProducts)
		
		setFieldValue(`sku_${id}`, "")
	}
	
	return (
		<div className="fullWidth">
			<FlexStyled className="fullWidth">
				{!isEditProduct && (
					<Input
						SideInfo={() => (
							<label className="underline" onClick={() => {
								setFieldValue("sku1", "")
								setIsShowExtraLookupFields()
							}}>Delete All Extra SKU</label>
						)}
						label="SKU/Barcode (1)"
						placeholder="Add extra sku lookup (1)"
						name="sku1"
					/>
				)}
				
				{moreProducts?.map(({id, ExtraSKULookUpField}, index) => (
					<ExtraSKULookUpField
						key={id}
						count={index + 2}
						handleDelete={handleDelete}
						className={index === 0 ? "move_first_flex_item_to_left" : ""}/>
				))}
			</FlexStyled>
			
			<Button
				onClick={addExtraSKUField}
				type="button"
				text="Add More SKU"
				className="small add_extra"
				small
			/>
			<br/>
		</div>
	)
}

export default CreateProductModal
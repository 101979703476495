import { Field } from "formik";
import {PhoneInputStyled} from "../../styles/formStyles";
import {FormProvider} from "./Form";
import {useContext} from "react";

const PhoneInput = ({ name, label, placeholder, optional }) => {
  const { country, allFieldsNotRequired } = useContext(FormProvider)
  const { flag, dialCode } = country || {}

  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }

  return (
    <PhoneInputStyled $code={!!dialCode}>
      <label htmlFor={name}>{label} {!!optional && "(Optional)"}</label>

      {!!dialCode && (
        <i className="right_icon">
          <span>{flag}</span>
          <span>{dialCode}</span>
        </i>
      )}

      <Field id={name} type="number" name={name} placeholder={placeholder} required={isRequired()} />
    </PhoneInputStyled>
  )
}

export default PhoneInput
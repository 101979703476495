import {
  NoDataLayout,
  OrderSearchAndFilter,
  PageHeaderWithButtonAndDateFilter,
  PageSummaryCard,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
  TablePagination
} from "../../../components";

import {Fragment, useState} from "react";
import {EmptyBoxImage} from "../../../assets/images";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useGroupOrderByBusinessIdQuery} from "../../../hooks/useServices/useOrderServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import orderQueryKey from "../../../hooks/useServices/useOrderServices/orderQueryKey";
import {getOrderServiceStructure} from "../../../services/orderServices/orderServiceStructure";
import {useOrderAnalyticsService} from "../../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum} from "../../../data/selectField/dateFilter";


const Orders = () => {
  const {numberFormatter} = useDataTypeFormatter()
  const {orderTable} = tableHeadersAndValueKeys
  const {debounceState, debounceReducer} = useQueryDebounce()
  
  const {toDate, fromDate} = handleDateFilterValue(dateFilterValue.TODAY)
  const [dateFilter, setDateFilter] = useState({from_date: fromDate, to_date: toDate})
  
  const {
    data: orderAnalytics,
    isLoading: isOrderAnalyticsLoading,
    refetch: refetchOrderAnalytics
  } = useOrderAnalyticsService(dateFilter.from_date, dateFilter.to_date)
  const query = {
    ...debounceState,
    ...(!debounceState.searchValue && {fromDateValue: dateFilter.from_date, toDateValue: dateFilter.to_date})
  }
  
  const {mainQuery, ...allGroupOrders} = useOrderQueryWithSearchAndFilter(query, useGroupOrderByBusinessIdQuery)
  
  const refetchAllQueries = async () => {
    await refetchOrderAnalytics()
    await allGroupOrders.refetch()
  }
  
  const totalOrders = () => {
    return allGroupOrders?.data?.results?.reduce((prev, curr) => {
      return prev + Number(curr.total_items)
    }, 0)
  }

  return (
    <div>
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={refetchAllQueries} />
      )}
      
      <Fragment>
        <PageHeaderWithButtonAndDateFilter
          setDateFilter={setDateFilter}
          title="Sales History"
          globalStateKey="orderAnalytics"
          dateFilterDefaultValue={dateFilterValueEnum.TODAY}
        />

        {allGroupOrders.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No orders"
              imgSrc={EmptyBoxImage}
              heading="No orders yet"
            />
          </Fragment>
        )}

        {allGroupOrders.showTable && (
          <Fragment>

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allGroupOrders.isQueryLoading}
                title="Total Number Of Orders"
                value={numberFormatter(allGroupOrders?.data?.count || 0)}
                subText={`${numberFormatter(totalOrders())} items`}
              />
              
              <PageSummaryCard
                title="Total Paid Orders"
                isLoading={isOrderAnalyticsLoading}
                subText={`${Number(orderAnalytics?.total_paid_orders)} Com`}
                // subText2={`${Number(orderAnalytics?.total_partial_orders)} P.A`}
                // subText3={`${Number(getTotalCompletedOrders())} C.I`}
                // subText4={`${Number(getTotalPartialOrders())} P.I`}
                value={numberFormatter((Number(orderAnalytics?.total_paid_orders) + Number(orderAnalytics?.total_partial_orders)) || 0)}
              />
              
              <PageSummaryCard
                isLoading={isOrderAnalyticsLoading}
                title="Total Pending Orders"
                value={numberFormatter(orderAnalytics?.total_pending_orders || 0)}
              />
              
              <PageSummaryCard
                isLoading={isOrderAnalyticsLoading}
                title="Total Cancelled Orders"
                value={numberFormatter(orderAnalytics?.total_cancelled_orders || 0)}
                subText={`${Number(orderAnalytics?.total_returned_orders)} returned`}
              />
            </PageSummaryStyled>

            <SetUpLayoutStyled>

              <OrderSearchAndFilter
                state={debounceState}
                dispatch={debounceReducer}
                searchPlaceholder="Search by Id"
              />

              <Table bg headers={orderTable.headers} noCols={orderTable.headers.length}>
                {allGroupOrders.isQueryWithData && (
                  allGroupOrders?.data?.results.map((items, k) =>
                    <TableItemForGroupOrders
                      statusPosition={5}
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allGroupOrders.currentCount}
                      keys={orderTable.values}
                    />
                  )
                )}

                {allGroupOrders.isQueryWithNoData && (
                  <NoQueryData text="No sale record" />
                )}

                {allGroupOrders.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allGroupOrders.showPagination && (
                <TablePagination
                  query={allGroupOrders}
                  restructureFn={getOrderServiceStructure}
                  queryKey={orderQueryKey.main(allGroupOrders.queryValues)}
                />
              )}
            </SetUpLayoutStyled>
            {allGroupOrders.showPagination && (
              <TablePagination
                query={allGroupOrders}
                restructureFn={getOrderServiceStructure}
                queryKey={orderQueryKey.main(allGroupOrders.queryValues)}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Orders;
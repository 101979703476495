import {Fragment, useState} from "react";
import {OvaloopDashboardAnalyticsHeader, OverviewCard, Tab} from "../../../components";
import {ContainerStyled, DashboardStyled} from "../../../styles/DashboardStyles";
import {SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {BarIcon} from "../../../assets/icons";
import {useTheme} from "styled-components";
import {useQueryClient} from "react-query";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
  ovaloopPayAnalyticsQueryKey, useOvaloopPayAnalyticsByBusinessIdService,
} from "../../../hooks/useServices/useStaffBackendServices/useOvaloopPayAnalyticsServices";
import {getOvaloopPayAnalyticsService} from "../../../services/staffBackendServices/ovaloopPayAnalyticsServices";
import {useParams} from "react-router-dom";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {OvaloopSettlementLogs, OvaloopTransferLogs} from "../../dashboard/OvaloopPay";


const TABS = ["Transfer Logs", "Settlement Logs"]

const ViewOvaloopAnalyticsByBusiness = () => {
  const [activeTab, setActiveTab] = useState(TABS[0])
  const theme = useTheme()
  const { businessId } = useParams()
  const queryClient = useQueryClient()
  const { data: business } = useBusinessByIdQuery(businessId)

  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  const { data: ovaloopPayAnalytics } = useOvaloopPayAnalyticsByBusinessIdService({ business_id: businessId })
  
  
  const refetchQueries = (from_date, to_date) => {
    queryClient.fetchQuery({
      queryKey: ovaloopPayAnalyticsQueryKey.byBusiness(businessId),
      queryFn: () => getOvaloopPayAnalyticsService({ from_date, to_date, business_id: businessId })
    })
  }
  
  return (
    <Fragment>
      <OvaloopDashboardAnalyticsHeader title={`${business?.store_name || ""}`} refetchQueries={refetchQueries} />
  
      <DashboardStyled>
        <SpaceBetweenStyled>
          <OverviewCard
            icon={BarIcon}
            title="Total Transactions"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[0]?.total_transactions || 0)}
            bgColor={theme.overViewCard[3]}
          />
      
          <OverviewCard
            icon={BarIcon}
            title="Ovaloop Charges (Profit)"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[1]?.total_ovaloop_charge || 0)}
            bgColor={theme.overViewCard[2]}
          />
      
          <OverviewCard
            icon={BarIcon}
            title="Total Settled Amount"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[3]?.total_settlement || 0)}
            bgColor={theme.overViewCard[1]}
          />
      
          <OverviewCard
            icon={BarIcon}
            title="Total Pending Settlement"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[2]?.pending_settlement || 0)}
            bgColor={theme.overViewCard[0]}
          />
        </SpaceBetweenStyled>
      </DashboardStyled>
  
      <ContainerStyled>
        <br/>
        <SectionStyled>
          <Tab tabs={TABS} activeTab={activeTab} setActiveTab={setActiveTab} />
    
          {activeTab === "Transfer Logs" && <OvaloopTransferLogs />}
    
          {activeTab === "Settlement Logs" && <OvaloopSettlementLogs />}
        </SectionStyled>
      </ContainerStyled>
    </Fragment>
  )
}


export default ViewOvaloopAnalyticsByBusiness
import { useQuery } from "react-query"
import {
  getAllGroupOrderDebtsServices,
  getCustomerGroupOrderDebtsServices,
  getDebtorsServices
} from "../../../services/debtorsServices"
import useGlobalContext from "../../useContexts/useGlobalContext"
import debtorQueryKey from "./debtorQueryKey";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";

const useDebtorQuery = (searchValue) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: debtorQueryKey.mainList(searchValue),
    queryFn: () => getDebtorsServices(getBusinessId(), searchValue)
  })
}


export const useCustomerOrderDebtsQuery = (queryParam) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: debtorQueryKey.orderDebts(getAllObjectKeysWithValues(queryParam)),
    queryFn: () => getCustomerGroupOrderDebtsServices({ businessId: getBusinessId(), ...queryParam })
  })
}


export const useAllOrderDebtsQuery = (queryParam) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: debtorQueryKey.allDebts(getAllObjectKeysWithValues(queryParam)),
    queryFn: () => getAllGroupOrderDebtsServices({ business_id: getBusinessId(), ...queryParam })
  })
}


export { useDebtorQuery }
import {getBusinessIdFromStorage} from "../../../utils";
const businessId = getBusinessIdFromStorage()


const key = ["debtors", businessId]

const debtorQueryKey = {
	all: [...key],
	mainList: (searchValue) => [...key, searchValue || undefined],
	orderDebts: (queryParam) => [...key, "debtors", queryParam],
	allDebts: (queryParam) => [...key, "debts", queryParam]
}

export default debtorQueryKey
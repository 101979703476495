import {StoreCardStyled} from "../../styles/cardStyles";
import {Heading, Heading2} from "../../styles/textStyles";
import {setBusinessIdInStorage} from "../../utils";
import {useSetBusinessMutation} from "../../hooks/useServices/useStoreServices";


const StoreCard = ({store: {id, store_name}}) => {
  const setBusinessMutation = useSetBusinessMutation()
  
  const handleSelectStore = () => {
    setBusinessIdInStorage(id)
    setBusinessMutation.mutate(id)
  }

  return (
    <StoreCardStyled onClick={handleSelectStore}>
      <Heading2>
        {store_name[0].toUpperCase()}
      </Heading2>
      <Heading size={1.2} color="black">{store_name}</Heading>
    </StoreCardStyled>
  )
}

export default StoreCard
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, CheckBox, Form, Input, TextArea} from "../../index";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {useFormikContext} from "formik";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useEffect, useState} from "react";
import {SplitOrPartialPaymentStyled} from "../../../styles/formStyles";
import lodash from "lodash";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const RefundBalanceModal = ({onClose, handleSubmit, totalPayable, isMutationLoading, pay_log = []}) => {
  const {toast} = useGlobalContext()
  const {currencyFormatter} = useDataTypeFormatter()
  
  const handleSubmitFn = (values) => {
    let totalRefundValue = 0
    const allAvailableLogIds = pay_log.map(log => log.id)
    
    const payload = []
    for (const logId of allAvailableLogIds) {
      if (Object.hasOwn(values, `${logId}_value`) && Number(values[`${logId}_value`]) > 0) {
        const refund_value = Number(values[`${logId}_value`])
        totalRefundValue += refund_value
        payload.push({
          id: logId,
          refund_value,
          remark: values.remark,
        })
      }
    }
    
    if (totalRefundValue === 0) {
      toast.error(`Please input an amount to refund`)
      return
    }
    
    if (totalRefundValue > totalPayable) {
      toast.error(`You can not refund more than available. ${currencyFormatter(totalRefundValue)} is more than ${currencyFormatter(totalPayable)}`)
      return
    }
    
    handleSubmit(payload)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Refund</Heading>
        
        <Form values={{}} onSubmit={handleSubmitFn}>
          <SplitOrPartialPayment totalPayable={totalPayable} pay_log={pay_log}/>
          <FlexStyled>
            <Button type="submit" text="Pay" isLoading={isMutationLoading}/>
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default RefundBalanceModal


const SplitOrPartialPayment = ({totalPayable, pay_log = []}) => {
  const {setFieldValue} = useFormikContext()
  const {currencyFormatter} = useDataTypeFormatter()
  const [paymentValue, setPaymentValue] = useState({})
  const [showPayment, setShowPayment] = useState({})
  
  const handleShowPayment = (value, type) => {
    setShowPayment(prevState => ({
      ...prevState,
      [type]: value
    }))
    
    if (!value) {
      setFieldValue(`${type}_value`, "")
      setFieldValue(`${type}_value_format`, "")
      setPaymentValue(prevState => ({...prevState, [`${type}_value`]: 0}))
    }
  }
  
  const handleChange = (value, type) => {
    setPaymentValue(prevState => ({
      ...prevState,
      [type]: value
    }))
  }
  
  const getAmountPaying = () => {
    return Object.values(paymentValue).reduce((prev, curr) => {
      if (Number(Number(curr).toFixed(2))) {
        return prev + Number(Number(curr).toFixed(2))
      }
      
      return prev
    }, 0)
  }
  
  useEffect(() => {
    const amountPaying = getAmountPaying()
    setFieldValue("splitOrPartialPaymentAmount", amountPaying)
  }, [paymentValue])
  
  return (
    <SplitOrPartialPaymentStyled>
      {pay_log.map(log => (
        <FlexColumnStyled key={log.id}>
          <CheckBox
            optional
            afterCheck={handleShowPayment}
            name={log.id}
            labelProp={`${lodash.capitalize(log.method)} (${currencyFormatter(log.amount)})`}
          />
          
          {showPayment?.[log.id] && (
            <Input
              label={`${lodash.capitalize(log.method)} (${currencyFormatter(log.amount)})`}
              onChange={handleChange}
              noBottomMargin
              className="fullWidth"
              name={`${log.id}_value`}
              type="number"
              placeholder="Enter refund amount"
            />
          )}
        </FlexColumnStyled>
      ))}
      
      <br/>
      <TextArea name="remark" placeholder="Reason for refund" rows={2}/>
      <SpaceBetweenStyled className="summary">
        <Heading color="secondary">Amount to refund: </Heading>
        <Heading color="black">{currencyFormatter(getAmountPaying())}</Heading>
      </SpaceBetweenStyled>
      
      <SpaceBetweenStyled className="summary">
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "secondary")}>
          Amount Remaining:
        </Heading>
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "black")}>
          {currencyFormatter(Number(totalPayable) - getAmountPaying())}
        </Heading>
      </SpaceBetweenStyled>
      
      <SpaceBetweenStyled>
        <Heading color="secondary">Total Refundable: </Heading>
        <Heading color="black">{currencyFormatter(totalPayable)}</Heading>
      </SpaceBetweenStyled>
    </SplitOrPartialPaymentStyled>
  )
}
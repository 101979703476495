import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
const businessId = getBusinessIdFromStorage()

const key = "staffs"
const withBusinessId = [key, businessId]

const staffQueryKey = {
	all: [key],
	mainListKey: [...withBusinessId, "main"],
	byId: (id) => [...withBusinessId, "staff", id?.toString() || id],
	analyticsById: (id, query = {}) => [...withBusinessId, "staff", id?.toString() || id, "analytics", getAllObjectKeysWithValues(query)],
	mainList: (searchValue) => [...withBusinessId, "main", searchValue || undefined],
}

export default staffQueryKey
import {useQuery} from "react-query";
import queryKeys from "../queryKeys";
import {
	getBusinessProductAnalyticsByService,
	getCustomerDashboardAnalyticsByIdService,
	getDashboardAnalyticsByBusinessIdService,
	getExpensesAnalyticsByBusinessIdService,
	getOrderAnalyticsByBusinessIdService,
	getProductAnalyticsByIdService, getPurchaseOrderAnalyticsByBusinessIdService,
	getStaffDashboardAnalyticsByIdService,
	getSubscriptionAnalyticsByBusinessIdService
} from "../../../services/analyticsServices";
import {getBusinessIdFromStorage, scrollToTop} from "../../../utils";
import {useParams} from "react-router-dom";
import useGlobalContext from "../../useContexts/useGlobalContext";
import customerQueryKey from "../useCustomerServices/customQueryKey";
import orderQueryKey from "../useOrderServices/orderQueryKey";
import staffQueryKey from "../useStaffServices/staffQueryKey";
import expensesQueryKey from "../useExpenseServices/expensesQueryKey";
import analyticsQueryKeys from "./analyticsQueryKeys";
import {purchaseOrderQueryKey} from "../usePurchaseOrder";


const useDashboardAnalyticsByBusinessIdService = (from_date = "", to_date = "") => {
	scrollToTop()
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery({
		enabled: !!businessId,
		queryKey: orderQueryKey.dashboardAnalytics({ from_date, to_date }),
		queryFn: () => getDashboardAnalyticsByBusinessIdService(businessId, from_date, to_date)
	})
}

const useStaffDashboardAnalyticsByIdService = (staffId, from_date, to_date) => {
	const { getBusinessId } = useGlobalContext()
	const business_id = getBusinessId()

	return useQuery({
		queryKey: staffQueryKey.analyticsById(staffId, { from_date, to_date }),
		queryFn: () => getStaffDashboardAnalyticsByIdService({ business_id, staffId, from_date, to_date })
	})
}

const useProductAnalyticsByIdService = () => {
	const { getBusinessId } = useGlobalContext()

	const { productId } = useParams()
	const businessId = getBusinessId()

	return useQuery(
		[queryKeys.DASHBOARD_ANALYTICS, productId],
		() => getProductAnalyticsByIdService(businessId, productId)
	)
}

const useBusinessProductAnalyticsService = (queryParams = {}) => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery(
		[queryKeys.DASHBOARD_ANALYTICS, businessId, queryParams],
		() => getBusinessProductAnalyticsByService({ business_id: businessId, ...queryParams })
	)
}

const useCustomerDashboardAnalyticsByIdService = (queryParams = {}) => {
	const { customerId: customer_id } = useParams()
	
	return useQuery({
		queryKey: customerQueryKey.byIdAnalytics(customer_id, queryParams),
		queryFn: () => getCustomerDashboardAnalyticsByIdService({ customer_id, ...queryParams })
	})
}

const useExpensesAnalyticsService = (queryParams = {}) => {
	const { getBusinessId } = useGlobalContext()
	const business_id = getBusinessId()

	return useQuery({
		queryKey: expensesQueryKey.analytics(queryParams),
		queryFn: () => getExpensesAnalyticsByBusinessIdService({business_id, ...queryParams})
	})
}

export const usePurchaseOrderAnalyticsService = (queryParams = {}) => {
	const { getBusinessId } = useGlobalContext()
	const business_id = getBusinessId()
	
	return useQuery({
		queryKey: purchaseOrderQueryKey.analytics(queryParams),
		queryFn: () => getPurchaseOrderAnalyticsByBusinessIdService({business_id, ...queryParams})
	})
}


export const useOrderAnalyticsService = (from_date = "", to_date = "") => {
	const { getBusinessId } = useGlobalContext()
	const business_id = getBusinessId()
	
	return useQuery({
		enabled: !!business_id,
		queryKey: orderQueryKey.orderAnalytics({ from_date, to_date }),
		queryFn: () => getOrderAnalyticsByBusinessIdService({business_id, from_date, to_date})
	})
}

export const useAccountAnalytics = () => {
	const getAccountId = getBusinessIdFromStorage()
	try {
		const { data } = new Promise(() => {})
		
		if(typeof JSON.parse(data) !== null) {
			return getAccountId;
		}

		return [...[]].map(() => {
		
		})
	} catch (e) {
		// value cannot be parsed to a json, invalid accountId...
		throw e?.response?.data || ""
	}
}

export const useSubscriptionAnalyticsService = (from_date = "", to_date = "") => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()
	
	return useQuery({
		enabled: !!businessId,
		queryKey: analyticsQueryKeys.subscription(from_date, to_date),
		queryFn: () => getSubscriptionAnalyticsByBusinessIdService({businessId, from_date, to_date})
	})
}


export {
	useExpensesAnalyticsService,
	useDashboardAnalyticsByBusinessIdService,
	useCustomerDashboardAnalyticsByIdService,
	useProductAnalyticsByIdService,
	useStaffDashboardAnalyticsByIdService, useBusinessProductAnalyticsService
}
import styled from "styled-components";
import {FlexStyled, SpaceBetweenStyled} from "./utilStyles";
import {Paragraph} from "./textStyles";

export const KitchenLayoutStyled = styled("div")`
  padding: 2rem 4rem;
  width: calc(100% - 8rem);
  
  > ${FlexStyled} {
    align-items: flex-start;
    gap: 2rem;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    padding: 1.5rem 0;
    margin-top: -4.5rem;
    width: 100%;
  }
`


export const KitchenOrderCardStyled = styled("div")`
  position: relative;
  background: white;
  border-radius: .3rem;
  width: calc(100% / 4 - 2rem - 1rem);
  
  ${SpaceBetweenStyled}:first-of-type {
    padding: .3rem 1rem .4rem;
    background: rgba(211, 211, 211, 0.39);
  }

  ${FlexStyled} {
    padding: 1.5rem 1rem 0;
  }

  ${Paragraph} {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    white-space: normal;
  }

  ${SpaceBetweenStyled}:last-of-type {
    padding: .3rem 1rem;
    margin-top: 1.8rem;
    background: ${props => props.theme[props.$statusColor]};
    
    .done_button {
      cursor: pointer;
      border-radius: .3rem;
      padding: .15rem 1rem;
      background: ${props => props.theme.darkGray};
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 100%;
  }
`
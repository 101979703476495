import { CreateStoreModalStyled, ModalLayoutStyled } from "../../../styles/modalStyles"
import {ChoosePlansStyled, FlexRowStyled} from "../../../styles/subscriptionCardStyles";
import PlanCard from "../../Card/PlanCard";
import {useSubscriptionPackagesQuery} from "../../../hooks/useServices/useSubscriptionServices";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import SubscriptionToggle from "../../Utils/SubscriptionToggle";
import {useState} from "react";
import {AllCenterStyled} from "../../../styles/utilStyles";


const ChooseSubscriptionModal = ({ onClose, setSubscription }) => {
  const [period, setPeriod] = useState("year")
  const { data: packages } = useSubscriptionPackagesQuery()

  const handlePeriod = (isYear) => {
    if(!isYear) {
      setPeriod("month")
      return
    }
    setPeriod("year")
  }

  const filterPackages = () => {
    return packages?.filter(subPackage => subPackage.amount > 10)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={100}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Change Subscription</Heading>

        <br/>
        <AllCenterStyled>
          <FlexRowStyled>
            <Paragraph color="black" $size={1.4}>Monthly</Paragraph>
            <SubscriptionToggle updateFn={handlePeriod} />
            <Paragraph color="black" $size={1.4}>Annually</Paragraph>
          </FlexRowStyled>
        </AllCenterStyled>

        <br/><br/>
        <ChoosePlansStyled>
          {filterPackages()?.map(pack => <PlanCard selectSubscription={setSubscription} period={period} key={pack.id} data={pack} />)}
        </ChoosePlansStyled>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}


export default ChooseSubscriptionModal
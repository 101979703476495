import {useQuery} from "react-query";
import {
  getOvaloopPayAnalyticsService
} from "../../../../services/staffBackendServices/ovaloopPayAnalyticsServices";


export const ovaloopPayAnalyticsQueryKey = {
  all: ["ovaloop_pay_analytics"],
  dashboard: ["ovaloop_pay_analytics", "dashboard"],
  byBusiness: (businessId) => ["ovaloop_pay_analytics", "business", businessId]
}


export const useOvaloopPayAnalyticsService = (queryParams = {}) => {
  return useQuery({
    queryKey: ovaloopPayAnalyticsQueryKey.dashboard,
    queryFn: () => getOvaloopPayAnalyticsService(queryParams)
  })
}

export const useOvaloopPayAnalyticsByBusinessIdService = (queryParams = {}) => {
  return useQuery({
    queryKey: ovaloopPayAnalyticsQueryKey.byBusiness(queryParams?.business_id),
    queryFn: () => getOvaloopPayAnalyticsService(queryParams)
  })
}
import {useContext, useEffect, useRef, useState} from "react";
import {Field, useFormikContext} from "formik";
import {Paragraph} from "../../styles/textStyles";
import {NumericFormat} from 'react-number-format';
import {InputStyled} from "../../styles/formStyles";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {FormProvider} from "./Form";
import {useBusinessByIdQuery} from "../../hooks/useServices/useStoreServices";
import countries from "../../data/location/countries"


const Input = (
  {
    className, name, type, label, placeholder, icon, bgDark, rightIcon, disabled,
    error, optional, noBottomMargin, SideInfo, onChange, onBlur = () => {
  }, clearCondition, noLabel, inputRef, isCurrency, formatNumber, allowScannerInput, errorColor
  }
) => {
  
  const {data: business} = useBusinessByIdQuery()
  const {allFieldsNotRequired} = useContext(FormProvider)
  const {setFieldValue, values} = useFormikContext()
  const [initialMount, setInitialMount] = useState(true)
  const isNumberFormatter = ((type === "number") && formatNumber)
  
  const searchInputRef = useRef()
  
  useEffect(() => {
    if (allowScannerInput || inputRef) {
      inputRef.current = searchInputRef.current
      searchInputRef?.current?.focus()
    }
  }, [searchInputRef.current])
  
  
  useEffect(() => {
    // do not clear the states when the component mounts the first time
    // This is to avoid the states from clearing when the component mounts with already populated values i.e. Edit Form
    if (!!clearCondition && !initialMount) {
      setFieldValue(name, "")
      setFieldValue(`${name}_format`, "")
    }
    
    if (initialMount) {
      setInitialMount(false)
    }
  }, [values[clearCondition]])

  const handleChange = (e) => {
    const value = e.target.value
    setFieldValue(name, value)
    onChange?.(value, name, values, setFieldValue)
  }

  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }
  
  return (
    <InputStyled $noBottomMargin={noBottomMargin} className={className} bgDark={bgDark || disabled} rightIcon={rightIcon}>
      <SpaceBetweenStyled>
        {!noLabel && (
          <label htmlFor={name}>
            {label} {!!optional && "(Optional)"}
          </label>
        )}
        {!!SideInfo && <SideInfo/>}
      </SpaceBetweenStyled>
      {!!rightIcon && <i className="right_icon">{rightIcon}</i>}
      {!!icon && <i>{icon}</i>}
  
      {isNumberFormatter && (
        <NumericFormat
          value={!Number(values[name]) ? "" : Number(values[name])}
          id={name}
          name={name}
          onBlur={onBlur}
          thousandSeparator
          disabled={disabled}
          required={isRequired()}
          placeholder={placeholder}
          defaultValue={Number(values[name])}
          onValueChange={(values) => handleChange({target: {value: values.value}})}
          prefix={(isCurrency && !!business?.details?.currency_field) ? countries?.[business?.details?.currency_field]?.currency : ""}
        />
      )}
  
      {!isNumberFormatter && (
        <Field
          onBlur={onBlur}
          autoComplete="new-password"
          onChange={handleChange}
          required={isRequired()}
          disabled={disabled}
          id={name}
          type={type || "text"}
          name={name}
          innerRef={searchInputRef}
          placeholder={placeholder}
        />
      )}
  
      {!!error && <Paragraph noTop color={errorColor || "warning"}>{error}</Paragraph>}
    </InputStyled>
  )
}

export default Input
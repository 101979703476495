import {useDesktopSyncOrdersMutation} from "../useFetchAllDesktopDataFromServer/useDesktopServices";
import {useEffect, useRef} from "react";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useDesktopBusinessSyncStatusFromLocalDB} from "../useDesktopServicesFromLocalDB";


const useSyncDesktopOfflineData = () => {
  const {toast} = useGlobalContext()
  
  const syncingActive = useRef(false)
  const isManualSyncClickedRef = useRef(false)
  const isMoreOrderAvailableToSync = useRef(false)
  const {data: businessSyncStatus} = useDesktopBusinessSyncStatusFromLocalDB()
  
  const sync = useDesktopSyncOrdersMutation({
    syncingActive, isMoreOrderAvailableToSync, isManualSyncClickedRef
  })
  
  // When DB has deleted synced orders, allow app to sync next batch or allow the next sync interval to pass through
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
      
      const onDeleteOrder = () => {
        syncingActive.current = false
        
        // if there is more available order to sync from db, do it immediately, don't wait for the sync interval
        if (isMoreOrderAvailableToSync.current) {
          ipcRenderer.send(IPC_EVENTS.GET_ALL_OFFLINE_ORDERS_FOR_SYNCING)
          return
        }
  
        toast.info("All orders synced to the cloud", "no_offline_data")
        isManualSyncClickedRef.current = false
      }
  
      ipcRenderer.on(IPC_EVENTS.DELETE_ALREADY_SYNCED_ORDERS, onDeleteOrder)
  
      return () => {
        ipcRenderer.removeListener(IPC_EVENTS.DELETE_ALREADY_SYNCED_ORDERS, onDeleteOrder)
      }
    }
  }, [])
  
  
  // sync orders when there is available order in db and no syncing is currently running
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window?.require("electron")
  
      const handleSync = (_, data) => {
        if (data.groupOrders.length > 0 && !syncingActive.current && !sync.mutation.isLoading) {
          syncingActive.current = true
          isMoreOrderAvailableToSync.current = data.isMoreOrdersAvailableToSync
      
          sync.mutation.mutate(data)
        }
    
        if (data.groupOrders.length === 0 && isManualSyncClickedRef.current) {
          toast.info("No offline data to sync", "no_offline_data", 3000)
          isManualSyncClickedRef.current = false
        }
      }
  
      ipcRenderer.on(IPC_EVENTS.GET_ALL_OFFLINE_ORDERS_FOR_SYNCING, handleSync)
  
      return () => {
        ipcRenderer.removeListener(IPC_EVENTS.GET_ALL_OFFLINE_ORDERS_FOR_SYNCING, handleSync);
      }
    }
  }, [])
  
  
  // automatically sync offline orders every 3 min when no syncing is/will run
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
  
      const handleSyncOfflineOrders = () => {
        if (Object.hasOwn(businessSyncStatus || {}, "auto_sync") && !businessSyncStatus.auto_sync) return
  
        if (!syncingActive.current && !sync.mutation.isLoading && !isMoreOrderAvailableToSync.current) {
          ipcRenderer.send(IPC_EVENTS.GET_ALL_OFFLINE_ORDERS_FOR_SYNCING)
        }
      }
  
      ipcRenderer.on(IPC_EVENTS.SYNC_OFFLINE_ORDERS_INTERVAL, handleSyncOfflineOrders)
  
      return () => {
        ipcRenderer.removeListener(IPC_EVENTS.SYNC_OFFLINE_ORDERS_INTERVAL, handleSyncOfflineOrders)
      }
    }
  }, [businessSyncStatus])
  
  
  // allow users to sync all offline orders manually
  const onManualSync = () => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
      
      if (!syncingActive.current && !sync.mutation.isLoading && !isMoreOrderAvailableToSync.current) {
        isManualSyncClickedRef.current = true
        ipcRenderer.send(IPC_EVENTS.GET_ALL_OFFLINE_ORDERS_FOR_SYNCING)
      }
    }
  }
  
  return {
    onManualSync,
    isOfflineDataSyncing: (syncingActive.current && sync.mutation.isLoading) || isMoreOrderAvailableToSync.current
  }
}

export default useSyncDesktopOfflineData
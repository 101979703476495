import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {Fragment, useState} from "react";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Chips, PageHeader, Table, TableItemWithStatus, TablePagination, ViewSettlementModal} from "../../../components";
import DateFilter from "../../../components/Utils/DateFilter";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
  settlementQueryKeys,
  useAllSettlementRequestLogsQuery, useApproveSettlementMutation
} from "../../../hooks/useServices/useStaffBackendServices/useSettlementServices";
import {useModal} from "../../../hooks";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import {settlementLogsRestructure} from "../../../services/ovaloopPaymentServices/ovaloopPaymentRestrucuture";


const chips = [{ name: "All", value: "" }, { name: "Pending", value: "pending" }, { name: "Confirmed", value: "confirmed" }, { name: "Rejected", value: "rejected" }]

const Settlements = () => {
  const { debounceState } = useQueryDebounce()
  const { settlementLogTable } = tableHeadersAndValueKeys
  const [transactionType, setTransactionType] = useState("")
  const [isSettlePreviewOpen, setIsSettlePreviewOpen] = useModal()
  const [previewSettlementValues, setPreviewSettlementValues] = useState({})
  
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  const { ...settlementRequestLogs } = useSearchQuery(debounceState, useAllSettlementRequestLogsQuery.bind(this, { status: transactionType, from_date, to_date }))
  
  const approveSettlementMutation = useApproveSettlementMutation({ successFn: setIsSettlePreviewOpen })
  
  const handleViewSettlement = (settlement) => {
    setPreviewSettlementValues(settlement)
    setIsSettlePreviewOpen()
  }
  
  const handleSettlementApproval = (settlement, status) => {
    const body = { settlement_request_id: settlement.id, status }
    approveSettlementMutation.mutate(body)
  }
  
  return (
    <Fragment>
      <PageHeader title="Settlement Requests" />
      {isSettlePreviewOpen && (
        <ViewSettlementModal
          handleSubmit={handleSettlementApproval}
          mutation={approveSettlementMutation}
          onClose={setIsSettlePreviewOpen}
          formValues={previewSettlementValues}
        />
      )}
      
      <SpaceBetweenStyled>
        <FlexStyled>
          {chips.map(item => (
            <Chips
              key={item.name}
              title={item.name}
              active={item.value === transactionType}
              onClick={() => setTransactionType(item.value)}
            />
          ))}
        </FlexStyled>
        
        <DateFilter labelBackground="background" placeholder="Filter by date" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled> <br/>
      
      <SetUpLayoutStyled>
        <Table headers={settlementLogTable.headers} noCols={settlementLogTable.headers.length}>
          {settlementRequestLogs.isQueryWithData && (
            settlementRequestLogs?.data?.results.map((log, k) => (
              <TableItemWithStatus
                count={k}
                onView={handleViewSettlement}
                pageCount={settlementRequestLogs.currentCount}
                key={k}
                data={log}
                statusPosition={4}
                keys={settlementLogTable.values}
              />
            ))
          )}
    
          {settlementRequestLogs.isQueryWithNoData && (
            <NoQueryData text="No settlement log" />
          )}
    
          {settlementRequestLogs.isQueryLoading && (
            <TableSkeleton />
          )}
        </Table>
  
        {settlementRequestLogs.showPagination && (
          <TablePagination
            query={settlementRequestLogs}
            restructureFn={settlementLogsRestructure}
            queryKey={settlementQueryKeys.logs({ from_date, to_date, status: transactionType })}
          />
        )}
      </SetUpLayoutStyled>
  
      {settlementRequestLogs.showPagination && (
        <TablePagination
          query={settlementRequestLogs}
          restructureFn={settlementLogsRestructure}
          queryKey={settlementQueryKeys.logs({ from_date, to_date, status: transactionType })}
        />
      )}
    </Fragment>
  )
}


export default Settlements
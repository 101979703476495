import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createBusinessSubscriptionService,
  deleteBusinessSubscriptionService,
  editBusinessSubscriptionService,
  getBusinessSubscriptionService
} from "../../../services/businessSuscriptionServices";
import {scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import useGlobalContext from "../../useContexts/useGlobalContext";
import modalNames from "../../useModalConnector/modalNames";
import {useClearModalConnector} from "../../useModalConnector";
import {errorTypes} from "../../../utils/logger";
import useErrorLogger from "../../useUtils/useErrorLogger";


const useBusinessSubscriptionQuery = (searchValue) => {
  scrollToTop()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    queryKey: [queryKeys.BUSINESS_SUBSCRIPTION, searchValue],
    queryFn: getBusinessSubscriptionService.bind(this, getBusinessId(), searchValue)
  })
}

const useCreateBusinessSubscriptionMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const clearModalConnection = useClearModalConnector()
  const { toast, getBusinessId, globalState: { modalConnector }, navigate } = useGlobalContext()
  

  return useMutation({
    mutationFn: data => {
      return createBusinessSubscriptionService({ ...data, business: getBusinessId() })
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Subscription Created!!", "created")
      queryClient.invalidateQueries({queryKey: queryKeys.BUSINESS_SUBSCRIPTION})
    
      if (modalConnector.toModalName === modalNames.CREATE_SUBSCRIPTION) {
        clearModalConnection()
        navigate("/dashboard/business_subscription/")
      }
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createBusinessCustomerSubscription,
      })
    }
  })
}

const useEditBusinessSubscriptionMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editBusinessSubscriptionService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!", "edit")
      queryClient.invalidateQueries({queryKey: queryKeys.BUSINESS_SUBSCRIPTION})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateBusinessCustomerSubscription,
      })
    }
  })
}

const useDeleteBusinessSubscriptionMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: subscriptionId => {
      return deleteBusinessSubscriptionService(subscriptionId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!", "delete")
      queryClient.invalidateQueries({ queryKey: queryKeys.BUSINESS_SUBSCRIPTION })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useBusinessSubscriptionQuery, useCreateBusinessSubscriptionMutation, useEditBusinessSubscriptionMutation, useDeleteBusinessSubscriptionMutation }
export const getAllStaffRestructure = (data) => {
  const results = data?.results?.map(item => {
    return {
      ...item,
      designation: item?.designation || "Client Admin",
      full_name: `${item?.first_name} ${item?.last_name}`,
      business_details_name: item?.business_details?.name
    }
  })
  
  return { ...data, results }
}
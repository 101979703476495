import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getCategoriesService = async (query = {}) => {
  try {
    const url = handleQueryParams('/category', query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createCategoryService = async (body) => {
  try {
    const { data } = await axios.post("/category/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editCategoryService = async (categoryId, body) => {
  try {
    const { data } = await axios.patch(`/category/${categoryId}`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteCategoryService = async (categoryId) => {
  try {
    const { data } = await axios.delete(`/category/${categoryId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getCategoriesService, createCategoryService, editCategoryService, deleteCategoryService }
const sellScreenLayouts = [
  { name: "Product Based", id: "product_based" },
  { name: "Category Based", id: "category_based" }
]

export default sellScreenLayouts


export const sellScreenLayoutsEnum = {
  product_based: "Product Based",
  category_based: "Category Based"
}
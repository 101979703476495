import {Route, Routes} from "react-router-dom"
import SetUpStore from "./on-boarding/SetUpStore";
import NewSubscription from "./NewSubscription";

import {
  EmailAndPassword,
  ForgotPassword,
  LoginRoute,
  RegisterBusinessInfo,
  RegisterPersonalInfo,
  RegisterRoute,
  RegisterVerifyEmail,
  ValidateForgotPassword
} from "./auth"
import {
  ActivityLogs,
  AddOns,
  AllStores,
  Banks,
  BusinessSettings,
  BusinessSubscription,
  BusinessSubscriptionPlan,
  CartMobilePage,
  Category,
  CreatePurchaseOrder,
  Customer,
  CustomPaymentMethod,
  Dashboard,
  DashboardLayout,
  Debtor,
  DeliveryRate,
  DesktopSetting,
  Discount,
  ExpenseCategory,
  Expenses,
  FaultyProductLogs,
  FaultyProducts,
  InventoryLogs,
  Invoice,
  Items,
  NestedSubCategory,
  Orders,
  OrdersWithDueDate,
  OvaloopPay,
  PackageExpired,
  PackageItems,
  Password,
  ProducibleProducts,
  ProductionDistributionLogs,
  ProductionProcess,
  ProductionStage,
  ProductionUnitMeasurement,
  Profile,
  PurchasedSubscriptions,
  PurchaseOrder,
  RawMaterials,
  ReceiptSettings,
  Report,
  RequestRawMaterial,
  Sell,
  SellDashboardLayout,
  SentItems,
  Settings,
  Settlement,
  SetUpTable,
  SingleCustomer,
  SingleOrder,
  SingleProduct,
  SingleStaff,
  SingleSupplier,
  Staff,
  StaffRoles,
  StaffWip,
  StoreFrontSettings,
  Subscription,
  Supplier,
  TablePage,
  Tax,
  Tips,
  UserNotifications,
  VerifyEmail,
  ViewAddon,
  ViewAllOrderDebts,
  ViewCategory,
  ViewCustomerOrderDebts,
  ViewProductionProcess,
  ViewPurchaseOrder,
  ViewSubscriptionPurchase,
  Wip
} from "./dashboard"
import SelectStore from "./SelectStore";
import {useRoutePermission} from "../hooks/usePermissions";
import {Fragment, useEffect} from "react";
import useGlobalContext from "../hooks/useContexts/useGlobalContext";
import StaffDashboard from "./dashboard/staff/StaffDashboard";
import {
  clearAllDataInStorage,
  clearBusinessIdFromStorage,
  getAuthTokenFromStorage,
  getBusinessIdFromStorage,
  setAuthTokenInStorage,
  setBusinessIdInStorage
} from "../utils";
import {useUserProfile} from "../hooks/useServices/userHooks";
import ExpiryProducts from "./dashboard/inventory/ExpiryProducts";
import ProductWithContext from "./dashboard/inventory/Product/ProductWithContext";
import StaffBackendLayout from "./StaffBackend/StaffBackendLayout";
import {
  Accounts,
  AccountSubscription,
  AllUsersForStaffBackend,
  KYC,
  OvaloopPayAnalytics,
  OvaloopPayTransactions,
  ReferralsForStaffBackend,
  RequestDemo,
  Settlements,
  StaffBackendHome,
  StaffsForStaffBackend,
  ViewAccountBusinesses,
  ViewOvaloopAnalyticsByBusiness
} from "./StaffBackend";
import {useBusinessByIdQuery} from "../hooks/useServices/useStoreServices";
import {
  StoreFrontLayout,
  StoreFrontProductScreen,
  StoreFrontSalesHistoryScreen,
  StoreFrontViewProductScreen
} from "./StoreFront";
import env from "../utils/env";
import {KitchenView, PriceCheckerMode} from "./FullScreenMode";
import useFetchAllDesktopDataFromServer from "../hooks/useDesktop/useFetchAllDesktopDataFromServer";


const Index = () => {
  const HomePage = () => {
    const {navigate} = useGlobalContext()
    const {data: userProfile, isSuccess: isUserProfileSuccess, error} = useUserProfile()
    const {data: business, isSuccess: isBusinessSuccess} = useBusinessByIdQuery()

    useEffect(() => {
      const token = getAuthTokenFromStorage()
      const businessId = getBusinessIdFromStorage()

      if(token && businessId && isUserProfileSuccess) {
        if (userProfile?.[0]?.user_type === "client_admin") {
          navigate("/dashboard")
        }
  
        if (userProfile?.[0]?.user_type === "client") {
          navigate("/dashboard/staff_dashboard")
        }
  
        if (userProfile?.[0]?.user_type === "staff") {
          clearBusinessIdFromStorage()
          navigate("/staff_backend")
        }
  
        return
      }
  
      // if there is no token or businessId but there is in the local DB
      if(userProfile?.[0]?.token && business?.id) {
        setAuthTokenInStorage(userProfile?.[0]?.token)
        setBusinessIdInStorage(business?.id)
  
        if (userProfile?.[0]?.user_type === "client_admin") {
          navigate("/dashboard")
          return
        }
  
        if (userProfile?.[0]?.user_type === "client") {
          navigate("/dashboard/staff_dashboard")
          return
        }
  
        if (userProfile?.[0]?.user_type === "staff") {
          clearBusinessIdFromStorage()
          navigate("/staff_backend")
          return
        }
      }

      if ((!token || !businessId) && !userProfile?.[0]?.token) {
        clearAllDataInStorage()
        navigate("/login")
        return
      }

      if (!!error) {
        clearAllDataInStorage()
        navigate("/login")
      }
      
    }, [isUserProfileSuccess, isBusinessSuccess])
    
    return (
      <></>
    )
  }
  
  useRoutePermission()
  useFetchAllDesktopDataFromServer()
  
  return (
    <Routes>
      
      {/*THESE ROUTES ARE FOR THE STORE FRONT*/}
      {window.location.origin === env.STORE_FRONT_URL && (
        <Route path="/:storeName" element={<StoreFrontLayout />}>
          {/*<Route path="/:storeName" element={<StoreFrontCategoryScreen />}/>*/}
          <Route path="/:storeName" element={<StoreFrontProductScreen />}/>
          <Route path="/:storeName/products" element={<StoreFrontProductScreen />}/>
          <Route path="/:storeName/products/:productId" element={<StoreFrontViewProductScreen />}/>
          <Route path="/:storeName/sales_history" element={<StoreFrontSalesHistoryScreen />}/>
        </Route>
      )}
  
      {window.location.origin !== env.STORE_FRONT_URL && (
        <Fragment>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/login" element={<LoginRoute/>}/>
          <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
          <Route path="/auth/validate-forgot-password" element={<ValidateForgotPassword/>}/>
  
          {/* Registration Routes */}
          <Route path="/register" element={<RegisterRoute/>}/>
          <Route path="/register/:packageId/:period" element={<RegisterRoute/>}>
            <Route path="/register/:packageId/:period" element={<EmailAndPassword/>}/>
            <Route path="/register/:packageId/:period/person_info" element={<RegisterPersonalInfo/>}/>
            <Route path="/register/:packageId/:period/business_info" element={<RegisterBusinessInfo/>}/>
            <Route path="/register/:packageId/:period/verify_email" element={<RegisterVerifyEmail/>}/>
          </Route>
  
          <Route path="/dashboard_sell" element={<SellDashboardLayout/>}>
            <Route path="/dashboard_sell" element={<Sell/>}/>
            <Route path="/dashboard_sell/cart" element={<CartMobilePage/>}/>
          </Route>
  
          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<DashboardLayout/>}>
            <Route path="/dashboard/package_expired" element={<PackageExpired/>}/>
    
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/dashboard/wip" element={<Wip/>}/>
            <Route path="/dashboard/tips" element={<Tips/>}/>
            <Route path="/dashboard/wallet" element={<OvaloopPay/>}/>
            <Route path="/dashboard/expenses/" element={<Expenses/>}/>
            <Route path="/dashboard/report" element={<Report/>}/>
            <Route path="/dashboard/orders/" element={<Orders/>}/>
            <Route path="/dashboard/invoice/" element={<Invoice/>}/>
            <Route path="/dashboard/staff" element={<Staff/>}/>
            <Route path="/dashboard/table" element={<TablePage/>}/>
            <Route path="/dashboard/wip/:staffId" element={<StaffWip/>}/>
            <Route path="/dashboard/orders/:groupOrderId" element={<SingleOrder/>}/>
            <Route path="/dashboard/staff/:staffId/" element={<SingleStaff/>}/>
            <Route path="/dashboard/staff_dashboard" element={<StaffDashboard/>}/>
            <Route path="/dashboard/orders-with-due-date/" element={<OrdersWithDueDate/>}/>
  
            {/* SET-UP ROUTES */}
            <Route path="/dashboard/set-up/all-stores/" element={<AllStores/>}/>
            <Route path="/dashboard/set-up/categories/" element={<Category/>}/>
            <Route path="/dashboard/set-up/activity-logs/" element={<ActivityLogs/>}/>
            <Route path="/dashboard/set-up/tax" element={<Tax/>}/>
            <Route path="/dashboard/set-up/add-ons/" element={<AddOns/>}/>
            <Route path="/dashboard/set-up/banks/" element={<Banks/>}/>
            <Route path="/dashboard/set-up/staff-roles/" element={<StaffRoles/>}/>
            <Route path="/dashboard/set-up/discount" element={<Discount/>}/>
            <Route path="/dashboard/set-up/expense_category" element={<ExpenseCategory />}/>
            <Route path="/dashboard/set-up/table" element={<SetUpTable/>}/>
            <Route path="/dashboard/set-up/unit-measurement/" element={<ProductionUnitMeasurement />}/>
            <Route path="/dashboard/set-up/delivery-rate/" element={<DeliveryRate/>}/>
            <Route path="/dashboard/set-up/subscriptions/" element={<BusinessSubscriptionPlan/>}/>
            <Route path="/dashboard/set-up/custom-payment-method/" element={<CustomPaymentMethod/>}/>
            <Route path="/dashboard/set-up/categories/:categoryName/:categoryId" element={<ViewCategory/>}/>
            <Route path="/dashboard/set-up/stages/:serviceName/:serviceId" element={<ViewAddon/>}/>
    
            {/*I'm passing the parentParentID so that when editing the nested subcategory they can select another sibling of the parent*/}
            <Route
              path="/dashboard/set-up/categories/nested-subcategory-category/:parentParentId/:level/:parentCategoryId/:parentCategoryName"
              element={<NestedSubCategory/>}/>
    
    
            {/* INVENTORY ROUTES */}
            <Route path="/dashboard/inventory/debtor" element={<Debtor/>}/>
            <Route path="/dashboard/inventory/all_debts" element={<ViewAllOrderDebts/>}/>
            <Route path="/dashboard/inventory/debtor/:customerId" element={<ViewCustomerOrderDebts/>}/>
            <Route path="/dashboard/inventory/expiry-products/" element={<ExpiryProducts/>}/>
            <Route path="/dashboard/inventory/faulty-products/" element={<FaultyProducts/>}/>
            <Route path="/dashboard/inventory/faulty-products-logs/" element={<FaultyProductLogs/>}/>
            <Route path="/dashboard/inventory/inventory-logs/" element={<InventoryLogs/>}/>
            <Route path="/dashboard/inventory/supplier" element={<Supplier/>}/>
            <Route path="/dashboard/inventory/customer" element={<Customer/>}/>
            <Route path="/dashboard/inventory/product" element={<ProductWithContext/>}/>
            <Route path="/dashboard/inventory/product/:productId" element={<SingleProduct/>}/>
            <Route path="/dashboard/inventory/customer/:customerId" element={<SingleCustomer/>}/>
            <Route path="/dashboard/inventory/purchase_order/" element={<PurchaseOrder />}/>
            <Route path="/dashboard/inventory/purchase_order/create" element={<CreatePurchaseOrder />}/>
            <Route path="/dashboard/inventory/purchase_order/update/:purchaseOrderId" element={<CreatePurchaseOrder />}/>
            <Route path="/dashboard/inventory/purchase_order/:purchaseOrderId" element={<ViewPurchaseOrder />}/>
            <Route path="/dashboard/inventory/supplier/:supplierId" element={<SingleSupplier />}/>
  
  
            {/* PRODUCTION ROUTES */}
            <Route path="/dashboard/production/unit-measurements/" element={<ProductionUnitMeasurement />}/>
            <Route path="/dashboard/production/process/" element={<ProductionProcess />}/>
            <Route path="/dashboard/production/process/:processId" element={<ViewProductionProcess />}/>
            <Route path="/dashboard/production/stages/" element={<ProductionStage />}/>
            <Route path="/dashboard/production/history/" element={<ProductionDistributionLogs />}/>
            <Route path="/dashboard/production/products/" element={<ProducibleProducts />}/>
            <Route path="/dashboard/production/raw-materials/" element={<RawMaterials />}/>
            <Route path="/dashboard/production/request-raw-materials/" element={<RequestRawMaterial />}/>
  
            {/* DELIVERY ROUTES */}
            <Route path="/dashboard/delivery/items/" element={<Items/>}/>
            <Route path="/dashboard/delivery/package-items/" element={<PackageItems/>}/>
            <Route path="/dashboard/delivery/sent-items/" element={<SentItems/>}/>
    
            {/* SETTINGS ROUTES */}
            <Route path="/dashboard/settings/" element={<Settings/>}>
              <Route path="/dashboard/settings/" element={<Profile/>}/>
              <Route path="/dashboard/settings/receipt/" element={<ReceiptSettings/>}/>
              <Route path="/dashboard/settings/settlement" element={<Settlement/>}/>
              <Route path="/dashboard/settings/password" element={<Password/>}/>
              <Route path="/dashboard/settings/verify_email" element={<VerifyEmail/>}/>
              <Route path="/dashboard/settings/business_settings" element={<BusinessSettings/>}/>
              {/*<Route path="/dashboard/settings/subscription/" element={<Subscription/>}/>*/}
              <Route path="/dashboard/settings/store_front/" element={<StoreFrontSettings/>}/>
              <Route path="/dashboard/settings/user_notification/" element={<UserNotifications/>}/>
              <Route path="/dashboard/settings/desktop/" element={<DesktopSetting/>}/>
            </Route>
    
            <Route path="/dashboard/subscription/" element={<Subscription/>}/>
            <Route path="/dashboard/business_subscription/" element={<BusinessSubscription/>}/>
            <Route path="/dashboard/business_purchased_subscriptions/" element={<PurchasedSubscriptions/>}/>
            <Route path="/dashboard/purchased_subscriptions/:subscriptionId" element={<ViewSubscriptionPurchase/>}/>
    
    
            <Route path="/dashboard/ovaloop_pay/" element={<OvaloopPay/>}/>
          </Route>
  
          <Route path="/select-store" element={<SelectStore/>}/>
          <Route path="/kitchen" element={<KitchenView/>}/>
          <Route path="/full_screen_mode/price-checker" element={<PriceCheckerMode />}/>
          <Route path="/new_subscription" element={<NewSubscription/>}/>
  
          {/* ON BOARDING ROUTES */}
          <Route path="/on-boarding/setup-store/" element={<SetUpStore/>}/>
  
  
          {/* STAFF BACKEND */}
          <Route path="/staff_backend" element={<StaffBackendLayout/>}>
            <Route path="/staff_backend" element={<StaffBackendHome/>}/>
            <Route path="/staff_backend/kyc_documents" element={<KYC/>}/>
            <Route path="/staff_backend/settlements" element={<Settlements/>}/>
            <Route path="/staff_backend/accounts" element={<Accounts/>}/>
            <Route path="/staff_backend/accounts/:accountId" element={<ViewAccountBusinesses/>}/>
            <Route path="/staff_backend/staffs" element={<StaffsForStaffBackend/>}/>
            <Route path="/staff_backend/referrals" element={<ReferralsForStaffBackend />}/>
            <Route path="/staff_backend/users" element={<AllUsersForStaffBackend/>}/>
            <Route path="/staff_backend/demo_request" element={<RequestDemo />}/>
            <Route path="/staff_backend/ovaloop_pay_analytics" element={<OvaloopPayAnalytics/>}/>
            <Route path="/staff_backend/ovaloop_pay_transactions" element={<OvaloopPayTransactions />}/>
            <Route path="/staff_backend/ovaloop_pay_analytics/:businessId" element={<ViewOvaloopAnalyticsByBusiness/>}/>
            <Route path="/staff_backend/accounts/:accountId/subscription" element={<AccountSubscription/>}/>
          </Route>
        </Fragment>
      )}
      
    
    
    </Routes>
  )
}

export default Index
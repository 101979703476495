import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {Fragment, memo, useRef, useState} from "react";
import {useProductQuery} from "../../../hooks/useServices/useProductServices";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import {Button, Form, Input, Select} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {purchaseOrderFormDefaultValues} from "../../../data/defaultFormValues";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";


const CreateRequestRawMaterialModal = ({ onClose, handleSubmit, mutation }) => {
  
  const onSubmit = (formValues) => {
    const products = [
      {
        raw_materials: formValues["raw_materials_items"],
        producible_product: formValues[`request_product_name`],
        producing_quantity: Number(formValues[`request_product_quantity`]),
      }
    ]
  
  
    for (const key in formValues) {
      if(key.slice(0, 20) === "request_product_name") {
        const uuid = key.split("_")[3]
        if(!uuid || uuid.length < 17) continue
  
        products.push({
          raw_materials: formValues[`raw_materials_items_${uuid}`],
          producible_product: formValues[`request_product_name_${uuid}`],
          producing_quantity: Number(formValues[`request_product_quantity_${uuid}`]),
        })
      }
    }
    
    handleSubmit({ items: products })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose} $zIndex={9999}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={62}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Request Raw Materials</Heading>
        
        <Form values={{ ...purchaseOrderFormDefaultValues }} onSubmit={onSubmit}>
          <Fragment>
            {/*<Input*/}
            {/*  optional*/}
            {/*  className="fullWidth"*/}
            {/*  name="batch_number"*/}
            {/*  label="Enter batch number to add raw materials to an existing request"*/}
            {/*  placeholder="Enter batch number"*/}
            {/*/>*/}
            
            <AttachProduct />
  
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text="Create" type="submit" />
            </FlexStyled>
          </Fragment>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateRequestRawMaterialModal


const AttachProduct = memo(() => {
  const [searchProduct, setSearchProduct] = useState("")
  const { data, isLoading: isProductLoading } = useProductQuery({ search: searchProduct, type: "producible" })
  const { setFieldValue, values } = useFormikContext()
  const { numberFormatter } = useDataTypeFormatter()
  const allSelectedProductsWithIDRef = useRef([])
  
  
  const handleSelectProduct = (product, id) => {
    const filterProducts = allSelectedProductsWithIDRef.current.filter(productObj => productObj?.id !== (id || "main"))
    allSelectedProductsWithIDRef.current = [...filterProducts, {id: (id || "main"), product}]
  }
  
  const handleCalculateRawMaterials = (quantity, id) => {
    const product = allSelectedProductsWithIDRef.current.find(productObj => productObj.id === (id || "main"))
    const raw_materials = []
    
    const productRawMaterials = product.product.product_attachment.map(material => {
      raw_materials.push({ id: material.product_id, base_quantity: material.base_quantity, quantity: (Number(quantity) * Number(material.base_quantity)) })
      return `You will need ${numberFormatter(Number(quantity) * Number(material.base_quantity))}-${material.unit_measurement} of ${material.product_name}`
    })
    
    if(id) {
      setFieldValue(`raw_materials_items_${id}`, raw_materials)
      setFieldValue(`request_product_raw_materials_array_${id}`, productRawMaterials)
      return
    }
  
    setFieldValue(`raw_materials_items`, raw_materials)
    setFieldValue(`request_product_raw_materials_array`, productRawMaterials)
  }
  
  const SalesAttachmentForm = () => {
    const id = uuid()
    
    const SelectComp = ({ data, isProductLoading }) => (
      <Select
        valueKey="id"
        displayKey="name"
        name={`request_product_name_${id}`}
        label="Product"
        displayName={`request_product_name_display_${id}`}
        placeholder="Select product"
        options={data?.results}
        updateFn={(prod) => handleSelectProduct(prod, id)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
      />
    )
    
    const InputComp = () => (
      <Input
        type="number"
        formatNumber
        name={`request_product_quantity_${id}`}
        label="Quantity"
        placeholder="Enter quantity"
        onChange={(quantity) => handleCalculateRawMaterials(quantity, id)}
      />
    )
    
    return { id, SelectComp, InputComp }
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`request_product_name_${id}`, "")
    setFieldValue(`request_product_name_display_${id}`, "")
    setFieldValue(`request_product_quantity_${id}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        <div>
          <FlexStyled className="fullWidth two_cols_with_trash_icon">
            <Select
              valueKey="id"
              displayKey="name"
              name="request_product_name"
              label="Product"
              displayName="request_product_name_display"
              placeholder="Select product"
              options={data?.results}
              updateFn={(prod) => handleSelectProduct(prod)}
              updateQueryResult={setSearchProduct}
              isSearchLoading={isProductLoading}
            />
      
            <Input
              type="number"
              formatNumber
              name="request_product_quantity"
              label="Quantity"
              onChange={(quantity) => handleCalculateRawMaterials(quantity)}
              placeholder="Enter quantity"
            />
            
            <FlexStyled className="fullWidth production_raw_materials_preview">
              {values[`request_product_raw_materials_array`]?.map((material, k) => (
                <Paragraph color="black">({k + 1}). {material}</Paragraph>
              ))}
            </FlexStyled>
            
          </FlexStyled>
        </div>
        
        {moreProducts?.map(({ id, SelectComp, InputComp }) => (
          <div>
            <FlexStyled className="fullWidth two_cols_with_trash_icon" key={id}>
              <SelectComp data={data} isProductLoading={isProductLoading} />
              <InputComp />
              <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
  
              <FlexStyled className="fullWidth production_raw_materials_preview">
                {values[`request_product_raw_materials_array_${id}`]?.map((material, k) => (
                  <Paragraph color="black">({k + 1}). {material}</Paragraph>
                ))}
              </FlexStyled>
            </FlexStyled>
          </div>
        ))}
        
        <Button onClick={addExtraProduct} type="button" text="Add more product" className="small" small />
        <br/>
      </Fragment>
    
    </div>
  )
})
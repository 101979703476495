import {CloseSquare} from "iconsax-react";
import { Button, Form, Input } from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useState} from "react";


const formValues = { settlement_amount: "" }

const WithdrawModal = ({ onClose, handleSubmit, mutation }) => {
  const [withdrawalFee, setWithdrawalFee] = useState(0)
  const [amount, setAmount] = useState(0)
  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  
  const handleAmountEnter = (values) => {
    const value = Number(values)
    
    if(!values) {
      setWithdrawalFee(0)
      setAmount(0)
      return
    }
    
    if(value <= 5000) {
      setWithdrawalFee(15)
      setAmount(Number(values) - Number(15))
    } else if(value > 5000 && value <= 50000) {
      setWithdrawalFee(30)
      setAmount(Number(values) - Number(30))
    } else if(value > 50000 && value <= 100000) {
      setWithdrawalFee(60)
      setAmount(Number(values) - Number(60))
    } else {
      setWithdrawalFee(100)
      setAmount(Number(values) - Number(100))
    }
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Withdraw</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <Input onChange={handleAmountEnter} label="Withdraw Amount" type="number" formatNumber placeholder="Enter withdraw amount" name="settlement_amount"  />
          <br/>
          <SpaceBetweenStyled>
            <Paragraph color="black" bold size={1}>Withdrawal Fee:</Paragraph>
            <Paragraph color="black" bold size={1}>
              -{nairaCurrencyFormatter(withdrawalFee)}
            </Paragraph>
          </SpaceBetweenStyled>
          {/*<br/>*/}
          <SpaceBetweenStyled>
            <Paragraph color="black" bold size={1}>Withdrawal Amount:</Paragraph>
            <Paragraph color="black" bold size={1}>
              {nairaCurrencyFormatter(amount)}
            </Paragraph>
          </SpaceBetweenStyled>
          <br/>
          <FlexStyled>
            <Button text="Withdraw" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default WithdrawModal
const stagesServiceRestructure = (data, currencyFormatter, numberFormatter) => {
  if(!data?.results) {
    return data?.map(stages => {
      if(!stages.commission) {
        return { ...stages, commissionValue: "No commission" }
      }
    
      return {
        ...stages,
        commissionValue: stages.commission.type === "amount" ?
          currencyFormatter(stages.commission.value) :
          `${numberFormatter(stages.commission.value)}%`,
        commissionType: stages.commission.type
      }
    })
  }
  
  const results = data?.results?.map(stages => {
    if(!stages.commission) {
      return { ...stages, commissionValue: "No commission" }
    }
    
    return {
      ...stages,
      commissionValue: stages.commission.type === "amount" ?
        currencyFormatter(stages.commission.value) :
        `${numberFormatter(stages.commission.value)}%`,
      commissionType: stages.commission.type
    }
  })
  
  return { ...data, results }
}


export default stagesServiceRestructure
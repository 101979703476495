import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getExpenseCategoryService = async (queryParams = {}) => {
  try {
    const url = handleQueryParams("/custom_expenses_category", queryParams)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createExpenseCategoryService = async (body) => {
  try {
    const { data } = await axios.post(`/custom_expenses_category/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editExpenseCategoryService = async (expenses_categoryId, body) => {
  try {
    const { data } = await axios.patch(`/custom_expenses_category/${expenses_categoryId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteExpenseCategoryService = async (expenses_categoryId) => {
  try {
    const { data } = await axios.delete(`/custom_expenses_category/${expenses_categoryId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getExpenseCategoryService, createExpenseCategoryService, editExpenseCategoryService, deleteExpenseCategoryService }
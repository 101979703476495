import {useMutation, useQuery, useQueryClient} from "react-query";
import {approveReferralService, getReferralService, getRequestDemoService} from "../../../services/referralServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";


export const useAllReferralQuery = (query = {}) => {
	return useQuery({
		queryKey: ["Referral", query],
		queryFn: () => getReferralService(query)
		}
	)
}


export const useAllRequestDemoQuery = (query = {}) => {
	return useQuery({
			queryKey: ["Demo Request", query],
			queryFn: () => getRequestDemoService(query)
		}
	)
}


export const useReferralApprovalMutation = ({ successFn }) => {
	const { toast } = useGlobalContext()
	const queryClient = useQueryClient()
	
	return useMutation({
		mutationFn: (body) => approveReferralService(body),
		
		onSuccess: () => {
			successFn()
			toast.success("Referral Approved")
			queryClient.refetchQueries({ queryKey: "Referral" })
		},
		
		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getAddOnService = async (query = {}) => {
  try {
    const url = handleQueryParams('/services', query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createAddOnService = async (body) => {
  try {
    const { data } = await axios.post(`/services/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editAddOnService = async (addOnId, body) => {
  try {
    const { data } = await axios.patch(`/services/${addOnId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteAddOnService = async (addOnId) => {
  try {
    const { data } = await axios.delete(`/services/${addOnId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getAddOnService, createAddOnService, editAddOnService, deleteAddOnService }
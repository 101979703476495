import useDataTypeFormatter from "../useDataTypeFormatter";

const useGroupOrderCalculation = (orders = []) => {
  const { currencyFormatter } = useDataTypeFormatter()
  
  const summary = orders.reduce((prev, curr) => {
    const values = {...prev}
    
    values.totalTax = values.totalTax + Number(curr.tax_amount)
    values.totalCost = values.totalCost + Number(curr.total_amount)
    values.totalService = values.totalService + Number(curr.total_services_cost)
    values.totalDiscount = values.totalDiscount + Number(curr.discount_amount)
    values.subTotal = values.subTotal + Number(curr.total_items_cost) + Number(curr.discount_amount)
    
    return values
  }, { totalTax: 0, totalDiscount: 0, totalService: 0, subTotal: 0, totalCost: 0 })
  
  return {
    totalTaxValue: summary.totalTax,
    totalDiscountValue: summary.totalDiscount,
    subTotal: currencyFormatter(summary.subTotal),
    totalCost: currencyFormatter(summary.totalCost),
  }
}

export default useGroupOrderCalculation
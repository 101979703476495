import {useEffect} from "react";
import {Cart} from "../../../components";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {CartMobilePageStyled} from "../../../styles/sellStyles";


const CartMobilePage = () => {
  const { isMobile, navigate } = useGlobalContext()

  useEffect(() => {
    if(!isMobile) {
      navigate("/dashboard_sell")
    }
  }, [])

  return (
    <CartMobilePageStyled>
      <Cart />
    </CartMobilePageStyled>
  )
}

export default CartMobilePage;
import states from "../data/location/states";

const childrenPropToArray = (children) => {
  if(!children) return

  return !!children.length ? children : [children]
}

const isPropAvailableAndAString = (text) => {
  return (!!text && typeof text === "string") ? text : null
}

const isSelectedOptionAvailableInOptionsData = (data, value, valueKey) => {
  return data.find(optionData => optionData[valueKey] === value)
}


const findCountryById = (countryList, countryId) => {
  return countryList.find(country => country.id === countryId)
}

const findStatesByCountryId = countryId => {
  return states[countryId]
}

export {
  isPropAvailableAndAString,
  childrenPropToArray,
  findCountryById,
  findStatesByCountryId,
  isSelectedOptionAvailableInOptionsData,
}
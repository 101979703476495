import {Input} from "../index";
import {Eye, EyeSlash} from "iconsax-react";
import usePassword from "../../hooks/usePassword";

const PasswordInput = ({ name, label, placeholder, required, key }) => {

  const [passwordState, setPasswordState] = usePassword()

  const changePasswordVisibility = () => {
    passwordState.visible ? setPasswordState.makeInVisible() : setPasswordState.makeVisible()
  }

  return (
    <Input
      key={key}
      name={name}
      label={label}
      required={required}
      placeholder={placeholder}
      type={passwordState.type}
      icon={passwordState.visible ?
        <Eye size={15} onClick={changePasswordVisibility} className="password" /> :
        <EyeSlash size={15} onClick={changePasswordVisibility} className="password" />
      }
    />
  )
}

export default PasswordInput
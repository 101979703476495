import Form from "../../Forms/Form";
import {Button, CreateBankModal, Input, Select} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import PaymentMethod from "../../Forms/PaymentMethod";
import {
	tableCheckoutPaymentFromTableMethods,
} from "../../../data/selectField/paymentMethodsList";
import {Fragment, memo, useState} from "react";
import {useBankQuery, useCreateBankMutation} from "../../../hooks/useServices/useBankServices";
import {useModal} from "../../../hooks";
import {useFormikContext} from "formik";
import {ArrowSwapHorizontal} from "iconsax-react";
import {useCustomerQuery, useUpdateCustomerWalletMutation} from "../../../hooks/useServices/useCustomerServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";


const BusinessSubscriptionCheckout = ({ onClose, closeDrawer, customer }) => {

	const { data: allBanks } = useBankQuery()

	const [showCreateBankModal, setShowCreateBankModal] = useModal()
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
	const createBankMutation = useCreateBankMutation({ successFn: setShowCreateBankModal })

	const { debounceState: customerDebounceState, selectFieldSearch: setCustomerSearchValue } = useQueryDebounce()
	const { isSuccess, data: allCustomers } = useCustomerQuery(customerDebounceState.searchValue)
	
	
	const handleSubmitSuccessful = () => {
		onClose()
		closeDrawer()
	}

	const updateCustomerWalletMutation = useUpdateCustomerWalletMutation({ successFn: handleSubmitSuccessful })
	
	const handleCreateCustomerSubscription = (values) => {
		const body = {
			...values,
			id: values.customer,
			action: "credit"
		}
		
		updateCustomerWalletMutation.mutate(body)
	}

	const CreateBank = () => (
		<label className="underline" onClick={setShowCreateBankModal}>Create +</label>
	)
	
	return (
		<ContainerStyled className="checkout">

			{showCreateBankModal && (
				<CreateBankModal
					position="fixed"
					onClose={setShowCreateBankModal}
					mutation={createBankMutation}
					handleSubmit={createBankMutation.mutate}
				/>
			)}

			<br/>
			<Form values={{ pay_method: "", display_bank: "", customer: customer.id, customer_name: `${customer.first_name} ${customer.last_name}`, banks: "", amount: "" }} onSubmit={handleCreateCustomerSubscription}>
				{!selectedPaymentMethod && (
					<PaymentMethod
						label="Select Payment Method"
						name="pay_mode"
						methods={tableCheckoutPaymentFromTableMethods}
						setPaymentMethod={setSelectedPaymentMethod}
					/>
				)}

				{!!selectedPaymentMethod && (
					<Fragment>
						<ShowSelectedPayment selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />
						<br />
						
						<Select
							options={isSuccess ? allCustomers.results : []}
							displayKey="full_name"
							valueKey="id"
							name="customer"
							label="Customer"
							disabled
							placeholder="Select customer"
							displayName="customer_name"
							updateQueryResult={setCustomerSearchValue}
						/>
						
						{selectedPaymentMethod === "Bank Transfer" && (
							<Select
								label="Select bank"
								name="banks"
								options={allBanks}
								displayKey="bankInfo"
								valueKey="id"
								displayName="display_bank"
								placeholder="Select bank to transfer"
								SideInfo={CreateBank}
							/>
						)}
						
						<Input
							label="Credit Amount"
							name="amount"
							type="number"
							formatNumber
							placeholder="Enter amount to credit"
						/>
					</Fragment>
				)}
				
				{selectedPaymentMethod && (
					<Button type="submit" text="Credit Wallet" isLoading={updateCustomerWalletMutation.isLoading} />
				)}
			</Form>
		</ContainerStyled>
	)
}

export default BusinessSubscriptionCheckout



const ShowSelectedPayment = memo(({ setSelectedPaymentMethod, selectedPaymentMethod }) => {
	const { setFieldValue } = useFormikContext()

	const handleChangePayment = () => {
		setSelectedPaymentMethod("")
		setFieldValue("pay_mode", "")
	}

	return (
		<SpaceBetweenStyled className="show_payment_info">
			<FlexStyled $gap={.5}>
				<Heading color="black">Payment Method:</Heading>
				<Paragraph noTop color="secondary" bold>{selectedPaymentMethod.toUpperCase()}</Paragraph>
			</FlexStyled>

			<Paragraph color="secondary" bold className="underline" onClick={handleChangePayment}>
				CHANGE
				<ArrowSwapHorizontal size={16} color="black"/>
			</Paragraph>
		</SpaceBetweenStyled>
	)
})
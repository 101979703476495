import {SelectStoreNavStyled, SelectStoreStyled, StoresCardListStyled} from "../../styles/SelectStoreStyles";
import {ScreenLoading, StoreCard} from "../../components";
import {Heading2, Paragraph} from "../../styles/textStyles";
import {useStoreQuery} from "../../hooks/useServices/useStoreServices";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {useEffect, useState} from "react";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {FlexColumnStyled} from "../../styles/utilStyles";
import {clearAllDataInStorage} from "../../utils";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import {ContainerStyled} from "../../styles/DashboardStyles";


const SelectStore = () => {
  const {navigate} = useGlobalContext()
  const {data: user, error} = useUserProfile()
  const [availableBusinesses, setAvailableBusinesses] = useState([])
  const {
    isSuccess: isStoreSuccess,
    data: allStores,
    status,
    refetch,
    isLoading,
  } = useStoreQuery("")
  
  useEffect(() => {
    if (isStoreSuccess) {
      allStores.length < 1 && navigate("/on-boarding/setup-store/")
    }
  }, [isStoreSuccess])
  
  
  useEffect(() => {
    if (!!error || status === "error") {
      clearAllDataInStorage()
      navigate("/login")
    }
  }, [error, status])
  
  useEffect(() => {
    if (user?.[0]?.user_type === "client_admin" && allStores?.length > 0) {
      setAvailableBusinesses(allStores)
      return
    }
    
    if (!!user?.[0]?.other_business && allStores?.length > 0) {
      const biz = []
      const _allStores = [...user?.[0]?.other_business, user?.[0]?.business]
  
      _allStores.forEach(businessId => {
        const addBiz = allStores?.find(business => business.id === businessId)
        if (!!addBiz) {
          biz.push(addBiz)
        }
      })
      
      setAvailableBusinesses(biz)
    }

  }, [user?.[0]?.other_business, isStoreSuccess])
  

  return (
    <div>
      {status === "error" && (
        <ScreenLoading refetchFn={refetch} />
      )}
  
      {isStoreSuccess && (
        <ContainerStyled padding>
          <SelectStoreStyled>
            <div>
              <SelectStoreNavStyled>
                <FlexColumnStyled $gap={.5}>
                  <Heading2 color="black">Hello, {user?.[0]?.first_name} 👋🏼</Heading2>
                  <Paragraph color="black" size={1.3}>Please select a store to continue</Paragraph>
                </FlexColumnStyled>
              </SelectStoreNavStyled>
      
              <StoresCardListStyled>
                {availableBusinesses?.map(item => (
                  <StoreCard store={item} key={item?.id} />
                ))}
              </StoresCardListStyled>
            </div>
            <div></div>
          </SelectStoreStyled>
        </ContainerStyled>
      )}
  
      {isLoading && (
        <ContainerStyled padding>
          <SelectStoreStyled>
            <div>
              <SelectStoreNavStyled>
                <FlexColumnStyled $gap={.5}>
                  <Heading2 color="black">
                    <TableSkeleton count={1} height={50} width="250px" />
                  </Heading2>
  
                  <Heading2 color="black">
                    <TableSkeleton count={1} height={30} width="170px" noTop />
                  </Heading2>
                </FlexColumnStyled>
              </SelectStoreNavStyled>
      
              <StoresCardListStyled>
                <TableSkeleton count={1} height={200} width="250px" />
                <TableSkeleton count={1} height={200} width="250px" />
                <TableSkeleton count={1} height={200} width="250px" />
              </StoresCardListStyled>
            </div>
          </SelectStoreStyled>
        </ContainerStyled>
      )}
    </div>
  )
}

export default SelectStore
import {ModalLayoutStyled, PaymentModalStyled, PaymentModalMainStyled} from "../../../styles/modalStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Button, Image, Line} from "../../index";
import {Logo} from "../../../assets/images";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {CloseCircle} from "iconsax-react";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {Fragment, useEffect, useState} from "react";
import {
	useDynamicAccountMutation,
	useDynamicAccountQuery, useVerifyDynamicPaymentMutation,
} from "../../../hooks/useServices/useOvaloopPaymentServices";
import {isAccountNumberExpired} from "../../../utils/expiryCheck";
import uuid from "react-uuid";


const PaymentModal = ({ onClose, dynamicAccountBody, verifyAccountQueryParam, payment_details }) => {
	const isEnabled = () => {
		if(!payment_details?.account_number) return true
	}

	const { nairaCurrencyFormatter } = useDataTypeFormatter()
	const {
		data, isSuccess, isLoading: isAccountNumberLoading
	} = useDynamicAccountQuery(dynamicAccountBody, !!isEnabled())
	
	const [{ timeRemaining, key: timeKey }, setTimeRemaining] = useState({ timeRemaining: "", key: "" })
	const confirmTransferMutation = useVerifyDynamicPaymentMutation({ successFn: onClose })
	const generateNewAccountMutation = useDynamicAccountMutation()
	
	const handleConfirmTransfer = () => {
		confirmTransferMutation.mutate(verifyAccountQueryParam)
	}
	
	const handleGenerateNewAccount = () => {
		generateNewAccountMutation.mutate(dynamicAccountBody)
	}
	
	const accDetails = () => {
		if(!payment_details?.account_name) {
			return { accountDetails: data, isAccountNumberGenerated: isSuccess, isLoading: isAccountNumberLoading }
		}
		
		if(!!payment_details?.account_name && isAccountNumberExpired(payment_details?.account_number)) {
			return { accountDetails: data, isAccountNumberGenerated: isSuccess, isLoading: isAccountNumberLoading }
		}
		
		if(!!data?.account_name) {
			return { accountDetails: data, isAccountNumberGenerated: isSuccess, isLoading: isAccountNumberLoading }
		}
		
		return { accountDetails: payment_details, isAccountNumberGenerated: true, isLoading: false }
	}
	
	const { accountDetails, isLoading, isAccountNumberGenerated } = accDetails()
	
	const handleTimeStamp = (dateGenerated) => {
		const timeDiff = new Date(new Date() - new Date(dateGenerated))
		let minutes = 29 - timeDiff.getMinutes()
		let seconds = 60 - timeDiff.getSeconds()
		
		if(minutes.toString().length < 2) {
			minutes = `0${minutes}`
		}
		
		if(seconds.toString().length < 2) {
			seconds = `0${seconds}`
		}
		
		return !minutes ? "" : `${minutes}:${seconds}`
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining({
				timeRemaining: handleTimeStamp(accountDetails?.date_generated),
				key: uuid()
			})
		}, 1000)
		
		return () => {
			clearInterval(interval)
		}
	}, [timeKey])

	
	return (
		<ModalLayoutStyled onClick={onClose} $zIndex={999999}>
			<PaymentModalStyled $width={45} onClick={e => e.stopPropagation()} className="payment_modal">
				<SpaceBetweenStyled className="header">
					<CloseCircle size={23} onClick={onClose} color="black" />

					<Image src={Logo} width={30} />
					<FlexStyled>
						<Paragraph noTop color="gray" bold></Paragraph>
						<Paragraph color="gray" bold>
							To Pay:
							<span> {nairaCurrencyFormatter(accountDetails?.amount || 0)} </span>
						</Paragraph>
					</FlexStyled>
				</SpaceBetweenStyled>

				<Line />
				
				<PaymentModalMainStyled>
					{isAccountNumberGenerated && (
						<Fragment>
							<Heading color="black" size={1.3}>Transfer {nairaCurrencyFormatter(accountDetails?.amount || 0)} to {accountDetails?.account_name}</Heading>
							
							<Fragment>
								<FlexStyled>
									<Heading color="secondary" size={1.3}>Providus Bank</Heading>
									<Heading color="black" size={1.6}>{accountDetails?.account_no || accountDetails?.account_number}</Heading>
									
									{!isAccountNumberExpired(accountDetails?.date_generated) && (
										<Paragraph color="secondary" size={1.1} id={timeKey} key={timeKey}>
											Use this account for this transaction only. <br />
											Account expires in <span className="text_black">{timeRemaining}</span> minutes
										</Paragraph>
									)}
									
									{isAccountNumberExpired(accountDetails?.date_generated) && (
										<Paragraph color="error" size={1.2} bold>
											Account number has expired, please generate a new one to make payment
										</Paragraph>
									)}
								</FlexStyled>
								
								{isAccountNumberExpired(accountDetails?.date_generated) && (
									<Fragment>
										<Button text="Regenerate Account" onClick={handleGenerateNewAccount} isLoading={generateNewAccountMutation.isLoading} />
										<Button text="Confirm transfer" onClick={handleConfirmTransfer} isLoading={confirmTransferMutation.isLoading} />
									</Fragment>
								)}
								
								{!isAccountNumberExpired(accountDetails?.date_generated) && (
									<Fragment>
										<Button text="Confirm transfer" onClick={handleConfirmTransfer} isLoading={confirmTransferMutation.isLoading} />
										<Button text="Confirm later" onClick={onClose} />
									</Fragment>
								)}
							</Fragment>
						</Fragment>
					)}
					
					{isLoading && (
						<Fragment>
							<Heading color="black" bold>Please wait</Heading>
							<Heading color="black">Account Generating...</Heading>
						</Fragment>
					)}
				</PaymentModalMainStyled>
				
			</PaymentModalStyled>
		</ModalLayoutStyled>
	)
}


export default PaymentModal
import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createTaxService, deleteTaxService, editTaxService, getTaxService} from "../../../services/taxServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopTaxServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import productQueryKey from "../useProductServices/productQueryKey";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const useTaxQuery = (search) => {
  const {getBusinessId, isOffline} = useGlobalContext()
  
  const offlineData = useDesktopTaxServiceFromLocalDB(search)
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: [queryKeys.TAXES, search],
    queryFn: () => getTaxService({business_id: getBusinessId(), search})
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCreateTaxMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createTaxService({ ...data, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Tax Created!!")
      queryClient.invalidateQueries({queryKey: queryKeys.TAXES})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createTax,
      })
    }
  })
}

const useEditTaxMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editTaxService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({queryKey: queryKeys.TAXES})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateTax,
      })
    }
  })
}

const useDeleteTaxMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: subCategoryId => {
      return deleteTaxService(subCategoryId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({queryKey: queryKeys.TAXES})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
  
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useTaxQuery, useCreateTaxMutation, useEditTaxMutation, useDeleteTaxMutation }
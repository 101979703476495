import styled from "styled-components";
import {PageSummaryCardStyled} from "./cardStyles";
import {Heading, Paragraph} from "./textStyles";
import {SpaceBetweenStyled} from "./utilStyles";


export const PageSummarySkeletonStyled = styled(PageSummaryCardStyled)`
  height: ${props => props.$height && props.$height};
  
  padding: 1.3rem 0.7rem 1.6rem;
  ${Paragraph} {
    margin: 0 !important;
  }
  
  ${Paragraph}.title {
    position: relative;
    top: -.5rem;
    width: 100%;
    height: ${props => !props.$height && ".5rem"};
  }
  
  ${SpaceBetweenStyled} {
    width: 100%;
    margin-top: .6rem;
    
    ${Heading} {
      width: 25%;
      height: .9rem !important;
    }

    ${Paragraph} {
      width: 60%;
      height: .8rem !important;
    }
  }
`

import {InputStyled} from "../../styles/formStyles";
import {Field, useFormikContext} from "formik";
import {FormProvider} from "./Form";
import {useContext} from "react";

const TextArea = ({ className, label, placeholder, name, rows, optional, disabled, onChange }) => {
  const { allFieldsNotRequired } = useContext(FormProvider)
  const { setFieldValue } = useFormikContext()

  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }
  
  const handleChange = (e) => {
    const value = e.target.value
    setFieldValue(name, value)
    onChange?.(value, name)
  }

  return (
    <InputStyled className={className}>
      <label htmlFor={name}>
        {label} {!!optional && "(Optional)"}
      </label>
      <Field onChange={handleChange} disabled={disabled} required={isRequired()} rows={rows} id={name} as="textarea" name={name} placeholder={placeholder} />
    </InputStyled>
  )
}

export default TextArea
import {handleGetCurrentCount} from "../useOrderQueryWithSearchAndFilter";

const useSearchQuery = (state, useMainQuery, noPagination) => {
	const searchResponse = useMainQuery(state.searchValue)
	const searchQuery = queryResponse(searchResponse)
	const data = searchQuery?.data
	
	return {
		mainQuery: searchQuery,
		searchQuery,
		get data() {
			return data
		},
		get next() { return data?.next },
		get prev() { return data?.previous },
		get currentCount() { return handleGetCurrentCount(data?.next, data?.previous) },
		get showPagination() { return !!data?.next || !!data?.previous },
		get isQueryLoading() { return searchQuery.isLoading },
		get isEmpty() { return noPageData(data, state.searchValue, noPagination) && !this.isQueryLoading },
		isSearchEmptyMessage(dataName, by) { return `No ${dataName} with ${(by || "name")}  "${state.searchValue}"` },
		get isQueryWithData() { return searchQuery.isSuccess && !getIsSearchEmpty(data, noPagination) },
		get isQueryWithNoData() { return searchQuery.isSuccess && getIsSearchEmpty(data, noPagination) },
		get showTable() {
			if(searchQuery.isLoading) return true
			if(searchQuery.isSuccess && !this.isEmpty) return true
		},
		get count() {
			if(noPagination) {
				return data?.length || 0
			}
			return data?.count || 0
		},
		refetch: () => {
			return searchQuery.refetch()
		},
		get queryValues() { return state.searchValue }
	}
}

export default useSearchQuery

const queryResponse = (query) => {
	return {
		data: query?.data,
		refetch: query?.refetch,
		isSuccess: query?.isSuccess,
		isLoading: query?.isLoading,
		isError: query?.status === "error",
	}
}

const getIsSearchEmpty = (data, noPagination) => {
	if(noPagination) {
		return (!data || data.length < 1)
	}
	
	return (!data || data.results.length < 1)
}

const noPageData = (data, searchValue, noPagination) => {
	if(!!searchValue) return false
	
	if(noPagination) {
		return (!data || data.length < 1)
	}
	
	return (!data || data.results.length < 1)
}
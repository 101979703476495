import {Heading4, Paragraph} from "../../styles/textStyles";
import {UserNavigationProfileStyled} from "../../styles/cardStyles";
import {Notification, Setting2} from "iconsax-react";
import {Link} from "react-router-dom";
import IconWithToolTip from "../Utils/IconWithToolTip";
import {HiOutlineWrenchScrewdriver} from "react-icons/hi2"
import {FlexStyled, SyncingLoaderStyled} from "../../styles/utilStyles";
import {ListDropDown} from "../index";
import {MenuListStyled} from "../../styles/listStyles";
import useNavigationMenuList from "../../hooks/useUtils/useNavigationMenuList";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {Tooltip} from "react-tooltip";
import {MdOutlineSync, MdToggleOff, MdToggleOn} from "react-icons/md";
import {desktopOnlineStatusReducerActions} from "../../reducers/DesktopOnlineStatusReducer";
import useDesktopOnlineStatusContext from "../../hooks/useContexts/useDesktopOnlineStatusContext";
import useDesktopOfflineModeAllowed from "../../hooks/useDesktop/useUtils/useDesktopOfflineModeAllowed";
import {Fragment} from "react";
import useIsOfflineDataReady from "../../hooks/useDesktop/useUtils/useIsOfflineDataReady";
import {isAllDesktopRequestSynced} from "../../utils/desktopUtils/desktopSyncUtils";


const UserNavigationProfile = ({firstName = "", lastName = "", companyName = "", openNotification}) => {
  const isOfflineReady = useIsOfflineDataReady()
  const {isOffline, toast, notificationCount: {viewCount: viewNotificationCount}} = useGlobalContext()
  const {setupNavMenuList} = useNavigationMenuList()
  const {desktopOnlineReducer, desktopOnlineState} = useDesktopOnlineStatusContext()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  const handleSwitchToOnline = () => {
    desktopOnlineReducer({type: desktopOnlineStatusReducerActions.SWITCH_DESKTOP_STATUS, status: "online"})
  }
  
  const handleSwitchToOffline = () => {
    if (!isOfflineReady) {
      toast.error("Online data is still syncing, please wait", "syncing")
      return
    }
    
    desktopOnlineReducer({type: desktopOnlineStatusReducerActions.SWITCH_DESKTOP_STATUS, status: "offline"})
  }
  
  const handleSwitchDesktopStatus = (type) => {
    if (type === "offline") {
      toast.confirm(
        "Are you sure you want to switch offline",
        () => handleSwitchToOffline()
      )
      return
    }
    
    toast.confirm(
      "Are you sure you want to switch online",
      () => handleSwitchToOnline()
    )
  }
  
  
  return (
    <UserNavigationProfileStyled>
      <div>
        <FlexStyled $gap={.3}>
          {isDesktopOfflineModeAllowed && (
            <Fragment>
              <IconWithToolTip Comp={OfflineSyncingLoader}>
                {(!isOfflineReady || desktopOnlineState.re_sync) &&
                  <MdOutlineSync style={{top: ".5rem"}} size={22} className="loading_circle"/>}
              </IconWithToolTip>
            </Fragment>
          )}
          
          {isDesktopOfflineModeAllowed && (
            !isOffline ?
              <MdToggleOn size={30} onClick={() => handleSwitchDesktopStatus("offline")} color="green"
                          className="network_status"/> :
              <MdToggleOff size={28} onClick={() => handleSwitchDesktopStatus("online")} color="red"
                           className="network_status"/>
          )}
          
          <Tooltip
            anchorId="network_status"
            place="top"
            variant={isOffline ? "error" : "success"}
            content={isOffline ? "You are offline" : "You are online"}
          />
          
          <Heading4 color="black">
            {firstName} {lastName}
          </Heading4>
        </FlexStyled>
        <Paragraph color="darkGray" size={.9}>{companyName}</Paragraph>
      </div>
      
      <FlexStyled $gap={.5}>
        <MenuListStyled>
          <HiOutlineWrenchScrewdriver color="black" size={28}/>
          <div className="container">
            <ListDropDown options={setupNavMenuList}/>
          </div>
        </MenuListStyled>
  
        <Link to="/dashboard/settings">
          <IconWithToolTip text="Settings">
            <Setting2 size={28}/>
          </IconWithToolTip>
        </Link>
  
        <IconWithToolTip chip={viewNotificationCount}>
          <Notification size={28} onClick={openNotification}/>
        </IconWithToolTip>
      </FlexStyled>
    </UserNavigationProfileStyled>
  )
}


export default UserNavigationProfile

const OfflineSyncingLoader = () => {
  const isOfflineReady = useIsOfflineDataReady()
  const {desktopOnlineState} = useDesktopOnlineStatusContext()
  const progressPercentage = isAllDesktopRequestSynced() ? 100 : Number(desktopOnlineState.completedProgress)
  
  return (
    <SyncingLoaderStyled>
      <Paragraph color="black">Syncing data offline
        ({Number(desktopOnlineState.completedProgress).toFixed(0)}%)</Paragraph>
      <progress
        value={isOfflineReady ? Number(Number(desktopOnlineState.completedProgress).toFixed(0)) : progressPercentage}
        max={100}/>
    </SyncingLoaderStyled>
  )
}
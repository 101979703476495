import Form from "../../Forms/Form";
import {Fragment, memo, useRef, useState} from "react";
import {ArrowSwapHorizontal, CloseSquare} from "iconsax-react";
import PaymentModal from "../PaymentModal";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useSellContext from "../../../hooks/useContexts/useSellContext";
import {CartStyled, CartSummaryStyled} from "../../../styles/sellStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {Button, CreateBankModal, DatePicker, Input, Select} from "../../index";
import {checkoutFormDefaultValues} from "../../../data/defaultFormValues";
import {ModalLayoutStyled} from "../../../styles/modalStyles";
import {useCreateOrderMutation, usePartialPayForGroupOrderMutation,} from "../../../hooks/useServices/useOrderServices";
import {handleInvoicePaymentMethod} from "../../../utils/formatForms/checkout";
import PaymentMethod from "../../Forms/PaymentMethod";
import usePaymentModal from "../../../hooks/useModals/usePaymentModal";
import {useModal} from "../../../hooks";
import SplitOrPartialPayment from "../../Forms/SplitOrPartialPayment";
import {useBankQuery, useCreateBankMutation} from "../../../hooks/useServices/useBankServices";
import {useFormikContext} from "formik";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useCustomPaymentMethodQuery} from "../../../hooks/useServices/useCustomPaymentMethodServices";
import {useCustomerActiveSubscriptionByIdQuery} from "../../../hooks/useServices/useCustomerSubscriptionServices";
import {currencyFormatterFn} from "../../../utils/dataTypeFormatter";
import usePaymentMethods from "../../../hooks/useUtils/usePaymentMethods";
import {useSearchParams} from "react-router-dom";
import {formatDatetimeForBackend} from "../../../utils/desktopUtils/desktopSyncUtils";


const handlePaymentModal = (sellSearchRef, sellCategoryRef) => {
  if (!!sellSearchRef.current) {
    sellSearchRef.current.style.zIndex = 0
  }
  
  if(!!sellCategoryRef.current) {
    sellCategoryRef.current.style.zIndex = 0
  }
}


const InvoiceCheckOutModal = ({ onClose, orders, customPaymentMethods, handlePaymentSubmission, onlyOnePartial, balance, mutation }) => {
  const formDataRef = useRef(null)
  const submitButtonRef = useRef(null)
  const { currencyFormatter } = useDataTypeFormatter()

  const { generalPaymentMethods } = usePaymentMethods()

  const { toast, isMobile } = useGlobalContext()
  const { sellSearchRef, sellCategoryRef } = useSellContext()
  
  
  const businessSettings = useBusinessSettingQuery()
  
  const [showCreateBankModal, setShowCreateBankModal] = useModal()
  
  const [openTransferModal, setOpenTransferModal] = usePaymentModal(sellSearchRef, sellCategoryRef, (businessSettings?.sell_screen === "category_based"))
  
  const [receiptData, setReceiptData] = useState([])
  const [dataForOvaloopTransfer, setDataForOvaloopTransfer] = useState()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
  
  const {data: allBanks} = useBankQuery()
  const createBankMutation = useCreateBankMutation({successFn: setShowCreateBankModal})
  const payBalanceMutation = usePartialPayForGroupOrderMutation({successFn: onClose})
  
  const handleOrderSuccess = (res) => {
    setReceiptData(res)
    
    if (res[0].pay_mode !== "ovaloop_transfer") {
      return
    }
    
    handleTransferPaymentMethod(res)
  }
  
  const createOrderMutation = useCreateOrderMutation({ successFn: handleOrderSuccess })
  
  const handleOpenCreateModal = (type) => {
    handlePaymentModal(sellSearchRef, sellCategoryRef);
    
    type === "bank" && setShowCreateBankModal()
  }
  
  const handleTransferPaymentMethod = (res) => {
    setOpenTransferModal()
    setDataForOvaloopTransfer(res)
  }
  
  const handleCheckout = (paymentValues) => {
    const values = {...paymentValues}
    if (handlePaymentSubmission) {
      handlePaymentSubmission(values)
      return
    }
    
    handlePaymentModal(sellSearchRef, sellCategoryRef)
    
    if (values.pay_mode === "pos") {
      values.split_pos_bank = values.banks
      values.split_pos_value = Number(balance || orders?.[0]?.balance)
      values.split_transfer_value = 0
      values.split_cash_value = 0
    }
    
    if (values.pay_mode === "cash") {
      values.split_cash_value = Number(balance || orders?.[0]?.balance)
      values.split_transfer_value = 0
      values.split_pos_value = 0
    }
  
    if (values.pay_mode === "transfer") {
      values.split_transfer_bank = values.banks
      values.split_transfer_value = Number(balance || orders?.[0]?.balance)
      values.split_cash_value = 0
      values.split_pos_value = 0
    }
  
    if (values.pay_mode === "others") {
      values.split_other_value_id = values.other_payment
      values.split_other_value = Number(balance || orders?.[0]?.balance)
      values.split_transfer_value = 0
      values.split_cash_value = 0
      values.split_pos_value = 0
    }
  
    const formattedValues = handleInvoicePaymentMethod(values)
  
    if (values.pay_mode === "split") {
      const total = Number(balance || orders?.[0]?.balance).toFixed(2)
    
      if (Number(values.splitOrPartialPaymentAmount).toFixed(2) !== total) {
        toast.error("Split amount must match total payable")
        return
      }
    }
  
    if (values.pay_mode === "partial") {
      const total = Number(Number(balance || orders?.[0]?.balance).toFixed(2))
    
      if (Number(Number(values.splitOrPartialPaymentAmount).toFixed(2)) > total) {
        toast.error("Partial amount cannot be greater than total payable")
        return
      }
    }
  
    payBalanceMutation.mutate({
      split_payment: formattedValues,
      groupOrderId: orders[0]?.group_order?.id,
      pay_mode: values.pay_mode,
      ...(!!values.payment_date && {payment_date: formatDatetimeForBackend(values.payment_date)})
    })
  }
  
  const ShowSelectedPayment = () => {
    const [searchParams] = useSearchParams()
    const { setFieldValue } = useFormikContext()

    const handleChangePayment = () => {
      setSelectedPaymentMethod("")
      setFieldValue("pay_mode", "")
    }

    return (
      <SpaceBetweenStyled className="show_payment_info">
        <FlexStyled $gap={.5}>
          <Heading color="black">Payment Method:</Heading>
          <Paragraph noTop color="secondary" bold>{selectedPaymentMethod.toUpperCase()}</Paragraph>
        </FlexStyled>
  
        {!searchParams.get("pay_mode") && (
          <Paragraph color="secondary" bold className="underline" onClick={handleChangePayment}>
            CHANGE
            <ArrowSwapHorizontal size={16} color="black"/>
          </Paragraph>
        )}
      </SpaceBetweenStyled>
    )
  }

  const handleSubmitForm = () => {
    submitButtonRef.current.click()
  }
  
  const onOvaloopTransferComplete = () => {
    setDataForOvaloopTransfer(null)
    setOpenTransferModal()
  }

  return (
    <Fragment>
      {openTransferModal && (
        <PaymentModal
          dynamicAccountBody={{ group_order: dataForOvaloopTransfer[0]?.group_order?.id }}
          verifyAccountQueryParam={{ group_order_id: dataForOvaloopTransfer[0]?.group_order?.id }}
          order={dataForOvaloopTransfer}
          onClose={onOvaloopTransferComplete}
        />
      )}

      {showCreateBankModal && (
        <CreateBankModal
          position="fixed"
          onClose={setShowCreateBankModal}
          mutation={createBankMutation}
          handleSubmit={createBankMutation.mutate}
        />
      )}

      <ModalLayoutStyled $zIndex="99999" onClick={onClose}>
        <CartStyled onClick={e => e.stopPropagation()}>
          {isMobile && <><br/><br/><br/></>}

          <FlexStyled>
            <CloseSquare size={28} onClick={onClose} />
            <Heading size={1.3} color="black">Check Out</Heading>
          </FlexStyled> <br/>

          <Form innerRef={formDataRef} values={checkoutFormDefaultValues} onSubmit={handleCheckout}>
            {(selectedPaymentMethod === "Split" || selectedPaymentMethod === "Partial") && (
              <ShowSelectedPayment />
            )}
  
            {!(selectedPaymentMethod === "Split" || selectedPaymentMethod === "Partial") && (
              <PaymentMethod
                label="Select Payment Method"
                name="pay_mode"
                methods={customPaymentMethods || generalPaymentMethods}
                setPaymentMethod={setSelectedPaymentMethod}
              />
            )}
  
            {(["Bank Transfer", "Pos"].includes(selectedPaymentMethod)) && (
              <Select
                label="Select bank"
                name="banks"
                options={allBanks}
                displayKey="bankInfo"
                valueKey="id"
                displayName="display_bank"
                placeholder="Select bank to transfer"
              />
            )}
  
            <DatePicker
              optional
              required
              datetime
              labelBg="white"
              name="payment_date"
              label="Payment Date"
              placeholder="Select payment date"
            />
  
            <br/>
            <Fragment>
              {!!selectedPaymentMethod && (
                <Fragment>
                  <CustomPaymentMethod handleOpenCreateModal={handleOpenCreateModal}/>
        
                  {((selectedPaymentMethod === "Split" || selectedPaymentMethod === "Partial") && !onlyOnePartial) && (
                    <Fragment>
                      <SplitOrPartialPayment totalPayable={(balance || orders?.[0]?.balance)}/> <br/>
                    </Fragment>
                  )}
        
                  {((selectedPaymentMethod === "Split" || selectedPaymentMethod === "Partial") && !!onlyOnePartial) && (
                    <Fragment>
                      <Input type="number" name="total_partial" formatNumber label="Partial Amount"
                             placeholder="Enter amount paid"/>
                    </Fragment>
                  )}

                  <CustomerSubscription handleOpenCreateModal={handleOpenCreateModal} />

                  <br/><br/>

                  {/*This button is hidden, triggered by the ref from cart summary*/}
                  <Button ref={submitButtonRef} isLoading={createOrderMutation.isLoading} type="submit" id="submit_checkout" text="Submit" />
                </Fragment>
              )}
            </Fragment>
          </Form>

          <CartSummaryStyled className="cart_summary">
            <SpaceBetweenStyled>
              <Heading color="black" bold size={1.1}>
                {/*Amount Paid:*/}
              </Heading>
              <Heading color="black" bold size={1.1}>
                {/*{currencyFormatter(Number(orders?.[0]?.group_order?.group_total_cost) - Number(balance || orders?.[0]?.balance))}*/}
              </Heading>
            </SpaceBetweenStyled>
            
            <SpaceBetweenStyled>
              <Heading color="black" bold size={1.1}>Total Payable:</Heading>
              <Heading color="black" bold size={1.1}>
                {currencyFormatter(balance || orders?.[0]?.balance)}
              </Heading>
            </SpaceBetweenStyled>
  
            {businessSettings.exchange_rate && (
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Exchange Value: </Paragraph>
                <Paragraph color="black" bold>
                  {currencyFormatterFn("en-NG", businessSettings?.ex_currency)(Number(balance || orders?.[0]?.balance) * Number(businessSettings.currency_exchange_rate)) }
                </Paragraph>
              </SpaceBetweenStyled>
            )}

            <Button disabled={!selectedPaymentMethod}
                    isLoading={createOrderMutation.isLoading || payBalanceMutation.isLoading || mutation?.isLoading}
                    onClick={handleSubmitForm} text="Checkout"/>

          </CartSummaryStyled>
        </CartStyled>
      </ModalLayoutStyled>
    </Fragment>
  )
}

export default InvoiceCheckOutModal


const CustomPaymentMethod = memo(({ handleOpenCreateModal }) => {
  const { values } = useFormikContext()
  const { data: customPaymentMethod } = useCustomPaymentMethodQuery()

  const CreatePaymentMethod = () => (
    <label className="underline" onClick={() => handleOpenCreateModal("custom_payment_method")}>Create +</label>
  )

  return (
    <Fragment>
      {values.pay_mode === "others" && (
        <Select
          options={customPaymentMethod?.results}
          displayKey="payment_method"
          valueKey="id"
          name="other_payment"
          label="Payment Method"
          placeholder="Select custom payment method"
          displayName="other_payment_name"
          SideInfo={CreatePaymentMethod}
        />
      )}
    </Fragment>
  )
})


const CustomerSubscription = memo(() => {
  const { values } = useFormikContext()
  const { data: allCustomerSubscriptions } = useCustomerActiveSubscriptionByIdQuery(values.customer)

  return (
    <Fragment>
      {values.pay_mode === "subscription" && (
        <Fragment>
          <Select
            options={allCustomerSubscriptions?.results}
            displayKey="subscription_service_name"
            valueKey="id"
            label="Subscription package"
            placeholder="Select subscription package"
            name="subscription"
            displayName="subscription_service_name"
          />
        </Fragment>
      )}
    </Fragment>
  )
})
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {Fragment} from "react";

const TableSkeleton = ({ height = 50, count = 3, isAnalytics, width, noTop }) => {
	return (
		<Fragment>
			{isAnalytics && (
				<Skeleton baseColor="#C0C0C0" style={{ marginTop: "2rem" }} height={50} count={2}/>
			)}
			
			{!isAnalytics && (
				<Skeleton baseColor="#C0C0C0" style={{ ...(!noTop && { marginTop: "2rem" }), ...(width && { width }) }} height={height} count={count}/>
			)}
		</Fragment>
	)
}

export default TableSkeleton
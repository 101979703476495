export const dateFilterValue = {
	TODAY: "today",
	YESTERDAY: "yesterday",
	TOMORROW: "tomorrow",
	THIS_WEEK: "this_week",
	THIS_MONTH: "this_month",
	DAY_30: "30_days",
	DAY_90: "90_days",
	NEXT_DAY_30: "next_30_days",
	NEXT_DAY_90: "next_90_days",
	THIS_YEAR: "this_year",
	NEXT_N_DAYS: "NEXT_N_DAYS",
	ALL_TIME: "all_time",
	CUSTOM_PERIOD: "custom_period"
}

export const dateFilterValueEnum = {
	TODAY: "Today",
	YESTERDAY: "Yesterday",
	TOMORROW: "Tomorrow",
	THIS_WEEK: "This Week",
	THIS_MONTH: "This Month",
	DAY_30: "Last 30 days",
	DAY_90: "Last 90 days",
	THIS_YEAR: "This Year",
	ALL_TIME: "All Time",
	Next_30_DAYS: "Next 30 days",
	Next_90_DAYS: "Next 90 Days",
	CUSTOM_PERIOD: "Custom Period"
}


const dateFilter = [
	{name: "Today", value: dateFilterValue.TODAY},
	{name: "Yesterday", value: dateFilterValue.YESTERDAY},
	{name: "This Week", value: dateFilterValue.THIS_WEEK},
	{name: "This Month", value: dateFilterValue.THIS_MONTH},
	{name: "Last 30 days", value: dateFilterValue.DAY_30},
	{name: "Last 90 days", value: dateFilterValue.DAY_90},
	{name: "This Year", value: dateFilterValue.THIS_YEAR},
	{name: "All Time", value: dateFilterValue.ALL_TIME},
	{name: "Custom Period", value: dateFilterValue.CUSTOM_PERIOD},
]


export const expiryDateFilter = [
	{name: "Today", value: dateFilterValue.TODAY},
	{name: "Yesterday", value: dateFilterValue.YESTERDAY},
	{name: "Tomorrow", value: dateFilterValue.TOMORROW},
	{name: "This Week", value: dateFilterValue.THIS_WEEK},
	{name: "This Month", value: dateFilterValue.THIS_MONTH},
	{name: "Next 30 days", value: dateFilterValue.NEXT_DAY_30},
	{name: "Next 90 days", value: dateFilterValue.NEXT_DAY_90},
	{name: "This Year", value: dateFilterValue.THIS_YEAR},
	{name: "All Time", value: dateFilterValue.ALL_TIME},
	{name: "Custom Period", value: dateFilterValue.CUSTOM_PERIOD},
]

export default dateFilter
import {OrderDetailsCardStyled} from "../../styles/cardStyles";
import {Heading} from "../../styles/textStyles";
import {Line} from "../index";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {Link} from "react-router-dom";


const OrderDetailsCard = ({ half, title, data = []}) => {
  return (
    <OrderDetailsCardStyled $half={half}>
      <Heading size={1.3} color="black">{title}</Heading>
      <Line />

      {data.map((obj, idx) => (
        <SpaceBetweenStyled key={idx}>
          <Heading color="black">{obj.key}:</Heading>
          <Heading color="secondary" thin>
            {!obj.link ?
              (obj.value || "No Data") :
              (obj.key === "Name") ?
                <Link to={`/dashboard/staff/${obj.id}`}>{obj.value}</Link> :
                (obj.value || "No Data")
            }
          </Heading>
        </SpaceBetweenStyled>
      ))}
    </OrderDetailsCardStyled>
  )
}

export default OrderDetailsCard
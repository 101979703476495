import {ArrowLeft} from "iconsax-react";
import {Fragment, useEffect, useState} from "react";
import {FlexStyled} from "../../../styles/utilStyles";
import {NoProductImage} from "../../../assets/images";
import useSellContext from "../../../hooks/useContexts/useSellContext";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {sellReducerActions} from "../../../reducers/sellReducer";
import {ProductCardCountStyled} from "../../../styles/cardStyles";
import {Button, CheckBox, Form, Image, Input, Select, TextArea} from "../../index";
import {addToCartFormDefaultValues} from "../../../data/defaultFormValues";
import {AddToCartModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {useAddOnQuery} from "../../../hooks/useServices/useAddOnServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import {CustomSellingPriceStyled} from "../../../styles/formStyles";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../data/permissions";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import useModal from "../../../hooks/useModal";
import {isAppStoreFront} from "../../../utils";
import {productSaleType} from "../../../data/selectField/product";


const AddToCartModal = ({ onClose, isDisableIncrement, maxUnit }) => {
  const permissionFn = useActionPermissionFn()
  const {toast, isOffline} = useGlobalContext()
  const businessSettings = useBusinessSettingQuery()
  
  const [addonSearch, setAddonSearch] = useState("")
  const {data: allAddOns, isSuccess: isAddonsSuccess, isLoading: isAddonsLoading} = useAddOnQuery(addonSearch)
  const [selectedAddOns, setSelectedAddOns] = useState([])
  const {currencyFormatter} = useDataTypeFormatter()
  const {activeProductForAddToCartModal, reducer, cart} = useSellContext()
  const {
    image_path,
    name,
    selling_price: defaultSellingPrice,
    stock_unit,
    unit_increment,
    unit,
    complimentary,
    id: productId
  } = activeProductForAddToCartModal
  
  const [selectedUnitMeasurement, setSelectedUnitMeasurement] = useState(activeProductForAddToCartModal?.selected_unit_measurement || {})
  
  const [isCustomSellingPrice, setIsCustomSellingPrice] = useState(false)
  const [isComplimentary, setIsComplimentary] = useModal(complimentary)
  const [selling_price, setSellingPrice] = useState(defaultSellingPrice)
  const [is_selling_faulty, set_is_selling_faulty] = useState(false)
  
  const [productCount, setProductCount] = useState(Number(unit || unit_increment || 1))
  
  useEffect(() => {
    setSelectedAddOns(activeProductForAddToCartModal.service || [])
  }, [activeProductForAddToCartModal])
  
  const CustomSellingPrice = () => {
    const [custom, setCustom] = useState(false)
  
    const handleShowCustomSellingPrice = (value, o, set) => {
      if(!value) {
        set("custom", "")
        set("custom_format", "")
        setSellingPrice(defaultSellingPrice)
      }
    
      setCustom(value)
      setIsCustomSellingPrice(value)
    }
  
    const handleCustomPrice = (price) => {
      !!price ? setSellingPrice(price) : setSellingPrice(0)
    }
    
    return (
      <CustomSellingPriceStyled>
          <CheckBox optional name="isCustom" checked={custom} afterCheck={handleShowCustomSellingPrice} labelProp="Add custom selling price" />
          
        {custom && (
            <Input isCurrency onChange={handleCustomPrice} name="custom" type="number" formatNumber
                   placeholder="Custom selling price"/>
          )}
      </CustomSellingPriceStyled>
    )
  }

  const handleIncrement = (result) => {
    if(!isOffline && (Number(result) > Number(stock_unit))) {
      if(!businessSettings.sell_below_stock && activeProductForAddToCartModal.use_stock) {
        toast.error("You are not allowed to sell above stock unit, please contact business owner", "prevent_stock_increment")
        return
      }
    }

    setProductCount(result)
  }
  
  const handleDynamicCountChange = (value) => {
    if(!isOffline && (Number(value) > Number(stock_unit))) {
      if(!businessSettings.sell_below_stock && activeProductForAddToCartModal.use_stock) {
        toast.error("You are not allowed to sell above stock unit, please contact business owner", "prevent_stock_increment")
        return
      }
    }
  
    setProductCount(Number(value))
  }

  const calculateTotalCost = () => {
    const totalPriceOfAddOns = selectedAddOns.reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.cost
    }, 0)
  
    const mainSellingPrice = function () {
      if (!Number(selling_price).toString() && !selectedUnitMeasurement?.selling_price && !!activeProductForAddToCartModal?.meta_measurement && activeProductForAddToCartModal?.meta_measurement?.length > 0) {
        setSelectedUnitMeasurement(activeProductForAddToCartModal?.meta_measurement?.[0])
        return Number(activeProductForAddToCartModal?.meta_measurement?.[0]?.selling_price)
      }
    
      const sellingPrice = isCustomSellingPrice ? selling_price : (selectedUnitMeasurement?.selling_price || selling_price)
      return Number(sellingPrice)
    }()
  
    return currencyFormatter(Number(productCount * (mainSellingPrice + Number(totalPriceOfAddOns))).toFixed(2))
  }

  const handleAddToCart = (values) => {
    const getSellingPrice = () => {
      if (isComplimentary) return 0
  
      if (selectedUnitMeasurement?.selling_price) {
        if (values.isCustom) {
          return Number(selectedUnitMeasurement?.selling_price) !== Number(selling_price) ? Number(selling_price).toFixed(2) : Number(selectedUnitMeasurement?.selling_price).toFixed(2)
        }
  
        return Number(selectedUnitMeasurement?.selling_price).toFixed(2)
      }
  
      return Number(selling_price).toFixed(2)
    }
  
    if (selectedUnitMeasurement?.name && !businessSettings.sell_below_stock && activeProductForAddToCartModal.use_stock && ((Number(selectedUnitMeasurement.quantity) * productCount) > stock_unit)) {
      toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
      return
    }
  
    if (!activeProductForAddToCartModal?.id) {
      toast.error("Please re-add this product to cart", "product_message_")
      onClose()
      return
    }
  
    if (Number(productCount) <= 0) {
      toast.error("You can not sell below or a quantity of 0", "product_message_")
      return
    }
  
    reducer({
      type: sellReducerActions.ADD_PRODUCT_TO_CART,
      product: {
        ...activeProductForAddToCartModal,
        ...values,
        unit: productCount,
        service: selectedAddOns,
        isWithoutModal: false,
        selling_price: Number(getSellingPrice()),
        complimentary: isComplimentary,
        ...(selectedUnitMeasurement?.name && {
          selected_unit_measurement: {
            ...selectedUnitMeasurement,
            quantity: productCount
          }
        }),
        ...(Number(defaultSellingPrice) !== Number(selling_price) && {sales_price: isComplimentary ? 0 : Number(selling_price).toFixed(2)}),
        ...(!!values.isCustom && {sales_price: Number(getSellingPrice())}),
        ...(isComplimentary && {sales_price: 0})
      },
    })
    onClose()
  }
  
  const [availableAddons, setAvailableAddons] = useState([])
  
  useEffect(() => {
    if(isAddonsSuccess) {
      const addons = allAddOns?.results?.map(item =>
        ({...item, fullName: `${item.name} - ${currencyFormatter(item.cost)}`})
      )
      
      setAvailableAddons(addons)
    }
  }, [isAddonsSuccess, addonSearch])

  const disableProductIncrement = () => {
    if(isOffline) return false
    if(isDisableIncrement && productCount < maxUnit) return false
    if(isDisableIncrement && productCount >= maxUnit) return true
  
    if(!activeProductForAddToCartModal.use_stock) return false
    if(stock_unit >= productCount) return false

    return !businessSettings.sell_below_stock;
  }
  
  const unitMeasurementOptions = () => {
    if (is_selling_faulty) {
      return activeProductForAddToCartModal?.faulty_meta_measurement?.map(measure => ({
        ...measure, main_name: `${measure.name} (${currencyFormatter(measure.selling_price)} per ${measure.name})`
      }))
    }
  
    return activeProductForAddToCartModal?.meta_measurement?.map(measure => ({
      ...measure, main_name: `${measure.name} (${currencyFormatter(measure.selling_price)} per ${measure.name})`
    }))
  }
  
  const handleSelectUnitMeasurement = (unit_measurement) => {
    if (!unit_measurement?.name) {
      setSelectedUnitMeasurement({})
      return
    }
    
    setSelectedUnitMeasurement({...unit_measurement, quantity: Number(unit_measurement.base_quantity)})
  }
  
  const handleSelectProductType = (value) => {
    if (value.value === true) {
      set_is_selling_faulty(true)
      setSellingPrice(activeProductForAddToCartModal.faulty_selling_price)
      return
    }
    
    setSellingPrice(activeProductForAddToCartModal.selling_price)
    set_is_selling_faulty(false)
  }
  
  const getDefaultFormValues = () => {
    if (activeProductForAddToCartModal?.selected_unit_measurement || selectedUnitMeasurement) {
      const measure = activeProductForAddToCartModal?.selected_unit_measurement || selectedUnitMeasurement
      
      return {
        ...addToCartFormDefaultValues,
        ...(measure?.name && {
          unit_measurement_name: measure.name,
          unit_measurement_name_display: `${measure.name} (${currencyFormatter(measure.selling_price)} per ${measure.name})`
        })
      }
    }
  
    return addToCartFormDefaultValues
  }
  
  const getButtonText = () => {
    if (cart.find(product => product.id === productId)) return "Save"
    if (isDisableIncrement) return "Modify Order"
    return "Add To Cart"
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <AddToCartModalStyled onClick={e => e.stopPropagation()}>
        
        <FlexStyled className="navigation_section">
          <ArrowLeft size={18} onClick={onClose}/>
          <FlexStyled>
            <Paragraph noTop color="menuGray">Back to point of sales</Paragraph>
            <Heading color="black">Add to cart</Heading>
          </FlexStyled>
        </FlexStyled>

        <FlexStyled className="phone_and_counter">
          <Image src={image_path || NoProductImage} alt="product" />

          <FlexStyled className="product_name_and_counter">
            <Heading color="black" size={1.5}>{name}</Heading>
            <Heading color="black" thin className="price">{calculateTotalCost()}</Heading>
  
            <ProductCardCountStyled>
              <Button onClick={handleIncrement.bind(this, productCount - Number(unit_increment || 1))} text="-" disabled={productCount === Number(unit_increment || 1)}/>
              <Form enableReinitialize values={{ count: productCount, count_format: productCount }}>
                <Input disabled={productCount === stock_unit} type="number" name="count" onChange={handleDynamicCountChange} />
              </Form>
              <Button onClick={handleIncrement.bind(this, productCount + Number(unit_increment || 1))} text="+" disabled={disableProductIncrement()}/>
            </ProductCardCountStyled>
          </FlexStyled>
        </FlexStyled>
        
        <Form values={getDefaultFormValues()} onSubmit={handleAddToCart}>
          {Number(activeProductForAddToCartModal?.faulty_stock_unit || 0) > 0 && (
            <Select
              optional
              valueKey="value"
              displayKey="name"
              displayName="sell_faulty_display"
              name="sell_faulty"
              label="Product Type"
              placeholder="Choose product type"
              clearFilter
              options={productSaleType}
              updateFn={handleSelectProductType}
            />
          )}
  
          {!!activeProductForAddToCartModal?.meta_measurement && activeProductForAddToCartModal?.meta_measurement?.length > 0 && (
            <Select
              optional
              valueKey="name"
              displayKey="main_name"
              displayName="unit_measurement_name_display"
              name="unit_measurement_name"
              label="Unit Measurement"
              placeholder="Select Unit Measurement"
              clearFilter
              options={unitMeasurementOptions()}
              updateFn={handleSelectUnitMeasurement}
              clearCondition="sell_faulty_display"
            />
          )}
  
  
          {!isDisableIncrement && (
            <Fragment>
              {(permissionFn.check(actionsPermissions.changeSalesPriceWhenMakingSales) && !isAppStoreFront()) && CustomSellingPrice()}
      
              {(availableAddons?.length !== 0 || addonSearch) && (
                <MultiSelectInput
                  isLoading={isAddonsLoading}
                  optional
                  onSearch={setAddonSearch}
                  options={availableAddons}
                  displayName="fullName"
                  setSelected={setSelectedAddOns}
                  label="Select Addons"
                  placeholder="Select add-ons for this order"
                  selectedValues={activeProductForAddToCartModal.service || []}
                />
              )}
  
              {!isAppStoreFront() && (
                <Fragment>
                  <TextArea optional className="fullWidth" rows={3} name="remark" placeholder="Add extra note to order " label="Describe Order" />
                  <CheckBox optional name="isComplimentary" checked={isComplimentary} afterCheck={setIsComplimentary} labelProp="Sell as complimentary" />
                </Fragment>
              )}
            </Fragment>
          )}
  
          <Button text={getButtonText()} type="submit"/>
        </Form>
      </AddToCartModalStyled>
    </ModalLayoutStyled>
  )
}

export default AddToCartModal
import {
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton, PageSummaryCard, ScreenLoading,
  SearchAndFilter, SupplierModal,
  Table, TableItemWithDeleteAndEditAction,
  TablePagination, UploadBulkProductModal
} from "../../../../components";
import {
  useCreateSupplierMutation, useDeleteSupplierMutation, useEditSupplierMutation,
  useSupplierByBusinessIdQuery, useUploadBulkSupplierService
} from "../../../../hooks/useServices/useSupplierServices";

import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import {AddCircle} from "iconsax-react";
import {NoSupplierImage} from "../../../../assets/images";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import EditSupplierModal from "../../../../components/Modal/InventoryModal/SupplierModals/EditSupplierModal";
import {supplierFormDefaultValues} from "../../../../data/defaultFormValues";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import supplierQueryKey from "../../../../hooks/useServices/useSupplierServices/supplierQueryKey";
import {actionsPermissions} from "../../../../data/permissions";
import modalNames from "../../../../hooks/useModalConnector/modalNames";


const Supplier = () => {
  const { toast, navigate } = useGlobalContext()
  const [editFormValues, setEditFormValues] = useState(supplierFormDefaultValues)
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useModal(modalNames.CREATE_SUPPLIER_ORDER)
  const [showEditSupplierModal, setShowEditSupplierModal] = useModal()
  const [showUploadSupplierModal, setShowUploadSupplierModal] = useModal()
  
  const { supplierTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allSuppliers } = useSearchQuery(debounceState, useSupplierByBusinessIdQuery)
  const createSupplierMutation = useCreateSupplierMutation({ successFn: setShowCreateSupplierModal })
  const editSupplierMutation = useEditSupplierMutation({ successFn: setShowEditSupplierModal })
  const deleteSupplierMutation = useDeleteSupplierMutation({ successFn: () => {} })
  const uploadSupplierMutation = useUploadBulkSupplierService({ successFn: setShowUploadSupplierModal })
  
  const handleCreateSupplier = (values) => {
    createSupplierMutation.mutate(values)
  }
  
  const handleEditSupplier = (values) => {
    editSupplierMutation.mutate(values)
  }
  
  const handleEditAction = (data) => {
    setEditFormValues(data)
    setShowEditSupplierModal()
  }
  
  const handleDeleteAction = (data) => {
    toast.confirm(
      "Are you sure you want to delete this supplier",
      () => deleteSupplierMutation.mutate(data.id)
    )
  }
  
  const handleViewSupplier = (data) => {
    navigate(`/dashboard/inventory/supplier/${data.id}`)
  }
  
  const handleUploadSupplier = (type, values) => {
    uploadSupplierMutation.mutate({ type, values })
  }
  
  return (
    <div>
      {showCreateSupplierModal && <SupplierModal onClose={setShowCreateSupplierModal} handleSubmit={handleCreateSupplier} mutation={createSupplierMutation} />}
      {showEditSupplierModal && <EditSupplierModal onClose={setShowEditSupplierModal} formValues={editFormValues} handleSubmit={handleEditSupplier} mutation={editSupplierMutation} />}
      {showUploadSupplierModal && <UploadBulkProductModal downloadTemplateLink="https://ovaloopbucket1.s3.amazonaws.com/media/Suppliers_templates.xlsx" noType={true} file_name="supplier_file" modalText="Upload Suppliers" onClose={setShowUploadSupplierModal} handleSubmit={handleUploadSupplier} mutation={uploadSupplierMutation} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allSuppliers.refetch} />
      )}
      
      <Fragment>
        {allSuppliers.isEmpty && (
          <Fragment>
            <PageHeader title="Suppliers" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No product"
              imgSrc={NoSupplierImage}
              heading="No supplier yet"
              subHeading="Create a contact list for each supplier."
              buttonText="Create"
              button2Text="Upload"
              button2Click={setShowUploadSupplierModal}
              buttonClick={setShowCreateSupplierModal}
              permissionCode={actionsPermissions.createSupplier}
            />
          </Fragment>
        )}

        {allSuppliers.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonText2="Upload" buttonClick2={setShowUploadSupplierModal} title="Suppliers" buttonText="Create" buttonClick={setShowCreateSupplierModal} Icon={AddCircle} permissionCode={actionsPermissions.createSupplier} />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allSuppliers.isQueryLoading}
                only title="Total Number Of Suppliers"
                value={allSuppliers?.data?.count}
              />
            </PageSummaryStyled>

            <SetUpLayoutStyled>

              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search supplier by name, phone, email"
              />

              <Table bg headers={supplierTable.headers} noCols={supplierTable.headers.length}>
                {allSuppliers.isQueryWithData && (
                  allSuppliers?.data?.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      onClick={handleViewSupplier}
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allSuppliers.currentCount}
                      keys={supplierTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.editSupplier}
                      deletePermission={actionsPermissions.deleteSupplier}
                    />
                  )
                )}

                {allSuppliers.isQueryWithNoData && (
                  <NoQueryData text={allSuppliers.isSearchEmptyMessage("Supplier", "name, phone or email")} />
                )}

                {allSuppliers.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              {allSuppliers.showPagination &&
                <TablePagination
                  query={allSuppliers}
                  queryKey={supplierQueryKey.mainList(debounceState.searchValue)}
                />
              }
            </SetUpLayoutStyled>

            {allSuppliers.showPagination &&
              <TablePagination
                query={allSuppliers}
                queryKey={supplierQueryKey.mainList(debounceState.searchValue)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Supplier;
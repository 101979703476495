import {useFormikContext} from "formik";
import {Heading} from "../../styles/textStyles";
import {FlexStyled} from "../../styles/utilStyles";
import {FileInputStyled} from "../../styles/formStyles";
import {Image} from "../index";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const ImageInput = ({
  name, className, placeholder, accept,
  optional, handleFileChange,
	fileName, fileBase64, shape
}) => {
	
	const { allFieldsNotRequired } = useGlobalContext()
	const { values } = useFormikContext()
	
	const isRequired = () => {
		return !(allFieldsNotRequired ?? optional);
	}
	
	return (
		<div>
			<FileInputStyled className={className}>
				<label htmlFor={name}>
					<FlexStyled>
						<div className="browse_box">
							<Heading className="browse" color="gray">{placeholder || "Browse"}</Heading>
						</div>
						<Heading className="secondary" color={!!fileName ? "black" : "gray" }>{fileName || "No File Selected"}</Heading>
					</FlexStyled>
				</label>
				
				<Image shape={shape} className="preview" src={fileBase64 || values.image_path} alt={fileName} />
				<input
					onChange={handleFileChange}
					id={name}
					name={name}
					type="file"
					required={isRequired()}
					accept={accept || "image/jpeg image/png"}
				/>
			</FileInputStyled>
		</div>
	)
}

export default ImageInput
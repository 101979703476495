import {Fragment, useState} from "react";
import {
  PageHeader,
  SearchAndFilter,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {useAllReferralQuery, useReferralApprovalMutation} from "../../../hooks/useServices/useReferralServices";
import {useModal} from "../../../hooks";
import ReferralModal from "../../../components/Modal/StaffDasboardModals/ReferralModal";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const Referrals = () => {
  const { referralsListForBackendTable } = tableHeadersAndValueKeys
  
  const [showModal, setShowModal] = useModal()
  const [referralAccount, setReferralAccount] = useState({})
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const query = { search: debounceState.searchValue }
  const { mainQuery, ...allReferrals } = useOrderQueryWithSearchAndFilter(query, useAllReferralQuery)
  const approveReferralMutation = useReferralApprovalMutation({ successFn: setShowModal })
  
  const handleViewReferral = (referral) => {
    setReferralAccount(referral)
    setShowModal()
  }
  
  const handleUpdateReferral = (user) => {
    approveReferralMutation.mutate({
      ...user, approved_status: true, associate_user: user.id,
      referral_code: user.referral_code?.split(" ").join("_")
    })
  }
  
  return (
    <Fragment>
      <PageHeader title="All Referrals" />
      {showModal && <ReferralModal formValues={referralAccount} handleSubmit={handleUpdateReferral} onClose={setShowModal} mutation={approveReferralMutation} />}
      
        <Fragment>
          {allReferrals.showTable && (
            <Fragment>
              <SetUpLayoutStyled>
                <SearchAndFilter
                  noFilter
                  dispatch={debounceReducer}
                  searchPlaceholder="Search staff by name, phone, email"
                />
                
                <Table headers={referralsListForBackendTable.headers} noCols={referralsListForBackendTable.headers.length}>
                  {allReferrals.isQueryWithData && (
                    allReferrals?.data?.results?.map((log, k) => (
                      <TableItemWithStatus
                        count={k}
                        onView={handleViewReferral}
                        pageCount={allReferrals.currentCount}
                        key={k}
                        data={{ ...log, approval_status: (!!log.referral_code ? "approved" : "pending")  }}
                        noAction
                        statusPosition={3}
                        keys={referralsListForBackendTable.values}
                      />
                    ))
                  )}
      
                  {allReferrals.isQueryWithNoData && (
                    <NoQueryData text="No staffs" />
                  )}
      
                  {allReferrals.isQueryLoading && (
                    <TableSkeleton />
                  )}
  
                  {!!allReferrals?.data?.next && (
                    <TablePagination queryKey={["Referral", query]} query={allReferrals} />
                  )}
                </Table>
              </SetUpLayoutStyled>
  
              {!!allReferrals?.data?.next && (
                <TablePagination queryKey={["Referral", query]} query={allReferrals} />
              )}
            </Fragment>
          )}
          
        </Fragment>
    </Fragment>
  )
}


export default Referrals
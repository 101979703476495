import {Multiselect} from "multiselect-react-dropdown";
import {SelectStyled} from "../../styles/formStyles";
import {ArrowDown2, ArrowUp2} from "iconsax-react";
import {useModal} from "../../hooks";
import {useRef} from "react";


const MultiSelectInput = ({ options, setSelected, displayName, placeholder, label, optional = false, hideSelectedList, selectedValues, onSearch, isLoading, className }) => {
  const inputRef = useRef(null)
  const [showModal, setShowModal] = useModal()

  const handleModal = () => {
    setShowModal()

    if(showModal) {
      inputRef.current.onBlur()
    }
    inputRef.current.onFocus()
  }
  
  
  const handleSelect = (selected) => {
    setSelected([...selected])
  }
  
  const handleSearch = (value) => {
    onSearch?.(value)
  }
  
  return (
    <SelectStyled className={className}>
      <label>{label}: </label>
      
      <Multiselect
        loading={isLoading}
        onSearch={handleSearch}
        required={!optional}
        showCheckbox
        ref={inputRef}
        options={options}
        selectedValues={selectedValues}
        hideSelectedList={hideSelectedList}
        onSelect={handleSelect}
        onRemove={handleSelect}
        displayValue={displayName}
        placeholder={placeholder}
      />

      <i className="multi_select">
        {showModal ? <ArrowUp2 size={20} onClick={handleModal} /> : <ArrowDown2 size={20} onClick={handleModal} />}
      </i>
    </SelectStyled>
  )
}

export default MultiSelectInput
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, Form, Select} from "../../../index";
import {useAllStoresQuery} from "../../../../hooks/useServices/useStoreServices";
import {FlexStyled} from "../../../../styles/utilStyles";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";


const MoveProductsModal = ({ onClose, handleSubmit, mutation }) => {
  const { getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()
  const { isSuccess, data: allStores } = useAllStoresQuery()
  
  
  const moveStores = (stores = []) => {
    return stores.filter(store => store.id !== businessId)
  }


  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Duplicate Product List</Heading>

        <Form values={{ business: "", display_store_name: "" }} onSubmit={handleSubmit}>
          <Select
            required
            valueKey="id"
            name="business"
            label="Select Store"
            className="fullWidth"
            displayKey="store_name"
            displayName="display_store_name"
            options={isSuccess && moveStores(allStores)}
            placeholder="Select store to move products to"
          />

          <FlexStyled>
            <Button type="submit" text="Duplicate" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default MoveProductsModal
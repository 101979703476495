import {useMutation, useQuery} from "react-query";
import {getAllObjectKeysWithValues} from "../../../../utils/handleQueryParams";
import {downloadAllUserService, getAllUserService} from "../../../../services/staffBackendServices/userServices";
import useGlobalContext from "../../../useContexts/useGlobalContext";
import reportDownload from "../../../../utils/reportDownload";
import errorHandler from "../../../../utils/errorHandler";

export const allUsersQueryKey = {
  all: ["ALL_USERS"],
  main: (queryParams) => ["ALL_USERS", getAllObjectKeysWithValues(queryParams)]
}


export const useAllUserQuery = (queryParams = {}) => {
  return useQuery({
    queryKey: allUsersQueryKey.main(queryParams),
    queryFn: () => getAllUserService(queryParams)
  })
}


export const useDownloadAllUserQueryMutation = () => {
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: (queryParams) => {
      return downloadAllUserService(queryParams)
    },
    
    onSuccess: (res) => {
      reportDownload(res, "user list")
      toast.success("User list downloading...")
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../index";
import { Heading } from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";


const EditDeliveryRateModal = ({ onClose, handleSubmit, mutation, formValues }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Delivery Rate</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <Input label="Name" placeholder="Delivery name" name="name"  />
          <Input label="Amount" type="number" formatNumber placeholder="Amount for this delivery" name="amount"  />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditDeliveryRateModal
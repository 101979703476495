export default {
  NGN: {
    flag: "🇳🇬",
    dialCode: "+234",
    locale: "en-NG",
    currency: "₦"
  },
  GHC: {
    flag: "🇬🇭",
    dialCode: "+233",
    locale: "en-GH",
    currency: "GH₵"
  },
  GHS: {
    flag: "🇬🇭",
    dialCode: "+233",
    locale: "en-GH"
  },
  USD: {
    flag: "🇺🇸",
    dialCode: "+234",
    locale: "en-US",
    currency: "$"
  },
  GBP: {
    flag: "🇬🇧",
    dialCode: "+44",
    locale: "en-GB",
    currency: "£"
  }
}
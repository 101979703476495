import PaymentMethod from "../../Forms/PaymentMethod";
import {Button, Form} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {ModalLayoutStyled, CreateCategoryModalStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import paymentMethodsList from "../../../data/selectField/paymentMethodsList";


const DeliveryVehicleTypeModal = ({ onClose, mutation, handleSubmit }) => {
	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Select Vehicle Type</Heading>

				<Form values={{ vehicle_type: "" }} onSubmit={handleSubmit}>
					<PaymentMethod name="vehicle_type" methods={paymentMethodsList} />

					<FlexStyled>
						<Button text="Create" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default DeliveryVehicleTypeModal
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Form} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {WIPStatus} from "../../../utils/table";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {Link} from "react-router-dom";


const ViewTransferModal = ({ onClose, formValues }) => {
  const { nairaCurrencyFormatter, dateFormatter } = useDataTypeFormatter()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">View Transfer</Heading>
      
        <Form values={{}} onSubmit={() => {}} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Bank Name: </Paragraph>
            <Paragraph color="black" size={1.2}>Providus Bank</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account Number: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_number}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }}></FlexStyled>
        
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Amount Paid: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.paid_amount)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Ovaloop Charges: </Paragraph>
            <Paragraph color="black" size={1.2}>-{nairaCurrencyFormatter(formValues.ovaloop_charges)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Settled Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(Number(formValues.amount) - Number(formValues.ovaloop_charges))}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }}></FlexStyled>
  
          {formValues?.originator_details?.source_account_name && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Sender Name: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.originator_details.source_account_name}</Paragraph>
            </FlexStyled>
          )}
  
          {formValues?.originator_details?.source_bank_name && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Sender Bank: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.originator_details.source_bank_name}</Paragraph>
            </FlexStyled>
          )}
  
          {formValues?.originator_details?.remark && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Remark: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.originator_details.remark}</Paragraph>
            </FlexStyled>
          )}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }}></FlexStyled>
  
          {!!formValues?.group_id && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Order ID: </Paragraph>
              <Link to={`/dashboard/orders/${formValues.group_id}`}>
                <Paragraph color="black" size={1.2}>{formValues.group_id}</Paragraph>
              </Link>
            </FlexStyled>
          )}
  
          {!!formValues?.transaction_id && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Transaction ID: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.transaction_id}</Paragraph>
            </FlexStyled>
          )}
  
          {!!formValues?.session_id && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Session ID: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.session_id}</Paragraph>
            </FlexStyled>
          )}
  
          {!!formValues?.settlement_id && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Settlement ID: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.settlement_id}</Paragraph>
            </FlexStyled>
          )}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Created: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.date)}</Paragraph>
          </FlexStyled>

          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}></FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Deposit Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
  

          
          <FlexStyled></FlexStyled>
        </Form>
    
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default ViewTransferModal
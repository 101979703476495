import {ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {StoreFrontAuthModalStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Button} from "../../../../components";
import {SpaceBetweenStyled} from "../../../../styles/utilStyles";


const StoreFrontLogoutModal = ({ onClose, handleSubmit }) => {
  return (
    <ModalLayoutStyled onClick={onClose}>
      <StoreFrontAuthModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Log out</Heading>
        <br/>
        
        <div className="intro">
          <Heading size={1.1} color="black" bold>Logout of your account</Heading>
          {/*<Paragraph color="black">Welcome back! Please enter .</Paragraph>*/}
        </div>
        
        <SpaceBetweenStyled className="logout">
          <Button type="button" text="Cancel" className="cancel" onClick={onClose} />
          <Button type="button" text="Log out" onClick={handleSubmit} />
        </SpaceBetweenStyled>
      </StoreFrontAuthModalStyled>
    </ModalLayoutStyled>
  )
}

export default StoreFrontLogoutModal
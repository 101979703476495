import {Button, DatePicker, Form, Select} from "../index";
import {AddCircle, ArrowLeft, SearchNormal1} from "iconsax-react";
import {Heading, Paragraph} from "../../styles/textStyles";
import {useActionPermission} from "../../hooks/usePermissions";
import {FlexStyled, PageHeaderWithButtonStyled} from "../../styles/utilStyles";
import {useTheme} from "styled-components";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import dateFilter, {dateFilterValue} from "../../data/selectField/dateFilter";
import {Fragment, useMemo, useState} from "react";
import {useQueryClient} from "react-query";
import {globalReducerActions} from "../../reducers/globalReducer";
import handleDateFilterValue, {getDateFilterEnumByDateRange} from "../../utils/handleDateFilterValue";
import {getWindowQueryParams} from "../../utils/handleQueryParams";


const PageHeaderWithButtonAndDateFilter = ({
	                                           title,
	                                           buttonText,
	                                           buttonClick,
	                                           Icon,
	                                           permissionCode,
	                                           isBack,
	                                           backToText,
	                                           placeholder,
	                                           queryKeysAndFunctions = [],
	                                           searchValue,
	                                           setDateFilter,
	                                           callWhenBeforeFilterQuery = () => {
	                                           },
	                                           otherData = {},
	                                           globalStateKey,
	                                           dateFilterDefaultValue,
	                                           dateFilterOptions,
	                                           allowCreateOffline
                                           }) => {
	const theme = useTheme()
	const showButton = useActionPermission(permissionCode)
	
	const queryClient = useQueryClient()
	const [filter, setFilter] = useState("")
	const {toast, getBusinessId, globalReducer, globalState, navigate, isOffline} = useGlobalContext()
	
	
	const typeAndState = {
		expenses: {type: globalReducerActions.SET_EXPENSES_DATE_FILTER, state: globalState.expensesDateFilterType},
		wip: {type: globalReducerActions.SET_WIP_DATE_FILTER, state: globalState.wipDateFilterType},
		staffAnalytics: {type: globalReducerActions.SET_STAFF_DATE_FILTER, state: globalState.staffDateFilterType},
		orderAnalytics: {type: globalReducerActions.SET_ORDER_DATE_FILTER, state: globalState.orderAnalyticsDateFilterType},
	}
	
	const dateFilterValueToDisplay = useMemo(() => {
		const {toDateValue, fromDateValue} = getWindowQueryParams()
		
		if (toDateValue && fromDateValue)
			return getDateFilterEnumByDateRange({toDateValue, fromDateValue})
		
		return dateFilterDefaultValue
	}, [])
	
	
	const [{from_date, to_date}, setDate] = useState({
		from_date: "",
		to_date: ""
	})
	
	const handleChange = (type, value) => {
		setDate(prevState => ({
			...prevState,
			[type]: value
		}))
	}

	const handleSearch = () => {
		if(!from_date) {
			toast.error("Input Start Date")
			return
		}

		if(!to_date) {
			toast.error("Input End Date")
			return
		}

		callWhenBeforeFilterQuery?.()

		const data = { business_id: getBusinessId(), searchValue, from_date, to_date, ...otherData }
		setDateFilter?.({ from_date, to_date })
		queryKeysAndFunctions.forEach(({ key, fn }) => {
			queryClient.fetchQuery({
				queryKey: key,
				queryFn: () => fn(data)
			})
		})
	}

	const handleDateFilterSelect = (filterType) => {
		setFilter(filterType.value)
		
		globalReducer({
			type: typeAndState[globalStateKey]?.type,
			filterType: filterType.name
		})
		
		if (filterType.value === dateFilterValue.CUSTOM_PERIOD) {
			return
		}
		
		const {toDate, fromDate} = handleDateFilterValue(filterType.value)
		
		callWhenBeforeFilterQuery?.()
		
		const data = {business_id: getBusinessId(), searchValue, from_date: fromDate, to_date: toDate, ...otherData}
		setDateFilter?.({from_date: fromDate, to_date: toDate})
		queryKeysAndFunctions.forEach(({key, fn}) => {
			queryClient.fetchQuery({
				queryKey: key,
				queryFn: () => fn(data)
			})
		})
	}

	const isButtonDisabled = () => {
		if(allowCreateOffline) return false
		if(isOffline) return true
		return false
	}
	
	return (
		<PageHeaderWithButtonStyled>
			{isBack && (
				<FlexStyled className="navigation_section">
					<ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)}/>
					<FlexStyled>
						<Paragraph noTop color="menuGray">{backToText || "Back to"}</Paragraph>
						<Heading color="black">{title}</Heading>
					</FlexStyled>
				</FlexStyled>
			)}

			{!isBack && <Heading color="black" size={1.7}>{title}</Heading>}
			
			<div className="with_date_filter">
				<Form values={{
					from_date: "",
					to_date: "",
					filter: "",
					display_filter: dateFilterValueToDisplay || typeAndState[globalStateKey]?.state
				}}>
					<FlexStyled className="date_filter_fields">
						{!!buttonText &&
							<Button text={buttonText} type="button" disabled={isButtonDisabled()} onClick={buttonClick}
							        Icon={Icon || AddCircle} permissionCode={permissionCode}/>}
						
						<Select
							className="date_select_field"
							name="filter"
							valueKey="value"
							displayKey="name"
							options={dateFilterOptions || dateFilter}
							displayName="display_filter"
							placeholder={placeholder || "Filter analytics by date"}
							updateFn={handleDateFilterSelect}
						/>

						{filter === dateFilterValue.CUSTOM_PERIOD && (
							<Fragment>
								<DatePicker labelBg="background" isTodayMax required onChange={e => handleChange("from_date", e)} noBottomMargin name="from_date" placeholder="Date from" />
								<DatePicker labelBg="background" required onChange={e => handleChange("to_date", e)} noBottomMargin name="to_date" placeholder="Date to" />
								<SearchNormal1 type="submit" onClick={handleSearch} size={23} color="black" />
							</Fragment>
						)}
					</FlexStyled>
				</Form>

			</div>


			{!!buttonClick && (
				<div className="mobile_view">
					{(showButton && Icon) && <Icon color="#7C7C7A" onClick={buttonClick} />}
					{(showButton && !Icon) && <AddCircle color="#7C7C7A" onClick={buttonClick} />}
				</div>
			)}
		</PageHeaderWithButtonStyled>
	)
}

export default PageHeaderWithButtonAndDateFilter
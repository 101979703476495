import styled from "styled-components";
import {
  ChipStyled,
  FlexCenterStyled,
  FlexColumnStyled,
  FlexStyled,
  SectionStyled,
  SpaceBetweenStyled
} from "./utilStyles";
import {Heading, Paragraph} from "./textStyles";
import {ButtonStyled, InputStyled} from "./formStyles";


const SubscriptionCardStyled = styled("div")`
  padding: 1rem 0 0;
  border-radius: .3rem;
  width: ${props => props.$isDashboard ? "75%" : "calc(65% - 2rem)"};
  background: ${props => props.theme.disabled};
  border: 1px solid ${props => props.theme.disabled};
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 100%;
  }

  ${FlexColumnStyled} {
    gap: .5rem;
  }
  
  ${SpaceBetweenStyled} {
    padding: 0 1.5rem;
    margin-bottom: 2.5rem;
    
    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      gap: 1.5rem;
    }

    ${ChipStyled} {
      * {
        font-family: Bold, serif;
        color: white;
      }

      cursor: auto;
      border-radius: 0;
      padding: .3rem 1.5rem;
      margin-right: calc(-1.5rem - 1px);
      background: ${props => props.$active ? props.theme.success : props.theme.gray};
    }


    ${FlexColumnStyled}:nth-child(2) {
      text-align: right;
      
      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        text-align: left;
      }
    }
  }
  
  ${SectionStyled} {
    margin: 0;
    padding: 1.5rem;
    background: ${props => props.theme.background};

    ${FlexStyled}.info {
      ${FlexColumnStyled} {
        gap: .7rem;
        text-align: center;
        width: calc((100% / 4) - 1rem);
      }

      ${FlexColumnStyled}:first-of-type {
        text-align: left;
      }

      ${FlexColumnStyled}:last-of-type {
        text-align: right;
      }
    }
    
    .section_line {
      position: relative;
      margin-top: 3.3rem;
      
      ${ChipStyled} {
        top: -1.1rem;
        width: 4.5rem;
        text-align: center;
        position: absolute;
        border-radius: 10%;
        right: calc(50% - 3.5rem);
        background: ${props => props.theme.background};
      }
    }
    
    .addons {
      padding: 1.7rem 0 2rem;
      text-align: center;
      
      ${SpaceBetweenStyled} {
        padding: 0;
      }
      
      form {
        width: 35%;
        ${FlexCenterStyled} {
          width: 100%;
          align-items: center;
          gap: .5rem;
          justify-content: flex-end;
        }
        ${InputStyled}, ${SpaceBetweenStyled} {
          width: 40%;
          padding: 0;
          margin-bottom: 0;
        }
        input {
          border-radius: .2rem;
          padding: .5rem .5rem .5rem .7rem;
        }
      }
      
      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        ${SpaceBetweenStyled} {
          gap: .5rem;
        }
        form {
          width: 60%;
  
          ${FlexCenterStyled} {
            justify-content: flex-start;
          }
          
          input {
            padding: .4rem .8rem .4rem 1rem;
          }
        }
      }
    }
    
    .manage_subscription {
      padding: .5rem 0;
      text-align: center;
      
      ${Paragraph} {
        cursor: pointer;
        text-decoration: underline ${props => props.theme.primary};
      }
    }
  }
`

const SubscriptionSummaryStyled = styled("div")`
  width: ${props => props.$isDashboard ? "45%" : "30%"};
  height: fit-content;
  border-radius: .3rem;


  > ${Heading} {
    padding: 1.5rem 1rem;
    background: ${props => props.theme.disabled};

  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 1rem;
    background: ${props => props.theme.background};
  }

  ${ButtonStyled} {
    margin-top: 1rem;
  }
`

const SubscriptionLayout = styled("div")`
  padding: ${props => props.$isDashboard ? 0 : "3rem"};
  margin-bottom: 2rem;
  display: flex;
  
  > ${FlexCenterStyled} {
    width: 65%;
    flex-direction: column;

    .top_actions {
      width: 100%;
      margin-bottom: .7rem;

      ${Paragraph} {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 2rem;
    flex-direction: column;
    
    > ${FlexCenterStyled} {
      width: 100%;
      
      .top_actions {
        width: 100% !important;
      }
    }
  
    ${SubscriptionCardStyled} {
      height: fit-content;
      width: 100%;
      padding: .3rem;
    }
    
    ${SubscriptionSummaryStyled} {
      width: 100%;
    }
  }
  
  @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
    ${FlexCenterStyled} {
      width: 75%;
    }
  }
`

export { SubscriptionCardStyled, SubscriptionLayout, SubscriptionSummaryStyled }
import axios from "../../axios"
import handleQueryParams from "../../utils/handleQueryParams";

export const getProductionService = async (query = {}) => {
	try {
		const url = handleQueryParams("/production", query)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getProductionByIdService = async (id) => {
	try {
		const { data } = await axios.get(`/production/${id}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const startProductionService = async (body) => {
	try {
		const { data } = await axios.post("/production/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateProductionService = async (id, body) => {
	try {
		const { data } = await axios.patch(`/production/${id}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateProductionLogService = async (id, body) => {
	try {
		const { data } = await axios.patch(`/production_log/${id}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getProductionDistributionService = async (query = {}) => {
	try {
		const url = handleQueryParams("/production_distribution/", query)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createProductionDistributionService = async (body) => {
	try {
		const { data } = await axios.post("/production_distribution/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateProductionDistributionService = async (body) => {
	try {
		const { data } = await axios.patch("/production_distribution/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
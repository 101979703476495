import {
  CreateProductionStageModal, EditProductionStageModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import {customPaymentMethodFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {CategoryImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {
  useProductionStagesQuery,
  useCreateProductionStagesMutation,
  useDeleteProductionStagesMutation,
  useUpdateProductionStagesMutation,
} from "../../../hooks/useServices/useProductionServices/useProductionStageServices";


const ProductionStage = () => {
  const { toast } = useGlobalContext()
  const [showCreateProductionStagesModal, setShowCreateProductionStagesModal] = useModal()
  const [showEditProductionStagesModal, setShowEditProductionStagesModal] = useModal()
  const {
    createProductionStage: createProductionStagesPermission,
    updateProductionStage: updateProductionStagesPermission,
    deleteProductionStage: deleteProductionStagesPermission
  } = actionsPermissions
  
  const { productionUnitMeasurementTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allProductionStages } = useOrderQueryWithSearchAndFilter({ search: debounceState.searchValue }, useProductionStagesQuery, true)
  const [editProductionStagesFormValues, setEditProductionStagesFormValues] = useState(customPaymentMethodFormDefaultValues)
  const deleteProductionStagesMutation = useDeleteProductionStagesMutation({ successFn: () => {} })
  const editProductionStagesMutation = useUpdateProductionStagesMutation({ successFn: setShowEditProductionStagesModal })
  const createProductionStagesMutation = useCreateProductionStagesMutation({ successFn: setShowCreateProductionStagesModal })
  
  
  const handleCreateProductionStages = (values) => {
    createProductionStagesMutation.mutate(values)
  }
  
  const handleEditProductionStages = (values) => {
    editProductionStagesMutation.mutate(values)
  }
  
  const handleEditAction = (customPaymentMethod) => {
    setEditProductionStagesFormValues(customPaymentMethod)
    setShowEditProductionStagesModal()
  }
  
  const handleDeleteAction = (customPaymentMethod) => {
    toast.confirm(
      "Are you sure?",
      deleteProductionStagesMutation.mutate.bind(this, customPaymentMethod.id),
    )
  }
  
  return (
    <SetUpMainStyled>
      {showCreateProductionStagesModal && <CreateProductionStageModal onClose={setShowCreateProductionStagesModal} handleSubmit={handleCreateProductionStages} mutation={createProductionStagesMutation} />}
      {showEditProductionStagesModal && <EditProductionStageModal onClose={setShowEditProductionStagesModal} handleSubmit={handleEditProductionStages} mutation={editProductionStagesMutation} formValues={editProductionStagesFormValues} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allProductionStages.isEmpty && (
          <Fragment>
            <PageHeader title="Production Stages" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={CategoryImage}
              heading="No Production Stage"
              buttonText="Add new stage"
              buttonClick={setShowCreateProductionStagesModal}
              permissionCode={createProductionStagesPermission}
            />
          </Fragment>
        )}
        
        {allProductionStages.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Production Stages"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateProductionStagesModal}
              permissionCode={createProductionStagesPermission}
            />
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search unit measurement by name"
              />
              
              <Table headers={productionUnitMeasurementTable.headers} noCols={productionUnitMeasurementTable.headers.length}>
                {allProductionStages.isQueryWithData && (
                  allProductionStages.data.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allProductionStages.currentCount}
                      keys={productionUnitMeasurementTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateProductionStagesPermission}
                      deletePermission={deleteProductionStagesPermission}
                    />
                  )
                )}
                
                {allProductionStages.isQueryWithNoData && (
                  <NoQueryData text={allProductionStages.isSearchEmptyMessage("Custom payment method", "")} />
                )}
                
                {allProductionStages.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {!!allProductionStages?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allProductionStages?.data?.next && <TablePagination />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ProductionStage
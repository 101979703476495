import axios from "../../axios";
import getInventoryServiceStructure from "./inventoryServiceStructure"
import handleQueryParams from "../../utils/handleQueryParams";

const getInventoryByExpiryService = async (queryParams = {}) => {
  try {
    const url = handleQueryParams("/inventory", { ...queryParams, expiry: true })
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e
  }
}

export const updateInventoryByExpiryService = async (businessId, id, body) => {
  try {
    const { data } = await axios.patch(`/inventory/${id}?business_id=${businessId}`, body)
    return data
  } catch (e) {
    throw e
  }
}

const getInventoryByBusinessIdService = async (queryParams = {}) => {
  try {
    const url = handleQueryParams("/inventory", queryParams)
    const {data} = await axios.get(url)
    return getInventoryServiceStructure(data, {businessId: queryParams?.business_id})
  } catch (e) {
    throw e
  }
}

export const getFaultyProductLogByBusinessIdService = async (queryParams = {}) => {
  try {
    const url = handleQueryParams("/faulty_product_log", queryParams)
    const {data} = await axios.get(url)
    return data
  } catch (e) {
    throw e
  }
}

const updateInventoryService = async (body) => {
  try {
    const {data} = await axios.patch(`/product_distribution/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateFacultyProductLogService = async (logId, body) => {
  try {
    const {data} = await axios.patch(`/faulty_product_log/${logId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateFaultyProductInventoryService = async (body) => {
  try {
    const {data} = await axios.patch(`/faulty_product_move/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getInventoryByExpiryService, getInventoryByBusinessIdService, updateInventoryService }
import styled from "styled-components";
import {FlexStyled} from "./utilStyles";

const TablePageLayoutStyled = styled(FlexStyled)`
	
	> ${FlexStyled} {
		gap: 1.5rem calc((100% - 94.5% + 10px) / 4);
		width: 100%;
	}
`

export {
	TablePageLayoutStyled
}
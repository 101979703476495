import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createSupplierService,
  deleteSupplierService,
  editSupplierService,
  getSupplierByBusinessIdService,
  getSupplierByCategoryIdService,
  getSupplierByIdService,
  uploadBulkSupplierService
} from "../../../services/supplierServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import supplierQueryKey from "./supplierQueryKey";
import errorHandler from "../../../utils/errorHandler";
import {useLocation} from "react-router-dom";
import {useDesktopSupplierServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";

const useSupplierByBusinessIdQuery = (searchValue) => {
  const {getBusinessId, isOffline} = useGlobalContext()
  const offlineData = useDesktopSupplierServiceFromLocalDB(searchValue)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: supplierQueryKey.mainList(searchValue),
    queryFn: () => getSupplierByBusinessIdService(getBusinessId(), searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useSupplierByIdQuery = (supplierId) => {
  return useQuery({
    queryKey: supplierQueryKey.id(supplierId),
    queryFn: () => getSupplierByIdService(supplierId)
  })
}

const useSupplierByCategoryIdQuery = (categoryId) => {
  const { getBusinessId } = useGlobalContext()
  return useQuery(
    `${queryKeys.SUPPLIERS}_${categoryId}`,
    getSupplierByCategoryIdService.bind(this, getBusinessId(), categoryId)
  )
}

const useCreateSupplierMutation = ({ successFn }) => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { toast, getBusinessId, globalState, navigate } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createSupplierService({ ...data, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Supplier Created!!")
      queryClient.invalidateQueries({ queryKey: supplierQueryKey.mainList() })
  
      if(globalState.modalConnector.toModalURL === pathname) {
        navigate(globalState.modalConnector.fromModalURL)
      }
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditSupplierMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return editSupplierService(data.id, data)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Supplier edited!!")
      queryClient.invalidateQueries({ queryKey: supplierQueryKey.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteSupplierMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: supplierId => {
      return deleteSupplierService(supplierId)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Supplier deleted!!")
      queryClient.invalidateQueries({ queryKey: supplierQueryKey.mainList() })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useUploadBulkSupplierService = ({ successFn }) => {
  const { toast, getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: ({ type, values }) => {
      return uploadBulkSupplierService(type, getBusinessId(), values)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Suppliers imported!!!")
      queryClient.refetchQueries({ queryKey: supplierQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export {
  useSupplierByBusinessIdQuery, useCreateSupplierMutation,
  useSupplierByCategoryIdQuery, useEditSupplierMutation, useDeleteSupplierMutation
}
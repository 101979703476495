import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  deleteProductionUnitMeasurementService,
  getProductionUnitMeasurementService,
  updateProductionUnitMeasurementService,
  createProductionUnitMeasurementService
} from "../../../services/productionServices/productionUnitMeasurementServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import errorHandler from "../../../utils/errorHandler";
import {useLocation} from "react-router-dom";

const mainKey = ["Production Unit Measurement", getBusinessIdFromStorage()]
const productionUnitMeasurementQueryKeys = {
  all: mainKey,
  mainList: (query) => [...mainKey, getAllObjectKeysWithValues(query)]
}


export const useProductionUnitMeasurementQuery = (query = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: productionUnitMeasurementQueryKeys.mainList(query),
    queryFn: () => getProductionUnitMeasurementService({ ...query, business_id: getBusinessId() })
  })
}


export const useCreateProductionUnitMeasurementMutation = ({ successFn }) => {
  const { getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  const { toast, globalState, navigate } = useGlobalContext()
  const { pathname } = useLocation()
  
  return useMutation({
    mutationFn: body => {
      return createProductionUnitMeasurementService({ ...body, business: getBusinessId() })
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Create Successful!!", "create")
      await queryClient.refetchQueries({ queryKey: productionUnitMeasurementQueryKeys.all })
  
      if(globalState.modalConnector.toModalURL === pathname) {
        navigate(globalState.modalConnector.fromModalURL)
      }
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useUpdateProductionUnitMeasurementMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: (body) => {
      return updateProductionUnitMeasurementService(body.id, body)
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Update Successful!!", "update")
      await queryClient.refetchQueries({ queryKey: productionUnitMeasurementQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useDeleteProductionUnitMeasurementMutation = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()


  return useMutation({
    mutationFn: id => {
      return deleteProductionUnitMeasurementService(id)
    },

    onSuccess: async () => {
      toast.success("Delete Successful!!", "delete")
      await queryClient.refetchQueries({ queryKey: productionUnitMeasurementQueryKeys.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
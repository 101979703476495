import axios from "../../../axios";
import {getAccountsServiceStructure} from "./accountServiceStructure";
import handleQueryParams from "../../../utils/handleQueryParams";


export const getAccountsService = async (searchValue = " ") => {
	try {
		const { data } = await axios.get(`/accounts?search=${searchValue}`)
		return getAccountsServiceStructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getAccountByIdService = async (accountId) => {
	try {
		const { data } = await axios.get(`/accounts/?account_id=${accountId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getAccountBusinessesByIdService = async (queryParams) => {
	try {
		const url = handleQueryParams("/business", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getBusinessesUsingOvaloopPayService = async (queryParams) => {
	try {
		const url = handleQueryParams("/ovaloop_pay_businesses/", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateAccountTypeByIdService = async (accountId, body) => {
	try {
		const { data } = await axios.patch(`/account_edit/${accountId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
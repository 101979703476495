import {FlexStyled, DashboardPageHeaderStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading} from "../../styles/textStyles";
import {DatePicker, Form, Select} from "../index";
import {SearchNormal1} from "iconsax-react";
import {Fragment, useState} from "react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import dateFilter, {dateFilterValue} from "../../data/selectField/dateFilter";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import {globalReducerActions} from "../../reducers/globalReducer";


const OvaloopDashboardAnalyticsHeader = ({ title, refetchQueries }) => {
  const [filter, setFilter] = useState("")
  const { toast, globalReducer, globalState } = useGlobalContext()

  const [{ from_date, to_date }, setDate] = useState({
    from_date: "",
    to_date: ""
  })

  const handleChange = (type, value) => {
    setDate(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const handleSearch = () => {
    if(!from_date) {
      toast.error("Input Start Date")
      return
    }

    if(!to_date) {
      toast.error("Input End Date")
      return
    }

    refetchQueries(from_date, to_date)
  }
  
  const handleDateFilterSelect = (filterType) => {
    setFilter(filterType.value)

    globalReducer({
      type: globalReducerActions.SET_DASHBOARD_DATE_FILTER,
      filterType: filterType.name
    })

    if(filterType.value === dateFilterValue.CUSTOM_PERIOD) {
      return
    }

    const { toDate, fromDate } = handleDateFilterValue(filterType.value)
  
    refetchQueries(fromDate, toDate)
  }


  return (
    <DashboardPageHeaderStyled>
      <SpaceBetweenStyled>
        <Heading color="black" size={1.7}>{title}</Heading>
        <Form values={{ from_date: "", to_date: "", filter: "", display_filter: globalState.dashboardDateFilterType }}>
          <FlexStyled>
            <Select
              name="filter"
              valueKey="value"
              displayKey="name"
              options={dateFilter}
              displayName="display_filter"
              placeholder="Filter analytics date"
              updateFn={handleDateFilterSelect}
            />
            
            {filter === dateFilterValue.CUSTOM_PERIOD && (
              <Fragment>
                <DatePicker labelBg="background" isTodayMax required onChange={e => handleChange("from_date", e)} noBottomMargin name="from_date" placeholder="Date from" />
                <DatePicker labelBg="background" isTodayMax required onChange={e => handleChange("to_date", e)} noBottomMargin name="to_date" placeholder="Date to" />
                <SearchNormal1 type="submit" onClick={handleSearch} size={23} color="black" />
              </Fragment>
            )}
          </FlexStyled>
        </Form>
      </SpaceBetweenStyled>
    </DashboardPageHeaderStyled>
  )
}

export default OvaloopDashboardAnalyticsHeader
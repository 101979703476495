import styled from "styled-components";
import {FlexCenterStyled, FlexStyled} from "./utilStyles";
import {Heading} from "./textStyles";


const DataItemStyled = styled("div")`
  gap: .3rem;
  display: flex;
  font-family: Light, serif;
  align-items: center;
  
  span {
    height: 4.5px;
    padding: .2rem .3rem;
    background-color: ${props => props.$color};
  }
`

const ChartLayout = styled("section")`
  position: relative;
  padding: 1rem 1rem 2rem;
  border-radius: .4rem;
  background-color: white;
  max-height: 70vh;
  
  * {
    user-select: none;
  }
  
  ${Heading} {
    padding: .5rem 1rem 0;
  }
  
  > ${FlexStyled} {
    justify-content: center;
    width: 100%;
    height: 85%;
    
    ${FlexCenterStyled} {
      text-align: center;
      position: relative;
      top: 35%;
    }
    
    > ${FlexStyled} {
      width: 100%;
      padding: 0 0 1.5rem;
    }
  }

  >${FlexStyled} ${FlexStyled}:has(${DataItemStyled}) {
    //max-height: 3.2rem;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }
  
  ${FlexStyled}.list {
    height: fit-content;
    position: relative;
    top: -2.5rem;
    padding-bottom: .5rem;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    padding: 1.5rem .5rem 3rem;
    min-height: 45vh;
    max-height: 70vh;
    
    ${FlexStyled} {
      height: 70%;
    }

    ${FlexStyled}.list {
      top: -1rem;
    }
  }
  
  @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
    ${Heading} {
      margin-bottom: 1rem;
    }
    
    .recharts-responsive-container {
      height: ${props => props.$isEmpty ? "40%" : "86%"} !important;
    }
  }

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
    min-height: 45vh;
    max-height: 60vh;
    
    ${FlexStyled} {
      height: 65%;
    }
  }

  path[stroke="none"].recharts-rectangle {
    fill: rgba(220, 217, 217, 0.18);
  }
  
  .recharts-tooltip-label {
    display: none;
  }
`

// const NoChartData = styled(FlexCenterStyled)

const AreaChartStyled = styled("div")`
  margin: 1rem 0;
  max-width: 100%;
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .recharts-tooltip-wrapper {
      display: none !important;
    }
  }
`


export {
  ChartLayout,
  AreaChartStyled,
  DataItemStyled
}
import env from "../env";

const payWithMonnify = (config, handleSuccess) => {
	const { full_name, email, amount } = config

	window?.MonnifySDK?.initialize({
		amount: amount,
		currency: "NGN",
		reference: String((new Date()).getTime()),
		customerFullName: full_name,
		customerEmail: email,
		apiKey: env.MONNIFY_KEY,
		contractCode: env.MONNIFY_CONTRACT_CODE,
		paymentDescription: "Ovaloop Subscription",

		onComplete: (response) => {
			handleSuccess(response)
		},
		
		onClose: () => {
		}
	});
}

export default payWithMonnify
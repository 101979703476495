import { Link } from "react-router-dom";
import { BackgroundPattern, RegisterLayout } from "../AuthStyles";
import {Button, Form, Icon, Input, QuoteCreator} from "../../../components";

import {CenterContent} from "../../../styles/utilStyles";
import {Logo, LogoLight} from "../../../assets/images";
import {Heading, Heading2, Paragraph} from "../../../styles/textStyles";
import {QuoteIcon} from "../../../assets/icons";
import {useForgotPasswordMutation} from "../../../hooks/useServices/authenticationHooks";
import useAllowOnlyUnauthenticatedUsers from "../../../hooks/useRouteProtection/useAllowOnlyUnauthenticatedUsers";

const formValues = { email: "" }

const ForgotPassword = () => {
	useAllowOnlyUnauthenticatedUsers()
	const forgotPasswordMutation = useForgotPasswordMutation()
	
	const handleForgotPassword = (values) => {
		forgotPasswordMutation.mutate(values)
	}
	
	return (
		<RegisterLayout>
			{/* FIRST COLUMN - FORM SECTION */}
			<div className="logo_div">
				<img src={LogoLight} alt="Ovaloop logo" />
			</div>
			
			
			<section className="email_and_password">
				<div className="email_and_password_div">
					<img src={Logo} alt="Ovaloop logo" />
					<div>
						<Heading2 color="black">Reset your password</Heading2>
						<Form values={formValues} onSubmit={handleForgotPassword}>
							<Input type="email" required label="Email" placeholder="Email address" name="email" />
							<Button type="submit" isLoading={forgotPasswordMutation.isLoading} text="Send OTP" shadow />
						</Form>
					</div>
					
					<Paragraph color="gray" bold size={.9}>
						Don’t have an account? <Link to="/register">Sign up now</Link>
					</Paragraph>
				</div>
			</section>
			
			
			{/* SECOND COLUMN - QUOTE SECTION */}
			<BackgroundPattern className="quote_section">
				<div>
					<CenterContent>
						<Icon iconImg={QuoteIcon} bgDark />
						<Heading size={1.4} spread>
							"I've always had the issue of knowing the stock level of each product, keeping up with orders and knowing when to stock up can all be challenging. That has changed since I started using Ovaloop, with just a few clicks I can view it all at a glance. I now have peace of mind when I'm at my business.
							<br/> <br/>
							I recommend Ovaloop to every SME that wants a seamless business operation."
						</Heading>
						<QuoteCreator />
					</CenterContent>
				</div>
			</BackgroundPattern>
		</RegisterLayout>
	)
}

export default ForgotPassword
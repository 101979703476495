import {DetailsStyled} from "./settingsStyles";
import {Heading} from "../../../styles/textStyles";
import {Button, Form, Input, Line} from "../../../components";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {
  useEmailVerificationService,
  useGetOTPForEmailVerificationService
} from "../../../hooks/useServices/authenticationHooks";
import {useUserProfile} from "../../../hooks/useServices/userHooks";


const VerifyEmail = () => {
  const { data: userProfile } = useUserProfile()
  const { globalState } = useGlobalContext()
  
  const getOTPForEmailVerificationMutation = useGetOTPForEmailVerificationService()
  const formValues = { email: globalState?.emailForVerifyEmailOTP, otp: "" }
  
  const verifyEmailMutation = useEmailVerificationService()
  
  
  const handleGetOTP = () => {
    getOTPForEmailVerificationMutation.mutate({ email: userProfile?.[0]?.email })
  }
  
  const handleVerifyEmail = (formValues) => {
    verifyEmailMutation.mutate(formValues)
  }

  return (
    <DetailsStyled $avatarWidth={30} $formWidth={30}>
      <SpaceBetweenStyled className="pageHeader">
        <div>
          <Heading size={1.3} color="black">Verify Your Email</Heading>
        </div>
      </SpaceBetweenStyled>

      <Line />

      <FlexStyled className="avatarAndForm">
        <section className="form_section">
          {!globalState?.emailForVerifyEmailOTP && (
            <Form values={{ email: "" }} onSubmit={handleGetOTP}>
              <SectionStyled>
                <Input type="email" disabled required label="Email" placeholder={userProfile?.[0]?.email} name="email" />
                <Button
                  disabled={userProfile?.[0]?.is_verified}
                  type="submit"
                  isLoading={getOTPForEmailVerificationMutation.isLoading}
                  text={userProfile?.[0]?.is_verified ? "Email already verified" : "Send OTP"}
                />
              </SectionStyled>
            </Form>
          )}
  
          {globalState?.emailForVerifyEmailOTP && (
            <Form values={formValues} onSubmit={handleVerifyEmail}>
              <SectionStyled>
                <Input type="email" disabled required label="Email" placeholder={userProfile?.[0]?.email} name="email" />
                <Input type="number" label="OTP" placeholder="Enter the OTP sent to your email" name="otp" />
                <Button type="submit" isLoading={verifyEmailMutation.isLoading} text="Send OTP" shadow />
              </SectionStyled>
            </Form>
          )}
        </section>
      </FlexStyled>
    </DetailsStyled>
  )
}

export default VerifyEmail
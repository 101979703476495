import {
  CreateStoreModal,
  EditStoreModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton, PageSummaryCard, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {StoreImage} from "../../../assets/images";
import useToast from "../../../hooks/useUtils/useToast";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {storeFormDefaultValues} from "../../../data/defaultFormValues";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useStoreQuery,
  useCreateStoreMutation,
  useEditStoreMutation,
  useDeleteStoreMutation,
} from "../../../hooks/useServices/useStoreServices";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import {validatePhoneNumberWithLocale} from "../../../utils/validator";
import {PageSummaryStyled} from "../../../styles/utilStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";


const AllStores = () => {
  const toast = useToast()
  const { storesTable } = tableHeadersAndValueKeys
  const { numberFormatter } = useDataTypeFormatter()
  
  const { debounceState, debounceReducer } = useQueryDebounce()
  const [showEditStoreModal, setShowEditStoreModal] = useModal()
  const [showCreateStoreModal, setShowCreateStoreModal] = useModal()
  const [editStoreFormValues, setStoreFormValues] = useState(storeFormDefaultValues)

  const deleteStoreMutation = useDeleteStoreMutation({ successFn: () => {} })
  const editStoreMutation = useEditStoreMutation({ successFn: setShowEditStoreModal })
  const { mainQuery, searchQuery, ...allStores } = useSearchQuery(debounceState, useStoreQuery, true)
  const createStoreMutation = useCreateStoreMutation({ successFn: setShowCreateStoreModal })

  const handleCreateStore = (values) => {
    if(!!values?.countryLocale && !validatePhoneNumberWithLocale(values.phone, values?.countryLocale)) {
      toast.error("Phone number not valid for country")
      return
    }
    
    createStoreMutation.mutate(values)
  }

  const handleEditStore = (values) => {
    if(!!values?.countryLocale && !validatePhoneNumberWithLocale(values.phone, values?.countryLocale)) {
      toast.error("Phone number not valid for country")
      return
    }
    
    delete values?.image_path
    editStoreMutation.mutate(values)
  }

  const handleEditAction = (store) => {
    setStoreFormValues(store)
    setShowEditStoreModal()
  }

  const handleDeleteAction = (store) => {
    if(allStores.count === 1) {
      toast.error("You can not delete your only store")
      return
    }

    toast.confirm(
      "Are you sure?",
      deleteStoreMutation.mutate.bind(this, store.id),
    )
  }
  

  return (
    <SetUpMainStyled>
      {showCreateStoreModal && <CreateStoreModal onClose={setShowCreateStoreModal} handleSubmit={handleCreateStore} mutation={createStoreMutation} />}
      {showEditStoreModal && <EditStoreModal formValues={editStoreFormValues} onClose={setShowEditStoreModal} handleSubmit={handleEditStore} mutation={editStoreMutation} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allStores.refetch} />
      )}
      
      <Fragment>
        {allStores.isEmpty && (
          <Fragment>
            <PageHeader title="Stores" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={StoreImage}
              heading="No store yet"
              subHeading="It is a long established fact that a reader will be distracted by the readable content of a page when looking far."
              buttonText="Add new store"
              buttonClick={setShowCreateStoreModal}
              permissionCode={actionsPermissions.setBusinessSettings}
            />
          </Fragment>
        )}
  
        {allStores.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateStoreModal} title="Stores" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.setBusinessSettings} />
    
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allStores.isQueryLoading}
                title="Number Of Stores"
                value={numberFormatter(allStores.count)}
              />
            </PageSummaryStyled>
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search store by name"
              />
      
              <Table headers={storesTable.headers} noCols={5}>
                {allStores.isQueryWithData && (
                  allStores.data.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allStores.currentCount}
                      keys={storesTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.setBusinessSettings}
                      deletePermission={actionsPermissions.setBusinessSettings}
                    />
                  )
                )}
        
                {allStores.isQueryWithNoData && (
                  <NoQueryData text={allStores.isSearchEmptyMessage("Store", "")} />
                )}
        
                {allStores.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
            </SetUpLayoutStyled>
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default AllStores
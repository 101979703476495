import {AddCircle, CloseSquare, MinusCirlce} from "iconsax-react";
import {AddressInput, Button, Form, Input} from "../../../index";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import { FlexStyled } from "../../../../styles/utilStyles";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {memo, useEffect, useState} from "react";
import uuid from "react-uuid";
import {ExtraFieldsStyled} from "../../../../styles/formStyles";
import {useFormikContext} from "formik";


const EditCustomerModal = ({ onClose, handleSubmit, mutation, formValues }) => {
	const [extraFields, setExtraFields] = useState([])
	const [extraFieldsData, setExtraFieldsData] = useState(formValues?.extra_fields)
	
	useEffect(() => {
		const formattedFields = formValues?.extra_fields?.map(field => ({
			Component: AddExtraFields,
			id: field.id,
			values: field
		}))
		
		setExtraFields(formattedFields || [])
		setExtraFieldsData(formValues?.extra_fields || [])
	}, [formValues.extra_fields])
	
	const handleAddExtraFields = () => {
		setExtraFields(prev => ([ ...prev, { id: uuid(), Component: AddExtraFields }]))
	}
	
	const handleDeleteExtraField = (id) => {
		const otherExtraFields = extraFields.filter(field => field.id !== id)
		const otherExtraFieldsData = extraFieldsData.filter(field => field.id !== id)
		
		setExtraFields(otherExtraFields)
		setExtraFieldsData(otherExtraFieldsData)
	}
	
	const handleSubmission = (values) => {
		const newValues = { ...values, extra_fields: extraFieldsData }
		handleSubmit(newValues)
	}
	
	return (
		<ModalLayoutStyled onClick={onClose}>
			<InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Edit Customer</Heading>

				<Form values={formValues} onSubmit={handleSubmission} allFieldsNotRequired>
					<Input label="First Name" placeholder="Enter first name" name="first_name"  />
					<Input label="Last Name" placeholder="Enter last name" name="last_name"  />
					<Input label="Phone" type="number" placeholder="Enter phone number" name="phone"  />
					<Input optional label="Email" type="email" placeholder="Enter a valid email" name="email"  />
					<AddressInput className="fullWidth" name="address" main_name="main_address" isCustomer placeholder="Enter customer address" label="Customer Address"  />
					
					{extraFields?.map(({Component, id, values}) => (
						<Component
							id={id}
							key={id}
							values={values}
							setExtraFieldsData={setExtraFieldsData}
							handleDeleteExtraField={handleDeleteExtraField}
						/>
					))}
					
					<div className="add_extra_fields" onClick={handleAddExtraFields}>
						<AddCircle color="black" size={18}/>
						<Paragraph color="black" bold noTop>
							Add Extra fields
						</Paragraph>
					</div>
					
					<FlexStyled>
						<Button text="Save" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>

			</InventoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default EditCustomerModal


const AddExtraFields = memo(({ setExtraFieldsData, id, handleDeleteExtraField, values }) => {
	const [field, setField] = useState({})
	const { setFieldValue } = useFormikContext()
	
	useEffect(() => {
		setField({ name: values?.name, value: values?.value })
		setFieldValue(`${id}_name`, values?.name || "")
		setFieldValue(`${id}_value`, values?.value || "")
	}, [values])
	
	
	useEffect(() => {
		setExtraFieldsData(prev => {
			const otherData = prev?.filter(item => item?.id !== id)
			return [...otherData, { ...field, id }]
		})
	}, [field])
	
	const handleFieldName = (fieldName) => {
		setField(prev => ({ ...prev, name: fieldName }))
	}
	
	const handleFieldValue = (fieldValue) => {
		setField(prev => ({ ...prev, value: fieldValue }))
	}
	
	return (
		<ExtraFieldsStyled>
			<div>
				<Input onChange={handleFieldName} label="Label" placeholder="Enter field name" name={`${id}_name`}  />
				<Input onChange={handleFieldValue} label="Value" placeholder="Enter field value" name={`${id}_value`}  />
			</div>
			
			<div className="delete_extra_fields" onClick={() => handleDeleteExtraField(id)}>
				<MinusCirlce color="red" size={18}/>
				<Paragraph color="error" bold noTop>
					Delete Extra field
				</Paragraph>
			</div>
		</ExtraFieldsStyled>
	)
})

import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createCustomerService,
  deleteCustomerService,
  editCustomerService,
  getCustomerByIdService,
  getCustomerService,
  getCustomerWalletLogByIdService,
  updateCustomerWalletByIdService,
  uploadBulkCustomerService
} from "../../../services/customerServices";
import {scrollToTop} from "../../../utils";
import {useParams} from "react-router-dom";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getGroupOrderByBusinessIdService} from "../../../services/orderServices";
import customerQueryKey from "./customQueryKey";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopCustomersServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const useCustomerQuery = (search) => {
  scrollToTop()
  const {getBusinessId, isOffline} = useGlobalContext()
  const offlineData = useDesktopCustomersServiceFromLocalDB({search})
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: customerQueryKey.mainList(search),
    queryFn: () => getCustomerService({business_id: getBusinessId(), search})
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCustomerByIdQuery = (customer) => {
  const { customerId } = useParams()
  const { isOffline } = useGlobalContext()
  
  const offlineData = useDesktopCustomersServiceFromLocalDB({ id: customerId || customer })
  
  const onlineData = useQuery({
    enabled: !isOffline && (!!customer || !!customerId),
    queryKey: customerQueryKey.byId(customerId || customer),
    queryFn: () => getCustomerByIdService(customerId || customer)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCustomerOrdersByIdQuery = (status = "", from_date = "", to_date = "") => {
  const { customerId } = useParams()
  const { getBusinessId } = useGlobalContext()

  return useQuery({
    queryKey: customerQueryKey.customerOrders(customerId, { status, from_date, to_date }),
    queryFn: () => getGroupOrderByBusinessIdService(getBusinessId(), "", status, from_date, to_date, customerId)
  })
}

const useCreateCustomerMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createCustomerService({...data, business: getBusinessId()})
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Customer Created!!")
      queryClient.invalidateQueries({queryKey: customerQueryKey.mainList()})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createCustomer,
      })
    }
  })
}

const useEditCustomerMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editCustomerService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.refetchQueries({queryKey: customerQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateCustomer,
      })
    }
  })
}

const useDeleteCustomerMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: customerId => {
      return deleteCustomerService(customerId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: customerQueryKey.mainList() })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}





// CUSTOMER WALLET QUERIES & MUTATION

export const useCustomerWalletLogByIdQuery = ({ customer = "", transactionType = "", from_date, to_date  }) => {
  const { customerId } = useParams()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: customerQueryKey.walletLog(customerId || customer, transactionType, from_date, to_date),
    queryFn: () => getCustomerWalletLogByIdService({
      transactionType,
      businessId: getBusinessId(),
      customerId: customerId || customer,
      from_date, to_date
    })
  })
}


export const useUpdateCustomerWalletMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return updateCustomerWalletByIdService(data.id, data)
    },
  
    onSuccess: (res, data) => {
      successFn()
      toast.success(`Wallet ${data.action === "credit" ? "Credited" : "Debited"}`)
      queryClient.refetchQueries({queryKey: customerQueryKey.byId(data.id.toString())})
      queryClient.refetchQueries({queryKey: customerQueryKey.byId(data.id)})
      queryClient.refetchQueries({queryKey: customerQueryKey.walletLog(data.id.toString(), "")})
      queryClient.refetchQueries({queryKey: customerQueryKey.walletLog(data.id.toString(), data.action)})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateCustomerWallet,
      })
    }
  })
}

export const useUploadBulkCustomerService = ({ successFn }) => {
  const { toast, getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: ({ type, values }) => {
      return uploadBulkCustomerService(type, getBusinessId(), values)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Customer imported!!!")
      queryClient.refetchQueries({ queryKey: customerQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export { useCustomerQuery, useCustomerByIdQuery, useCreateCustomerMutation,
  useEditCustomerMutation, useDeleteCustomerMutation, useCustomerOrdersByIdQuery }
import {
  Button,
  DeliveryVehicleTypeModal,
  NoDataLayout,
  PageHeader, PageSummaryCard,
  SearchAndFilter,
  Table, TableItemWithCheckoutBoxAndImage, TablePagination
} from "../../../components";
import {EmptyBoxImage} from "../../../assets/images";
import {Fragment, useState} from "react";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {useModal} from "../../../hooks";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  usePackageItemByBusinessIdQuery,
  useCreatePackageItemMutation,
  useFareCalculatorForDelivery
} from "../../../hooks/useServices/usePackageItemServices";
import {
  getAllApprovedTransactions,
  getAllPendingTransactions,
  getAllRejectedTransactions
} from "../../../utils/filters";
import {FlexStyled, PageSummaryStyled} from "../../../styles/utilStyles";
import {TableTopBarStyled} from "../../../styles/TableStyles";
import useDeliveryReducer, {orderReducerActionTypes} from "../../../reducers/deliveryReducer";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const Items = () => {
  const [fareCalculationState, setFareCalculationState] = useState({
    vehicle_type: "",
    packageList: []
  })

  const [packageState, packageReducer] = useDeliveryReducer()
  const [showCreatePackageItemModal, setShowCreatePackageItemModal] = useModal()

  const { deliveryPackageItemsTable } = tableHeadersAndValueKeys
  const {data: fareCalculation, ...fareCalculationMutation} = useFareCalculatorForDelivery(fareCalculationState.packageList, fareCalculationState.vehicle_type)
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allPackageItems } = useSearchQuery(debounceState, usePackageItemByBusinessIdQuery)
  const createPackageItemMutation = useCreatePackageItemMutation({ successFn: setShowCreatePackageItemModal })

  const handleCreatePackageItem = (values) => {
    createPackageItemMutation.mutate(values)
  }

  const handleFareCalculation = (values) => {
    setFareCalculationState({
      vehicle_type: values.vehicle_type,
      packageList: packageState.batch
    })
  }

  const approvedPackageItems = getAllApprovedTransactions(allPackageItems?.results)
  const pendingPackageItems = getAllPendingTransactions(allPackageItems?.results)
  const rejectedPackageItems = getAllRejectedTransactions(allPackageItems?.results)

  const handleAddItemToBatch = (itemId) => {
    packageReducer({
      itemId,
      type: orderReducerActionTypes.ADD_ITEM_TO_BATCH
    })
  }

  return (
    <div>
      {showCreatePackageItemModal && <DeliveryVehicleTypeModal onClose={setShowCreatePackageItemModal} handleSubmit={handleFareCalculation} mutation={fareCalculationMutation} />}

      {mainQuery.isSuccess && (
        <Fragment>
          {allPackageItems.isEmpty && (
            <Fragment>
              <PageHeader title="Package Items" />
              <NoDataLayout
                imgWidth={45}
                imgAlt="No delivery"
                imgSrc={EmptyBoxImage}
                heading="No package item yet"
                subHeading="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less"
              />
            </Fragment>
          )}

          {!allPackageItems.isEmpty && (
            <Fragment>
              <PageHeader title="Package Items" />

              <PageSummaryStyled>
                <PageSummaryCard title="Total Number Of Package Items" value={allPackageItems?.data?.results.length} />
                <PageSummaryCard title="Approved Package Items" value={approvedPackageItems.length} />
                <PageSummaryCard title="Pending Package Items" value={pendingPackageItems.length} />
                <PageSummaryCard title="Rejected Package Items" value={rejectedPackageItems.length} />
              </PageSummaryStyled>

              <SetUpLayoutStyled>
                <TableTopBarStyled>
                  <SearchAndFilter
                    noFilter
                    dispatch={debounceReducer}
                    placeholder="Search deliveries by Id"
                  />

                  <FlexStyled>
                    <Button text="Send Items" onClick={setShowCreatePackageItemModal} disabled={!packageState.itemToBatchAvailable} />
                  </FlexStyled>
                </TableTopBarStyled>
                <Table bg headers={deliveryPackageItemsTable.headers} noCols={deliveryPackageItemsTable.headers.length} >
                  {allPackageItems.isQueryWithData && (
                    allPackageItems?.data?.results.map((items, k) =>
                      <TableItemWithCheckoutBoxAndImage
                        key={k}
                        data={items}
                        statusPosition={9}
                        checkId="package_item_id"
                        handleCheck={handleAddItemToBatch}
                        keys={deliveryPackageItemsTable.values}
                      />
                    )
                  )}

                  {allPackageItems.isQueryWithNoData && (
                    <NoQueryData text={allPackageItems.isSearchEmptyMessage("Packaged Item", "Id")} />
                  )}

                  {allPackageItems.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
                {!!allPackageItems.next && <TablePagination />}
              </SetUpLayoutStyled>

              {!!allPackageItems.next && <TablePagination />}
            </Fragment>
          )}

        </Fragment>
      )}
    </div>
  )
}

export default Items;
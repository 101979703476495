import {BusinessSubscriptionDrawerStyled, DrawerStyled} from "../../../styles/drawerStyles";
import { SpaceBetweenStyled } from "../../../styles/utilStyles";
import {Heading } from "../../../styles/textStyles";
import BusinessSubscriptionCheckout from "./BusinessSubscriptionCheckout";
import {CloseSquare} from "iconsax-react";


const CustomerWalletDrawer = ({ customer, onClose }) => {

	return (
		<DrawerStyled onClick={onClose}>
			<BusinessSubscriptionDrawerStyled onClick={e => e.stopPropagation()}>
				<SpaceBetweenStyled>
					<Heading color="black" size={1.3}>Credit Customer Wallet</Heading>
					<CloseSquare size={28} onClick={onClose} />
				</SpaceBetweenStyled>
				<br/>
				
				<BusinessSubscriptionCheckout customer={customer} onClose={onClose} closeDrawer={onClose} />
			</BusinessSubscriptionDrawerStyled>
		</DrawerStyled>
	)
}


export default CustomerWalletDrawer
import axios from "../../axios";

const getPackageItemByBusinessIdService = async (businessId, searchValue) => {
	try {
		const { data } = await axios.get(`/package_order/?business_id=${businessId}&search=${searchValue}`)
		const results = data.results.map(item => ({
			...item,
			customer_name: item.order_details.customer.name,
			customer_phone: item.order_details.customer.phone
		}))

		return { ...data, results }
	} catch (e) {
		throw e
	}
}

const getFareCalculatorService = async (businessId, packageList = [], vehicleType) => {
	if(packageList.length < 1 || !vehicleType) return

	try {
		const { data } = await axios.get(`/fare_calculator/?business_id=${businessId}&package_list=${packageList}&vehicle_type=${vehicleType}`)
		return data
	} catch (e) {
		throw e
	}
}

const createPackageItemService = async (body) => {
	try {
		const { data } = await axios.post(`/package_order/`, body)
		return data
	} catch (e) {
		throw e
	}
}

export { getPackageItemByBusinessIdService, getFareCalculatorService, createPackageItemService }
import queryKeys from "./queryKeys";
import {useQuery} from "react-query";
import {getAllCountriesService, getAllStatesByCountryIdService} from "../../services/formFieldServices";


const useCountries = () => {
  return useQuery({
    queryKey: [queryKeys.COUNTRIES],
    queryFn: getAllCountriesService
  })
}

export const useCurrencies = () => {
  const { data: countries, ...others } = useCountries()

  const currencies = countries?.map(country => ({ name: country.code, id: country.id }))

  return { ...others, data: currencies }
}

const useStatesByCountryId = (countryId) => {
  return useQuery({
    queryKey: [queryKeys.STATES, countryId],
    queryFn: () => getAllStatesByCountryIdService(countryId)
  })
}

export { useCountries, useStatesByCountryId }
import {
  CustomerDetailsStyled,
  CustomerInformationStyled,
  SingleCustomerStyled
} from "../../../styles/singleCustomerStyles";
import {
  Chips,
  CustomerAndStaffReviewCard,
  CustomerModal,
  Image,
  Line,
  PageHeader,
  PageHeaderWithButtonAndDateFilter,
  ResetStaffPasswordModal,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
  TableItemWithStatus
} from "../../../components";

import {Fragment, useEffect, useState} from "react";
import {useModal} from "../../../hooks";
import {useParams} from "react-router-dom";
import {Avatar} from "../../../assets/images";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexColumnStyled, FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {useResetStaffPasswordMutation, useSingleStaffByIdQuery} from "../../../hooks/useServices/useStaffServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useGroupOrderByStaffIdQuery} from "../../../hooks/useServices/useOrderServices";
import {topNData} from "../../../utils";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {useStaffDashboardAnalyticsByIdService} from "../../../hooks/useServices/useAnalyticsServices";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {useWipByStaffIdQuery} from "../../../hooks/useServices/useWipServices";
import {useStoreQuery} from "../../../hooks/useServices/useStoreServices";
import dateFilter, {dateFilterValue, dateFilterValueEnum} from "../../../data/selectField/dateFilter";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {globalReducerActions} from "../../../reducers/globalReducer";
import {useTipQuery} from "../../../hooks/useServices/useTipsServices";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../data/permissions";


const StaffDetail = ({ title, value }) => {
  return (
    <CustomerDetailsStyled>
        <Heading color="menuGray">{title}</Heading>
        <Heading color="black" size={1.1}>{value}</Heading>
    </CustomerDetailsStyled>
  )
}


const SingleStaff = () => {
  const { staffId } = useParams()
  const { globalReducer } = useGlobalContext()
  const [showModal, setShowModal] = useModal()
  const [isStaffOpen, setIsStaffOpen] = useModal()
  const [tabState, setTabState] = useState("orders")
  const permission = useActionPermissionFn()
  
  const { fromDate, toDate } = handleDateFilterValue(dateFilterValue.DAY_30)
  const [dateFilterObj, setDateFilterObj] = useState({ from_date: fromDate, to_date: toDate })
  
  const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
  const { data: staffDetails, status, refetch, isLoading: isStaffLoading, isSuccess: isStaffSuccess } = useSingleStaffByIdQuery(staffId)
  const { data: staffAnalytics, isLoading: isStaffAnalyticsLoading, refetch: refetchStaffAnalytics } = useStaffDashboardAnalyticsByIdService(staffId, dateFilterObj.from_date, dateFilterObj.to_date)
  const { data: allBusinesses } = useStoreQuery()
  const businessSettings = useBusinessSettingQuery()
  const { data: userProfile } = useUserProfile()
  
  const resetStaffPasswordMutation = useResetStaffPasswordMutation({ successFn: setIsStaffOpen })
  
  useEffect(() => {
    globalReducer({
      type: globalReducerActions.SET_STAFF_DATE_FILTER,
      filterType: dateFilter[2].name
    })
  }, [staffId])


  const staffAvailableBusinesses = () => {
    if (!staffDetails?.other_business) return []
    const staffBusinesses = staffDetails.other_business.filter(business => !!business)
    const businessExist = staffBusinesses.filter(businessId => allBusinesses?.map(business => business.id)?.includes(businessId))
  
    return businessExist.map(businessId => {
      return allBusinesses?.find(business => business.id === businessId)
    })
  }
  
  const refetchQueries = async () => {
    await refetch()
    await refetchStaffAnalytics()
  }
  
  const handleResetPassword = (values) => {
    resetStaffPasswordMutation.mutate(values)
  }

  return (
    <div>
      {showModal && <CustomerModal onClose={setShowModal}/>}
      {isStaffOpen && <ResetStaffPasswordModal mutation={resetStaffPasswordMutation} handleSubmit={handleResetPassword} formValues={staffDetails} onClose={setIsStaffOpen} /> }
  
  
      <PageHeader title="Staff" />
  
      {status === "error" && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}

      <SingleCustomerStyled>

        <PageHeaderWithButtonAndDateFilter
          isBack
          otherData={{ staffId }}
          buttonText="Reset Password"
          buttonClick={setIsStaffOpen}
          permissionCode={actionsPermissions.onlyAdmin}
          placeholder="Filter staff analytics by date"
          backToText="Back to staff list"
          globalStateKey="staffAnalytics"
          title={isStaffSuccess ? `${staffDetails?.first_name} ${staffDetails?.last_name}` : "" }
          setDateFilter={setDateFilterObj}
          dateFilterDefaultValue={dateFilterValueEnum.DAY_30}
        />

        <SpaceBetweenStyled className="review_section">
          <div className="staff_avatar_section">
            <Image src={staffDetails?.avatar_path || Avatar} alt="staff" width={65} />
            <Heading color="black" size={1.4}>{isStaffSuccess ? `${staffDetails?.first_name} ${staffDetails?.last_name}` : "" }</Heading>
            <Paragraph color="secondary">{staffDetails?.business_details?.name || ""}</Paragraph>
          </div>
          
          <FlexColumnStyled $gap={1.4}>
            <SpaceBetweenStyled>
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                title="Total Volume"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? (numberFormatter(staffAnalytics?.total_volume || 0)) : "##"}
                noBorder
              />
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                className="left"
                title="Total Value"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_value?.amount || 0) : "##"}
                noBorder
              />
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                title="Total Profit"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_profit?.amount || 0) : "##"}
                noBorder />
              
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                className="left"
                title="Total Debt"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_debt?.amount || 0) : "##"}
                noBorder
              />
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                title="Total Commission"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_commission || 0) : "##"}
                noBorder
              />
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                className="left"
                title="Product Commission"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.product_commission || 0) : "##"}
                noBorder
              />
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                title="Service Commission"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.service_commission || 0) : "##"}
                noBorder
              />
              <CustomerAndStaffReviewCard
                isLoading={isStaffAnalyticsLoading}
                className="left"
                title="Total Service Cost"
                value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_service_cost || 0) : "##"}
                noBorder
              />
            </SpaceBetweenStyled>
          </FlexColumnStyled>
        </SpaceBetweenStyled>

        <Line />

        <SectionStyled>
          <Heading color="black" size={1.3}>Staff Information</Heading>

          <CustomerInformationStyled>
            <div className="customer_bio_data">
              <Fragment>
                {isStaffSuccess && (
                  <Fragment>
                    <StaffDetail title="Name" value={`${staffDetails.first_name} ${staffDetails.last_name}`} />
                    <StaffDetail title="Email" value={staffDetails.email} />
                    <StaffDetail title="Phone" value={`0${staffDetails.phone}`} />
                    <StaffDetail title="Address" value={staffDetails.address} />
                    <StaffDetail title="Start Date" value="10-10-2022" />
  
                    <CustomerDetailsStyled>
                      <Heading color="menuGray">Other businesses</Heading>
                      {staffAvailableBusinesses()?.map(business => (
                        <Heading color="black" size={1.1} key={business?.id}>{business?.store_name}</Heading>
                      ))}
                    </CustomerDetailsStyled>
                  </Fragment>
                )}
  
                {isStaffLoading && (
                  <TableSkeleton count={4} height={35} />
                )}
              </Fragment>
            </div>

            <div className="customer_activities">
              <SpaceBetweenStyled>
                <FlexStyled>
                  <Chips title="Recent Orders" active={tabState === "orders"} onClick={() => setTabState("orders")}/>

                  {(businessSettings?.wip && [...userProfile?.[0]?.package_details?.modules].includes("WIP")) && (
                    <Chips title="Wip" active={tabState === "wip"} onClick={() => setTabState("wip")} />
                  )}
                  
                  <Chips title="Tips" active={tabState === "tips"} onClick={() => setTabState("tips")} />                    {/*<Chips title="Sales" />*/}
                  {/*<Chips title="Sales" />*/}
                  {/*<Chips title="Customers" />*/}
                  {/*<Chips title="Reports" />*/}
                </FlexStyled>

                <Chips title="View all" />
              </SpaceBetweenStyled>

              {tabState === "orders" && <OrderTable dateFilterObj={dateFilterObj} />}
              {tabState === "wip" && <WipTable dateFilterObj={dateFilterObj} />}
              {tabState === "tips" && <TipsTable dateFilterObj={dateFilterObj} />}

            </div>
          </CustomerInformationStyled>
        </SectionStyled>

      </SingleCustomerStyled>
    </div>
  )
}

export default SingleStaff

const OrderTable = ({ dateFilterObj }) => {
  const { data: staffDetails } = useUserProfile()
  const { customerOrderTable } = tableHeadersAndValueKeys
  const { debounceState } = useQueryDebounce()
  const { mainQuery, ...allStaffOrders } = useSearchQuery(debounceState, useGroupOrderByStaffIdQuery.bind(this, { ...dateFilterObj, staffIdByProps: staffDetails?.[0]?.id || "" }))

  return (
    <Table headers={customerOrderTable.headers} noPadding equalWidth noCols={customerOrderTable.headers.length}  >
      {mainQuery.isSuccess && (
        <Fragment>
          {topNData(allStaffOrders?.data?.results, 10).map((item, k) => (
            <TableItemForGroupOrders
              statusPosition={4}
              count={k}
              pageCount={allStaffOrders.currentCount}
              key={k}
              data={item}
              keys={customerOrderTable.values}
            />
          ))}
          
          {allStaffOrders.data.results.length === 0 && (
            <NoQueryData text="No staff order " />
          )}
        </Fragment>
      )}
  
      {allStaffOrders.isQueryLoading && (
        <TableSkeleton />
      )}
    </Table>
  )
}

const WipTable = () => {
  const { staffId } = useParams()
  const { debounceState } = useQueryDebounce()
  const { staffWipTable } = tableHeadersAndValueKeys
  const { mainQuery, ...allStaffWip } = useSearchQuery(debounceState, useWipByStaffIdQuery.bind(this, staffId))
  
  return (
    <Table headers={staffWipTable.headers} noCols={staffWipTable.headers.length} equalWidth>
      {allStaffWip.isQueryWithData && (
        allStaffWip.data.results.map((items, k) =>
          <TableItemWithStatus
            key={k}
            count={k}
            pageCount={allStaffWip.currentCount}
            data={items}
            noAction
            statusPosition={2}
            keys={staffWipTable.values}
          />
        )
      )}
      
      {allStaffWip.isQueryWithNoData && (
        <NoQueryData text={allStaffWip.isSearchEmptyMessage("Stage", "")} />
      )}
  
      {allStaffWip.isQueryLoading && (
        <TableSkeleton />
      )}
    </Table>
  )
}


const TipsTable = ({ dateFilterObj }) => {
  const { staffId } = useParams()
  const { debounceState } = useQueryDebounce()
  const { staffTipsTable } = tableHeadersAndValueKeys
  const { mainQuery, ...allStaffTips } = useSearchQuery(debounceState, useTipQuery.bind(this, { staff_id: staffId, ...dateFilterObj }))
  
  return (
    <Table headers={staffTipsTable.headers} noCols={staffTipsTable.headers.length} equalWidth>
      {allStaffTips.isQueryWithData && (
        allStaffTips.data.results.map((items, k) =>
          <TableItemWithStatus
            key={k}
            count={k}
            pageCount={allStaffTips.currentCount}
            data={items}
            noAction
            statusPosition={2}
            keys={staffTipsTable.values}
          />
        )
      )}
      
      {allStaffTips.isQueryWithNoData && (
        <NoQueryData text={allStaffTips.isSearchEmptyMessage("Stage", "")} />
      )}
  
      {allStaffTips.isQueryLoading && (
        <TableSkeleton />
      )}
    </Table>
  )
}
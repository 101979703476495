import {useEffect, useRef} from "react";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {
  clearAllDesktopBusinessRequestSynced,
  setBusinessIsReadyForOffline
} from "../../../utils/desktopUtils/desktopSyncUtils";

export const useIsOfflineLoginAvailable = () => {
  const isAvailable = useRef(false)
  
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
      
      // if there is no user in offline db that means offline login is not available
      ipcRenderer.on(IPC_EVENTS.GET_NUMBER_OF_USER_IN_OFFLINE_DB, (_, data) => {
        const {user_count, allSyncedBusinesses = []} = data || {}
        isAvailable.current = (Number(user_count) > 0 && allSyncedBusinesses.length > 0)
    
        allSyncedBusinesses.forEach(synced_business => {
          setBusinessIsReadyForOffline(true, synced_business)
        })
    
        // if there is no synced business in db, remove all business synced status from local storage
        if (!allSyncedBusinesses || allSyncedBusinesses.length === 0) {
          clearAllDesktopBusinessRequestSynced()
        }
      })
      
      ipcRenderer.send(IPC_EVENTS.GET_NUMBER_OF_USER_IN_OFFLINE_DB)
    }
  }, [])
  
  return isAvailable.current
}
import {useContext} from "react";
import globalProvider from "../../../providers/GlobalProvider";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createDiscountService,
  deleteDiscountService,
  editDiscountService,
  getDiscountService
} from "../../../services/discountServices";
import {getBusinessIdFromStorage, scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopDiscountsServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useDataTypeFormatter from "../../useUtils/useDataTypeFormatter";
import productQueryKey from "../useProductServices/productQueryKey";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const mainKey = ["Discount", getBusinessIdFromStorage()]
export const discountQueryKeys = {
  main: [...mainKey],
  mainList: (search = "") => [...mainKey, search]
}

const useDiscountQuery = (search) => {
  scrollToTop()
  const {currencyFormatter} = useDataTypeFormatter()
  const {getBusinessId, isOffline} = useContext(globalProvider)
  
  const offlineData = useDesktopDiscountsServiceFromLocalDB(search)

  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: discountQueryKeys.mainList(search),
    queryFn: () => getDiscountService({business_id: getBusinessId(), search}, currencyFormatter)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}


const useCreateDiscountMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useContext(globalProvider)

  return useMutation({
    mutationFn: data => {
      return createDiscountService({...data, business: getBusinessId()})
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Discount Created!!")
      queryClient.invalidateQueries({queryKey: discountQueryKeys.main})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
    
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createDiscount,
      })
    }
  })
}

const useEditDiscountMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useContext(globalProvider)

  return useMutation({
    mutationFn: data => {
      return editDiscountService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({queryKey: discountQueryKeys.main})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
    
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateDiscount,
      })
    }
  })
}

const useDeleteDiscountMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useContext(globalProvider)

  return useMutation({
    mutationFn: discountId => {
      return deleteDiscountService(discountId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({queryKey: discountQueryKeys.main})
      queryClient.invalidateQueries({queryKey: productQueryKey.all})
  
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useDiscountQuery, useCreateDiscountMutation, useEditDiscountMutation, useDeleteDiscountMutation }
import Form from "../../Forms/Form";
import {Button, CreateBankModal, CustomerModal, Select} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import PaymentMethod from "../../Forms/PaymentMethod";
import {Fragment, memo, useState} from "react";
import {useBankQuery, useCreateBankMutation} from "../../../hooks/useServices/useBankServices";
import {useModal} from "../../../hooks";
import {useCreateCustomerSubscriptionMutation} from "../../../hooks/useServices/useCustomerSubscriptionServices";
import {TableOrderSummaryStyled} from "../../../styles/drawerStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useFormikContext} from "formik";
import {ArrowSwapHorizontal} from "iconsax-react";
import {useCreateCustomerMutation, useCustomerQuery} from "../../../hooks/useServices/useCustomerServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import usePaymentMethods from "../../../hooks/useUtils/usePaymentMethods";
import PaymentModal from "../../Modal/PaymentModal";


const BusinessSubscriptionCheckout = ({ onClose, closeDrawer, subscription }) => {
	const { customerSubscriptionPaymentMethods } = usePaymentMethods()
	const { data: allBanks } = useBankQuery()
	const { currencyFormatter } = useDataTypeFormatter()
	
	const [openOvaloopPayModal, setOpenOvaloopPayModal] = useModal()
	const [dataForOvaloopPay, setDataForOvaloopPay] = useState()
	
	const [showCreateBankModal, setShowCreateBankModal] = useModal()
	const [showCreateCustomerModal, setShowCreateCustomerModal] = useModal()
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
	const createBankMutation = useCreateBankMutation({ successFn: setShowCreateBankModal })

	const { debounceState: customerDebounceState, selectFieldSearch: setCustomerSearchValue } = useQueryDebounce()
	const { isSuccess, data: allCustomers } = useCustomerQuery(customerDebounceState.searchValue)


	const handleSubmitSuccessful = (res) => {
		if(res.pay_mode === "ovaloop_transfer") {
			setOpenOvaloopPayModal()
			setDataForOvaloopPay(res)
			return
		}
		
		onClose()
		closeDrawer()
	}

	const createCustomerMutation = useCreateCustomerMutation({ successFn: setShowCreateCustomerModal })
	const createCustomerSubscriptionMutation = useCreateCustomerSubscriptionMutation({ successFn: handleSubmitSuccessful })

	const handleCreateCustomerSubscription = (values) => {
		const body = {
			...values,
			subscription_service: subscription.id
		}

		createCustomerSubscriptionMutation.mutate(body)
	}

	const CreateBank = () => (
		<label className="underline" onClick={setShowCreateBankModal}>Create +</label>
	)

	const CreateCustomer = () => (
		<label className="underline" onClick={setShowCreateCustomerModal}>Create +</label>
	)
	
	const onOvaloopTransferComplete = () => {
		setDataForOvaloopPay(null)
		onClose()
		closeDrawer()
		setOpenOvaloopPayModal()
	}

	return (
		<ContainerStyled className="checkout">
			{openOvaloopPayModal && (
				<PaymentModal
					dynamicAccountBody={{ subscription_log: dataForOvaloopPay?.id }}
					verifyAccountQueryParam={{ subscription_log: dataForOvaloopPay?.id }}
					order={dataForOvaloopPay}
					onClose={onOvaloopTransferComplete}
				/>
			)}
			
			{showCreateBankModal && (
				<CreateBankModal
					position="fixed"
					onClose={setShowCreateBankModal}
					mutation={createBankMutation}
					handleSubmit={createBankMutation.mutate}
				/>
			)}

			{showCreateCustomerModal && (
				<CustomerModal
					position="fixed"
					onClose={setShowCreateCustomerModal}
					mutation={createCustomerMutation}
					handleSubmit={createCustomerMutation.mutate}
				/>
			)}

			<br/>
			<Form values={{ pay_method: "", display_bank: "", customer: "", customer_name: "", banks: "", pay_mode: "" }} onSubmit={handleCreateCustomerSubscription}>
				{!selectedPaymentMethod && (
					<PaymentMethod
						label="Select Payment Method"
						name="pay_mode"
						methods={customerSubscriptionPaymentMethods}
						setPaymentMethod={setSelectedPaymentMethod}
					/>
				)}

				{!!selectedPaymentMethod && (
					<Fragment>
						<ShowSelectedPayment selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />
						<br />

						{selectedPaymentMethod === "Bank Transfer" && (
							<Select
								label="Select bank"
								name="banks"
								options={allBanks}
								displayKey="bankInfo"
								valueKey="id"
								displayName="display_bank"
								placeholder="Select bank to transfer"
								SideInfo={CreateBank}
							/>
						)}

						<Select
							options={isSuccess ? allCustomers.results : []}
							displayKey="full_name"
							valueKey="id"
							name="customer"
							label="Customer"
							placeholder="Select customer"
							displayName="customer_name"
							SideInfo={CreateCustomer}
							updateQueryResult={setCustomerSearchValue}
						/>
					</Fragment>
				)}

				<Button type="submit" id="submit_checkout" text="submit" isLoading={createCustomerSubscriptionMutation.isLoading} />
			</Form>

			<TableOrderSummaryStyled $noMinHeight $loading={createCustomerSubscriptionMutation.isLoading}>
				<SpaceBetweenStyled>
					<Heading color="secondary">Subtotal:</Heading>
					<Heading color="secondary">{currencyFormatter(subscription.value)}</Heading>
				</SpaceBetweenStyled>

				<SpaceBetweenStyled>
					<Heading color="black" bold size={1.1}>Total:</Heading>
					<Heading color="black" bold size={1.1}>{currencyFormatter(subscription.value)}</Heading>
				</SpaceBetweenStyled>

				<label className="submit_checkout" htmlFor="submit_checkout">Check Out</label>
			</TableOrderSummaryStyled>
		</ContainerStyled>
	)
}

export default BusinessSubscriptionCheckout



const ShowSelectedPayment = memo(({ setSelectedPaymentMethod, selectedPaymentMethod }) => {
	const { setFieldValue } = useFormikContext()

	const handleChangePayment = () => {
		setSelectedPaymentMethod("")
		setFieldValue("pay_mode", "")
	}

	return (
		<SpaceBetweenStyled className="show_payment_info">
			<FlexStyled $gap={.5}>
				<Heading color="black">Payment Method:</Heading>
				<Paragraph noTop color="secondary" bold>{selectedPaymentMethod.toUpperCase()}</Paragraph>
			</FlexStyled>

			<Paragraph color="secondary" bold className="underline" onClick={handleChangePayment}>
				CHANGE
				<ArrowSwapHorizontal size={16} color="black"/>
			</Paragraph>
		</SpaceBetweenStyled>
	)
})
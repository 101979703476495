import {FlexColumnStyled, FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {
  CustomerDetailsStyled,
  CustomerInformationStyled,
  SingleCustomerStyled
} from "../../../../styles/singleCustomerStyles";
import {
  Chips,
  CustomerAndStaffReviewCard,
  Line,
  ScreenLoading,
  Table,
  TableItemWithStatus,
} from "../../../../components";
import {Fragment, useState} from "react";
import {ArrowLeft} from "iconsax-react";
import {SingleOrderHeaderStyled} from "../../../../styles/singleOrderStyles";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {useTheme} from "styled-components";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {
  usePurchaseOrderAnalyticsService
} from "../../../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {topNData} from "../../../../utils";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import {
  handleMainAddress
} from "../../../../services/customerServices/customerServiceStructure";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {useParams} from "react-router-dom";
import DateFilter from "../../../../components/Utils/DateFilter";
import {useSupplierByIdQuery} from "../../../../hooks/useServices/useSupplierServices";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {usePurchaseOrderQuery} from "../../../../hooks/useServices/usePurchaseOrder";


const CustomerDetail = ({ title, value }) => {
  return (
    <CustomerDetailsStyled>
        <Heading color="menuGray">{title}</Heading>
        <Heading color="black" size={1.1}>{value}</Heading>
    </CustomerDetailsStyled>
  )
}


const SingleSupplier = () => {
  const { supplierId } = useParams()
  const theme = useTheme()
  
  const [dateFilterObj, setDateFilterObj] = useState({ from_date: "", to_date: "" })
  const [statusState, setStatusState] = useState("")

  const { purchaseOrderTable } = tableHeadersAndValueKeys
  const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
  const { navigate } = useGlobalContext()
  
  const queryState = { status: statusState, ...dateFilterObj, supplier: supplierId }
  const { mainQuery: supplierPurchaseOrders, ...allSupplierOrderQuery } = useOrderQueryWithSearchAndFilter(queryState, usePurchaseOrderQuery)
  
  const { data: customer, isLoading: isSupplierQueryLoading, status, refetch } = useSupplierByIdQuery(supplierId)
  const { data: purchaseOrderAnalytics, isLoading: isPurchaseOrderAnalyticsLoading, refetch: refetchPurchaseOrderAnalytics } = usePurchaseOrderAnalyticsService({ supplier: supplierId, ...dateFilterObj })
  
  
  const chipsText = [
    { name: "All Orders", value: "" },
    { name: "Pending", value: "pending" },
    { name: "Confirmed", value: "confirmed" },
  ]
  
  const refetchQueries = async () => {
    await refetch()
    await refetchPurchaseOrderAnalytics()
  }
  
  const handleViewPurchaseOrder = (purchaseOrder) => {
    navigate(`/dashboard/inventory/purchase_order/${purchaseOrder.id}`)
  }
  
  return (
    <div>
      
      {status === "error" && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}
      
        <Fragment>
          <SingleCustomerStyled>
            <SingleOrderHeaderStyled>
              <FlexStyled className="navigation_section">
                <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
                <FlexStyled>
                  <Paragraph noTop color="menuGray">Back to supplier</Paragraph>
                  <Heading color="black">
                    {customer?.name}
                  </Heading>
                </FlexStyled>
              </FlexStyled>
              
              <DateFilter placeholder="Filter analytics" setDateFilter={setDateFilterObj} />
            </SingleOrderHeaderStyled>

            <SpaceBetweenStyled className="review_section">
              <CustomerAndStaffReviewCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Number Of Orders"
                value={numberFormatter(purchaseOrderAnalytics?.total_purchase_order || 0)}
                subTitle={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_purchase_order_value || 0)}`}
              />
              <CustomerAndStaffReviewCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Paid Orders"
                value={numberFormatter(purchaseOrderAnalytics?.total_paid || 0)}
                subTitle={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_paid_amount || 0)}`}
                statusColor="#17E383"
              />
              <CustomerAndStaffReviewCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Pending Orders"
                value={numberFormatter(purchaseOrderAnalytics?.total_pending || 0)}
                // subTitle={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_paid_amount || 0)}`}
                statusColor="#FF9548"
              />
              <CustomerAndStaffReviewCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Credit Orders"
                value={numberFormatter(purchaseOrderAnalytics?.total_credit_orders || 0)}
                subTitle={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_balance_amount || 0)}`}
                statusColor="#F24E30"
              />
            </SpaceBetweenStyled>

            <Line />

            <SectionStyled>
              <Heading color="black" size={1.3}>Supplier Information</Heading>
              
              <CustomerInformationStyled>
                
                <FlexColumnStyled>
                  {isSupplierQueryLoading && (
                    <TableSkeleton height={170} count={1} />
                  )}
                  
                  <div className="customer_bio_data">
                    {!isSupplierQueryLoading && (
                      <Fragment>
                        <CustomerDetail title="Name" value={customer?.name} />
                        <CustomerDetail title="Email" value={customer?.email} />
                        <CustomerDetail title="Phone" value={customer?.phone} />
                        <CustomerDetail title="Address" value={handleMainAddress(customer?.address)} />
  
                        <CustomerDetail title="Category" value={customer?.category_name} />
                        <CustomerDetail title="Contact Person" value={customer?.contact_person} />
                        <CustomerDetail title="Contact Phone" value={customer?.contact_phone} />
                      </Fragment>
                    )}
  
                    {isSupplierQueryLoading && (
                      <Fragment>
                        <TableSkeleton count={4} height={35} />
                      </Fragment>
                    )}
                  </div>
                </FlexColumnStyled>

                <div className="customer_activities">
                  <Fragment>
                    <SpaceBetweenStyled>
                      <FlexStyled>
                        {chipsText.map(item => (
                          <Chips
                            key={item.name}
                            title={item.name}
                            active={item.value === statusState}
                            onClick={() => setStatusState(item.value)}
                          />
                        ))}
                      </FlexStyled>

                    </SpaceBetweenStyled>

                    <Table headers={purchaseOrderTable.headers} noPadding noCols={purchaseOrderTable.headers.length}  >
                      {supplierPurchaseOrders.isSuccess && (
                        <Fragment>
                          {topNData(allSupplierOrderQuery?.data?.results, 50).map((item, k) => (
                            <TableItemWithStatus
                              statusPosition={5}
                              key={k}
                              count={k}
                              pageCount={allSupplierOrderQuery.currentCount}
                              data={item}
                              onView={handleViewPurchaseOrder}
                              keys={purchaseOrderTable.values}
                            />
                          ))}
                        </Fragment>
                      )}

                      {allSupplierOrderQuery?.data?.results.length === 0 && (
                        <NoQueryData text={`No Order ${!!statusState ? `for status: "${statusState}"` : ""}`} />
                      )}

                      {allSupplierOrderQuery.isQueryLoading && (
                        <TableSkeleton />
                      )}
                    </Table>
                  </Fragment>
                </div>
              </CustomerInformationStyled>
            </SectionStyled>

          </SingleCustomerStyled>
        </Fragment>
    </div>
  )
}

export default SingleSupplier

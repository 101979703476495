import {useEffect, useState} from "react";
import {findCountryById} from "../utils/forms";
import {getAllLocalsByStateIdService, getAllStatesByCountryIdService} from "../services/formFieldServices";


const useFormWithCountryAndState = () => {
  const [additionalDetails, setAdditionalDetails] = useState({
    country: {
      id: "",
      flag: "",
      dialCode: ""
    },
    state: {
      id: "",
      name: "",
      country: ""
    },
    locals: [],
    statesOrProvinces: []
  })

  // update the state array when a country is chosen or changes
  useEffect(() => {
    (async function() {
      if(!!additionalDetails.country.id) {
        const data = await getAllStatesByCountryIdService(additionalDetails.country.id)
        setAdditionalDetails(prevState => ({
          ...prevState,
          statesOrProvinces: data
        }))
      }
    })()
  }, [additionalDetails.country.id])


  // update the local array when a state is chosen or changes
  useEffect(() => {
    (async function() {
      if(!!additionalDetails.state.id) {
        const data = await getAllLocalsByStateIdService(additionalDetails.state.id)
        setAdditionalDetails(prevState => ({
          ...prevState,
          locals: data
        }))
      }
    })()
  }, [additionalDetails.state.id])

  const updateCountry = (data) => {
    setAdditionalDetails(prevState => ({
      ...prevState,
      country: data
    }))
  }

  const updateState = (data) => {
    setAdditionalDetails(prevState => ({
      ...prevState,
      state: data
    }))
  }

  const populateDataForEditing = (countries, countryId) => {
    if(!!countries && !!countryId) {
      updateCountry(findCountryById(countries, countryId))
      // updateState(findStatesByCountryId(countryId))
    }
  }

  return [additionalDetails, setAdditionalDetails, updateCountry, updateState, populateDataForEditing]
}

export default useFormWithCountryAndState
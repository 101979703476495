import queryKeys from "../queryKeys";
import {
  createStoreService,
  deleteStoreService,
  editStoreService,
  getAllStoresService,
  getStoreByIdService,
  getStoresByCountryIdService,
  getStoresService
} from "../../../services/storeServices";
import {getIsDesktopOnlineStatus, scrollToTop, setBusinessIdInStorage} from "../../../utils";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {useProductNotifyQuery} from "../useProductServices";
import {
  desktopOfflineQueryKeys,
  useDesktopAllBusinessServiceFromLocalDB,
  useDesktopBusinessServiceFromLocalDB
} from "../../useDesktop/useDesktopServicesFromLocalDB";


const useStoreQuery = (searchValue = " ") => {
  scrollToTop()
  const {isOffline} = useGlobalContext()
  const offlineData = useDesktopAllBusinessServiceFromLocalDB()
  
  const isUseOfflineQuery = (isOffline || getIsDesktopOnlineStatus() === "offline")
  
  const onlineData = useQuery({
    retry: 2,
    enabled: !isUseOfflineQuery,
    queryKeys: [queryKeys.STORES, searchValue],
    queryFn: () => getStoresService(searchValue),
  })
  
  const fetch = () => {
    return isUseOfflineQuery ? offlineData : onlineData
  }
  
  return fetch()
}

export const useAllStoresQuery = (searchValue = " ") => {
  scrollToTop()
  
  return useQuery(
    ["All Stores X", searchValue],
    getAllStoresService.bind(this, searchValue),
  )
}

const useBusinessByIdQuery = (business) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  const businessId = getBusinessId()

  useProductNotifyQuery(business || businessId)
  
  const offlineData = useDesktopBusinessServiceFromLocalDB()
  
  const onlineData = useQuery({
    enabled: (!!business || !!businessId),
    queryKey: [queryKeys.STORES, (business || businessId)],
    queryFn: () => getStoreByIdService(business || businessId)
  })
  
  const fetch = () => {
    return (isOffline && offlineData.data) ? offlineData : onlineData
  }
  
  return fetch()
}

const useStoreByCountryIdQuery = (countryId = " ", searchValue = " ") => {
  return useQuery(
    [queryKeys.STORES, countryId, searchValue],
    getStoresByCountryIdService.bind(this, countryId, searchValue),
  )
}

const useCreateStoreMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  const { data: allStores } = useStoreQuery()

  return useMutation({
    mutationFn: data => {
      return createStoreService(data)
    },

    onSuccess: res => {
      successFn()
      toast.success("Store Created!!")

      if(allStores.length === 0) {
        setBusinessIdInStorage(res.id)
      }

      queryClient.invalidateQueries({ queryKey: [queryKeys.STORES] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditStoreMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: (data) => {
      return editStoreService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({ queryKey: [queryKeys.STORES] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteStoreMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: (storeId) => {
      return deleteStoreService(storeId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: [queryKeys.STORES] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


const useSetBusinessMutation = () => {
  const {navigate} = useGlobalContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (storeId) => {
      if (localStorage.getItem("is_offline_login")) {
        queryClient.refetchQueries({
          queryKey: desktopOfflineQueryKeys.business(),
        }).then().catch()
        return
      }
  
      queryClient.prefetchQuery({
        queryKey: [queryKeys.STORES, storeId],
        queryFn: getStoreByIdService(storeId)
      }).then().catch()
    },
    onError: () => {
    },
    onSettled: () => {
      localStorage.setItem("is_offline_login", null)
      navigate("/dashboard")
      navigate(0)
    }
  })
}

export { useStoreQuery, useBusinessByIdQuery, useSetBusinessMutation, useStoreByCountryIdQuery, useCreateStoreMutation, useEditStoreMutation, useDeleteStoreMutation }
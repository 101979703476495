import {CloseSquare} from "iconsax-react";
import { Button, Form, Input } from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {bankFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const CreateBankModal = ({ onClose, handleSubmit, mutation }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Bank</Heading>
        <Form values={bankFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Bank Name" placeholder="Enter bank name" name="name"  />
          <Input label="Account Name" placeholder="Enter account name" name="account_name"  />
          <Input label="Account Number" type="number" placeholder="Enter account number" name="account_no"  />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateBankModal
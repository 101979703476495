import {ProductProvider} from "../../../../providers/ProductProvider";
import Product from "./Product";
import useProductReducer, {productReducerActionTypes} from "../../../../reducers/productReducer";

const ProductWithContext = () => {
  const [state, dispatch] = useProductReducer()
  
  return (
    <ProductProvider.Provider value={{ state, dispatch, actions: productReducerActionTypes }}>
      <Product />
    </ProductProvider.Provider>
  )
}


export default ProductWithContext
import {
  CreateNestedSubCategoryModal, EditNestedSubCategoryModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import { AddCircle } from "iconsax-react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../SetUpStyles";
import {subCategoryFormDefaultValues} from "../../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {SubcategoryImage} from "../../../../assets/images";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {useParams} from "react-router-dom";
import {actionsPermissions} from "../../../../data/permissions";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {
  useNestedSubCategoriesQuery,
  useEditNestedSubCategoryMutation,
  useDeleteNestedSubCategoryMutation,
  useCreateNestedSubCategoryMutation,
  nestedSubCategoryQueryKeys
} from "../../../../hooks/useServices/useNestedSubCategoryServices";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {MAX_NESTED_SUB_CATEGORY_LEVEL} from "../../../../utils/constants";
import {
  nestedSubCategoryRestructure
} from "../../../../services/nestedSubCategoryServices/nestedSubCategoryRestructure";


const NestedSubCategory = () => {
  const { parentCategoryName, parentCategoryId, level } = useParams()
  const { toast, navigate } = useGlobalContext()
  const [showEditSubCategoryModal, setShowEditSubCategoryModal] = useModal()
  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] = useModal(modalNames.CREATE_SUBCATEGORY_MODAL)

  const { nestedSubCategoriesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const { searchQuery, ...allNestedSubcategories } = useOrderQueryWithSearchAndFilter({
    search: debounceState.searchValue,
    level: Number(level),
    ...(Number(level) === 1 ? {sub_category: parentCategoryId} : {parent: parentCategoryId})
  }, useNestedSubCategoriesQuery)
  
  const [editSubCategoryFormValues, setEditSubCategoryFormValues] = useState(subCategoryFormDefaultValues)
  const deleteSubCategoryMutation = useDeleteNestedSubCategoryMutation({ successFn: () => {} })
  const editSubCategoryMutation = useEditNestedSubCategoryMutation({ successFn: setShowEditSubCategoryModal })
  const createSubCategoryMutation = useCreateNestedSubCategoryMutation({ successFn: setShowCreateSubCategoryModal })
  
  const handleViewNestedSubCategory = (subCategory) => {
    const nextLevel = Number(level) + 1
    if(nextLevel > MAX_NESTED_SUB_CATEGORY_LEVEL) return
    
    const parentId = subCategory?.parent || subCategory?.sub_category
    navigate(`/dashboard/set-up/categories/nested-subcategory-category/${parentId}/${nextLevel}/${subCategory.id}/${subCategory.name}`)
  }
  
  const handleCreateSubCategory = (values) => {
    const body = {
      ...values,
      level: Number(level),
      ...(Number(level) === 1 ? { sub_category: parentCategoryId } : { parent: parentCategoryId })
    }

    createSubCategoryMutation.mutate(body)
  }

  const handleEditSubCategory = (values) => {
    editSubCategoryMutation.mutate(values)
  }

  const handleEditAction = (subCategory) => {
    setEditSubCategoryFormValues(subCategory)
    setShowEditSubCategoryModal()
  }

  const handleDeleteAction = (subCategory) => {
    toast.confirm(
      "Are you sure?",
      deleteSubCategoryMutation.mutate.bind(this, subCategory.id),
      "delete"
    )
  }
  
  return (
    <SetUpMainStyled>
      {showCreateSubCategoryModal && <CreateNestedSubCategoryModal onClose={setShowCreateSubCategoryModal} handleSubmit={handleCreateSubCategory} mutation={createSubCategoryMutation} />}
      {showEditSubCategoryModal && <EditNestedSubCategoryModal onClose={setShowEditSubCategoryModal} handleSubmit={handleEditSubCategory} mutation={editSubCategoryMutation} formValues={editSubCategoryFormValues} />}
  
      {!!searchQuery.isError && (
        <ScreenLoading refetchFn={allNestedSubcategories.refetch} />
      )}
      
      <Fragment>

        {allNestedSubcategories.isEmpty && (
          <Fragment>
            <PageHeader isBack title={`Sub-categories in ${parentCategoryName}`} />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={SubcategoryImage}
              heading="No nested sub-category found"
              buttonText="Add Sub-Category"
              buttonClick={setShowCreateSubCategoryModal}
              permissionCode={actionsPermissions.createCategory}
            />
          </Fragment>
        )}

        {allNestedSubcategories.showTable && (
          <Fragment>
            <PageHeaderWithButton
              isBack
              noBackText
              buttonClick={setShowCreateSubCategoryModal}
              title={`Sub-categories in ${parentCategoryName}`}
              extra={`Level ${Number(level) + 2}`}
              buttonText="Create"
              Icon={AddCircle}
              permissionCode={actionsPermissions.createCategory}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                valueKey="id"
                displayKey="name"
                dispatch={debounceReducer}
                searchPlaceholder="Search nested sub-category by name"
              />

              <Table headers={nestedSubCategoriesTable.headers} noCols={nestedSubCategoriesTable.headers.length}>
                
                {allNestedSubcategories.isQueryWithData && (
                  allNestedSubcategories.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      onClick={Number(level) < MAX_NESTED_SUB_CATEGORY_LEVEL ? handleViewNestedSubCategory : null}
                      pageCount={allNestedSubcategories.currentCount}
                      editAction={handleEditAction}
                      keys={nestedSubCategoriesTable.values}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.updateCategory}
                      deletePermission={actionsPermissions.deleteCategory}
                    />
                  )
                )}

                {allNestedSubcategories.isQueryWithNoData && (
                  <NoQueryData text={allNestedSubcategories.emptyMessage("Nested Sub-Category")} />
                )}

                {allNestedSubcategories.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allNestedSubcategories.showPagination &&
                <TablePagination
                  restructureFn={nestedSubCategoryRestructure}
                  query={allNestedSubcategories}
                  queryKey={nestedSubCategoryQueryKeys.main(allNestedSubcategories.queryValues)}
                />
              }
            </SetUpLayoutStyled>
            
            {allNestedSubcategories.showPagination &&
              <TablePagination
                restructureFn={nestedSubCategoryRestructure}
                query={allNestedSubcategories}
                queryKey={nestedSubCategoryQueryKeys.main(allNestedSubcategories.queryValues)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default NestedSubCategory

const getOrderValueDetails = (products = [], currencyFormatter, dateFormatter, dateOnlyFormatter, timeFormatter) => {
	if (!!products && products.length < 1) return
	
	let totalAmount = 0;
	let totalItemCost = 0;
	let totalServiceCost = 0;
	let totalTaxCost = 0;
	let totalDiscountAmount = 0;
	
	const allAvailableProducts = products.filter(order => !(order.returned === true && order.return_type === "order"))
	
	allAvailableProducts?.forEach(item => {
		if (!item.complimentary) {
			totalTaxCost += Math.abs(Number(item?.tax_amount)) || 0
			totalServiceCost += Number(item?.service_cost) || 0
			totalDiscountAmount += Number(item?.discount_amount) || 0
			totalAmount += Number(item?.total_cost) || 0
			totalItemCost += (Number(item?.product_cost_before_tax) || Number(item?.product_cost) || 0)
		}
	})

	const summary = {
		totalItemCost: currencyFormatter(totalItemCost),
		totalTaxCost: currencyFormatter(totalTaxCost || 0),
		totalServiceCost: currencyFormatter(totalServiceCost),
		totalDiscountAmount: currencyFormatter(totalDiscountAmount),
		totalDeliveryRate: currencyFormatter(products[0]?.group_order?.group_delivery || 0),
		overallTotal: currencyFormatter(products[0]?.group_order?.group_total_cost)
	}
	
	const orderDetails = [
		{key: "Total Amount", value: currencyFormatter(totalAmount)},
		{key: "Date", value: dateOnlyFormatter(products[0]?.date_created)},
		{key: "Time", value: timeFormatter(products[0]?.date_created)},
	]
	
	// if(!!products[0]?.payment_date) {
	// 	orderDetails.push({ key: "Payment Date", value: dateOnlyFormatter(products[0]?.payment_date) })
	// }
	
	if (!!products[0]?.remark) {
		orderDetails.push({key: "Remark", value: products[0]?.remark})
	}
	
	if (!!products[0]?.group_order?.note) {
		orderDetails.push({key: "Note", value: products[0]?.group_order?.note})
	}
	
	return {summary, orderDetails}
}

const getOrderStaffDetails = (staff = {}) => {
	return [
		{ key: "Name", value: staff?.name, link: true, id: staff?.id },
		{ key: "Email", value: staff?.email },
		{ key: "Designation", value: staff?.designation || "Admin" }
	]
}

const getOrderBankDetails = (bank = {}) => {
	return [
		{ key: "Bank Name", value: bank?.name },
		{ key: "Acc Name", value: bank?.account_name },
		{ key: "Acc No", value: bank?.account_no }
	]
}

export { getOrderValueDetails, getOrderStaffDetails, getOrderBankDetails }
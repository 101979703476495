import {
	NoDataLayout, PageHeader,
	ScreenLoading,
	SearchAndFilter,
	Table,
	TableItemWithStatus,
	TablePagination
} from "../../../components";
import {Fragment} from "react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {BankImage} from "../../../assets/images";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
	useCustomerSubscriptionQuery
} from "../../../hooks/useServices/useCustomerSubscriptionServices";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {
	customerSubscriptionRestructure
} from "../../../services/customerSubscriptionServices/customerSubscriptionRestructure";
import customerQueryKey from "../../../hooks/useServices/useCustomerServices/customQueryKey";


const PurchasedSubscriptions = () => {
	const { navigate } = useGlobalContext()
	const { debounceState, debounceReducer } = useQueryDebounce()
	const { mainQuery, ...allCustomerSubscriptions } = useSearchQuery(debounceState, useCustomerSubscriptionQuery)

	const { businessPurchasedSubscriptionsTable } = tableHeadersAndValueKeys

	const handleViewPurchase = (subscription) => {
		navigate(`/dashboard/purchased_subscriptions/${subscription.id}`)
	}

	return (
		<SetUpMainStyled>
			{!!mainQuery.isError && (
				<ScreenLoading refetchFn={allCustomerSubscriptions.refetch} />
			)}

			<PageHeader title="Purchased Packages" />
			
			<Fragment>
				{allCustomerSubscriptions.isEmpty && (
					<Fragment>
						<NoDataLayout
							imgWidth={32}
							imgAlt="No subscription"
							imgSrc={BankImage}
							heading="No purchased subscription yet"
						/>
					</Fragment>
				)}

				{allCustomerSubscriptions.showTable && (
					<Fragment>
						<SetUpLayoutStyled>
							<SearchAndFilter
								noFilter
								dispatch={debounceReducer}
								searchPlaceholder="Search subscription by name"
							/>

							<Table headers={businessPurchasedSubscriptionsTable.headers} noCols={businessPurchasedSubscriptionsTable.headers.length}>
								{allCustomerSubscriptions.isQueryWithData && (
									allCustomerSubscriptions.data.results.map((items, k) =>
										<TableItemWithStatus
											key={k}
											data={items}
											count={k}
											statusPosition={2}
											onView={handleViewPurchase}
											pageCount={allCustomerSubscriptions.currentCount}
											keys={businessPurchasedSubscriptionsTable.values}
										/>
									)
								)}

								{allCustomerSubscriptions.isQueryWithNoData && (
									<NoQueryData text={allCustomerSubscriptions.isSearchEmptyMessage("Bank / Account", "")} />
								)}

								{allCustomerSubscriptions.isQueryLoading && (
									<TableSkeleton />
								)}
							</Table>

							{!!allCustomerSubscriptions?.showPagination &&
								<TablePagination
									query={allCustomerSubscriptions}
									queryKey={customerQueryKey.allSubscriptionsQuery(allCustomerSubscriptions.queryValues)}
									restructureFn={customerSubscriptionRestructure}
								/>
							}
						</SetUpLayoutStyled>
						
						{!!allCustomerSubscriptions?.showPagination &&
							<TablePagination
								query={allCustomerSubscriptions}
								queryKey={customerQueryKey.allSubscriptionsQuery(allCustomerSubscriptions.queryValues)}
								restructureFn={customerSubscriptionRestructure}
							/>
						}

					</Fragment>
				)}

			</Fragment>
		</SetUpMainStyled>
	)
}

export default PurchasedSubscriptions
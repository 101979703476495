import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import {PageSummarySkeletonStyled} from "../../styles/skeletonStyles";

const PageSummaryCardSkeleton = ({ cardHeight, titleHeight = 20, headingHeight = 25 }) => {
  return (
    <PageSummarySkeletonStyled $height={cardHeight}>
      <FlexStyled>
        <Paragraph className="title">
          <Skeleton baseColor="#C0C0C0" height={titleHeight} count={1} />
        </Paragraph>
        
        <SpaceBetweenStyled>
          <Heading>
            <Skeleton baseColor="#C0C0C0" height={headingHeight} count={1} />
          </Heading>
  
          <Paragraph>
            <Skeleton baseColor="#C0C0C0" height={headingHeight} count={1} />
          </Paragraph>
        </SpaceBetweenStyled>
      </FlexStyled>
    </PageSummarySkeletonStyled>
  )
}


export default PageSummaryCardSkeleton
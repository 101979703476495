import queryKeys from "../queryKeys";
import {useMutation, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {updateBusinessSettingsService} from "../../../services/settingServices";
import {useBusinessByIdQuery} from "../useStoreServices";
import errorHandler from "../../../utils/errorHandler";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const useBusinessSettingQuery = () => {
  const {data: business} = useBusinessByIdQuery()
  
  return business?.settings
}

const useBusinessSettingMutation = () => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()

  return useMutation({
    mutationFn: ({id, body}) => {
      return updateBusinessSettingsService(id, {...body, business: businessId})
    },
  
    onSuccess: () => {
      toast.success("Settings Updated!!!")
      queryClient.invalidateQueries({queryKey: [queryKeys.STORES, businessId]})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateBusinessSettings,
      })
    }
  })
}


export { useBusinessSettingQuery, useBusinessSettingMutation }
import {Image} from "../components";
import {Heading, Paragraph} from "../styles/textStyles";
import {ErrorCircleIcon, GrayCircleIcon, PendingCircleIcon, ShippingBoxIcon, SuccessCircleIcon} from "../assets/icons";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";


const statusObj = {
  payment_confirmed: "Confirmed",
  approved: "Approved",
  pending: "Pending",
  available: "Available",
  unavailable: "Unavailable",
  confirmed: "Confirmed",
  occupied: "Occupied",
  completed: "Completed",
  processing: "Processing",
  pay_later: "Pay Later",
  returned: "Returned",
  cancelled: "Cancelled",
  rejected: "Rejected",
  return: "Returned",
  partial: "Partial",
  credit: "Credit",
  debit: "Debit",
  cash: "Cash",
  in: "In",
  out: "Out",
  paid: "Paid",
  active: "Active",
  verified: "Verified",
  inactive: "Inactive",
  create: "Create",
  update: "Update",
  delete: "Delete",
  failed: "Failed",
  recall: "Recalled",
  credit_green: "Credit",
  "In Progress": "In Progress",
  in_progress: "In Progress",
  ovaloop_transfer: "Ovaloop transfer",
  pending_verification: "Pending Verification",
  store_available: "Congratulations, store name is available",
  store_already_exist: "Store already exists, please choose another one"
}

const isGreen = [statusObj.payment_confirmed, statusObj.store_available, statusObj.paid, statusObj.create, statusObj.approved, statusObj.confirmed, statusObj.available, statusObj.completed, statusObj.cash, statusObj.in, statusObj.active, statusObj.verified]
const isYellow = [
  statusObj["In Progress"], statusObj.in_progress, statusObj.occupied, statusObj.update, statusObj.pay_later, statusObj.pending_verification,
  statusObj.processing, statusObj.partial, statusObj.credit, statusObj.ovaloop_transfer, statusObj.credit_green]
const isGray = [statusObj.pending]

const statusColor = (text) => {
  return isGreen.includes(text) ? "success" : isYellow.includes(text) ? "pending" : isGray.includes(text) ? "gray" : "error"
}

export const getOrderStatus = (statusText) => {
  const color = statusColor(statusObj[statusText])

  return (
    <Paragraph color={color} thin size={.9} noTop>
      <Image src={statusIcon[color]} alt="" />
      {statusObj[statusText]}
    </Paragraph>
  )
}

const statusIcon = {
  "pending": PendingCircleIcon,
  "error": ErrorCircleIcon,
  "success": SuccessCircleIcon,
  "gray": GrayCircleIcon
}

const checkIfValueIsADate = (value) => {
  const newData = new Date(value).toString()

  if(newData === "Invalid Date") {
    return value
  } else {
    return newData.slice(0, 15)
  }
}

const WIPStatus = (item) => {
  const status = statusObj[item]
  const color = statusColor(status)
  return (
    <td className={color}>
      <Heading color="black" thin size={.9} className="wip_status">
        <Image src={statusIcon[color]} alt={item} />
        {status}
      </Heading>
    </td>
  )
}

const isOrderDisbursed = (order) => {
  return !!order.disbursed
}

const displayTableItemWithStatus = (item, k, statusPosition, order) => {
  switch (k) {
    case statusPosition:
      const status = statusObj[item]
      const color = statusColor(status)
      return (
        <td key={k} className={color}>
          <Heading color="black" thin size={.9}>
            <Image src={statusIcon[color]} alt={item} />
            {status}
          </Heading>
        </td>
      )
    
    case 1:
      return <td key={k}><Heading color="black" thin size={.9}>
        {item}

        {(!!order && isOrderDisbursed(order)) && <IoMdCheckmarkCircleOutline className="group_order_disburse_svg" size={18} color="green" />}
      </Heading></td>

    default:
      if (Object.keys(statusObj).includes(item)) {
        const status = statusObj[item]
        const color = statusColor(status)
    
        return (
          <td key={k} className={color}>
            <Heading color="black" thin size={.9}>
              <Image src={statusIcon[color]} alt={item}/>
              {status}
            </Heading>
          </td>
        )
      }
      return <td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>
  }
}

const displayTableItemWithQuantityAndStatus = (item, k, qualityPosition, statusPosition) => {
  switch (k) {
    case qualityPosition:
      return (
        <td key={k} className="success">
          <Heading color="black" thin size={.9}>{item}</Heading>
        </td>
      )
    case statusPosition:
      const status = "success"
      return (
        <td key={k} className={status}>
          <Heading color="black" thin size={.9}>
            <Image src={SuccessCircleIcon} alt={status} />{item}
          </Heading>
        </td>
      )
    default:
      return <td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>
  }
}

const displayTableItemForOrders = (item, k, numberFormatter) => {
  if(parseInt(item)) {
    item = numberFormatter(item)
  }
  switch (k) {
    case 0:
      return (
        <td key={k}>
          <Heading color="black" thin size={.9}>
            <Image src={ShippingBoxIcon} alt="order" />
          </Heading>
        </td>
      )
    case 5:
      let status1 = "success"
      return (
        <td key={k} className={status1}>
          <Heading color="black" thin size={.9}>
            <Image src={SuccessCircleIcon} alt={status1} />{item}
          </Heading>
        </td>
      )
    case 8:
      const status = "success"
      return (
        <td key={k} className={status}>
          <Heading color="black" thin size={.9}>
            <Image src={SuccessCircleIcon} alt={status} />{item}
          </Heading>
        </td>
      )
    default:
      return <td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>

  }
}

const displayTableItemsForTransactions = (item, k) => {
  switch (k) {
    case 2:
      const status = (item === "Credit" ? "success" : "error")
      const stateIcon = (item === "Credit" ? SuccessCircleIcon : ErrorCircleIcon)
      return (
        <td key={k} className={status}>
          <Heading color="black" thin size={.9}>
            <Image src={stateIcon} alt={status} />{item}
          </Heading>
        </td>
      )

    default:
      return <td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>
  }
}

export {
  WIPStatus,
  displayTableItemForOrders,
  displayTableItemWithStatus,
  checkIfValueIsADate,
  displayTableItemsForTransactions,
  displayTableItemWithQuantityAndStatus
}
import handleQueryParams from "../../../utils/handleQueryParams";
import axios from "../../../axios";
import {settlementLogsRestructure} from "../../ovaloopPaymentServices/ovaloopPaymentRestrucuture";

export const getAllSettlementRequestLogsService = async (queryParams) => {
  const url = handleQueryParams("/settlement_requests", queryParams)
  
  try {
    const { data } = await axios.get(url)
    return settlementLogsRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const approveSettlementService = async (body) => {
  try {
    const { data } = await axios.post("/settlement_approval/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
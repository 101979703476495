import {firebaseDatabase, onValue, ref} from "../firebase/init";
import {getBusinessIdFromStorage} from "../index";
import {formatDatetimeForBackend} from "./desktopSyncUtils";
import {desktopOnlineStatusReducerActions} from "../../reducers/DesktopOnlineStatusReducer";
import {IPC_EVENTS} from "../../shared/ipcEvents";


const setFirebaseLastUpdatedTime = (time) => {
  localStorage.setItem(`last_firebase_updated_time_${getBusinessIdFromStorage()}`, time.toString())
}

const autoSyncOfflineOnFirebaseEventDispatch = ({desktopOnlineReducer, businessSyncStatus}) => {
  if (!window?.require || !getBusinessIdFromStorage()) return
  
  const refUrl = `business/${getBusinessIdFromStorage()}`
  const notificationCounter = ref(firebaseDatabase, (refUrl));
  
  onValue(notificationCounter, (counter) => {
    const lastUpdatedTime = counter?._node?.children_?.root_?.value?.value_

    // if no last updaed time in firebase event or if business has not synced for the first time (does not have a last sync date) stop autosyncing
    // Stop autosyncing when there is no DB last sync date because the system will already be syncing at this point
    // so stop firebase from triggering another sync that will mess up the current syncing
    if (!lastUpdatedTime || !businessSyncStatus.last_sync_date) return
    
    // if date time in firebase is the same with what is in my local db, do not refetch products again
    if (formatDatetimeForBackend(lastUpdatedTime) === formatDatetimeForBackend(businessSyncStatus.last_sync_date)) return
    
    setFirebaseLastUpdatedTime(lastUpdatedTime)
    
    desktopOnlineReducer({
      re_sync: true,
      type: desktopOnlineStatusReducerActions.RE_SYNC_ALL_DATA
    })
    
    const {ipcRenderer} = window.require("electron")
    ipcRenderer.send(IPC_EVENTS.MANUAL_UPDATE_OF_ALL_OFFLINE_DATA)
  })
}


export default autoSyncOfflineOnFirebaseEventDispatch
import { useState } from "react"

const usePaymentModal = (sellSearchRef, sellCategoryRef, categoryBased) => {
	const [showModal, setShowModal] = useState(false)
	const handleModal = () => {
		if(!showModal && !categoryBased) {
			handlePaymentModal(sellSearchRef, sellCategoryRef)
		}
		setShowModal(!showModal)
	}

	return [showModal, handleModal]
}

export default usePaymentModal


const handlePaymentModal = (sellSearchRef, sellCategoryRef) => {
	if(!!sellSearchRef) {
		sellSearchRef.current.style.zIndex = 0
	}

	if(!!sellCategoryRef) {
		sellCategoryRef.current.style.zIndex = 0
	}
}
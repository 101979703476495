import {getBusinessIdFromStorage} from "../../../utils";
const businessId = getBusinessIdFromStorage()

const key = "analytics"
const withBusinessId = [key, businessId]


const analyticsQueryKeys = {
  allAnalytics: [...withBusinessId],
  subscription: () => [...withBusinessId, "subscription"],
  
}

export default analyticsQueryKeys
import {
  CreateSubCategoryModal, EditSubCategoryModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import { AddCircle } from "iconsax-react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../SetUpStyles";
import {subCategoryFormDefaultValues} from "../../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {
  useCreateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useEditSubCategoryMutation,
  useSubCategoriesByCategoryIdQuery,
} from "../../../../hooks/useServices/useSubCategoryServices";
import {SubcategoryImage} from "../../../../assets/images";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import {useParams} from "react-router-dom";
import {actionsPermissions} from "../../../../data/permissions";
import modalNames from "../../../../hooks/useModalConnector/modalNames";


const ViewCategory = () => {
  const { categoryName, categoryId } = useParams()
  const { toast, navigate } = useGlobalContext()
  const [showEditSubCategoryModal, setShowEditSubCategoryModal] = useModal()
  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] = useModal(modalNames.CREATE_SUBCATEGORY_MODAL)

  const { subCategoriesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()

  const { mainQuery, ...allSubcategories } = useSearchQuery(debounceState, useSubCategoriesByCategoryIdQuery.bind(this, categoryId))

  const [editSubCategoryFormValues, setEditSubCategoryFormValues] = useState(subCategoryFormDefaultValues)
  const deleteSubCategoryMutation = useDeleteSubCategoryMutation({ successFn: () => {} })
  const editSubCategoryMutation = useEditSubCategoryMutation({ successFn: setShowEditSubCategoryModal })
  const createSubCategoryMutation = useCreateSubCategoryMutation({ successFn: setShowCreateSubCategoryModal })
  
  const handleViewNestedSubCategory = (subCategory) => {
    navigate(`/dashboard/set-up/categories/nested-subcategory-category/${categoryId}/1/${subCategory.id}/${subCategory.name}`)
  }
  
  const handleCreateSubCategory = (values) => {
    createSubCategoryMutation.mutate(values)
  }

  const handleEditSubCategory = (values) => {
    editSubCategoryMutation.mutate(values)
  }

  const handleEditAction = (subCategory) => {
    setEditSubCategoryFormValues(subCategory)
    setShowEditSubCategoryModal()
  }

  const handleDeleteAction = (subCategory) => {
    toast.confirm(
      "Are you sure?",
      deleteSubCategoryMutation.mutate.bind(this, subCategory.id),
      "delete"
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateSubCategoryModal && <CreateSubCategoryModal onClose={setShowCreateSubCategoryModal} handleSubmit={handleCreateSubCategory} mutation={createSubCategoryMutation} />}
      {showEditSubCategoryModal && <EditSubCategoryModal onClose={setShowEditSubCategoryModal} handleSubmit={handleEditSubCategory} mutation={editSubCategoryMutation} formValues={editSubCategoryFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allSubcategories.refetch} />
      )}
      
      
      <Fragment>
        {allSubcategories.isEmpty && (
          <Fragment>
            <PageHeader isBack title={`Sub-categories in ${categoryName}`} />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={SubcategoryImage}
              heading="No sub-category found"
              buttonText="Add new sub-category"
              buttonClick={setShowCreateSubCategoryModal}
              permissionCode={actionsPermissions.createCategory}
            />
          </Fragment>
        )}

        {allSubcategories.showTable && (
          <Fragment>
            <PageHeaderWithButton
              isBack
              noBackText
              extra="Level 2"
              buttonClick={setShowCreateSubCategoryModal}
              title={`Sub-categories in ${categoryName}`}
              buttonText="Create"
              Icon={AddCircle}
              permissionCode={actionsPermissions.createCategory}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                valueKey="id"
                displayKey="name"
                dispatch={debounceReducer}
                searchPlaceholder="Search sub-category by name"
              />

              <Table headers={subCategoriesTable.headers} noCols={subCategoriesTable.headers.length}>
                
                {allSubcategories.isQueryWithData && (
                  allSubcategories.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      onClick={handleViewNestedSubCategory}
                      pageCount={allSubcategories.currentCount}
                      editAction={handleEditAction}
                      keys={subCategoriesTable.values}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.updateCategory}
                      deletePermission={actionsPermissions.deleteCategory}
                    />
                  )
                )}

                {allSubcategories.isQueryWithNoData && (
                  <NoQueryData text={allSubcategories.isSearchEmptyMessage("Sub-Category", "")} />
                )}

                {allSubcategories.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allSubcategories.showPagination &&
                <TablePagination
                  query={allSubcategories}
                  
                />
              }
            </SetUpLayoutStyled>
            {!!allSubcategories.showPagination &&
              <TablePagination
                query={allSubcategories}
                
              />
            }
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ViewCategory
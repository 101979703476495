import {Fragment, memo, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import {Button, CheckBox, Form, Input, Line, RadioButton, Select} from "../../../index";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {CloseCircle, CloseSquare} from "iconsax-react";
import {
  useAdjustProductSellingPriceAcrossAccountMutation,
  useEditProductMutation,
  useGetProductsWithSameSKUService
} from "../../../../hooks/useServices/useProductServices";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {ContainerStyled} from "../../../../styles/DashboardStyles";


const AdjustProductSellingPriceModal = ({productId, onClose}) => {
  const {data: products} = useGetProductsWithSameSKUService(productId)
  const adjustProductSellingPriceAcrossAccountMutation = useAdjustProductSellingPriceAcrossAccountMutation({successFn: onClose})
  const editProductMutation = useEditProductMutation({successFn: onClose})
  
  const handleSubmission = (values) => {
    if (values.adjust_type === "one_store") {
      editProductMutation.mutate({selling_price: values.selling_price, id: productId})
      return
    }
  
    const payload = handleAdjustSellingPriceFormatting(values, products, productId)
    adjustProductSellingPriceAcrossAccountMutation.mutate({productId, payload})
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Update Product Selling Price</Heading>
        
        <Form values={{adjust_type: "one_store"}} onSubmit={handleSubmission}>
          <FlexStyled>
            <RadioButton required name="adjust_type" labelProp="Update for this store alone" value="one_store"
                         id="increase"/>
            <RadioButton name="adjust_type" labelProp="Update across all stores" value="all_store" id="decrease"/>
          </FlexStyled>
          
          <AdjustType products={products}/>
          
          <FlexStyled>
            <Button type="submit" text="Update Price"
                    isLoading={adjustProductSellingPriceAcrossAccountMutation.isLoading || editProductMutation.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default AdjustProductSellingPriceModal


export const AdjustType = ({products}) => {
  const {values, setFieldValue} = useFormikContext()
  
  return (
    <Fragment>
      {values.adjust_type === "one_store" && (
        <Fragment>
          <Input
            className="fullWidth"
            type="number"
            formatNumber
            name="selling_price"
            label="Update Selling Price"
            placeholder="Enter new selling price"
          />
        </Fragment>
      )}
      
      {values.adjust_type === "all_store" && (
        <Fragment>
          {products?.map((product, k) => (
            <Fragment key={product.id}>
              {k !== 0 && <Line/>}
              
              <ContainerStyled>
                <SpaceBetweenStyled>
                  <Paragraph color="black" $strike={values[`removed_${product.id}`]} bold size={1.2}
                             $flex={values[`removed_${product.id}`]}>
                    {values[`removed_${product.id}`] ? (
                      <>
                        <CloseCircle size={17} color="red"/>
                        {k + 1}) {product.business_name}: {product.name}
                      </>
                    ) : (
                      <>{k + 1}) {product.business_name}: {product.name} </>
                    )}
                  </Paragraph>
    
                  {(products.length > 1 && k !== 0) && (
                    <FlexStyled>
                      {!values[`removed_${product.id}`] && (
                        <Paragraph
                          size={1}
                          color="black"
                          className="pointer"
                          onClick={() => setFieldValue(`show_${product.id}`, !values[`show_${product.id}`])}
                        >
                          <u>{!values[`show_${product.id}`] ? 'Show' : 'Hide'}</u>
                        </Paragraph>
                      )}
                      <Paragraph
                        bold
                        size={1}
                        color="error"
                        className="pointer"
                        onClick={() => setFieldValue(`removed_${product.id}`, !values[`removed_${product.id}`])}
                      >
                        <u>{values[`removed_${product.id}`] ? 'Add Back' : 'Remove'}</u>
                      </Paragraph>
                    </FlexStyled>
                  )}
    
                  {/*<Paragraph color="black">PRODUCT SKU: {product.sku}</Paragraph>*/}
                </SpaceBetweenStyled>
  
                <br/>
  
                {((!values[`removed_${product.id}`] && values[`show_${product.id}`]) || (k === 0 && !values[`show_${product.id}`])) && (
                  <AdjustMeasurementSellingPrice
                    key={product.id}
                    productId={product.id}
                    isFirstProduct={k === 0}
                    measurements={product.frontend_measurements}/>
                )}
  
                {(k === 0 && products.length > 1) && (
                  <div>
                    <CheckBox
                      optional
                      name="overwrite"
                      labelProp="Overwrite all similar products with this product unit prices"
                    />
                    <Paragraph size={1.2} noTop bold color="error">
                      Checking this box will use all unit selling price for this product above
                      to update all similar products that have not been removed from the list below. Please go through
                      all the products below and ensure you want to overwrite their selling prices. Remove the
                      products you do not want to overwrite.
                    </Paragraph>
                    <br/>
                  </div>
                )}

              </ContainerStyled>
            </Fragment>
          ))}
        </Fragment>
      )}
      <FlexStyled className="fullWidth"></FlexStyled>
    
    </Fragment>
  )
}


export const AdjustMeasurementSellingPrice = memo(({measurements = [], productId, isFirstProduct}) => {
  const [selectedMeasurements, setSelectedMeasurements] = useState([])
  const {setFieldValue, values: formikValues} = useFormikContext()
  
  const handleSelectMeasurement = (data) => {
    setSelectedMeasurements([...selectedMeasurements, data.id])
  }
  
  const SalesAttachmentForm = () => {
    const id = uuid()
    
    const SelectComp = ({productId, measurements, isOverWrite, isFirstProduct}) => (
      <Select
        valueKey="id"
        displayKey="name_display"
        label="Product Unit Measurement"
        disabled={isOverWrite && !isFirstProduct}
        placeholder="Select product unit measurement"
        name={`product_measurement_name_${id}_${productId}`}
        displayName={`product_measurement_display_name_${id}_${productId}`}
        options={measurements.filter(price => !selectedMeasurements.includes(price.id))}
        updateFn={handleSelectMeasurement}
      />
    )
    
    const InputComp = ({productId, isOverWrite, isFirstProduct}) => (
      <Input
        type="number"
        formatNumber
        label="Measurement Selling Price"
        placeholder="Enter new selling price"
        disabled={isOverWrite && !isFirstProduct}
        name={`product_measurement_selling_price_${id}_${productId}`}
      />
    )
    
    return {id, SelectComp, InputComp}
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  useEffect(() => {
    const selectedMeasurementIds = []
    const products = measurements.map(measurement => {
      const measure_comp = SalesAttachmentForm()
      selectedMeasurementIds.push(measurement.id)
      setFieldValue(`product_measurement_name_${measure_comp.id}_${productId}`, measurement.id)
      setFieldValue(`product_measurement_display_name_${measure_comp.id}_${productId}`, measurement.name_display)
      setFieldValue(`product_measurement_selling_price_${measure_comp.id}_${productId}`, measurement.selling_price)
      return measure_comp
    })
    
    setMoreProducts(products)
    setSelectedMeasurements(selectedMeasurementIds)
  }, [measurements, productId])
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    const measurementId = formikValues[`product_measurement_name_${id}_${productId}`]
    setSelectedMeasurements(selectedMeasurements.filter(measurement => measurement !== measurementId))
    
    setFieldValue(`product_measurement_name_${id}_${productId}`, "")
    setFieldValue(`product_measurement_display_name_${id}_${productId}`, "")
    setFieldValue(`product_measurement_selling_price_${id}_${productId}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        {moreProducts?.map(({id, SelectComp, InputComp}) => (
          <FlexStyled className="fullWidth" $noOfRows={2} key={id}>
            <SelectComp
              productId={productId}
              measurements={measurements}
              isFirstProduct={isFirstProduct}
              isOverWrite={formikValues["overwrite"]}
            />
    
            <InputComp
              productId={productId}
              isFirstProduct={isFirstProduct}
              isOverWrite={formikValues["overwrite"]}
            />
          </FlexStyled>
        ))}
        
        {(measurements.length !== selectedMeasurements.length && measurements.length > 1) && (moreProducts.length <= selectedMeasurements.length) && (
          <Button onClick={addExtraProduct} type="button" text="Add More Measurement" className="small" small/>
        )}
        <br/>
      </Fragment>
    
    </div>
  )
})


const handleAdjustSellingPriceFormatting = (values = {}, products = [], mainProductId) => {
  const finalProductPayload = []
  
  if (values.overwrite === true) {
    return overwriteMainProductSellingPricesWithOtherSimilarProducts(values, products, mainProductId)
  }
  
  for (const product of products) {
    if (values[`removed_${product.id}`]) continue // If product is marked as remove, skip
    
    const updatedProduct = getProductUpdatedPrices(product, values)
    finalProductPayload.push(updatedProduct)
  }
  
  return finalProductPayload
}


const overwriteMainProductSellingPricesWithOtherSimilarProducts = (values = {}, products = [], mainProductId) => {
  const modifyingProducts = products.filter(product => (!values[`removed_${product.id}`] && product.id !== mainProductId))
  
  const mainProduct = products.find(product => product.id === mainProductId)
  
  const mainProductUpdatedPrices = getProductUpdatedPrices(mainProduct, values)
  const updatedModifyingProducts = modifyingProducts.map(product => {
    return {
      ...mainProductUpdatedPrices,
      
      product_id: product.id,
      product_name: product.name,
      business_id: product.business,
    }
  })
  
  return [mainProductUpdatedPrices, ...updatedModifyingProducts]
}


const getProductUpdatedPrices = (product = {}, values) => {
  const updateProduct = {
    product_id: product.id,
    product_name: product.name,
    business_id: product.business,
    selling_price: product.selling_price,
    unit_measurement: product.unit_measurement,
    meta_measurement: product.meta_measurement
  }
  
  for (const [key, value] of Object.entries(values)) {
    if (key.includes("product_measurement_name_") && key.includes(product.id)) {
      const [measurement_unique_id, measurement_product_id] = key.split("_").slice(-2)
      
      const measurement = product.frontend_measurements.find(measurement => measurement.id === value)
      if (!measurement) continue
      
      if (measurement.type === "main") {
        updateProduct.selling_price = values[`product_measurement_selling_price_${measurement_unique_id}_${measurement_product_id}`]
      } else {
        const {name, base_quantity, unit_price_difference} = measurement
        
        updateProduct.meta_measurement = updateProduct.meta_measurement.map(_measurement => {
          if (
            _measurement.name !== name ||
            _measurement.base_quantity !== base_quantity ||
            _measurement.unit_price_difference !== unit_price_difference
          ) return _measurement
          
          return {
            ..._measurement,
            selling_price: values[`product_measurement_selling_price_${measurement_unique_id}_${measurement_product_id}`]
          }
        })
      }
    }
  }
  
  return updateProduct
}
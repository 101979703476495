const storeFormDefaultValues = {
  store_name: "",
  phone: "",
  country: "",
  state: "",
  local: "",
  address: "",
  type: "",
  currency: "",
  currency_name: "",
  description: "",
  country_name: "",
  state_name: "",
  local_name: "",
  receipt_message: "",
  countryLocale: ""
}

const categoryFormDefaultValues = {
  name: "",
  image: "",
  description: "",
}

const subCategoryFormDefaultValues = {
  name: "",
  category: "",
  description: "",
  category_name: ""
}

const taxFormDefaultValues = {
  title: "",
  value: "",
  active: false
}

const deliveryRateFormDefaultValues = {
  name: "",
  amount: "",
  amount_format: ""
}


const addOnFormDefaultValues = {
  name: "",
  cost: "",
  stage: ""
}

const createCustomerSubscriptionFormDefaultValues = {
  name: "",
  subscription_service: "",
}


const stagesFormDefaultValues = {
  stage_name: "",
  service: "",
  commission_type: "",
  commission_value: "",
  commission_type_name: ""
}

const discountFormDefaultValues = {
  title: "",
  value: "",
  active: ""
}

const expenseCategoryFormDefaultValues = {
  name: ""
}

const businessSubscriptionFormDefaultValues = {
  name: "",
  value: "",
  duration: "",
  limit: "",
  exceptions: []
}

const bankFormDefaultValues = {
  name: "",
  account_no: "",
  account_name: ""
}

const roleFormDefaultValues = {
  name: "",
  permission: [],
}

const expenseFormDefaultValues = {
  remark: "",
  amount: "",
  category: "",
  created_date: "",
  category_name: ""
}

const inventoryFormDefaultValues = {
  is_in: "",
  remark: "",
  quantity: "",
  product: "",
}

const supplierFormDefaultValues = {
  name: "",
  phone: "",
  email: "",
  address: "",
  category: "",
  country: "",
  contact_phone: "",
  contact_name: ""
}

const customerFormDefaultValues = {
  phone: "",
  email: "",
  last_name: "",
  first_name: "",
}

const staffFormDefaultValues = {
  phone: "",
  email: "",
  role: "",
  password: "",
  last_name: "",
  first_name: "",
  address: "",
  business: "",
  offline_pin: ""
}

const addToCartFormDefaultValues = {
  unit: 1,
  addOns: "",
  description: "",
  display_addon: "",
  
  custom: "",
  isCustom: "",
  custom_format: ""
}

const productFormDefaultValues = {
  name: "",
  sku: "",
  image: "",
  category: "",
  use_stock: true,
  sellable: true,
  sub_category: "",
  unit_increment: "",
  unit_measurement: "",
  cost_price: "",
  prepare_time: "",
  selling_price: "",
  commission_type: "",
  commission_value: "",
  stock_low_level: "",
  commission_type_name: "",

  show_commission: false,
  show_product_attachment: false,
  display_category: "",
  display_sub_category: "",
  unit_increment_format: ""
}

const purchaseOrderFormDefaultValues = {
  supplier: "",
  supplier_name: "",
  display_supplier: "",
  shipping_cost: "",
  tax: "",
  remark: "",
}

const packageDeliveryFormDefaultValues = {
  remark: "",
  image: "",
  order_list: []
}

const checkoutFormDefaultValues = {
  customer: "",
  method: "",
  delivery: "",
  date: "",
  pay_mode: "",
  order_due_date: "",
  payment_date: "",
  discount_type: "",
  discount_value: "",
  banks: "",
  split_cash_value: "",
  split_pos_value: "",
  split_transfer_value: "",

  display_bank: "",
  customer_name: "",
  discount_type_name: "",
  discount_value_name: "",
  delivery_rate_name: "",
  splitOrPartialPaymentAmount: ""
}

const tableFormDefaultValues = {
  name: "",
  capacity: "",
}

const businessSettingsFormDefaultValues = {
  payment_due: false,
  due_date: false,
  credit_sales: false,
  partial_payment: false,
  auto_payment_confirm: false
}

export const customPaymentMethodFormDefaultValues = {
  payment_method: ""
}

export const debitWalletFormDefaultValues = {
  amount: "",
  type: ""
}

export {
  storeFormDefaultValues, categoryFormDefaultValues, subCategoryFormDefaultValues,
  taxFormDefaultValues, addOnFormDefaultValues, discountFormDefaultValues,
  bankFormDefaultValues, roleFormDefaultValues, expenseFormDefaultValues,
  inventoryFormDefaultValues, supplierFormDefaultValues, customerFormDefaultValues,
  staffFormDefaultValues, addToCartFormDefaultValues, productFormDefaultValues,
  packageDeliveryFormDefaultValues, checkoutFormDefaultValues, tableFormDefaultValues,
  stagesFormDefaultValues, businessSettingsFormDefaultValues, deliveryRateFormDefaultValues,
  businessSubscriptionFormDefaultValues, createCustomerSubscriptionFormDefaultValues,
  purchaseOrderFormDefaultValues, expenseCategoryFormDefaultValues
}
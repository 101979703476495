import {Line} from "../index";
import {useTheme} from "styled-components";
import {Eye} from "iconsax-react";
import {displayTableItemWithStatus} from "../../utils/table";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {TableItemActionStyled, TableItemForOrderStyled} from "../../styles/TableStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {Fragment} from "react";
import {Heading} from "../../styles/textStyles";
import {NO_OF_RESULTS_FROM_BACKEND} from "../../utils/constants";
import {Link} from "react-router-dom";
import {MdOutlineCloudSync} from "react-icons/md";


const TableItemForGroupOrders = ({ data, keys, isReport, statusPosition = 5, pageCount, count, noAction, bgColor, isInvoice }) => {
  const theme = useTheme()
  const { navigate } = useGlobalContext()
  const tableData = useTableValueTransformer(data, keys)
  
  const handleViewSingleOrder = (groupOrderId) => {
    if(noAction) return
    
    if(groupOrderId.split("/").length > 1) {
      const orderArr = groupOrderId.split("/")
      const orderId = groupOrderId.split("/").join("")
      let url = `/dashboard/orders/${orderId}?trimmed=true&count=${orderArr[0].length}`
      if(data.is_offline) {
        url = `${url}&is_offline=true`
      }
      
      if(isInvoice) {
        url = `${url}&invoice=true`
      }
      
      navigate(url)
      return
    }
    
    let url = `/dashboard/orders/${groupOrderId}`
    if(data.is_offline) {
      url = `${url}?is_offline=true`
    }
    
    if(isInvoice && !data.is_offline) {
      url = `${url}?invoice=true`
    }
    
    navigate(url)
  }
  
  const viewOrderLink = (groupOrderId) => {
    if (groupOrderId.split("/").length > 1) {
      const orderArr = groupOrderId.split("/")
      const orderId = groupOrderId.split("/").join("")
      let url = `/dashboard/orders/${orderId}?trimmed=true&count=${orderArr[0].length}`
      if (data.is_offline) {
        url = `${url}&is_offline=true`
      }
  
      if (isInvoice) {
        url = `${url}&invoice=true`
      }
  
      return url
    }
  
    let url = `/dashboard/orders/${groupOrderId}`
    if (data.is_offline) {
      url = `${url}?is_offline=true`
    }
  
    if (isInvoice && !data.is_offline) {
      url = `${url}?invoice=true`
    }
  
    return url
  }
  
  return (
    <Link to={!!noAction ? "#" : viewOrderLink(data?.group_id || data?.group_order?.g_order_number || "")}>
      <TableItemForOrderStyled $bgColor={bgColor}>
        <td className="counter">
          <Heading color="black" thin size={1}>{(count + 1) + ((pageCount - 1) * NO_OF_RESULTS_FROM_BACKEND)}</Heading>
          {(!!data && !!data?.is_offline) &&
            <MdOutlineCloudSync className="group_order_offline_svg" size={18} color="orange"/>}
        </td>
      
        <Fragment>
          {tableData.map((item, k) => displayTableItemWithStatus(item, k, statusPosition, data))}
        </Fragment>
      
        {!isReport && (
          <TableItemActionStyled>
            <Eye color={theme.gray} onClick={() => handleViewSingleOrder(data.group_id)}/>
          </TableItemActionStyled>
        )}
        <Line />
      </TableItemForOrderStyled>
    </Link>
  )
}

export default TableItemForGroupOrders
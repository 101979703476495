export const discountSelectTypes = [
  {name: "General", value: "general"},
  {name: "Product", value: "product"},
  {name: "Sub Category", value: "sub_category"},
  {name: "Category", value: "category"},
  {name: "Customer", value: "customer"}
]

export const discountAndTaxValueType = [
  {name: "Percentage", value: "percentage"},
  {name: "Amount", value: "amount"}
]

export const taxCalculationTypes = [
  {name: "Addition", value: "addition"},
  {name: "Subtraction", value: "subtraction"}
]


export const taxSelectTypes = [
  {name: "General", value: "general"},
  {name: "Product", value: "product"},
  {name: "Sub Category", value: "sub_category"},
  {name: "Category", value: "category"},
]


export const discountAndTaxTypesEnum = {
  general: "general",
  product: "product",
  sub_category: "sub_category",
  category: "category",
  customer: "customer"
}


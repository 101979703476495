import styled from "styled-components"
import {Heading, Heading2, Paragraph} from "../../styles/textStyles";
import {FlexStyled, IconStyled} from "../../styles/utilStyles";

const BackgroundPattern = styled("section")`
  width: 50% !important;
  position: relative;
  background-color: ${props => props.theme.black};

  > div {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 4rem;
    position: absolute;
    background: url(${props => props.theme.pattern}) right;
  }
`

const StepLayout = styled("section")`
  display: flex;
  gap: 3rem;
  align-items: center;
  flex-direction: column;
  
  .step_div {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 80vh;
    
    > div {
      margin: auto 0;
    }
  }
  
  .verify_later {
    display: flex;
    justify-content: center;
    
    ${Paragraph} {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .step_div {
      width: 100%;
      height: 55vh;
    }
  }
  
  nav img {
    max-width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      max-width: 100%;
    }
  }
  
  nav ${Paragraph} {
    gap: .3rem;
    display: flex;
    cursor: pointer;
    margin-bottom: 1.5rem;
    align-items: center;
    color: ${props => props.theme.darkGray};
  }

  p::selection, p::-moz-selection {
    color: transparent !important;
  }
  
  p {
    user-select: none !important;
  }
`

const RegisterLayout = styled("div")`
  display: flex;
  justify-content: space-between;
  
  .quote_section {
    ${Heading} {
      line-height: 2rem;
      width: 75%;
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    flex-direction: column;
    .logo_div {
      display: flex;
      align-items: center;
      padding: 1.7rem 2rem;
      max-width: calc(100% - 4rem);
      background-color: black;
      img {
        max-width: 50%;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.tabletBreakPoint}) {
    .logo_div img {
        display: none;
    }
  }
  
  section {
    width: 50%;
    padding: 2rem 0;
    min-height: calc(100vh - 4rem);

    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: calc(100% - 4rem);
      min-height: 80vh;
      padding: 2rem;
    }
  }
  
  .email_and_password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      img {
        display: none;
      }
    }

    .email_and_password_div {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-direction: column;
      
      ${FlexStyled} {
        margin-bottom: 1.5rem;
        justify-content: space-between;
      }
      
      img {
        max-width: 45%;
      }
    }

    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      gap: 3rem;
      
      img {
        background-color: black;
      }

      .email_and_password_div {
        margin-top: 10%;
        width: 100%;
        height: 60vh;
      }
    }
  }
  
  ${BackgroundPattern} {
    ${IconStyled} {
      padding: 1.6rem 1.25rem;
      border-radius: .5rem;
      margin-bottom: 2rem;
    }
    
    ${Heading2} {
      width: 68% !important;
      margin: 2rem 0;
    }

    
    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      display: none;
    }
  }
  
`

export {
  RegisterLayout,
  StepLayout,
  BackgroundPattern
}
import styled from "styled-components";
import {FlexStyled} from "./utilStyles";
import {ListDropDownStyled, MenuListStyled} from "./listStyles";
import {Heading, Heading2} from "./textStyles";

const DashboardMobileNavStyled = styled("nav")`
  position: fixed;
  right: .1rem;
  left: 0;
  top: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .3rem 1rem;
  box-shadow: 0 1px 2px ${props => props.theme.gray};
  background-color: ${props => props.theme.background};
  
  > ${FlexStyled}:first-of-type {
    gap: .4rem;
    ${Heading2} {
      font-size: 1.3rem;
      margin-bottom: 0;
    }
  }

  ${FlexStyled}:last-of-type {
    svg {
      padding: .4rem;
      border-radius: 50%;
    }
  }
  
  ${FlexStyled}.business_name {
    width: 65% !important;
    
    ${Heading2} {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  ${FlexStyled} {
    > ${MenuListStyled} {
      gap: 0;
      padding: 0;
      
      a {
        text-decoration: none;
      }
      
      :hover {
        border-left: none;
        background: transparent;
      }
      
      div.container {
        position: relative;
        padding-top: 4.5rem;
        
        ${ListDropDownStyled} {
          top: initial;
          left: -62vw;
          width: 62vw;
          
          svg {
            background: transparent;
          }
          
          img {
            padding: .4rem;
          }
        }
      }
      
      ${ListDropDownStyled} {
        top: 7vh;
        left: -12rem;
        width: fit-content;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.desktopBreakPoint}) {
    display: none !important; 
  }
`

const DashboardMobileNavDrawerStyled = styled("nav")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  min-height: 100vh;
  background-color: ${props => props.theme.modalBackground};
  box-shadow: 1px 1px 3px ${props => props.theme.shadow};
  
  * {
    user-select: none;
  }
  
  > div {
    width: 70%;
    min-height: 100vh;
    padding: 1.5rem 0;
    overflow-y: scroll;
    background-color: white;
    
    @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: 45%;
    }

    > svg {
      cursor: pointer;
      position: absolute;
      right: 8rem;
      top: .6rem;
      color: ${props => props.theme.gray};
    }

    .logo_div {
      padding: 0 2rem;
    }
    
    .nav_menu_settings {
      margin-top: 2rem;
    }
    
    ${MenuListStyled} {
      padding: .8rem 2rem;
    }
  }
  
  a {
    text-decoration: none;
  }
  
  div.items {
    height: 85vh;
    overflow-y: scroll;
  }

  .package_renewal_warning {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;
    width: 100%;

    ${Heading} {
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.error};
    }
  }
`


export {
  DashboardMobileNavStyled,
  DashboardMobileNavDrawerStyled
}
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getSubCategoriesService = async (query = {}) => {
  try {
    const url = handleQueryParams('/sub_category', query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e
  }
}

const getSubCategoriesByCategoryIdService = async (categoryId, searchValue = " ") => {
  if(!categoryId) {
    return { results: [] }
  }

  try {
    const { data } = await axios.get(`/sub_category/?category_id=${categoryId}&search=${searchValue}`)
    return data
  } catch (e) {
    throw e
  }
}

const createSubCategoryService = async (body) => {
  try {
    const { data } = await axios.post("/sub_category/", body)
    return data
  } catch (e) {
    throw e
  }
}

const editSubCategoryService = async (subCategoryId, body) => {
  try {
    const { data } = await axios.patch(`/sub_category/${subCategoryId}/`, body)
    return data
  } catch (e) {
    throw e
  }
}

const deleteSubCategoryService = async (subCategoryId) => {
  try {
    const { data } = await axios.delete(`/sub_category/${subCategoryId}/`)
    return data
  } catch (e) {
    throw e
  }
}

export {
  getSubCategoriesService, getSubCategoriesByCategoryIdService, createSubCategoryService,
  editSubCategoryService, deleteSubCategoryService
}
import {Button, Form, Input} from "../../../components";
import { StepLayout } from "../AuthStyles";
import { ArrowLeft2 } from "iconsax-react"
import { useOutletContext } from "react-router-dom";
import {Step1} from "../../../assets/images";
import {Paragraph} from "../../../styles/textStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";

const PersonInfo = () => {
  const { globalState: { packageId, packagePeriod, referralCode } } = useGlobalContext()
  const { navigate, setRegisterData, formValues } = useOutletContext()
  

  const handleSubmit = (values) => {
    setRegisterData(prevState => ({
      ...prevState,
      ...values
    }))
    
    let url = `/register/${packageId}/${packagePeriod}/business_info`
    if(referralCode) {
      url = `${url}?referral_code=${referralCode}`
    }

    navigate(url)
  }

  return (
    <StepLayout className="form_section">
      <div className="step_div">
        <nav>
          <Paragraph onClick={() => navigate(-1)} bold size={.96}> <ArrowLeft2 size={18} /> Back</Paragraph>
          <img src={Step1} alt="step 1" />
        </nav>
        <div>
          <Form values={formValues} onSubmit={handleSubmit}>
            <Input
              required
              name="first_name"
              label="First Name"
              placeholder="Your first name"
              type="text"
            />
            <Input
              required
              name="last_name"
              label="Last Name"
              placeholder="Your last name"
              type="text"
            />
            <Button type="submit" text="Next" shadow />
          </Form>
        </div>
      </div>
    </StepLayout>
  )
}

export default PersonInfo
import {Button, Form, Input, PhoneInput, Select} from "../../../components";
import { StepLayout } from "../AuthStyles";
import { ArrowLeft2 } from "iconsax-react"
import { useOutletContext } from "react-router-dom";
import {Step2} from "../../../assets/images";
import {Paragraph} from "../../../styles/textStyles";
import useFormWithCountryAndState from "../../../hooks/useFormWithCountryAndState";
import {useCountries} from "../../../hooks/useServices/formFieldHooks";
import {validatePhoneNumberWithLocale} from "../../../utils/validator";
import useToast from "../../../hooks/useUtils/useToast";
import env from "../../../utils/env";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";

const BusinessInfo = () => {
  const toast = useToast()
  const { data: countries } = useCountries()
  const { globalState: { referralCode } } = useGlobalContext()
  
  const { navigate, formValues, registerMutation } = useOutletContext()
  const [ additionalDetails, setAdditionalDetails, updateCountry ] = useFormWithCountryAndState()

  const handleSubmit = (values) => {
    const country = countries.find(country => country.id === values.country)

    if(!!country.locale && !validatePhoneNumberWithLocale(values.phone, country.locale)) {
      toast.error(`Phone number not valid for country`)
      return
    }

    if(window?.grecaptcha) {
      window?.grecaptcha?.ready(function() {
        window?.grecaptcha?.execute(env.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
          registerMutation.mutate({ ...formValues, ...values, recaptcha_token: token, ...(!!referralCode && { referral_code: referralCode  }) })
        });
      });
    }
  }

  return (
    <StepLayout className="form_section">
      <div className="step_div">
        <nav>
          <Paragraph onClick={() => navigate(-1)} bold size={.96}> <ArrowLeft2 size={18} /> Back</Paragraph>
          <img src={Step2} alt="step 2" />
        </nav>
        <div>
          <Form
            values={formValues}
            onSubmit={handleSubmit}
            additionalData={additionalDetails}
            setAdditionalData={setAdditionalDetails}
          >
            <Input
              required
              name="business_name"
              label="Company / Organization"
              placeholder="Your company/organization"
              type="text"
            />
            <Select
              required
              options={countries}
              displayKey="name"
              valueKey="id"
              name="country"
              displayName="countryName"
              label="Country"
              updateFn={updateCountry}
              placeholder="Select Country"
            />

            <PhoneInput
              required
              name="phone"
              label="Phone"
              placeholder="Phone Number"
            />
            <Button type="submit" isLoading={registerMutation.isLoading} text="Register" shadow />
          </Form>
        </div>
      </div>
    </StepLayout>
  )
}

export default BusinessInfo
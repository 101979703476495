import {Fragment, useState} from "react";
import {
  PageHeader,
  ResetStaffPasswordModal,
  SearchAndFilter,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {useAllStaffsQuery, useResetStaffPasswordMutation} from "../../../hooks/useServices/useStaffServices";
import {getAllStaffRestructure} from "../../../services/staffServices/staffRestructure";
import {useModal} from "../../../hooks";


const Staff = () => {
  const { staffListForBackendTable } = tableHeadersAndValueKeys
  
  const [staffDetails, setStaffDetails] = useState({})
  const [isStaffOpen, setIsStaffOpen] = useModal()
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allStaffs } = useSearchQuery(debounceState, useAllStaffsQuery)

  const resetStaffPasswordMutation = useResetStaffPasswordMutation({ successFn: setIsStaffOpen })
  
  const handleViewStaff = (staff) => {
    setStaffDetails(staff)
    setIsStaffOpen()
  }
  
  const handleResetPassword = (values) => {
    resetStaffPasswordMutation.mutate(values)
  }
  
  return (
    <Fragment>
      {isStaffOpen && <ResetStaffPasswordModal mutation={resetStaffPasswordMutation} handleSubmit={handleResetPassword} formValues={staffDetails} onClose={setIsStaffOpen} /> }
      
      <PageHeader title="All Staffs" />
      
      {mainQuery.isSuccess && (
        <Fragment>
          {!allStaffs.isEmpty && (
            <Fragment>
              <SetUpLayoutStyled>
                <SearchAndFilter
                  noFilter
                  dispatch={debounceReducer}
                  searchPlaceholder="Search staff by name, phone, email"
                />
                
                <Table headers={staffListForBackendTable.headers} noCols={staffListForBackendTable.headers.length}>
                  {allStaffs.isQueryWithData && (
                    allStaffs?.data?.results.map((log, k) => (
                      <TableItemWithStatus
                        count={k}
                        onView={handleViewStaff}
                        pageCount={allStaffs.currentCount}
                        key={k}
                        data={log}
                        statusPosition={40}
                        keys={staffListForBackendTable.values}
                      />
                    ))
                  )}
      
                  {allStaffs.isQueryWithNoData && (
                    <NoQueryData text="No staffs" />
                  )}
      
                  {allStaffs.isQueryLoading && (
                    <TableSkeleton />
                  )}
  
                  {!!allStaffs?.data?.next && (
                    <TablePagination queryKey={["STAFFS", debounceState.searchValue]} query={allStaffs} restructureFn={getAllStaffRestructure} />
                  )}
                </Table>
              </SetUpLayoutStyled>
  
              {!!allStaffs?.data?.next && (
                <TablePagination queryKey={["STAFFS", debounceState.searchValue]} query={allStaffs} restructureFn={getAllStaffRestructure} />
              )}
            </Fragment>
          )}
          
        </Fragment>
      )}
    </Fragment>
  )
}


export default Staff
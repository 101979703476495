import {useQuery} from "react-query";
import queryKeys from "../queryKeys";
import {getPermissionCategoryService, getPermissionService} from "../../../services/permissionServices";

const usePermissionQuery = () => {
  return useQuery(queryKeys.PERMISSIONS, getPermissionService)
}

const usePermissionCategoryQuery = () => {
  return useQuery({
    queryKey: [queryKeys.PERMISSIONS, "Category"],
    queryFn: getPermissionCategoryService
  })
}


export { usePermissionQuery, usePermissionCategoryQuery }
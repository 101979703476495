import React, {useState, useCallback, useRef} from 'react'
import Cropper from 'react-easy-crop'

import { getCroppedImg } from '../../../utils/cropImageUtils/canvasUtils'
import {CropImageStyled, FlexStyled} from "../../../styles/utilStyles";
import {Button} from "../../index";
import ImageInput from "../../Forms/ImageInput";
import {useFormikContext} from "formik";
import {useModal} from "../../../hooks";
import {MAX_IMAGE_SIZE} from "../../../utils/constants";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import getCompressionRatioForImage from "../../../utils/getCompressionRatioForImage";
import Compressor from "compressorjs";


const getFileNameForDisplay = (fileName = "") => {
	const [name, type] = fileName.split(".")
	if(name.length > 13) {
		return `${name.slice(0, 12)}.${type}`
	}
	
	return fileName
}


const CropImage = ({ name, className, placeholder, optional, width, height, cropShape="rect" }) => {
	const { toast } = useGlobalContext()
	const cropEl = useRef(null)
	const { setFieldValue } = useFormikContext()
	const [imageName, setImageName] = useState("")
	const [{ fileName, fileBase64 }, setFileName] = useState({
		fileName: "",
		fileBase64: ""
	})
	
	const [showCropped, setShowCropped] = useModal()
	const [imageSrc, setImageSrc] = React.useState(null)
	const [crop, setCrop] = useState({ x: 300, y: 200 })
	const [rotation, setRotation] = useState(0)
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [, setCroppedImage] = useState(null)
	
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])
	
	
	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				imageSrc,
				croppedAreaPixels,
				rotation
			)
			setCroppedImage(croppedImage)
			
			const newFile = new File([
				new Blob([croppedImage])
			], "output_file_name");

			setFieldValue(name, croppedImage.split(",")[1])
			setFileName({
				fileName: getFileNameForDisplay(imageName),
				fileBase64: croppedImage
			})
			
			setShowCropped()
		} catch (e) {
			console.error(e)
		}
	}, [imageSrc, croppedAreaPixels, rotation])
	
	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			
			if((file.size / 1000000) > MAX_IMAGE_SIZE) {
				toast.error(`File too big, upload a maximum of ${MAX_IMAGE_SIZE}mb`)
				return
			}
			
			const compressionRatio = getCompressionRatioForImage(file.size)
			// setImageName(file.name)
			//
			// let imageDataUrl = await readFile(file)
			// setImageSrc(imageDataUrl)
			// setShowCropped()
			
			new Compressor(file, {
				quality: compressionRatio,
				success: async (compressedFile) => {
					let imageDataUrl = await readFile(compressedFile)
					setImageSrc(imageDataUrl)
					setImageName(file.name)
					setShowCropped()
				},

				error() {
					toast.error("Error: please try selecting image again")
				}
			});
		}
	}
	
	const handleCancel = () => {
		const fileInput = cropEl.current
		fileInput.querySelector("input").value = ""
		
		setShowCropped()
	}
	
	return (
		<CropImageStyled ref={cropEl}>
			{showCropped && (
				<React.Fragment>
					<div className="cropper">
						<Cropper
							image={imageSrc}
							crop={crop}
							rotation={rotation}
							zoom={zoom}
							aspect={4/3}
							cropShape={cropShape}
							showGrid={false}
							cropSize={{ width, height}}
							onCropChange={setCrop}
							onRotationChange={setRotation}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
						/>
						<FlexStyled className="actions">
							<Button type="button" text="Crop Image" onClick={showCroppedImage} />
							<Button type="button" text="Cancel" onClick={handleCancel} />
						</FlexStyled>
					</div>
				</React.Fragment>
			)}
			
			<ImageInput
				shape={cropShape}
				name={name}
				className={className}
				placeholder={placeholder}
				accept="image/*"
				optional={optional}
				handleFileChange={onFileChange}
				fileName={fileName}
				fileBase64={fileBase64}
			/>
		</CropImageStyled>
	)
}


function readFile(file) {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.addEventListener('load', () => resolve(reader.result), false)
		reader.readAsDataURL(file)
	})
}


export default CropImage
export const getAccountsServiceStructure = (data) => {
	const results = data?.results?.map(account => ({
		...account,
		owner_name: account.user_details.name,
		owner_phone: account.user_details.phone,
		owner_email: account.user_details.email,
		package_name: account?.package?.name || ""
	}))
	
	return { ...data, results }
}
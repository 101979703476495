import {FlexStyled, PageHeaderWithButtonStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import {ArrowLeft} from "iconsax-react";
import {useTheme} from "styled-components";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";

const PageHeader = ({ title, isBack, extra }) => {
  const theme = useTheme()
  const { navigate } = useGlobalContext()

  return (
    <PageHeaderWithButtonStyled>
      {!isBack && <Heading color="black" size={1.7}>{title}</Heading>}

      {isBack && (
        <FlexStyled className="navigation_section">
          <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
          <FlexStyled>
            <Paragraph noTop color="menuGray">Back</Paragraph>
            <Heading color="black">{title}</Heading>
            {!!extra && <Heading bold color="black">{extra}</Heading>}
          </FlexStyled>
        </FlexStyled>
      )}
    </PageHeaderWithButtonStyled>
  )
}

export default PageHeader
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, TextArea} from "../../../index";
import {subCategoryFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useParams} from "react-router-dom";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import modalNames from "../../../../hooks/useModalConnector/modalNames";


const CreateNestedSubCategoryModal = ({ onClose, handleSubmit, mutation }) => {
  const { parentCategoryName } = useParams()
  const { globalState, navigate } = useGlobalContext()

  const handleSkipCreateSubcategoryToConnector = () => {
    navigate(globalState.modalConnector.fromModalURL)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Nested Sub Category</Heading>

        <Form values={subCategoryFormDefaultValues} onSubmit={handleSubmit}>
          {(globalState.modalConnector.toModalName === modalNames.CREATE_SUBCATEGORY_MODAL) && (
            <SpaceBetweenStyled style={{ marginBottom: "1rem" }}>
              <Paragraph bold color="black">Don't need sub-category?</Paragraph>
              <Paragraph onClick={handleSkipCreateSubcategoryToConnector} className="underline" color="black">Create product</Paragraph>
            </SpaceBetweenStyled>
          )}
          
          <Input label="Category" placeholder={parentCategoryName} name="category_name" disabled  />
          <Input label="Nested Sub-category" placeholder="Name of nested sub-category" name="name"  />
          <TextArea optional label="Describe Nested Sub-Category" placeholder="Enter a description for this nested sub-category" name="description" />

          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Save" type="submit" />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateNestedSubCategoryModal
import {BusinessSubscriptionDrawerStyled, DrawerStyled} from "../../../styles/drawerStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import BusinessSubscriptionCheckout from "./BusinessSubscriptionCheckout";
import {CloseSquare} from "iconsax-react";


const BusinessSubscriptionDrawer = ({ subscription, onClose }) => {
	const { numberFormatter, currencyFormatter } = useDataTypeFormatter()


	return (
		<DrawerStyled onClick={onClose}>
			<BusinessSubscriptionDrawerStyled onClick={e => e.stopPropagation()}>
				<SpaceBetweenStyled>
					<Heading color="black" size={1.3}>{subscription.name}</Heading>
					<CloseSquare size={28} onClick={onClose} />
				</SpaceBetweenStyled>

				<br/>
				<FlexColumnStyled $gap={1}>
					<SpaceBetweenStyled>
						<FlexStyled $gap={.8}>
							<Paragraph color="black" bold>Amount:</Paragraph>
							<Paragraph color="black">{currencyFormatter(subscription.value)}</Paragraph>
						</FlexStyled>

						<FlexStyled $gap={.8}>
							<Paragraph color="black" bold>Duration:</Paragraph>
							<Paragraph color="black">{numberFormatter(subscription.duration)} months</Paragraph>
						</FlexStyled>
					</SpaceBetweenStyled>

					<SpaceBetweenStyled>
						{(Array.isArray(subscription?.exceptions) && subscription.exceptions.length > 0 ) && (
							<div>
								<Paragraph color="black" bold>Exempted Products:</Paragraph>

								<ol className="exempted_products">
									{subscription.exceptions.map(product => (
										<li> <Paragraph color="black" size={.9}>{product.name}</Paragraph> </li>
									))}
								</ol>
							</div>
						)}

						<FlexStyled $gap={.8}>
							<Paragraph color="black" bold>Limit:</Paragraph>
							<Paragraph color="black">{numberFormatter(subscription.limit)} purchase</Paragraph>
						</FlexStyled>
					</SpaceBetweenStyled>
				</FlexColumnStyled>

				<br/>

				<BusinessSubscriptionCheckout subscription={subscription} onClose={onClose} closeDrawer={onClose} />
			</BusinessSubscriptionDrawerStyled>
		</DrawerStyled>
	)
}


export default BusinessSubscriptionDrawer
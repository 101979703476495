import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import purchaseOrderRestructure from "./purchaseOrderRestructure";

export const getPurchaseOrderService = async (query = {}) => {
  try {
    const url = handleQueryParams("/purchase_order", query)
    const { data } = await axios.get(url)
    return purchaseOrderRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getPurchaseOrderByIdService = async (purchaseOrderId) => {
  try {
    const { data } = await axios.get(`/purchase_order/${purchaseOrderId}`)
    return purchaseOrderRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const createPurchaseOrderService = async (body) => {
  try {
    const { data } = await axios.post("/purchase_order/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updatePurchaseOrderService = async (id, body) => {
  try {
    const { data } = await axios.patch(`/purchase_order/${id}/`, body)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const deletePurchaseOrderService = async (id) => {
  try {
    const { data } = await axios.delete(`/purchase_order/${id}/`)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

import {ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {StoreFrontAuthModalStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Button, Form, Input} from "../../../../components";
import {useUserProfile} from "../../../../hooks/useServices/userHooks";


const StoreFrontUserProfileModal = ({ onClose, handleSubmit, mutation }) => {
  const { data: userProfile } = useUserProfile()
  
  const getUserProfile = () => {
    const full_name = `${userProfile?.[0]?.first_name} ${userProfile?.[0]?.last_name}`
   return { ...userProfile?.[0], full_name }
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <StoreFrontAuthModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading color="black" >Update Your Profile</Heading>
        
        <Form values={getUserProfile()} onSubmit={handleSubmit}>
          <Input placeholder="Enter your full name" label="Full Name" name="full_name" />
          <Input type="email" placeholder="Enter your email address" label="Email Address" name="email" />
          <Input type="number" placeholder="Enter your phone number" label="Phone Number" name="phone" />
          <Input placeholder="Enter your address for delivery" label="Address" name="address" />
          
          <Button type="submit" text="Update" isLoading={mutation.isLoading} />
        </Form>
      </StoreFrontAuthModalStyled>
    </ModalLayoutStyled>
  )
}

export default StoreFrontUserProfileModal
import types from "../../../utils/types";
import useDataTypeFormatter from "../useDataTypeFormatter";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {disableSellBelowStockWhenOfflineForAccount} from "../../../utils/manageDisplayOfFeatures";
import {useUserProfile} from "../../useServices/userHooks";


const useTableValueTransformer = (data = [], valueKeys = []) => {
	const {isOffline} = useGlobalContext()
	const {data: userProfile} = useUserProfile()
	const {
		dateFormatter,
		numberFormatter,
		currencyFormatter,
		dateOnlyFormatter,
		nairaCurrencyFormatter,
		dateWithDayFormatter
	} = useDataTypeFormatter()

	return valueKeys.map(({ key, keyType }) => {
		switch (keyType) {
			case types.DATE:
				try {
					if (data[key] === "None") return "N/A"
					return !!data[key] ? dateFormatter(data[key]) : "N/A"
				} catch (_) {
					return "N/A"
				}
			
			case types.DATE_WITH_DAY_NAME:
				try {
					if (data[key] === "None") return "N/A"
					return !!data[key] ? dateWithDayFormatter(data[key]) : "N/A"
				} catch (_) {
					return "N/A"
				}
			
			case types.DATE_ONLY:
				try {
					if (data[key] === "None") return "N/A"
					return !!data[key] ? dateOnlyFormatter(data[key]) : "N/A"
				} catch (_) {
					return "N/A"
				}
			
			case types.NUMBER:
				if (key === "stock_unit" && (!data.use_stock || isOffline)) {
					return disableSellBelowStockWhenOfflineForAccount(userProfile?.[0]?.account) ? numberFormatter(data[key]) : "N/A"
				}
				
				return numberFormatter(data[key])
			
			case types.NEGATIVE_NUMBER:
				if(Number(data[key]) === 0) return 0
				return `-${numberFormatter(data[key])}`
			
			case types.CURRENCY:
				return currencyFormatter(data[key])
			
			case types.NAIRA_CURRENCY:
				return nairaCurrencyFormatter(data[key])
			
			case types.LOG_ACTION:
				return !!data[key] ? "in" : "out"

			case types.ACTIVE_STATUS:
				return !!data[key] ? "Active" : "Inactive"

			default:
				return data[key] || ""
		}
	})
}

export default useTableValueTransformer
import useDataTypeFormatter from "../useDataTypeFormatter";
import {getPercentageFromAmount, getSumOfAllProductsInCart} from "../../../utils/sell";


// TODO Expensive calculation, find a way to memoize it

const handleDiscount = (type, value, total) => {
	if(type === "percentage") {
		return getTotalDiscount(value, total)
	}

	return value
}

const useCartCalculations = (products = [], customDiscountType, customDiscountValue = 0, deliveryRate = 0) => {
	const {currencyFormatter} = useDataTypeFormatter()
	const {manualCalculationFn} = useCartProductTaxAndDiscountCalculation(products[0])
	
	const totalCalculation = products.reduce((prev, curr) => {
		const total = manualCalculationFn(curr, customDiscountValue, customDiscountType)
		
		return {
			totalTaxAmount: total.taxAmount + prev.totalTaxAmount,
			totalDiscountAmount: total.discountAmount + prev.totalDiscountAmount,
			totalServiceAmount: total.serviceAmount + prev.totalServiceAmount,
			subtotalAmount: total.productOriginalCost + prev.subtotalAmount,
			totalAmount: total.productTotalValue + prev.totalAmount
		}
	}, {totalTaxAmount: 0, totalDiscountAmount: 0, totalServiceAmount: 0, totalAmount: 0, subtotalAmount: 0})
	
	const totalAmount = (totalCalculation.totalAmount + deliveryRate)
	
	const getCheckoutDiscount = () => {
		if (!Number(customDiscountValue) || customDiscountType === "amount") return Number(customDiscountValue)
		return Number(((totalAmount * customDiscountValue) / 100).toFixed(2))
	}
	
	return {
		manualCalculationForProduct: manualCalculationFn,
		checkoutDiscount: getCheckoutDiscount(),
		totalTaxValue: totalCalculation.totalTaxAmount,
		totalDelivery: currencyFormatter(deliveryRate),
		taxPercentage: !!totalCalculation.totalTaxAmount,
		totalServicesValue: totalCalculation.totalServiceAmount,
		totalDiscountValue: totalCalculation.totalDiscountAmount,
		discountPercentage: !!totalCalculation.totalDiscountAmount,
		totalTax: currencyFormatter(totalCalculation.totalTaxAmount),
		subTotal: currencyFormatter(totalCalculation.subtotalAmount),
		totalDiscount: currencyFormatter(totalCalculation.totalDiscountAmount),
		totalService: currencyFormatter(totalCalculation.totalServiceAmount),
		checkoutDiscountPercentage: customDiscountType === "percentage" && customDiscountValue,
		overallCalculationValue: Number((totalAmount - getCheckoutDiscount()).toFixed(2)),
		overallCalculation: currencyFormatter(Number((totalAmount - getCheckoutDiscount())).toFixed(2))
	}
}

export default useCartCalculations

export const useCartProductTaxAndDiscountCalculation = (product = {}) => {
	const productSellingPrice = Number(product.unit) * Number(product.selling_price)
	const productTotalServicesCost = getProductServiceCost(product.service)
	let discountPercentage = 0
	
	const getProductDiscount = (product, productSellingPrice) => {
		if (!product?.applied_discount) return 0
		if (product?.applied_discount?.value_type === "amount") return Number(Number(product?.applied_discount?.value).toFixed(2))
		discountPercentage = Number(product?.applied_discount?.value)
		return Number(((productSellingPrice * product?.applied_discount?.value) / 100).toFixed(2))
	}
	
	const getProductTaxDetails = (product, productSellingPrice) => {
		if (!product?.name) {
			return {
				totalAdditionalTaxInAmount: 0,
				totalSubtractiveTaxInAmount: 0,
				additionalTaxArray: [],
				subtractiveTaxArray: []
			}
		}
		
		product["applied_tax"] = product?.applied_tax || []
		const subtractiveTaxArray = product?.applied_tax?.filter(tax => tax.calculation_type === "subtraction")?.map(tax => {
			const sellingPriceAfterDiscount = productSellingPrice - (getProductDiscount(product, productSellingPrice) || 0)
			const taxAmount = Number(getPercentageFromAmount(Number(tax.value), sellingPriceAfterDiscount).toFixed(2))
			
			return {...tax, taxAmount}
		})
		
		const additionalTaxArray = product?.applied_tax?.filter(tax => tax.calculation_type === "addition")?.map(tax => {
			const sellingPriceAfterDiscount = productSellingPrice - (getProductDiscount(product, productSellingPrice) || 0)
			const taxAmount = Number(getPercentageFromAmount(Number(tax.value), sellingPriceAfterDiscount).toFixed(2))
			
			return {...tax, taxAmount}
		})
		
		const totalSubtractiveTaxInAmount = subtractiveTaxArray?.reduce((prev, curr) => {
			return prev + curr.taxAmount
		}, 0)
		
		const totalAdditionalTaxInAmount = additionalTaxArray?.reduce((prev, curr) => {
			return prev + curr.taxAmount
		}, 0)
		
		return {
			additionalTaxArray,
			subtractiveTaxArray,
			totalAdditionalTaxInAmount: Number(totalAdditionalTaxInAmount.toFixed(2)),
			totalSubtractiveTaxInAmount: Number(totalSubtractiveTaxInAmount.toFixed(2)),
			totalTax: Number((totalSubtractiveTaxInAmount + totalAdditionalTaxInAmount).toFixed(2))
		}
	}
	
	const getProductOriginalCostAndFinalSellingPrice = (product, productSellingPrice, productTotalServicesCost) => {
		const taxDetails = getProductTaxDetails(product, (productSellingPrice + productTotalServicesCost))
		const discountAmount = getProductDiscount(product, (productSellingPrice + productTotalServicesCost)) || 0
		
		let price = productSellingPrice
		
		price -= discountAmount
		price += (taxDetails.totalAdditionalTaxInAmount || 0)
		let productOriginalCost = productSellingPrice - (taxDetails.totalSubtractiveTaxInAmount || 0)
		price += (productTotalServicesCost || 0)
		
		return {totalSellingPrice: price, productOriginalCost}
	}
	
	const manualCalculationFn = (product, customDiscountValue, customDiscountType) => {
		const productSellingPrice = Number(product.unit) * Number(product.selling_price)
		const productTotalServicesCost = getProductServiceCost(product.service)
		const {
			totalSellingPrice,
			productOriginalCost
		} = getProductOriginalCostAndFinalSellingPrice(product, productSellingPrice, productTotalServicesCost)
		
		if (customDiscountType === "amount" && Number(customDiscountValue) > 0) {
			const customDiscountPercentage = (Number(customDiscountValue / 100) * productSellingPrice)
			product.applied_discount = [{value: customDiscountPercentage, value_type: "percentage"}]
		}
		
		if (customDiscountType === "percentage" && Number(customDiscountValue) > 0) {
			product.applied_discount = [{value: customDiscountValue, value_type: "percentage"}]
		}
		
		const {totalTax} = getProductTaxDetails(product, (productSellingPrice + productTotalServicesCost))
		
		return {
			taxAmount: totalTax,
			productOriginalCost,
			productTotalValue: totalSellingPrice,
			subtotalAmount: productSellingPrice,
			serviceAmount: productTotalServicesCost,
			discountAmount: getProductDiscount(product, productSellingPrice),
		}
	}
	
	const {
		totalAdditionalTaxInAmount,
		additionalTaxArray,
		subtractiveTaxArray,
		totalSubtractiveTaxInAmount
	} = getProductTaxDetails(product, (productSellingPrice + productTotalServicesCost))
	
	const {
		totalSellingPrice,
		productOriginalCost
	} = getProductOriginalCostAndFinalSellingPrice(product, productSellingPrice, productTotalServicesCost)
	
	return {
		manualCalculationFn,
		additionalTaxArray,
		subtractiveTaxArray,
		discountPercentage,
		totalSubtractiveTaxInAmount,
		totalAdditionalTaxInAmount,
		serviceAmount: productTotalServicesCost,
		discountAmount: getProductDiscount(product, productSellingPrice),
		productOriginalCost,
		productTotalValue: totalSellingPrice,
	}
}

export const useReturnCartCalculations = ({ products, customDiscountType, customDiscountValue = 0, deliveryRate = 0, taxPercentage, discountPercentage }) => {
	const { currencyFormatter } = useDataTypeFormatter()
	
	const subTotal = getSubTotalOfAllProducts(products)
	const totalDiscountVal = !!customDiscountType ? handleDiscount(customDiscountType, customDiscountValue, subTotal) : getTotalDiscount(discountPercentage, subTotal)
	const totalTax = getTotalTax(taxPercentage, subTotal, totalDiscountVal)
	const overallCalculation = getOverallCalculation(subTotal || 0, totalDiscountVal, totalTax, deliveryRate || 0)
	
	const getDiscountPercentage = () => {
		if(!customDiscountType) return discountPercentage
		return customDiscountValue
	}
	
	return {
		discountPercentage: getDiscountPercentage(),
		subTotal: currencyFormatter(subTotal),
		totalDiscount: currencyFormatter(totalDiscountVal),
		totalTax: currencyFormatter(totalTax),
		totalDelivery: currencyFormatter(deliveryRate),
		overallCalculationValue: Number(Number(overallCalculation).toFixed(2)),
		overallCalculation: currencyFormatter(Number(overallCalculation).toFixed(2))
	}
}




export const getSubTotalOfAllProducts = (cart) => {
	const totalProductServices = getSumOfServices(cart)
	const totalSumOfProducts = getSumOfAllProductsInCart(cart)
	return (totalSumOfProducts + totalProductServices)
}

const getSumOfServices = (productsInCart) => {
	const allProductsWithAddOn = productsInCart.filter(product => {
		return (!!product.service && product.service.length > 0)
	})

	const allProductServices = allProductsWithAddOn.map(productInCart => productInCart.service)

	const allServices = []
	allProductServices.forEach(item => {
		allServices.push(...item)
	})

	const amountOfServices = allServices.reduce((prev, curr) => {
		return prev + (curr.cost)
	}, 0)

	return amountOfServices || 0
}

const getTotalDiscount = (discount, subTotal) => {
	return getPercentageFromAmount(discount, subTotal)
}

const getTotalTax = (tax, subTotal, totalDiscount) => {
	const taxableAmount = subTotal - totalDiscount
	return getPercentageFromAmount(tax, taxableAmount)

	// const taxableAmount = getSubTotalOfAllProducts() - getTotalDiscount()
	// return getPercentageFromAmount(totalTax, taxableAmount)
}

const getOverallCalculation = (subTotal, totalDiscount, totalTax, deliveryRate) => {
	return (subTotal - totalDiscount) + totalTax + deliveryRate
	
	// const subTotal = getSubTotalOfAllProducts()
	// const discountOnProduct = getTotalDiscount()
	//
	// const taxableAmount = subTotal - discountOnProduct
	// const taxOnProducts = getTotalTax()
	//
	// return taxableAmount + taxOnProducts
}

const getProductServiceCost = (services = []) => {
	if (!services) return 0
	
	return services.reduce((previousValue, currentValue) => {
		return previousValue + Number(Number(currentValue?.cost).toFixed(2))
	}, 0)
}
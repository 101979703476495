import {Line} from "../index";
import {DocumentDownload, Eye, Trash} from "iconsax-react";
import {useTheme} from "styled-components";
import {Heading} from "../../styles/textStyles";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {TableItemActionStyled, TableItemStyled} from "../../styles/TableStyles";
import {NO_OF_RESULTS_FROM_BACKEND} from "../../utils/constants";
import {Fragment} from "react";


const TableItemWithEyeAction = ({
                                  data,
                                  actionPosition,
                                  keys,
                                  onClick,
                                  dataKey = "id",
                                  pageCount,
                                  count,
                                  returnFullData,
                                  downloadAction,
                                  handleDelete
                                }) => {
  const theme = useTheme()
  const tableData = useTableValueTransformer(data, keys)
  
  const dataToReturnOnClick = !!returnFullData ? data : data[dataKey]
  return (
    <TableItemStyled onClick={onClick?.bind(this, dataToReturnOnClick)}>
      <td className="counter">
        <Heading color="black" thin size={1}>{(count + 1) + ((pageCount - 1) * NO_OF_RESULTS_FROM_BACKEND)}</Heading>
      </td>
    
      {tableData.map((item, k) =>
        <td key={k} onClick={e => e.stopPropagation()}>
          <Heading color="black" thin size={.9}>{item}</Heading>
        </td>
      )}
    
      <TableItemActionStyled position={actionPosition} onClick={e => e.stopPropagation()}>
        {downloadAction && (
          <Fragment>
            <DocumentDownload onClick={onClick?.bind(this, dataToReturnOnClick)} color={theme.success}/>
            <Trash color={theme.error} onClick={() => handleDelete(data)}/>
          </Fragment>
        )}
      
        {!downloadAction && (
          <Eye onClick={onClick?.bind(this, dataToReturnOnClick)} color={theme.gray}/>
        )}
      </TableItemActionStyled>
      <Line/>
    </TableItemStyled>
  )
}

export default TableItemWithEyeAction
import {useMutation, useQuery, useQueryClient} from "react-query";
import accountQueryKey from "./accountQueryKey";
import {
	getAccountBusinessesByIdService,
	getAccountByIdService,
	getAccountsService, getBusinessesUsingOvaloopPayService, updateAccountTypeByIdService
} from "../../../../services/staffBackendServices/accountServices";
import {useParams} from "react-router-dom";
import useGlobalContext from "../../../useContexts/useGlobalContext";
import errorHandler from "../../../../utils/errorHandler";


export const useAccountQuery = (searchValue) => {
	return useQuery({
		queryKey: accountQueryKey.mainList(searchValue),
		queryFn: () => getAccountsService(searchValue)
	})
}

export const useAccountByIdQuery = (accountId) => {
	return useQuery({
		queryKey: accountQueryKey.accountById(accountId),
		queryFn: () => getAccountByIdService(accountId)
	})
}

export const useAccountBusinessesByIdQuery = (search = "") => {
	const { accountId } = useParams()
	
	return useQuery({
		queryKey: accountQueryKey.byAccountId(accountId, search),
		queryFn: () => getAccountBusinessesByIdService({ account_id: accountId, search })
	})
}

export const useBusinessesUsingOvaloopPayQuery = (query = {}) => {
	return useQuery({
		queryKey: accountQueryKey.ovaloopPay(query),
		queryFn: () => getBusinessesUsingOvaloopPayService(query)
	})
}


export const useSwitchAccountTypeByIdMutation = () => {
	const { toast } = useGlobalContext()
	const queryClient = useQueryClient()
	
	return useMutation({
		mutationFn: (body) => updateAccountTypeByIdService(body.accountId, body),
		
		onSuccess: (res, variables) => {
			toast.success("Switch account successful")
			queryClient.refetchQueries({ queryKey: accountQueryKey.accountById(variables.accountId) })
		},
		
		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}
import {
  Button,
  CreateProductModal,
  ExportProductModal,
  MoveProductsModal,
  NoDataLayout,
  PageHeader,
  PageSummaryCard,
  ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithImageAndActions,
  TableItemWithImageAndCheckBox,
  TablePagination,
  UploadBulkProductModal
} from "../../../../components";
import {Fragment, useEffect, useState,} from "react";
import {useModal} from "../../../../hooks";
import {EmptyBoxImage} from "../../../../assets/images";
import {FlexStyled, PageSummaryStyled} from "../../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {
  useBulkDeleteProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
  useExportAllProductMutation,
  useMoveProductService,
  useProductDistributionMutation,
  useProductQuery,
  usePublishProductToStoreFrontMutation,
  useUploadBulkProductService
} from "../../../../hooks/useServices/useProductServices";

import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {TableTopBarStyled} from "../../../../styles/TableStyles";
import useQueryDebounce, {debounceActions} from "../../../../hooks/useUtils/useQueryDebounce";
import {productFormDefaultValues} from "../../../../data/defaultFormValues";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import EditProductModal from "../../../../components/Modal/InventoryModal/ProductModals/EditProductModal";
import {
  formatProductFormForEditing,
  formatProductFormForSubmission,
  handleEditProductAttachments
} from "../../../../utils/formatForms/product";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import {actionsPermissions, routesPermissions} from "../../../../data/permissions";
import productQueryKey from "../../../../hooks/useServices/useProductServices/productQueryKey";
import {useBusinessProductAnalyticsService} from "../../../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {SlOptionsVertical} from "react-icons/sl"
import useProductContext from "../../../../hooks/useContexts/useProductContext";
import {desktopQueryKeys} from "../../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../../desktop/sendDesktopNotification";
import {useQueryClient} from "react-query";
import DistributeMultipleProductModal
  from "../../../../components/Modal/InventoryModal/ProductModals/DistributeMultipleProductModal";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {handleFormatCustomUnitMeasurementForFormEditing} from "../../../../utils/products";
import {ListDropDownStyled, MenuDropDownStyled, MenuListStyled} from "../../../../styles/listStyles";
import {Copy, ExportCircle, ImportCircle, Shop, Transmit, Trash} from "iconsax-react";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {sortActionFilters} from "../../../../data/selectField/actionFilters";
import {globalReducerActions} from "../../../../reducers/globalReducer";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {
  handleFormattingOfPurchaseOrderForRestockingOfProducts
} from "../../../../utils/formatForms/purchaseOrderFormatter";


const Product = () => {
  const queryClient = useQueryClient()
  const productContext = useProductContext()
  const businessSettings = useBusinessSettingQuery()
  
  const actionPermission = useActionPermissionFn()
  const { navigate, toast, globalReducer, isOffline } = useGlobalContext()
  const [editFormValues, setEditFormValues] = useState(productFormDefaultValues)

  const [isBulkDelete, setIsBulkDelete] = useModal()
  const [showExportProductModal, setShowExportProductModal] = useModal()
  const [isMultipleTransfer, setIsMultipleTransfer] = useModal()
  const [isRestockLowLevel, setRestockLowLevel] = useModal()
  const [isPublishProductsToStoreFront, setIsPublishProductsToStoreFront] = useModal()
  const [showManageMultipleTransfer, setShowManageMultipleTransfer] = useModal()
  
  const [showCreateProductModal, setShowCreateProductModal] = useModal(modalNames.CREATE_PRODUCT_MODAL)
  const [showEditProductModal, setShowEditProductModal] = useModal(modalNames.EDIT_PRODUCT_MODAL)
  
  const [showUploadProductModal, setShowUploadProductModal] = useModal()
  const [showMoveProductModal, setShowMoveProductModal] = useModal()
  const { data: businessProductAnalytics, isLoading: isBusinessAnalyticsLoading, refetch: refetchBusinessAnalytics } = useBusinessProductAnalyticsService({ type: "general_product" })
  const { currencyFormatterWithNoFraction, numberFormatter, currencyFormatter } = useDataTypeFormatter()

  const {
    createProduct: createProductPermission,
    updateProduct: updateProductPermission,
    deleteProduct: deleteProductPermission,
  } = actionsPermissions

  const { productTable, productBulkDeleteTable } = tableHeadersAndValueKeys
  const { data: allCategories } = useCategoriesQuery()
  
  const { debounceState, debounceReducer } = useQueryDebounce()
  const query = { search: debounceState.searchValue, category_id: debounceState.filterValue, type: "general_product", filter: debounceState.staffValue }
  const { mainQuery, ...allProducts } = useOrderQueryWithSearchAndFilter(query, useProductQuery)
  
  const handleCloseMultipleTransfer = () => {
    setShowManageMultipleTransfer()
    setIsMultipleTransfer()
  }
  
  const distributeProductMutation = useProductDistributionMutation({ successFn: handleCloseMultipleTransfer })
  const editProductMutation = useEditProductMutation({ successFn: setShowEditProductModal })
  const createProductMutation = useCreateProductMutation({ successFn: setShowCreateProductModal })
  const moveProductMutation = useMoveProductService({ successFn: setShowMoveProductModal })
  const uploadBulkProductMutation = useUploadBulkProductService({ successFn: setShowUploadProductModal })
  const deleteProductMutation = useDeleteProductMutation()
  
  const bulkDeleteSuccess = () => {
    setIsBulkDelete()
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
    })
  }
  
  useEffect(() => {
    return () => {
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
      })
  
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER
      })
  
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_PUBLISH_ORDER
      })
  
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_RESTOCK
      })
    }
  }, [])
  
  const handleCloseMoreOptions = () => {
    isBulkDelete && setIsBulkDelete()
    isMultipleTransfer && setIsMultipleTransfer()
    isRestockLowLevel && setRestockLowLevel()
    isPublishProductsToStoreFront && setIsPublishProductsToStoreFront()
  }
  
  const bulkDeleteProductMutation = useBulkDeleteProductMutation({ successFn: bulkDeleteSuccess })
  const exportAllProductMutation = useExportAllProductMutation({ successFn: setShowExportProductModal })
  const publishProductMutation = usePublishProductToStoreFrontMutation({ successFn: handleCloseMoreOptions })

  const handleCreateProduct = (values) => {
    const formattedValues = formatProductFormForSubmission(values)
    createProductMutation.mutate(formattedValues)
  }

  const handleEditProduct = (values) => {
    const newValues = { ...values }
    const formattedValues = formatProductFormForSubmission(values)

    const currentProject = allProducts.data.results.find(product => product.id === values.id)
    if(currentProject.image_path === newValues.image_path) {
      delete formattedValues.image_path
    }

    editProductMutation.mutate(formattedValues)
  }

  const handleEditAction = (product) => {
		const values = { ...product, prepare_time_format: product.prepare_time }

    const formattedValues = formatProductFormForEditing(values)
    const formattedValues2 = handleEditProductAttachments(formattedValues)
    const formattedValues3 = handleFormatCustomUnitMeasurementForFormEditing(formattedValues2, currencyFormatter)
    
    setEditFormValues(formattedValues3)
    setShowEditProductModal()
  }

  const handleDeleteAction = (productId) => {
    toast.confirm(
      "Are you sure?",
      () => deleteProductMutation.mutate(productId),
      "delete"
    )
  }

  const handleUploadProduct = (type, values) => {
    uploadBulkProductMutation.mutate({ type, values })
  }

  const handleMoveProduct = (values) => {
    moveProductMutation.mutate(values)
  }

  const handleViewItem = (productId) => {
    navigate(`/dashboard/inventory/product/${productId}`)
  }

  const handleQueryKeyForPagination = () => {
    const query = { search: debounceState.searchValue, category_id: debounceState.filterValue, type: "general_product", filter: debounceState.staffValue }
    return productQueryKey.byQueryParams(query)
  }
  
  const handleBulkDeleteSelect = (product) => {
    productContext.dispatch({
      type: productContext.actions.ADD_PRODUCT_TO_BULK_DELETE,
      itemId: product.id
    })
  }
  
  const handleMultipleTransferSelect = (product) => {
    productContext.dispatch({
      product,
      type: productContext.actions.ADD_PRODUCT_TO_MULTIPLE_TRANSFER,
    })
  }
  
  const handlePublishProductSelect = (product) => {
    productContext.dispatch({
      itemId: product.id,
      type: productContext.actions.ADD_PRODUCT_TO_PUBLISH_ORDER,
    })
  }
  
  const handleRestockProductSelect = (product) => {
    productContext.dispatch({
      product,
      type: productContext.actions.ADD_PRODUCT_TO_RESTOCK,
    })
  }
  
  const handleBulkDelete = (isDeleteAll) => {
    if(isDeleteAll) {
      toast.confirm(
        "Are you sure you want to delete all product in this business?",
        () => bulkDeleteProductMutation.mutate({ delete_all: true }),
        "delete all"
      )
      return
    }
    
    const product_list = productContext.state.bulkDelete
    toast.confirm(
      `Are you sure you want to ${product_list.length} delete selected products?`,
      () => bulkDeleteProductMutation.mutate({ delete_all: false, product_list }),
      "delete all"
    )
  }
  
  const handlePublishProduct = (type) => {
    if(type === "all") {
      toast.confirm(
        "Are you sure you want to publish all products to your store front?",
        () => publishProductMutation.mutate({ action: "all" }),
        "publish_all"
      )
      return
    }
  
    toast.confirm(
      `Are you sure you want to publish ${productContext.state.publishProducts.length} products to your store front?`,
      () => publishProductMutation.mutate({ action: "selected", products: productContext.state.publishProducts }),
      "publish_selected"
    )
  }
  
  const handleRestockLevelMutation = (type) => {
    const onConfirm = (products) => {
      const formatProducts = handleFormattingOfPurchaseOrderForRestockingOfProducts({
        products, businessSettings, currencyFormatter
      })

      globalReducer({ type: globalReducerActions.SET_RESTOCK_PRODUCTS, products: formatProducts })
      navigate(`/dashboard/inventory/purchase_order/create`)
    }

    if(type === "all") {
      toast.confirm(
        "Are you sure you want to restock all low level products?",
        () => onConfirm(allProducts?.data?.results),
        "restock_all"
      )
      return
    }

    toast.confirm(
      `Are you sure you want to restock ${productContext.state.restockProducts.length} low level products?`,
      () => onConfirm(productContext.state.restockProducts),
      "restock_all"
    )
  }
  
  const handleMultipleTransfer = () => {
    setShowManageMultipleTransfer()
  }
  
  const handleCancelMultipleTransfer = () => {
    isMultipleTransfer && setIsMultipleTransfer()
    isBulkDelete && setIsBulkDelete()
    isRestockLowLevel && setRestockLowLevel()
    isPublishProductsToStoreFront && setIsPublishProductsToStoreFront()
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER
    })
  
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
    })
  
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_PUBLISH_ORDER
    })
  
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_RESTOCK
    })
  }
  
  const handleProductDistribution = (values) => {
    distributeProductMutation.mutate({ multiple_data: values, multiple: true })
  }
  
  const refetchQueries = async () => {
    await refetchBusinessAnalytics()
    mainQuery.refetch()
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.products}).then(() => {
      sendDesktopNotification({
        title: "Sync successful",
        body: "All products has been synced offline"
      })
    })
  }
  
  const handleExportAllProductsToExcel = (query = {}) => {
    exportAllProductMutation.mutate(query)
  }
  
  const handleSortFilter = (filter) => {
    debounceReducer({
      staffValue: filter.id,
      type: debounceActions.SET_FILTER_BY_STAFF_FIELD
    })
  }
  
  const tableUtil = () => {
    if(isBulkDelete) return { handleCheck: handleBulkDeleteSelect, checked: (itemId) => productContext.state.bulkDelete.find(item => item === itemId) }
    if(isPublishProductsToStoreFront) return { handleCheck: handlePublishProductSelect, checked: (itemId) => productContext.state.publishProducts.find(item => item === itemId) }
    if(isMultipleTransfer) return { handleCheck: handleMultipleTransferSelect, checked: (itemId) => productContext.state.multipleTransfer.find(item => item === itemId) }
    if(isRestockLowLevel) return { handleCheck: handleRestockProductSelect, checked: (itemId) => productContext.state.restockProducts.find(item => item === itemId) }
  }

  return (
    <div>
      {showCreateProductModal && <CreateProductModal onClose={setShowCreateProductModal} handleSubmit={handleCreateProduct} mutation={createProductMutation} />}
      {showEditProductModal && <EditProductModal onClose={setShowEditProductModal} handleSubmit={handleEditProduct} mutation={editProductMutation} formValues={editFormValues} />}
      {showUploadProductModal && <UploadBulkProductModal onClose={setShowUploadProductModal} handleSubmit={handleUploadProduct} mutation={uploadBulkProductMutation} />}
      {showMoveProductModal && <MoveProductsModal onClose={setShowMoveProductModal} handleSubmit={handleMoveProduct} mutation={moveProductMutation} />}
      {showManageMultipleTransfer && <DistributeMultipleProductModal products={productContext.state.multipleTransfer} onClose={setShowManageMultipleTransfer} mutation={distributeProductMutation} handleSubmit={handleProductDistribution} />}
      {showExportProductModal && <ExportProductModal handleSubmit={handleExportAllProductsToExcel} mutation={exportAllProductMutation} onClose={setShowExportProductModal} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}

      <Fragment>
        {allProducts.isEmpty && (
          <Fragment>
            <PageHeader title="Products" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No product"
              imgSrc={EmptyBoxImage}
              heading="No product yet"
              subHeading="You have no items yet, start updating your stock"
              buttonText="Add new product"
              button2Text="Upload Product"
              buttonClick={setShowCreateProductModal}
              button2Click={setShowUploadProductModal}
              permissionCode={createProductPermission}
            />
          </Fragment>
        )}
  
  
        {allProducts.showTable && (
          <Fragment>
            <PageHeader title="Products" />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={isBusinessAnalyticsLoading}
                only
                title="Total Number Of Products"
                value={numberFormatter(isOffline ? allProducts?.data?.count : businessProductAnalytics?.number_of_product)}
                subText={actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && `Total stock units: ${businessProductAnalytics?.number_of_product_qty}`}
              />
              
              {actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (
                <PageSummaryCard
                  isLoading={isBusinessAnalyticsLoading}
                  title="Total cost in stock"
                  value={isOffline ? "N/A" : currencyFormatterWithNoFraction(businessProductAnalytics?.total_cost_price)}
                />
              )}

              {actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (
                <PageSummaryCard
                  isLoading={isBusinessAnalyticsLoading}
                  title="Total Stock Value"
                  value={isOffline ? "N/A" : currencyFormatterWithNoFraction(businessProductAnalytics?.total_selling_price)}
                />
              )}
              
              {actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (
                <PageSummaryCard
                  isLoading={isBusinessAnalyticsLoading}
                  title="Total Profit Value"
                  value={isOffline ? "N/A" : currencyFormatterWithNoFraction(businessProductAnalytics?.total_profit)}
                />
              )}
            </PageSummaryStyled>

            <SetUpLayoutStyled>

              <TableTopBarStyled $showSortFilter={(!isMultipleTransfer && !isBulkDelete && !isPublishProductsToStoreFront && !isRestockLowLevel)}>
                <SearchAndFilter
                  showSortFilter={(!isMultipleTransfer && !isBulkDelete && !isPublishProductsToStoreFront && !isRestockLowLevel) && !isOffline}
                  sync={{ title: "products", fn: handleManualSync }}
                  valueKey="id"
                  displayKey="name"
                  sortFilterOptions={sortActionFilters}
                  dispatch={debounceReducer}
                  handleSortFilter={handleSortFilter}
                  filterOptions={allCategories?.results || []}
                  filterPlaceholder="Filter by category"
                  searchPlaceholder="Search by product name or SKU"
                />

                <FlexStyled>
                  {(!isBulkDelete && !isMultipleTransfer && !isPublishProductsToStoreFront && !isRestockLowLevel) && (
                    <Fragment>
                      <Button
                        disabled={isOffline}
                        text="Create"
                        onClick={setShowCreateProductModal}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}

                  {isBulkDelete && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.bulkDelete.length} Selected</Paragraph>
  
                      <Button
                        text="Delete Selected"
                        disabled={!productContext.state.isItemToBulkDelete}
                        onClick={() => handleBulkDelete(false)}
                        permissionCode={createProductPermission}
                      />
                      
                      <Button
                        text="Delete All"
                        disabled={productContext.state.isItemToBulkDelete}
                        onClick={() => handleBulkDelete(true)}
                        permissionCode={createProductPermission}
                      />
  
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
  
                  {isPublishProductsToStoreFront && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.publishProducts.length} Selected</Paragraph>
      
                      <Button
                        text="Publish Selected"
                        disabled={!productContext.state.isItemToPublish}
                        onClick={() => handlePublishProduct("selected")}
                        permissionCode={createProductPermission}
                      />
      
                      <Button
                        text="Publish All"
                        disabled={productContext.state.isItemToPublish}
                        onClick={() => handlePublishProduct("all")}
                        permissionCode={createProductPermission}
                      />
      
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
  
                  {isMultipleTransfer && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.multipleTransfer.length} Selected</Paragraph>
                      <Button
                        text="Transfer Selected"
                        disabled={!productContext.state.isMultipleTransfer}
                        onClick={handleMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
  
                  {isRestockLowLevel && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.restockProducts.length} Selected</Paragraph>
      
                      <Button
                        text="Restock Selected"
                        disabled={!productContext.state.isItemToRestock}
                        onClick={() => handleRestockLevelMutation("selected")}
                        permissionCode={createProductPermission}
                      />
      
                      <Button
                        text="Restock All"
                        disabled={productContext.state.isItemToRestock}
                        onClick={() => handleRestockLevelMutation("all")}
                        permissionCode={createProductPermission}
                      />
      
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
  
                  {((!isMultipleTransfer && !isBulkDelete && !isPublishProductsToStoreFront && !isRestockLowLevel) && !isOffline) && (
                    <MenuDropDownStyled>
                      <SlOptionsVertical size={25} color="black" />
    
                      <div className="container">
                        <ListDropDownStyled>
                          {(actionPermission.check(createProductPermission) && debounceState.staffValue === "low_stock_level") && (
                            <MenuListStyled onClick={setRestockLowLevel}>
                              <ImportCircle size={20} color="black" />
                              <Heading size={1.1} color="black">Restock low level</Heading>
                            </MenuListStyled>
                          )}
                          
                          {actionPermission.check(createProductPermission) && (
                            <MenuListStyled onClick={setShowUploadProductModal}>
                              <ImportCircle size={20} color="black" />
                              <Heading size={1.1} color="black">Import / Update</Heading>
                            </MenuListStyled>
                          )}
  
                          <MenuListStyled onClick={setIsMultipleTransfer}>
                            <Transmit size={20} color="black" />
                            <Heading size={1.1} color="black">Transfer</Heading>
                          </MenuListStyled>
                          
                          <MenuListStyled onClick={setShowExportProductModal}>
                            <ExportCircle size={20} color="black" />
                            <Heading size={1.1} color="black">Export Products</Heading>
                          </MenuListStyled>
                          
                          <MenuListStyled onClick={setIsBulkDelete}>
                            <Trash size={20} color="black" />
                            <Heading size={1.1} color="black">Delete Products</Heading>
                          </MenuListStyled>
                          
                          <MenuListStyled onClick={setShowMoveProductModal}>
                            <Copy size={20} color="black" />
                            <Heading size={1.1} color="black">Duplicate Product List</Heading>
                          </MenuListStyled>
                          
                          <MenuListStyled onClick={setIsPublishProductsToStoreFront}>
                            <Shop size={20} color="black"/>
                            <Heading size={1.1} color="black">Publish Products</Heading>
                          </MenuListStyled>
                        </ListDropDownStyled>
                      </div>
                    </MenuDropDownStyled>
                  )}
                </FlexStyled>
              </TableTopBarStyled>

              {(!isBulkDelete && !isMultipleTransfer && !isPublishProductsToStoreFront && !isRestockLowLevel) && (
                <Table bg headers={productTable.headers} noCols={productTable.headers.length}>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndActions
                          key={k}
                          data={items}
                          count={k}
                          pageCount={allProducts.currentCount}
                          keys={productTable.values}
                          onClick={handleViewItem}
                          editAction={handleEditAction}
                          deleteAction={handleDeleteAction}
                          editPermission={updateProductPermission}
                          deletePermission={deleteProductPermission}
                        />
                      )
                    )}
                  </Fragment>
  
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
  
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
  
              {(isBulkDelete || isMultipleTransfer || isPublishProductsToStoreFront || isRestockLowLevel) && (
                <Table bg headers={productBulkDeleteTable.headers} noCols={productBulkDeleteTable.headers.length} noCount equalWidth>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndCheckBox
                          key={k}
                          data={items}
                          keys={productBulkDeleteTable.values}
                          handleCheck={tableUtil().handleCheck}
                          checked={tableUtil().checked(items.id)}
                        />
                      )
                    )}
                  </Fragment>
    
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
    
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
              
              {allProducts.showPagination && (
                <TablePagination
                  query={allProducts}
                  queryKey={handleQueryKeyForPagination()}
                />
              )}
            </SetUpLayoutStyled>
            {allProducts.showPagination && (
              <TablePagination
                query={allProducts}
                queryKey={handleQueryKeyForPagination()}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Product;
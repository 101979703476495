import { ButtonStyled } from "../../styles/formStyles";
import { ChartCircle } from "iconsax-react"
import {useNavigate} from "react-router-dom";
import {forwardRef, Fragment} from "react";
import {useActionPermission} from "../../hooks/usePermissions";


const Button = forwardRef(function Button({ id, bgColor, className, text, type, isLoading, shadow, onClick, link, Icon, disabled, permissionCode, buttonRef, small }, ref) {
  const navigate = useNavigate()
  const showButton = useActionPermission(permissionCode)

  const handleClick = (e) => {
    e.stopPropagation()
    return link ? navigate(link) : onClick ? onClick() : () => {}
  }

  return (
    (showButton || !permissionCode) && (
      <ButtonStyled $bgColor={bgColor} $small={small} ref={ref} id={id} className={className} type={type} disabled={isLoading || disabled} shadow={shadow} onClick={handleClick}>
        { isLoading ? <ChartCircle className="loading" size={18} color="black" /> : (
          <Fragment>
            {!!Icon && <Icon size={20} />}
            {text}
          </Fragment>
        ) }
      </ButtonStyled>
    )
  )
})

export default Button
import {filterProductOutById, findProductInCartToHandleMultipleUnitMeasurement} from "../utils/sell";
import uuid from "react-uuid";

export const sellReducerActions = {
  CLEAR_CART: "CLEAR_CART",
  REFRESH_CART: "REFRESH_CART",
  ADD_PRODUCT_TO_CART: "ADD_TO_CART",
  REMOVE_PRODUCT_FROM_CART: "REMOVE_PRODUCT_FROM_CART",
  SET_PRODUCT_FOR_ADD_TO_CART_MODAL: "SET_PRODUCT_FOR_ADD_TO_CART_MODAL",
  SET_CUSTOMER_FOR_ORDER: "SET_CUSTOMER_FOR_ORDER",
  CLEAR_CUSTOMER_FOR_ORDER: "CLEAR_CUSTOMER_FOR_ORDER"
}

export const sellReducerInitialValues = {
  cart: [],
  holdOrderId: "",
  sellSearchRef: "",
  sellCategoryRef: "",
  reducer: () => {
  },
  selectedCustomer: {},
  activeProductForAddToCartModal: {}
}

export default (state, action) => {
  switch (action.type) {
    case sellReducerActions.REFRESH_CART:
      return {
        ...state,
        cart: action.cart.sort((a, b) => b.updated_timestamp - a.updated_timestamp),
        holdOrderId: action.holdOrderId || ""
      }

    case sellReducerActions.ADD_PRODUCT_TO_CART:
      const productInCart = findProductInCartToHandleMultipleUnitMeasurement(state.cart, action.product)
      
      if(!productInCart) {
        return {
          ...state,
          cart: [
            ...state.cart,
            {
              ...action.product, qty: 1, unique_id: uuid(), updated_timestamp: Date.now(),
              ...(!!action.product?.selected_unit_measurement && {unit: action.product?.selected_unit_measurement?.quantity})
            }
          ].sort((a, b) => b.updated_timestamp - a.updated_timestamp)
        }
      }
  
      const newCart = state.cart.map(product => {
        if (product?.unique_id === productInCart?.unique_id) {
          return {
            ...productInCart,
            ...action.product,
            unique_id: uuid(),
            ...(!action.product.updatedFromCartCard && {updated_timestamp: Date.now()}),
            ...(action.product.isWithoutModal && {
              unit: Number(product.unit) + 1,
              selling_price: Number(product.selling_price),
              sales_price: product.sales_price
            })
          }
        }
    
        return product
      }).sort((a, b) => b.updated_timestamp - a.updated_timestamp);
  
      return {...state, cart: newCart}
  
    case sellReducerActions.REMOVE_PRODUCT_FROM_CART:
      const newCartProducts = filterProductOutById(state.cart, action.productId)
      return {...state, cart: newCartProducts}
  
    case sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL:
      return {...state, activeProductForAddToCartModal: action.product}
  
    case sellReducerActions.CLEAR_CART:
      return {...state, cart: [], activeProductForAddToCartModal: {}, holdOrderId: "", selectedCustomer: {}}
  
    case sellReducerActions.SET_CUSTOMER_FOR_ORDER:
      return {...state, selectedCustomer: action.customer}
  
    default:
      return state
  }
}

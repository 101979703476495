import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllObjectKeysWithValues} from "../../../../utils/handleQueryParams";
import {
  approveSettlementService,
  getAllSettlementRequestLogsService
} from "../../../../services/staffBackendServices/settlementsServices";
import errorHandler from "../../../../utils/errorHandler";
import useGlobalContext from "../../../useContexts/useGlobalContext";


export const settlementQueryKeys = {
  all: ["settlements"],
  logs: (queryParams) => ["settlements", "logs", getAllObjectKeysWithValues(queryParams)]
}


export const useAllSettlementRequestLogsQuery = (queryParams = {}) => {
  return useQuery({
    queryKey: settlementQueryKeys.logs(queryParams),
    queryFn: () => getAllSettlementRequestLogsService(queryParams)
  })
}


export const useApproveSettlementMutation = ({ successFn }) => {
  const { toast } = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: (body) => approveSettlementService(body),
  
    onSuccess: () => {
      successFn()
      toast.success("Settlement Modified")
      queryClient.refetchQueries({ queryKey: settlementQueryKeys.all })
    },
  
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

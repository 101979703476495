import {
  CreateDeliveryRateModal,
  EditDeliveryRateModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import { AddCircle } from "iconsax-react";
import {useModal} from "../../../hooks";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useDeliveryRateQuery, useCreateDeliveryRateMutation,
  useDeleteDeliveryRateMutation,
  useEditDeliveryRateMutation, deliveryQueryKeys
} from "../../../hooks/useServices/useDeliveryRateServices";
import {Fragment, useState} from "react";
import {deliveryRateFormDefaultValues} from "../../../data/defaultFormValues";
import {TaxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {actionsPermissions} from "../../../data/permissions";


const DeliveryRate = () => {
  const { toast } = useGlobalContext()
  const [showCreateDeliveryRateModal, setShowCreateDeliveryRateModal] = useModal()
  const [showEditDeliveryRateModal, setShowEditDeliveryRateModal] = useModal()

  const { deliveryRateTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allDeliveryRates } = useSearchQuery(debounceState, useDeliveryRateQuery)
  const [editDeliveryRateFormValues, setEditDeliveryRateFormValues] = useState(deliveryRateFormDefaultValues)
  const deleteDeliveryRateMutation = useDeleteDeliveryRateMutation({ successFn: () => {} })
  const editDeliveryRateMutation = useEditDeliveryRateMutation({ successFn: setShowEditDeliveryRateModal })
  const createDeliveryRateMutation = useCreateDeliveryRateMutation({ successFn: setShowCreateDeliveryRateModal })

  const handleCreateDeliveryRate = (values) => {
    createDeliveryRateMutation.mutate(values)
  }

  const handleEditDeliveryRate = (values) => {
    editDeliveryRateMutation.mutate(values)
  }

  const handleEditAction = (deliveryRate) => {
    setEditDeliveryRateFormValues(deliveryRate)
    setShowEditDeliveryRateModal()
  }

  const handleDeleteAction = (deliveryRate) => {
    toast.confirm(
      "Are you sure?",
      deleteDeliveryRateMutation.mutate.bind(this, deliveryRate.id),
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateDeliveryRateModal && <CreateDeliveryRateModal onClose={setShowCreateDeliveryRateModal} handleSubmit={handleCreateDeliveryRate} mutation={createDeliveryRateMutation} />}
      {showEditDeliveryRateModal && <EditDeliveryRateModal onClose={setShowEditDeliveryRateModal} handleSubmit={handleEditDeliveryRate} mutation={editDeliveryRateMutation} formValues={editDeliveryRateFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>

        {allDeliveryRates.isEmpty && (
          <Fragment>
            <PageHeader title="Delivery" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No delivery"
              imgSrc={TaxImage}
              heading="No delivery rate yet"
              subHeading="Add delivery fare list"
              buttonText="Add new delivery"
              buttonClick={setShowCreateDeliveryRateModal}
              permissionCode={actionsPermissions.createDeliveryRate}
            />
          </Fragment>
        )}

        {allDeliveryRates.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateDeliveryRateModal} title="Delivery Rate" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.createDeliveryRate} />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search delivery rate by title"
              />

              <Table headers={deliveryRateTable.headers} noCols={deliveryRateTable.headers.length}>
                {allDeliveryRates.isQueryWithData && (
                  allDeliveryRates.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allDeliveryRates.currentCount}
                      keys={deliveryRateTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.updateDeliveryRate}
                      deletePermission={actionsPermissions.deleteDeliveryRate}
                    />
                  )
                )}

                {allDeliveryRates.isQueryWithNoData && (
                  <NoQueryData text={allDeliveryRates.isSearchEmptyMessage("Delivery Rate", "")} />
                )}


                {allDeliveryRates.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allDeliveryRates.showPagination &&
                <TablePagination
                  query={allDeliveryRates}
                  queryKey={deliveryQueryKeys.mainList(allDeliveryRates.queryValues)}
                />
              }
            </SetUpLayoutStyled>
  
            {allDeliveryRates.showPagination &&
              <TablePagination
                query={allDeliveryRates}
                queryKey={deliveryQueryKeys.mainList(allDeliveryRates.queryValues)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default DeliveryRate
import {Heading} from "../../styles/textStyles";
import {SectionStyled} from "../../styles/utilStyles";


const NoQueryData = ({ text }) => (
	<SectionStyled $center>
		<Heading size={1.2} color="secondary">{text}</Heading>
	</SectionStyled>
)

export default NoQueryData
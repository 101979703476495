import {
	CreateTableModal,
	EditTableModal,
	NoDataLayout,
	PageHeader,
	PageHeaderWithButton, PageSummaryCard, ScreenLoading,
	SearchAndFilter,
	Table, TableItemWithDeleteAndEditAction,
	TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import {AddCircle} from "iconsax-react";
import {SubcategoryImage} from "../../../../assets/images";
import {SetUpLayoutStyled} from "../SetUpStyles";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import {
	useCreateTableMutation,
	useTableQuery,
	useEditTableMutation,
	useDeleteTableMutation
} from "../../../../hooks/useServices/useTableServices";
import {tableFormDefaultValues} from "../../../../data/defaultFormValues";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {getTablesByStatus, getTotalTablesCapacity} from "../../../../utils/filters";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import generateId from "react-uuid";
import {actionsPermissions} from "../../../../data/permissions";


const Tables = () => {
	const { toast } = useGlobalContext()
	const { numberFormatter } = useDataTypeFormatter()
	const [showEditTableModal, setShowEditTableModal] = useModal()
	const [showCreateTableModal, setShowCreateTableModal] = useModal()
	const [editTableFormValues, setEditTableFormValues] = useState(tableFormDefaultValues)

	const { tableItemsTable } = tableHeadersAndValueKeys
	const { debounceState, debounceReducer } = useQueryDebounce()
	const { mainQuery, searchQuery, ...allTables } = useSearchQuery(debounceState, useTableQuery)
	const deleteTableMutation = useDeleteTableMutation({ successFn: () => {} })
	const editTableMutation = useEditTableMutation({ successFn: setShowEditTableModal })
	const createTableMutation = useCreateTableMutation({ successFn: setShowCreateTableModal })

	const handleCreateTable = (values) => {
		createTableMutation.mutate(values)
	}

	const availableTables = getTablesByStatus(allTables?.data?.results, "available")
	const occupiedTables = getTablesByStatus(allTables?.data?.results, "occupied")
	const totalTablesCapacity = getTotalTablesCapacity(allTables?.data?.results)

	const handleEditTable = (values) => {
		editTableMutation.mutate(values)
	}

	const handleEditAction = (table) => {
		setEditTableFormValues(table)
		setShowEditTableModal()
	}

	const handleDeleteAction = (table) => {
		if(table.status === "occupied") {
			toast.error("You cannot delete table while occupied")
			return
		}

		toast.confirm(
			"Are you sure?",
			deleteTableMutation.mutate.bind(this, table.id),
			generateId()
		)
	}

	return (
		<div>
			{showEditTableModal && <EditTableModal onClose={setShowEditTableModal} handleSubmit={handleEditTable} mutation={editTableMutation} formValues={editTableFormValues} />}
			{showCreateTableModal && <CreateTableModal onClose={setShowCreateTableModal} handleSubmit={handleCreateTable} mutation={createTableMutation} />}
			
			{!mainQuery.isSuccess && (
				<ScreenLoading isError={mainQuery.isError} refetch={mainQuery.refetch} />
			)}
			
			{mainQuery.isSuccess && (
				<Fragment>

					{allTables.isEmpty && (
						<Fragment>
							<PageHeader title="Tables" />
							<NoDataLayout
								imgWidth={32}
								imgAlt="No table"
								imgSrc={SubcategoryImage}
								heading="No tables yet"
								subHeading="Add table in your lounge/restaurant and manage the service for each table."
								buttonText="Add new table"
								buttonClick={setShowCreateTableModal}
								permissionCode={actionsPermissions.createTable}
							/>
						</Fragment>
					)}

					{!allTables.isEmpty && (
						<Fragment>
							<PageHeaderWithButton title="Tables" buttonText="Create" buttonClick={setShowCreateTableModal} Icon={AddCircle} permissionCode={actionsPermissions.createTable} />

							<PageSummaryStyled>
								<PageSummaryCard title="Total Number Of Tables" value={numberFormatter(allTables?.data?.results.length)} />
								<PageSummaryCard title="Available Tables" value={numberFormatter(availableTables.length)} />
								<PageSummaryCard title="Occupied Tables" value={numberFormatter(occupiedTables.length)} />
								<PageSummaryCard title="Total Tables Capacity" value={numberFormatter(totalTablesCapacity)} />
							</PageSummaryStyled>

							<SetUpLayoutStyled>
								<SearchAndFilter
									noFilter
									dispatch={debounceReducer}
									searchPlaceholder="Search table by  name, remark or status"
								/>

								<Table bg headers={tableItemsTable.headers} noCols={tableItemsTable.headers.length} >
									{allTables.isQueryWithData && (
										allTables?.data?.results.map((items, k) =>
											<TableItemWithDeleteAndEditAction
												key={k}
												data={items}
												statusPosition={2}
												count={k}
												pageCount={allTables.currentCount}
												editAction={handleEditAction}
												deleteAction={handleDeleteAction}
												keys={tableItemsTable.values}
												editPermission={actionsPermissions.updateTable}
												deletePermission={actionsPermissions.deleteTable}
											/>
										)
									)}

									{allTables.isQueryWithNoData && (
										<NoQueryData text={allTables.isSearchEmptyMessage("Table", "")} />
									)}

									{allTables.isQueryLoading && (
										<TableSkeleton />
									)}
								</Table>

								{!!allTables?.data?.next && <TablePagination />}
							</SetUpLayoutStyled>

							{!!allTables?.data?.next && <TablePagination />}
						</Fragment>
					)}

				</Fragment>
			)}
		</div>
	)
}

export default Tables;
import queryKeys from "./queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getUserProfileService, updateUserNotificationService} from "../../services/userProfileServices";
import useGlobalContext from "../useContexts/useGlobalContext";
import {useDesktopUserServiceFromLocalDB} from "../useDesktop/useDesktopServicesFromLocalDB";
import errorHandler from "../../utils/errorHandler";
import {
  getAuthTokenFromStorage,
  getIsDesktopOnlineStatus,
  setBusinessAccountIdInStorage,
  setUserIdInStorage
} from "../../utils";

const useUserProfile = () => {
  const {isOffline} = useGlobalContext()
  const offlineData = useDesktopUserServiceFromLocalDB()
  
  const onlineData = useQuery({
    enabled: (!isOffline && !!getAuthTokenFromStorage()),
    queryKey: queryKeys.USER_PROFILE,
    queryFn: getUserProfileService,
    retry: 2,
    onSuccess: (userProfile) => {
      setUserIdInStorage(userProfile?.[0]?.id || "")
      setBusinessAccountIdInStorage(userProfile?.[0]?.account)
    }
  })
  
  const fetch = () => {
    return (isOffline || getIsDesktopOnlineStatus() === "offline") ? offlineData : onlineData
  }
  
  return fetch()
}


const useUpdateUserNotificationMutation = () => {
  const queryClient = useQueryClient()
  const {toast} = useGlobalContext()
  
  return useMutation({
    mutationFn: ({id, body}) => {
      return updateUserNotificationService(id, body)
    },
    
    onSuccess: () => {
      toast.success("Notification Updated!!")
      queryClient.refetchQueries({queryKey: queryKeys.USER_PROFILE}).then().catch()
    },
    
    onError: (err) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export {useUserProfile, useUpdateUserNotificationMutation}
import {
  CreateCustomPaymentMethodModal, EditCustomPaymentMethodModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateCustomPaymentMethodMutation,
  useDeleteCustomPaymentMethodMutation,
  useEditCustomPaymentMethodMutation,
  useCustomPaymentMethodQuery
} from "../../../hooks/useServices/useCustomPaymentMethodServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {customPaymentMethodFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {BankImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";


const CustomPaymentMethod = () => {
  const { toast } = useGlobalContext()
  const [showCreateCustomPaymentMethodModal, setShowCreateCustomPaymentMethodModal] = useModal()
  const [showEditCustomPaymentMethodModal, setShowEditCustomPaymentMethodModal] = useModal()
  const {
    createCustomPaymentMethod: createCustomPaymentMethodPermission,
    updateCustomPaymentMethod: updateCustomPaymentMethodPermission,
    deleteCustomPaymentMethod: deleteCustomPaymentMethodPermission
  } = actionsPermissions

  const { customPaymentMethodTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allCustomPaymentMethods } = useSearchQuery(debounceState, useCustomPaymentMethodQuery)
  const [editCustomPaymentMethodFormValues, setEditCustomPaymentMethodFormValues] = useState(customPaymentMethodFormDefaultValues)
  const deleteCustomPaymentMethodMutation = useDeleteCustomPaymentMethodMutation({ successFn: () => {} })
  const editCustomPaymentMethodMutation = useEditCustomPaymentMethodMutation({ successFn: setShowEditCustomPaymentMethodModal })
  const createCustomPaymentMethodMutation = useCreateCustomPaymentMethodMutation({ successFn: setShowCreateCustomPaymentMethodModal })


  const handleCreateCustomPaymentMethod = (values) => {
    createCustomPaymentMethodMutation.mutate(values)
  }

  const handleEditCustomPaymentMethod = (values) => {
    editCustomPaymentMethodMutation.mutate(values)
  }

  const handleEditAction = (customPaymentMethod) => {
    setEditCustomPaymentMethodFormValues(customPaymentMethod)
    setShowEditCustomPaymentMethodModal()
  }

  const handleDeleteAction = (customPaymentMethod) => {
    toast.confirm(
      "Are you sure?",
      deleteCustomPaymentMethodMutation.mutate.bind(this, customPaymentMethod.id),
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateCustomPaymentMethodModal && <CreateCustomPaymentMethodModal onClose={setShowCreateCustomPaymentMethodModal} handleSubmit={handleCreateCustomPaymentMethod} mutation={createCustomPaymentMethodMutation} />}
      {showEditCustomPaymentMethodModal && <EditCustomPaymentMethodModal onClose={setShowEditCustomPaymentMethodModal} handleSubmit={handleEditCustomPaymentMethod} mutation={editCustomPaymentMethodMutation} formValues={editCustomPaymentMethodFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allCustomPaymentMethods.isEmpty && (
          <Fragment>
            <PageHeader title="Custom Payment Method" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={BankImage}
              heading="No custom payment method yet"
              buttonText="Add new payment method"
              buttonClick={setShowCreateCustomPaymentMethodModal}
              permissionCode={createCustomPaymentMethodPermission}
            />
          </Fragment>
        )}

        {allCustomPaymentMethods.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Custom Payment Method List"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateCustomPaymentMethodModal}
              permissionCode={createCustomPaymentMethodPermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search payment method by name"
              />

              <Table headers={customPaymentMethodTable.headers} noCols={customPaymentMethodTable.headers.length}>
                {allCustomPaymentMethods.isQueryWithData && (
                  allCustomPaymentMethods.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allCustomPaymentMethods.currentCount}
                      keys={customPaymentMethodTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateCustomPaymentMethodPermission}
                      deletePermission={deleteCustomPaymentMethodPermission}
                    />
                  )
                )}

                {allCustomPaymentMethods.isQueryWithNoData && (
                  <NoQueryData text={allCustomPaymentMethods.isSearchEmptyMessage("Custom payment method", "")} />
                )}

                {allCustomPaymentMethods.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allCustomPaymentMethods?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allCustomPaymentMethods?.data?.next && <TablePagination />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default CustomPaymentMethod
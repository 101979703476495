import {Heading} from "./textStyles";
import styled from "styled-components";
import {CartProductCardStyled, CategoryCardStyled} from "./cardStyles";
import {ButtonStyled, InputStyled} from "./formStyles";
import {AllCenterStyled, FlexStyled, ImageStyled, LineStyled, SectionStyled, SpaceBetweenStyled} from "./utilStyles";
import {GetStartedStyled} from "./DashboardStyles";


const SellLayoutStyled = styled("div")`
  position: relative;
  background-color: ${props => props.theme.background};
  
  .sell_navigation {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: calc(.5rem + 2px);
    width: 3vw;
    z-index: 99999999;
    box-shadow: 2px 2px 3px ${props => props.theme.shadow};
    
    
    ${FlexStyled} {
      margin-top: 1rem;
      justify-content: center;
    }
    
    div:last-child {
      text-align: center;
      width: 100%;
      margin-bottom: .5rem;
    }
  }
  
  main {
    padding: .5rem 1rem 1rem;
    height: 100%;
    min-height: calc(100vh - 1.5rem);
    position: relative;
    left: calc(3vw + 1rem);
    width: ${props => `calc(100% - 2rem - 3vw - ${(props.$mainWidth || "30")}%) `}
  }
  
 @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .sell_navigation {
      display: none;
    }
   
   main {
     left: 0;
     padding-top: 6rem;
     width: calc(100% - 2rem);
   }

   .cart_section {
     display: none;
     top: 0;
     position: fixed !important;
   }
 }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    .sell_navigation {
      display: none;
    }
  
    main {
      left: 0;
      padding-top: 6rem;
      width: 53% !important;
    }
  }
`

const SellStyled = styled("div")`
  position: relative;

  ${SpaceBetweenStyled}.layout_switch {
    svg {
      padding: .1rem;
      cursor: pointer;
    }

    svg.active {
      background: ${props => props.theme.gray};
    }
  }

  ${SectionStyled}.header_section {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    margin-top: 0;
    z-index: 99;
    height: 5.7vh;
    padding: 1rem .6rem;
    background-color: ${props => props.theme.background};
    width: calc(100% - 2rem - 3vw - 30% - 1.2rem) !important;
    
    ${Heading} {
      margin: 0;
    }
    
    .sell_arrow {
      position: relative;
      top: 0.25rem;
      right: .3rem;
    }

    form {
      width: 65%;
    }

    ${InputStyled} {
      margin-bottom: .7rem !important;
      input {
        padding: .6rem;
        border: none !important;
        background-color: white;
      }
    }
  }
  
  ${SectionStyled}.categories_section {
    ${CategoryCardStyled}:first-of-type {
      width: 5% !important;
    }
    
    top: calc(5.7vh + 1.2rem);
    position: fixed;
    margin-top: 0;
    height: 10vh;
    padding: .8rem 0 1.3rem;
    z-index: 99;
    background-color: ${props => props.theme.background};
    width: calc(100% - 2rem - 3vw - 30%) !important;
    
    > div {
      gap: .8rem;
      display: flex;
      //flex-wrap: wrap;
    }
  }
  
  .categories_section + ${LineStyled} {
    margin-top: 1rem;
  }
  
  .product_section {
    flex-wrap: wrap;
    gap: .8rem;
    position: relative;
    top: calc(5.7vh + 2rem + 11vh + 1.6rem);
    align-items: center;
    padding-bottom: 2rem;
    background: ${props => props.theme.background};
  
    ${GetStartedStyled} {
      width: 100%;
      > ${AllCenterStyled} {
        width: 100% !important;
        
        ${ButtonStyled} {
          width: 30%;
        }
      }
      
      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        ${ImageStyled} {
          width: 35% !important;
        }
  
        ${ButtonStyled} {
          width: 60% !important;
        }
      }
    }
  
  }
  
  .mobile_categories_section {
    display: none;
  }
  
  .mobile-only {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${SectionStyled}.header_section {
      position: relative;
      height: fit-content;
      padding: 1rem 0;
      width: 100% !important;
      flex-direction: column;
      
      form {
        margin-top: -2rem;
        width: calc(100% - 1.2rem);
      }
    }
    
    ${SectionStyled}.categories_section {
      display: none;
    }

    .mobile_categories_section {
      display: flex;
      gap: .3rem;
      padding: 1rem 0;
      
      > div {
        text-align: center;
        width: calc(33% - 0rem );
        padding: .8rem 0;
        background-color: white;
      }
    }
    
    
    ${FlexStyled}.product_section {
      top: 0;
      gap: .3rem;
    }

    .mobile-only {
      display: flex;
    }
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${SectionStyled}.header_section {
      height: 5vh;
      padding-bottom: 0;
      width: calc(53% - 1.5rem) !important;
      
      form {
        width: 80%;
      }
  
      ${InputStyled} {
        top: -.4rem;
      }
    }
  
    ${SectionStyled}.categories_section {
      height: fit-content !important;
      width: 53% !important;
      display: none;
    }
  
    .product_section {
      top: 0 !important;
    }
  }
`

const CartStyled = styled("section")`
  position: fixed;
  right: 0;
  top: 1rem;
  padding: 1rem;
  z-index: 999999999999;
  border-top-left-radius: .3rem;
  width: calc(28.5% - 3rem);
  height: calc(100vh - 2rem);
  background-color: white;
  border-left: 1px solid ${props => props.theme.shadow};

  #submit_checkout {
    display: none;
  }

  .cart_icon {
    cursor: pointer;
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(45% - 3rem);
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    top: 0;
    width: calc(100% - 2rem);
  }
  
  > ${Heading} {
    margin-bottom: 1rem;
  }
  
  ${SectionStyled} {
    margin-top: 0;
    overflow-y: scroll;
    height: calc(100vh - 7rem - 25vh);

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      height: calc(100vh - 1rem);
    }

    ${CartProductCardStyled}:last-of-type {
      margin-bottom: 35%;

      ${LineStyled} {
        display: none;
      }
    }
  }
`

const CartSummaryStyled = styled("section")`
  position: fixed;
  gap: .7rem .4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  bottom: 0;
  min-height: 20vh;
  max-height: calc(32vh - 2rem);
  width: calc(28% - 3rem);
  padding: 1rem 0;
  //z-index: 9999999999999999;
  border-top: 1px solid ${props => props.theme.shadow};
  background-color: white;
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(45% - 3rem);
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    left: 0;
    right: 0;
    padding: 1rem 1rem !important;
    width: calc(100% - 2rem) !important;
  }
  
  ${ButtonStyled} {
    padding: .8rem;
    background-color: ${props => props.theme.primary};
  }
  
  .submit_checkout {
    display: flex;
    gap: .7rem;
    justify-content: center;
    align-items: center;
    border: 1px;
    color: white;
    cursor: pointer;
    padding: .9rem 1.1rem;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: Bold, serif;
    width: calc(48% - 2.2rem);
    background-color: ${props => props.theme.primary};
    box-shadow: ${props => props.shadow ? "0 6.17828px 18.5348px 8px rgba(244, 168, 80, 0.3)" : "none"};
  }
  
  .checkout_form_actions ${ButtonStyled} {
    width: 47%;
  }
`

const EmptyCartStyled = styled(FlexStyled)`
  gap: 0;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`

const CartMobilePageStyled = styled("div")`
  ${CartStyled} {
    top: 6.5rem;
    position: relative;
    flex-direction: column;
    padding: 0 .5rem;
    width: calc(100% - 1rem);
    display: flex;
    border-left: 0;
    background-color: transparent;
    
    ${EmptyCartStyled} {
      height: 50vh;
    }
    
    ${SectionStyled} {
      overflow-x: hidden;
    }
    
    ${CartSummaryStyled} {
      padding: 1.5rem 1rem;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed !important;
      width: calc(100% - 2rem);
    }
    
    .modal {
      position: fixed !important;
      top: 0;
      bottom: 0;
      
      .cart_summary {
        position: fixed !important;
        //left: 2rem;
        right: 0;
        bottom: 0;
        width: calc(100% - 2rem);
        margin-top: 2rem;
        z-index: 9999999999999999999 !important;
      }
    }
  }
`


export const CategoryBasedLayoutStyled = styled("div")`
  width: 100%;
  top: calc(5.7vh + 1.2rem);
  position: absolute;
  padding: 1rem;
  
  ${FlexStyled}.products {
    width: 100%;
    background: pink;
    flex-wrap: wrap;
    gap: .8rem;
    position: relative;
    align-items: center;
    margin-top: 1.5rem;
    padding-bottom: 2rem;
    background: ${props => props.theme.background};
  
    ${GetStartedStyled} {
      width: 100%;
      > ${AllCenterStyled} {
        width: 100% !important;
        
        ${ButtonStyled} {
          width: 30%;
        }
      }
      
      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        ${ImageStyled} {
          width: 35% !important;
        }
  
        ${ButtonStyled} {
          width: 60% !important;
        }
      }
    }
  
  }
`

export {
  CartStyled,
  SellStyled,
  EmptyCartStyled,
  CartMobilePageStyled,
  SellLayoutStyled,
  CartSummaryStyled
}
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const getProductionUnitMeasurementService = async (query) => {
  try {
    const url = handleQueryParams("/unit_measurement", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const createProductionUnitMeasurementService = async (body) => {
  try {
    const { data } = await axios.post(`/unit_measurement/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateProductionUnitMeasurementService = async (id, body) => {
  try {
    const { data } = await axios.patch(`/unit_measurement/${id}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const deleteProductionUnitMeasurementService = async (id) => {
  try {
    const { data } = await axios.delete(`/unit_measurement/${id}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
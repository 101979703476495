import {AllCenterStyled} from "../../styles/utilStyles";
import {Button, Image} from "../index";
import {Heading2, Paragraph} from "../../styles/textStyles";
import {GetStartedStyled} from "../../styles/DashboardStyles";


const NoDataLayout = ({ imgSrc, imgWidth, imgAlt, heading, subHeading, buttonText, buttonClick, permissionCode, button2Text, button2Click }) => {
  return (
    <GetStartedStyled noData={true}>
      <AllCenterStyled>
        <Image src={imgSrc} width={imgWidth} alt={imgAlt} />
        <Heading2 color="black">{heading}</Heading2>
        <Paragraph size={1.1} color="textLightBlue" noTop>{subHeading}</Paragraph>
        {!!buttonText && <Button permissionCode={permissionCode} text={buttonText} onClick={buttonClick}/>}
        {!!button2Text && <Button permissionCode={permissionCode} text={button2Text} onClick={button2Click}/>}
      </AllCenterStyled>
    </GetStartedStyled>
  )
}

export default NoDataLayout
import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getDeliveryService, packageDeliveryService} from "../../../services/deliveryServices";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";


const useDeliveryByBusinessIdQuery = (searchValue) => {
	scrollToTop()
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery({
		queryKey: [queryKeys.DELIVERIES, businessId, searchValue],
		queryFn: () => getDeliveryService(businessId, searchValue)
	})
}

const usePackageDeliveryMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()

	const businessId = getBusinessId()

	return useMutation({
		mutationFn: data => {
			return packageDeliveryService({ ...data, business: businessId })
		},

		onSuccess: () => {
			successFn()
			toast.success("Delivery Package Created!!")
			queryClient.invalidateQueries({ queryKey: [queryKeys.DELIVERIES, businessId, ""] })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}


export {
	useDeliveryByBusinessIdQuery, usePackageDeliveryMutation
}
import {StoreFrontViewProductLayoutStyled} from "../../../../styles/StoreFrontStyles/storeFrontLayoutStyles";
import {Link, useParams} from "react-router-dom";
import {useProductByIdQuery} from "../../../../hooks/useServices/useProductServices";
import {getBusinessIdFromStorage} from "../../../../utils";
import {FlexColumnStyled, FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Image, Input} from "../../../../components";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {findProductInArray} from "../../../../utils/sell";
import useSellContext from "../../../../hooks/useContexts/useSellContext";
import {sellReducerActions} from "../../../../reducers/sellReducer";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import handleQueryParams from "../../../../utils/handleQueryParams";
import {ProductCardCountStyled} from "../../../../styles/cardStyles";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {useState} from "react";


const StoreFrontViewProductScreen = () => {
  const { productId, storeName } = useParams()
  const businessSettings = useBusinessSettingQuery()
  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  const { data: product, isSuccess: isProductSuccess } = useProductByIdQuery(productId, { business_id: getBusinessIdFromStorage() })
  
  const { toast } = useGlobalContext()
  const { reducer, cart, activeProductForAddToCartModal } = useSellContext()
  const { stock_unit, unit_increment, unit } = activeProductForAddToCartModal
  const [productCount, setProductCount] = useState(Number(unit || unit_increment || 1))
  
  
  const isProductInCart = findProductInArray(cart, product?.id)
  
  const handleAddProductToCart = () => {
    reducer({
      product: { ...product, unit: productCount || 1 },
      type: sellReducerActions.ADD_PRODUCT_TO_CART
    })
    
    setProductCount(1)
    toast.success("Product added to cart", "added_to_cart")
  }
  
  const handleRemoveProductFromCart = () => {
    reducer({
      productId: product.id,
      type: sellReducerActions.REMOVE_PRODUCT_FROM_CART,
    })
    
    toast.success("Product removed from cart", "removed_to_cart")
  }
  
  const handleViewCategory = () => {
    const searchParams = { category_id: product.category, category_name: product.category_name.split(" ").join("-") }
    return handleQueryParams(`/${storeName}`, searchParams)
  }
  
  const handleIncrement = (result) => {
    if(Number(result) > Number(stock_unit)) {
      if(!businessSettings?.sell_below_stock && activeProductForAddToCartModal.use_stock) {
        toast.error("You have exceeded the stock available", "prevent_stock_increment")
        return
      }
    }
    
    setProductCount(Number(result))
  }
  
  const handleDynamicCountChange = (value) => {
    if(Number(value) > Number(stock_unit)) {
      if(!businessSettings?.sell_below_stock && activeProductForAddToCartModal.use_stock) {
        toast.error("You have exceeded the stock available", "prevent_stock_increment")
        return
      }
    }
    
    setProductCount(Number(value))
  }
  
  const disableProductIncrement = () => {
    if(!activeProductForAddToCartModal.use_stock) return false
    if(stock_unit >= productCount) return false
    
    return !businessSettings?.sell_below_stock;
  }
  
  
  // const ProductUnitModify = () => {
  //   const handleIncrement = (result) => {
  //     if(Number(result) > Number(stock_unit)) {
  //       if(!businessSettings?.sell_below_stock && activeProductForAddToCartModal.use_stock) {
  //         toast.error("You have exceeded the stock available", "prevent_stock_increment")
  //         return
  //       }
  //     }
  //
  //     setProductCount(Number(result))
  //   }
  //
  //   const handleDynamicCountChange = (value) => {
  //     if(Number(value) > Number(stock_unit)) {
  //       if(!businessSettings?.sell_below_stock && activeProductForAddToCartModal.use_stock) {
  //         toast.error("You have exceeded the stock available", "prevent_stock_increment")
  //         return
  //       }
  //     }
  //
  //     setProductCount(Number(value))
  //   }
  //
  //   const disableProductIncrement = () => {
  //     if(!activeProductForAddToCartModal.use_stock) return false
  //     if(stock_unit >= productCount) return false
  //
  //     return !businessSettings?.sell_below_stock;
  //   }
  //
  //   return (
  //     <ProductCardCountStyled>
  //       <Button onClick={handleIncrement.bind(this, productCount - Number(unit_increment || 1))} text="-" disabled={productCount === Number(unit_increment || 1)}/>
  //       <Form enableReinitialize values={{ count: productCount, count_format: productCount }}>
  //         <Input disabled={productCount === stock_unit} type="number" name="count" onChange={handleDynamicCountChange} />
  //       </Form>
  //       <Button onClick={handleIncrement.bind(this, productCount + Number(unit_increment || 1))} text="+" disabled={disableProductIncrement()}/>
  //     </ProductCardCountStyled>
  //   )
  // }
  
  return (
    <StoreFrontViewProductLayoutStyled>
      {isProductSuccess && (
        <FlexStyled $gap={6}>
          <Image src={product?.image_path} alt={product?.name} />
          <FlexColumnStyled $gap={2.5}>
            <Heading color="black" size={2.5} bold>{product.name}</Heading>
            <Paragraph color="black" bold noTop size={1.3}>{nairaCurrencyFormatter(product.selling_price)}</Paragraph>
            
            <Paragraph color="secondary" noTop size={1.2} onClick={handleViewCategory}>Category:
              <Link to={handleViewCategory()}>{product.category_name}</Link>
            </Paragraph>
            <Paragraph color="secondary" noTop size={1.2}>Measurement: {product.unit_measurement}</Paragraph>
  
            <ProductCardCountStyled>
              <Button onClick={handleIncrement.bind(this, productCount - Number(unit_increment || 1))} text="-" disabled={productCount === Number(unit_increment || 1)}/>
              <Form enableReinitialize values={{ count: productCount, count_format: productCount }}>
                <Input disabled={productCount === stock_unit} type="number" name="count" onChange={handleDynamicCountChange} />
              </Form>
              <Button onClick={handleIncrement.bind(this, productCount + Number(unit_increment || 1))} text="+" disabled={disableProductIncrement()}/>
            </ProductCardCountStyled>
  
            <Button
              text={isProductInCart ? "REMOVE FROM CART" : "ADD TO CART"}
              onClick={isProductInCart ? handleRemoveProductFromCart : handleAddProductToCart}
            />
          </FlexColumnStyled>
        </FlexStyled>
      )}
    </StoreFrontViewProductLayoutStyled>
  )
}


export default StoreFrontViewProductScreen
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createNestedSubCategoryService,
  deleteNestedSubCategoryService,
  editNestedSubCategoryService,
  getNestedSubCategoriesService
} from "../../../services/nestedSubCategoryServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {useLocation} from "react-router-dom";
import {useProgressModalConnector} from "../../useModalConnector";
import modalNames from "../../useModalConnector/modalNames";
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";


const mainKey = ["nested-subcategory", getBusinessIdFromStorage()]

export const nestedSubCategoryQueryKeys = {
  all: [...mainKey],
  main: (queryParam = {}) => [...mainKey, getAllObjectKeysWithValues(queryParam)]
}

const useNestedSubCategoriesQuery = (queryParams) => {
  const { businessId } = useGlobalContext()

  return useQuery({
    queryKey: nestedSubCategoryQueryKeys.main(queryParams),
    queryFn: () => getNestedSubCategoriesService({ business_id: businessId, ...queryParams }),
  })
}

const useCreateNestedSubCategoryMutation = ({ successFn }) => {
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const progressModalConnector = useProgressModalConnector()
  const { toast, getBusinessId, globalState } = useGlobalContext()

  
  return useMutation({
    mutationFn: data => {
      return createNestedSubCategoryService({ ...data, business: getBusinessId() })
    },

    onSuccess: (res, variables) => {
      successFn()
      toast.success("Nested SubCategory Created!!")
      
      queryClient.refetchQueries({
        queryKey: nestedSubCategoryQueryKeys.main({
          level: variables.level,
          ...(Number(variables.level) === 1 ? { sub_category: variables.sub_category } : { parent: variables.parent })
        })
      })

      if(globalState.modalConnector.toModalURL === pathname) {
        progressModalConnector({
          toModalName: modalNames.CREATE_SUBCATEGORY_MODAL,
          toModalURL: `/dashboard/set-up/categories/${res.name}/${res.id}`
        })
      }
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditNestedSubCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editNestedSubCategoryService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.refetchQueries({ queryKey: nestedSubCategoryQueryKeys.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteNestedSubCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: categoryId => {
      return deleteNestedSubCategoryService(categoryId)
    },

    onSuccess: (res, variables) => {
      successFn()
      toast.success("Delete Successful!!")

      queryClient.refetchQueries({
        queryKey: nestedSubCategoryQueryKeys.main({
          level: variables.level,
          ...(Number(variables.level) === 1 ? { sub_category: variables.sub_category } : { parent: variables.parent })
        })
      })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useNestedSubCategoriesQuery, useCreateNestedSubCategoryMutation, useEditNestedSubCategoryMutation, useDeleteNestedSubCategoryMutation }
import {useModal} from "../../hooks";
import {ArrowDown2, ArrowUp2} from "iconsax-react";
import {WIPStatus} from "../../utils/table";
import {AddCustomerModal, Button, Image, Line} from "../index";
import {NoProductImage} from "../../assets/images";
import {Heading, Paragraph} from "../../styles/textStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {TableItemOrderAndWIPStyled, WIPDropDownStyled, WIPTableStyled} from "../../styles/tableOrderAndWIPStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useAssignStaffToWipMutation, useCompleteWipMutation} from "../../hooks/useServices/useWipServices";
import {Fragment, useState} from "react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {actionsPermissions} from "../../data/permissions";
import {Link} from "react-router-dom";


const TableItemOrderAndWIP = ({order}) => {
	const {toast} = useGlobalContext()
	const {timeFormatter, currencyFormatter} = useDataTypeFormatter()
	const [isDropDown, setIsDropDown] = useModal()
	const [activeStage, setActiveStage] = useState({})
	const [showAssignStaff, setShowAssignStaff] = useModal()
	const assignStaffToWipMutation = useAssignStaffToWipMutation({successFn: setShowAssignStaff})
	const completeWipMutation = useCompleteWipMutation({
		successFn: () => {
		}
	})
	
	
	const handleAssignStaffToWip = (values) => {
		assignStaffToWipMutation.mutate(
			{id: activeStage.id, data: {...values, status: "processing", service: activeStage.service}}
		)
	}
	
	const handleAssignStaffModal = (stage, serviceId) => {
		setActiveStage({...stage, service: serviceId})
		setShowAssignStaff()
	}

	const handleCompleteWip = (wipId, serviceId) => {
		toast.confirm(
			"Are you sure you want to mark stage as complete?",
			() => completeWipMutation.mutate(
				{ id: wipId, data: {status: "completed", service: serviceId} }
			)
		)
	}

	return (
		<TableItemOrderAndWIPStyled>
			{showAssignStaff && <AddCustomerModal values={activeStage} stage_name={activeStage.stage_name} onClose={setShowAssignStaff} mutation={assignStaffToWipMutation} handleSubmit={handleAssignStaffToWip} />}
			
			<SpaceBetweenStyled className="order_item">
				<FlexStyled className="image_and_name">
					<Image width={25} src={order?.product.image_path || NoProductImage} alt="order"/>
					<Link to={`/dashboard/inventory/product/${order?.product?.id}`}>
						<Heading color="black">{order?.product.name} {order?.complimentary &&
							<Paragraph noTop color="error"> Complimentary</Paragraph>}</Heading>
					</Link>
				</FlexStyled>
				
				{/*<FlexStyled className="order_id">*/}
				{/*	<Heading color="black">ID:</Heading>*/}
				{/*	<Heading color="secondary">*/}
				{/*		<span style={{fontSize: 17}}> {order?.order_id.slice(0, 7)}... </span>*/}
				{/*		<Copy*/}
				{/*			color="black"*/}
				{/*			size={15}*/}
				{/*			variant="Bold"*/}
				{/*			id={`copy_${order?.order_id}`}*/}
				{/*			onClick={() => handleCopyToClipBoard({*/}
				{/*				toast,*/}
				{/*				text: order?.order_id,*/}
				{/*				successMessage: "Group Order ID Copied"*/}
				{/*			})}*/}
				{/*		/>*/}
				{/*		*/}
				{/*		<Tooltip*/}
				{/*			anchorId={`copy_${order?.order_id}`}*/}
				{/*			place="top" variant="dark"*/}
				{/*			content="Copy order group id"*/}
				{/*		/>*/}
				{/*	</Heading>*/}
				{/*</FlexStyled>*/}
				
				<FlexStyled className="quantity">
					<Heading color="black">Quantity:</Heading>
					<Heading color="secondary">
						{!order?.meta_measurement?.name && `${order?.qty}`}
						{!!order?.meta_measurement?.name && `${order?.meta_measurement?.quantity} ${order?.meta_measurement?.name}`}
					</Heading>
				</FlexStyled>
				
				{!(order?.returned && order?.return_type === "order") && (
					<div style={{textAlign: "right"}}>
						<Heading bold color="black">Amount:</Heading>
						
						<FlexColumnStyled $gap={.5} className="total_amount">
							<Heading color="secondary">
								Cost: {currencyFormatter(Number(order?.product_cost_before_tax) || Number(order?.product_cost))}
							</Heading>
							
							{Number(order?.discount_amount) > 0 && (
								<Heading color="secondary">Discount: -{currencyFormatter(order?.discount_amount)}</Heading>
							)}
							
							{Number(order?.tax_amount) > 0 && (
								<Heading color="secondary">
									Tax: +{currencyFormatter(order?.tax_amount)}
								</Heading>
							)}
							
							<Heading bold color="secondary">Total: {currencyFormatter(order?.total_cost)}</Heading>
						</FlexColumnStyled>
					</div>
				)}

				{!!order?.set_for_delivery && (
					<FlexStyled>
						<Heading color="black">Delivery Status:</Heading>
						<Heading color="" size={.8} className="pending">{order?.delivery_status}</Heading>
					</FlexStyled>
				)}
				
				{(order?.returned && order?.return_type === "order") && (
					<FlexStyled>
						<Heading size={1} className="error">Returned</Heading>
					</FlexStyled>
				)}
				

				{order?.service?.length > 0 && (
					<Fragment>
						{isDropDown && <ArrowUp2 color="black" size={22} variant="Bulk" onClick={setIsDropDown} />}
						{!isDropDown && <ArrowDown2 color="black" size={22} variant="Bulk" onClick={setIsDropDown} />}
					</Fragment>
				)}
			</SpaceBetweenStyled>


			{isDropDown && (
				order?.service?.map(service => (
					<WIPDropDownStyled key={service?.id}>
						<Line />

						<FlexStyled className="wip_heading">
							<Image height={85} src={order?.product.image_path || NoProductImage} alt={order?.product.name} />
							<div>
								<Heading className="wip_title" color="black" size={1.1}>
									WIP :
									<Paragraph color="secondary">{service?.service_name}</Paragraph>
								</Heading>
							</div>
						</FlexStyled>

						<WIPTableStyled>
							{service?.wip?.map(wip => (
								<tr key={wip.stage_name}>
									<td className="name">{wip?.stage_name}</td>
									<td>
										{!!wip.staff ? wip?.staff_name : "No Staff assigned"}
									</td>
									{WIPStatus(wip.status)}
									<td>{timeFormatter(wip.date_created)}</td>
									{wip.status !== "completed" && (
										<Fragment>
											<td>
												<Button permissionCode={actionsPermissions.assignStaffToWip} text={!!wip.staff ? "Re-assign Staff" : "Assign Staff"} onClick={() => handleAssignStaffModal(wip, service?.service_id)} />
											</td>
											<td>
												<Button
													disabled={!wip.staff}
													text="Mark complete"
													permissionCode={actionsPermissions.updateWip}
													onClick={() => handleCompleteWip(wip.id, service?.service_id)}
												/>
											</td>
										</Fragment>
									)}
								</tr>
							))}
						</WIPTableStyled>
					</WIPDropDownStyled>
				))
			)}
		</TableItemOrderAndWIPStyled>
	)
}

export default TableItemOrderAndWIP
import {Field, useFormikContext} from "formik";
import { CheckBoxStyled } from "../../styles/formStyles";
import {childrenPropToArray} from "../../utils/forms";
import {useContext, useState} from "react";
import {FormProvider} from "./Form";


const RolesCheckBox = ({ className, name, optional, labelProp, afterCheck, noBottomMargin, checked = false }) => {
  const { setFieldValue, values } = useFormikContext()
  const childrenArray = childrenPropToArray(labelProp)
  const [checkedState, setCheckedState] = useState(values[name] ?? checked)
  const { allFieldsNotRequired } = useContext(FormProvider)

  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }

  const handleChange = (e) => {
    setFieldValue(name, e.target.checked)
    afterCheck?.(e.target.checked, name)
    setCheckedState(e.target.checked)
  }

  return (
    <CheckBoxStyled className={className} $noBottomMargin={noBottomMargin}>
      <Field checked={checkedState} onChange={handleChange} type="checkbox" name={name} id={name} required={isRequired()} />
      <label htmlFor={name}>
        {childrenArray}
      </label>
    </CheckBoxStyled>
  )
}

export default RolesCheckBox
export default {
  "718fefc6-585d-4feb-9aeb-0bc1bb0be4c3": [
    {
      "id": "04a48408-5682-4571-ae62-a7e8cd5d60fb",
      "name": "Abuja",
      "country": "718fefc6-585d-4feb-9aeb-0bc1bb0be4c3"
    },
    {
      "id": "fd737413-fbcb-475d-8362-910fc1d1d27c",
      "name": "Lagos",
      "country": "718fefc6-585d-4feb-9aeb-0bc1bb0be4c3"
    }
  ],

  "f9b08a42-eb0f-404b-aa59-efa0ccc26a26": [
    {
      "id": "f3e37b09-6a64-4ce9-9cab-98f01e0f1804",
      "name": "Accra",
      "country": "f9b08a42-eb0f-404b-aa59-efa0ccc26a26"
    }
  ]
}
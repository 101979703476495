import {useMutation, useQuery, useQueryClient} from "react-query";
import {createTipService, deleteTipService, getTipsService} from "../../../services/tipsServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import errorHandler from "../../../utils/errorHandler";


export const tipsQueryKey = {
  all: ["tips"],
  mainList: (queryParams) => ["tips", queryParams]
}


export const useTipQuery = (queryParams = {}) => {
  const query = { from_date: queryParams.fromDateValue, to_date: queryParams.toDateValue, staff_id: queryParams.staffValue }
  
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: tipsQueryKey.mainList(getAllObjectKeysWithValues(query)),
    queryFn: () => getTipsService({ business_id: getBusinessId(), ...query })
  })
}


export const useCreateTipMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return createTipService({ business: getBusinessId(), ...data })
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Tip Added!!")
      queryClient.refetchQueries({ queryKey: tipsQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useCancelTipMutation = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: tipId => {
      return deleteTipService(tipId)
    },
    
    onSuccess: () => {
      toast.success("Tip Cancelled!!")
      queryClient.refetchQueries({ queryKey: tipsQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
import {Fragment, memo, useEffect, useState} from "react";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, Select} from "../../../index";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {unitMeasurementOptions} from "../../../../data/selectField/product";
import {useModal} from "../../../../hooks";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {useFormikContext} from "formik";
import {useFromModalConnector} from "../../../../hooks/useModalConnector";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {globalReducerActions} from "../../../../reducers/globalReducer";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import uuid from "react-uuid";
import {handleFormatCustomUnitMeasurementForFormSubmission} from "../../../../utils/products";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {
  useProductionUnitMeasurementQuery
} from "../../../../hooks/useServices/useProductionServices/useProductionUnitMeasurementServices";


const ManageFaultyProductModal = ({onClose, handleSubmit, mutation, formValues}) => {
  const businessSettings = useBusinessSettingQuery()
  
  const {toast, globalState, globalReducer} = useGlobalContext()
  const [isShowCommission] = useModal(!!globalState.modalConnector.fromModalFormValues.category || !!formValues?.commission_value)
  
  
  const [showExtraMeasurement, setShowExtraMeasurement] = useModal()
  
  const {data: unitMeasurement} = useProductionUnitMeasurementQuery()
  
  
  const handleBeforeSubmission = (values) => {
    if (!isShowCommission) {
      values.commission_value = 0
    }
    
    if ((isShowCommission && !values.commission_value) || isShowCommission && !values.commission_type) {
      toast.error("Please input both commission type and value")
      return
    }
    
    const formValues = {...values, product_attachment: []}
    formValues?.unit_measurement_ids?.forEach(id => {
      delete formValues[`unit_measurement_name_${id}`]
    })
    
    const {meta_measurement, faulty_selling_price} = handleFormatCustomUnitMeasurementForFormSubmission(formValues)
    
    handleSubmit({
      id: formValues.id,
      type: "general_product",
      faulty_selling_price,
      faulty_meta_measurement: meta_measurement
    })
  }
  
  const handleClose = () => {
    onClose()
    globalReducer({
      type: globalReducerActions.CLEAR_MODAL_CONNECTOR
    })
  }
  
  const AddUnitMeasurement = () => (
    <label className="underline"
           onClick={setShowExtraMeasurement}>{showExtraMeasurement ? "Remove All -" : "Add More +"}</label>
  )
  
  const getProductFormValues = () => {
    if (!businessSettings.use_sales_margin) return formValues
    
    return {
      ...formValues,
      sales_margin: Number(((Number(formValues.selling_price) - Number(formValues.cost_price)) / Number(formValues.cost_price)) * 100),
      sales_margin_format: Number(Number(((Number(formValues.selling_price) - Number(formValues.cost_price)) / Number(formValues.cost_price)) * 100))
    }
  }
  
  return (
    <ModalLayoutStyled onClick={handleClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={handleClose}/>
        <Heading size={1.3} color="black">Manage Faulty Product</Heading>
        
        <Form values={getProductFormValues()} modalName={modalNames.EDIT_PRODUCT_MODAL}
              onSubmit={handleBeforeSubmission} allFieldsNotRequired>
          <Fragment>
            <Input className="fullWidth" type="number" formatNumber label="Selling Price"
                   placeholder="Enter selling price"
                   name="faulty_selling_price"/>
            
            <CustomerUnitMeasurement unitMeasurements={[...(unitMeasurement || []), ...unitMeasurementOptions]}
                                     attachments={formValues?.faulty_measurement_ids}
                                     showExtraMeasurement={formValues?.faulty_measurement_ids && formValues?.faulty_measurement_ids?.length === 0}/>
            
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text="Save" type="submit"/>
            </FlexStyled>
          </Fragment>
        </Form>
      
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default ManageFaultyProductModal

const CustomerUnitMeasurement = memo(({attachments = [], showExtraMeasurement, unitMeasurements = []}) => {
  const {setFieldValue, values} = useFormikContext()
  const {currencyFormatter} = useDataTypeFormatter()
  const fromModalConnector = useFromModalConnector()
  
  const handleCreateUnitMeasurement = () => {
    fromModalConnector({
      fromModalFormValues: values,
      toModalURL: "/dashboard/set-up/unit-measurement/",
      toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
      fromModalName: modalNames.CREATE_PRODUCT_MODAL,
    })
  }
  
  const CreateUnitMeasurementComp = () => {
    return <label className="underline" onClick={handleCreateUnitMeasurement}>Create +</label>
  }
  
  const handleSetCostPriceForUnitMeasurement = (id = "main", quantity, _, product) => {
    const totalCostForUnitMeasurement = Math.ceil(Number(product.cost_price) * Number(quantity))
    setFieldValue(`unit_measurement_cost_price_${id}`, `Cost Price: ${currencyFormatter(totalCostForUnitMeasurement)}`)
  }
  
  const ProductionAttachmentForm = (newId) => {
    const id = newId || uuid()
    
    const SelectComp = () => (
      <Select
        SideInfo={CreateUnitMeasurementComp}
        options={unitMeasurements}
        displayKey="name"
        valueKey="name"
        label="Unit Measurement"
        placeholder="Select unit measurement"
        name={`unit_measurement_name_${id}`}
        displayName={`unit_measurement_display_${id}`}
      />
    )
    
    const InputComp = () => (
      <Input
        type="number"
        formatNumber
        name={`unit_measurement_quantity_${id}`}
        label="Quantity"
        placeholder="Enter quantity"
        onChange={handleSetCostPriceForUnitMeasurement.bind(this, id)}
      />
    )
    
    const InputComp2 = ({values}) => (
      <Input
        type="number"
        formatNumber
        name={`unit_measurement_price_${id}`}
        label="Sales Price"
        placeholder="Enter sales price per measurement"
        error={values[`unit_measurement_cost_price_${id}`]}
      />
    )
    
    return {id, SelectComp, InputComp, InputComp2}
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, ProductionAttachmentForm()])
  }
  
  useEffect(() => {
    if (!!attachments && attachments.length > 0) {
      const attachmentStructure = attachments.map(id => {
        return ProductionAttachmentForm(id)
      })
      
      setMoreProducts(attachmentStructure)
    }
  }, [attachments])
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`unit_measurement_name_${id}`, "")
    setFieldValue(`unit_measurement_display_${id}`, "")
    setFieldValue(`unit_measurement_quantity_${id}`, "")
    setFieldValue(`unit_measurement_price_${id}`, "")
  }
  
  return (
    <Fragment>
      {(showExtraMeasurement || moreProducts?.length > 0) && (
        <div className="fullWidth add_extra_3">
          {showExtraMeasurement && (
            <FlexStyled className="fullWidth three_form_cols space_between">
              <Select
                SideInfo={CreateUnitMeasurementComp}
                options={unitMeasurements}
                displayKey="name"
                valueKey="name"
                label="Unit Measurement"
                placeholder="Select unit measurement"
                name="unit_measurement_name"
                displayName="unit_measurement_display"
              />
              
              <Input
                type="number"
                formatNumber
                name="unit_measurement_quantity"
                label="Quantity"
                placeholder="Enter quantity per measurement"
                onChange={handleSetCostPriceForUnitMeasurement.bind(this, "main")}
              />
              
              <Input
                type="number"
                formatNumber
                name="unit_measurement_price"
                label="Sales Price"
                placeholder="Enter sales price per measurement"
                error={values[`unit_measurement_cost_price_main`]}
              />
            </FlexStyled>
          )}
          
          <Fragment>
            {moreProducts?.map(({id, SelectComp, InputComp, InputComp2}) => (
              <FlexStyled className="fullWidth three_form_cols" key={id}>
                <SelectComp/>
                <InputComp/>
                <InputComp2 values={values}/>
                <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)}/>
              </FlexStyled>
            ))}
            
            <Button onClick={addExtraProduct} type="button" text="Add more measurement" className="small add_extra"
                    small/>
            <br/>
          </Fragment>
        
        </div>
      )}
    </Fragment>
  )
})
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import { FlexStyled } from "../../../../styles/utilStyles";
import {Button, Form, Input, PhoneInput, Select} from "../../../index";
import {useCountries} from "../../../../hooks/useServices/formFieldHooks";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import useFormWithCountryAndState from "../../../../hooks/useFormWithCountryAndState";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const EditSupplierModal = ({ onClose, handleSubmit, mutation, formValues }) => {
	const { data: countries } = useCountries()
	const { isSuccess, data: allCategories } = useCategoriesQuery()
	const [additionalDetails, setAdditionalDetails, updateCountry] = useFormWithCountryAndState()
	
	
	return (
		<ModalLayoutStyled onClick={onClose}>
			<InventoryModalStyled $width={60} onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Edit Supplier</Heading>
				
				<Form
					values={formValues}
					onSubmit={handleSubmit}
					additionalData={additionalDetails}
					setAdditionalData={setAdditionalDetails}
				>
					<Input label="Name" placeholder="Enter name" name="name"  />
					<PhoneInput label="Phone" placeholder="Enter phone number" name="phone" />
					
					<Input label="Email" optional type="email" placeholder="Enter a valid email" name="email" />
					<Input label="Address" optional type="address" placeholder="Enter House address" name="address"  />
					<Select
						optional
						options={isSuccess ? allCategories?.results : []}
						label="Category"
						placeholder="Select Category"
						name="category"
						valueKey="id"
						displayKey="name"
						displayName="category_name"
					/>
					<Select
						optional
						options={countries}
						displayKey="name"
						valueKey="id"
						name="country"
						displayName="country_name"
						label="Country"
						updateFn={updateCountry}
						placeholder="Select Country"
					/>
					<Input optional label="Contact Person" placeholder="Enter your contact name" name="contact_person"  />
					<PhoneInput optional label="Contact Phone" placeholder="Enter contact phone" name="contact_phone"  />
					
					<FlexStyled>
						<Button text="Save" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>
			
			</InventoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default EditSupplierModal
import {useModal} from "../../hooks";
import {Heading, Paragraph} from "../../styles/textStyles";
import {EmptyBoxImage} from "../../assets/images";
import {sellReducerActions} from "../../reducers/sellReducer";
import useSellContext from "../../hooks/useContexts/useSellContext";
import {AddToCartModal, Button, CartProductCard, CheckBox, Form, Image} from "../index";
import {SectionStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {CartStyled, CartSummaryStyled, EmptyCartStyled} from "../../styles/sellStyles";
import { useReturnCartCalculations } from "../../hooks/useUtils/useCartCalculations";
import {currencyFormatterFn} from "../../utils/dataTypeFormatter";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import {getTotalActiveTaxPercentage} from "../../hooks/useUtils/useTaxCalculation";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {
  useModifyOrderMutation,
  useReturnSingleGroupOrderByGroupIdMutation
} from "../../hooks/useServices/useOrderServices";
import {CustomDiscount} from "../Modal/SellModals/CheckOutModal";
import {memo, useEffect, useState} from "react";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useFormikContext} from "formik";


const ReturnOrderCart = ({ groupOrderDetails, onClose, mainOrders = []  }) => {
  const { toast } = useGlobalContext()
  const { cart, reducer } = useSellContext()
  const { numberFormatter } = useDataTypeFormatter()
  const businessSettings = useBusinessSettingQuery()
  const [currentMaxUnit, setCurrentMaxUnit] = useState(0)
  const [addToCartModal, setAddToCartModal] = useModal()
  
  const [showDiscount, setShowDiscount] = useModal(!!groupOrderDetails.checkout_discount)
  const [{ discountType, discountValue }, setCustomDiscount] = useState({ discountType: groupOrderDetails?.checkout_discount?.type, discountValue: groupOrderDetails?.checkout_discount?.value })

  const returnOrderMutation = useReturnSingleGroupOrderByGroupIdMutation({ successFn: onClose })
  const modifyOrderMutation = useModifyOrderMutation({ successFn: onClose })
  
  const getDiscountPercentage = () => {
    if(!!groupOrderDetails.checkout_discount && !showDiscount) return 0
    
    return groupOrderDetails.discount?.reduce((prev, curr) => {
      return prev + Number(curr.value)
    }, 0)
  }
  
  const handleShowDiscount = () => {
    if(showDiscount) {
      setCustomDiscount({ discountType: "", discountValue: "" })
    } else {
      setCustomDiscount({ discountType: groupOrderDetails?.checkout_discount?.type, discountValue: groupOrderDetails?.checkout_discount?.value })
    }
    
    setShowDiscount()
  }
  
  const getTotalDiscount = () => {
    return cart.reduce((prev, curr) => {
      const getOrder = mainOrders.find(order => order.product.id === curr.id)
      return prev + Number(getOrder.discount_amount)
    }, 0)
  }
  
  const {
    discountPercentage, totalTax, totalDiscount, subTotal, overallCalculation, overallCalculationValue
  } = useReturnCartCalculations({
    products: cart,
    customDiscountType: showDiscount && discountType,
    customDiscountValue: showDiscount && discountValue,
    discountPercentage: getDiscountPercentage(),
    totalDiscount: (!!groupOrderDetails.checkout_discount && !showDiscount) ? 0 : Number(getTotalDiscount() || 0),
    taxPercentage: Number(groupOrderDetails.tax?.[0]?.value || 0),
  })
  
  const handleRemoveProductFromCart = (productId) => {
    const product = cart.find(ord => ord.id === productId)
  
    reducer({
      product: { ...product, unit: 0, service: [] },
      type: sellReducerActions.ADD_PRODUCT_TO_CART,
    })
  }
  
  const taxPercentage = getTotalActiveTaxPercentage(groupOrderDetails?.tax || [])
  
  const handleReturnAllOrder = () => {
    returnOrderMutation.mutate(groupOrderDetails.group_order.id)
  }
  
  
  const handleModifyOrder = () => {
    const newOrders = mainOrders.map(mainOrder => {
      const cartOrder = cart.find(ord => ord.id === mainOrder.product.id)

      return {
        ...mainOrder.product,
        product: mainOrder.product.id,
        order_id: mainOrder.id,
        group_id: mainOrder.group_order.id,
        business: mainOrder.business.id,
        returned: (cartOrder.unit === 0) ? true : Number(cartOrder?.unit) !== Number(mainOrder?.qty),
        return_type: (cartOrder.unit === 0) ? "order" : "quantity",
        qty: Number(cartOrder.unit),
        ...(!!groupOrderDetails.checkout_discount && {
          checkout_discount: !!discountValue ? { value: Number(discountValue), type: discountType } : null
        })
      }
    })
  
    modifyOrderMutation.mutate(newOrders)
  }
  
  const handleAddToCartWithModal = (product) => {
    const prod = mainOrders.find(order => order.product.id === product.id)
    setCurrentMaxUnit(Number(prod.qty))
    
    if(!businessSettings.sell_below_stock && (Number(product.stock_unit) < 1) && product.use_stock) {
      toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
      return
    }
    
    reducer({
      product,
      type: sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL
    })
    
    setAddToCartModal()
  }
  
  return (
    <CartStyled className="cart_section" onClick={e => e.stopPropagation()}>
  
      {addToCartModal && <AddToCartModal isDisableIncrement onClose={setAddToCartModal} maxUnit={currentMaxUnit} />}
      <SpaceBetweenStyled>
        <Heading color="black" size={1.3}>Orders ({cart.length} Item{cart.length > 1 && "s"})</Heading>
      </SpaceBetweenStyled> <br/>

      <SectionStyled>
        {cart.length < 1 && (
          <EmptyCartStyled>
            <Image width={35} src={EmptyBoxImage} alt="no product" />
            <Heading color="gray" size={1.2}>Cart is empty</Heading>
          </EmptyCartStyled>
        )}
  
        {!!groupOrderDetails.checkout_discount && (
          <Form values={{ show_discount: showDiscount, discount_type: discountType, discount_type_name: discountType, discount_value: discountValue, discount_value_format: numberFormatter(discountValue) }}>
            <CheckBox afterCheck={handleShowDiscount} checked={showDiscount} optional name="show_discount" labelProp="Discount (uncheck to remove discount)" />
    
            {showDiscount && (
              <ReturnOrderCustomerDiscount setCustomDiscount={setCustomDiscount} existingDiscount={groupOrderDetails.checkout_discount} />
            )}
          </Form>
        )}

        {cart.length > 0 && (
          cart.map(product => (
            <CartProductCard
              isReturnOrder
              returned={Number(product.unit) === 0}
              key={product.id}
              product={product}
              handleRemoveProductFromCart={handleRemoveProductFromCart}
              handleAddToCartWithModal={handleAddToCartWithModal}
            />
          ))
        )}
      </SectionStyled>

      <CartSummaryStyled>
        <SpaceBetweenStyled>
          <Heading color="secondary">Subtotal:</Heading>
          <Heading color="secondary">{subTotal}</Heading>
        </SpaceBetweenStyled>

        {!!discountPercentage && (
          <SpaceBetweenStyled>
            <Heading color="secondary">Discount: {(discountType !== "amount") && `${discountPercentage}%`}</Heading>
            <Heading color="secondary">
              -{totalDiscount}
            </Heading>
          </SpaceBetweenStyled>
        )}

        {!!taxPercentage && (
          <SpaceBetweenStyled>
            <Heading color="secondary">Tax: {Number(taxPercentage)}%</Heading>
            <Heading color="secondary">
              +{totalTax}
            </Heading>
          </SpaceBetweenStyled>
        )}

        <SpaceBetweenStyled>
          <Heading color="black" bold size={1.1}>Total:</Heading>
          <Heading color="black" bold size={1.1}>
            {overallCalculation}
          </Heading>
        </SpaceBetweenStyled>
  
        {businessSettings?.exchange_rate && (
          <SpaceBetweenStyled>
            <Paragraph color="black" bold className="section_title">Exchange Value: </Paragraph>
            <Paragraph color="black" bold>
              {currencyFormatterFn("en-NG", businessSettings?.ex_currency)(Number(overallCalculationValue) * Number(businessSettings?.currency_exchange_rate))}
            </Paragraph>
          </SpaceBetweenStyled>
        )}
        
        <Button text="Modify Order" disabled={cart.length < 1 || returnOrderMutation.isLoading} isLoading={modifyOrderMutation.isLoading} onClick={handleModifyOrder} />
        <Button bgColor="error" disabled={modifyOrderMutation.isLoading} text="Return All Order" isLoading={returnOrderMutation.isLoading} onClick={handleReturnAllOrder} />

      </CartSummaryStyled>
    </CartStyled>
  )
}

export default ReturnOrderCart


const ReturnOrderCustomerDiscount = memo(({ setCustomDiscount, existingDiscount }) => {
  const { numberFormatter } = useDataTypeFormatter()
  const { setFieldValue } = useFormikContext()
  
  useEffect(() => {
    setFieldValue("discount_type", existingDiscount.type)
    setFieldValue("discount_type_name", existingDiscount.type)
    setFieldValue("discount_value_name", "")
    setFieldValue("discount_value", existingDiscount.value)
    setFieldValue("discount_value_format", numberFormatter(existingDiscount.value))
  }, [])
  
  
  return (
    <CustomDiscount noWarningMessage setCustomDiscount={setCustomDiscount} />
  )
})




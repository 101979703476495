import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import { Heading } from "../../../styles/textStyles";
import {Button, FileInput, Form, TextArea} from "../../index";
import {CloseSquare} from "iconsax-react";
import { FlexStyled } from "../../../styles/utilStyles";
import {packageDeliveryFormDefaultValues} from "../../../data/defaultFormValues";


const CreatePackageDeliveryModal = ({ onClose, handleSubmit, mutation }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={40} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">New Package</Heading>

        <Form values={packageDeliveryFormDefaultValues} onSubmit={handleSubmit}>
          <TextArea className="fullWidth" name="remark" rows={3} placeholder="Package Remark" />
          <FileInput name="image" className="fullWidth" />
          <FlexStyled>
            <Button text="Add" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreatePackageDeliveryModal
import {useQuery} from "react-query";
import {getBusinessAccountIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {getActivityLogService} from "../../../services/activityLogServices";
import useGlobalContext from "../../useContexts/useGlobalContext";

export const activityLogKeys = {
  all: ["activity logs", getBusinessAccountIdFromStorage()],
  main: (query) => ["activity logs", getBusinessAccountIdFromStorage(), getAllObjectKeysWithValues(query)]
}

export const useActivityLogQuery = (queryParams = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: activityLogKeys.main(queryParams),
    queryFn: () => getActivityLogService({ business_id: getBusinessId(), ...queryParams })
  })
}
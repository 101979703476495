import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";

const updateSubscriptionService = async (body) => {
  try {
    const { data } = await axios.patch("/subcription", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getAllSubscriptionPackages = async () => {
  try {
    const { data } = await axios.get("/packages")
    return data.reverse()
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getSubscriptionLogService = async (query = {}) => {
  try {
    const url = handleQueryParams("/subscription_log", query)
    const { data } = await axios.get(url)

    const results = data?.results?.map(log => {
      return {
        ...log,
        status: !!log.is_verified ? "confirmed" : "processing",
        sub_type: log.sub_type.split("_").join(" ").toUpperCase(),
        packageNameAndDuration: `${log.package_name || ""} (${log.package_duration})`
      }
    })

    return { ...data, results }
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const calculateSubscriptionPackages = async (body) => {
  try {
    const { data } = await axios.post("/package_cost/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const subscribeForPackageService = async (body) => {
  try {
    const { data } = await axios.post("/subscription/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { updateSubscriptionService, getAllSubscriptionPackages, calculateSubscriptionPackages, subscribeForPackageService, getSubscriptionLogService }

import {isLinkActive} from "../../../utils";
import {Outlet, useLocation} from "react-router-dom";
import {FlexStyled} from "../../../styles/utilStyles";
import {Chips, Line, PageHeader} from "../../../components";
import {SettingsStyled} from "../../../styles/DashboardStyles";
import useSettingOptions from "../../../hooks/useUtils/useSettingOptions";


const Settings = () => {
  const location = useLocation()
  const settingsOptions = useSettingOptions()

  return (
    <div>
      <PageHeader title="Settings" />

      <SettingsStyled>
        <FlexStyled>
          {settingsOptions.map(option => (
            <Chips title={option.title} permissionCode={option.permissionCode} active={isLinkActive(option.link, location)} link={option.link} key={option.title} />
          ))}
        </FlexStyled>

        <Line />

        <Outlet />
      </SettingsStyled>
    </div>
  )
}

export default Settings
import {useEffect, useReducer, useRef} from "react";
import { useTheme } from "styled-components";
import {Link, Outlet} from "react-router-dom";
import {FlexStyled} from "../../../styles/utilStyles";
import {SellLayoutStyled} from "../../../styles/sellStyles";
import {SellProvider} from "../../../providers/SellProvider";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import {Cart, DashboardMobileNav} from "../../../components";
import sellReducer, { sellReducerInitialValues } from "../../../reducers/sellReducer";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import IconWithToolTip from "../../../components/Utils/IconWithToolTip";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {User} from "iconsax-react";
import useAllowOnlyAuthenticatedUsers from "../../../hooks/useRouteProtection/useAllowOnlyAuthenticatedUsers";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {clearAllDataInStorage} from "../../../utils";
import useNavigationMenuList from "../../../hooks/useUtils/useNavigationMenuList";
import useSyncDesktopOfflineData from "../../../hooks/useDesktop/useUtils/useSyncDesktopOfflineData";


const SellLayout = () => {
  const theme = useTheme()
  useSyncDesktopOfflineData()
  useAllowOnlyAuthenticatedUsers()
  const { sellDashboardNavMenuList } = useNavigationMenuList()
  
  const { isTablet, navigate } = useGlobalContext()
  const { data: userProfile, error } = useUserProfile()
  const { data: business } = useBusinessByIdQuery()
  const { user_permission = [], user_type } = userProfile?.[0] || {}

  const sellSearchRef = useRef(undefined)
  const sellCategoryRef = useRef(undefined)
  const [sellState, reducer] = useReducer(sellReducer, sellReducerInitialValues)

  useEffect(() => {
    if(!!error) {
      clearAllDataInStorage()
      navigate("/login")
    }
  }, [error])

  return (
    <ContainerStyled>
      <SellProvider.Provider value={{ ...sellState, reducer, sellSearchRef, sellCategoryRef }}>
        {!isTablet && <DashboardMobileNav businessName={business?.store_name} /> }
        <SellLayoutStyled>
          <nav className="sell_navigation">
            <FlexStyled>
              {userProfile?.[0]?.user_type !== "client_admin" && (
                <Link to={`/dashboard/staff/${userProfile?.[0]?.id}`}>
                  <IconWithToolTip text="Dashboard">
                    <User color={theme.black} size={20}/>
                  </IconWithToolTip>
                </Link>
              )}
              
              {sellDashboardNavMenuList.map(({icon: Icon, link, title, permission}, k) =>{
                if(!user_permission.includes(permission) && user_type !== "client_admin") return
  
                return (
                  <Link to={link} key={k}>
                    <IconWithToolTip text={title}>
                      <Icon color={theme.black} size={20}/>
                    </IconWithToolTip>
                  </Link>
                )
              })}
            </FlexStyled>

            {/* <div>
              <Line />
              <Link to={Setting.link}>
                <IconWithToolTip text="Settings">
                  <Setting.icon color={theme.black} size={20} />
                </IconWithToolTip>
              </Link>
            </div> */}
          </nav>

          <main>
            <Outlet />
          </main>

          <Cart />
        </SellLayoutStyled>
      </SellProvider.Provider>
    </ContainerStyled>
  )
}

export default SellLayout
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const getProductExpiryServices = async (queryParams = {}) => {
	try {
		const url = handleQueryParams("/product_expiry", queryParams)
		const { data } = await axios.get(url)
		
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createProductExpiryService = async (body) => {
	try {
		const { data } = await axios.post(`/product_expiry/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateProductExpiryService = async (id, body) => {
	try {
		const { data } = await axios.patch(`/product_expiry/${id}`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const deleteProductExpiryService = async (id) => {
	try {
		const { data } = await axios.delete(`/product_expiry/${id}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
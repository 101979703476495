import {BusinessSettingsStyled} from "./settingsStyles";
import {Button, CheckBox, FileInput, Form, Line, Select} from "../../../components";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexColumnStyled, FlexStyled} from "../../../styles/utilStyles";
import useDesktopOfflineModeAllowed from "../../../hooks/useDesktop/useUtils/useDesktopOfflineModeAllowed";
import {WIPStatus} from "../../../utils/table";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
  desktopOfflineQueryKeys,
  useDesktopBusinessSyncStatusFromLocalDB
} from "../../../hooks/useDesktop/useDesktopServicesFromLocalDB";
import fileAcceptTypes from "../../../data/fileAcceptTypes";
import {Fragment, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import useDesktopOnlineStatusContext from "../../../hooks/useContexts/useDesktopOnlineStatusContext";
import {desktopOnlineStatusReducerActions} from "../../../reducers/DesktopOnlineStatusReducer";
import useIsOfflineDataReady from "../../../hooks/useDesktop/useUtils/useIsOfflineDataReady";
import {useQueryClient} from "react-query";

const DesktopSetting = () => {
  const queryClient = useQueryClient()
  const {data: userProfile} = useUserProfile()
  const {toast} = useGlobalContext()
  const {dateFormatter} = useDataTypeFormatter()
  const isOfflineReady = useIsOfflineDataReady()
  const isOfflineAllowed = useDesktopOfflineModeAllowed()
  const {data: businessSyncStatus} = useDesktopBusinessSyncStatusFromLocalDB()
  const {desktopOnlineReducer, desktopOnlineState} = useDesktopOnlineStatusContext()
  
  const [uploadDBPath, setUploadDBPath] = useState("")
  const [desktopDownloadProgress, setDesktopDownloadProgress] = useState(0)
  
  useEffect(() => {
    if (!window?.require) return
    const {ipcRenderer} = window.require("electron")
    
    const updateDesktopDownloadProgress = (progress) => setDesktopDownloadProgress(progress)
    ipcRenderer.on(IPC_EVENTS.MANUAL_DESKTOP_DOWNLOAD_PROGRESS, (_, progressNumber) => {
      const progress = Number(progressNumber || 0)
      updateDesktopDownloadProgress(progress === 100 ? 0 : progress)
    })
    
    return () => {
      ipcRenderer.removeListener(IPC_EVENTS.MANUAL_DESKTOP_DOWNLOAD_PROGRESS, updateDesktopDownloadProgress)
    }
  }, [])
  
  const handleCheckForDesktopUpdate = () => {
    if (!window?.require) return
    
    const {ipcRenderer} = window.require("electron")
    ipcRenderer.send(IPC_EVENTS.MANUAL_DESKTOP_UPDATE)
    
    ipcRenderer.once(IPC_EVENTS.MANUAL_DESKTOP_UPDATE, (_, message) => {
      toast.info(message, "desktop_update")
    })
  }
  
  const handleUpdateAllOfflineData = () => {
    if (!window?.require) return
  
    desktopOnlineReducer({
      re_sync: true,
      type: desktopOnlineStatusReducerActions.RE_SYNC_ALL_DATA
    })
    const {ipcRenderer} = window.require("electron")
    ipcRenderer.send(IPC_EVENTS.MANUAL_UPDATE_OF_ALL_OFFLINE_DATA)
  }
  
  const handleExportDatabase = () => {
    if (!window?.require) return
    
    let businessName = userProfile?.[0]?.business_name || userProfile?.[0]?.business_details?.name
    businessName = businessName.split(" ").join("_")
    
    const {ipcRenderer} = window.require("electron")
    ipcRenderer.send(IPC_EVENTS.EXPORT_OFFLINE_DATABASE, businessName)
  
    ipcRenderer.once(IPC_EVENTS.EXPORT_OFFLINE_DATABASE, (_, message) => {
      if (message === "success") {
        toast.info("Database exported. Please check your download folder", "desktop_export")
        return
      }
    
      toast.error(message, "desktop_export")
    })
  }
  
  const handleAutoSyncStatus = (checked) => {
    if (!window?.require) return
    
    const {ipcRenderer} = window.require("electron")
    ipcRenderer.send(IPC_EVENTS.MODIFY_AUTO_SYNC, {
      status: checked,
      businessId: businessSyncStatus.business
    })
    
    ipcRenderer.once(IPC_EVENTS.MODIFY_AUTO_SYNC, (_, data) => {
      if (data.status === "success") {
        toast.info(data.message, "modify_auto_sync")
        queryClient.refetchQueries({
          queryKey: desktopOfflineQueryKeys.isOfflineReady
        }).then().catch()
        return
      }
      
      toast.error(data.message, "modify_auto_sync")
    })
  }
  
  return (
    <BusinessSettingsStyled>
      <Heading size={1.45} color="black">Ovaloop Desktop</Heading>
      <FlexColumnStyled $gap={1.7}>
        <Paragraph color="black" size={1.1}>Desktop version: v{process.env.REACT_APP_VERSION}</Paragraph>
        
        <FlexStyled>
          <Paragraph color="black" size={1.1}>
            Offline mode:
          </Paragraph>
          <Paragraph>
            {isOfflineAllowed ? WIPStatus("available") : WIPStatus("unavailable")}
          </Paragraph>
        </FlexStyled>
        
        {(!!businessSyncStatus?.last_sync_date && isOfflineAllowed) && (
          <Paragraph color="black" size={1.1}>Last sync
            time: {dateFormatter(businessSyncStatus.last_sync_date)}</Paragraph>
        )}
        
        {(!!businessSyncStatus?.last_sync_date && isOfflineAllowed) && (
          <Form values={{auto_sync: businessSyncStatus.auto_sync}} onSubmit={() => {
          }}>
            <CheckBox
              noBottomMargin
              checked={businessSyncStatus.auto_sync}
              name="auto_sync"
              afterCheck={handleAutoSyncStatus}
              labelProp="Allow auto sync all offline orders to cloud"
            />
          </Form>
        )}
  
        {desktopDownloadProgress > 1 && (
          <div>
            <Paragraph color="black">
              Downloading New Desktop Update ({Number(desktopDownloadProgress).toFixed(0)}%)
            </Paragraph>
            <progress value={desktopDownloadProgress} max={100}/>
          </div>
        )}
      </FlexColumnStyled>
  
      <FlexStyled>
        {isOfflineAllowed && (
          <Button disabled={!isOfflineReady} isLoading={desktopOnlineState.re_sync} bgColor="black"
                  text="Update all offline data"
                  onClick={handleUpdateAllOfflineData}/>
        )}
        <Button disabled={desktopOnlineState.re_sync} text="Check for new desktop update"
                onClick={handleCheckForDesktopUpdate}/>
      </FlexStyled>
  
      {(isOfflineAllowed && isOfflineReady && !desktopOnlineState.re_sync) && (
        <>
          <Line mt={2} mb={2}/>
          <Heading size={1.45} color="black">Offline Database</Heading>
      
          <Form values={{database: "", database_display_name: "", database_action: ""}} onSubmit={() => {
          }}>
            <Select
              options={[{name: "Import Database", value: "import"}, {name: "Export Database", value: "export"}]}
              name="database_action"
              placeholder="Select action"
              label="Database Action"
              displayKey="name"
              valueKey="value"
              displayName="database_action_name"
            />
        
            <DatabaseFormDisplay
              handleSelectDatabase={(file) => setUploadDBPath(file.path)}
              handleImportDatabase={() => handleImportDatabase({
                toast, uploadDBPath, successFn: () => {
                }
              })}
              handleExportDatabase={handleExportDatabase}
            />
          </Form>
        </>
      )}
  
      <br/><br/>
    </BusinessSettingsStyled>
  )
}

const DatabaseFormDisplay = ({handleSelectDatabase, handleImportDatabase, handleExportDatabase}) => {
  const {values} = useFormikContext()
  
  return (
    <Fragment>
      {values.database_action === "import" && (
        <Fragment>
          <FileInput onChange={handleSelectDatabase} isNotImage placeholder="Select excel file" name="database"
                     accept={fileAcceptTypes.desktopDatabase}/>
          <Button type="submit" onClick={handleImportDatabase} text="Import Database"/>
        </Fragment>
      )}
  
      {values.database_action === "export" && (
        <Button type="button" onClick={handleExportDatabase} text="Export Database"/>
      )}
    </Fragment>
  )
}

export default DesktopSetting


export const handleImportDatabase = ({toast, uploadDBPath, successFn}) => {
  if (!window?.require) return
  if (!uploadDBPath) {
    toast.error("Please reselect database file", "desktop_import")
    return
  }
  
  const {ipcRenderer} = window.require("electron")
  ipcRenderer.send(IPC_EVENTS.IMPORT_OFFLINE_DATABASE, uploadDBPath)
  
  ipcRenderer.once(IPC_EVENTS.IMPORT_OFFLINE_DATABASE, (_, message) => {
    if (message === "success") {
      successFn()
      toast.info("Database imported. Application will restart in 5 seconds", "desktop_import")
      
      setTimeout(() => {
        ipcRenderer.send(IPC_EVENTS.RESTART_APP)
      }, 5000)
      return
    }
    
    toast.error(message, "desktop_import")
  })
}
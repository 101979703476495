import Form from "../../Forms/Form";
import {CloseSquare} from "iconsax-react";
import {Button, CreateBankModal, Select} from "../../index";
import {Heading} from "../../../styles/textStyles";
import TableOrderSummary from "./TableOrderSummary";
import {SpaceBetweenStyled} from "../../../styles/utilStyles";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import PaymentMethod from "../../Forms/PaymentMethod";
import {tableCheckoutPaymentFromTableMethods,} from "../../../data/selectField/paymentMethodsList";
import {usePayForGroupOrderMutation} from "../../../hooks/useServices/useOrderServices";
import {useState} from "react";
import {useBankQuery, useCreateBankMutation} from "../../../hooks/useServices/useBankServices";
import {useModal} from "../../../hooks";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const TableCheckout = (
	{ onClose, closeDrawer, tableId, trackId, table_amount, cart, checkOut }
) => {
	const {data: allBanks} = useBankQuery()
	const [showCreateBankModal, setShowCreateBankModal] = useModal()
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
	const createBankMutation = useCreateBankMutation({successFn: setShowCreateBankModal})
	const {isOffline} = useGlobalContext()
	
	
	const handleSubmitSuccessful = () => {
		onClose()
		closeDrawer()
	}
	
	const payForGroupOrderMutation = usePayForGroupOrderMutation({successFn: handleSubmitSuccessful})
	
	const handleCheckout = (values) => {
		payForGroupOrderMutation.mutate({
			clear: true,
			...values,
			table_id: tableId,
			track_id: trackId,
			total_amount: table_amount,
		})
	}
	
	const CreateBank = () => (
		<label className="underline" onClick={setShowCreateBankModal}>Create +</label>
	)
	
	console.log(selectedPaymentMethod)
	
	return (
		<ContainerStyled className="checkout">
			
			{showCreateBankModal && (
				<CreateBankModal
					position="fixed"
					onClose={setShowCreateBankModal}
					mutation={createBankMutation}
					handleSubmit={createBankMutation.mutate}
				/>
			)}

			<SpaceBetweenStyled>
				<Heading size={1.3} color="black">Check Out</Heading>
				<CloseSquare size={28} onClick={onClose} />
			</SpaceBetweenStyled>
			
			<Form values={{pay_method: "", display_bank: ""}} onSubmit={handleCheckout}>
				<PaymentMethod
					label="Select Payment Method"
					name="pay_mode"
					methods={tableCheckoutPaymentFromTableMethods}
					setPaymentMethod={setSelectedPaymentMethod}
				/>
				
				{(["Bank Transfer", "Pos"].includes(selectedPaymentMethod)) && (
					<Select
						label="Select bank"
						name="banks"
						options={allBanks}
						displayKey="bankInfo"
						valueKey="id"
						displayName="display_bank"
						placeholder="Select bank to transfer"
						SideInfo={!isOffline && CreateBank}
					/>
				)}

				<Button type="submit" id="submit_checkout" text="submit" />
			</Form>

			<TableOrderSummary
				cart={cart}
				checkOut={checkOut}
				buttonText="Check out"
				onClick={handleCheckout}
			/>
		</ContainerStyled>
	)
}

export default TableCheckout
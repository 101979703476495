import axios from "../../axios";

const getSentItemByBusinessIdService = async (businessId) => {
	try {
		const { data } = await axios.get(`/package_order/?business_id=${businessId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const createSentItemService = async (body) => {
	try {
		const { data } = await axios.post(`/package_order/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export { getSentItemByBusinessIdService, createSentItemService }
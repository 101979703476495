import { useState } from "react"

const visibleType = { type: "text", visible: true }
const inVisibleType = { type: "password", visible: false }

const usePassword = () => {
  const [passwordState, setPasswordState] = useState(inVisibleType)

  const makeVisible = () => setPasswordState(visibleType)
  const makeInVisible = () => setPasswordState(inVisibleType)

  return [passwordState, { makeVisible, makeInVisible }]
}

export default usePassword
import {useReducer} from "react";


export const orderReducerActionTypes = {
	ADD_ITEM_TO_BATCH: "ADD_ITEM_TO_BATCH"
}

const orderReducerInitialValues = {
	batch: [],
	itemToBatchAvailable: false
}

const deliveryReducer = (state, action) => {
	switch (action.type) {
		case orderReducerActionTypes.ADD_ITEM_TO_BATCH:
			const isItemInBatch = state.batch.find(item => item === action.itemId)

			if(isItemInBatch) {
				const newBatch = state.batch.filter(item => item !== action.itemId)
				return {...state, batch: newBatch, itemToBatchAvailable: newBatch.length > 0 }
			}

			return {...state, batch: [...state.batch, action.itemId], itemToBatchAvailable: true}

		default:
			return state
	}
}

const useDeliveryReducer = () => {
	return useReducer(deliveryReducer, orderReducerInitialValues)
}

export default useDeliveryReducer


import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, DatePicker, Form} from "../../index";
import SplitOrPartialPayment from "../../Forms/SplitOrPartialPayment";
import {FlexStyled} from "../../../styles/utilStyles";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../data/permissions";

const formValues = {
	split_cash_value: "",
	split_pos_value: "",
	split_transfer_value: "",
}

const PayBalanceModal = ({onClose, handleSubmit, totalPayable, isMutationLoading, isTransferConfirmation}) => {
	const permissionAction = useActionPermissionFn()
	
	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose}/>
				<Heading size={1.3} color="black">{isTransferConfirmation ? "Confirm Transfer" : "Pay Balance"}</Heading>
				
				<Form values={formValues} onSubmit={handleSubmit}>
					{permissionAction.check(actionsPermissions.addPaymentDateDuringSales) && (
						<DatePicker
							optional
							datetime
							labelBg="white"
							name="payment_date"
							label="Payment Date"
							placeholder="Select payment date"
						/>
					)}
					
					{!isTransferConfirmation && (
						<SplitOrPartialPayment totalPayable={totalPayable}/>
					)}
					
					<FlexStyled>
						<Button type="submit" text="Pay" isLoading={isMutationLoading}/>
					</FlexStyled>
				</Form>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default PayBalanceModal
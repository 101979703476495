import {Button, Form, Input} from "../../../components";
import { StepLayout } from "../AuthStyles";
import { ArrowLeft2 } from "iconsax-react"
import { useOutletContext } from "react-router-dom";
import {Step3} from "../../../assets/images";
import {Paragraph} from "../../../styles/textStyles";
import {useEmailVerificationService} from "../../../hooks/useServices/authenticationHooks";

const VerifyEmail = () => {
  const { navigate, formValues } = useOutletContext()
  const emailVerificationMutation = useEmailVerificationService()

  const handleSubmit = (values) => {
    emailVerificationMutation.mutate({
      otp: values.otp,
      email: values.email,
      password: values.password,
    })
  }

  const handleSkipVerification = () => {
    emailVerificationMutation.mutate({
      otp: null,
      email: formValues.email,
      password: formValues.password,
      verify_later: true
    })
  }

  return (
    <StepLayout className="form_section">
      <div className="step_div">
        <nav>
          <Paragraph onClick={() => navigate(-1)} bold size={.96}> <ArrowLeft2 size={18} /> Back</Paragraph>
          <img src={Step3} alt="step 3" />
        </nav>
        <div>
          <Form values={formValues} onSubmit={handleSubmit}>
            <Input
              required
              name="otp"
              label="Verify Email To Continue"
              placeholder="Enter OTP"
              type="number"
            />
            <Button type="submit" text="Verify" isLoading={false} shadow />
            <br/>

          </Form>
          <div className="verify_later">
            <Paragraph color="secondary" onClick={handleSkipVerification}>Verify Later</Paragraph>
          </div>
        </div>
      </div>
    </StepLayout>
  )
}

export default VerifyEmail
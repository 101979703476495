import axios from "../../axios";
import {getCustomerServiceStructure, getCustomerWalletLogStructure} from "./customerServiceStructure";
import handleQueryParams from "../../utils/handleQueryParams";


const getCustomerService = async (query = {}) => {
  try {
    const url = handleQueryParams("/customers", query)
    const { data } = await axios.get(url, { timeout: 200000 })
    
    return getCustomerServiceStructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getCustomerByIdService = async (customerId) => {
  try {
    const { data } = await axios.get(`/customers/${customerId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createCustomerService = async (body) => {
  try {
    const { data } = await axios.post(`/customers/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editCustomerService = async (customerId, body) => {
  try {
    const { data } = await axios.patch(`/customers/${customerId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteCustomerService = async (customerId) => {
  try {
    const { data } = await axios.delete(`/customers/${customerId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const getCustomerWalletLogByIdService = async ({ businessId, customerId, transactionType, from_date, to_date }) => {
  let url = `/customer_wallet_log?business_id=${businessId}&customer_id=${customerId}`
  
  if(transactionType) {
    url = `/customer_wallet_log?business_id=${businessId}&customer_id=${customerId}&transaction_type=${transactionType}`
  }
  
  if(!!to_date && !!from_date) {
    url = `${url}&from_date=${from_date}&to_date=${to_date}`
  }
  
  try {
    const { data } = await axios.get(url)
    return getCustomerWalletLogStructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateCustomerWalletByIdService = async (customerId, body) => {
  try {
    const { data } = await axios.patch(`/customer_wallet/${customerId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const uploadBulkCustomerService = async (type, businessId, body) => {
  try {
    const { data } = await axios.post(`/customer_upload/?business_id=${businessId}`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}



export { getCustomerService, getCustomerByIdService, createCustomerService,
  editCustomerService, deleteCustomerService, uploadBulkCustomerService
}
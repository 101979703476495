import {Eye} from "iconsax-react";
import {useTheme} from "styled-components";
import {ShippingBoxIcon} from "../../assets/icons";
import {CheckBox, Form, Image, Line} from "../index";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {displayTableItemWithStatus, checkIfValueIsADate} from "../../utils/table";
import {TableItemStyled, CheckBoxAndPackageIconStyled, TableItemActionStyled} from "../../styles/TableStyles";


const TableItemWithCheckoutBoxAndImage = ({ data, keys, handleCheck, checkId, noImage, noAction }) => {
	const theme = useTheme()
	const tableData = useTableValueTransformer(data, keys)

	return (
		<TableItemStyled>
			<CheckBoxAndPackageIconStyled>
				<Form values={{ order_id: "" }}>
					<CheckBox noBottomMargin name="order_id" required afterCheck={handleCheck?.bind(this, data[checkId])} />
				</Form>
				{!noImage && <Image src={ShippingBoxIcon} alt="order" />}
			</CheckBoxAndPackageIconStyled>

			{tableData.map((item, k) => {
				const value = typeof item === "number" ? item : checkIfValueIsADate(item)
				return displayTableItemWithStatus(value, k, 20)
			})}
			
			{!noAction && (
				<TableItemActionStyled>
					<Eye color={theme.gray}/>
				</TableItemActionStyled>
			)}
			<Line />
		</TableItemStyled>
	)
}

export default TableItemWithCheckoutBoxAndImage
import {Button, FileInput, Form, RadioButton} from "../../../index";
import {SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled, UploadBulkProductModalStyled} from "../../../../styles/modalStyles";
import fileAcceptTypes from "../../../../data/fileAcceptTypes";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Fragment} from "react";


const UploadBulkProductModal = ({ onClose, handleSubmit, mutation, modalText, file_name = "product_file", downloadTemplateLink = "https://ovaloopbucket1.s3.amazonaws.com/media/Ovaloop_Product_Upload_Template.xlsx", noType = false }) => {
  const formData = new FormData()
  
  const handleDataChange = (file) => {
    formData.append(file_name, file)
  }
  
  const handleSubmitFn = (values) => {
    handleSubmit(values.type || "upload", formData)
  }

  const handleDownloadTemplate = () => {
    window.open(downloadTemplateLink)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={50}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">{modalText || "Upload/Update Bulk Product"}</Heading>

        <UploadBulkProductModalStyled>
          <Form values={{ [file_name]: "" }} onSubmit={handleSubmitFn}>
            <FileInput onChange={handleDataChange} isNotImage placeholder="Select excel file" name={file_name} accept={fileAcceptTypes.excel} />

            <br/>
            {!noType && (
              <Fragment>
                <RadioButton name="type" id="upload" value="upload" labelProp="Upload New Data" required />
                <RadioButton name="type" id="update" value="update" labelProp="Update Existing Data" />
              </Fragment>
            )}
            
            <SpaceBetweenStyled>
              <Button type="submit" text="Submit" isLoading={mutation.isLoading} />
              <Button type="button" onClick={handleDownloadTemplate} text="Download Template" />
            </SpaceBetweenStyled>
          </Form>
        </UploadBulkProductModalStyled>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default UploadBulkProductModal
import {CreditCardStyled} from "../../styles/cardStyles";
import {CardTick, Copy} from "iconsax-react";
import { BsFillInfoCircleFill } from "react-icons/bs"
import { IoIosRefreshCircle, IoIosRefresh } from "react-icons/io"
import {Heading, Paragraph} from "../../styles/textStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import { Tooltip } from "react-tooltip"
import {useBusinessByIdQuery} from "../../hooks/useServices/useStoreServices";
import {isStoreUsingNGN} from "../../hooks/useUtils/useNavigationMenuList";
import {Fragment} from "react";
import {PageSummaryCardSkeleton} from "../index";


const CreditCard = ({ balance, name, number, isCustomerWallet, handleCreateCustomerAccount, infoText, isPendingTransactions, totalPendingTransactions, handleReload = () => {}, isLoading }) => {
  const { toast } = useGlobalContext()
  const { data: business } = useBusinessByIdQuery()

  const handleCopyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success("Account number copied"))
      .catch()
  }

  return (
    <Fragment>
      {isLoading && (
        <PageSummaryCardSkeleton  cardHeight="8rem" headingHeight="4rem" titleHeight="2rem" />
      )}
      
      {!isLoading && (
        <CreditCardStyled $small={!isStoreUsingNGN(business)}>
          <SpaceBetweenStyled>
            <Paragraph>{name}</Paragraph>
            <CardTick variant="Bold" size={35} color="white" />
          </SpaceBetweenStyled>
    
          {isPendingTransactions && (
            <WalletBalance handleReload={handleReload} balance={balance} business={business} totalPendingTransactions={totalPendingTransactions} />
          )}
    
          {!isPendingTransactions && (
            <Fragment>
              <FlexStyled className="wallet_balance">
                <Heading>Wallet Balance</Heading>
                <Heading size={1.4}>{balance}</Heading>
              </FlexStyled>
        
              {!isCustomerWallet && (
                <FlexColumnStyled className="card_details">
                  <Paragraph>Bank Name: Providus Bank</Paragraph>
            
                  <Paragraph>Account Number: {number || "XXXXXXXXXX "}
                    {!!number && (
                      <Copy color="white" size={18} variant="Bold" onClick={() => handleCopyToClipBoard(number)} />
                    )}
              
                    {!number && (
                      <BsFillInfoCircleFill id="info" color="white" size={18} variant="Bold" />
                    )}
              
                    <Tooltip
                      anchorId="info"
                      place="top" variant="info"
                      content={infoText || "Reserved account number will be autogenerated when settlement account is approved"}
                    />
                  </Paragraph>
                </FlexColumnStyled>
              )}
        
        
              {(isCustomerWallet && isStoreUsingNGN(business)) && (
                <FlexColumnStyled className="card_details">
                  <Paragraph>Bank: Providus Bank</Paragraph>
            
                  <Paragraph>Acc No: {number || "XXXXXXXXXX"}
                    {!!number && (
                      <Copy color="white" size={18} variant="Bold" onClick={() => handleCopyToClipBoard(number)} />
                    )}
              
                    {!number && (
                      <IoIosRefreshCircle onClick={handleCreateCustomerAccount} id="info" color="white" size={22} variant="Bold" />
                    )}
              
                    <Tooltip
                      anchorId="info"
                      place="top" variant="info"
                      content="Create account number for customer"
                    />
                  </Paragraph>
                </FlexColumnStyled>
              )}
            </Fragment>
          )}
  
        </CreditCardStyled>
      )}
    </Fragment>
  )
}

export default CreditCard


const WalletBalance = ({ balance, totalPendingTransactions, handleReload }) => {
  return (
    <Fragment>
      <FlexStyled className="wallet_balance">
        <Heading>Pending Balance</Heading>
        <Heading size={1.4}>{balance}</Heading>
      </FlexStyled>
  
  
      <FlexColumnStyled className="card_details">
        <Paragraph></Paragraph>
      </FlexColumnStyled>
  
      <SpaceBetweenStyled className="card_details_reload">
        <Paragraph>Total Pending: {totalPendingTransactions}</Paragraph>
        <IoIosRefresh id="reload_pending" color="black" className="reload_icon" onClick={handleReload} />
  
        <Tooltip
          anchorId="reload_pending"
          place="top" variant="info"
          content="Reload pending transactions"
        />
      </SpaceBetweenStyled>
    </Fragment>
  )
}
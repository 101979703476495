import {toast} from "react-toastify";
import {useLocation} from "react-router-dom"
import {ConfirmationAlertBox} from "../../../components";

const isDashboardRoute = (path = "") => {
	return path.split("/")[1] === "dashboard"
}

const useToast = () => {
	const { pathname } = useLocation()
	const isDashboard = isDashboardRoute(pathname)

	return {
		confirm: function (text, onConfirm, toastId = "confirm") {
			toast(
				<ConfirmationAlertBox
					text={text}
					onConfirm={onConfirm}
				/>,
				{
					autoClose: false,
					toastId: toastId,
					position: toast.POSITION.TOP_CENTER,
					className: isDashboard && "dashboard_toast"
				})
		},
		success: function (text, toastId) {
			toast.success(text, {
				toastId: toastId,
				position: toast.POSITION.TOP_CENTER,
				className: isDashboard && "dashboard_toast"
			})
		},
		info: function (text, toastId, timer = 5000) {
			toast.info(text, {
				toastId: toastId,
				autoClose: timer,
				position: toast.POSITION.TOP_CENTER,
				className: isDashboard && "dashboard_toast"
			})
		},
		warning: function (text, toastId) {
			toast.warn(text, {
				toastId: toastId,
				autoClose: 5000,
				position: toast.POSITION.TOP_CENTER,
				className: isDashboard && "dashboard_toast"
			})
		},
		error: function (text, toastId) {
			toast.error(text, {
				toastId: toastId,
				autoClose: 5000,
				position: toast.POSITION.TOP_CENTER,
				className: isDashboard && "dashboard_toast"
			})
		}
	}
}

export default useToast
import {TableCardStyled} from "../../styles/cardStyles";
import {FlexColumnStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";


const BusinessSubscriptionCard = ({ subscription, openDrawer }) => {
	const { numberFormatter, currencyFormatter } = useDataTypeFormatter()

	return (
		<TableCardStyled onClick={openDrawer.bind(this, subscription)}>
			<SpaceBetweenStyled>
				<Heading size={1.3} color="black">{subscription.name}</Heading>
			</SpaceBetweenStyled>

			<FlexColumnStyled>
				<Paragraph color="secondary">Amount: {currencyFormatter(subscription.value)}</Paragraph>
				<Paragraph color="secondary">Duration: {numberFormatter(subscription.duration)} months</Paragraph>
				<Paragraph color="secondary">Limit: {numberFormatter(subscription.limit)} purchase</Paragraph>
			</FlexColumnStyled>
		</TableCardStyled>
	)
}

export default BusinessSubscriptionCard
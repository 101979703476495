const queryKeys = {
  ROLES: "ROLES",
  TAXES: "TAXES",
  BANKS: "BANKS",
  DEBTORS: "DEBTORS",
  STAFFS: "STAFFS",
  ADDONS: "ADDONS",
  STAGES: "STAGES",
  STORES: "STORES",
  TABLES: "TABLES",
  STATES: "STATES",
  ORDERS: "ORDERS",
  SERVICES: "SERVICES",
  PRODUCTS: "PRODUCTS",
  EXPENSES: "EXPENSES",
  DISCOUNTS: "DISCOUNTS",
  CUSTOMERS: "CUSTOMERS",
  SUPPLIERS: "SUPPLIERS",
  COUNTRIES: "COUNTRIES",
  DELIVERIES: "DELIVERIES",
  CATEGORIES: "CATEGORIES",
  SENT_ITEMS: "SENT_ITEMS",
  TRANSFER_LOGS: "TRANSFER_LOGS",
  DELIVERY_RATE: "DELIVERY_RATE",
  INVENTORIES: "INVENTORIES",
  PERMISSIONS: "PERMISSIONS",
  GROUP_ORDERS: "GROUP_ORDERS",
  USER_PROFILE: "USER_PROFILE",
  WIP_ANALYTICS: "WIP_ANALYTICS",
  PACKAGE_ITEMS: "PACKAGE_ITEMS",
  SUB_CATEGORIES: "SUB_CATEGORIES",
  FARE_CALCULATOR: "FARE_CALCULATOR",
  DYNAMIC_ACCOUNT: "DYNAMIC_ACCOUNT",
  STAFF_ANALYTICS: "STAFF_ANALYTICS",
  SETTLEMENT_BANKS: "SETTLEMENT_BANKS",
  BUSINESS_SETTINGS: "BUSINESS_SETTINGS",
  EXPENSE_CATEGORIES: "EXPENSE_CATEGORIES",
  TRANSACTION_WALLET: "TRANSACTION_WALLET",
  DASHBOARD_ANALYTICS: "DASHBOARD_ANALYTICS",
  CUSTOMER_SUBSCRIPTION: "CUSTOMER_SUBSCRIPTION",
  BUSINESS_SUBSCRIPTION: "BUSINESS_SUBSCRIPTION",
  CUSTOM_PAYMENT_METHOD: "CUSTOM_PAYMENT_METHOD",
  USER_NOTIFICATION: "USER_NOTIFICATION",
  REPORT_DOWNLOADABLE: "REPORT_DOWNLOADABLE"
}

export default queryKeys
import {Fragment, useEffect, useState} from "react";
import {SearchNormal1} from "iconsax-react";
import {SearchAndFilterStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {DatePicker, Form, SearchField, Select} from "../index";
import {debounceActions} from "../../hooks/useUtils/useQueryDebounce";
import {useCustomerQuery} from "../../hooks/useServices/useCustomerServices";
import orderFilter, {dateFilterType} from "../../data/selectField/orderFilter";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import {useStaffByBusinessIdQuery} from "../../hooks/useServices/useStaffServices";
import {useSearchParams} from "react-router-dom";
import queryParamKeys from "../../utils/queryParamKeys";
import {useFormikContext} from "formik";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import dateFilter, {dateFilterValue} from "../../data/selectField/dateFilter";


const OrderSearchAndFilter = (
	{
		searchPlaceholder,
		state,
		dispatch,
		customFilters,
		filterPlaceholder,
		noSearch,
		showCustomer,
		isTodayMax = true,
		dateFilterOptions
	}
) => {
	const [filter, setFilter] = useState("")
	
	const [{fromDate, toDate}, setDate] = useState({fromDate: "", toDate: ""})
	const {data: allCustomers} = useCustomerQuery()
	const {data: allStaffs} = useStaffByBusinessIdQuery()
	const businessSettings = useBusinessSettingQuery()
	
	const orderFilterOptions = () => {
		const options = [...orderFilter]
		
		if (!!businessSettings?.payment_date) {
			options.push({name: "Payment Date", value: "Payment Date"})
			return options
		}
		
		if (!!businessSettings?.due_date) {
			options.push({name: "Due Date", value: "Payment Date"})
			return options
		}
		
		return options
	}

	const handleSearch = (value) => {
		dispatch({
			searchValue: value,
			type: debounceActions.SET_SEARCH_FIELD
		})
	}
	
	useEffect(() => {
		if(!!fromDate && !!toDate) {
			dispatch({
				toDateValue: toDate,
				type: debounceActions.SET_FILTER_BY_TO_DATE
			})
			
			dispatch({
				fromDateValue: fromDate,
				type: debounceActions.SET_FILTER_BY_FROM_DATE
			})
		}
	}, [fromDate, toDate])

	const handleGeneralFilter = (data, otherValues) => {
		if(!showCustomer) {
			dispatch({
				type: debounceActions.CLEAR_ALL_FILTERS
			})
		}
		
		dispatch({
			searchValue: otherValues?.search || "",
			type: debounceActions.SET_SEARCH_FIELD
		})
		
		dispatch({
			filterValue: data?.value || "",
			type: debounceActions.SET_FILTER_FIELD
		})
	}

	const handleFilterByCustomer = (data) => {
		dispatch({
			customerValue: data?.id || "",
			type: debounceActions.SET_FILTER_BY_CUSTOMER_FIELD
		})
	}
	
	const handleFilterByStaff = (data) => {
		dispatch({
			staffValue: data?.id || "",
			type: debounceActions.SET_FILTER_BY_STAFF_FIELD
		})
	}
	
	const handleFilterByToDate = (date) => {
		setDate(prev => ({...prev, toDate: date}))
	}
	
	const handleFilterByFromDate = (date) => {
		setDate(prev => ({...prev, fromDate: date}))
	}
	
	const handleDateFilterSelect = (filterType) => {
		setFilter(filterType.value)
		if (filterType.value === dateFilterValue.CUSTOM_PERIOD) return
		
		const {toDate, fromDate} = handleDateFilterValue(filterType.value)
		console.log(toDate, fromDate)
		setDate(prev => ({...prev, toDate, fromDate}))
	}
	
	return (
		<SearchAndFilterStyled>
			<SpaceBetweenStyled className="form_space">
				<Form values={
					{
						search: "", filter: "", customer: "", display_customer: "",
						display_filter: "", from_date: "", to_date: "", display_staff: "", staff: ""
					}
				}>
					<AutoFillComponent dispatchFn={dispatch}/>
					{!noSearch && (
						<SearchField
							bgDark
							name="search"
							type="search"
							className="search_field"
							allowScannerInput
							placeholder={searchPlaceholder}
							setSearchValueState={handleSearch}
							rightIcon={<SearchNormal1 size={15}/>}
						/>
					)}
					
					<Select
						bgDark
						clearFilter
						name="filter"
						valueKey="value"
						displayKey="name"
						options={customFilters || orderFilterOptions()}
						className="filter_field"
						placeholder={filterPlaceholder || "Filter Orders"}
						displayName="display_filter"
						updateFn={handleGeneralFilter}
					/>
					
					{(state.filterValue === "customer" || showCustomer) && (
						<Select
							bgDark
							clearFilter
							valueKey="id"
							name="customer"
							displayKey="full_name"
							className="filter_field"
							placeholder="Select Customer"
							displayName="display_customer"
							options={allCustomers?.results}
							updateFn={handleFilterByCustomer}
						/>
					)}
					
					{state.filterValue === "staff" && (
						<Select
							bgDark
							clearFilter
							valueKey="id"
							name="staff"
							displayKey="full_name"
							className="filter_field"
							placeholder="Select Staff"
							displayName="display_staff"
							options={allStaffs?.results}
							updateFn={handleFilterByStaff}
						/>
					)}
					
					{dateFilterType.includes(state.filterValue) && (
						<Fragment>
							<Select
								bgDark
								name="date_filter"
								valueKey="value"
								displayKey="name"
								options={dateFilterOptions || dateFilter}
								displayName="display_date_filter"
								placeholder="Filter date"
								updateFn={handleDateFilterSelect}
							/>
							
							{filter === dateFilterValue.CUSTOM_PERIOD && (
								<Fragment>
									<DatePicker
										bgDark
										required
										isTodayMax={isTodayMax}
										labelBg="inputBGDark"
										name="from_date"
										className="date_field"
										placeholder="Filter From"
										onChange={handleFilterByFromDate}
									/>
									
									<DatePicker
										bgDark
										required
										labelBg="inputBGDark"
										name="to_date"
										isTodayMax={isTodayMax}
										className="date_field"
										placeholder="Filter To"
										onChange={handleFilterByToDate}
									/>
								</Fragment>
							)}
						</Fragment>
					)}
				</Form>
			</SpaceBetweenStyled>
		</SearchAndFilterStyled>
	)
}

export default OrderSearchAndFilter


const AutoFillComponent = ({ dispatchFn }) => {
	const [queryParams] = useSearchParams()
	const { setFieldValue } = useFormikContext()
	
	const handleCustomerQueryParams = () => {
		const customerId = queryParams.get(queryParamKeys.customerID)
		const customerName = queryParams.get(queryParamKeys.customerName)
		
		if(customerId && customerName) {
			setFieldValue("filter", "customer").then().catch()
			setFieldValue("display_filter", "Customer").then().catch()
			setFieldValue("customer", customerId).then().catch()
			setFieldValue("display_customer", customerName).then().catch()
			
			dispatchFn({
				filterValue: "customer",
				type: debounceActions.SET_FILTER_FIELD
			})
			
			dispatchFn({
				customerValue: customerId,
				type: debounceActions.SET_FILTER_BY_CUSTOMER_FIELD
			})
		}
	}
	
	useEffect(() => {
		handleCustomerQueryParams()
	}, [queryParams])
	
	return <></>
}
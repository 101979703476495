import { CloseSquare } from "iconsax-react"
import { useCategoriesQuery } from "../../../hooks/useServices/useCategoryServices"
import { CreateCategoryModalStyled, ModalLayoutStyled } from "../../../styles/modalStyles"
import { Heading } from "../../../styles/textStyles"
import { FlexStyled, SpaceBetweenStyled } from "../../../styles/utilStyles"
import CategoryCard from "../../Card/CategoryCard"
import Form from "../../Forms/Form"


const SelectFilterCategoryModal = ({ onClose, activateCategory, currentCategory }) => {
  const { data, isSuccess } = useCategoriesQuery()

  const categories = () => {
    const allCategories = [...data?.results]
    return allCategories.splice(4, allCategories?.length - 1)
  }

  const handleSelectCategory = (id) => {
    activateCategory(id)
    onClose()
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Select Category</Heading>

        <Form values={{}}>
          {isSuccess && (
            <SpaceBetweenStyled className="selectCategory">
              {categories().map(category => (
                <CategoryCard 
                  key={category.id} 
                  title={category.name} 
                  onClick={() => handleSelectCategory(category.id)} 
                  active={currentCategory === category.id}
                />
              ))}
          </SpaceBetweenStyled>
          )}
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default SelectFilterCategoryModal;
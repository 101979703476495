import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, Form, Input, TextArea} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {AddCostPriceToMove, handleCostPriceDataFormatting} from "./DistributeProductModal";
import {useProductByIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useParams} from "react-router-dom";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";


const AdjustProductCostPriceModal = ({onClose, handleSubmit, mutation}) => {
  const {productId} = useParams()
  const {data: product} = useProductByIdQuery(productId)
  const {currencyFormatter, numberFormatter, dateOnlyFormatter} = useDataTypeFormatter()
  
  const costPricesArray = product?.cost_prices?.map(cost_price => ({
    ...cost_price,
    name:
      `${currencyFormatter(cost_price.cost_price)} [${numberFormatter(cost_price.qty_remaining)} qty] ${(!!cost_price.expiry_date && cost_price.expiry_date !== "None") ? `- Expiry: ${dateOnlyFormatter(cost_price.expiry_date)}` : ""} ${!!cost_price.current ? "(ACTIVE)" : ""}`
  }))
  
  const handleSubmission = (values) => {
    const formValues = handleCostPriceDataFormatting(values)
    const cost_price_ids = formValues.cost_price_ids.map(cost => ({id: cost.id, cost_price: cost.quantity}))
    handleSubmit({remark: formValues.remark, cost_price_ids})
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Adjust Cost Prices</Heading>
        
        <Form values={{quantity: "", remark: ""}} onSubmit={handleSubmission}>
          {!!costPricesArray && costPricesArray?.length > 0 && (
            <AddCostPriceToMove isAdjustCost cost_prices={costPricesArray}/>
          )}
          
          {(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (
            <Input type="number" name="qty" className="fullWidth" label="New Cost Price"
                   placeholder="Enter cost price"/>
          )}
          
          <TextArea name="remark" placeholder="" label="Remark" className="fullWidth"/>
          
          <FlexStyled>
            <Button type="submit" text="Adjust" isLoading={mutation.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}


export default AdjustProductCostPriceModal

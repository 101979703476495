import {Fragment} from "react";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Form, PasswordInput} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {isPasswordRequirementsValid, isStringLengthValid} from "../../../utils/validator";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const ResetStaffPasswordModal = ({ onClose, formValues, handleSubmit, mutation }) => {
  const { toast } = useGlobalContext()

  const handleStaffPassword = (values) => {
    if(!isStringLengthValid(values.new_password, 8)) {
      toast.error("Password length must be at least 8 characters", values.new_password)
      return
    }
  
    if(!isPasswordRequirementsValid(values.new_password)) {
      toast.error("Password must contain at least an uppercase and lowercase letter, number and a symbol", values.new_password)
      return
    }
    
    handleSubmit(values)
  }
  
  return (
    <Fragment>
      <ModalLayoutStyled onClick={onClose}>
        <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
          <CloseSquare size={28} onClick={onClose} />
          <Heading size={1.3} color="black">Reset Staff Password</Heading>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }} />
          
          <FlexStyled className="fullWidth" style={{ marginBottom: ".8rem" }}>
            <Paragraph bold color="black" size={1.2}>Staff Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.full_name || `${formValues.first_name} ${formValues.last_name}`}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: ".8rem" }}>
            <Paragraph bold color="black" size={1.2}>Business Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.business_details_name || formValues.business_details.name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: ".8rem" }}>
            <Paragraph bold color="black" size={1.2}>Staff Role/Designation: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.designation || "Admin"}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Staff Email Address: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.email}</Paragraph>
          </FlexStyled>
  
          <Form values={{ new_password: "", staff_id: formValues?.id }} onSubmit={handleStaffPassword}>
            <PasswordInput label="New Password" placeholder="Enter new password for staff" name="new_password"  />
    
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text="Reset" type="submit" />
            </FlexStyled>
          </Form>
          
        </CreateCategoryModalStyled>
      </ModalLayoutStyled>
    </Fragment>
  )
}


export default ResetStaffPasswordModal
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, CheckBox, DatePicker, Form, Input, RadioButton, TextArea} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {useActionPermission} from "../../../../hooks/usePermissions";
import {actionsPermissions} from "../../../../data/permissions";
import {AddCostPriceToMove, handleCostPriceDataFormatting} from "./DistributeProductModal";
import {useProductByIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useParams} from "react-router-dom";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {Fragment} from "react";
import {useFormikContext} from "formik";


const ManageStock = ({ onClose, handleSubmit, mutation, mutation2 }) => {
  const { productId } = useParams()
  const { data: product } = useProductByIdQuery(productId)
  const { currencyFormatter, numberFormatter, dateOnlyFormatter } = useDataTypeFormatter()
  
  const costPricesArray = product?.cost_prices?.map(cost_price => ({
    ...cost_price,
    name:
      `${currencyFormatter(cost_price.cost_price)} [${numberFormatter(cost_price.qty_remaining)} qty] ${(!!cost_price.expiry_date && cost_price.expiry_date !== "None") ? `- Expiry: ${dateOnlyFormatter(cost_price.expiry_date)}` : ""} `
  }))
  
  const handleSubmission = (values) => {
    const formValues = handleCostPriceDataFormatting(values)
    handleSubmit({...formValues, quantity: Number(formValues.qty), qty: Number(formValues.qty)})
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Adjust Stock</Heading>

        <Form values={{ quantity: "", remark: "", damaged: false, expiry_date: "" }} onSubmit={handleSubmission}>
          <HandleAdjustType costPricesArray={costPricesArray} mutation={mutation} mutation2={mutation2} />
          {/*{(title === "Reduce Stock" && businessSettings?.faulty_products && isBadAndDamagePermission) && (*/}
          {/*  <CheckBox labelProp="Bad or damaged?" optional name="damaged" />*/}
          {/*)}*/}
          
          {/*{!!costPricesArray && costPricesArray?.length > 0 && (*/}
          {/*  <AddCostPriceToMove cost_prices={costPricesArray} />*/}
          {/*)}*/}
          
          {/*{(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (*/}
          {/*  <Input type="number" name="qty" className="fullWidth" label="Quantity" placeholder="Enter quantity" />*/}
          {/*)}*/}
          
          
          {/*{title === "Add Stock" && (*/}
          {/*  <DatePicker optional name="expiry_date" label="Expiry Date" />*/}
          {/*)}*/}
          
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}


export default ManageStock


const HandleAdjustType = ({ costPricesArray, mutation, mutation2 }) => {
  const { values } = useFormikContext()
  
  const businessSettings = useBusinessSettingQuery()
  const isBadAndDamagePermission = useActionPermission(actionsPermissions.moveProductToBadAndDamage)
  
  return (
    <Fragment>
      <FlexStyled>
        <RadioButton required name="adjust_type" labelProp="Increase Stock" value="increase" id="increase" />
        <RadioButton name="adjust_type" labelProp="Decrease Stock" value="decrease" id="decrease" />
      </FlexStyled>
      
      <FlexStyled></FlexStyled>
  
      {values.adjust_type === "decrease" && (
        <Fragment>
          <div className="fullWidth"></div>
          {businessSettings?.faulty_products && isBadAndDamagePermission && (
            <CheckBox labelProp="Bad or damaged?" optional name="damaged"/>
          )}
    
          {!!costPricesArray && costPricesArray?.length > 0 && (
            <AddCostPriceToMove cost_prices={costPricesArray}/>
          )}
    
          {(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (
            <Input type="number" name="qty" className="fullWidth" label="Quantity" placeholder="Enter quantity"/>
          )}
    
          <TextArea name="remark" placeholder="" label="Remark" className="fullWidth"/>
    
          <FlexStyled>
            <Button type="submit" text="Decrease" isLoading={mutation.isLoading || mutation2.isLoading} />
          </FlexStyled>
        </Fragment>
      )}
  
      {values.adjust_type === "increase" && (
        <Fragment>
          {!!costPricesArray && costPricesArray?.length > 0 && (
            <AddCostPriceToMove cost_prices={costPricesArray} />
          )}
      
          {(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (
            <Input type="number" name="qty" className="fullWidth" label="Quantity" placeholder="Enter quantity" />
          )}
  
          <DatePicker optional name="expiry_date" className="fullWidth" label="Expiry Date" />
  
          <TextArea name="remark" placeholder="" label="Remark" className="fullWidth" />
  
          <FlexStyled>
            <Button type="submit" text="Increase" isLoading={mutation.isLoading || mutation2.isLoading} />
          </FlexStyled>
        </Fragment>
      )}
    </Fragment>
  )
}
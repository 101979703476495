export default [
	{name: "Pending", value: "pending"},
	{name: "Returned", value: "returned"},
	{name: "Confirmed", value: "confirmed"},
	{name: "Cancelled", value: "cancelled"},
	{name: "Credit", value: "credit"},
	{name: "Customer", value: "customer"},
	{name: "Modified", value: "modified"},
]

export const orderStatusOptions = [
	{ name: "Pending", value: "pending" },
	{ name: "Returned", value: "returned" },
	{ name: "Confirmed", value: "confirmed" },
	{ name: "Cancelled", value: "cancelled" },
]

export const invoiceStatusOptions = [
	{ name: "Pending", value: "pending" },
	{ name: "Confirmed", value: "confirmed" },
	{ name: "Cancelled", value: "cancelled" },
]

export const pendingAndConfirmedOptions = [
	{name: "Pending", value: "pending"},
	{name: "Confirmed", value: "confirmed"},
	{name: "Payment Due Date", value: "Payment Due Date"},
]


export const staffAndDateRangeFilter = [
	{ name: "Staff", value: "staff" },
	{ name: "Date Filter", value: "Date Filter" },
]

export const dateFilterType = ["Order Date", "Payment Date", "Payment Due Date", "Due Date", "Date Filter"]
import {
  useProductionProcessByIdQuery, useUpdateProductionLogMutation,
  useUpdateProductionMutation
} from "../../../hooks/useServices/useProductionServices/useProductionService";
import {Fragment, useState} from "react";
import {
  AddProductionStageModal,
  Button, CompleteProductionModal,
  OrderDetailsCard,
  PageHeader,
  ScreenLoading,
  Table,
  TableItemWithStatus, ViewProductionStageModal
} from "../../../components";
import {OrdersTableStyled, SingleOrderHeaderStyled, SingleOrderLayoutStyled} from "../../../styles/singleOrderStyles";
import {ArrowLeft} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useTheme} from "styled-components";
import {getOrderStatus} from "../../../utils/table";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {useModal} from "../../../hooks";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {actionsPermissions} from "../../../data/permissions";
import {useUserProfile} from "../../../hooks/useServices/userHooks";

const ViewProductionProcess = () => {
  const theme = useTheme()
  const { navigate } = useGlobalContext()
  const { data: userProfile } = useUserProfile()
  const [showAddStageModal, setShowAddStageModal] = useModal()
  const [showViewStageModal, setShowViewStageModal] = useModal()
  const [viewStageValues, setViewStageValues] = useState({})
  const [showCompleteModal, setShowCompleteModal] = useModal()
  
  const { data: productionProcess, refetch, status, isSuccess: isProductionProcessSuccess } = useProductionProcessByIdQuery()
  const { productionCard, staffCard, quantityCard } = useProductionCardDetails(productionProcess)
  const updateProductionMutation = useUpdateProductionMutation({ successFn: setShowAddStageModal })
  const updateProductionLogMutation = useUpdateProductionLogMutation({ successFn: setShowViewStageModal })
  
  const { productionStageTable } = tableHeadersAndValueKeys
  
  const {
    changeProductionStage: changeProductionStagePermission,
    completeProduction: completeProductionPermission,
  } = actionsPermissions
  
  const handleAddStageToProcess = (values) => {
    updateProductionMutation.mutate(values)
  }
  
  const handleViewStage = (values) => {
    if(userProfile?.[0]?.user_type === "client_admin" || values.assigned_to.map(staff => staff.id).includes(userProfile?.[0]?.id)) {
      setViewStageValues(values)
      setShowViewStageModal()
    }
  }
  
  const handleUpdateProductionStage = (values) => {
    updateProductionLogMutation.mutate(values)
  }
  
  const isAllStagesCompletedAndNotConfirmed = () => {
    return isProductionProcessSuccess && !(productionProcess?.production_logs?.find(log => log.status === "pending"))
  }
  
  const handleCompleteProduction = (values) => {
    updateProductionMutation.mutate(values)
  }
  
  return (
    <div>
      {status === "error" && (
        <ScreenLoading refetchFn={refetch} />
      )}
  
      {showAddStageModal && (
        <AddProductionStageModal
          formValues={productionProcess}
          mutation={updateProductionMutation}
          handleSubmit={handleAddStageToProcess}
          onClose={setShowAddStageModal}
        />
      )}
  
      {showViewStageModal && (
        <ViewProductionStageModal
          formValues={{ ...viewStageValues, productStatus: productionProcess.status }}
          mutation={updateProductionLogMutation}
          handleSubmit={handleUpdateProductionStage}
          onClose={setShowViewStageModal}
        />
      )}
  
      {showCompleteModal && (
        <CompleteProductionModal
          onClose={setShowCompleteModal}
          formValues={productionProcess}
          handleSubmit={handleCompleteProduction}
          mutation={updateProductionMutation}
        />
      )}
      
      <PageHeader title={productionProcess?.product_name} />
      
      <Fragment>
        <SingleOrderHeaderStyled>
          <FlexStyled className="navigation_section">
            <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
            <FlexStyled>
              <Paragraph noTop color="menuGray">Back to production</Paragraph>
              <FlexStyled className="id_and_status">
                <Heading color="black">Batch Number: <span>{productionProcess?.batch_number}</span></Heading>
                {getOrderStatus(productionProcess?.status)}
              </FlexStyled>
            </FlexStyled>
          </FlexStyled>
        </SingleOrderHeaderStyled>
  
        <SingleOrderLayoutStyled>
          <div className="main">
            {isProductionProcessSuccess && (
              <SpaceBetweenStyled className="order_staff_bank">
                <OrderDetailsCard title="Production" data={productionCard} />
                <OrderDetailsCard title="Summary" data={quantityCard} />
                <OrderDetailsCard title="Staff" data={staffCard} />
              </SpaceBetweenStyled>
            )}
          </div>
        </SingleOrderLayoutStyled>
  
        <OrdersTableStyled>
          <SpaceBetweenStyled className="product_expiry">
            <Heading size={1.4}  color="black">Production Stages</Heading>
            {(productionProcess?.status === "in_progress") && (
              <FlexStyled>
                <Button bgColor="primary" text="Add Stage" permissionCode={changeProductionStagePermission} small onClick={setShowAddStageModal} />
                {isAllStagesCompletedAndNotConfirmed() && (
                  <Button permissionCode={completeProductionPermission} isLoading={updateProductionMutation.isLoading} bgColor="black" text="Complete Production" small onClick={setShowCompleteModal} />
                )}
              </FlexStyled>
            )}
          </SpaceBetweenStyled>
    
          <Table headers={productionStageTable.headers} equalWidth noCols={productionStageTable.headers.length}>
            {isProductionProcessSuccess && productionProcess?.production_logs?.length > 0 && (
              productionProcess?.production_logs?.map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={productionStageTable.values}
                  statusPosition={4}
                  key={k}
                  data={items}
                  onView={handleViewStage}
                />
              )
            )}
      
            {isProductionProcessSuccess && productionProcess?.production_logs?.length === 0 && (
              <NoQueryData text="No Production Stage" />
            )}
      
            {!isProductionProcessSuccess && (
              <TableSkeleton />
            )}
          </Table>
        </OrdersTableStyled>
      </Fragment>
    </div>
  )
}


export default ViewProductionProcess


const useProductionCardDetails = (productionProcess) => {
  const { dateFormatter, numberFormatter } = useDataTypeFormatter()
  
  const productionCard = [
    { key: "Product Name", value: productionProcess?.product_name },
    { key: "Projected Quantity", value: numberFormatter(productionProcess?.projected_quantity) },
    { key: "Available Quantity", value: numberFormatter(productionProcess?.projected_quantity) },
    { key: "Date", value: dateFormatter(productionProcess?.date_created || new Date()) },
  ]
  
  const quantityCard = [
    { key: "Projected Quantity", value: numberFormatter(productionProcess?.projected_quantity) },
    { key: "Available Quantity", value: numberFormatter(productionProcess?.available_quantity) },
    { key: "Produced Quantity", value: numberFormatter(productionProcess?.produced_quantity) },
  ]
  
  const staffCard = [
    { key: "Name", value: "No staff " },
    { key: "Email", value: "danielolulowo3@gmail.com" },
    { key: "Designation", value: "Default" },
  ]
  
  return { productionCard, staffCard, quantityCard }
}
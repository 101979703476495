import {useUserProfile} from "../../useServices/userHooks";
import {useNavigate} from "react-router-dom";

const useAllowOnlyOvaloopStaff = () => {
  const navigate = useNavigate()
  const { data: user, isSuccess: isUserSuccess } = useUserProfile()
  
  if(isUserSuccess && user?.[0]?.user_type !== "staff") {
    navigate("/dashboard")
  }
}


export default useAllowOnlyOvaloopStaff
import {useReducer} from "react";
import {getIsDesktopOnlineStatus, setIsDesktopOnlineStatus} from "../../utils";
import {PAGINATION_COUNT_FOR_OFFLINE_SYNCING} from "../../utils/constants";

export const desktopOnlineStatusReducerActions = {
  SWITCH_DESKTOP_STATUS: "SWITCH_DESKTOP_STATUS",
  SET_PRODUCT_TO_SYNC: "SET_PRODUCT_TO_SYNC",
  SET_CUSTOMER_TO_SYNC: "SET_CUSTOMER_TO_SYNC",
  CLEAR_PRODUCT_TO_SYNC: "CLEAR_PRODUCT_TO_SYNC",
  UPDATE_FETCH_DATA_STATUS: "UPDATE_FETCH_DATA_STATUS",
  CLEAR_CUSTOMER_TO_SYNC: "CLEAR_CUSTOMER_TO_SYNC",
  RE_SYNC_ALL_DATA: "RE_SYNC_ALL_DATA"
}


const desktopOnlineStatusReducer = (state, action) => {
  switch (action.type) {
    case desktopOnlineStatusReducerActions.SWITCH_DESKTOP_STATUS:
      setIsDesktopOnlineStatus(action.status)
      return { ...state, status: action.status }
    
    case desktopOnlineStatusReducerActions.UPDATE_FETCH_DATA_STATUS:
      return { ...state, [action.dataType]: true }
  
    case desktopOnlineStatusReducerActions.SET_PRODUCT_TO_SYNC: {
      const products = action.allProducts.results || []
      const totalAvailableProducts = [...state.productsToSync, ...products]
      const percentageProducts = (action.allProducts.count === 0) ? 100 : Math.ceil((totalAvailableProducts.length / action.allProducts.count) * 100)
  
      if (state.monitorProductLatestKey === products[0]?.id) {
        return {...state}
      }
  
      if (!products[0]?.id) {
        return {
          ...state,
          isProductCompleted: true,
          completedProgress: Number(state.completedProgress) > 49 ? 100 : Math.ceil(((100 + state.completedProgress) / 200) * 100)
        }
      }
  
      const completedProgress = Math.ceil(((percentageProducts + state.customerSyncingData.customerCompletedProgress) / 200) * 100)
  
      return {
        ...state,
        productsToSync: totalAvailableProducts,
        completedProgress: completedProgress,
        monitorProductLatestKey: products[0]?.id,
        productFetchCount: state.productFetchCount + PAGINATION_COUNT_FOR_OFFLINE_SYNCING,
        isProductCompleted: (percentageProducts === 100) || action?.["isCompleted"] || false,
      }
    }
  
    case desktopOnlineStatusReducerActions.SET_CUSTOMER_TO_SYNC: {
      const customers = action.allCustomers.results || []
      const totalAvailableCustomers = [...state.customerSyncingData.customersToSync, ...customers]
      const percentageCustomers = (action.allCustomers.count === 0) ? 100 : Math.ceil((totalAvailableCustomers.length / action.allCustomers.count) * 100)
  
      if ((state.customerSyncingData.monitorCustomerLatestKey === customers[0]?.id)) {
        return {...state}
      }
  
      if (!customers[0]?.id) {
        return {
          ...state,
          customerSyncingData: {
            ...state.customerSyncingData,
            customerCompletedProgress: 100,
            isCustomerCompleted: true,
          },
          completedProgress: Number(state.completedProgress) > 49 ? 100 : Math.ceil(((100 + state.completedProgress) / 200) * 100)
        }
      }
  
      return {
        ...state,
        customerSyncingData: {
          customersToSync: totalAvailableCustomers,
          customerCompletedProgress: percentageCustomers,
          monitorCustomerLatestKey: customers[0]?.id,
          customerFetchCount: state.customerSyncingData.customerFetchCount + PAGINATION_COUNT_FOR_OFFLINE_SYNCING,
          isCustomerCompleted: (percentageCustomers === 100) || action?.["isCompleted"] || false,
        },
        completedProgress: Math.ceil(((percentageCustomers + state.completedProgress) / 200) * 100)
      }
    }
  
    case desktopOnlineStatusReducerActions.CLEAR_PRODUCT_TO_SYNC: {
      return {
        ...state,
        productsToSync: [],
        completedProgress: 0,
        monitorProductLatestKey: "",
        productFetchCount: 0,
        isProductCompleted: false,
        isGroupOrderCompleted: false,
        isSingleOrderCompleted: false,
        isCustomerCompleted: false
      }
    }
  
    case desktopOnlineStatusReducerActions.CLEAR_CUSTOMER_TO_SYNC: {
      return {
        ...state,
        customerSyncingData: {
          customersToSync: [],
          customerCompletedProgress: 0,
          monitorCustomerLatestKey: "",
          customerFetchCount: 0,
          isCustomerCompleted: false
        }
      }
    }
  
    case desktopOnlineStatusReducerActions.RE_SYNC_ALL_DATA: {
      return {
        re_sync: action.re_sync,
        status: getIsDesktopOnlineStatus(),
        productsToSync: [],
        customerSyncingData: {
          customersToSync: [],
          customerCompletedProgress: 0,
          monitorCustomerLatestKey: "",
          customerFetchCount: 0,
          isCustomerCompleted: false
        },
        completedProgress: 0,
        monitorProductLatestKey: "",
        productFetchCount: 0,
        isProductCompleted: false,
        isGroupOrderCompleted: false,
        isSingleOrderCompleted: false,
        isCustomerCompleted: false
      }
    }
  
    default:
      return state
  }
}

export const desktopOnlineStatusReducerInitialValues = {
  status: getIsDesktopOnlineStatus(),
  productsToSync: [],
  customerSyncingData: {
    customersToSync: [],
    customerCompletedProgress: 0,
    monitorCustomerLatestKey: "",
    customerFetchCount: 0,
    isCustomerCompleted: false
  },
  completedProgress: 0,
  monitorProductLatestKey: "",
  productFetchCount: 0,
  isProductCompleted: false,
  isGroupOrderCompleted: false,
  isSingleOrderCompleted: false,
  isCustomerCompleted: false,
  re_sync: false
}

const useDesktopOnlineStatusReducer = () => {
  return useReducer(desktopOnlineStatusReducer, desktopOnlineStatusReducerInitialValues)
}

export default useDesktopOnlineStatusReducer
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, TextArea} from "../../../index";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import CropImage from "../../CropImage";


const EditCategoryModal = ({ onClose, handleSubmit, mutation, formValues }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>

        <Form values={formValues} onSubmit={handleSubmit}>
          <Input label="Category Name" placeholder="e.g Electronic" name="name"  />
          <TextArea optional label="Describe Category" placeholder="Enter a description for this category" name="description" />
          <CropImage optional width={400} height={600} className="fullWidth" name="image" placeholder="Choose Category Image" />
          
          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Save" type="submit" />
          </FlexStyled>
        </Form>

      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditCategoryModal
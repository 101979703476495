import {Button, Image} from "../index";
import {Heading} from "../../styles/textStyles";
import {ProductCardStyled} from "../../styles/cardStyles";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {sellReducerActions} from "../../reducers/sellReducer";
import {NoProductImage} from "../../assets/images";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import useSellContext from "../../hooks/useContexts/useSellContext";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {disableSellBelowStockWhenOfflineForAccount} from "../../utils/manageDisplayOfFeatures";


const ProductCard = ({product, setAddToCartModal}) => {
  const {data: userProfile} = useUserProfile()
  const {reducer, selectedCustomer} = useSellContext()
  const businessSettings = useBusinessSettingQuery()
  const {currencyFormatter} = useDataTypeFormatter()
  const {toast, isOffline} = useGlobalContext()
  const {image_path, name, selling_price, stock_unit} = product
  
  const disableSellBelowStockWhenOffline = (isOffline && disableSellBelowStockWhenOfflineForAccount(userProfile?.[0]?.account))
  
  const handleAddToCartWithModal = (product) => {
    if ((!isOffline || disableSellBelowStockWhenOffline) && (!businessSettings.sell_below_stock && stock_unit < 1 && product.use_stock)) {
      toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
      return
    }
    
    reducer({
      product: {
        ...product,
        ...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
          applied_discount: selectedCustomer?.customer_discount,
          previous_applied_discount: product?.applied_discount
        })
      },
      type: sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL
    })
  
    setAddToCartModal()
    // isMobile ?
    //   navigate("/dashboard_sell/cart") :
    //   setAddToCartModal()
  }

  const handleAddToCartWithoutModal = (product) => {
    if ((!isOffline || disableSellBelowStockWhenOffline) && (!businessSettings.sell_below_stock && stock_unit < 1 && product.use_stock)) {
      toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
      return
    }
  
    if (!!product?.meta_measurement && product?.meta_measurement?.length > 0) {
      handleAddToCartWithModal(product)
      return
    }
  
    if (Number(product?.faulty_stock_unit || 0) > 0) {
      handleAddToCartWithModal(product)
      return;
    }
  
    const otherData = function () {
      if (!Number(product?.selling_price) && !!product?.meta_measurement && product?.meta_measurement?.length > 0) {
        const selling_price = Number(product?.meta_measurement?.[0]?.selling_price)
        const selected_unit_measurement = {...product?.meta_measurement?.[0], quantity: 1}
      
        return {selling_price, selected_unit_measurement}
      }
    
      return {selling_price: Number(product?.selling_price)}
    }()
  
    reducer({
      product: {
        ...product,
        ...otherData,
        unit: Number(product.unit_increment) || 1,
        isWithoutModal: true,
        ...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
          applied_discount: selectedCustomer?.customer_discount,
          previous_applied_discount: product?.applied_discount
        })
      },
      type: sellReducerActions.ADD_PRODUCT_TO_CART
    })
  }

  return (
    <ProductCardStyled onClick={handleAddToCartWithModal.bind(this, product)}>
      <div className="img_div">
        <Image src={image_path || NoProductImage} alt={name} />
      </div>
      <div>
        <Heading color="black" size={1.2}>{name}</Heading>
  
        <SpaceBetweenStyled>
          <Heading thin color="black" size={1}>{currencyFormatter(selling_price)}</Heading>
          {(!!product.use_stock && (!isOffline || disableSellBelowStockWhenOffline)) && (
            <Heading thin color={stock_unit > 0 ? "success" : "error"} size={1}>
              {stock_unit} in stock
            </Heading>
          )}
    
          {(!product.use_stock || (isOffline && !disableSellBelowStockWhenOffline)) &&
            <Heading thin size={1} color="black">N/A</Heading>}
        </SpaceBetweenStyled>

        <Button
          className="add_to_cart"
          text="Add To Cart"
          onClick={() => handleAddToCartWithoutModal(product)}
        />
      </div>
    </ProductCardStyled>
  )
}

export default ProductCard
import {StoreFrontOrderHistoryCardStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {Paragraph} from "../../../../styles/textStyles";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {Image} from "../../../../components";
import {WIPStatus} from "../../../../utils/table";
import PaymentModal from "../../../../components/Modal/PaymentModal";
import {Fragment} from "react";
import useModal from "../../../../hooks/useModal";

const StoreFrontOrderHistoryCard = ({ order }) => {
  const { nairaCurrencyFormatter, dateOnlyFormatter } = useDataTypeFormatter()
  const [showOvaloopTransferModal, setShowOvaloopTransferModal] = useModal()
  
  const isOvaloopTransferAndNotConfirmed = () => {
    return (!order.paid && order.pay_mode === "ovaloop_transfer")
  }
  
  return (
    <Fragment>
      {showOvaloopTransferModal && (
        <PaymentModal
          payment_details={order.ovaloop_transfer}
          order={order}
          onClose={setShowOvaloopTransferModal}
          dynamicAccountBody={{ group_order: order.group_order?.id }}
          verifyAccountQueryParam={{ group_order_id: order.group_order?.id }}
        />
      )}
      
      <StoreFrontOrderHistoryCardStyled>
        <SpaceBetweenStyled>
          <FlexStyled className="image_and_order_summary" $gap={1.5}>
            <Image src={order.product.image_path} />
        
            <FlexColumnStyled className="summary">
              <Paragraph color="black" noTop size={1.2}>{order.product.name}</Paragraph>
              <Paragraph color="secondary" noTop size={1.2}>Quantity: {order.qty} {order.product.unit_measurement}</Paragraph>
              <Paragraph color="secondary" noTop size={1.2}>Order ID: {order.order_id}</Paragraph>
              <Paragraph color="secondary" noTop size={1.2}>Date: {dateOnlyFormatter(order.date_created)}</Paragraph>
              
              <FlexStyled>
                <Paragraph color="black" size={1} className="id_and_status">{WIPStatus(order.bulk_details.status)}</Paragraph>
                {isOvaloopTransferAndNotConfirmed() && <Paragraph color="black" onClick={setShowOvaloopTransferModal}><u>Confirm Payment</u></Paragraph>}
              </FlexStyled>
            </FlexColumnStyled>
          </FlexStyled>
      
          <FlexColumnStyled className="address_and_other">
            <Paragraph color="black" bold noTop size={1.2}>{nairaCurrencyFormatter(order.total_cost)}</Paragraph>
            <Paragraph color="secondary" noTop size={1.2}>Address: <br/> {order.delivery_address}</Paragraph>
            <Paragraph color="secondary" noTop size={1.2}>Contact Number: <br/> {order.delivery_phone_number}</Paragraph>
          </FlexColumnStyled>
        </SpaceBetweenStyled>
      </StoreFrontOrderHistoryCardStyled>
    </Fragment>
  )
}


export default StoreFrontOrderHistoryCard
import {
  NoDataLayout, PageHeader,
   PageSummaryCard, ScreenLoading, SearchAndFilter,
  Table, TableItemForGroupOrders,
  TablePagination
} from "../../../components";

import {Fragment} from "react";
import {EmptyBoxImage} from "../../../assets/images";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useGroupOrderWithDueDateByBusinessIdQuery
} from "../../../hooks/useServices/useOrderServices";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import orderQueryKey from "../../../hooks/useServices/useOrderServices/orderQueryKey";
import {getOrderServiceStructure} from "../../../services/orderServices/orderServiceStructure";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {dateFilterValue} from "../../../data/selectField/dateFilter";


const OrdersWithDueDate = () => {
  const { navigate } = useGlobalContext()
  const { numberFormatter } = useDataTypeFormatter()
  const { orderWithDueDateTable } = tableHeadersAndValueKeys
  const { toDate, fromDate } = handleDateFilterValue(dateFilterValue.NEXT_N_DAYS, 10)
  const { debounceState, debounceReducer } = useQueryDebounce({ fromDateValue: fromDate, toDateValue: toDate, searchValue: "" })
  
  const { mainQuery, ...allGroupOrders } = useOrderQueryWithSearchAndFilter(debounceState, useGroupOrderWithDueDateByBusinessIdQuery)
  
  const handleViewSingleOrder = (groupId) => {
    navigate(`/dashboard/orders/${groupId}`)
  }

  return (
    <div>
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allGroupOrders.refetch} />
      )}
      
      <Fragment>
        <PageHeader title="Sales With Due Date" />

        {allGroupOrders.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No orders"
              imgSrc={EmptyBoxImage}
              heading="No orders yet"
            />
          </Fragment>
        )}

        {allGroupOrders.showTable && (
          <Fragment>
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allGroupOrders.isQueryLoading}
                title="Total Number Of Orders"
                value={numberFormatter(allGroupOrders?.searchQuery?.data?.count || 0)}
              />
            </PageSummaryStyled>

            <SetUpLayoutStyled>

              <SearchAndFilter fromDateValue={debounceState.fromDateValue} toDateValue={debounceState.toDateValue} noFilter searchPlaceholder="Search by Id" dateFilter dispatch={debounceReducer} />

              <Table bg headers={orderWithDueDateTable.headers} noCols={orderWithDueDateTable.headers.length}>
                {allGroupOrders.isQueryWithData && (
                  allGroupOrders.data?.results.map((items, k) =>
                    <TableItemForGroupOrders
                      statusPosition={6}
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allGroupOrders.currentCount}
                      keys={orderWithDueDateTable.values}
                      onView={handleViewSingleOrder}
                    />
                  )
                )}

                {allGroupOrders.isQueryWithNoData && (
                  <NoQueryData text="No orders with due date" />
                )}

                {allGroupOrders.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allGroupOrders.showPagination && (
                <TablePagination
                  query={allGroupOrders}
                  restructureFn={getOrderServiceStructure}
                  queryKey={orderQueryKey.withDueDate(debounceState)}
                />
              )}
            </SetUpLayoutStyled>
            {allGroupOrders.showPagination && (
              <TablePagination
                query={allGroupOrders}
                restructureFn={getOrderServiceStructure}
                queryKey={orderQueryKey.withDueDate(debounceState)}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default OrdersWithDueDate;
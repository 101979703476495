import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const getProductionStagesService = async (query) => {
  try {
    const url = handleQueryParams("/production_stage", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const createProductionStagesService = async (body) => {
  try {
    const { data } = await axios.post(`/production_stage/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateProductionStagesService = async (id, body) => {
  try {
    const { data } = await axios.patch(`/production_stage/${id}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const deleteProductionStagesService = async (id) => {
  try {
    const { data } = await axios.delete(`/production_stage/${id}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
import {
  CreateCategoryModal,
  EditCategoryModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  categoryQueryKeys,
  useCategoriesQuery,
  useCreateCategoryMutation, useDeleteCategoryMutation,
  useEditCategoryMutation
} from "../../../../hooks/useServices/useCategoryServices";
import {CategoryImage} from "../../../../assets/images";
import {SetUpLayoutStyled, SetUpMainStyled} from "../SetUpStyles";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import {categoryFormDefaultValues} from "../../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../../data/permissions";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../../desktop/sendDesktopNotification";


const Category = () => {
  const queryClient = useQueryClient()
  const { toast, navigate } = useGlobalContext()

  const {
    createCategory: createCategoryPermission,
    updateCategory: updateCategoryPermission,
    deleteCategory: deleteCategoryPermission
  } = actionsPermissions
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useModal(modalNames.CREATE_CATEGORY_MODAL)
  const [showEditCategoryModal, setShowEditCategoryModal] = useModal()
  
  const { categoriesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allCategories } = useSearchQuery(debounceState, useCategoriesQuery)
  const [editCategoryFormValues, setEditCategoryFormValues] = useState(categoryFormDefaultValues)
  const deleteCategoryMutation = useDeleteCategoryMutation({ successFn: () => {} })
  const editCategoryMutation = useEditCategoryMutation({ successFn: setShowEditCategoryModal })
  const createCategoryMutation = useCreateCategoryMutation({ successFn: setShowCreateCategoryModal })

  const handleCreateCategory = (values) => {
    createCategoryMutation.mutate(values)
  }

  const handleEditCategory = (values) => {
    editCategoryMutation.mutate(values)
  }

  const handleEditAction = (category) => {
    setEditCategoryFormValues(category)
    setShowEditCategoryModal()
  }

  const handleDeleteAction = (category) => {
    toast.confirm(
      "Are you sure?",
      deleteCategoryMutation.mutate.bind(this, category.id),
      "delete"
    )
  }

  const handleSubCategory = (category) => {
    navigate(`/dashboard/set-up/categories/${category.name}/${category.id}`)
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.category }).then(() => {
      queryClient.refetchQueries({ queryKey: desktopQueryKeys.subCategory }).then(() => {
        sendDesktopNotification({
          title: "Sync successful",
          body: "All categories has been synced offline"
        })
      })
    })
  }

  return (
    <SetUpMainStyled>
      {showCreateCategoryModal && <CreateCategoryModal onClose={setShowCreateCategoryModal} handleSubmit={handleCreateCategory} mutation={createCategoryMutation} />}
      {showEditCategoryModal && <EditCategoryModal onClose={setShowEditCategoryModal} handleSubmit={handleEditCategory} mutation={editCategoryMutation} formValues={editCategoryFormValues} />}

      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allCategories.refetch} />
      )}
      
      <Fragment>
        {allCategories.isEmpty && (
          <Fragment>
            <PageHeader title="Categories" />

            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={CategoryImage}
              heading="No Category yet"
              subHeading="Add category that classifies your product base on type, model etc."
              buttonText="Add new category"
              buttonClick={setShowCreateCategoryModal}
              permissionCode={createCategoryPermission}
            />
          </Fragment>
        )}

        {allCategories.showTable && (
          <Fragment>
            <PageHeaderWithButton
              isBack
              noBackText
              extra="Level 1"
              Icon={AddCircle}
              title="Category List"
              buttonText="Create"
              buttonClick={setShowCreateCategoryModal}
              permissionCode={createCategoryPermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "categories", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search category by name"
              />

              <Table headers={categoriesTable.headers} noCols={3} equalWidth lastHeaderPosition="flex-end">
                {allCategories.isQueryWithData && (
                  allCategories.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allCategories.currentCount}
                      actionPosition="flex-end"
                      onClick={handleSubCategory}
                      keys={categoriesTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateCategoryPermission}
                      deletePermission={deleteCategoryPermission}
                    />
                  )
                )}

                {allCategories.isQueryWithNoData && (
                  <NoQueryData text={allCategories.isSearchEmptyMessage("Category", "")} />
                )}

                {allCategories.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allCategories.showPagination &&
                <TablePagination
                  query={allCategories}
                  queryKey={categoryQueryKeys.mainList(allCategories.queryValues)}
                />
              }
            </SetUpLayoutStyled>
            {!!allCategories.showPagination &&
              <TablePagination
                query={allCategories}
                queryKey={categoryQueryKeys.mainList(allCategories.queryValues)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Category
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createCategoryService,
  deleteCategoryService,
  editCategoryService,
  getCategoriesService
} from "../../../services/categoryServices";
import {getSubCategoriesByCategoryIdService} from "../../../services/subCategoryServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {useLocation} from "react-router-dom";
import {useProgressModalConnector} from "../../useModalConnector";
import modalNames from "../../useModalConnector/modalNames";
import {getBusinessIdFromStorage} from "../../../utils";
import {subCategoryQueryKeys} from "../useSubCategoryServices";
import {useDesktopCategoriesServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const main = ["categories", getBusinessIdFromStorage()]
export const categoryQueryKeys = {
  main: [...main],
  mainList: (search) => [...main, search]
}


const useCategoriesQuery = (searchValue = "") => {
  const { getBusinessId, isOffline } = useGlobalContext()
  
  const offlineData = useDesktopCategoriesServiceFromLocalDB(searchValue)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: categoryQueryKeys.mainList(searchValue),
    queryFn: () => getCategoriesService({ business_id: getBusinessId(), search: searchValue })
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCreateCategoryMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {pathname} = useLocation()
  const queryClient = useQueryClient()
  const progressModalConnector = useProgressModalConnector()
  const {toast, getBusinessId, globalState} = useGlobalContext()
  
  
  return useMutation({
    mutationFn: data => {
      return createCategoryService({...data, business: getBusinessId()})
    },
    
    onSuccess: (res) => {
      successFn()
      toast.success("Category Created!!")
      queryClient.invalidateQueries({queryKey: categoryQueryKeys.main})
      
      if (globalState.modalConnector.toModalURL === pathname) {
        progressModalConnector({
          toModalName: modalNames.CREATE_SUBCATEGORY_MODAL,
          toModalURL: `/dashboard/set-up/categories/${res.name}/${res.id}`
        })
      }
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createCategory,
      })
    }
  })
}

const useEditCategoryMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editCategoryService(data.id, data)
    },
  
    onSuccess: (res) => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({queryKey: categoryQueryKeys.main})
    
      queryClient.prefetchQuery({
        queryKey: subCategoryQueryKeys.mainList({categoryId: res.id, searchValue: ""}),
        queryFn: () => getSubCategoriesByCategoryIdService(res.id, "")
      })
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateCategory,
      })
    }
  })
}

const useDeleteCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: categoryId => {
      return deleteCategoryService(categoryId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: categoryQueryKeys.main })
      queryClient.invalidateQueries({ queryKey: subCategoryQueryKeys.main })

    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useCategoriesQuery, useCreateCategoryMutation, useEditCategoryMutation, useDeleteCategoryMutation }
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";

const getDashboardAnalyticsByBusinessIdService = async (businessId, from_date, to_date) => {
	let url = `/analytics/?business_id=${businessId}`

	if(!!to_date && !!from_date) {
		url = `/analytics/?business_id=${businessId}&from_date=${from_date}&to_date=${to_date}`
	}

	try {
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getStaffDashboardAnalyticsByIdService = async ({ business_id, staffId, from_date, to_date }) => {
	let url = `/staff_customer_analytics/?staff_id=${staffId}&business_id=${business_id}`

	if(!!from_date && !!to_date) {
		url = `${url}&from_date=${from_date}&to_date=${to_date}`
	}

	try {
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getCustomerDashboardAnalyticsByIdService = async (queryParams) => {
	try {
		const url = handleQueryParams("staff_customer_analytics/", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getProductAnalyticsByIdService = async (businessId, productId) => {
	try {
		const { data } = await axios.get(`/product_analytics/?product_id=${productId}&business_id=${businessId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getBusinessProductAnalyticsByService = async (queryParams) => {
	try {
		const url = handleQueryParams("/product_analytics/", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


const getExpensesAnalyticsByBusinessIdService = async (queryParams = {}) => {
	try {
		const url = handleQueryParams("expense_analytics/", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getPurchaseOrderAnalyticsByBusinessIdService = async (queryParams = {}) => {
	try {
		const url = handleQueryParams("purchase_order_analytics/", queryParams)
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getOrderAnalytics = async () => {
	try {
	
	} catch(e) {
	
	}
	
}

export const getOrderAnalyticsByBusinessIdService = async ({ business_id, from_date, to_date }) => {
	let url = `/order_analytics/?business_id=${business_id}`
	
	if(!!to_date && !!from_date) {
		url = `${url}&from_date=${from_date}&to_date=${to_date}`
	}
	
	try {
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const getSubscriptionAnalyticsByBusinessIdService = async ({ businessId, from_date, to_date }) => {
	let url = `/subscription_analytics/?business_id=${businessId}`
	
	if(!!to_date && !!from_date) {
		url = `${url}&from_date=${from_date}&to_date=${to_date}`
	}
	
	try {
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export {
	getExpensesAnalyticsByBusinessIdService,
	getDashboardAnalyticsByBusinessIdService, getStaffDashboardAnalyticsByIdService,
	getCustomerDashboardAnalyticsByIdService, getProductAnalyticsByIdService, getBusinessProductAnalyticsByService
}
import {Fragment, useEffect} from "react";
import {useModal} from "../../hooks";
import {HambergerMenu, Notification} from "iconsax-react";
import {useLocation} from "react-router-dom";
import {FlexStyled} from "../../styles/utilStyles";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import UserNavigationProfile from "../Card/UserNavigationProfile";
import DashboardMobileNavDrawer from "./DashboardMobileNavDrawer";
import {DashboardMobileNavStyled} from "../../styles/navigationStyles";
import {Heading2} from "../../styles/textStyles";
import {MenuListStyled} from "../../styles/listStyles";
import {HiOutlineWrenchScrewdriver} from "react-icons/hi2";
import {ListDropDown} from "../index";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import IconWithToolTip from "../Utils/IconWithToolTip";
import useNavigationMenuList from "../../hooks/useUtils/useNavigationMenuList";


const DashboardMobileNav = ({ businessName, openNotification, navMenu }) => {
  const { pathname } = useLocation()
  const { isTablet } = useGlobalContext()
  const [showDrawer, setShowDrawer] = useModal()
  const { data: userProfile, isSuccess } = useUserProfile()
  const { setupNavMenuList } = useNavigationMenuList()
  
  useEffect(() => {
    if(showDrawer) {
      setShowDrawer()
    }
  }, [pathname])
  
  
  return (
    <DashboardMobileNavStyled>
      {showDrawer && <DashboardMobileNavDrawer onClose={setShowDrawer} navMenu={navMenu} />}

      <FlexStyled className="business_name">
        <HambergerMenu size={35} onClick={setShowDrawer} />
        <Heading2 color="black" >{businessName}</Heading2>
      </FlexStyled>
      
      <FlexStyled>
        {/*<Notification color="white" size={25} />*/}
        {isSuccess && (
          <UserNavigationProfile
            firstName={userProfile?.[0]?.first_name}
            lastName={userProfile?.[0]?.last_name}
            companyName={userProfile[0]?.business_name || userProfile[0]?.business_details?.name}
          />
        )}
        
        {!isTablet && (
          <Fragment>
            <MenuListStyled>
              <HiOutlineWrenchScrewdriver color="black" size={28} />
              <div className="container">
                <ListDropDown options={setupNavMenuList} />
              </div>
            </MenuListStyled>
  
            <IconWithToolTip text="" chip="+">
              <Notification size={28} onClick={openNotification} />
            </IconWithToolTip>
          </Fragment>
        )}


      </FlexStyled>
    </DashboardMobileNavStyled>
  )
}

export default DashboardMobileNav
import {useUserProfile} from "../../useServices/userHooks";

export const actionPermissionEnum = {
	NO_DISPLAY: "NO_DISPLAY"
}

const useActionPermission = (permissionCode) => {
	const { isSuccess: isUserProfileAvailable, data: userProfile } = useUserProfile()
	const { user_permission = [], user_type } = userProfile?.[0] || {}
	
	if(permissionCode === actionPermissionEnum.NO_DISPLAY) return false

	if(isUserProfileAvailable) {
		return user_permission.includes(permissionCode) || user_type === "client_admin";
	}
}

export const useActionPermissionFn = () => {
	const { data: userProfile } = useUserProfile()
	const { user_permission = [], user_type } = userProfile?.[0] || {}
	
	const check = (permissionCode) => {
		return user_type === "client_admin" || user_permission?.includes(permissionCode);
	}
	
	return { check }
}


export default useActionPermission
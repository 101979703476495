import {
  CreateDiscountModal,
  EditDiscountModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton,
  ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import {AddCircle} from "iconsax-react";
import {
  discountQueryKeys,
  useCreateDiscountMutation,
  useDeleteDiscountMutation,
  useDiscountQuery,
  useEditDiscountMutation
} from "../../../hooks/useServices/useDiscountServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {discountFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {TaxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import {desktopQueryKeys} from "../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../desktop/sendDesktopNotification";
import {useQueryClient} from "react-query";
import {capitalizeFirstLetterInText} from "../../../utils/textTransformer";


const Discount = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  const [showCreateDiscountModal, setShowCreateDiscountModal] = useModal()
  const [showEditDiscountModal, setShowEditDiscountModal] = useModal()
  const {
    createDiscount: createDiscountPermission,
    updateDiscount: updateDiscountPermission,
    deleteDiscount: deleteDiscountPermission
  } = actionsPermissions
  
  const {discountTable} = tableHeadersAndValueKeys
  const {debounceState, debounceReducer} = useQueryDebounce()
  const {mainQuery, searchQuery, ...allDiscounts} = useSearchQuery(debounceState, useDiscountQuery)
  const [editDiscountFormValues, setEditDiscountFormValues] = useState(discountFormDefaultValues)
  const deleteDiscountMutation = useDeleteDiscountMutation({
    successFn: () => {
    }
  })
  const editDiscountMutation = useEditDiscountMutation({successFn: setShowEditDiscountModal})
  const createDiscountMutation = useCreateDiscountMutation({successFn: setShowCreateDiscountModal})
  
  const handleCreateDiscount = (values) => {
    const mapping_id = values.mapping_id.map(mapping => mapping.id)
    createDiscountMutation.mutate({...values, mapping_id: mapping_id[0]})
  }
  
  const handleEditDiscount = (values) => {
    const mapping_id = values.mapping_id.map(mapping => mapping.id)
    editDiscountMutation.mutate({...values, mapping_id: mapping_id[0]})
  }
  
  const handleEditAction = (discountValue) => {
    const discount = {...discountValue}
    let selectedMapping = [discount?.discount_details]
  
    if (discount?.discount_type === "customer") {
      selectedMapping = [{id: discount?.discount_details?.id, full_name: discount?.discount_details?.name}]
    }
  
    if (discount?.discount_details?.cat_id) {
      discount.category_name_display = discount.discount_details.cat_name
      discount.category_id = discount.discount_details.cat_id
    }
  
    setEditDiscountFormValues({
      ...discount,
      selectedMapping,
      discount_value_type: capitalizeFirstLetterInText(discount.value_type)
    })
    setShowEditDiscountModal()
  }
  
  const handleDeleteAction = (discount) => {
    toast.confirm(
      "Are you sure?",
      deleteDiscountMutation.mutate.bind(this, discount.id),
    )
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.discount }).then(() => {
      sendDesktopNotification({
        title: "Sync successful",
        body: "All discounts has been synced offline"
      })
    })
  }

  return (
    <SetUpMainStyled>
      {showCreateDiscountModal && <CreateDiscountModal onClose={setShowCreateDiscountModal} handleSubmit={handleCreateDiscount} mutation={createDiscountMutation} />}
      {showEditDiscountModal && <EditDiscountModal onClose={setShowEditDiscountModal} handleSubmit={handleEditDiscount} mutation={editDiscountMutation} formValues={editDiscountFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allDiscounts.isEmpty && (
          <Fragment>
            <PageHeader title="Discounts" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={TaxImage}
              heading="No discount yet"
              subHeading="Business tip: Give discounted prices to your customers once in a while. Input the percentage/offer and add the specific
              item you’re doing A promo on this would avoid your inventory logs from being untouched."
              buttonText="Add new discount"
              buttonClick={setShowCreateDiscountModal}
              permissionCode={createDiscountPermission}
            />
          </Fragment>
        )}

        {allDiscounts.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Discount List"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateDiscountModal}
              permissionCode={createDiscountPermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "discounts", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search discount by name"
              />

              <Table headers={discountTable.headers} noCols={discountTable.headers.length}>
                {allDiscounts.isQueryWithData && (
                  allDiscounts.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allDiscounts.currentCount}
                      keys={discountTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateDiscountPermission}
                      deletePermission={deleteDiscountPermission}
                    />
                  )
                )}

                {allDiscounts.isQueryWithNoData && (
                  <NoQueryData text={allDiscounts.isSearchEmptyMessage("Discount", "")} />
                )}

                {allDiscounts.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allDiscounts.showPagination &&
                <TablePagination
                  query={allDiscounts}
                  queryKey={discountQueryKeys.mainList(allDiscounts.queryValues)}
                />
              }
            </SetUpLayoutStyled>
            
            {allDiscounts.showPagination &&
              <TablePagination
                query={allDiscounts}
                queryKey={discountQueryKeys.mainList(allDiscounts.queryValues)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Discount
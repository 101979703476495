import styled from "styled-components";
import {FlexStyled} from "./utilStyles";
import {ButtonStyled, InputStyled} from "./formStyles";

export const CreatePurchaseOrderStyled = styled("div")`
  padding: 2rem;
  width: calc(100% - 6rem) !important;
  form { width: 100%; }
  
  ${FlexStyled}.purchase_details_row {
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;
    
    ${InputStyled} {
      width: calc((100% / 4) - 2rem);
    }
  }
  
  ${ButtonStyled}.add_more_product {
    max-width: 20%;
  }
`

export const ProductSectionForPurchaseOrder = styled("div")`
  padding: 1.5rem;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  border: 1px solid gray;

  > ${FlexStyled} {
    width: 100%;
    justify-content: space-between;

    ${InputStyled} {
      width: calc((100% / 3) - 2rem);
    }
  }

  ${FlexStyled}.product_purchase_action {
    width: 75%;
    margin: 1.3rem 0 .2rem 0;
    justify-content: flex-end;
    
    ${ButtonStyled} {
      min-width: 10%;
      max-width: 17%;
    }
  }
`
import {dateFilterValue, dateFilterValueEnum} from "../data/selectField/dateFilter";
import moment from "moment";

const handleDateFilterValue = (type, nDays) => {
	const todayDate = moment().format().slice(0, 10)
	
	switch (type) {
		case dateFilterValue.TODAY:
			return {toDate: todayDate, fromDate: todayDate}
		
		case dateFilterValue.YESTERDAY:
			return {toDate: getNumberOfDaysBackFromDate(1), fromDate: getNumberOfDaysBackFromDate(1)}
		
		case dateFilterValue.TOMORROW:
			return {toDate: getNumberOfDaysForwardFromDateWithFormat(1), fromDate: todayDate}
		
		case dateFilterValue.THIS_WEEK:
			return {toDate: todayDate, fromDate: getStartOfWeek()}
		
		case dateFilterValue.THIS_MONTH:
			return {toDate: todayDate, fromDate: getCurrentMonth()}
		
		case dateFilterValue.DAY_30:
			return {toDate: todayDate, fromDate: getNumberOfDaysBackFromDate(30)}
		
		case dateFilterValue.DAY_90:
			return { toDate: todayDate, fromDate: getNumberOfDaysBackFromDate(90) }
		
		case dateFilterValue.THIS_YEAR:
			return { toDate: todayDate, fromDate: getStartOfYear() }
		
		case dateFilterValue.ALL_TIME:
			return { toDate: todayDate, fromDate: "2019-01-01" }
		
		case dateFilterValue.NEXT_DAY_30:
			return { toDate: getNumberOfDaysForwardFromDateWithFormat(30), fromDate: todayDate }
		
		case dateFilterValue.NEXT_DAY_90:
			return { toDate: getNumberOfDaysForwardFromDateWithFormat(90), fromDate: todayDate }
		
		case dateFilterValue.NEXT_N_DAYS:
			return { toDate: getNumberOfDaysForwardFromDateWithFormat(nDays), fromDate: getCurrentMonth() }
		
		default:
			return { toDate: todayDate, fromDate: todayDate }
	}
}

export default handleDateFilterValue


const getNumberOfDaysBackFromDate = (noOfDays) => {
	const date = new Date()
	const day = date.getTime() - (noOfDays * 24 * 60 * 60 * 1000);
	date.setTime(day);

	return date.toISOString().slice(0, 10)
}

const getStartOfYear = () => {
	const year = new Date().getFullYear()
	return `${year}-01-01`
}

const getCurrentMonth = () => {
	const month = new Date().getMonth()
	const year = new Date().getFullYear()
	
	if(`${month+1}`.length === 1) {
		return `${year}-0${Number(month) + 1}-01`
	}

	return `${year}-${Number(month) + 1}-01`
}

export const getNumberOfDaysForwardFromDate = (noOfDays) => {
	const date = new Date()
	const day = date.getTime() + (noOfDays * 24 * 60 * 60 * 1000);
	date.setTime(day);
	
	return date
}

export const getNumberOfDaysForwardFromDateWithFormat = (noOfDays) => {
	const date = new Date()
	const day = date.getTime() + (noOfDays * 24 * 60 * 60 * 1000);
	date.setTime(day);
	
	return date.toISOString().slice(0, 10)
}

export const getDaysDifferenceFromCurrentDate = (date) => {
	const currentDate = new Date()
	const toDate = new Date(date)

	const dayDifference = toDate.getTime() - currentDate.getTime()
	
	return Math.ceil(dayDifference / (1000 * 3600 * 24));
}

export const getStartOfWeek = () => {
	const d = new Date();
	const day = d.getDay(),
		diff = d.getDate() - day + (Number(day) === 0 ? -6 : 1); // adjust when day is sunday
	return new Date(d.setDate(diff)).toISOString().slice(0, 10);
}


export const getDateFilterEnumByDateRange = ({fromDateValue, toDateValue}) => {
	for (const [key, value] of Object.entries(dateFilterValue)) {
		const {toDate, fromDate} = handleDateFilterValue(value, 0)
		if (toDate === toDateValue && fromDate === fromDateValue) {
			return dateFilterValueEnum[key]
		}
	}
	return dateFilterValueEnum.CUSTOM_PERIOD
}
import axios from "../../axios";
import {customerSubscriptionRestructure} from "./customerSubscriptionRestructure";


const getCustomerSubscriptionService = async (businessId, searchValue = " ") => {
	try {
		const { data } = await axios.get(`/customer_subscription/?business_id=${businessId}&search=${searchValue}`)
		return customerSubscriptionRestructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getCustomerSubscriptionByIdService = async (businessId, customerId) => {
	try {
		const { data } = await axios.get(`/customer_subscription/?business_id=${businessId}&customer_id=${customerId}`)

		const results = data?.results?.map(subscription => ({
			...subscription,
			subscription_service_name: subscription.subscription_service_details.subscription_service_name
		}))

		return { ...data, results }
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getCustomerActiveSubscriptionByIdService = async (businessId, customerId) => {
	try {
		const { data } = await axios.get(`/customer_subscription/?business_id=${businessId}&customer_id=${customerId}&status=active`)

		const results = data?.results?.map(subscription => ({
			...subscription,
			subscription_service_name: subscription.subscription_service_details.subscription_service_name
		}))

		return { ...data, results }
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const getCustomerSubscriptionPurchaseByIdService = async (subscriptionId) => {
	try {
		const { data } = await axios.get(`/customer_subscription/${subscriptionId}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const createCustomerSubscriptionService = async (body) => {
	try {
		const { data } = await axios.post(`/customer_subscription/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const cancelCustomerSubscriptionPurchaseService = async (subscriptionId) => {
	try {
		const { data } = await axios.patch(`/customer_subscription/${subscriptionId}/`, { status: "inactive" })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const confirmCustomerSubscriptionPurchaseService = async (subscriptionId) => {
	try {
		const { data } = await axios.patch(`/customer_subscription/${subscriptionId}/`, { payment_status: "payment_confirmed" })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export { getCustomerSubscriptionService, getCustomerSubscriptionByIdService, createCustomerSubscriptionService,
}
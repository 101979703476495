import {
  BagHappy,
  Book1,
  Box1,
  Category,
  Celo,
  ChartCircle,
  Clock,
  DocumentCloud,
  MedalStar,
  MoneySend,
  MoneyTick,
  Note,
  Ontology,
  People,
  ReceiptText,
  Setting2,
  ShopAdd,
  TableLamp,
  TruckFast,
  User,
  Wallet1
} from "iconsax-react"

import {IoInfinite} from "react-icons/io5"
import {
  AddOnIcon,
  AllStoresIcon,
  BanksIcon,
  CategoriesIcon,
  CustomerIcon,
  DebtorIcon,
  DiscountIcon,
  InventoryIcon,
  ProductIcon,
  StaffRolesIcon,
  SupplierIcon,
  TaxIcon
} from "../assets/icons"
import {actionsPermissions, routesPermissions} from "./permissions";


const sellMenuNav = [
  { title: "Dashboard", icon: Category, link: "/dashboard/", permission: routesPermissions.viewAnalyticsDashboard },
  
  { title: "Ovaloop Pay", icon: Wallet1, link: "/dashboard/ovaloop_pay/", permission: routesPermissions.viewOvaloopWallet  },
  
  { title: "Make a sale", icon: BagHappy, link: "/dashboard_sell/", permission: routesPermissions.viewSell },
  
  { title: "Sales History", icon: Box1, link: "/dashboard/orders/", permission: routesPermissions.viewListOfSales },
  
  // { title: "Quotation", icon: Book1, link: "/dashboard/invoice/", permission: routesPermissions.viewListOfSales },
  
  // { title: "Sales With Due Date", icon: Box1, link: "/dashboard/orders-with-due-date/", permission: routesPermissions.viewListOfSales },
  
  { title: "Inventory", icon: Note, link: "/dashboard/inventory/product/", permission: routesPermissions.viewListOfProducts },
  
  // { title: "Delivery", icon: Truck, link: "/dashboard/delivery/items/", permission: routesPermissions.viewListOfDeliveries },
  
  // { title: "Index", icon: Wallet1, link: "/dashboard/wallet/" },
  
  { title: "Employees", icon: People, link: "/dashboard/staff/", permission: routesPermissions.viewListOfStaffs },
  
  { title: "Tips", icon: MoneyTick, link: "/dashboard/tips/", permission: routesPermissions.viewListOfStaffs },
  
  { title: "Expenses/Bills", icon: MoneySend, link: "/dashboard/expenses/", permission: routesPermissions.viewListOfExpenses },
  
  { title: "Report", icon: ReceiptText, link: "/dashboard/report/", permission: routesPermissions.viewReport },
  
  { title: "Customer Packages", icon: MedalStar, link: "/dashboard/business_subscription/", permission: routesPermissions.viewCustomerSubscription },
  
  { title: "Table", icon: TableLamp, link: "/dashboard/table/", permission: routesPermissions.viewListOfTables },
  
  { title: "Kitchen Schedule", icon: Clock, link: "/kitchen/", permission: routesPermissions.viewKitchenSchedule },
  
  { title: "Wip", icon: ChartCircle, link: "/dashboard/wip/", permission: actionsPermissions.crateWip }
]


export const setupNavMenu = [
  { title: "All Stores", icon: AllStoresIcon, link: "/dashboard/set-up/all-stores/" },
  { title: "Categories", icon: CategoriesIcon, link: "/dashboard/set-up/categories/", permission: routesPermissions.viewListOfCategories },
  { title: "Tax", icon: TaxIcon, link: "/dashboard/set-up/tax/", permission: routesPermissions.viewListOfTaxes },
  { title: "Add-On(s)", icon: AddOnIcon, link: "/dashboard/set-up/add-ons/", permission: routesPermissions.viewListOfServices },
  { title: "Banks", icon: BanksIcon, link: "/dashboard/set-up/banks/", permission: routesPermissions.viewListOfBanks },
  { title: "Unit Measurement", icon: CategoriesIcon, link: "/dashboard/set-up/unit-measurement/", permission: routesPermissions.viewListOfProducts },
  { title: "Staff Roles", icon: StaffRolesIcon, link: "/dashboard/set-up/staff-roles/", permission: routesPermissions.viewListOfStaffs },
  { title: "Discount", icon: DiscountIcon, link: "/dashboard/set-up/discount/", permission: routesPermissions.viewListOfDiscounts },
  { title: "Expense Category", icon: DiscountIcon, link: "/dashboard/set-up/expense_category/", permission: routesPermissions.viewListOfDiscounts },
  { title: "Tables", icon: TableLamp, link: "/dashboard/set-up/table/", permission: routesPermissions.viewListOfTables },
  { title: "Delivery Rate", icon: TruckFast, link: "/dashboard/set-up/delivery-rate/", permission: routesPermissions.viewListOfDeliveryRates },
  { title: "Payment Method", icon: BanksIcon, link: "/dashboard/set-up/custom-payment-method/", permission: routesPermissions.viewCustomPaymentMethod },
  { title: "Customer Packages", icon: AddOnIcon, link: "/dashboard/set-up/subscriptions/", permission: routesPermissions.viewCustomerSubscription },
  { title: "Activity Logs", icon: CategoriesIcon, link: "/dashboard/set-up/activity-logs/", permission: routesPermissions.viewActivityLogs }
]

const navMenuLists = [
  {title: "Dashboard", icon: Category, link: "/dashboard/", permission: routesPermissions.viewAnalyticsDashboard},
  
  {title: "Ovaloop Pay", icon: IoInfinite, link: "/dashboard/ovaloop_pay/", permission: routesPermissions.openRoute},
  
  {title: "Make a sale", icon: BagHappy, link: "/dashboard_sell/", permission: routesPermissions.viewSell},
  
  {title: "Sales History", icon: Box1, link: "/dashboard/orders/", permission: routesPermissions.viewListOfSales},
  
  // { title: "Sales With Due Date", icon: Box1, link: "/dashboard/orders-with-due-date/", permission: routesPermissions.viewListOfSales },
  
  {
    title: "Inventory", permission: routesPermissions.viewListOfProducts, icon: Note, dropDown: true, options: [
      {
        title: "Products",
        icon: ProductIcon,
        link: "/dashboard/inventory/product/",
        permission: routesPermissions.viewListOfProducts
      },
      {
        title: "Product History",
        icon: InventoryIcon,
        link: "/dashboard/inventory/inventory-logs/",
        permission: routesPermissions.viewListOfInventories
      },
      {
        title: "Expiring Products",
        icon: ProductIcon,
        link: "/dashboard/inventory/expiry-products/",
        permission: routesPermissions.viewListOfInventories
      },
      {
        title: "Purchase",
        icon: ShopAdd,
        link: "/dashboard/inventory/purchase_order/",
        permission: routesPermissions.viewListOfPurchaseOrder
      },
      {
        title: "Supplier",
        icon: SupplierIcon,
        link: "/dashboard/inventory/supplier/",
        permission: routesPermissions.viewListOfSuppliers
      },
      {
        title: "Customers",
        icon: CustomerIcon,
        link: "/dashboard/inventory/customer/",
        permission: routesPermissions.viewListOfCustomers
      },
      {
        title: "Debtors",
        icon: DebtorIcon,
        link: "/dashboard/inventory/debtor/",
        permission: routesPermissions.viewListOfDebtors
      },
    ]
  },
  
  {title: "Quotation", icon: Book1, link: "/dashboard/invoice/", permission: routesPermissions.viewInvoice},
  
  {
    title: "Production", permission: routesPermissions.viewListOfProducts, icon: Note, dropDown: true, options: [
      {
        title: "Products",
        icon: ProductIcon,
        link: "/dashboard/production/products/",
        permission: routesPermissions.viewListOfProducts
      },
      {
        title: "Process",
        icon: ProductIcon,
        link: "/dashboard/production/process/",
        permission: routesPermissions.viewListOfProducts
      },
      {
        title: "Raw Materials",
        icon: ProductIcon,
        link: "/dashboard/production/raw-materials/",
        permission: routesPermissions.viewRawMaterials
      },
      {
        title: "Request Raw Materials",
        icon: ProductIcon,
        link: "/dashboard/production/request-raw-materials/",
        permission: routesPermissions.viewRequestRawMaterialLogs
      },
      {
        title: "Production History",
        icon: InventoryIcon,
        link: "/dashboard/production/history/",
        permission: routesPermissions.viewListOfInventories
      },
      {
        title: "Unit Measurements",
        icon: ShopAdd,
        link: "/dashboard/production/unit-measurements/",
        permission: routesPermissions.viewListOfPurchaseOrder
      },
      {
        title: "Production Stages",
        icon: SupplierIcon,
        link: "/dashboard/production/stages/",
        permission: routesPermissions.viewProductionStages
      },
    ]
  },
  
  {
    title: "Bad and Damage",
    icon: Ontology,
    link: "/dashboard/inventory/faulty-products-logs/",
    permission: routesPermissions.viewBadAndDamageProducts
  },
  
  
  // { title: "Delivery", icon: Truck, dropDown: true, options: [
  //   { title: "Items", icon: ItemIcon, link: "/dashboard/delivery/items/", permission: routesPermissions.viewListOfDeliveries },
  //   { title: "Package Items", icon: PackageIcon, link: "/dashboard/delivery/package-items/" },
  //   { title: "Sent Items", icon: SentIcon, link: "/dashboard/delivery/sent-items/" },
  // ]},
  
  // { title: "Users", icon: People, dropDown: true, permission: routesPermissions.openRoute, options: [
  //
  // ]},
  
  {title: "Employees", icon: People, link: "/dashboard/staff/", permission: routesPermissions.viewListOfStaffs},
  
  {
    title: "Expenses/Bills",
    icon: MoneySend,
    link: "/dashboard/expenses/",
    permission: routesPermissions.viewListOfExpenses
  },
  
  {title: "Report", icon: ReceiptText, link: "/dashboard/report/", permission: routesPermissions.openRoute},
  
  {title: "Tips", icon: MoneyTick, link: "/dashboard/tips/", permission: routesPermissions.viewTips},
  
  {
    title: "Customer Packages",
    permission: routesPermissions.viewCustomerSubscription,
    icon: MedalStar,
    dropDown: true,
    options: [
      {
        title: "Packages",
        icon: MedalStar,
        link: "/dashboard/business_subscription/",
        permission: routesPermissions.viewSubscribeCustomerToSubscriptionPackage
      },
      {
        title: "Purchased Packages",
        icon: Celo,
        link: "/dashboard/business_purchased_subscriptions/",
        permission: routesPermissions.viewCustomerSubscriptionHistory
      }
    ]
  },
  
  {title: "Table", icon: TableLamp, link: "/dashboard/table/", permission: routesPermissions.viewListOfTables},
  
  { title: "Kitchen Schedule", icon: Clock, link: "/kitchen/", permission: routesPermissions.viewKitchenSchedule },
  
  { title: "Wip", icon: ChartCircle, link: "/dashboard/wip/", permission: actionsPermissions.crateWip }
]


const navBottomMenuLists = [
  { title: "Settings", icon: Setting2, link: "/dashboard/settings/" },
]

export const ovaloopStaffDashboardNavigationData = [
  { title: "Accounts", icon: User, link: "/staff_backend/accounts" },
  { title: "Ovaloop Pay Analytics", icon: MoneyTick, link: "/staff_backend/ovaloop_pay_analytics" },
  { title: "Kyc Documents", icon: DocumentCloud, link: "/staff_backend/kyc_documents" },
  { title: "Settlement", icon: MoneySend, link: "/staff_backend/settlements" },
  { title: "All Employees", icon: People, link: "/staff_backend/staffs" },
  { title: "Export All Users", icon: CustomerIcon, link: "/staff_backend/users" },
  { title: "All Referrals", icon: People, link: "/staff_backend/referrals" },
  { title: "Ovaloop Transactions", icon: MoneySend, link: "/staff_backend/ovaloop_pay_transactions" },
  { title: "Demo Request", icon: People, link: "/staff_backend/demo_request" },
]

export {
  sellMenuNav,
  navMenuLists,
  navBottomMenuLists,
}
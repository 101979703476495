import ProductBasedLayout from "./ProductBasedLayout";
import {SellStyled} from "../../../styles/sellStyles";
import CategoryBasedLayout from "./CategoryBasedLayout";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";


const Sell = () => {
  const businessSettings = useBusinessSettingQuery()
  
  return (
      <SellStyled>
        {businessSettings?.sell_screen === "category_based" && (
          <CategoryBasedLayout />
        )}
  
        {businessSettings?.sell_screen !== "category_based" && (
          <ProductBasedLayout />
        )}
      </SellStyled>
  )
}

export default Sell
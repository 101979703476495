import {Paragraph} from "../../styles/textStyles";
import {IconWithToolTipStyled} from "../../styles/utilStyles";


const IconWithToolTip = ({ text, children, inline, chip, Comp }) => {
	return (
		<IconWithToolTipStyled $inline={inline}>
			{children}
			{!!chip && <span className="text_bold tool_tip_chip_text">{chip}</span>}
			{text && <Paragraph noTop color="black">{text}</Paragraph>}
			{Comp && <Comp />}
		</IconWithToolTipStyled>
	)
}


export default IconWithToolTip
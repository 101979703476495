import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {Button, DatePicker, Form, Input, Select, TextArea} from "../../index";
import {expenseFormDefaultValues} from "../../../data/defaultFormValues";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {useExpenseCategoryQuery} from "../../../hooks/useServices/useExpenseCategoryServices";


const CreateExpenseModal = ({onClose, handleSubmit, mutation}) => {
  const {isSuccess, data: allCategories} = useExpenseCategoryQuery()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Post Expense/Bill</Heading>
        
        <Form values={expenseFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Amount" type="number" formatNumber placeholder="e.g. ₦100000" name="amount"/>
          
          <Select
            options={isSuccess ? allCategories?.results : []}
            label="Category"
            placeholder="Select Category"
            name="custom_category"
            valueKey="id"
            displayKey="name"
            displayName="category_name"
          />
          
          <DatePicker
            className="fullWidth"
            labelBg="white"
            optional
            name="created_date"
            label="Date Created"
            placeholder="Select created date of expense/bill"
          />
          
          <TextArea className="fullWidth" rows={4} name="remark" placeholder="" label="Remark"/>
          
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading}/>
          </FlexStyled>
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateExpenseModal
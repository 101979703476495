import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Form} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {WIPStatus} from "../../../utils/table";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {Fragment, useState} from "react";


const ViewSettlementModal = ({ onClose, formValues, handleSubmit, mutation }) => {
  const { data: userProfile } = useUserProfile()
  const [type, setType] = useState("")
  const { nairaCurrencyFormatter, dateFormatter } = useDataTypeFormatter()

  const handleSubmission = (ty) => {
    setType(ty)
    
    if(!type) return
    handleSubmit(formValues, ty)
  }
  
  const getWithdrawal = () => {
    const value = Number(formValues?.amount)
    
    if(value <= 5000) {
      return { fee: 15, amount: Number(value) - Number(15) }
    } else if(value > 5000 && value <= 50000) {
      return { fee: 30, amount: Number(value) - Number(30) }
    } else if(value > 50000 && value <= 100000) {
      return { fee: 60, amount: Number(value) - Number(60) }
    } else {
      return { fee: 100, amount: Number(value) - Number(100) }
    }
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">View Settlement</Heading>
      
        <Form values={formValues} onSubmit={handleSubmit} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account Number: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_number}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Bank Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.bank_name}</Paragraph>
          </FlexStyled>
        
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Withdrawal Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.amount)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Withdrawal Fee: </Paragraph>
            <Paragraph color="black" size={1.2}>-{nairaCurrencyFormatter(getWithdrawal().fee)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Amount Disbursed: </Paragraph>
            <Paragraph color="black" size={1.2}>-{nairaCurrencyFormatter(getWithdrawal().amount)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Created: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.date)}</Paragraph>
          </FlexStyled>
  
          {userProfile?.[0]?.user_type === "staff" && (
            <Fragment>
              <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                <Paragraph bold color="black" size={1.2}>Transaction Ref: </Paragraph>
                <Paragraph color="black" size={1.2}>{formValues.transaction_ref}</Paragraph>
              </FlexStyled>
  
              <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}></FlexStyled>
  
              <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                <Paragraph bold color="black" size={1.2}>Settlement Account Status: </Paragraph>
                <Paragraph color="black" size={1}>{WIPStatus(formValues.is_verified ? "verified" : "pending")}</Paragraph>
              </FlexStyled>
            </Fragment>
          )}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Withdrawal Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
  
          {formValues?.remark && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Remark: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.remark}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled></FlexStyled>
  
          {(userProfile?.[0]?.user_type === "staff" && formValues.status === "pending") && (
            <FlexStyled>
              <Button className="bg_red" text="Reject" type="button" onClick={() => handleSubmission("rejected")} isLoading={mutation.isLoading && type === "rejected"} />
              <Button text="Approve" type="button" onClick={() => handleSubmission("confirmed")} isLoading={mutation.isLoading && type === "confirmed"} />
            </FlexStyled>
          )}
        </Form>
    
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default ViewSettlementModal
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {Fragment, useState} from "react";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {PageHeader, Table, TableItemWithStatus, TablePagination} from "../../../components";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import DateFilter from "../../../components/Utils/DateFilter";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
  useOvaloopPayTransactionServices, useRePushOvaloopPayTransactionMutation
} from "../../../hooks/useServices/useStaffBackendServices/useOvaloopPayTransactionServices";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum} from "../../../data/selectField/dateFilter";
import OvaloopPayTransactionModal from "../../../components/Modal/OvaloopPayModal/OvaloopPayTransactionModal";
import {useModal} from "../../../hooks";


// const chips = [{ name: "All", value: "" }, { name: "Pending", value: "pending" }, { name: "Confirmed", value: "confirmed" }, { name: "Rejected", value: "rejected" }]

const OvaloopPayTransactions = () => {
  const { debounceState } = useQueryDebounce()
  const { ovaloopPayTransactionTable } = tableHeadersAndValueKeys
  const [transactionType] = useState("")
  const [isTransactionModal, setIsTransactionModal] = useModal()
  const [transactionValues, setTransactionValues] = useState({})
  
  const defaultDate = handleDateFilterValue(dateFilterValue.TODAY)
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: defaultDate.fromDate, to_date: defaultDate.toDate })
  const { ...allOvaloopTransactions } = useSearchQuery(debounceState, useOvaloopPayTransactionServices.bind(this, { status: transactionType, from_date, to_date }), true)
  const rePushTransactionMutation = useRePushOvaloopPayTransactionMutation()
  
  const handleViewTransaction = (transaction) => {
    setTransactionValues(transaction)
    setIsTransactionModal()
  }
  
  const handleRePushTransactions = (transaction) => {
    rePushTransactionMutation.mutate({ session_id: transaction.sessionID })
  }
  
  return (
    <Fragment>
      {isTransactionModal && (
        <OvaloopPayTransactionModal
          handleSubmit={handleRePushTransactions}
          mutation={rePushTransactionMutation}
          onClose={setIsTransactionModal}
          formValues={transactionValues}
        />
      )}
      
      <PageHeader title="Ovaloop Pay Transactions" />
  
      <SpaceBetweenStyled>
        <FlexStyled>
          {/*{chips.map(item => (*/}
          {/*  <Chips*/}
          {/*    key={item.name}*/}
          {/*    title={item.name}*/}
          {/*    active={item.value === transactionType}*/}
          {/*    onClick={() => setTransactionType(item.value)}*/}
          {/*  />*/}
          {/*))}*/}
        </FlexStyled>
    
        <DateFilter dateFilterDefaultValue={dateFilterValueEnum.TODAY} labelBackground="background" placeholder="Filter by date" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled> <br/>
  
      <SetUpLayoutStyled>
        <Table headers={ovaloopPayTransactionTable.headers} noCols={ovaloopPayTransactionTable.headers.length}>
          {allOvaloopTransactions.isQueryWithData && (
            allOvaloopTransactions?.data?.map((log, k) => (
              <TableItemWithStatus
                count={k}
                onView={handleViewTransaction}
                pageCount={allOvaloopTransactions.currentCount}
                key={k}
                data={log}
                statusPosition={3}
                keys={ovaloopPayTransactionTable.values}
              />
            ))
          )}
      
          {allOvaloopTransactions.isQueryWithNoData && (
            <NoQueryData text="No settlement log" />
          )}
      
          {allOvaloopTransactions.isQueryLoading && (
            <TableSkeleton />
          )}
        </Table>
    
        {allOvaloopTransactions.showPagination && (
          <TablePagination
            query={allOvaloopTransactions}
            queryKey={allOvaloopTransactions.queryValues}
          />
        )}
      </SetUpLayoutStyled>
    </Fragment>
  )
}


export default OvaloopPayTransactions
import axios from "../../../axios";
import handleQueryParams from "../../../utils/handleQueryParams";

export const getOvaloopPayAnalyticsService = async (queryParams) => {
  try {
    const url = handleQueryParams("payment_analysis", queryParams)
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
export default {
	openRoute: "OPN045",
	viewListOfCategories: 'CAT002',
	viewListOfCustomers: 'CUS002',
	viewListOfOrdersBySelf: 'ORD002',
	viewListOfOrdersByAll: "ORD005",
	viewListOfProducts: 'PRO002',
	viewListOfServices: 'SER002',
	viewListOfTaxes: 'TAX002',
	// This is a custom permission code to handle both "view by self" and "view all" sales
	viewListOfSales: "SALE002",
	
	viewListOfDebtors: 'DEB001',
	viewListOfDiscounts: "DIS002",
	viewAnalyticsDashboard: 'DAN001',
	viewListOfDeliveries: 'DEL001',
	viewListOfInventories: 'INV002',
	viewListOfDesignations: 'DES002',
	viewListOfDeliveryRates: "DLR002",
	viewListOfTables: "TBL002",
	viewListOfExpenses: "EXP002",
	viewListOfStaffs: "STA002",
	viewDeliveryModule: "DEL002",
	viewAllOrdersFromAllStaffs: "ORD005",
	viewListOfBanks: "BAN002",
	viewBusinessSettings: "BSS001",
	viewReport: "REP001",
	viewSell: "ORD001",
	viewListOfSuppliers: "SUP002",
	viewCustomPaymentMethod: "CPM0002",
	viewCustomerSubscription: "CSB002",
	viewKitchenSchedule: "KIT002",
	viewSubscribeCustomerToSubscriptionPackage: "CSB005",
	viewCustomerSubscriptionHistory: "CSB006",
	viewProductSummaryAndAnalysis: "PRO006",
	viewActivityLogs: "ACL002",
	viewInvoice: "INC002",
	viewBadAndDamageProducts: "INV007",
	
	viewCostPrices: "PUR006",
	viewListOfPurchaseOrder: "PUR002",
	viewTips: "TIP002",
	
	// Ovaloop pay
	viewOvaloopWallet: "WAL003",
	
	// RAW MATERIAL
	viewRawMaterials: "RAW002",
	viewProductionStages: "PDS002",
	viewRequestRawMaterialLogs: "PDT008",
	viewProductionProcess: "PDT002"
}
import {Fragment, memo, useEffect} from "react";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {AddressInput, Button, Form, Input, PhoneInput, Select, TextArea} from "../../../index";
import {useCountries, useCurrencies} from "../../../../hooks/useServices/formFieldHooks";
import useFormWithCountryAndState from "../../../../hooks/useFormWithCountryAndState";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useFormikContext} from "formik";
import CropImage from "../../CropImage";


const EditStoreModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { data: countries } = useCountries()
  const { data: currencies } = useCurrencies()

  const [
    additionalDetails,
    setAdditionalDetails,
    updateCountry,
    updateState,
    populateDataForEditing
  ] = useFormWithCountryAndState(countries)

  useEffect(() => {
    populateDataForEditing(countries, formValues.country)
  }, [countries, formValues])


  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.store_name}</Heading>

        <Form
          values={formValues}
          onSubmit={handleSubmit}
          additionalData={additionalDetails}
          setAdditionalData={setAdditionalDetails}
        >
          <Fragment>
            <Input label="Store Name" placeholder="e.g your shop name" name="store_name"  />
            <Country updateCountry={updateCountry} />

            <Select
              options={currencies}
              displayKey="name"
              valueKey="id"
              name="currency"
              displayName="currency_name"
              label="Currency"
              placeholder="Select Currency"
            />
            <PhoneInput
              label="Phone"
              name="phone"
              dialCode={additionalDetails.dialCode}
              countryFlag={additionalDetails.countryFlag}
              placeholder="Phone Number"
            />
            <Select
              options={additionalDetails.statesOrProvinces}
              displayKey="name"
              valueKey="id"
              label="State/Province"
              placeholder="Select state/province"
              name="state"
              updateFn={updateState}
              displayName="state_name"
              // clearCondition="country"
            />
            <Select
              optional
              options={additionalDetails.locals}
              displayKey="name"
              valueKey="id"
              label="Local/LGA"
              placeholder="Select local/lga"
              name="local"
              displayName="local_name"
              // clearCondition="state"
            />
            <PhoneInput
              optional
              label="Secondary Phone"
              name="phone2"
              dialCode={additionalDetails.dialCode}
              countryFlag={additionalDetails.countryFlag}
              placeholder="Secondary phone number"
            />
            <Input optional label="Tagline" placeholder="Enter your tagline" name="tagline"/>
            <AddressInput name="address" main_name="address" label="Address" placeholder="e.g your shop address"
                          required/>
            <TextArea name="receipt_message" className="fullWidth" optional label="Receipt Message"
                      placeholder="Enter message to display on receipt"/>
  
            <CropImage width={500} height={300} name="image" className="fullWidth" optional
                       placeholder='Upload Business Logo'/>
  
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text="Update" type="submit"/>
            </FlexStyled>
          </Fragment>
        </Form>

      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditStoreModal

const Country = memo(({ updateCountry }) => {
  const { data: countries } = useCountries()
  const { setFieldValue } = useFormikContext()

  const handleUpdate = (country) => {
    setFieldValue("countryLocale", country?.locale)
    setFieldValue("currency", country.code)
    setFieldValue("currencyName", country.code)
    updateCountry(country)
  }

  return (
    <Select
      options={countries}
      displayKey="name"
      valueKey="id"
      name="country"
      displayName="country_name"
      label="Country"
      updateFn={handleUpdate}
      placeholder="Select Country"
    />
  )
})
import {
  NoDataLayout,
  OrderSearchAndFilter,
  PageHeaderWithButtonAndDateFilter,
  PageSummaryCard,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {EmptyBoxImage} from "../../../assets/images";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useGroupOrderByBusinessIdQuery} from "../../../hooks/useServices/useOrderServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import orderQueryKey from "../../../hooks/useServices/useOrderServices/orderQueryKey";
import {getOrderServiceStructure} from "../../../services/orderServices/orderServiceStructure";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum} from "../../../data/selectField/dateFilter";
import {AddCircle} from "iconsax-react";
import {useNavigate} from "react-router-dom";
import {invoiceStatusOptions} from "../../../data/selectField/orderFilter";
import {actionsPermissions} from "../../../data/permissions";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../desktop/sendDesktopNotification";


const Invoice = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { numberFormatter } = useDataTypeFormatter()
  const { orderTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const { toDate, fromDate } = handleDateFilterValue(dateFilterValue.THIS_YEAR)
  const [dateFilter, setDateFilter] = useState({ from_date: fromDate, to_date: toDate })
  const { mainQuery, isQueryLoading, ...allInvoice } = useOrderQueryWithSearchAndFilter({ ...debounceState, fromDateValue: dateFilter.from_date, toDateValue: dateFilter.to_date, invoice: true }, useGroupOrderByBusinessIdQuery)
  const { filterValue, fromDateValue, toDateValue, customerValue } = debounceState
  
  const totalOrders = () => {
    return allInvoice?.data?.results?.reduce((prev, curr) => {
      return prev + Number(curr?.total_items)
    }, 0)
  }
  
  const handleCreateInvoice = () => {
    navigate("/dashboard_sell/?pay_mode=invoice")
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.groupOrder }).then(() => {
      queryClient.refetchQueries({ queryKey: desktopQueryKeys.singleOrders }).then(() => {
        sendDesktopNotification({
          title: "Sync successful",
          body: "All sales has been synced offline"
        })
      })
    })
  }

  return (
    <div>
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allInvoice.refetch} />
      )}
      
      <Fragment>
        <PageHeaderWithButtonAndDateFilter
          permissionCode={actionsPermissions.createInvoice}
          buttonText="Create"
          Icon={AddCircle}
          buttonClick={handleCreateInvoice}
          setDateFilter={setDateFilter}
          title="Quotation"
          dateFilterDefaultValue={dateFilterValueEnum.THIS_YEAR}
        />

        {allInvoice.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No orders"
              imgSrc={EmptyBoxImage}
              heading="No orders yet"
            />
          </Fragment>
        )}

        {allInvoice.showTable && (
          <Fragment>

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={isQueryLoading}
                title="Total Number Of Orders"
                value={numberFormatter(allInvoice?.data?.count || 0)}
                subText={!!totalOrders() && `${numberFormatter(totalOrders())} items`}
              />
            </PageSummaryStyled>

            <SetUpLayoutStyled>

              <OrderSearchAndFilter
                sync={{ title: "sales", fn: handleManualSync }}
                showCustomer
                customFilters={invoiceStatusOptions}
                state={debounceState}
                dispatch={debounceReducer}
                searchPlaceholder="Search by Id"
              />

              <Table bg headers={orderTable.headers} noCols={orderTable.headers.length}>
                {allInvoice.isQueryWithData && (
                  allInvoice?.data?.results.map((items, k) =>
                    <TableItemForGroupOrders
                      isInvoice
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allInvoice.currentCount}
                      keys={orderTable.values}
                    />
                  )
                )}

                {allInvoice.isQueryWithNoData && (
                  <NoQueryData text="No sale record" />
                )}

                {allInvoice.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allInvoice.showPagination && (
                <TablePagination
                  query={allInvoice}
                  restructureFn={getOrderServiceStructure}
                  queryKey={orderQueryKey.mainList(debounceState.searchValue, filterValue, fromDateValue, toDateValue, customerValue)}
                />
              )}
            </SetUpLayoutStyled>
            {allInvoice.showPagination && (
              <TablePagination
                query={allInvoice}
                restructureFn={getOrderServiceStructure}
                queryKey={orderQueryKey.mainList(debounceState.searchValue, filterValue, fromDateValue, toDateValue, customerValue)}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Invoice;
import queryKeys from "../queryKeys";
import {
  addFaultyProductsService,
  adjustProductCostPriceService,
  bulkDeleteProductService,
  createProductService,
  deleteProductService,
  depleteFaultyProductService,
  editProductService,
  exportAllProductService,
  getFaultyProductService,
  getProductByBusinessIdService,
  getProductByCategoryIdService,
  getProductByIdService,
  getProductNotifyService,
  getProductService,
  getProductsWithSameSKUService,
  getSellableProductByBusinessIdService,
  getSellableProductByCategoryIdService,
  getSellableProductForCategoryBasedService,
  manageProductStockService,
  moveProductService,
  productDistributionService,
  publishProductToStoreFrontService,
  updateProductSellingPriceAcrossAccountService,
  uploadBulkProductService
} from "../../../services/productServices";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useParams} from "react-router-dom";
import useInventoryPrefetch from "../../usePrefetchQuery/useInventoryPrefetch";
import productQueryKey from "./productQueryKey";
import inventoryQueryKey from "../useInventoryServices/inventoryQueryKey";
import {globalReducerActions} from "../../../reducers/globalReducer";
import customServices from "../../../services/customServices";
import errorHandler from "../../../utils/errorHandler";
import reportDownload from "../../../utils/reportDownload";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {
  useDesktopProductsServiceFromLocalDB,
  useDesktopSingleProductServiceFromLocalDB
} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import useDataTypeFormatter from "../../useUtils/useDataTypeFormatter";


const useProductByIdQuery = (productId, query = {}) => {
  const {isOffline} = useGlobalContext()
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.byId(productId),
    queryFn: () => getProductByIdService(productId, query)
  })
  
  const offlineData = useDesktopSingleProductServiceFromLocalDB(productId)
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useFaultyProductQuery = (queryParams = {}) => {
  const {getBusinessId} = useGlobalContext()
  const businessId = getBusinessId()
  
  return useQuery({
    queryKey: productQueryKey.faultyProducts(queryParams),
    queryFn: () => getFaultyProductService({...queryParams, filter: 'faulty', business_id: businessId})
  })
}

export const useGetProductsWithSameSKUService = (productId) => {
  const {currencyFormatter} = useDataTypeFormatter()
  
  return useQuery({
    queryKey: productQueryKey.productsWithSameSKU(productId),
    queryFn: () => getProductsWithSameSKUService(productId, currencyFormatter)
  })
}

const useProductNotifyQuery = () => {
  const {getBusinessId} = useGlobalContext()
  const businessId = getBusinessId()
  
  return useQuery({
    enabled: !!getBusinessId(),
    queryKey: ["PRODUCT_NOTIFY", businessId],
    queryFn: () => getProductNotifyService(businessId)
  })
}

const useProductByBusinessIdQuery = (searchValue) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  const businessId = getBusinessId()
  
  const offlineData = useDesktopProductsServiceFromLocalDB({ search: searchValue })
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.byBusinessId(searchValue),
    queryFn: () => getProductByBusinessIdService(businessId, searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useProductQuery = (query = {}) => {
  const {getBusinessId, isOffline} = useGlobalContext()
  const businessId = getBusinessId()
  
  const offlineData = useDesktopProductsServiceFromLocalDB(query)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.byQueryParams(query),
    queryFn: () => getProductService({business_id: businessId, ...query})
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useSellableProductByBusinessIdQuery = (searchValue) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  const businessId = getBusinessId()
  
  const offlineData = useDesktopProductsServiceFromLocalDB({ search: searchValue })
  
  const onlineData =  useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.sellable(searchValue),
    queryFn: () => getSellableProductByBusinessIdService(businessId, searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useSellableProductForCategoryBasedQuery = (queryParams) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  const businessId = getBusinessId()
  
  const offlineData = useDesktopProductsServiceFromLocalDB(queryParams)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.sellableForCategoryBased(getAllObjectKeysWithValues(queryParams)),
    queryFn: () => getSellableProductForCategoryBasedService({ ...queryParams, business_id: businessId })
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useSellProductsOnEndViewMutation = (searchValue) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (query) => {
      if(!query.data.next) {
        return ""
      }
      
      return queryClient.fetchQuery({
        queryKey: query.data.next,
        queryFn: () => customServices(query.data.next)
      })
    },
    
    onSuccess: (newQuery) => {
      queryClient.setQueriesData(productQueryKey.sellable(searchValue), (previous) => {
        return { ...newQuery, results: [...previous.results, ...newQuery.results] }
      })
    },

    onError: () => {
    }
  })
}

const useProductByCategoryIdQuery = (categoryId, searchValue) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  
  const offlineData = useDesktopProductsServiceFromLocalDB({ category: categoryId, search: searchValue })
  
  const onlineData =  useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.byCategoryId(categoryId, searchValue),
    queryFn: () => getProductByCategoryIdService(getBusinessId(), categoryId, searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

export const useSellableProductByCategoryIdQuery = (categoryId, searchValue) => {
  const { getBusinessId, isOffline } = useGlobalContext()
  
  const offlineData = useDesktopProductsServiceFromLocalDB({ category: categoryId, search: searchValue })
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: productQueryKey.sellableByCategoryId(categoryId, searchValue),
    queryFn: () => getSellableProductByCategoryIdService(getBusinessId(), categoryId, searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}


const useCreateProductMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId, globalReducer } = useGlobalContext()
  const businessId = getBusinessId()

  return useMutation({
    mutationFn: data => {
      return createProductService({ ...data, business: businessId })
    },

    onSuccess: () => {
      successFn()
      toast.success("Product Created!!", "created")
  
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.onlyLists})
      queryClient.invalidateQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
  
      globalReducer({
        type: globalReducerActions.CLEAR_MODAL_CONNECTOR
      })
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createProduct,
      })
    }
  })
}

const useEditProductMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast , globalReducer } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editProductService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!", "edited")
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.invalidateQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
  
      queryClient.refetchQueries({queryKey: productQueryKey.all})
  
      globalReducer({
        type: globalReducerActions.CLEAR_MODAL_CONNECTOR
      })
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.editProduct,
      })
    }
  })
}

const useDeleteProductMutation = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()


  return useMutation({
    mutationFn: productId => {
      return deleteProductService(productId)
    },

    onSuccess: () => {
      toast.success("Delete Successful!!", "delete")
      queryClient.refetchQueries({ queryKey: productQueryKey.onlyLists })
      queryClient.invalidateQueries({ queryKey: [queryKeys.DASHBOARD_ANALYTICS] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useUploadBulkProductService = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: ({type, values}) => {
      return uploadBulkProductService(type, getBusinessId(), values)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Products import in progress!!!")
      queryClient.invalidateQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.onlyLists})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
  
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.uploadBulkProduct,
      })
    }
  })
}


export const useAdjustProductSellingPriceAcrossAccountMutation = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const {toast} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: ({payload}) => {
      return updateProductSellingPriceAcrossAccountService(payload)
    },
    
    onSuccess: (_, variables) => {
      successFn()
      toast.success("Product selling price updated")
      queryClient.refetchQueries({queryKey: productQueryKey.onlyLists}).catch()
      queryClient.refetchQueries({queryKey: productQueryKey.byId(variables.productId)}).catch()
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.adjustProductSellingPrice,
      })
    }
  })
}

const useMoveProductService = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const inventoryPrefetch = useInventoryPrefetch()
  const {toast, getBusinessId} = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return moveProductService({...data, source_business: getBusinessId(), move_all: true})
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Products move in progress")
      inventoryPrefetch.fetch()
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.invalidateQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.moveProduct,
      })
    }
  })
}

const useManageProductStockService = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  const businessId = getBusinessId()
  
  return useMutation({
    mutationFn: data => {
      return manageProductStockService({...data, business: businessId})
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Updated product stock")
      
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
  
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.manageProductStock,
      })
    }
  })
}


export const useAdjustProductCostPriceService = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  const businessId = getBusinessId()
  
  return useMutation({
    mutationFn: data => {
      return adjustProductCostPriceService({...data, business: businessId})
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Updated product cost price")
      
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.adjustProductCostPriceStock,
      })
    }
  })
}


export const useAddFaultyProductService = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  const businessId = getBusinessId()
  
  return useMutation({
    mutationFn: data => {
      return addFaultyProductsService({...data, business: businessId})
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Updated product stock")
      
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.addFaultyProduct,
      })
    }
  })
}

export const useDepleteFaultyProductService = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: data => {
      return depleteFaultyProductService(data)
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Product Depleted", "depleted")
      
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.depleteFaultyProduct,
      })
    }
  })
}

const useProductDistributionMutation = ({ successFn }) => {
  const {productId} = useParams()
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  const inventoryPrefetch = useInventoryPrefetch()
  
  
  return useMutation({
    mutationFn: data => {
      const to_business = data?.multiple_data?.[0]?.business
      
      return productDistributionService({
        ...data,
        product: productId,
        source_business: getBusinessId(),
        ...(!productId && {business: to_business})
      })
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Product distribution in progress")
      inventoryPrefetch.fetch()
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.productDistribution,
      })
    }
  })
}

export const useBulkDeleteProductMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: data => {
      return bulkDeleteProductService({...data, business_id: getBusinessId()})
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Products Deleted!!!")
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.invalidateQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.bulkDeleteProduct,
      })
    }
  })
}


export const useExportAllProductMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {getBusinessId, toast} = useGlobalContext()
  const businessId = getBusinessId()
  
  return useMutation({
    mutationFn: (queryParams = {}) => {
      const query = {...queryParams, business_id: businessId}
      return exportAllProductService(query)
    },
    
    onSuccess: (res) => {
      successFn()
      reportDownload(res, `All_Products_${businessId}`)
      toast.success("Products exported")
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.exportAllProduct,
      })
    }
  })
}


export const usePublishProductToStoreFrontMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast, getBusinessId} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: data => {
      return publishProductToStoreFrontService({...data, business_id: getBusinessId()})
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Products Publish")
      queryClient.refetchQueries({queryKey: productQueryKey.all})
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.publishProductToStoreFront,
      })
    }
  })
}


export {
  useProductByBusinessIdQuery, useProductByCategoryIdQuery, useSellProductsOnEndViewMutation,
  useCreateProductMutation, useEditProductMutation, useDeleteProductMutation, useProductByIdQuery, useProductNotifyQuery,
  useMoveProductService, useUploadBulkProductService, useManageProductStockService, useProductDistributionMutation
}
import { Field } from "formik";
import { CheckBoxStyled } from "../../styles/formStyles";
import {childrenPropToArray} from "../../utils/forms";

const RadioButton = ({ className, name, labelProp, required, value, id, handleChange }) => {
  const childrenArray = childrenPropToArray(labelProp)
  
  return (
    <CheckBoxStyled className={className} onChange={handleChange}>
      <Field type="radio" value={value} name={name} id={id || name} required={required} />
      <label htmlFor={id || name}>
        {childrenArray}
      </label>
    </CheckBoxStyled>
  )
}

export default RadioButton
import env from "../env";

export const isTimeForPackageRenewal = (packageDetails) => {
	if(!!packageDetails?.is_basic) {
		return false
	}
	
	const readyForRenewalDate =  new Date(packageDetails?.next_pay_date).getTime()
	return readyForRenewalDate < (new Date().getTime() + (31 * 24 * 60 * 60 * 1000))
}

export const isPackageBasic = (userProfile) => {
	return (userProfile?.[0]?.package_details?.package_id === env.FREE_SUBSCRIPTION_PLAN)
}

export const isUserEmailVerified = (userProfile) => {
	return userProfile?.[0]?.is_verified
}

export const isPackageExpired = (packageDetails) => {
	if(!!packageDetails?.is_basic) {
		return false
	}
	
	const readyForRenewalDate =  new Date(packageDetails?.next_pay_date).getTime()
	return readyForRenewalDate < new Date().getTime()
}


export const isAccountNumberExpired = (accountGenerationDate) => {
	const readyForRenewalDate =  new Date(accountGenerationDate)
	const additional30Minutes = readyForRenewalDate.setMinutes(readyForRenewalDate.getMinutes() + 30)
	
	return new Date(additional30Minutes).getTime() < new Date().getTime()
}

export const isOrderDueDateWithing3Days = (orderDueDate) => {
	const readyForRenewalDate =  new Date(orderDueDate).getTime()
	return readyForRenewalDate < (new Date().getTime() + (3 * 24 * 60 * 60 * 1000))
}

export const isOrderDueDateWithing6Days = (orderDueDate) => {
	const readyForRenewalDate =  new Date(orderDueDate).getTime()
	return readyForRenewalDate < (new Date().getTime() + (6 * 24 * 60 * 60 * 1000))
}

export const handleOrderDueDateWarning = (orderDueDate) => {
	if(isOrderDueDateWithing3Days(orderDueDate)) return "lightError"
	if(isOrderDueDateWithing6Days(orderDueDate)) return "lightWarning"
	return ""
}
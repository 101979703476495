import {useQueryClient} from "react-query";
import customServices from "../../services/customServices";
import {PaginationButton, TablePaginationStyled} from "../../styles/TableStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const TablePagination = ({ query, queryKey, restructureFn }) => {
  const queryClient = useQueryClient()
  const {getBusinessId} = useGlobalContext()
  
  const params = {
    businessId: getBusinessId()
  }
  
  const handleNext = async () => {
    if (!query.next) return
  
    await queryClient.prefetchQuery({
      queryKey: queryKey,
      queryFn: () => customServices(query.next, restructureFn, params)
    })
    // scrollWindowToTop()
    window.scrollTo(0, 0)
  }
  
  const handlePrev = async () => {
    if (!query.prev) return
  
    await queryClient.prefetchQuery({
      queryKey: queryKey,
      queryFn: () => customServices(query.prev, restructureFn)
    })
    // scrollWindowToTop()
    window.scrollTo(0, 0)
  }
  
  return (
    <TablePaginationStyled>
      <div>
        <PaginationButton active={!!query?.prev} disabled={!query?.prev} onClick={handlePrev}>Previous</PaginationButton>
        <PaginationButton active={!!query?.next} disabled={!query?.next} onClick={handleNext}>Next</PaginationButton>
      </div>
    </TablePaginationStyled>
  )
}

export default TablePagination
import env from "./env";

const isLinkParentActive = (title, location) => {
  const locationArr = location.pathname.split("/")

  if(locationArr.length <= 2) {
    return false
  }

  return title.toLowerCase() === locationArr[2].toLowerCase()
}

const isLinkActive = (link, location) => {
  return link === location.pathname
}

const noPageData = (data, searchValue) => {
  if(searchValue !== " " && !!searchValue) {
    return false
  }
  return (!data || data.length < 1)
}

const isMobile = () => {
  return window.innerWidth < 650
}

const isTablet = () => {
  return (window.innerWidth > 700 && window.innerWidth < 1200)
}

const isLargeDesktop = () => {
  return window.innerWidth > 1820
}

export const clearAllDataInStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const setBusinessIdInStorage = (businessId, isPersistent = true) => {
  if (isPersistent || window?.require) {
    localStorage.setItem("ovaloop_business_id", businessId.toString())
    return
  }

  sessionStorage.setItem("ovaloop_business_id", businessId.toString())
}

export const setBusinessAccountIdInStorage = (accountId, isPersistent = true) => {
  if (isPersistent || window?.require) {
    localStorage.setItem("ovaloop_business_account_id", (accountId || "").toString())
    return
  }
  
  sessionStorage.setItem("ovaloop_business_account_id", (accountId || "").toString())
}

export const setUserIdInStorage = (userId, isPersistent = true) => {
  if (isPersistent || window?.require) {
    localStorage.setItem("ovaloop_user_id", userId?.toString())
    return
  }
  
  sessionStorage.setItem("ovaloop_user_id", userId?.toString())
}

export const setAuthTokenInStorage = (token, isPersistent) => {
  if (isPersistent || window?.require) {
    localStorage.setItem("ovaloop_token", token.toString())
    return
  }
  
  sessionStorage.setItem("ovaloop_token", token.toString())
}

export const getBusinessIdFromStorage = () => {
  const businessIdFromLocalStorage = localStorage.getItem("ovaloop_business_id")
  const businessIdFromSessionStorage = sessionStorage.getItem("ovaloop_business_id")
  
  return businessIdFromLocalStorage || businessIdFromSessionStorage || ""
}

export const getUserIdFromStorage = () => {
  const userIdFromLocalStorage = localStorage.getItem("ovaloop_user_id")
  const userIdFromSessionStorage = sessionStorage.getItem("ovaloop_user_id")
  
  return userIdFromLocalStorage || userIdFromSessionStorage || ""
}

export const getBusinessAccountIdFromStorage = () => {
  const businessAccountIdFromLocalStorage = localStorage.getItem("ovaloop_business_account_id")
  const businessAccountIdFromSessionStorage = sessionStorage.getItem("ovaloop_business_account_id")
  
  return businessAccountIdFromLocalStorage || businessAccountIdFromSessionStorage || ""
}

export const getAuthTokenFromStorage = () => {
  const authTokenFromLocalStorage = localStorage.getItem("ovaloop_token")
  const authTokenFromSessionStorage = sessionStorage.getItem("ovaloop_token")

  return authTokenFromLocalStorage || authTokenFromSessionStorage || ""
}

export const clearAuthTokenFromStorage = () => {
  localStorage.setItem("ovaloop_token", "")
  sessionStorage.setItem("ovaloop_token", "")
}

export const clearBusinessIdFromStorage = () => {
  localStorage.removeItem("ovaloop_business_id")
  sessionStorage.removeItem("ovaloop_business_id")
  localStorage.removeItem("syncStatusForOffline")
}

export const getIsDesktopOnlineStatus = () => {
  return localStorage.getItem("isOvaloopDesktopOnline") || "online"
}

export const setIsDesktopOnlineStatus = (value) => {
  localStorage.setItem("isOvaloopDesktopOnline", value)
}

const topNData = (data = [], n= 5) => {
  const newData = [...data]
  newData.length = n
  return newData
}

const scrollToTop = () => {
  // window.scrollTo(0, 0)
}


export const scrollWindowToTop = () => {
  // window.scrollTo(0, 0)
}

export const generateRandomIndexForColor = (maxIndex = 3) => {
  return Number((Math.random() * maxIndex).toFixed())
}


export const isAppStoreFront = () => {
  return (window.location.origin === env.STORE_FRONT_URL)
}

export const handleCopyToClipBoard = ({text, toast, successMessage}) => {
  navigator.clipboard.writeText(text)
    .then(() => toast.success(successMessage, "copy"))
    .catch()
}

export const getNotificationCountFromStorage = () => {
  return Number(localStorage.getItem("NOTIFICATION_COUNT") || 0)
}

export const setNotificationCountFromStorage = (count) => localStorage.setItem("NOTIFICATION_COUNT", count)


export const isDisplayDashboardFinancialRecords = () => {
  if (localStorage.getItem("dashboard_financial_records") === null) return true
  return localStorage.getItem("dashboard_financial_records") === "true"
}
export const setDisplayDashboardFinancialRecords = (value) => localStorage.setItem("dashboard_financial_records", value)


export {
  noPageData,
  isLinkActive,
  isMobile,
  topNData,
  scrollToTop,
  isLargeDesktop,
  isLinkParentActive,
  isTablet
}
import axios from "../axios";
import {routesPermissions} from "../data/permissions";
import {clearAllDataInStorage} from "../utils";

const getUserProfileService = async () => {
  try {
    const {data} = await axios.get("/users/")
  
    if (data?.[0]?.user_type !== "client") return data
  
    const user_permission = [...data?.[0]?.user_permission]
    if (user_permission.includes(routesPermissions.viewListOfOrdersBySelf) || user_permission.includes(routesPermissions.viewListOfOrdersByAll)) {
      user_permission.push(routesPermissions.viewListOfSales)
    }
  
    return [{...data?.[0], user_permission}]
  } catch (e) {
    if (e?.response?.data?.code === "token_not_valid") {
      clearAllDataInStorage()
      window.location.reload()
      return
    }
  
    throw e?.response?.data || ""
  }
}


export const getAllStaffUserProfileService = async () => {
  try {
    const {data: allUsers} = await axios.get("/users/?all_user=true")
    return allUsers?.map(data => {
      if (data?.[0]?.user_type !== "client") return data
      
      const user_permission = [...data?.[0]?.user_permission]
      if (user_permission.includes(routesPermissions.viewListOfOrdersBySelf) || user_permission.includes(routesPermissions.viewListOfOrdersByAll)) {
        user_permission.push(routesPermissions.viewListOfSales)
      }
      
      return [{...data?.[0], user_permission}]
    })
    
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const updateUserNotificationService = async (userNotificationId, body) => {
  try {
    const {data} = await axios.patch(`/user_notification/${userNotificationId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export {getUserProfileService, updateUserNotificationService}
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getBusinessIdFromStorage, setAuthTokenInStorage} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {
  createCustomerForStoreService,
  createStoreFrontService,
  getStoreFrontBusinessService, updateStoreFrontService,
  verifyStoreFrontNameService
} from "../../../services/businessStoreFrontService";
import queryKeys from "../queryKeys";
import {useParams} from "react-router-dom";
import {getUserProfileService} from "../../../services/userProfileServices";


const mainKey = ["Store Query", getBusinessIdFromStorage()]
export const storeFrontQueryKeys = {
  all: mainKey,
  verify_store_name: (name) => [...mainKey, "verify", name],
  store_business: (name) =>  [...mainKey, "business", name]
}


export const useVerifyStoreFrontNameQuery = (value) => {
  return useQuery({
    enabled: !!value,
    queryKey: storeFrontQueryKeys.verify_store_name(value),
    queryFn: () => verifyStoreFrontNameService({ store_front_name: value })
  })
}

export const useStoreFrontBusinessQuery = () => {
  const { storeName } = useParams()
  
  return useQuery({
    enabled: !!storeName,
    queryKey: storeFrontQueryKeys.store_business(storeName),
    queryFn: () => getStoreFrontBusinessService({ store_front_name: storeName })
  })
}


export const useCreateStoreFrontMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: body => {
      return createStoreFrontService({ ...body, business_id: getBusinessId() })
    },

    onSuccess: () => {
      successFn?.()
      toast.success("Store Front Created!!", "store created")
      
      queryClient.invalidateQueries({ queryKey: [queryKeys.STORES, (getBusinessId())] })
      queryClient.invalidateQueries({ queryKey: storeFrontQueryKeys.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useUpdateStoreFrontMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return updateStoreFrontService({ ...body, business_id: getBusinessId() })
    },
    
    onSuccess: () => {
      successFn?.()
      toast.success("Store Front Updated!!", "store created")
      
      queryClient.invalidateQueries({ queryKey: [queryKeys.STORES, (getBusinessId())] })
      queryClient.invalidateQueries({ queryKey: storeFrontQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useCreateStoreFrontCustomerMutation = ({ successFn }) => {
  const { toast } = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: body => {
      return createCustomerForStoreService(body)
    },
    
    onSuccess: async (res) => {
      successFn()
      setAuthTokenInStorage(res.token)
      toast.success("Account Created!!", "customer_created")
  
      await queryClient.prefetchQuery(queryKeys.USER_PROFILE, getUserProfileService)
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
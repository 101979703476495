import queryKeys from "../queryKeys";
import {createBankService, deleteBankService, editBankService, getBankService} from "../../../services/bankServices";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopBanksServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import {errorTypes} from "../../../utils/logger";
import useErrorLogger from "../../useUtils/useErrorLogger";


const useBankQuery = (searchValue) => {
  scrollToTop()
  const {getBusinessId, isOffline} = useGlobalContext()
  const offlineData = useDesktopBanksServiceFromLocalDB(searchValue)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: [queryKeys.BANKS, searchValue],
    queryFn: () => getBankService({ business_id: getBusinessId(), search: searchValue }),
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCreateBankMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createBankService(data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Bank Created!!")
      queryClient.invalidateQueries({queryKey: queryKeys.BANKS})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createBank,
      })
    }
  })
}

const useEditBankMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editBankService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({queryKey: queryKeys.BANKS})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateBank,
      })
    }
  })
}

const useDeleteBankMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: bankId => {
      return deleteBankService(bankId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: queryKeys.BANKS })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useBankQuery, useCreateBankMutation, useEditBankMutation, useDeleteBankMutation }
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, TextArea} from "../../../index";
import {categoryFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import CropImage from "../../CropImage";


const CreateCategoryModal = ({ onClose, handleSubmit, mutation }) => {
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Category</Heading>

        <Form values={categoryFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Category Name" placeholder="e.g Electronic" name="name"  />
          <TextArea optional label="Describe Category" placeholder="Enter a description for this category" name="description" />
          <CropImage optional width={400} height={600} className="fullWidth" name="image" placeholder="Choose Category Image" />
          
          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Save" type="submit" />
          </FlexStyled>
        </Form>

      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateCategoryModal
import App from './App';
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, HashRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {QueryClient, QueryClientProvider} from "react-query"
import {activeFirebaseMessaging} from "./config/firebase_messaging";

// styles
import theme from "./styles";
import "./assets/styles/fonts.css"
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
})

const WrapAppWithRouter = () => {
  useEffect(() => {
    activeFirebaseMessaging().then().catch()
  }, [])
  
  if (!!window?.require) {
    return (
      <HashRouter>
        <App/>
      </HashRouter>
    )
  }
  
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <WrapAppWithRouter />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

import {Line} from "../index";
import {Eye, Trash} from "iconsax-react";
import {useTheme} from "styled-components";
import {displayTableItemWithStatus} from "../../utils/table";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {TableItemActionStyled, TableItemStyled} from "../../styles/TableStyles";
import {Heading} from "../../styles/textStyles";
import {NO_OF_RESULTS_FROM_BACKEND} from "../../utils/constants";
import { BsToggleOff, BsToggleOn } from "react-icons/bs"
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const TableItemWithStatus = ({ data, actionPosition, statusPosition = 4, noAction, keys, onView, actionDisabled, count, pageCount, isSwitchAction, isSwitchActive, isDeleteActive, deleteAction, noCount }) => {
  const theme = useTheme()
  const { isOffline, toast } = useGlobalContext()
  const tableData = useTableValueTransformer(data, keys)
  
  const handleDelete = (data) => {
    if(isOffline) {
      toast.error("You cannot delete when offline", "offline")
      return
    }
    
    deleteAction?.(data)
  }
  
  const handleSwitch = (data) => {
    if(isOffline) {
      toast.error("You cannot switch when offline", "offline")
      return
    }
    
    onView?.(data)
  }

  return (
    <TableItemStyled onClick={onView?.bind(this, data)}>
      {!noCount && (
        <td className="counter">
          <Heading color="black" thin size={1}>{(count+1) + ((pageCount-1) * NO_OF_RESULTS_FROM_BACKEND)}</Heading>
        </td>
      )}
      
      {tableData.map((item, k) => displayTableItemWithStatus(item, k, statusPosition))}

      {(!noAction && !isSwitchAction) && (
        <TableItemActionStyled position={actionPosition} onClick={onView?.bind(this, data)}>
          {!actionDisabled && <Eye color={theme.gray}/>}
        </TableItemActionStyled>
      )}

      {isSwitchAction && (
        <TableItemActionStyled position={actionPosition} onClick={() => handleSwitch(data)}>
          {isSwitchActive ? <BsToggleOn size={28} color="green" /> : <BsToggleOff size={28} />}
        </TableItemActionStyled>
      )}
  
      {deleteAction && (
        <TableItemActionStyled position={actionPosition}>
          {isDeleteActive && <Trash color={theme.error} onClick={() => handleDelete(data)} />}
        </TableItemActionStyled>
      )}
      <Line />
    </TableItemStyled>
  )
}

export default TableItemWithStatus
import styled from "styled-components";
import {FlexStyled, ImageStyled, SpaceBetweenStyled} from "./utilStyles";
import {ButtonStyled} from "./formStyles";
import {StoresImage} from "../assets/images";
import {Heading2} from "./textStyles";

const SelectStoreStyled = styled("div")`
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  
  > div {
    width: 90%;
    padding: 1.5rem 3.5rem;
  }
  
  > div:last-of-type {
    width: 80%;
    padding: 0;
    
    a {
      margin-top: -1rem;
      margin-right: 1rem;
      float: right;
    }
  }
  
  ${ImageStyled} {
    position: relative;
    height: 95vh;
    width: 100% !important;
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    padding: 1.5rem;
    
    > div {
      width: 100%;
      padding: 0;
    }
  
    > div:last-of-type {
      display: none;
    }
    
    ${ImageStyled} {
      display: none;
    }
  }
`

const SelectStoreNavStyled = styled("nav")`
  display: flex;
  align-content: center;
  justify-content: space-between;

  ${Heading2} {
    margin-bottom: 0;
  }
`

const SelectStoreHeaderStyled = styled(SpaceBetweenStyled)`
  margin-top: .5rem;
  height: 20vh;
  align-items: flex-end;

  .call_to_action {
    width: 20%;

    ${ButtonStyled} {
      width: 65%;
      font-size: .9rem;
      margin-top: .7rem;
      padding: .7rem .2rem;
    }
  }

  .store_image {
    width: 70%;
    height: 100%;
    background-image: url(${StoresImage});
    //background-repeat: ;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    display: none;
  }
`

const StoresCardListStyled = styled(FlexStyled)`
  margin-top: 4rem;
  gap: 2rem 1rem;

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    margin-top: 1.5rem;
    gap: 1.5rem .2rem;
    justify-content: space-between;
  }
`

export { SelectStoreStyled, SelectStoreNavStyled, SelectStoreHeaderStyled, StoresCardListStyled }
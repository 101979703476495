import {StoreFrontProductCardStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Button, Image} from "../../../../components";
import {FlexColumnStyled} from "../../../../styles/utilStyles";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";

const StoreFrontProductCard = ({ product, buttonText, handleClick, onView }) => {
  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  
  return (
    <StoreFrontProductCardStyled onClick={() => onView(product)}>
      <div className="image">
        <Image src={product?.image_path} />
      </div>
      
      <FlexColumnStyled $gap={.7}>
        <Heading size={1.2} color="black">{product.name}</Heading>
        <Paragraph color="black" noTop>{nairaCurrencyFormatter(product.selling_price)}</Paragraph>
        <Button text={buttonText} onClick={() => handleClick(product)} />
      </FlexColumnStyled>
    </StoreFrontProductCardStyled>
  )
}


export default StoreFrontProductCard
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {FlexCenterStyled, FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading} from "../../styles/textStyles";
import {AreaChartStyled} from "../../styles/chartStyles";


const AreaCharts = ({ title, showTypes, data, valueFormatter, config }) => {
  return (
    <AreaChartStyled>
      <SpaceBetweenStyled style={{padding: ".3rem 0 1.2rem"}}>
        <Heading color="black" size={1.5}>{title}</Heading>
        {showTypes && <Types />}
      </SpaceBetweenStyled>

      {data.length < 1 && (
        <FlexCenterStyled style={{ paddingLeft: "1rem"}} $gap={.5}>
          <Heading size={1.2} color="secondary">No Chart Data</Heading>
        </FlexCenterStyled>
      )}
      
      {data.length > 0 && (
        <ResponsiveContainer className="main_chart" width={config.width} height={config.height} maxWidth="90%">
          <AreaChart width={500} height={207} data={data} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3400FF" stopOpacity={1}/>
                <stop offset="95%" stopColor="#3400FF" stopOpacity={.6}/>
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="1" x2="0" y2="1">
                <stop offset="5%" stopColor="#FFFFFF" stopOpacity={.9}/>
                <stop offset="95%" stopColor="#000000" stopOpacity={0.87}/>
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={data => valueFormatter(data)} key="name" width={config.yAxisWidth ?? 100} />
            <Tooltip formatter={(data) => valueFormatter(data) } />
            <Area type="monotone" dataKey="value" stroke="#3400FF" fillOpacity={.9} fill="url(#colorUv)" />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </AreaChartStyled>
  )
}

const Types = () => (
  <FlexStyled>
    <Heading color="secondary">
            <span
              style={{ fontSize: "9px", padding: ".04rem .38rem", borderRadius: "50%", position: "relative", bottom: ".08rem", backgroundColor: "black", marginRight: ".3rem"}}
            />
      Sales
    </Heading>
    <Heading color="secondary">
            <span
              style={{ fontSize: "9px", padding: ".04rem .38rem", borderRadius: "50%", position: "relative", bottom: ".08rem", backgroundColor: "orange", marginRight: ".3rem"}}
            />
      Customers
    </Heading>
  </FlexStyled>
)

export default AreaCharts
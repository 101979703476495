import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import { FlexStyled } from "../../../styles/utilStyles";
import {Button, Form} from "../../index";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {actionsPermissions} from "../../../data/permissions";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {WIPStatus} from "../../../utils/table";


const EditExpenseModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { currencyFormatter, dateFormatter } = useDataTypeFormatter()

  const handleSubmission = (status) => {
    handleSubmit({ ...formValues, status })
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Expense/Bill Detail</Heading>

        <Form values={formValues} onSubmit={handleSubmit} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Category: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues?.custom_category_name || formValues?.category_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{currencyFormatter(formValues.amount)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Created by: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.created_by_name}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.created_date)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Remark: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.remark}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Action By: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.staff_action_name || ""}</Paragraph>
          </FlexStyled>
          
          
          <FlexStyled>  </FlexStyled>
          
          {(!mutation.isLoading && !formValues.staff_action) && (
            <FlexStyled>
              <Button permissionCode={actionsPermissions.approveExpenses} text="Approve" onClick={() => handleSubmission("approved")} type="submit" isLoading={mutation.isLoading} />
              <Button permissionCode={actionsPermissions.rejectExpenses} text="Reject" onClick={() => handleSubmission("rejected")} className="outline" type="submit" isLoading={mutation.isLoading} />
            </FlexStyled>
          )}
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditExpenseModal
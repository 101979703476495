import {useQueryClient} from "react-query";
import queryKeys from "../../useServices/queryKeys";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getInventoryByBusinessIdService} from "../../../services/inventoryServices";


const useInventoryPrefetch = () => {
	const { getBusinessId } = useGlobalContext()
	const queryClient = useQueryClient()
	const businessId = getBusinessId()
	
	const fetch = () => {
		queryClient.prefetchQuery({
			queryKey: [queryKeys.INVENTORIES, businessId, undefined],
			queryFn: () =>  getInventoryByBusinessIdService({ business_id: businessId })
		})
	}
	
	return { fetch }
}


export default useInventoryPrefetch
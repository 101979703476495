import axios from "../../axios";

const getPermissionService = async () => {
  try {
    const { data } = await axios.get("/permission/")
    return data
  } catch (e) {
    throw e
  }
}

const getPermissionCategoryService = async () => {
  try {
    const { data } = await axios.get("/permission_categories/")
    return data
  } catch (e) {
    throw e
  }
}

export { getPermissionService, getPermissionCategoryService }
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  deleteProductionStagesService,
  getProductionStagesService,
  updateProductionStagesService,
  createProductionStagesService
} from "../../../services/productionServices/productionStagesServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import errorHandler from "../../../utils/errorHandler";


const mainKey = ["Production Stages", getBusinessIdFromStorage()]
const productionStagesQueryKeys = {
  all: mainKey,
  mainList: (query) => [...mainKey, getAllObjectKeysWithValues(query)]
}


export const useProductionStagesQuery = (query = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: productionStagesQueryKeys.mainList(query),
    queryFn: () => getProductionStagesService({ ...query, business_id: getBusinessId() })
  })
}


export const useCreateProductionStagesMutation = ({ successFn }) => {
  const { getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: body => {
      return createProductionStagesService({ ...body, business: getBusinessId() })
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Create Successful!!", "create")
      await queryClient.refetchQueries({ queryKey: productionStagesQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useUpdateProductionStagesMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: (body) => {
      return updateProductionStagesService(body.id, body)
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Update Successful!!", "update")
      await queryClient.refetchQueries({ queryKey: productionStagesQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useDeleteProductionStagesMutation = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()


  return useMutation({
    mutationFn: id => {
      return deleteProductionStagesService(id)
    },

    onSuccess: async () => {
      toast.success("Delete Successful!!", "delete")
      await queryClient.refetchQueries({ queryKey: productionStagesQueryKeys.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
import {
  purchaseOrderRadioOptions
} from "../../components/Modal/InventoryModal/PurchaseOrderModals/CreatePurchaseOrderModal";
import uuid from "react-uuid";


export const handleCreatePurchaseOrderFormatting = (values = {}, isDelete = true) => {
  const formValues = { ...values }
  const { sales_attachment_main, sales_attachment_display_main, sales_attachment_quantity_main, sales_attachment_cost_main, sales_attachment_expiry_main, sales_attachment_batch_number_main, sales_attachment_measurement_name_main, sales_attachment_measurement_base_main, sales_attachment_measurement_array_main, sales_attachment_measurement_stock_low_level_main, sales_attachment_main_measurement_selling_price_main, sales_attachment_supplied_quantity_main } = values
  
  const products = []
  
  if(sales_attachment_main) {
    const quantity = Number(sales_attachment_measurement_base_main || 1) * Number(sales_attachment_quantity_main)
    const supplied_quantity = Number(sales_attachment_measurement_base_main || 1) * Number(sales_attachment_supplied_quantity_main || sales_attachment_quantity_main)
  
    const meta_measurement = [...(sales_attachment_measurement_array_main || [])]
    meta_measurement.shift()

    products.push({
      batch_number: sales_attachment_batch_number_main,
      product_name: sales_attachment_display_main,
      product_id: sales_attachment_main,
      requested_quantity: quantity,
      cost_price: (Number(sales_attachment_cost_main) / Number(sales_attachment_measurement_base_main || 1)),
      expiry_date: sales_attachment_expiry_main,
      unit_measurement: sales_attachment_measurement_name_main || "",
      base_quantity: sales_attachment_measurement_base_main || 1,
      
      // new data
      selected_measurement: formValues[`sales_attachment_measurement_real_name_main`],
      ...(formValues?.receive && { meta_measurement: meta_measurement }),
      low_stock_level: sales_attachment_measurement_stock_low_level_main || "",
      selling_price: sales_attachment_main_measurement_selling_price_main,
      all_meta_measurement: sales_attachment_measurement_array_main || [],
      
      ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
        formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
        supplied_quantity: Number(supplied_quantity),
      }),
  
      ...(formValues?.receive && { supplied_quantity: supplied_quantity })
    })
  }
  
  if(isDelete) {
    deleteFieldsInForm(formValues, "main")
  }
  
  for (const key in formValues) {
    if(key.slice(0, 21) === "sales_attachment_name") {
      const uuid = key.split("_")[3]
      if(!formValues[`sales_attachment_name_${uuid}`]) continue
  
      const product_id = formValues[`sales_attachment_name_${uuid}`]
      const product_name = formValues[`sales_attachment_display_${uuid}`]
      const requested_quantity = Number(formValues[`sales_attachment_quantity_${uuid}`])
      const cost_price = (Number(formValues[`sales_attachment_cost_${uuid}`]) / Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1))
      const expiry_date = formValues[`sales_attachment_expiry_${uuid}`]
      const batch_number = formValues[`sales_attachment_batch_number_${uuid}`]
      
      const quantity = Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1) * Number(requested_quantity)
      const supplied_quantity = Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1) * Number(formValues[`sales_attachment_supplied_quantity_${uuid}`] || requested_quantity)
  
      const meta_measurement = [...(formValues[`sales_attachment_measurement_array_${uuid}`] || [])]
      meta_measurement.shift()
      
      products.push({
        product_id,
        product_name,
        requested_quantity: quantity,
        cost_price,
        expiry_date,
        batch_number,
        unit_measurement: formValues[`sales_attachment_measurement_name_${uuid}`] || [],
        base_quantity: formValues[`sales_attachment_measurement_base_${uuid}`] || 1,
        
        // new data
        selected_measurement: formValues[`sales_attachment_measurement_real_name_${uuid}`],
        ...((formValues?.receive) && { meta_measurement: meta_measurement }),
        stock_low_level: formValues[`sales_attachment_measurement_stock_low_level_${uuid}`] || "",
        selling_price: formValues[`sales_attachment_main_measurement_selling_price_${uuid}`] || "",
        all_meta_measurement: formValues[`sales_attachment_measurement_array_${uuid}`] || [],
        
        ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
          formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
          supplied_quantity: Number(supplied_quantity),
        }),
  
        ...(formValues?.receive && { supplied_quantity: supplied_quantity })
      })
  
      if(isDelete) {
        deleteFieldsInForm(formValues, uuid)
      }
    }
  }

  return {
    ...formValues,
    ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
      formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
      supplied: true
    }),
    productsToCalculate: products,
    products,
    ...(formValues?.receive && { supplied_data: products })
  }
}

export const getPurchaseOrderTotalCost = (purchaseOrder = {}) => {
  const totalProductCost = purchaseOrder?.productsToCalculate?.reduce((prev, curr) => {
    return prev + (Number(curr?.cost_price) * Number(curr?.requested_quantity)) || 0
  }, 0)
  
  return totalProductCost + Number(purchaseOrder?.tax || 0) + Number(purchaseOrder?.shipping_cost || 0)
}

export const getTotalCostPriceForPurchaseProduct = (values, id = "main") => {
  const cost = values[`sales_attachment_cost_${id}`]
  const quantity = values[`sales_attachment_quantity_${id}`]
  return Number(cost) * Number(quantity)
}

const deleteFieldsInForm = (formValues, uuid) => {
  if(uuid === "main") {
    delete formValues.sales_quantity_main
    delete formValues.sales_attachment_main
    delete formValues.sales_attachment_quantity_main
    delete formValues.sales_attachment_cost_main
    delete formValues.sales_attachment_display_main
    delete formValues.sales_attachment_expiry_main
    delete formValues.sales_attachment_cost_main_format
    delete formValues.sales_attachment_quantity_main_format
    delete formValues.sales_attachment_use_sales_margin_main
    delete formValues.sales_attachment_batch_number_main
    delete formValues.sales_attachment_measurement_base_main
    delete formValues.sales_attachment_measurement_array_main
    delete formValues.sales_attachment_measurement_stock_low_level_main
    delete formValues.sales_attachment_main_measurement_selling_price_main
    delete formValues.sales_attachment_measurement_array_persist_main
    delete formValues.sales_attachment_measurement_product_previous_selling_price_main
    delete formValues.sales_attachment_measurement_percentage_diff_main
    delete formValues.sales_attachment_measurement_display_main
    delete formValues.sales_attachment_measurement_name_main
  }
  
  delete formValues[`sales_attachment_name_${uuid}`]
  delete formValues[`sales_attachment_display_${uuid}`]
  delete formValues[`sales_attachment_quantity_${uuid}`]
  delete formValues[`sales_attachment_cost_${uuid}`]
  delete formValues[`sales_attachment_expiry_${uuid}`]
  delete formValues[`sales_attachment_quantity_${uuid}_format`]
  delete formValues[`sales_attachment_cost_${uuid}_format`]
  delete formValues[`sales_attachment_use_sales_margin_${uuid}`]
  delete formValues[`sales_attachment_batch_number_${uuid}`]
  delete formValues[`sales_attachment_measurement_name_${uuid}`]
  delete formValues[`sales_attachment_measurement_base_${uuid}`]
  delete formValues[`sales_attachment_main_measurement_selling_price_${uuid}`]
  delete formValues[`sales_attachment_measurement_stock_low_level_${uuid}`]
  delete formValues[`sales_attachment_measurement_array_${uuid}`]
  delete formValues[`sales_attachment_measurement_array_persist_${uuid}`]
  delete formValues[`sales_attachment_measurement_product_previous_selling_price_${uuid}`]
  delete formValues[`sales_attachment_measurement_product_previous_selling_price_${uuid}_format`]
  delete formValues[`sales_attachment_measurement_percentage_diff_${uuid}`]
  delete formValues[`sales_attachment_measurement_display_${uuid}`]
}

export const handleFormattingOfPurchaseOrderProductToManage = ({ id = "", selectedProducts = [], values = {}, setProductToManage, setFieldValue, setShowManageProductModal }) => {
  const product = selectedProducts.find(prod => prod.id === id)
  
  const incomingQuantity = Number(values[`sales_attachment_quantity_${id}`])
  const incomeQuantityInBase = incomingQuantity * Number(values[`sales_attachment_measurement_base_${id}`] || 1)
  
  let incomingQuantityText
  
  if(incomingQuantity === incomeQuantityInBase) {
    incomingQuantityText = `${incomingQuantity} ${product.product.unit_measurement || "Piece"}`
  } else {
    incomingQuantityText = `${incomingQuantity} ${product.product.unit_measurement || "Piece"} (${incomeQuantityInBase} qty)`
  }
  
  const metaMeasurementFormValues = (values[`sales_attachment_measurement_array_persist_${id}`] || product?.product?.all_meta_measurement || product?.product?.meta_measurement).reduce((prev, curr) => {
    return { ...prev, [`unit_measurement_${curr.name}_selling_price`]: curr.selling_price }
  }, {})
  
  setProductToManage({
    product: {
      ...product.product,
      product_form_id: id,
      ...metaMeasurementFormValues,
      incomingQuantity: incomingQuantityText,
      stock_low_level: values[`sales_attachment_measurement_stock_low_level_${id}`] || product.product.stock_low_level
    },
    setFieldValue
  })
  
  setShowManageProductModal()
}

export const handleFormattingOfPurchaseOrderForUpdating = ({ purchaseOrders = {}, receive = false, businessSettings = {}, currencyFormatter }) => {
  const values = { ...purchaseOrders }
  
  const productsIds = values.products.map(item => {
    const id = uuid()
  
    const quantity = Number(item.requested_quantity) / Number(item.base_quantity || 1)
    const costPrice = Number(item.cost_price) * Number(item.base_quantity || 1)
  
    values[`sales_attachment_name_${id}`] = item.product_id
    values[`sales_attachment_display_${id}`] = item.product_name
    values[`sales_attachment_quantity_${id}`] = quantity
    values[`sales_attachment_requested_quantity_${id}`] = Number(item.requested_quantity) / Number(item.base_quantity || 1)
    values[`sales_attachment_quantity_${id}_format`] = item.requested_quantity
  
    values[`sales_attachment_cost_${id}`] = costPrice
    values[`sales_attachment_cost_${id}_format`] = costPrice
  
    values[`sales_attachment_batch_number_${id}`] = item.batch_number || ""
    values[`sales_attachment_use_sales_margin_${id}`] = item.use_sales_margin
    values[`sales_attachment_measurement_display_${id}`] = item.selected_measurement
    values[`sales_attachment_measurement_${id}`] = item.unit_measurement
    values[`sales_attachment_measurement_name_${id}`] = item.unit_measurement
    values[`sales_attachment_measurement_base_${id}`] = item?.base_quantity || 1
    values[`sales_attachment_measurement_percentage_diff_${id}`] = item?.unit_price_difference || ""
    values[`sales_attachment_measurement_stock_low_level_${id}`] = item?.stock_low_level || ""
    values[`sales_attachment_supplied_quantity_${id}`] = Number(item.requested_quantity) / Number(item.base_quantity || 1)
    values[`sales_attachment_supplied_quantity_${id}_format`] = Number(item.requested_quantity) / Number(item.base_quantity || 1)
    values[`sales_attachment_expiry_${id}`] = item?.expiry_date || ""
    values[`sales_attachment_measurement_array_${id}`] = item.all_meta_measurement
    values[`sales_attachment_measurement_real_name_${id}`] = item.selected_measurement
    values[`sales_attachment_total_cost_${id}`] = quantity * costPrice
    
    // if(businessSettings?.use_sales_margin) {
    //   const salesMarginPercentage = (Number(item?.sales_margin) === 0) ? item?.sales_margin : businessSettings?.sales_margin
    //   const perValue = (Number(item.cost_price) * Number(item.base_quantity || 1)) * (Number(salesMarginPercentage) / 100)
    //   const forecastValue = (Number(item.cost_price) * Number(item.base_quantity || 1)) + perValue
    //
    //   values[`sales_attachment_forecast_${id}`] = `${item.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)`
    // }
  
    return id
  })
  
  const products = values.products.map(product => ({ ...product, meta_measurement: product.all_meta_measurement }))
  
  return { ...values, productsIds, products, receive }
}

export const handleFormattingOfPurchaseOrderForRestockingOfProducts = ({ products = [], businessSettings, currencyFormatter }) => {
  const values = {}
  const allProducts = []
  
  const productsIds = products.map(item => {
    const id = uuid()
  
    const productUnitMeasurement = item.unit_measurement || "Piece"
  
    const meta_measurement = [
      { name: productUnitMeasurement, full_name: `${productUnitMeasurement} (1 qty)`, base_quantity: 1, selling_price: item.selling_price },
      ...((item?.meta_measurement || []).map(meta => ({ ...meta, full_name: `${meta.name} (${meta.base_quantity} qty)` })))]
    
    values[`sales_attachment_name_${id}`] = item.id
    values[`sales_attachment_display_${id}`] = item.name
    values[`sales_attachment_quantity_${id}`] = ""
    values[`sales_attachment_requested_quantity_${id}`] = ""
    values[`sales_attachment_cost_${id}`] = item?.cost_prices?.[item?.cost_prices?.length - 1]?.cost_price || ""
    values[`sales_attachment_quantity_${id}_format`] = ""
    values[`sales_attachment_cost_${id}_format`] = item?.cost_prices?.[item?.cost_prices?.length - 1]?.cost_price || ""
    values[`sales_attachment_batch_number_${id}`] = ""
    values[`sales_attachment_measurement_display_${id}`] = ""
    values[`sales_attachment_measurement_${id}`] = ""
    values[`sales_attachment_measurement_base_${id}`] = 1
    values[`sales_attachment_measurement_percentage_diff_${id}`] = ""
    values[`sales_attachment_measurement_stock_low_level_${id}`] = item?.stock_low_level || ""
    values[`sales_attachment_measurement_array_${id}`] = meta_measurement
    
    // let forecastPrice = ""
    // if(businessSettings?.use_sales_margin && item?.cost_prices?.length > 0) {
    //   const cp = Number(item?.cost_prices?.[item?.cost_prices?.length - 1]?.cost_price)
    //   const salesMarginPercentage = item?.sales_margin || businessSettings?.sales_margin
    //   const perValue = cp * (Number(salesMarginPercentage) / 100)
    //   const forecastValue = Number(cp) + perValue
    //   forecastPrice = forecastValue || ""
    //
    //   values[`sales_attachment_forecast_${id}`] = `${item.name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage).toFixed(1)}% sales margin)`
    // }
    
    allProducts.push({
      "product_name": item.name,
      "product_id": item.id,
      "requested_quantity": "",
      "cost_price": item?.cost_prices?.[item?.cost_prices?.length - 1]?.cost_price || "",
      "sales_margin": item?.sales_margin || businessSettings?.sales_margin || "",
      "selling_price": item.selling_price,
      "meta_measurement": meta_measurement,
    })
    
    return id
  })
  
  return { ...values, productsIds, products: allProducts, isRestocking: true }
}


export const getPurchaseOrderTotalPayableAmount = (purchaseOrder = {}) => {
  if(Number(purchaseOrder?.paid_amount) === 0) {
    return getPurchaseOrderTotalCost(purchaseOrder) + Number(purchaseOrder?.tax || 0) + Number(purchaseOrder?.shipping_cost || 0)
  }
  
  return purchaseOrder?.balance
}
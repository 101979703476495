import {useMutation, useQuery, useQueryClient} from "react-query";
import {
	createBusinessReservedAccountServices,
	createCustomerReservedAccountService,
	createDynamicAccountService,
	createReservedAccountService,
	createSettlementAccountServices, createSubscriptionAccountServices,
	getOvaloopPayTransactionWallet,
	getSettlementBankServices,
	getSettlementRequestLogsService,
	ovaloopTransferLogsService,
	requestSettlementService,
	verifyDynamicPaymentService, getSubscriptionAccountServices, getSubscriptionAccountLogsServices, getPendingTransactionLogsServices
} from "../../../services/ovaloopPaymentServices";
import queryKeys from "../queryKeys";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {getBusinessIdFromStorage} from "../../../utils";
import orderQueryKey from "../useOrderServices/orderQueryKey";
import inventoryQueryKey from "../useInventoryServices/inventoryQueryKey";
import productQueryKey from "../useProductServices/productQueryKey";
import customerQueryKey from "../useCustomerServices/customQueryKey";
import staffQueryKey from "../useStaffServices/staffQueryKey";
import {useParams} from "react-router-dom";


const main_key = ["ovaloop_pay", getBusinessIdFromStorage()]

export const ovaloopPayQueryKeys = {
	settlementBanks: [...main_key, "settlement_banks"],
	transactionWallet: [...main_key, "transaction_wallet"],
	settlementRequestLogs: (queryParams = {}) => [...main_key, "settlement_request_logs", queryParams],
	transferLogs: (queryParams = {}) => [...main_key, "transfer_logs", queryParams],
	dynamicAccountForOrder: (queryParams) => [...main_key, "dynamic_account", queryParams],
	subscriptionAccount: [...main_key, "subscription_account"],
	subscriptionAccountLogs: (queryParams = {}) => [...main_key, "subscription_account_logs", getAllObjectKeysWithValues(queryParams)],
	pendingTransactionLogs: (queryParams = {}) => [...main_key, "pending_transaction_logs", getAllObjectKeysWithValues(queryParams)]
}


export const useSettlementBankQuery = () => {
	return useQuery({
		queryKey: ovaloopPayQueryKeys.settlementBanks,
		queryFn: getSettlementBankServices
	})
}


export const useTransactionWalletQuery = () => {
	const { getBusinessId } = useGlobalContext()

	return useQuery({
		queryKey: ovaloopPayQueryKeys.transactionWallet,
		queryFn: () => getOvaloopPayTransactionWallet(getBusinessId())
	})
}

export const usePendingTransactionLogsQuery = (queryParams = {}) => {
	const { getBusinessId } = useGlobalContext()
	
	return useQuery({
		queryKey: ovaloopPayQueryKeys.pendingTransactionLogs(queryParams),
		queryFn: () => getPendingTransactionLogsServices({ business_id: getBusinessId(), ...queryParams })
	})
}

export const useSettlementRequestLogsQuery = (queryParams = {}) => {
	const { businessId: business_id } = useParams()
	const { getBusinessId } = useGlobalContext()
	const businessId = business_id || getBusinessId()
	
	return useQuery({
		queryKey: ovaloopPayQueryKeys.settlementRequestLogs(getAllObjectKeysWithValues({ ...queryParams, businessId })),
		queryFn: () => getSettlementRequestLogsService({ business_id: businessId, ...queryParams })
	})
}

export const useOvaloopTransferLogQuery = (queryParams = {}) => {
	const { businessId: business_id } = useParams()
	const { getBusinessId } = useGlobalContext()
	const businessId = business_id || getBusinessId()
	
	return useQuery({
		queryKey: ovaloopPayQueryKeys.transferLogs(getAllObjectKeysWithValues({ ...queryParams, businessId })),
		queryFn: () => ovaloopTransferLogsService({ business_id: businessId, ...queryParams })
	})
}

export const useCreateSettlementAccountMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()

	return useMutation({
		mutationFn: (body) => createSettlementAccountServices(getBusinessId(), { ...body, business: getBusinessId() }),

		onSuccess: () => {
			successFn?.()
			toast.success("Settlement account created", "created")
			queryClient.invalidateQueries({ queryKey: [queryKeys.STORES, getBusinessId()] })
		},

		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useCreateBusinessReservedAccountMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	
	return useMutation({
		mutationFn: () => createBusinessReservedAccountServices({ business_id: getBusinessId() }),
		
		onSuccess: () => {
			successFn?.()
			toast.success("Permanent account created", "created")
			queryClient.invalidateQueries({ queryKey: ovaloopPayQueryKeys.transactionWallet })
			queryClient.invalidateQueries({ queryKey: [queryKeys.STORES, getBusinessId()] })
		},
		
		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useDynamicAccountQuery = (body, isAccountExpired) => {
	return useQuery({
		queryKey: ovaloopPayQueryKeys.dynamicAccountForOrder(getAllObjectKeysWithValues(body)),
		queryFn: () => createDynamicAccountService(body),
		enabled: isAccountExpired
	})
}


export const useDynamicAccountMutation = () => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()
	
	
	return useMutation({
		mutationFn: (body) => createDynamicAccountService(body),
		
		onSuccess: (res, variables) => {
			queryClient.setQueryData(
				ovaloopPayQueryKeys.dynamicAccountForOrder(getAllObjectKeysWithValues(variables)),
				res
			)
		},
		
		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}


export const useVerifyDynamicPaymentMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()

	const businessId = getBusinessId()

	return useMutation({
		mutationFn: (queryParam) => verifyDynamicPaymentService(queryParam),

		onSuccess: (res) => {
			successFn(res)
			toast.success("Transfer confirmed")
			queryClient.refetchQueries({ queryKey: [queryKeys.TRANSACTION_WALLET, businessId] })
			queryClient.refetchQueries({ queryKey: [queryKeys.TRANSFER_LOGS, businessId] })
			
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
			queryClient.refetchQueries({ queryKey: orderQueryKey.analytics })
			queryClient.refetchQueries({ queryKey: orderQueryKey.mainListKey })
			queryClient.invalidateQueries({ queryKey: productQueryKey.onlyLists })
			queryClient.refetchQueries({ queryKey: customerQueryKey.all })
			queryClient.refetchQueries({ queryKey: staffQueryKey.all})
			
		},

		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}


export const useReservedAccountMutation = ({ successFn }) => {
	const { toast, getBusinessId } = useGlobalContext()

	return useMutation({
		mutationFn: () => createReservedAccountService(getBusinessId()),

		onSuccess: (res) => {
			successFn(res)
		},

		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useCreateCustomerReservedAccountMutation = ({ successFn }) => {
	const { toast, getBusinessId } = useGlobalContext()
	const queryClient = useQueryClient()
	
	return useMutation({
		mutationFn: (customerId) => {
			toast.success("Generating account number", "generate")
			return createCustomerReservedAccountService(getBusinessId(), customerId)
		},
		
		onSuccess: (res, customerId) => {
			successFn(res)
			queryClient.refetchQueries({ queryKey: customerQueryKey.byId(customerId) })
			queryClient.refetchQueries({ queryKey: customerQueryKey.byId(customerId.toString()) })
		},
		
		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}


export const useRequestSettlementMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => requestSettlementService(body),
		
		onSuccess: () => {
			successFn()
			toast.success("Withdrawal is being processed")
			queryClient.refetchQueries({ queryKey: main_key })
		},
		
		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}



export const useSubscriptionAccountQuery = () => {
	return useQuery({
		queryKey: ovaloopPayQueryKeys.subscriptionAccount,
		queryFn: getSubscriptionAccountServices
	})
}

export const useSubscriptionAccountLogsQuery = (query = {}) => {
	return useQuery({
		queryKey: ovaloopPayQueryKeys.subscriptionAccountLogs(query),
		queryFn: () => getSubscriptionAccountLogsServices(query)
	})
}

export const useCreateSubscriptionAccountMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => createSubscriptionAccountServices(body),
		
		onSuccess: () => {
			successFn?.()
			toast.success("Subscription account created", "created")
			queryClient.invalidateQueries({ queryKey: ovaloopPayQueryKeys.subscriptionAccount })
			queryClient.invalidateQueries({ queryKey: [queryKeys.STORES, getBusinessId()] })
		},
		
		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}
import {EmptyWallet} from "iconsax-react";
import {IoInfinite} from "react-icons/io5"

export const paymentMethodEnum = {
	pos: {title: "Pos", value: "pos"},
	cash: {title: "Cash", value: "cash"},
	partial: {title: "Partial", value: "partial"},
	credit: {title: "Credit", value: "credit"},
	ovaloop_pay: {title: "Ovaloop Pay", value: "ovaloop_transfer"},
	bank_transfer: {title: "Bank Transfer", value: "transfer"},
	pay_later: {title: "Tables", value: "pay_later"},
	transfer: {title: "Bank Transfer", value: "transfer"},
	split: {title: "Split", value: "split"},
	subscription: {title: "Subscription", value: "subscription"},
	pay_on_delivery: {title: "Pay On Delivery", value: "pay_on_delivery"},
	bill_on_table: {title: "Bill On Table", value: "pay_later"},
	invoice: {title: "Invoice", value: "invoice"},
	others: {title: "Others", value: "others"}
}

export const basicPlanPaymentMethods = [
	{title: "Pos", value: "pos", Icon: EmptyWallet},
	{title: "Cash", value: "cash", Icon: EmptyWallet}
]

export const purchaseOrderPaymentMethods = [
	{title: "Paid in full", value: "paid", Icon: EmptyWallet},
	{title: "Partial", value: "partial", Icon: EmptyWallet},
	{title: "Credit", value: "credit", Icon: EmptyWallet}
]

export default [
	{ title: "Ovaloop Pay", value: "ovaloop_transfer", Icon: IoInfinite },
	{ title: "Pos", value: "pos", Icon: EmptyWallet },
	{ title: "Cash", value: "cash", Icon: EmptyWallet },
	{ title: "Bank Transfer", value: "transfer", Icon: EmptyWallet },
	{ title: "Split", value: "split", Icon: EmptyWallet },
	{ title: "Partial", value: "partial", Icon: EmptyWallet },
	{ title: "Credit", value: "credit", Icon: EmptyWallet },
]

export const posCheckoutPaymentMethods = [
	{ title: "Ovaloop Checkout", value: "ovaloop_transfer", Icon: IoInfinite, isNew: true },
	{ title: "Customer Wallet", value: "customer_wallet", Icon: EmptyWallet, isNew: true },
	{ title: "Pos", value: "pos", Icon: EmptyWallet },
	{ title: "Cash", value: "cash", Icon: EmptyWallet },
	{ title: "Bank Transfer", value: "transfer", Icon: EmptyWallet },
	{ title: "Split", value: "split", Icon: EmptyWallet },
	{ title: "Partial", value: "partial", Icon: EmptyWallet },
	{ title: "Credit", value: "credit", Icon: EmptyWallet },
	{ title: "Subscription", value: "subscription", Icon: EmptyWallet },
	{ title: "Others", value: "others", Icon: EmptyWallet },
]

export const generalCheckoutPaymentMethods = [
	{ title: "Ovaloop Checkout", value: "ovaloop_transfer", Icon: IoInfinite, isNew: true },
	{ title: "Pos", value: "pos", Icon: EmptyWallet },
	{ title: "Cash", value: "cash", Icon: EmptyWallet },
	{ title: "Bank Transfer", value: "transfer", Icon: EmptyWallet },
]

export const desktopOfflinePaymentMethods = [
	{title: "Pos", value: "pos", Icon: EmptyWallet},
	{title: "Cash", value: "cash", Icon: EmptyWallet},
	{title: "Bank Transfer", value: "transfer", Icon: EmptyWallet},
	{title: "Split", value: "split", Icon: EmptyWallet},
	{title: "Partial", value: "partial", Icon: EmptyWallet},
	{title: "Credit", value: "credit", Icon: EmptyWallet},
]

export const storeFrontPaymentMethods = [
	{ title: "Ovaloop Checkout", value: "ovaloop_transfer", Icon: IoInfinite },
]

export const storeFrontWithPayOnDeliveryPaymentMethods = [
	{ title: "Ovaloop Checkout", value: "ovaloop_transfer", Icon: IoInfinite },
	{ title: "Pay on Delivery", value: "pay_on_delivery", Icon: EmptyWallet },
]


export const tableCheckoutPaymentMethods = [
	// { title: "Ovaloop Pay", value: "ovaloop_transfer", Icon: EmptyWallet },
	{ title: "Pos", value: "pos", Icon: EmptyWallet },
	{ title: "Cash", value: "cash", Icon: EmptyWallet },
	{ title: "Bill On Table", value: "pay_later", Icon: EmptyWallet },
	{ title: "Bank Transfer", value: "transfer", Icon: EmptyWallet },
]


export const tableCheckoutPaymentFromTableMethods = [
	// { title: "Ovaloop Pay", value: "ovaloop_transfer", Icon: EmptyWallet },
	{ title: "Pos", value: "pos", Icon: EmptyWallet },
	{ title: "Cash", value: "cash", Icon: EmptyWallet },
	{ title: "Bank Transfer", value: "transfer", Icon: EmptyWallet },
]
import {Input} from "../index";
import {useFormikContext} from "formik";


const AddressInput = ({ name, placeholder, label, isCustomer, main_name = " ", required }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = (address) => {
    if(isCustomer) {
      const addressData = [{
        address,
        lat: "",
        long: ""
      }]

      setFieldValue(name, addressData)
      setFieldValue(main_name, address)
      return
    }

    setFieldValue(name, address)
    setFieldValue(main_name, address)
  }

  return (
    <Input className="fullWidth" optional={!required} label={label} placeholder={placeholder} name={main_name} onChange={handleChange}  />
  )
}

export default AddressInput
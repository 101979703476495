import React from "react"
import {useLocation} from "react-router-dom";
import { Formik, Form as Forms } from "formik"
import {childrenPropToArray} from "../../utils/forms";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";

export const FormProvider = React.createContext(null)


const Form = ({ enableReinitialize = false, children, values, onSubmit, additionalData, modalName, setAdditionalData, allFieldsNotRequired, innerRef }) => {
  const { pathname } = useLocation()
  const childrenArr = childrenPropToArray(children)
  const { globalState: { modalConnector } } = useGlobalContext()

  const getFormValues = () => {
    if((modalConnector.fromModalURL === pathname) && (modalConnector.fromModalName === modalName)) {
      return { ...values, ...modalConnector.fromModalFormValues }
    }

    return values
  }

  const handleAllFormSubmission = (values, helpers) => {
    const newValues = {}

    for (const key in values) {
      if(values[key] !== "") {
        newValues[key] = values[key]
      }
    }

    onSubmit(newValues, helpers)
  }

  return (
    <Formik enableReinitialize={enableReinitialize} initialValues={getFormValues()} onSubmit={handleAllFormSubmission} innerRef={innerRef}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Forms autoComplete="off">
          <FormProvider.Provider value={{ ...additionalData, setAdditionalData, isSubmitting, values, setFieldValue, allFieldsNotRequired }}>
            {childrenArr}
          </FormProvider.Provider>
        </Forms>
      )}
    </Formik>
  )
}

export default Form
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, Select, TextArea} from "../../../index";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useParams} from "react-router-dom";
import {useSubCategoriesByCategoryIdQuery} from "../../../../hooks/useServices/useSubCategoryServices";
import {useNestedSubCategoriesQuery} from "../../../../hooks/useServices/useNestedSubCategoryServices";


const EditNestedSubCategoryModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { parentCategoryName, parentCategoryId, parentParentId, level } = useParams()
  
  const { data: allSubCategories } = useSubCategoriesByCategoryIdQuery(parentParentId)
  
  const { data: allNestedSubCategories } = useNestedSubCategoriesQuery({
    level: Number(level) - 1,
    ...(Number(level) === 2 ? { sub_category: parentParentId } : { parent: parentParentId })
  })
  
  
  const allowedCategories = () => {
    if(Number(level) === 1) {
      return allSubCategories?.results?.filter(category => category.id !== parentCategoryId)
    }
    
    return allNestedSubCategories?.results?.filter(category => category.id !== parentCategoryId)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <Select
            optional
            options={allowedCategories()}
            label="Category"
            placeholder={parentCategoryName}
            name={Number(level) === 2 ? "sub_category" : "parent"}
            valueKey="id"
            displayKey="name"
            displayName="category_name"
          />
          
          <Input label="Name" placeholder="Name of sub-category" name="name"  />
          <TextArea optional label="Describe Sub-Category" placeholder="Enter a description for this sub-category" name="description" />

          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Save" type="submit" />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditNestedSubCategoryModal
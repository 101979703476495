import {useEffect} from "react";
import {clearAllDataInStorage, getAuthTokenFromStorage, getBusinessIdFromStorage} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useUserProfile} from "../../useServices/userHooks";


const useAllowOnlyAuthenticatedUsers = () => {
	const { navigate } = useGlobalContext()
	const { data: userProfile, isLoading: isUserProfileSuccess, error } = useUserProfile()

	useEffect(() => {
		const businessId = getBusinessIdFromStorage()
		const authToken = getAuthTokenFromStorage()
		
		if((!businessId && !authToken) || error) {
			clearAllDataInStorage()
			navigate("/login")
		}
		
		if(!!authToken && !businessId && isUserProfileSuccess) {
			if(userProfile?.[0]?.user_type === "staff") {
				return
			}
			
			navigate("/select-store")
		}
		
	}, [userProfile])
}

export default useAllowOnlyAuthenticatedUsers
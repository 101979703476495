import queryKeys from "../queryKeys";
import {
  createRoleService, deleteRoleService,
  editRoleService,
  getRoleService
} from "../../../services/roleServices";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";


const useRoleQuery = (searchValue) => {
  const { getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()

  return useQuery({
    queryKey: [queryKeys.ROLES, searchValue],
    queryFn: () => getRoleService(businessId, searchValue),
  })
}

const useCreateRoleMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createRoleService({ ...data, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Role Created!!")
      queryClient.invalidateQueries({ queryKey: [queryKeys.ROLES] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditRoleMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editRoleService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({ queryKey: queryKeys.ROLES })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteRoleMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: roleId => {
      return deleteRoleService(roleId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: queryKeys.ROLES })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useRoleQuery, useCreateRoleMutation, useEditRoleMutation, useDeleteRoleMutation }
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, Form, Input, StaffRolesPermissionCard} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {transformActivePermissionsToArray, transformPermissionsToObject} from "../../../../utils/dataManipulation";
import useStructuredPermissions from "../../../../hooks/useStructuredPermissions";
import {Fragment, useState} from "react";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import MultiSelectInput from "../../../Forms/MultiSelectInput";


const EditRoleModal = ({onClose, handleSubmit, mutation, formValues}) => {
  const structuredPermissions = useStructuredPermissions()
  
  const [exemptedProducts, setExemptedProducts] = useState([])
  const {data: allCategories, isSuccess: isCategorySuccess} = useCategoriesQuery()
  
  const formatValuesForSubmission = (values) => {
    const {id, name, permission, ...rest} = values
    const permissionArray = transformActivePermissionsToArray(rest)
    
    handleSubmit({id, name, permission: permissionArray, exempted_category: exemptedProducts})
  }
  
  const formatValuesForFormValues = (values) => {
    const {id, name, permission} = values
    const permissionObj = transformPermissionsToObject(permission)

    return { id, name, ...permissionObj }
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={75}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>
        <Form values={formatValuesForFormValues(formValues)} onSubmit={formatValuesForSubmission} allFieldsNotRequired>
          <Input label="Role Title" placeholder="Enter role title" className="fullWidth" name="name"/>
    
          {isCategorySuccess && (
            <Fragment>
              <MultiSelectInput
                optional
                className="fullWidth"
                selectedValues={formValues.exempted_category}
                options={allCategories?.results}
                displayName="name"
                setSelected={setExemptedProducts}
                label="Exempted product categories"
                placeholder="Select categories to prevent staff role from selling"
              />
            </Fragment>
          )}
    
          <FlexStyled className="three_cols">
            {(!!structuredPermissions && structuredPermissions.length > 0) &&
              structuredPermissions.map(item => (
                <StaffRolesPermissionCard permission={item} key={item.id}/>
              ))
            }
          </FlexStyled>
    
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation?.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditRoleModal
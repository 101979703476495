import {handleGetCurrentCount} from "../useOrderQueryWithSearchAndFilter";

const useSearchAndFilterQuery = (state, useMainQuery, useFilterQuery, noPagination) => {
	const {searchValue, filterValue} = state
	
	const searchResponse = useMainQuery(searchValue)
	const filterResponse = useFilterQuery(filterValue, searchValue)
	
	const mainQuery = queryResponse(searchResponse)
	const searchQuery = queryResponse(searchResponse)
	const filterQuery = queryResponse(filterResponse)
	
	const data = getWhichQueryDataToShow(state, mainQuery.data, searchQuery.data, filterQuery.data)
	
	return {
		mainQuery,
		searchQuery,
		filterQuery,
		get data() { return data },
		get next() { return data?.next },
		get prev() { return data?.previous },
		get currentCount() { return handleGetCurrentCount(data?.next, data?.previous) },
		get showPagination() { return !!data?.next || !!data?.previous },
		get isQueryLoading() { return searchQuery.isLoading || filterQuery.isLoading },
		get isQueryWithData() {
			return (searchQuery.isSuccess && filterQuery.isSuccess && !getIsSearchOrFilterEmpty(data, noPagination))
		},
		get isQueryWithNoData() {
			return (searchQuery.isSuccess && filterQuery.isSuccess && getIsSearchOrFilterEmpty(data, noPagination))
		},
		get isEmpty() { return noPageData(data, searchValue, filterValue, noPagination) && !this.isQueryLoading },
		emptyMessage(dataName) { return getEmptyMessage(dataName, state) },
		get showTable() {
			if(this.isQueryLoading) return true
			if(searchQuery.isSuccess && !this.isEmpty) return true
		},
	}
}

export default useSearchAndFilterQuery

const queryResponse = (query) => {
	return {
		data: query?.data,
		refetch: query?.refetch,
		isSuccess: query?.isSuccess,
		isLoading: query?.isLoading,
		isError: query?.status === "error",
	}
}

const getWhichQueryDataToShow = (state, mainQueryData, searchQueryData, filterQueryData) => {
	const { searchValue, filterValue } = state
	
	if(!!searchValue && !filterValue) {
		return searchQueryData
	}
	
	if(!!filterValue) {
		return filterQueryData
	}
	
	return mainQueryData
}

const getEmptyMessage = (dataName, state) => {
	const { searchValue, filterValue } = state
	
	if(!!filterValue && !searchValue) {
		return `No ${dataName} with that filter`
	}
	
	return `No ${dataName} with name "${searchValue}"`
}

const getIsSearchOrFilterEmpty = (data, noPagination) => {
	if(noPagination) {
		return (!data || data.length < 1)
	}
	
	return (!data || data.results.length < 1)
}

const noPageData = (data, searchValue, filterValue,noPagination) => {
	if(!!searchValue || !!filterValue) {
		return false
	}
	
	if(noPagination) {
		return (!data || data.length < 1)
	}
	
	return (!data || data.results.length < 1)
}
import styled from "styled-components";
import {TablePaginationStyled} from "../../../styles/TableStyles";

const SetUpMainStyled = styled("div")`
  > ${TablePaginationStyled} {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    > ${TablePaginationStyled} {
      display: flex;
    }
  }
`

const SetUpLayoutStyled = styled("div")`
  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${TablePaginationStyled} {
      display: none !important;
    }
  }
`

export {
  SetUpMainStyled,
  SetUpLayoutStyled,
}
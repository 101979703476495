import {StoreFrontPageHeaderStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Heading} from "../../../../styles/textStyles";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {Fragment} from "react";
import {SectionStyled} from "../../../../styles/utilStyles";


const StoreFrontPageHeader = ({ pageTitle = "", LeftFilter, RightFilter, children }) => {
  return (
    <Fragment>
      {!children && (
        <Fragment>
          <StoreFrontPageHeaderStyled $padding={!LeftFilter && !RightFilter}>
            { !!LeftFilter ? <LeftFilter /> : <p></p> }
    
            { !!pageTitle && <Heading color="black" bold size={1.5}>{pageTitle}</Heading> }
            { !pageTitle && <TableSkeleton width={300} count={1} height={40} noTop /> }
    
            { !!RightFilter ? <p></p> : <p></p> }
          </StoreFrontPageHeaderStyled>
          
          <SectionStyled className="page_header_mobile_form">
            { !!LeftFilter ? <LeftFilter /> : <p></p> }
          </SectionStyled>

        </Fragment>
      )}
  
      {children && (
        <Fragment>
          <StoreFrontPageHeaderStyled>
            { !!pageTitle && <Heading color="black" bold size={1.5}>{pageTitle}</Heading> }
            { !pageTitle && <TableSkeleton width={300} count={1} height={40} noTop /> }
    
            {children}
          </StoreFrontPageHeaderStyled>
  
          <SectionStyled className="page_header_mobile_form">
            {children}
          </SectionStyled>
        </Fragment>
      )}
    </Fragment>
  )
}

export default StoreFrontPageHeader
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import handleDateFilterValue from "../../../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum, expiryDateFilter} from "../../../../data/selectField/dateFilter";
import {Fragment, useEffect, useState} from "react";
import {
  purchaseOrderQueryKey,
  useCreatePurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
  usePurchaseOrderQuery
} from "../../../../hooks/useServices/usePurchaseOrder";
import {
  CreatePurchaseOrderModal,
  InvoiceCheckOutModal,
  NoDataLayout,
  OrderSearchAndFilter,
  PageHeaderWithButtonAndDateFilter,
  PageSummaryCard,
  ScreenLoading,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {AddCircle} from "iconsax-react";
import {EmptyBoxImage} from "../../../../assets/images";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {pendingAndConfirmedOptions} from "../../../../data/selectField/orderFilter";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {useModal} from "../../../../hooks";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {purchaseOrderFormDefaultValues} from "../../../../data/defaultFormValues";
import {
  purchaseOrderRadioOptions
} from "../../../../components/Modal/InventoryModal/PurchaseOrderModals/CreatePurchaseOrderModal";
import {purchaseOrderPaymentMethods} from "../../../../data/selectField/paymentMethodsList";
import {usePurchaseOrderAnalyticsService} from "../../../../hooks/useServices/useAnalyticsServices";
import {actionsPermissions} from "../../../../data/permissions";
import {actionPermissionEnum} from "../../../../hooks/usePermissions/useActionPermission";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {getHeldPurchaseOrders, handleDeleteHeldPurchaseOrder} from "../../../../utils/holdOrders";
import {Heading} from "../../../../styles/textStyles";
import {handleFormattingOfPurchaseOrderForUpdating} from "../../../../utils/formatForms/purchaseOrderFormatter";
import {globalReducerActions} from "../../../../reducers/globalReducer";
import purchaseOrderRestructure from "../../../../services/purchaseOrderService/purchaseOrderRestructure";


const PurchaseOrder = () => {
  const {purchaseOrderTable, purchaseOrderOnHoldTable} = tableHeadersAndValueKeys
  const {navigate, toast, getBusinessId, globalReducer, isOffline} = useGlobalContext()
  const {numberFormatter} = useDataTypeFormatter()
  const [showCreateModal, setShowCreateModal] = useModal(modalNames.CREATE_PURCHASE_ORDER)
  const {debounceState, debounceReducer} = useQueryDebounce()
  const {currencyFormatter} = useDataTypeFormatter()
  
  const [showInvoiceCheckoutModal, setShowInvoiceCheckoutModal] = useModal()
  const [payFormValues, setPayEditFormValues] = useState(purchaseOrderFormDefaultValues)
  
  
  const {toDate, fromDate} = handleDateFilterValue(dateFilterValue.TODAY)
  const [dateFilter, setDateFilter] = useState({from_date: fromDate, to_date: toDate})
  
  const queryState = {
    status: debounceState.filterValue,
    search: debounceState.searchValue,
    from_date: debounceState.fromDateValue || dateFilter.from_date,
    to_date: debounceState.toDateValue || dateFilter.to_date,
    ...(debounceState.toDateValue ? {due_date: true} : {})
  }
  
  const {mainQuery, ...allPurchaseOrder} = useOrderQueryWithSearchAndFilter(queryState, usePurchaseOrderQuery)
  const {
    data: purchaseOrderAnalytics,
    isLoading: isPurchaseOrderAnalyticsLoading
  } = usePurchaseOrderAnalyticsService({from_date: dateFilter.from_date, to_date: dateFilter.to_date})
  
  useEffect(() => {
    // return () => {
    //   globalReducer({ type: globalReducerActions.CLEAR_RESTOCK_PRODUCTS })
    // }
  }, [])
  
  const getCurrentQueryKey = (query) => {
    const newQuery = {...query}
    if (query.status === "Payment Due Date") newQuery.status = ''
    
    return purchaseOrderQueryKey.orders(newQuery)
  }
  
  const handleCloseModal = () => {
    if (showCreateModal) {
      setShowCreateModal()
    }
    
    if (showInvoiceCheckoutModal) {
      setShowInvoiceCheckoutModal()
    }
  }
  
  const purchaseOrderMutation = useCreatePurchaseOrderMutation({ successFn: handleCloseModal })
  const deletePurchaseOrderMutation = useDeletePurchaseOrderMutation({ successFn: () => {} })
  
  const getTotalItemCost = () => {
    const totalProductCost = payFormValues?.products?.reduce((prev, curr) => {
      return prev + (Number(curr?.cost_price) * Number(curr?.requested_quantity)) || 0
    }, 0)
    
    return totalProductCost + Number(payFormValues?.tax || 0) + Number(payFormValues?.shipping_cost || 0)
  }
  
  const handleCreatePurchaseOrder = (values) => {
    if(values.submit_option === purchaseOrderRadioOptions.create_and_pay || values.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) {
      setPayEditFormValues(values)
      setShowInvoiceCheckoutModal()
      return
    }
    
    purchaseOrderMutation.mutate(values)
  }
  
  const handleViewPurchaseOrder = (purchaseOrder) => {
    if (purchaseOrder.hold_purchase_id) {
      const purchaseOrderValues = handleFormattingOfPurchaseOrderForUpdating({
        purchaseOrders: purchaseOrder,
      })
    
      globalReducer({type: globalReducerActions.SET_RESTOCK_PRODUCTS, products: purchaseOrderValues})
      navigate(`/dashboard/inventory/purchase_order/create`)
      return
    }
  
    navigate(`/dashboard/inventory/purchase_order/${purchaseOrder.id}`)
  }
  
  const handlePayForPurchaseOrder = (values) => {
    const formValues = {
      ...values,
      paid: true,
      supplied: false,
      paid_amount: getTotalItemCost(),
      business_id: getBusinessId(),
      ...payFormValues
    }
    
    if(values.pay_mode === "partial") {
      if(Number(values.total_partial) > getTotalItemCost()) {
        toast.error("You can not pay more than the purchase amount")
        return
      }
  
      formValues.payment_mode = "partial"
      formValues.paid_amount = Number(values.total_partial)
      purchaseOrderMutation.mutate(formValues)
      return
    }
  
    formValues.payment_mode = formValues.pay_mode
    purchaseOrderMutation.mutate(formValues)
  }
  
  const handleDeletePurchaseOrder = (purchaseOrder) => {
    if (purchaseOrder.hold_purchase_id) {
      toast.confirm(
        "Are you sure you want to delete this held purchase?",
        () => handleDeleteHeldPurchaseOrder(purchaseOrder.hold_purchase_id),
        "delete purchase order"
      )
      return
    }
  
    toast.confirm(
      "Are you sure?",
      () => deletePurchaseOrderMutation.mutate(purchaseOrder.id),
      "delete purchase order"
    )
  }
  
  const handleDeletePermission = (purchaseOrder) => {
    if (Number(purchaseOrder.balance) > 0) return actionPermissionEnum.NO_DISPLAY
    if (purchaseOrder.payment_status === "paid") return actionPermissionEnum.NO_DISPLAY
    if (purchaseOrder.status === "confirmed") return actionPermissionEnum.NO_DISPLAY
    if (purchaseOrder.status === "recall") return actionPermissionEnum.NO_DISPLAY
    return actionsPermissions.deletePurchaseOrder
  }
  
  return (
    <div>
      {showCreateModal &&
        <CreatePurchaseOrderModal onClose={setShowCreateModal} handleSubmit={handleCreatePurchaseOrder}
                                  mutation={purchaseOrderMutation}/>}
      {showInvoiceCheckoutModal &&
        <InvoiceCheckOutModal mutation={purchaseOrderMutation} balance={getTotalItemCost()} onlyOnePartial
                              orders={[payFormValues]} onClose={setShowInvoiceCheckoutModal}
                              customPaymentMethods={purchaseOrderPaymentMethods}
                              handlePaymentSubmission={handlePayForPurchaseOrder}/>}
    
    
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allPurchaseOrder.refetch}/>
      )}
    
      <Fragment>
        <PageHeaderWithButtonAndDateFilter
          allowCreateOffline
          permissionCode={actionsPermissions.createPurchaseOrder}
          setDateFilter={setDateFilter}
          title="Purchase"
          buttonText="Purchase item"
          Icon={AddCircle}
          buttonClick={() => navigate("/dashboard/inventory/purchase_order/create")}
          dateFilterDefaultValue={dateFilterValueEnum.TODAY}
        />
  
        {allPurchaseOrder.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No orders"
              imgSrc={EmptyBoxImage}
              heading="No orders yet"
            />
          </Fragment>
        )}
  
        {(allPurchaseOrder.showTable && !isOffline) && (
          <Fragment>
      
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Purchases"
                value={numberFormatter(purchaseOrderAnalytics?.total_purchase_order || 0)}
                subText={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_purchase_order_value || 0)}`}
              />
              <PageSummaryCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Paid Purchase Order"
                value={numberFormatter(purchaseOrderAnalytics?.total_paid || 0)}
                subText={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_paid_amount || 0)}`}
              />
              <PageSummaryCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Pending Purchase Order"
                value={numberFormatter(purchaseOrderAnalytics?.total_pending || 0)}
                // subText={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_balance_amount || 0)}`}
              />
              <PageSummaryCard
                isLoading={isPurchaseOrderAnalyticsLoading}
                title="Total Credit Purchase Order"
                value={numberFormatter(purchaseOrderAnalytics?.total_credit_orders || 0)}
                subText={`Value: ${currencyFormatter(purchaseOrderAnalytics?.total_balance_amount || 0)}`}
              />
            </PageSummaryStyled>
      
            <SetUpLayoutStyled>
  
              <OrderSearchAndFilter
                customFilters={pendingAndConfirmedOptions}
                state={debounceState}
                dispatch={debounceReducer}
                searchPlaceholder="Search by Id"
                isTodayMax={false}
                dateFilterOptions={expiryDateFilter}
              />
        
              <Table bg headers={purchaseOrderTable.headers} noCols={purchaseOrderTable.headers.length}>
                {allPurchaseOrder.isQueryWithData && (
                  allPurchaseOrder?.data?.results.map((item, k) =>
                    <TableItemWithDeleteAndEditAction
                      deletePermission={handleDeletePermission(item)}
                      editPermission={actionPermissionEnum.NO_DISPLAY}
                      deleteAction={handleDeletePurchaseOrder}
                      onClick={handleViewPurchaseOrder}
                      statusPosition={6}
                      key={k}
                      data={item}
                      count={k}
                      pageCount={allPurchaseOrder.currentCount}
                      keys={purchaseOrderTable.values}
                    />
                  )
                )}
          
                {allPurchaseOrder.isQueryWithNoData && (
                  <NoQueryData text="No sale record" />
                )}
          
                {allPurchaseOrder.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
        
              {allPurchaseOrder.showPagination && (
                <TablePagination
                  query={allPurchaseOrder}
                  restructureFn={purchaseOrderRestructure}
                  queryKey={getCurrentQueryKey(queryState)}
                />
              )}
            </SetUpLayoutStyled>
            {allPurchaseOrder.showPagination && (
              <TablePagination
                query={allPurchaseOrder}
                restructureFn={purchaseOrderRestructure}
                queryKey={getCurrentQueryKey(queryState)}
              />
            )}
    
          </Fragment>
        )}
  
        {getHeldPurchaseOrders().length > 0 && (
          <Fragment>
            <br/><br/>
            <Heading size={1.4} color="black">Held Purchase Orders</Heading> <br/>
            <Table bg headers={purchaseOrderOnHoldTable.headers} noCols={purchaseOrderOnHoldTable.headers.length}>
              {getHeldPurchaseOrders().map((item, k) => (
                <TableItemWithDeleteAndEditAction
                  editPermission={actionPermissionEnum.NO_DISPLAY}
                  deleteAction={handleDeletePurchaseOrder}
                  onClick={handleViewPurchaseOrder}
                  statusPosition={10}
                  key={item.purchase_order_id}
                  data={item}
                  count={k}
                  pageCount={1}
                  keys={purchaseOrderOnHoldTable.values}
                />
              ))}
            </Table>
          </Fragment>
        )}

      </Fragment>
    </div>
  )
}


export default PurchaseOrder
import {Heading, Paragraph} from "./textStyles";
import styled from "styled-components";
import {FlexStyled, ImageStyled, SpaceBetweenStyled} from "./utilStyles";
import {ButtonStyled} from "./formStyles";
import {ContainerStyled} from "./DashboardStyles";


const DrawerStyled = styled("div")`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  position: fixed;
  cursor: pointer;
  z-index: 999999999;
  background-color: ${props => props.theme.modalBackground}
`

export const NotificationModalStyled = styled("div")`
  top: 0;
  right: 0;
  bottom: 0;
	left: 0;
  cursor: auto;
  position: fixed;
	z-index: 999;
	background: transparent;
`

export const NotificationDrawerStyled = styled("div")`
  top: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
	z-index: 9999;
  position: fixed;
  background: rgb(239, 238, 238);
  padding: 1.5rem 1.5rem 16%;
  width: calc(27vw - 3rem);
  overflow-y: scroll;
	
	> div.heading {
		text-align: center;
    margin-top: 4.5rem;
	}

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 70vw;
  }
`

export const BusinessSubscriptionDrawerStyled = styled("section")`
  top: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
  position: fixed;
  background: white;
  padding: 1.5rem 1.5rem 16%;
  width: calc(30vw - 3rem);
  overflow-y: scroll;

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 75vw;
  }

  ${SpaceBetweenStyled}:first-child {
    align-items: center;
  }
	
	${SpaceBetweenStyled} {
		align-items: flex-start;
	}
	
	ol.exempted_products {
		gap: .6rem;
		display: flex;
    margin-top: .25rem;
		flex-direction: column;
    padding-left: 1.5rem;
		
		li {
			color: ${props => props.theme.black} !important;
		}
	}
`


const TablePageDrawerStyled = styled("section")`
	top: 0;
	right: 0;
	bottom: 0;
	cursor: auto;
  position: fixed;
	background: white;
	padding: 1.5rem 1.5rem 16%;
  width: calc(30vw - 3rem);
	overflow-y: scroll;
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		width: 70vw;
	}
	
	.header {
    margin-bottom: 2rem;
		
		${Heading} {
      border-radius: .8rem;
      padding: .35rem 1rem .25rem;
		}
		
		${Heading}.available {
			color: white;
			background: #007D42;
		}

    ${Heading}.occupied {
      background: ${props => props.theme.pending};
    }

    ${Heading}.clear {
      color: white;
      cursor: pointer;
      background: ${props => props.theme.darkGray};
    }
	}
	
	${FlexStyled}.no_order {
		position: relative;
		top: 30%;
    height: 55%;
		justify-content: center;
		
		> ${FlexStyled} {
      flex-direction: column;
      justify-content: center;

      ${ImageStyled} {
        margin-bottom: 0;
      }
			
			${ButtonStyled} {
        margin-top: 1rem;
			}
		}
	}
	
	${ButtonStyled}.add_more_orders {
    margin-top: 2rem;
	}
	
	u {
		cursor: pointer;
	}
	
	${ContainerStyled}.checkout {	
		> ${SpaceBetweenStyled} {
      margin-bottom: 2rem;
		}
		
		${SpaceBetweenStyled}.method_options {
			gap: 1rem .5rem;
      margin-bottom: 2rem;
		}
	}

  #submit_checkout {
    display: none;
  }
`

const TableOrderSummaryStyled = styled("div")`
	bottom: 0;
	padding: 1.5rem 1rem 0;
  position: fixed;
	right: 1rem;
	min-height: ${props => !props.$noMinHeight && "23vh"} ;
	max-height: calc(32vh - 2rem);
  width: calc(30vw - 3rem);
	background: white;
  border-top: 1px solid ${props => props.theme.shadow};

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(75vw - .5rem);
  }
	
	${SpaceBetweenStyled} {
    margin-bottom: 1.2rem;
	}
	
	${ButtonStyled} {
		margin-bottom: .5rem;
	}

  .submit_checkout {
    display: flex;
    gap: .7rem;
    justify-content: center;
    align-items: center;
    border: 1px;
    color: white;
    cursor: pointer;
    padding: .95rem 1.1rem;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: Bold, serif;
    width: calc(100% - 2.2rem);
		margin-bottom: .8rem;
    background-color: ${props => props.$loading ? props.theme.disabled : props.theme.primary};
    box-shadow: ${props => props.shadow ? "0 6.17828px 18.5348px 8px rgba(244, 168, 80, 0.3)" : "none"};
  }
	
	${ButtonStyled}:disabled {
		.submit_checkout {
			background: ${props => props.theme.disabled};
		}
	}
`

const TableDrawerOrderStyled = styled(FlexStyled)`
  margin-top: 2rem;
  padding-bottom: 1rem;
	align-items: flex-start;
	flex-direction: column;
	border-bottom: 1px dashed ${props => props.theme.darkGray};
	
	${Heading}, ${Paragraph} {
		gap: .6rem;
		display: flex;
		align-items: center;
		
		${ImageStyled} {
      margin-bottom: 0;
		}
	}
	
	${SpaceBetweenStyled} {
		width: 100%;
		
		svg {
      cursor: pointer;
		}
	}
`

export { DrawerStyled, TablePageDrawerStyled, TableDrawerOrderStyled, TableOrderSummaryStyled }
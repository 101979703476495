 import {
  NoDataLayout,
  PageHeader, PageSummaryCard, ScreenLoading,
  Table, TableItemForGroupOrders,
  TablePagination
} from "../../../../components";
import {NoDebtorImage} from "../../../../assets/images";
import {Fragment} from "react";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import { useAllOrderDebtsQuery } from "../../../../hooks/useServices/useDebtorServices";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import debtorQueryKey from "../../../../hooks/useServices/useDebtorServices/debtorQueryKey";
import {getDebtorsServiceStructure} from "../../../../services/debtorsServices/debtorServiceStructure";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import {useParams} from "react-router-dom";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";



const ViewCustomerOrderDebts = () => {
  const { customerId } = useParams()
  const { navigate } = useGlobalContext()
  const { customerOrderDebtsTable } = tableHeadersAndValueKeys
  const { debounceState } = useQueryDebounce()

  const { searchQuery, ...allGroupOrders } = useOrderQueryWithSearchAndFilter({ ...debounceState, customerId }, useAllOrderDebtsQuery)
  
  const handleViewSingleOrder = (groupId) => {
    navigate(`/dashboard/orders/${groupId}`)
  }
  
  return (
    <div>
      <PageHeader title={searchQuery.isSuccess ? `Debts By: ${allGroupOrders?.data?.results?.[0]?.customer_name}` : "Order Debts"} />
      
      {!!searchQuery.isError && (
        <ScreenLoading refetchFn={allGroupOrders.refetch} />
      )}
      
      <Fragment>
        
        {allGroupOrders.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No order debts"
              imgSrc={NoDebtorImage}
              heading="No order debts"
              subHeading="All customer that buy items on credit or installment will be here"
            />
          </Fragment>
        )}
        
        {allGroupOrders.showTable && (
          <Fragment>
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allGroupOrders.isQueryLoading}
                only
                title="Total Number Of Orders"
                value={allGroupOrders?.data?.count}
              />
            </PageSummaryStyled>
            
            <SetUpLayoutStyled>
              <br/>

              <Table bg headers={customerOrderDebtsTable.headers} noCols={customerOrderDebtsTable.headers.length}>
                {allGroupOrders.isQueryWithData && (
                  allGroupOrders?.data?.results.map((items, k) =>
                    <TableItemForGroupOrders
                      statusPosition={15}
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allGroupOrders.currentCount}
                      keys={customerOrderDebtsTable.values}
                      onView={handleViewSingleOrder}
                    />
                  )
                )}
  
                {allGroupOrders.isQueryWithNoData && (
                  <NoQueryData text="No sale record" />
                )}
  
                {allGroupOrders.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allGroupOrders.showPagination &&
                <TablePagination
                  query={allGroupOrders}
                  restructureFn={getDebtorsServiceStructure}
                  queryKey={debtorQueryKey.mainList(debounceState.searchValue)}
                />
              }
            </SetUpLayoutStyled>
            {allGroupOrders.showPagination &&
              <TablePagination
                query={allGroupOrders}
                restructureFn={getDebtorsServiceStructure}
                queryKey={debtorQueryKey.mainList(debounceState.searchValue)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default ViewCustomerOrderDebts;
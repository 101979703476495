import {Fragment, useEffect} from "react";
import {FlexStyled} from "../../../styles/utilStyles";
import {
	KitchenOrderCard,
	NoDataLayout,
	PageHeader,
	ScreenLoading
} from "../../../components";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {EmptyBoxImage} from "../../../assets/images";
import {KitchenLayoutStyled} from "../../../styles/kitchenStyles";
import {SellLayoutStyled} from "../../../styles/sellStyles";
import {Link} from "react-router-dom";
import IconWithToolTip from "../../../components/Utils/IconWithToolTip";
import {User} from "iconsax-react";
import {sellMenuNav} from "../../../data/dashboardData";
import {useTheme} from "styled-components";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {
	useKitchenGroupOrderByBusinessIdQuery
} from "../../../hooks/useServices/useOrderServices";
import { firebaseDatabase, ref, onValue } from "../../../utils/firebase/init";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useQueryClient} from "react-query";
import orderQueryKey from "../../../hooks/useServices/useOrderServices/orderQueryKey";


const KitchenView = () => {
	const theme = useTheme()
	const queryClient = useQueryClient()
	const { getBusinessId }  = useGlobalContext()
	const { data: userProfile } = useUserProfile()
	const { user_permission = [], user_type } = userProfile?.[0] || {}

	const { debounceState } = useQueryDebounce()
	const { mainQuery, ...allOrders } = useSearchQuery(debounceState, useKitchenGroupOrderByBusinessIdQuery)

	useEffect(() => {
		const kitchenCounter = ref(firebaseDatabase, 'businesses/' + getBusinessId() + '/counter');
		onValue(kitchenCounter, () => {
			queryClient.invalidateQueries({ queryKey: orderQueryKey.kitchenDisplay("") })
		});
	}, [])

	return (
		<SellLayoutStyled $mainWidth={5}>
			<nav className="sell_navigation">
				<FlexStyled>
					{userProfile?.[0]?.user_type !== "client_admin" && (
						<Link to={`/dashboard/staff/${userProfile?.[0]?.id}`}>
							<IconWithToolTip text="Dashboard">
								<User color={theme.black} size={20}/>
							</IconWithToolTip>
						</Link>
					)}

					{sellMenuNav.map(({icon: Icon, link, title, permission}, k) => {
						if(!user_permission.includes(permission) && user_type !== "client_admin") return

						return (
							<Link to={link} key={k}>
								<IconWithToolTip text={title}>
									<Icon color={theme.black} size={20}/>
								</IconWithToolTip>
							</Link>
						)
					})}
				</FlexStyled>
			</nav>

			<main>
				<KitchenLayoutStyled>

					{!mainQuery.isSuccess && (
						<ScreenLoading isError={mainQuery.isError} refetch={mainQuery.refetch} />
					)}

					{mainQuery.isSuccess && (
						<Fragment>
							<PageHeader title="Kitchen Schedule" />

							{allOrders.isEmpty && (
								<Fragment>
									<NoDataLayout
										imgWidth={45}
										imgAlt="No orders"
										imgSrc={EmptyBoxImage}
										heading="No orders yet"
									/>
								</Fragment>
							)}

							{!allOrders.isEmpty && (
								<FlexStyled>
									{allOrders.data?.results?.map(order => (
										<KitchenOrderCard key={order.group_id} orders={order} status="error" count={2} />
									))}
								</FlexStyled>
							)}
						</Fragment>
					)}
				</KitchenLayoutStyled>
			</main>
		</SellLayoutStyled>
	)
}

export default KitchenView



import {useMutation, useQuery, useQueryClient} from "react-query"
import {
  createProductionDistributionService,
  getProductionByIdService, getProductionDistributionService,
  getProductionService,
  startProductionService, updateProductionDistributionService, updateProductionLogService, updateProductionService
} from "../../../services/productionServices/productionServices"
import useGlobalContext from "../../useContexts/useGlobalContext"
import { getBusinessIdFromStorage } from "../../../utils"
import { getAllObjectKeysWithValues } from "../../../utils/handleQueryParams"
import errorHandler from "../../../utils/errorHandler"
import {useParams} from "react-router-dom";
import productQueryKey from "../useProductServices/productQueryKey";
import inventoryQueryKey from "../useInventoryServices/inventoryQueryKey";


const mainKey = ["Production", getBusinessIdFromStorage()]
export const productionQueryKeys = {
  all: [...mainKey],
  byId: (id) => [...mainKey, "view", id],
  distribution: (params = {}) => [...mainKey, "distribution log", getAllObjectKeysWithValues(params)],
  mainList: (params = {}) => [...mainKey, getAllObjectKeysWithValues(params)]
}


export const useProductionProcessQuery = (query = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: productionQueryKeys.mainList(query),
    queryFn: () => getProductionService({ ...query, business: getBusinessId() })
  })
}

export const useProductionProcessByIdQuery = () => {
  const { processId } = useParams()
  
  return useQuery({
    queryKey: productionQueryKeys.byId(processId),
    queryFn: () => getProductionByIdService(processId)
  })
}

export const useStartProductionMutation = ({ successFn }) => {
  const { getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: body => {
      return startProductionService({ ...body, business: getBusinessId() })
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Production Started!!", "start")
      await queryClient.refetchQueries({ queryKey: productionQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useUpdateProductionMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: (body) => {
      return updateProductionService(body.id, body)
    },
    
    onSuccess: async (_, variables) => {
      successFn()
      toast.success("Production Updated!!", "update")
      await queryClient.refetchQueries({ queryKey: productionQueryKeys.byId(variables.id) })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useUpdateProductionLogMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: (body) => {
      return updateProductionLogService(body.id, body)
    },
    
    onSuccess: async (_, variables) => {
      successFn()
      toast.success("Stage Updated!!", "update stage")
      await queryClient.refetchQueries({ queryKey: productionQueryKeys.byId(variables.productionId) })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useProductionDistributionLogQuery = (query = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: productionQueryKeys.distribution(query),
    queryFn: () => getProductionDistributionService({ ...query, business: getBusinessId() })
  })
}


export const useCreateProductionDistributionMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return createProductionDistributionService(body)
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Product Distributed", "distribute")
      queryClient.refetchQueries({ queryKey: productionQueryKeys.all })
      queryClient.refetchQueries({ queryKey: productQueryKey.all })
      queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useUpdateProductionDistributionMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: body => {
      return updateProductionDistributionService(body)
    },
    
    onSuccess: async (_, variables) => {
      successFn()
      toast.success(`Distribution ${variables.status}`, "distribution")
      queryClient.refetchQueries({ queryKey: productionQueryKeys.all })
      queryClient.refetchQueries({ queryKey: productQueryKey.all })
      queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
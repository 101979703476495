import {
  CreateProductionUnitMeasurementModal, EditProductionUnitMeasurementModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import {customPaymentMethodFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {CategoryImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {
  useProductionUnitMeasurementQuery,
  useCreateProductionUnitMeasurementMutation,
  useDeleteProductionUnitMeasurementMutation,
  useUpdateProductionUnitMeasurementMutation,
} from "../../../hooks/useServices/useProductionServices/useProductionUnitMeasurementServices";
import modalNames from "../../../hooks/useModalConnector/modalNames";


const ProductionUnitMeasurement = () => {
  const { toast } = useGlobalContext()
  const [showCreateProductionUnitMeasurementModal, setShowCreateProductionUnitMeasurementModal] = useModal(modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL)
  const [showEditProductionUnitMeasurementModal, setShowEditProductionUnitMeasurementModal] = useModal(modalNames.EDIT_PRODUCTION_UNIT_MEASUREMENT_MODAL)
  const {
    createProductionUnitMeasurement: createProductionUnitMeasurementPermission,
    updateProductionUnitMeasurement: updateProductionUnitMeasurementPermission,
    deleteProductionUnitMeasurement: deleteProductionUnitMeasurementPermission
  } = actionsPermissions
  
  const { productionUnitMeasurementTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allProductionUnitMeasurements } = useOrderQueryWithSearchAndFilter({ search: debounceState.searchValue }, useProductionUnitMeasurementQuery, true)
  const [editProductionUnitMeasurementFormValues, setEditProductionUnitMeasurementFormValues] = useState(customPaymentMethodFormDefaultValues)
  const deleteProductionUnitMeasurementMutation = useDeleteProductionUnitMeasurementMutation({ successFn: () => {} })
  const editProductionUnitMeasurementMutation = useUpdateProductionUnitMeasurementMutation({ successFn: setShowEditProductionUnitMeasurementModal })
  const createProductionUnitMeasurementMutation = useCreateProductionUnitMeasurementMutation({ successFn: setShowCreateProductionUnitMeasurementModal })
  
  
  const handleCreateProductionUnitMeasurement = (values) => {
    createProductionUnitMeasurementMutation.mutate(values)
  }
  
  const handleEditProductionUnitMeasurement = (values) => {
    editProductionUnitMeasurementMutation.mutate(values)
  }
  
  const handleEditAction = (customPaymentMethod) => {
    setEditProductionUnitMeasurementFormValues(customPaymentMethod)
    setShowEditProductionUnitMeasurementModal()
  }
  
  const handleDeleteAction = (customPaymentMethod) => {
    toast.confirm(
      "Are you sure?",
      deleteProductionUnitMeasurementMutation.mutate.bind(this, customPaymentMethod.id),
    )
  }
  
  return (
    <SetUpMainStyled>
      {showCreateProductionUnitMeasurementModal && <CreateProductionUnitMeasurementModal onClose={setShowCreateProductionUnitMeasurementModal} handleSubmit={handleCreateProductionUnitMeasurement} mutation={createProductionUnitMeasurementMutation} />}
      {showEditProductionUnitMeasurementModal && <EditProductionUnitMeasurementModal onClose={setShowEditProductionUnitMeasurementModal} handleSubmit={handleEditProductionUnitMeasurement} mutation={editProductionUnitMeasurementMutation} formValues={editProductionUnitMeasurementFormValues} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allProductionUnitMeasurements.isEmpty && (
          <Fragment>
            <PageHeader title="Unit Measurement" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No unit measurement"
              imgSrc={CategoryImage}
              heading="No unit measurement"
              buttonText="Add new measurement"
              buttonClick={setShowCreateProductionUnitMeasurementModal}
              permissionCode={createProductionUnitMeasurementPermission}
            />
          </Fragment>
        )}
        
        {allProductionUnitMeasurements.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Unit Measurement"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateProductionUnitMeasurementModal}
              permissionCode={createProductionUnitMeasurementPermission}
            />
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search unit measurement by name"
              />
              
              <Table headers={productionUnitMeasurementTable.headers} noCols={productionUnitMeasurementTable.headers.length}>
                {allProductionUnitMeasurements.isQueryWithData && (
                  allProductionUnitMeasurements.data.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allProductionUnitMeasurements.currentCount}
                      keys={productionUnitMeasurementTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateProductionUnitMeasurementPermission}
                      deletePermission={deleteProductionUnitMeasurementPermission}
                    />
                  )
                )}
                
                {allProductionUnitMeasurements.isQueryWithNoData && (
                  <NoQueryData text={allProductionUnitMeasurements.isSearchEmptyMessage("Custom payment method", "")} />
                )}
                
                {allProductionUnitMeasurements.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {!!allProductionUnitMeasurements?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allProductionUnitMeasurements?.data?.next && <TablePagination />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ProductionUnitMeasurement
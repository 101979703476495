import styled from "styled-components";
import {SingleOrderHeaderStyled} from "./singleOrderStyles";
import {FlexStyled, ImageStyled, SpaceBetweenStyled} from "./utilStyles";
import {PageSummaryCardStyled} from "./cardStyles";
import {Heading} from "./textStyles";

const SingleProductStyled = styled("div")`
  background-color: white;
  padding: 1.5rem;
  border-radius: .5rem;
  width: calc(100% - 4.5rem) !important;
  ${SingleOrderHeaderStyled} {
    margin-bottom: 1.5rem;
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(100% - 3rem) !important;
  }
`

const ProductInfoStyled = styled(FlexStyled)`
  gap: .4rem;
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    * {
      font-size: 1rem !important;
    }
  }
`

const SingleProductDetailsStyled = styled(SpaceBetweenStyled)`
  margin-top: 2rem;
  align-items: flex-start;
  
  ${ImageStyled} {
    margin: 0;
    height: 190px;
    max-height: 190px;
    max-width: 25.5% !important;
  }
  
  ${FlexStyled}.details {
    gap: .6rem 1.4rem;
    width: calc(100% - 25.5% - 1rem);
    
    ${PageSummaryCardStyled} {
      padding: 1rem .7rem;
      width: calc(26% + .7rem);
      
    }
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${FlexStyled}.details {
      ${PageSummaryCardStyled} {
        width: calc(50% - 1.4rem - 1.2rem) !important;
      }
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 1rem;
    flex-direction: column;
    
    ${ImageStyled} {
      height: 250px;
      max-height: 250px;
      max-width: 100% !important;
    }

    ${FlexStyled}.details {
      width: 100%;
    }
  }
`

const ChartAndRecentSalesStyled = styled(FlexStyled)`
  margin-top: 3rem;
  align-items: flex-start;
  
  .charts {
    padding: 1rem 0 1rem 0;
    border-radius: .5rem;
    width: calc(100% - 1rem - 2px);
    border: 1px solid ${props => props.theme.shadow};
    
    ${Heading} {
      margin: .5rem 1rem;
    }
  }

  .recent_sales {
    padding: 1rem;
    border-radius: .5rem;
    width: calc(33% - 2rem - 2px);
    border: 1px solid ${props => props.theme.shadow};
    
    > ${SpaceBetweenStyled} {
      margin-bottom: .8rem;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    flex-direction: column;

    .charts {
      width: 100%;
    }
    
    .recent_sales {
      width: calc(100% - 2rem);
    }
  }
`

export {
  SingleProductStyled,
  ProductInfoStyled,
  ChartAndRecentSalesStyled,
  SingleProductDetailsStyled
}
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Select } from "../../../index";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {useSubCategoriesByCategoryIdQuery} from "../../../../hooks/useServices/useSubCategoryServices";
import {useState} from "react";
import {useFormikContext} from "formik";


const ExportProductModal = ({ onClose, handleSubmit, mutation }) => {
  const [categoryId, setCategoryId] = useState("")
  
  const { data: allCategories } = useCategoriesQuery()
  const { data: allSubCategories, isLoading: isSubCategoryLoading } = useSubCategoriesByCategoryIdQuery(categoryId)
  
  const handleFormSubmit = (values) => {
    const query = { export_type: null }
    
    if(values.category && values.sub_category) {
      query.sub_category = values.sub_category
      query.export_type = "sub_category"
    }
  
    if(values.category && !values.sub_category) {
      query.category = values.category
      query.export_type = "category"
    }
    
    handleSubmit(query)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Export Products</Heading>

        <Form values={{ category: "", category_name: "", sub_category: "", sub_category_name: "" }} onSubmit={handleFormSubmit}>
          <Select
            clearFilter
            optional
            options={allCategories?.results || []}
            label="Category"
            placeholder="Export by product category"
            name="category"
            valueKey="id"
            displayKey="name"
            displayName="category_name"
            updateFn={(category) => setCategoryId(category.id)}
          />
  
          <Select
            isSearchLoading={isSubCategoryLoading}
            clearFilter
            optional
            options={allSubCategories?.results || []}
            label="Sub Category"
            placeholder="Export by product sub-category"
            name="sub_category"
            valueKey="id"
            displayKey="name"
            displayName="sub_category_name"
          />
          
          <SubmitButton mutation={mutation} />
        </Form>

      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default ExportProductModal

const SubmitButton = ({ mutation }) => {
  const { values } = useFormikContext()
  
  const getButtonText = () => {
    if(values.category && values.sub_category) return `Export All Products In ${values.sub_category_name}`
    if(values.category) return `Export All Products In ${values.category_name}`
    return "Export All Products"
  }
  
  return (
    <FlexStyled>
      <Button isLoading={mutation.isLoading} text={getButtonText()} type="submit" />
    </FlexStyled>
  )
}
import {
  NoDataLayout,
  PageHeader,
  PageSummaryCard, ScreenLoading, SearchAndFilter,
  Table,
  TablePagination, TableItemWithImageAndActions, DepleteFaultyProductModal
} from "../../../../components";
import {Fragment, useState} from "react";
import { NoLogsImage } from "../../../../assets/images";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {useDepleteFaultyProductService, useFaultyProductQuery} from "../../../../hooks/useServices/useProductServices";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import productQueryKey from "../../../../hooks/useServices/useProductServices/productQueryKey";
import {useModal} from "../../../../hooks";
import {useActionPermission} from "../../../../hooks/usePermissions";
import {actionsPermissions} from "../../../../data/permissions";


const FaultyProducts = () => {
  const { faultyProductTable } = tableHeadersAndValueKeys
  const isBadAndDamagePermission = useActionPermission(actionsPermissions.moveProductToBadAndDamage)
  
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const [faultyProductDetails, setFaultyProductDetails] = useState({})
  const [showDepleteModal, setShowDepleteModal] = useModal()
  
  const { data: allCategories } = useCategoriesQuery()
  
  const queryParams = { search: debounceState.searchValue, category_id: debounceState.filterValue }
  const { mainQuery, ...allFaultyProducts } = useOrderQueryWithSearchAndFilter(queryParams, useFaultyProductQuery)
  const depleteFaultyProductMutation = useDepleteFaultyProductService({ successFn: setShowDepleteModal })
  
  const handleViewFaultyProduct = (id) => {
    if(!isBadAndDamagePermission) return
    
    const fProduct = allFaultyProducts.data.results.find(prod => prod.id === id)
    
    setShowDepleteModal()
    setFaultyProductDetails(fProduct)
  }
  
  const handleDepleteProduct = (values) => {
    depleteFaultyProductMutation.mutate({ ...values, faulty_product_id: faultyProductDetails.id })
  }
  
  return (
    <div>
      {showDepleteModal && <DepleteFaultyProductModal onClose={setShowDepleteModal} mutation={depleteFaultyProductMutation} handleSubmit={handleDepleteProduct} formValues={faultyProductDetails} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allFaultyProducts.refetch} />
      )}
      
      <Fragment>
        {allFaultyProducts.isEmpty && (
          <Fragment>
            <PageHeader title="Bad and Damage" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No faulty product"
              imgSrc={NoLogsImage}
              heading="No faulty product"
              subHeading="You need to update your product stock to get a log"
            />
          </Fragment>
        )}

        {allFaultyProducts.showTable && (
          <Fragment>
            <PageHeader title="Bad and Damage" />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allFaultyProducts.isQueryLoading}
                only title="Total Number Of Faulty Products"
                value={allFaultyProducts?.data?.count} />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <SearchAndFilter
                valueKey="id"
                displayKey="name"
                dispatch={debounceReducer}
                filterOptions={allCategories?.results || []}
                filterPlaceholder="Filter by category"
                searchPlaceholder="Search by product name or SKU"
              />
              
              <Table bg headers={faultyProductTable.headers} noCols={faultyProductTable.headers.length}>
                {allFaultyProducts.isQueryWithData && (
                  allFaultyProducts?.data?.results.map((items, k) =>
                    <TableItemWithImageAndActions
                      onClick={handleViewFaultyProduct}
                      onlyView
                      count={k}
                      pageCount={allFaultyProducts.currentCount}
                      key={k}
                      data={items}
                      statusPosition={30}
                      keys={faultyProductTable.values}
                    />
                  )
                )}

                {allFaultyProducts.isQueryWithNoData && (
                  <NoQueryData text={allFaultyProducts.emptyMessage("faulty product")} />
                )}

                {allFaultyProducts.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allFaultyProducts.showPagination &&
                <TablePagination
                  query={allFaultyProducts}
                  queryKey={productQueryKey.faultyProducts(queryParams)}
                />
              }

            </SetUpLayoutStyled>
            
            {allFaultyProducts.showPagination &&
              <TablePagination
                query={allFaultyProducts}
                queryKey={productQueryKey.faultyProducts(queryParams)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default FaultyProducts;
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import stagesServiceRestructure from "./stagesServiceRestructure";


const getStageService = async ({ currencyFormatter, numberFormatter, ...query }) => {
	try {
		const url = handleQueryParams('/stages', query)
		const { data } = await axios.get(url)
		return stagesServiceRestructure(data, currencyFormatter, numberFormatter)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getStagesByServiceIdService = async (businessId, serviceId = " ", searchValue = " ", currencyFormatter, numberFormatter) => {
	try {
		const { data } = await axios.get(`/stages/?business_id=${businessId}&service_id=${serviceId}&search=${searchValue}`)
		return stagesServiceRestructure(data, currencyFormatter, numberFormatter)
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const createStageService = async (body) => {
	try {
		const { data } = await axios.post(`/stages/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const editStageService = async (addOnId, body) => {
	try {
		const { data } = await axios.patch(`/stages/${addOnId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const deleteStageService = async (addOnId) => {
	try {
		const { data } = await axios.delete(`/stages/${addOnId}/`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export { getStageService, createStageService, editStageService, deleteStageService, getStagesByServiceIdService }
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form} from "../../../index";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {actionsPermissions} from "../../../../data/permissions";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {WIPStatus} from "../../../../utils/table";
import {Link} from "react-router-dom";
import {useState} from "react";


const EditInventoryLogModal = ({ onClose, handleSubmit, mutation, formValues, noAction }) => {
  const [buttonClicked, setButtonClicked] = useState("")
  const { dateFormatter, numberFormatter } = useDataTypeFormatter()

  const handleSubmission = (status) => {
    setButtonClicked(status)
    handleSubmit({ ...formValues, status })
  }

  const getPreviousQuantity = () => {
    if(!formValues.is_in && formValues.sender_product_previous_qty) {
      return formValues.sender_product_previous_qty
    }
    
    return formValues.previous_qty
  }
  
  const getCurrentQuantity = () => {
    if(!formValues.is_in && formValues.sender_current_qty) {
      return formValues.sender_current_qty
    }
    
    return Number(formValues.previous_qty) + Number(formValues.quantity)
  }
  

  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Details</Heading>

        <Form values={formValues} onSubmit={handleSubmit} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Product Name: </Paragraph>
            <Paragraph color="black" size={1.2}>
              <Link to={`/dashboard/inventory/product/${formValues.product}`}>
                {formValues.product_name}
              </Link>
            </Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.quantity)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Previous Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(getPreviousQuantity())}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Current Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>
              {numberFormatter(getCurrentQuantity())}
            </Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Performed By: </Paragraph>
            <Link to={`/dashboard/staff/${formValues.performed_by}`}>
              <Paragraph color="black" size={1.2}>{formValues.performed_by_name}</Paragraph>
            </Link>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.date)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Remark: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.remark}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
  
          {!!formValues?.staff_action_name && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Action By: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.staff_action_name || ""}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled> </FlexStyled>
  
          {(formValues.allowed_to_perform_action && !noAction) && (
            <FlexStyled>
              <Button permissionCode={actionsPermissions.acceptInventory} text="Accept"
                      onClick={() => handleSubmission("confirmed")} type="button"
                      isLoading={buttonClicked === "confirmed" && mutation.isLoading} disabled={mutation.isLoading}/>
    
              <Button permissionCode={actionsPermissions.deleteInventory} text="Reject"
                      onClick={() => handleSubmission("rejected")} className="outline" type="button"
                      isLoading={buttonClicked === "rejected" && mutation.isLoading} disabled={mutation.isLoading}/>
            </FlexStyled>
          )}
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditInventoryLogModal
import {useBusinessByIdQuery} from "../../useServices/useStoreServices";
import {useUserProfile} from "../../useServices/userHooks";
import {errorLogger} from "../../../utils/logger";
import {getBusinessIdFromStorage} from "../../../utils";

const useErrorLogger = () => {
  const {data: business} = useBusinessByIdQuery()
  const {data: userProfile} = useUserProfile()
  
  return {
    /**
     * @param errorType{String} - pre-defined error type from utils/logger/index.js
     * @param error - Error message
     * @param payload{Any} - Payload sent to server or ipc process
     * @param queryKey{Array | String} - React query key
     * @param event{String} - Ipc event that caused the error (for desktop)
     * */
    create: ({errorType, error, payload, queryKey, event}) => {
      // only log error to firebase when code is deployed to production
      // the logger will also skip if you switch your local env to production for testing
      // the logger will also skip if you deploy to a testing environment
  
      if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === "production") {
        const data = {
          business_id: business?.id || getBusinessIdFromStorage(),
          staff_id: userProfile?.[0]?.id,
          business_name: business?.store_name,
          errorType, error, payload, queryKey, event,
          staff_name: `${userProfile?.[0]?.first_name} ${userProfile?.[0]?.last_name}`,
        }
        errorLogger(data).then().catch()
      }
    }
  }
}


export default useErrorLogger
import {Fragment, useEffect} from "react";
import {useModal} from "../../../hooks";
import {Paragraph} from "../../../styles/textStyles";
import {AddCircle} from "iconsax-react";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {
  useSellableProductByBusinessIdQuery,
  useSellableProductByCategoryIdQuery,
  useSellProductsOnEndViewMutation
} from "../../../hooks/useServices/useProductServices";
import {
  AddToCartModal,
  CategoryCard,
  NoDataLayout,
  ProductCard,
  ProductCard2,
  ScreenLoading,
  SellBar
} from "../../../components";
import {useCategoriesQuery} from "../../../hooks/useServices/useCategoryServices";
import {topNData} from "../../../utils";
import useQueryDebounce, {debounceActions} from "../../../hooks/useUtils/useQueryDebounce";
import useSellContext from "../../../hooks/useContexts/useSellContext";
import useSearchAndFilterQuery from "../../../hooks/useUtils/useSearchAndFilterQuery";
import {EmptyBoxImage} from "../../../assets/images";
import SelectFilterCategoryModal from "../../../components/Modal/SellModals/SelectFilterCategoryModal";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useInView} from 'react-intersection-observer';
import {RiLayoutColumnLine, RiLayoutGridLine} from "react-icons/ri"
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {sellReducerActions} from "../../../reducers/sellReducer";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";


const ProductBasedLayout = () => {
  const {navigate, isOffline} = useGlobalContext()
  const {toast} = useGlobalContext()
  const {data: allCategories, isLoading: isCategoriesLoading, isSuccess: isCategorySuccess} = useCategoriesQuery()
  
  const [isLayoutChange, setIsLayoutChange] = useModal()
  const [addToCartModal, setAddToCartModal] = useModal()
  const [showMoreCategory, setShowMoreCategory] = useModal()
  const {sellCategoryRef, reducer, selectedCustomer} = useSellContext()
  const {debounceState, debounceReducer} = useQueryDebounce()
  const businessSettings = useBusinessSettingQuery()
  const {
    mainQuery,
    ...allProducts
  } = useSearchAndFilterQuery(debounceState, useSellableProductByBusinessIdQuery, useSellableProductByCategoryIdQuery)
  
  const fourCategories = topNData(allCategories?.results, 4)
  
  const handleFilterProductsByCategory = (id) => {
    debounceReducer({
      filterValue: id,
      type: debounceActions.SET_FILTER_FIELD
    })
  }
  
  useEffect(() => {
    if (allProducts?.data?.results?.length === 1 && debounceState.searchValue === allProducts?.data?.results?.[0]?.sku) {
      const product = allProducts?.data?.results?.[0]
      if (!isOffline && !businessSettings?.sell_below_stock && Number(allProducts?.data?.results?.[0]?.stock_unit) < 1 && product.use_stock) {
        toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
        return
      }
  
      reducer({
        product: {
          ...product,
          unit: Number(product.unit_increment) || 1,
          ...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
            applied_discount: selectedCustomer?.customer_discount,
            previous_applied_discount: product?.applied_discount
          })
        },
        type: sellReducerActions.ADD_PRODUCT_TO_CART,
      })
      
      debounceReducer({
        type: debounceActions.CLEAR_ALL_FILTERS
      })
    }
  }, [allProducts.data?.results, debounceState.searchValue])
  
  const isMoreCategoryActive = () => {
    if(debounceState.filterValue === "") return false
    
    const isCategoryInDisplay = fourCategories.find(category => category.id === debounceState.filterValue)
    return !isCategoryInDisplay;
  }
  
  return (
    <div>
      {addToCartModal && <AddToCartModal onClose={setAddToCartModal} />}
      {showMoreCategory && <SelectFilterCategoryModal currentCategory={debounceState.filterValue} onClose={setShowMoreCategory} activateCategory={handleFilterProductsByCategory} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        <SellBar debounceReducer={debounceReducer} debounceState={debounceState} />
        <SectionStyled className="categories_section" ref={sellCategoryRef}>
  
          {isCategoriesLoading && (
            <div>
              <TableSkeleton noTop width="140px" count={1} height={60} />
              <TableSkeleton noTop width="140px" count={1} height={60} />
              <TableSkeleton noTop width="140px" count={1} height={60} />
              <TableSkeleton noTop width="140px" count={1} height={60} />
              <TableSkeleton noTop width="140px" count={1} height={60} />
            </div>
          )}
  
          {isCategorySuccess && (
            <div>
              <CategoryCard
                active={debounceState.filterValue === ""}
                colorKey={3}
                title="All"
                onClick={() => handleFilterProductsByCategory("")}
              />
    
              {
                fourCategories?.map((item, k) => (
                  <CategoryCard
                    active={debounceState.filterValue === item.id}
                    key={k}
                    colorKey={k}
                    noOfItems={2}
                    title={item?.name}
                    onClick={() => handleFilterProductsByCategory(item?.id)}
                  />
                ))
              }
    
              {allCategories?.results?.length === 5 && (
                <CategoryCard
                  colorKey={1}
                  noOfItems={2}
                  title={allCategories?.results[4].name}
                  active={debounceState.filterValue === allCategories?.results[4].id}
                  onClick={() => handleFilterProductsByCategory(allCategories?.results[4].id)}
                />
              )}
    
              {allCategories?.results?.length > 5 && (
                <CategoryCard title="More" onClick={setShowMoreCategory} active={isMoreCategoryActive()}>
                  <AddCircle size={16} />
                </CategoryCard>
              )}
            </div>
          )}
          
          <SpaceBetweenStyled className="layout_switch">
            <p></p>
            <FlexStyled>
              <RiLayoutGridLine
                size={28}
                onClick={setIsLayoutChange}
                className={!isLayoutChange ? "active" : ""}
              />
              <RiLayoutColumnLine
                size={28}
                onClick={setIsLayoutChange}
                className={isLayoutChange ? "active" : ""}
              />
            </FlexStyled>
          </SpaceBetweenStyled>
        </SectionStyled>
        
        <FlexStyled className="product_section">
          {allProducts.isQueryLoading && (
            <FlexStyled>
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
              <TableSkeleton count={1} height={200} width="190px" noTop />
            </FlexStyled>
          )}
          
          {allProducts.isQueryWithData && (
            allProducts?.data?.results.map(item => {
              return isLayoutChange ? (
                <ProductCard2
                  key={item.id}
                  product={item}
                  setAddToCartModal={setAddToCartModal}
                />
              ) : (
                <ProductCard
                  key={item.id}
                  product={item}
                  setAddToCartModal={setAddToCartModal}
                />
              )
            })
          )}
  
          <ProductPagination query={mainQuery} searchValue={debounceState.searchValue} />
          
          {allProducts.isQueryWithNoData && (
            <NoDataLayout
              imgWidth={12}
              imgAlt="No product"
              imgSrc={EmptyBoxImage}
              heading="No product yet"
              subHeading="You have no items yet, start updating your stock"
              buttonText="Add new product"
              buttonClick={() => navigate("/dashboard/inventory/product")}
              // permissionCode={createProductPermission}
            />
          )}
        </FlexStyled>
      </Fragment>
    </div>
  )
}

export default ProductBasedLayout


const ProductPagination = ({ query, searchValue }) => {
  const { ref, inView } = useInView()
  const sellProductOnEndViewMutation = useSellProductsOnEndViewMutation()
  
  useEffect(() => {
    if(inView) {
      sellProductOnEndViewMutation.mutate(query, searchValue)
    }
  }, [inView])
  
  return (
    <div ref={ref}>
      {sellProductOnEndViewMutation.isLoading && (
        <Paragraph color="black">Loading...</Paragraph>
      )}
    </div>
  )
}
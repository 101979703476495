import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, FileInput, Form} from "../../index";
import {categoryFormDefaultValues} from "../../../data/defaultFormValues";
import {FlexStyled} from "../../../styles/utilStyles";
import fileAcceptTypes from "../../../data/fileAcceptTypes";
import {useState} from "react";
import {handleImportDatabase} from "../../../routes/dashboard/settings/DesktopSetting";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";

const DesktopDatabaseImportModal = ({onClose}) => {
  const {toast} = useGlobalContext()
  const [uploadDBPath, setUploadDBPath] = useState("")
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Import Offline Database</Heading>
        <br/>
        
        <Form values={categoryFormDefaultValues}
              onSubmit={() => handleImportDatabase({toast, uploadDBPath, successFn: onClose})}>
          <FileInput onChange={(file) => setUploadDBPath(file.path)} isNotImage placeholder="Select database file"
                     name="database"
                     accept={fileAcceptTypes.desktopDatabase}/>
          
          <br/><br/>
          <FlexStyled>
            <Button text="Save" type="submit"/>
          </FlexStyled>
        </Form>
      
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default DesktopDatabaseImportModal
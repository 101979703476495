import {useMutation, useQuery, useQueryClient} from "react-query";
import {getNotifications, readNotificationService} from "../../../services/notificationServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getAuthTokenFromStorage} from "../../../utils";

export const useNotificationsQuery = () => {
	const {getBusinessId} = useGlobalContext()
	return useQuery({
		enabled: !!getAuthTokenFromStorage(),
		queryKey: "Notifications",
		queryFn: () => getNotifications({business_id: getBusinessId()})
	})
}


export const useReadNotificationMutation = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: data => {
			return readNotificationService(data)
		},

		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: "Notifications" })
		},

		onError: () => {

		}
	})
}
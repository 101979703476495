import {Fragment, useRef} from "react";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useCustomerByIdQuery} from "../../../hooks/useServices/useCustomerServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useReactToPrint} from "react-to-print";
import {CreateCategoryModalStyled, ModalLayoutStyled, ReceiptModalStyled} from "../../../styles/modalStyles";
import {AllCenterStyled, FlexColumnStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {formatPhoneNumber} from "../../../utils/textTransformer";
import moment from "moment/moment";
import Barcode from "react-barcode";
import {Button, Line} from "../../index";

export default function PaymentRecordReceipt({data, payment_record, allPaymentRecord = [], onClose}) {
  const receiptRef = useRef(null)
  const {isMobile} = useGlobalContext()
  const {data: customerData} = useCustomerByIdQuery(data?.[0]?.customer?.id)
  const {currencyFormatter} = useDataTypeFormatter()
  
  const balanceAtReceiptPayment = () => {
    let totalPaid = 0
    for (const record of allPaymentRecord) {
      totalPaid += record.amount
      
      if (record.id === payment_record.id) {
        break
      }
    }
    
    const remainingBalance = Number(data[0].group_order.group_total_cost) - totalPaid
    return {totalPaid, remainingBalance}
  }
  
  const {totalPaid, remainingBalance} = balanceAtReceiptPayment()
  
  const handlePrintReceipt = useReactToPrint({
    content: () => {
      const newReceiptNode = receiptRef.current.cloneNode(true)
      const actionEl = newReceiptNode.querySelector(".receipt_action")
      newReceiptNode.firstElementChild.lastElementChild.removeChild(actionEl)
      
      if (isMobile) {
        const closeActionEl = newReceiptNode.querySelector(".close_action")
        newReceiptNode.firstElementChild.lastElementChild.removeChild(closeActionEl)
      }
      
      return newReceiptNode
    }
  })
  
  const customer = data?.[0]?.selected_customer?.first_name ? data?.[0]?.selected_customer : customerData
  const business_details = typeof data?.[0]?.business === "string" ? (data?.[0]?.business_details || {}) : (data?.[0]?.business || {})
  
  
  return (
    <ModalLayoutStyled onClick={onClose} $noScroll>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()} ref={receiptRef} className="print_receipt">
        {isMobile && <><br/><br/><br/></>}
        
        <ReceiptModalStyled>
          <FlexColumnStyled className="header">
            <Heading color="black" bold>{business_details?.store_name}</Heading>
            {business_details?.tagline &&
              <Heading color="black" className="phone"><i>{business_details?.tagline}</i></Heading>}
            {business_details?.address &&
              <Heading color="black" className="phone">{business_details?.address}</Heading>}
            {business_details?.phone &&
              <Heading color="black" className="phone">
                TEL:
                {` ${formatPhoneNumber(business_details?.phone)}${!!formatPhoneNumber(business_details?.phone2) ? `, ${formatPhoneNumber(business_details?.phone2)}` : ''}`}
              </Heading>
            }
          </FlexColumnStyled>
          
          <SectionStyled $mb={0}>
  
            <FlexColumnStyled $gap={.1}>
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Payment Receipt</Paragraph>
                <Paragraph color="black" bold></Paragraph>
              </SpaceBetweenStyled>
    
              <SpaceBetweenStyled>
                <Paragraph bold color="black" className="section_title">Rep:</Paragraph>
                <Paragraph bold color="black">{payment_record.staff}</Paragraph>
              </SpaceBetweenStyled>
    
              {data?.[0]?.table_name && (
                <SpaceBetweenStyled>
                  <Paragraph bold color="black" className="section_title">Table Name:</Paragraph>
                  <Paragraph bold color="black">{data?.[0]?.table_name}</Paragraph>
                </SpaceBetweenStyled>
              )}
    
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Order ID:</Paragraph>
                <Paragraph color="black"
                           bold>{data?.[0]?.group_id}</Paragraph>
              </SpaceBetweenStyled>
    
              <Line/>
    
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Payment Method:</Paragraph>
                <Paragraph color="black"
                           bold>{payment_record.method}</Paragraph>
              </SpaceBetweenStyled>
    
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Amount Paid:</Paragraph>
                <Paragraph color="black"
                           bold>{currencyFormatter(payment_record.amount)}</Paragraph>
              </SpaceBetweenStyled>
    
              {payment_record.bank && (
                <SpaceBetweenStyled>
                  <Paragraph color="black" bold className="section_title">Bank:</Paragraph>
                  <Paragraph color="black"
                             bold>{payment_record.bank_and_account}</Paragraph>
                </SpaceBetweenStyled>
              )}
    
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Date Paid:</Paragraph>
                <Paragraph color="black"
                           bold>{moment(payment_record.date).format('D MMM YYYY [at] h:mm A')}</Paragraph>
              </SpaceBetweenStyled>
            </FlexColumnStyled>
  
            <Line/>
  
            <FlexColumnStyled $gap={.2} className="order_details">
              {!!customer?.first_name && (
                <Fragment>
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Customer Name:</Paragraph>
                    <Paragraph color="black" bold>{customer?.first_name} {customer?.last_name}</Paragraph>
                  </SpaceBetweenStyled>
        
                  <Fragment>
                    <SpaceBetweenStyled>
                      <Paragraph color="black" bold className="section_title">Customer Phone:</Paragraph>
                      <Paragraph color="black" bold>{customer?.phone}</Paragraph>
                    </SpaceBetweenStyled>
          
                    {/*<SpaceBetweenStyled id="address_div">*/}
                    {/*  <Paragraph color="black" bold className="section_title">Customer Address:</Paragraph>*/}
                    {/*  <Paragraph color="black" bold>{handleMainAddress(customer?.address) || "No address"}</Paragraph>*/}
                    {/*</SpaceBetweenStyled>*/}
          
                    {/*{handleMainAddress(customer?.address) && <Fragment><br/> <br/> <br/></Fragment>}*/}
                  </Fragment>
                </Fragment>
              )}
    
              <Line/>
    
              <SpaceBetweenStyled>
                <Paragraph bold color="black" className="section_title">Total Paid:</Paragraph>
                <Paragraph bold color="black">{currencyFormatter(totalPaid)}</Paragraph>
              </SpaceBetweenStyled>
    
              <SpaceBetweenStyled>
                <Paragraph bold color="black" className="section_title">Remaining Balance:</Paragraph>
                <Paragraph bold color="black">{currencyFormatter(remainingBalance)}</Paragraph>
              </SpaceBetweenStyled>
  
            </FlexColumnStyled>
            
            <AllCenterStyled className="thank_you_message">
              <Paragraph size={1.1} color="black" bold>
                {business_details?.receipt_message || "Powered by Ovaloop, your everyday business solution..."}
              </Paragraph>
            </AllCenterStyled>
            
            <FlexColumnStyled $gap={.5}>
              <span></span>
              <AllCenterStyled className="barcode_div">
                <Barcode height={50} format="CODE128" value={data?.[0]?.group_id}/>
              </AllCenterStyled>
            </FlexColumnStyled>
            
            <SpaceBetweenStyled className="receipt_action">
              <Button className="print_button" text="Print" onClick={handlePrintReceipt}/>
            </SpaceBetweenStyled>
            
            {isMobile && <Button text="Close" onClick={onClose} className="close_action"/>}
          </SectionStyled>
        </ReceiptModalStyled>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}
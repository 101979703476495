import styled from "styled-components";
import {AllCenterStyled, FlexColumnStyled, ImageStyled} from "./utilStyles";
import {Heading} from "./textStyles";
import {InputStyled} from "./formStyles";


export const PriceCheckerLayoutStyled = styled("div")`
  padding: ${props => props.$isProduct ? "1.5rem 3rem" : "3rem"};
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100vw - 6rem);
  height: calc(100vh - 6rem);
  
  ${Heading} {
    line-height: normal;
  }
  
  form {
    position: absolute;
    left: -100vw;
  }
  
  ${InputStyled} {
    position: absolute;
  }

  .img_div {
    width: 100%;
    display: flex;

    ${ImageStyled} {
      max-width: 17%;
    }
  }
  
  ${AllCenterStyled} {
    gap: ${props => props.$isProduct ? "2.5rem" : "6rem"};
    flex-direction: column;
    text-align: center;
  }

  @keyframes swing {
    //0% {
    //  transform: translateX(50px) translateY(100px);
    //}
    //25% {
    //  transform: translateX(-50px);
    //}
    //50% {
    //  transform: translateX(50px) translateY(150px);
    //}
    //
    //75% {
    //  transform: translateX(150px);
    //}
    //100% {
    //  transform: translateX(50px) translateY(150px);
    //}
    0% {
      transform: translateX(-150px);
    }
    50% {
      transform: translateX(150px);
    }
    100% {
      transform: translateX(-150px);
    }
  }

  .swinging-text {
    color: green;
    display: inline-block;
    animation: swing 5s infinite;
    transform-origin: center bottom;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`

export const PriceCheckerProductDetails = styled("div")`
  width: 60vw;
  min-width: 500px;

  .product_img_div {
    height: 140px;
    margin-bottom: ${props => props.$isProductImage ? "2rem" : "1rem"};
    max-width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${ImageStyled} {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 1rem;
    }
  }
  
  div.product_prices {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    ${FlexColumnStyled} {
      width: 60%;
      gap: 5rem;
      margin-top: 2rem;
      padding: 1.5rem 1rem;
      background-color: ${props => props.theme.background};
    }
  }
`
import {QuoteCreatorStyled} from "../../styles/cardStyles";
import {Paragraph, Text} from "../../styles/textStyles";

const QuoteCreator = () => {
  return (
    <QuoteCreatorStyled>
      <div>
        <Text>John Duru</Text>
        <Paragraph color="gray" size="1.3">Trader</Paragraph>
      </div>
    </QuoteCreatorStyled>
  )
}

export default QuoteCreator
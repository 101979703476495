import {useEffect, useState} from "react";
import {desktopOfflineQueryKeys} from "../useDesktopServicesFromLocalDB";
import {useQueryClient} from "react-query";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {isAllDesktopRequestSynced, setBusinessIsReadyForOffline} from "../../../utils/desktopUtils/desktopSyncUtils";
import {getBusinessIdFromStorage} from "../../../utils";
import useDesktopOnlineStatusContext from "../../useContexts/useDesktopOnlineStatusContext";
import {desktopOnlineStatusReducerActions} from "../../../reducers/DesktopOnlineStatusReducer";
import useGlobalContext from "../../useContexts/useGlobalContext";


const useIsOfflineDataReady = () => {
  const {toast} = useGlobalContext()
  const queryClient = useQueryClient()
  const {desktopOnlineReducer, desktopOnlineState} = useDesktopOnlineStatusContext()
  const [isStatus, setStatus] = useState(isAllDesktopRequestSynced())
  const handleAfterSyncAction = (_, data) => {
    setStatus(true)
    setBusinessIsReadyForOffline(true, getBusinessIdFromStorage())
    queryClient.setQueryData(desktopOfflineQueryKeys.isOfflineReady, data)
    queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all}).then().catch()
    desktopOnlineReducer({
      re_sync: false,
      type: desktopOnlineStatusReducerActions.RE_SYNC_ALL_DATA
    })
    
    toast.success("All offline data synced", "synced_all")
  }
  
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
      ipcRenderer.once(IPC_EVENTS.ALL_OFFLINE_DATA_HAS_SYNCED, handleAfterSyncAction)
      
      const handleStatus = () => setStatus(false)
      window.addEventListener("all_business_sync_cleared", handleStatus)
      
      const intervalID = setInterval(() => {
        setStatus(isAllDesktopRequestSynced())
      }, 2000)
      
      if (isStatus) {
        clearInterval(intervalID)
      }
      
      return () => {
        
        ipcRenderer.removeAllListeners(IPC_EVENTS.ALL_OFFLINE_DATA_HAS_SYNCED)
        window.removeEventListener("all_business_sync_cleared", handleStatus)
      }
    }
  }, [desktopOnlineState.re_sync])
  
  return isStatus
}

export default useIsOfflineDataReady
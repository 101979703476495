import styled from "styled-components";
import {ButtonStyled, InputStyled, SelectDropDownStyled, SelectStyled} from "./formStyles";
import {Heading, Paragraph} from "./textStyles";


const CenterContent = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const FlexStyled = styled("div")`
  gap: ${props =>`${props.$xGap || 1}rem ${props.$gap || 1}rem`};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const FlexColumnStyled = styled("div")`
  gap: ${props => props.$gap ? `${props.$gap}rem !important` : "1rem"};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const AllCenterStyled = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FlexCenterStyled = styled("div")`
  display: flex;
  //justify-content: center;
  
  svg {
    cursor: pointer;
  }
`

const SpaceBetweenStyled = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const LineStyled = styled("div")`
  margin-top: ${props => `${props.$mt || 0}rem`};
  margin-bottom: ${props => `${props.$mb || 1}rem`};
  padding: .02rem 0 !important;
  background-color: ${props => props.theme.shadow};
  margin-left: ${props => props.fullWidth ? 0 : "5%"};
  width: ${props => props.fullWidth ? "100%" : "90%"};
`

const IconStyled = styled("img")`
  width: ${props => props.width || "30px" };
  height: ${props => props.width || "20px"};
  background-color: ${props => !!props.bgDark ? props.theme.dark : "transparent"};
`

const ImageStyled = styled("img")`
  width: ${props => props.width && `${props.width}% !important` };
  max-width: 100%;
  margin-bottom: 1.5rem;
  
  border-radius: ${props => props.$shape === "round" && "50%"};
`

const PageHeaderWithButtonStyled = styled(AllCenterStyled)`
  margin-bottom: 2rem;
  justify-content: space-between;
  
  ${ButtonStyled} {
    width: fit-content;
    max-width: ${props => !!props.$buttonMaxWidth ? `${props.$buttonMaxWidth}%` : "25%"} !important;
    font-size: 1rem;
    padding: .9rem 1.5rem;
    border-radius: .3rem;
    font-family: Medium, serif;
    background-color: ${props => props.theme.primary};
  }
  
  .button_actions {
    width: 70%;
    justify-content: flex-end;
  }
  
  .mobile_view {
    display: none;
  }

  .with_date_filter {
    width: 70% !important;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    ${ButtonStyled} {
      width: 24% !important;
    }
    
    form {
      width: 100% !important;
      
      .date_filter_fields {
        justify-content: flex-end;
      }
    }
    
    .date_filter_fields ${InputStyled} {
      margin-bottom: .6rem !important;
      width: 20%;
    }
  }
  
  
    ${FlexStyled}.navigation_section {
    svg {
      padding: .4rem;
      cursor: pointer;
      border-radius: .3rem;
      border: 1px solid ${props => props.theme.menuGray};
    }

    ${FlexStyled} {
      gap: 0;
      align-items: flex-start;
      flex-direction: column;

      ${Heading} {
        font-size: 1.15rem !important;
        
        span {
          color: ${props => props.theme.menuGray};
        }
        
      }
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${ButtonStyled} {
      display: none;
    }
    
    .mobile_view {
      display: flex;
      gap: 1rem;
    }
  }
`

const DashboardPageHeaderStyled = styled(PageHeaderWithButtonStyled)`
  ${SpaceBetweenStyled} {
    width: 100%;
    align-items: center;
    
    form{
      width: 60%;
    }
    
    ${FlexStyled} {
      justify-content: flex-end;
    }
    
    ${InputStyled} {
      width: 28% !important;
      margin-bottom: 0;
    }
    
    ${SelectDropDownStyled} {
      max-height: fit-content;
      overflow-y: hidden;
    }
    
    svg {
      //margin-top: .3rem;
      cursor: pointer;
      
      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        position: relative;
        //margin-top: -2rem;
      }
    }

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      gap: 1rem;
      align-items: flex-start;
      flex-direction: column;
      
      form {
        width: 100%;
      }
      
      ${FlexStyled} {
        justify-content: flex-start;
      }

      ${InputStyled} {
        width: 42% !important;
      }
      
      ${SelectStyled} {
        width: 100% !important;
      }
    }
  }
`

const SearchAndFilterStyled = styled("div")`
  position: relative;
  background-color: white;
  bottom: -1.3rem;
  padding: 1.7rem 1.5rem;
  
  form {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  
  ${SpaceBetweenStyled}.form_space {
    width: 97%;
    
    form {
      width: 95%;
    }
  }
  
  .manual_sync_icon {
    cursor: pointer;
    border: none;
  }

  ${InputStyled} {
    width: 30%;
  }

  ${InputStyled}, ${InputStyled}.filter_field, ${InputStyled}.date_field, input {
    margin-top: -.5rem;
    border: none;
    border-radius: .2rem;
  }
  
  ${SelectStyled}, ${InputStyled}.date_field {
    width: 25% !important;
    max-width: 250px;
    i.select svg {
      top: 0.1rem !important;
    }
    
    i.select svg.clear_filter_icon {
      top: 0.3rem !important;
    }
  }
  
  ${InputStyled}.search_field input {
    width: calc(100% - 1rem - 2rem);
    border: none;
    border-radius: .2rem;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 100%;
    padding: .6rem !important;
    
    form {
      width: 100% !important;
      gap: .4rem;
      flex-direction: column;
    }
    
    ${InputStyled}, ${SelectStyled}#filter_field {
      margin-bottom: 1.5rem !important;
      display: initial !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${InputStyled}.search_field {
      width: 40%;
    }
  }
`

const SectionStyled = styled("section")`
  margin-top: 3rem;

  > ${Heading} {
    margin-bottom: 1rem;
  }
  
  > div:first-of-type {
    margin-bottom: ${prop => `${prop.$mb}rem` || "1rem"};
  }
  
  ${props => props.$center && `
    display: flex;
    margin: 6rem 0 2rem;
    justify-content: center;
  `}
  
  ${props => props.twoCol && `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    > div, > section {
      width: 45%;
    }
  `};

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 2rem;
    > div, > section {
      width: 100%;
    }
  }
`

const ChipStyled = styled("div")`
  cursor: pointer;
  width: fit-content;
  border-radius: .3rem;
  padding: .5rem 1rem .3rem;
  border: 1px solid ${props => props.theme.neutralGray};
  box-shadow: ${props => !props.active && `1px 1px 1px ${props.theme.shadow}`};
  background-color: ${props => props.active ? props.theme.transparentBlack : "white"};
  
  ${Paragraph} {
    color: ${props => props.active ? props.theme.black : props.theme.darkGray};
  }
  
  a {
    text-decoration: none;
  }

  ${props => props.$addon && `
    gap: .5rem;
    display: flex;
    align-items: center;
    padding: .3rem .7rem;
    background-color: ${props.theme.gray};
    
    ${Paragraph} {
      color: white;
    }
    
  `}
`

const PageSummaryStyled = styled("section")`
  display: flex;
  gap: 2%;
  margin-bottom: 1rem;

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    display: -webkit-box;
    overflow-x: scroll;
    padding-bottom: 1.2rem;
    
    > * {
      width: 80% !important;
    }
  }
`

export const SyncingLoaderStyled = styled("div")`
  z-index: 99999999999999999999999;
  min-width: 15vw;
  left: -5.5vw;
  bottom: -3rem;
  position: absolute;
  display: initial;

  p {
    text-align: center;
    width: 100%;
    background-color: #706c6c !important;
  }

  progress {
    width: 100%;
    padding: .7rem;
    color: red;
  }
`

const IconWithToolTipStyled = styled("div")`
  position: relative;
  
  ${Paragraph}, ${SyncingLoaderStyled} {
    display: none;
  }
  
  span {
    top: -.5rem;
    left: 1.9rem;
    font-size: 1rem;
    position: absolute;
    color: red;
    //padding: .1rem .3rem;
    //background-color: red;
    //border-radius: 50%;
  }
  
  :hover {
    ${SyncingLoaderStyled} {
      display: initial;
    }
    
    ${Paragraph} {
      left: -.6rem;
      color: white;
      bottom: -2.5rem;
      position: absolute;
      display: initial;
      background: black;
      border-radius: .2rem;
      padding: .4rem .8rem .4rem;
      font-family: Regular, serif;
      font-size: .9rem !important;
      z-index: 9999999999999999999999999;
    }
  }
`

const CropImageStyled = styled("div")`
  //height: 60vh;
  //position: relative;
  //z-index: 99999999999999999999;
  
  
  
  .reactEasyCrop_Image.reactEasyCrop_Contain {
    //transform: none !important;
  }
  
  .reactEasyCrop_Container {
    background: black;
    z-index: 9999999;
  }

  ${FlexStyled}.actions {
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 1rem;
    left: 0;
    right: 0;
    
    ${ButtonStyled} {
      position: relative;
      z-index: 9999999999999;
      width: 30% !important;
      margin-left: 1rem;
    }
  }
`

const ScreenLoadingStyled = styled("div")`
  height: 80vh;
  padding-top: 15vh;
  
  ${ImageStyled} {
    left: 20vw;
    position: relative;
  }
  
  ${FlexColumnStyled} {
    gap: 2rem;
    text-align: center;
    align-items: center;
  }
  
  ${ButtonStyled} {
    width: 30%;
  }
  
  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    padding-top: 20vh;
    
    ${ImageStyled} {
      width: 55% !important;
    }
  
    ${ButtonStyled} {
      width: 100%;
    }
  }
`

const TabStyled = styled(FlexStyled)`
  margin-bottom: 1.5rem;
  
  ${Heading} {
    cursor: pointer;
    padding-bottom: .25rem;
    border-bottom: 3px solid transparent;
  }

  ${Heading}:hover {
    border-bottom: 3px solid ${props => props.theme.backgroundGray};
  }
  
  ${Heading}.active {
    border-bottom: 3px solid ${props => props.theme.primary} !important;
  }
  
  ${SpaceBetweenStyled} {
    width: 100%;
  }
  
  ${FlexStyled}:nth-child(2) {
    width: 40%;
    justify-content: flex-end;
  }

  ${ButtonStyled} {
    font-size: .9rem;
    padding: .8rem;
    width: 45%;
  }
`


export const RowsWithDeleteIcon = styled(FlexStyled)`
  > div {
    width: ${props => `calc((100% / ${props.$noOfRows || 1}) - 2rem)`} !important;
  }
`


export {
  TabStyled,
  CropImageStyled,
  ChipStyled,
  CenterContent,
  FlexStyled,
  LineStyled,
  SectionStyled,
  AllCenterStyled,
  IconStyled,
  ImageStyled,
  ScreenLoadingStyled,
  FlexCenterStyled,
  FlexColumnStyled,
  PageSummaryStyled,
  SpaceBetweenStyled,
  SearchAndFilterStyled,
  IconWithToolTipStyled,
  DashboardPageHeaderStyled,
  PageHeaderWithButtonStyled
}
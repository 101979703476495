import {BackgroundPattern} from "../assets/images";

const theme = {
  white: "#ffffff",
  primary: "#3400FF",
  pending: "#FF9548",
  warning: "#ec7a25",
  lightWarning: "rgba(236,122,37,0.35)",
  gold: "#F4A850",
  secondary: "#737373",
  black: "#212121",
  button: "#3400FF",
  gray: "#9FA2B4",
  error: "#F24E30",
  lightError: "rgba(242,78,48,0.35)",
  success: "#17E383",
  modalInfo: "rgba(19,155,93,0.74)",
  lightSuccess: "rgba(229,255,241,0.49)",
  darkGray: "#7C7C7A",
  neutralGray: "rgba(184,180,203,0.39)",
  dark: "#383838",
  shadow: "#DEDEDE",
  menuGray: "#B8B4CB",
  disabled: "#BCBCBC",
  menuHover: "#F6F0FF",
  background: "#F5F5F5",
  backgroundSecondary: "#ffffff",
  textLightBlue: "rgba(47, 45, 81, 0.65)",
  pattern: BackgroundPattern,
  placeholder: "#4B506D",
  inputBGDark: "#F0F0FD",
  transparentBlack: "#00000014",
  inputBorder: "rgba(124,124,122,0.57)",
  modalBackground: "rgba(0, 0, 0, .6)",
  tableHover: "rgba(238, 238, 238, 0.62)",
  overViewCard: [
    "linear-gradient(90deg, #FC6076 0%, #FF9A44 100%)",
    "linear-gradient(90deg, #B224EF 0%, #7579FF 100%)",
    "linear-gradient(90deg, #0BA360 0%, #3CBA92 100%)",
    "linear-gradient(90deg, #868F96 0%, #596164 100%)"
  ],
  categoryCards:
    ["#F9D9C9", "#D1E3F4", "#F9D9E7", "#DDD6FC",
    "#f37736", "#D1E3F4", "#F9D9E7", "#DDD6FC",],

  // BREAKPOINTS
  mobileBreakPoint: "650px",
  desktopBreakPoint: "1000px",
  largeScreenByWidth: "1400px",
  largeScreenByHeight: "1100px",
  
  
  lightBlue: "#DBE4E7",
  lightGray: "#B8B4CB",
  deepBlack: "#181818",
  backgroundGray: "#EEEEEE",
  backgroundBlue: "#F8FAFD",
  backgroundPurple: "rgba(219, 228, 231, 0.35)",
  tabletBreakPoint: "999px",
  mediumDesktopBreakPointHeight: "900px",
  largeDesktopBreakPointHeight: "1170px",
  largeDesktopBreakPointWidth: "1820px"
}

export default theme
import {TableCardStyled} from "../../styles/cardStyles";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";


const TableCard = ({ tableData, openDrawer }) => {
	const {name, status, order_info} = tableData
	const { numberFormatter } = useDataTypeFormatter()

	return (
		<TableCardStyled $status={status} onClick={openDrawer.bind(this, tableData)}>
			<SpaceBetweenStyled>
				<Heading size={1.3} color="black">{name}</Heading>
				<Paragraph color="secondary" size={.9} className="status">
					{status === "occupied" ? "◉ Occupied" : "◉ Available"}
				</Paragraph>
			</SpaceBetweenStyled>

			<Paragraph color="secondary">
				{
					order_info.current_order_count > 0 ?
						`Order ${numberFormatter(order_info.current_order_count)} item(s)` :
						"No order"
				}
			</Paragraph>
		</TableCardStyled>
	)
}

export default TableCard
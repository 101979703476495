import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {Fragment, memo, useEffect, useRef, useState} from "react";
import {useProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import {Button, CheckBox, DatePicker, Form, Input, Select, TextArea} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {useSupplierByBusinessIdQuery} from "../../../../hooks/useServices/useSupplierServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {actionsPermissions} from "../../../../data/permissions";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {calculatePercentageDifferenceOfUnitMeasurementSellingPrice} from "../../../../utils/products";


const UpdatePurchaseOrderModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { getBusinessId } = useGlobalContext()
  const isPermitted = useActionPermissionFn()
  const { data: allSuppliers } = useSupplierByBusinessIdQuery()

  const onSubmit = (values) => {
    const formValues = { ...values }
    const products = []
  
  
    for (const key in formValues) {
      if(key.slice(0, 21) === "sales_attachment_name") {
      
        const uuid = key.split("_")[3]
      
        const product_id = formValues[`sales_attachment_name_${uuid}`]
        const product_name = formValues[`sales_attachment_display_${uuid}`]
        const requested_quantity = Number(formValues[`sales_attachment_quantity_${uuid}`])
        const cost_price = Number(formValues[`sales_attachment_cost_${uuid}`])
        const expiry_date = formValues[`sales_attachment_expiry_${uuid}`] || ""
        const use_sales_margin = formValues[`sales_attachment_use_sales_margin_${uuid}`]
        const batch_number = formValues[`sales_attachment_batch_number_${uuid}`]
        const percentageDiff = formValues[`sales_attachment_measurement_percentage_diff_${uuid}`]
  
        const quantity = Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1) * Number(requested_quantity)
  
        products.push({
          product_id,
          product_name,
          requested_quantity: quantity,
          cost_price: Math.ceil(cost_price / Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1)),
          expiry_date,
          batch_number,
          use_sales_margin,
          unit_price_difference: percentageDiff,
          unit_measurement: formValues[`sales_attachment_measurement_${uuid}`],
          base_quantity: formValues[`sales_attachment_measurement_base_${uuid}`],
          ...(formValues?.receive && {
            supplied_quantity: requested_quantity,
            expiry_date: formValues[`sales_attachment_expiry_${uuid}`] || "",
          })
        })
        
        delete formValues[`sales_attachment_name_${uuid}`]
        delete formValues[`sales_attachment_display_${uuid}`]
        delete formValues[`sales_attachment_quantity_${uuid}`]
        delete formValues[`sales_attachment_cost_${uuid}`]
        delete formValues[`sales_attachment_quantity_${uuid}_format`]
        delete formValues[`sales_attachment_cost_${uuid}_format`]
        delete formValues[`sales_attachment_use_sales_margin_${uuid}`]
        delete formValues[`sales_attachment_batch_number_${uuid}`]
        delete formValues[`sales_attachment_measurement_${uuid}`]
        delete formValues[`sales_attachment_measurement_base_${uuid}`]
        
        if(formValues?.receive) {
          delete formValues[`sales_attachment_expiry_${uuid}`]
        }
      }
    }
  
    handleSubmit({ ...formValues, business_id: getBusinessId(), ...(formValues?.receive ? { supplied_data: products } : { products } ) })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose} $zIndex={9999} $padding="0.5rem">
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={99}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">{formValues.receive ? "Receive" : "Update"} Purchase Order</Heading>
        
        <Form values={formValues} onSubmit={onSubmit}>
          <Fragment>
            <FlexStyled className="fullWidth">
              <Select
                optional
                disabled={!!formValues?.receive}
                options={allSuppliers?.results || []}
                displayKey="name"
                valueKey="id"
                label="Supplier"
                placeholder="Select supplier of product"
                name="supplier"
                displayName="supplier_name"
              />
  
              <Input disabled={!!formValues?.receive} optional type="number" label="Shipping Cost" placeholder="Enter shipping cost (if any)" name="shipping_cost" formatNumber />
              <Input disabled={!!formValues?.receive} optional label="Tax" type="number" placeholder="Enter shipping cost (if any)" name="tax" formatNumber />
              <Input optional label="Purchase ID" placeholder="Enter purchase ID provided by supplier" name="purchase_id" />
            </FlexStyled>
            
            <AttachProduct products_list={formValues.productsIds} formValues={formValues} />
  
            {(formValues?.receive && formValues?.payment_status !== "paid") && isPermitted.check(actionsPermissions.payForPurchaseOrder) && (
              <CheckBox name="is_paid" labelProp="Have you paid for this order" optional />
            )}
            
            <TextArea name="remark" optional label="Remark" className="fullWidth" placeholder="Enter a remark for this purchase" />
  
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text={formValues?.receive ? "Receive" : "Update"} type="submit" />
            </FlexStyled>
          </Fragment>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default UpdatePurchaseOrderModal


const AttachProduct = memo(({ products_list, formValues }) => {
  const [searchProduct, setSearchProduct] = useState("")
  const { data, isLoading: isProductLoading } = useProductByBusinessIdQuery(searchProduct)
  const { setFieldValue, values } = useFormikContext()
  const { currencyFormatter } = useDataTypeFormatter()
  const businessSettings = useBusinessSettingQuery()
  const allSelectedProductsWithIDRef = useRef([])

  useEffect(() => {
    allSelectedProductsWithIDRef.current = products_list?.map(product_uuid => {
      const productId = formValues[`sales_attachment_name_${product_uuid}`]
      const product = formValues?.products?.find(product => product?.product_id === productId)
  
      return {id: product_uuid, product }
    })
  }, [products_list])
  
  
  const handleForeCastSellingPrice = (cost, id) => {
    const product = allSelectedProductsWithIDRef.current.find(product => product.id === id)
    if(!businessSettings?.use_sales_margin || !product || !cost || !id) return
    
    const salesMarginPercentage = product?.product?.sales_margin || businessSettings?.sales_margin
    const perValue = Number(cost) * (Number(salesMarginPercentage) / 100)
    const forecastValue = Number(cost) + perValue
    
    if(Number(product?.product?.selling_price) === forecastValue) return
  
    setFieldValue(`sales_attachment_forecast_${id}`, `${product?.product?.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)\``)
  }
  
  const handleSelectProduct = (product, id = "main") => {
    const filterProducts = allSelectedProductsWithIDRef.current.filter(productObj => productObj?.id !== id)
    allSelectedProductsWithIDRef.current = [...filterProducts, {
      id: (id || "main"),
      product: { ...product, meta_measurement: [{ name: product.unit_measurement, base_quantity: 1, selling_price: product.selling_price }, ...(product?.meta_measurement || [])] }
    }]
  
    setFieldValue(`sales_attachment_measurement_product_previous_selling_price_${id}`, product.selling_price)
  
  
    // setFieldValue(`sales_attachment_cost_${id}`, product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price || "")
    // setFieldValue(`sales_attachment_cost_${id}_format`, numberFormatter(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) || "")
  }
  
  const handleSelectUnitMeasurement = (measurement, id = "main", values) => {
    const sellingPricePerUnit = values[`sales_attachment_measurement_product_previous_selling_price_${id}`]
    const percentageDiff = calculatePercentageDifferenceOfUnitMeasurementSellingPrice(measurement, sellingPricePerUnit)

    setFieldValue(`sales_attachment_measurement_percentage_diff_${id}`, percentageDiff)
    setFieldValue(`sales_attachment_measurement_name_${id}`, measurement.name)
    setFieldValue(`sales_attachment_measurement_base_${id}`, measurement.base_quantity)
  }
  
  const SalesAttachmentForm = (newId) => {
    const id = newId || uuid()
    
    const SelectProduct = ({ data, isProductLoading }) => (
      <Select
        disabled //={!!formValues?.receive}
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_name_${id}`}
        label="Product"
        displayName={`sales_attachment_display_${id}`}
        placeholder="Select product"
        options={data?.results}
        updateFn={(prod) => handleSelectProduct(prod, id)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
      />
    )
  
    const SelectUnitMeasurement = ({ allSelectedProductsWithIDRef, values }) => (
      <Select
        disabled //={!!formValues?.receive}
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_measurement_${id}`}
        label="Unit Measurement"
        displayName={`sales_attachment_measurement_display_${id}`}
        placeholder="Select Unit Measurement"
        options={allSelectedProductsWithIDRef.current?.find(pro => pro?.id === id)?.product?.meta_measurement || []}
        updateFn={(measurement) => handleSelectUnitMeasurement(measurement, id, values)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
        clearCondition={`sales_attachment_name_${id}`}
      />
    )
  
    const InputCostPerQuantity = ({ values }) => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_cost_${id}`}
        label={`Cost ${(!!values?.[`sales_attachment_measurement_display_${id}`]) ? `per 1 ${values[`sales_attachment_measurement_display_${id}`]}` : "per quantity"}`}
        placeholder="Enter cost"
        onChange={(cost) => handleForeCastSellingPrice(cost, id)}
      />
    )
  
    const InputQuantity = () => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_quantity_${id}`}
        label="Quantity"
        placeholder="Enter quantity"
        error={!!values[`sales_attachment_requested_quantity_${id}`] ? `Requested Quantity: ${values[`sales_attachment_requested_quantity_${id}`]}` : ""}
      />
    )
  
    const InputBatchNumber = () => (
      <Input
        optional
        label="Batch Number"
        placeholder="Enter batch number"
        name={`sales_attachment_batch_number_${id}`}
      />
    )
  
    const InputExpiryDate = () => (
      <DatePicker name={`sales_attachment_expiry_${id}`} label="Expiry Date" optional />
    )
  
    return { id, SelectProduct, InputExpiryDate, InputQuantity, InputBatchNumber, InputCostPerQuantity, SelectUnitMeasurement }
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  useEffect(() => {
    if(!!products_list && products_list.length > 0) {
      
      const attachmentStructure = products_list.map(id => {
        return SalesAttachmentForm(id)
      })
      
      setMoreProducts(attachmentStructure)
    }
  }, [products_list])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`sales_attachment_name_${id}`, "")
    setFieldValue(`sales_attachment_display_${id}`, "")
    setFieldValue(`sales_attachment_quantity_${id}`, "")
    setFieldValue(`sales_attachment_cost_${id}`, "")
    setFieldValue(`sales_attachment_expiry_${id}`, "")
    setFieldValue(`sales_attachment_batch_number_${id}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        {moreProducts?.map(({ id, SelectProduct, InputExpiryDate, InputQuantity, InputBatchNumber, InputCostPerQuantity, SelectUnitMeasurement }) => (
          <div>
            <FlexStyled className="fullWidth five_cols" key={id}>
              <SelectProduct data={data} isProductLoading={isProductLoading} />
              <SelectUnitMeasurement allSelectedProductsWithIDRef={allSelectedProductsWithIDRef} values={values} />
              <InputCostPerQuantity value={values} />
              <InputQuantity />
              {values.receive && (
                <InputQuantity />
              )}
              <InputBatchNumber />
              <InputExpiryDate />
              <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
            </FlexStyled>
      
            {!!values?.[`sales_attachment_forecast_${id}`] && (
              <Fragment>
                <CheckBox optional checked={true} name={`sales_attachment_use_sales_margin_${id}`} labelProp={values?.[`sales_attachment_forecast_${id}`]} />
              </Fragment>
            )}
          </div>
        ))}
  
        {!formValues.receive && (
          <Button onClick={addExtraProduct} type="button" text="Add more product" className="small" small />
        )}
        <br/>
      </Fragment>
    
    </div>
  )
})
import {TableOrderSummaryStyled} from "../../../styles/drawerStyles";
import {SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading} from "../../../styles/textStyles";
import {Button} from "../../index";
import useGroupOrderCalculation from "../../../hooks/useUtils/useGroupOrderCalculation";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";


const TableOrderSummary = (
	{ cart, onClick, buttonText, checkOut }
) => {
	const {
		totalCost,
		subTotal,
		totalDiscountValue,
		totalServiceValue,
		totalTaxValue
	} = useGroupOrderCalculation(cart)
	const {currencyFormatter} = useDataTypeFormatter()


	return (
		<TableOrderSummaryStyled>
			<SpaceBetweenStyled>
				<Heading color="secondary">Subtotal:</Heading>
				<Heading color="secondary">{subTotal}</Heading>
			</SpaceBetweenStyled>
			
			{!!totalServiceValue && (
				<SpaceBetweenStyled>
					<Heading color="secondary">Services:</Heading>
					<Heading color="secondary">{currencyFormatter(totalServiceValue)}</Heading>
				</SpaceBetweenStyled>
			)}
			
			{!!totalDiscountValue && (
				<SpaceBetweenStyled>
					<Heading color="secondary">Discount:</Heading>
					<Heading color="secondary">-{currencyFormatter(totalDiscountValue)}</Heading>
				</SpaceBetweenStyled>
			)}
			
			{!!totalTaxValue && (
				<SpaceBetweenStyled>
					<Heading color="secondary">Tax:</Heading>
					<Heading color="secondary">{currencyFormatter(totalTaxValue)}</Heading>
				</SpaceBetweenStyled>
			)}
			
			<SpaceBetweenStyled>
				<Heading color="black" bold size={1.1}>Total:</Heading>
				<Heading color="black" bold size={1.1}>{totalCost}</Heading>
			</SpaceBetweenStyled>

			{checkOut && (
				<label className="submit_checkout" htmlFor="submit_checkout">
					CheckOut
				</label>
			)}

			{!checkOut && <Button text={buttonText} onClick={onClick} />}
		</TableOrderSummaryStyled>
	)
}

export default TableOrderSummary
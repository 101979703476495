import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, Form, Input, Select} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {commissionAndDiscountTypeOptionsForStages} from "../../../../data/selectField/product";
import {useAddOnQuery} from "../../../../hooks/useServices/useAddOnServices";
import {useModal} from "../../../../hooks";
import {Fragment} from "react";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {useParams} from "react-router-dom";


const EditStagesModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { serviceName, serviceId } = useParams()
  const { toast } = useGlobalContext()
  const { data: allAddOns } = useAddOnQuery()
  const [isShowCommission, setIsShowCommission] = useModal(!!formValues?.commission_value)
  
  const allowedAddons = () => {
    return allAddOns?.results?.filter(addons => addons.id !== serviceId)
  }

  const handleBeforeSubmission = (values) => {
    if(!isShowCommission) {
      values.commission_value = 0
    }

    if((isShowCommission && !values.commission_value) || isShowCommission && !values.commission_type) {
      toast.error("Please input both commission type and value")
      return
    }

    handleSubmit(values)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>
        <Form values={formValues} onSubmit={handleBeforeSubmission} allFieldsNotRequired>
          <Select
            optional
            options={allowedAddons()}
            displayKey="name"
            valueKey="id"
            label="Add Ons"
            placeholder={serviceName}
            name="service"
            displayName="addon_type_name"
          />
          
          <Input label="Name" placeholder="Stage name" name="name" />

          <CheckBox checked={!!formValues?.commission_value} name="show_commission" afterCheck={setIsShowCommission} labelProp="Add commission to stage" />

          {isShowCommission && (
            <Fragment>
              <Select
                options={commissionAndDiscountTypeOptionsForStages}
                displayKey="name"
                valueKey="name"
                label="Commission Type"
                placeholder="Enter commission type"
                name="commission_type"
                displayName="commission_type_name"
              />

              <Input
                type="number"
                formatNumber
                label="Commission Value"
                placeholder="Enter value"
                name="commission_value"
              />
            </Fragment>
          )}
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditStagesModal
import {CheckBox, Form, Image, Line} from "../index";
import {Heading} from "../../styles/textStyles";
import {NoProductImage} from "../../assets/images";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {CheckBoxAndPackageIconStyled, TableItemStyled} from "../../styles/TableStyles";


const TableItemWithImageAndActions = ({ data, keys, handleCheck, checked }) => {
  const tableData = useTableValueTransformer(data, keys)
  
  const handleCheckFn = (isChecked) => {
    handleCheck(data, isChecked)
  }

  return (
    <TableItemStyled>
      <CheckBoxAndPackageIconStyled>
        <Form values={{ order_id: "" }}>
          <CheckBox checked={checked} noBottomMargin name="order_id" required afterCheck={handleCheckFn} />
        </Form>
      </CheckBoxAndPackageIconStyled>
      
      {tableData.map((item, k) =>
        k === 0 ?
          (<td key={k}> <Image className="product_image" src={item || NoProductImage} /> </td>) :
          (<td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>)
      )}
      <Line />
    </TableItemStyled>
  )
}

export default TableItemWithImageAndActions
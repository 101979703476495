export default (reportResponse, reportName) => {
	const link = document.createElement("a");
	link.target = "_blank";
	link.download = `${reportName}.xls`
	link.href = URL.createObjectURL(new Blob([reportResponse], { type: 'application/ms-excel' }));
	link.click();
}

export const receiptDownload = (reportResponse, reportName) => {
	const link = document.createElement("a");
	link.target = "_blank";
	link.download = `${reportName}.pdf`
	link.href = URL.createObjectURL(new Blob([reportResponse], { type: 'application/pdf' }));
	link.click();
}

export const downloadSvg = (svgEl, productName) => {
	const svgData = svgEl.outerHTML;
	const link = document.createElement("a");

	link.href = URL.createObjectURL(new Blob([svgData], {type: "image/svg+xml;charset=utf-8"}));
	link.download = `${productName}_barcode.svg`;
	link.click();
}
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, Form, Select, TextArea} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import {useState} from "react";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const DisburseOrderModal = ({ onClose, handleSubmit, mutation, orders }) => {
  const { toast } = useGlobalContext()
  const [selectedOrders, setSelectedOrders] = useState([])
  const { data: allStaffs } = useStaffByBusinessIdQuery()

  const handleSubmission = (values) => {
    if(selectedOrders.length < 1) {
      toast.error("Please select at least 1 product to disburse")
      return
    }
    
    const orderIds = selectedOrders.map(order => order.order_id)
    handleSubmit({ ...values, order_list: orderIds })
  }
  
  const productOrders = () => {
    return orders?.map(order => ({ ...order, product_name: order.product.name }))
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Disburse Product</Heading>

        <Form values={{ receiver_id: "", disburse_remark: "", order_list: "", display_staff_name: "" }} onSubmit={handleSubmission}>
          <Select
            valueKey="id"
            name="receiver_id"
            label="Disburse staff"
            className="fullWidth"
            displayKey="full_name"
            displayName="display_staff_name"
            options={allStaffs?.results}
            placeholder="Select staff to disburse to"
          />

          <MultiSelectInput
            displayName="product_name"
            label="Orders"
            setSelected={setSelectedOrders}
            placeholder="Select orders to disburse"
            options={productOrders()}
          />
          <TextArea name="disburse_remark" placeholder="" label="Disburse Remark" className="fullWidth" />

          <FlexStyled>
            <Button type="submit" text="Disburse" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default DisburseOrderModal
import {
  placeOrderOnHold,
  getAllOrdersOnHold,
  getOrderOnHold,
  replaceOrderOnHold,
  removeOrderOnHold
} from "../../../utils/holdOrders";
import useToast from "../useToast";


const useHoldOrders = () => {
  const toast = useToast()
  
  const holdOrder = (cart, id) => {
    if(id) {
      replaceOrderOnHold(cart, id)
      toast.success("Order placed on hold", "hold")
      return
    }
    
    placeOrderOnHold(cart)
    toast.success("Order placed on hold", "hold")
  }
  
  const getOrdersOnHold = () => getAllOrdersOnHold() || []
  
  const selectHolderOnHold = (orderId) => {
    return getOrderOnHold(orderId)
  }
  
  const removeOrderOnHoldById = (id) => {
    removeOrderOnHold(id)
  }
  
  
  return { holdOrder, getOrdersOnHold, selectHolderOnHold, removeOrderOnHoldById }
}


export default useHoldOrders
import axios from "../../axios";


const getDeliveryRateService = async (businessId, searchValue = " ") => {
  try {
    const { data } = await axios.get(`/delivery_rate/?business_id=${businessId}&search=${searchValue}`)
    return data
  } catch (e) {
    throw e
  }
}


const createDeliveryRateService = async (body) => {
  try {
    const { data } = await axios.post(`/delivery_rate/`, body)
    return data
  } catch (e) {
    throw e
  }
}

const editDeliveryRateService = async (rateId, body) => {
  try {
    const { data } = await axios.patch(`/delivery_rate/${rateId}`, body)
    return data
  } catch (e) {
    throw e
  }
}

const deleteDeliveryRateService = async (rateId) => {
  try {
    const { data } = await axios.delete(`/delivery_rate/${rateId}`)
    return data
  } catch (e) {
    throw e
  }
}

export { getDeliveryRateService, createDeliveryRateService, editDeliveryRateService, deleteDeliveryRateService }
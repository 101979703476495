import {
  NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination, CreateBusinessSubscriptionModal, EditBusinessSubscriptionModal
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateBusinessSubscriptionMutation,
  useDeleteBusinessSubscriptionMutation,
  useEditBusinessSubscriptionMutation,
  useBusinessSubscriptionQuery
} from "../../../hooks/useServices/useBusinessSubscriptionServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {discountFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {AddOnImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import modalNames from "../../../hooks/useModalConnector/modalNames";


const BusinessSubscriptionPlan = () => {
  const { toast } = useGlobalContext()
  const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] = useModal(modalNames.CREATE_SUBSCRIPTION)
  const [showEditSubscriptionModal, setShowEditSubscriptionModal] = useModal()
  const {
    createCustomerSubscription: createBusinessSubscriptionPermission,
    updateCustomerSubscription: updateBusinessSubscriptionPermission,
    deleteCustomerSubscription: deleteBusinessSubscriptionPermission
  } = actionsPermissions

  const { businessSubscriptionTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allSubscriptionPlans } = useSearchQuery(debounceState, useBusinessSubscriptionQuery, true)
  const [editSubscriptionFormValues, setEditSubscriptionFormValues] = useState(discountFormDefaultValues)
  const deleteBusinessSubscriptionMutation = useDeleteBusinessSubscriptionMutation({ successFn: () => {} })
  const editBusinessSubscriptionMutation = useEditBusinessSubscriptionMutation({ successFn: setShowEditSubscriptionModal })
  const createBusinessSubscriptionMutation = useCreateBusinessSubscriptionMutation({ successFn: setShowCreateSubscriptionModal })

  const handleCreateSubscription = (values) => {
    createBusinessSubscriptionMutation.mutate(values)
  }

  const handleEditSubscription = (values) => {
    editBusinessSubscriptionMutation.mutate(values)
  }

  const handleEditAction = (discount) => {
    setEditSubscriptionFormValues(discount)
    setShowEditSubscriptionModal()
  }

  const handleDeleteAction = (discount) => {
    toast.confirm(
      "Are you sure?",
      deleteBusinessSubscriptionMutation.mutate.bind(this, discount.id),
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateSubscriptionModal && <CreateBusinessSubscriptionModal onClose={setShowCreateSubscriptionModal} handleSubmit={handleCreateSubscription} mutation={createBusinessSubscriptionMutation} />}
      {showEditSubscriptionModal && <EditBusinessSubscriptionModal onClose={setShowEditSubscriptionModal} handleSubmit={handleEditSubscription} mutation={editBusinessSubscriptionMutation} formValues={editSubscriptionFormValues} />}
  
      {!mainQuery.isSuccess && (
        <ScreenLoading isError={mainQuery.isError} refetch={mainQuery.refetch} />
      )}
      
      {mainQuery.isSuccess && (
        <Fragment>
          {allSubscriptionPlans.isEmpty && (
            <Fragment>
              <PageHeader title="Subscription Plans" />
              <NoDataLayout
                imgWidth={32}
                imgAlt="No subscription plan"
                imgSrc={AddOnImage}
                heading="No subscription plan yet"
                subHeading=""
                buttonText="Add new subscription"
                buttonClick={setShowCreateSubscriptionModal}
                permissionCode={createBusinessSubscriptionPermission}
              />
            </Fragment>
          )}

          {!allSubscriptionPlans.isEmpty && (
            <Fragment>
              <PageHeaderWithButton
                title="Subscription Plans"
                buttonText="Create"
                Icon={AddCircle}
                buttonClick={setShowCreateSubscriptionModal}
                permissionCode={createBusinessSubscriptionPermission}
              />

              <SetUpLayoutStyled>
                <SearchAndFilter
                  noFilter
                  dispatch={debounceReducer}
                  searchPlaceholder="Search subscription by name"
                />

                <Table headers={businessSubscriptionTable.headers} noCols={businessSubscriptionTable.headers.length}>
                  {allSubscriptionPlans.isQueryWithData && (
                    allSubscriptionPlans.data.map((items, k) =>
                      <TableItemWithDeleteAndEditAction
                        key={k}
                        data={items}
                        count={k}
                        pageCount={allSubscriptionPlans.currentCount}
                        keys={businessSubscriptionTable.values}
                        editAction={handleEditAction}
                        deleteAction={handleDeleteAction}
                        editPermission={updateBusinessSubscriptionPermission}
                        deletePermission={deleteBusinessSubscriptionPermission}
                      />
                    )
                  )}

                  {allSubscriptionPlans.isQueryWithNoData && (
                    <NoQueryData text={allSubscriptionPlans.isSearchEmptyMessage("Subscription plan", "")} />
                  )}

                  {allSubscriptionPlans.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>

                {!!allSubscriptionPlans?.data?.next && <TablePagination />}
              </SetUpLayoutStyled>
              {!!allSubscriptionPlans?.data?.next && <TablePagination />}
            </Fragment>
          )}
        </Fragment>
      )}
    </SetUpMainStyled>
  )
}

export default BusinessSubscriptionPlan
import styled from "styled-components";


export const ButtonStyled = styled("button")`
  border: none;
  cursor: pointer;
  border-radius: .35rem;
  font-size: ${props => props.$large ? "1rem" : ".89rem"};
  padding: ${props => props.$large ? "1rem" : ".8rem 1rem"};
  font-family: ${props => props.$large ? "Bold, serif" : "Light, serif"};
  color: ${props => props.$color ? props.theme[props.$color] : props.theme.white };
  background: ${props => props.$color ? props.theme[props.$color] : props.theme.primary };

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
	  font-size: 2rem;
	  border-radius: 1rem;
	  padding: 1.6rem !important;
  }
	
	:hover {
    transition: all 1s ease;
    transform: scale(1.04);
	}
`

export const SpaceBetweenStyled = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${props => props.$gap ? `${props.$gap}rem` : "1rem" }
`

export const FlexRowStyled = styled("div")`
  display: flex;
  align-items: center;
  gap: ${props => props.$gap ? `${props.$gap}rem` : "1rem" }
`

export const FlexColumnStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${props => props.$gap ? `${props.$gap}rem` : "1rem" }
`

export const Heading2 = styled("h2")`
  font-family: Light, serif;
  font-size: 3rem;
  margin: ${props => props.$margin ? `${props.$margin}rem` : 0};
	color: ${props => props.$color ? props.theme[props.$color] : props.theme.deepBlack };
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		font-size: 2.2rem;
	}

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
	  font-size: 5rem;
  }
`

export const Heading3 = styled("h3")`
  font-family: ${props => props.$font ? `${props.$font}, serif` : "Light, serif"};
  font-size: 1.7rem;
  margin: ${props => props.$margin ? `${props.$margin}rem` : 0};
	color: ${props => props.$color ? props.theme[props.$color] : props.theme.black };

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
    font-size: 2.5rem;
  }
`

export const Heading4 = styled("h4")`
  font-family: Light, serif;
  font-size: 1.2rem;
  margin: ${props => props.$margin ? `${props.$margin}rem` : 0};
	color: ${props => props.$color ? props.theme[props.$color] : props.theme.black };
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		font-size: 1rem;
	}

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
    font-size: 1.6rem;
  }
`

export const Paragraph = styled("p")`
  font-family: ${props => props.$font ? `${props.$font}, serif` : "Normal, serif"};
  font-size: ${props => props.$size ? `${props.$size}rem` : ".95rem" };
  margin: ${props => props.$margin ? `${props.$margin}rem` : 0};
	color: ${props => props.$color ? props.theme[props.$color] : props.theme.black };

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
		font-size: 2rem;
	}
`


export function Button({ children, large, onClick }) {
	return (
		<ButtonStyled $large={large} onClick={onClick}>
			{children}
		</ButtonStyled>
	)
}


export const SubscriptionCardStyled = styled("div")`
	padding: 1rem;
	width: calc(100% - 2rem);
	border-radius: .3rem;
	background: ${props => props.theme.backgroundPurple};

  ${FlexColumnStyled}.subscription_summary {
		flex-direction: row;
		justify-content: space-between;
    margin-top: 2rem;

    .discount {
      padding: .2rem;
      border-radius: .5rem;
      background: rgba(0, 128, 0, 0.18);
    }
  }
	
	${ButtonStyled} {
		width: 100%;
		margin: 1.5rem 0;
	}
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		width: calc(100% - 2rem);
	}
	
	@media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
		width: calc(100% / 2 - 3rem);
	}

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
	  padding: 2rem 1rem 1rem;
		${FlexRowStyled}.header {
			img {
				width: 50px;
				height: 50px;
			}
		}

    ${FlexColumnStyled}.subscription_summary {
			${Heading4} {
			
			}
    }
    
    ${FlexColumnStyled}.features {
	    gap: 1.5rem;
	    
	    ${FlexRowStyled} {
		    gap: 1rem;
	    }
	    
	    svg {
		    font-size: 1.5rem !important;
	    }
    }

    ${ButtonStyled} {
      width: 100%;
	    font-size: 1.7rem;
	    padding: 1.5rem !important;
      margin: 2rem 0;
	    border-radius: .5rem;
    }
  }
`

export const ChoosePlansStyled = styled(FlexColumnStyled)`
  padding: 2rem;
  align-items: flex-start;
	//background: ${props => props.theme.backgroundBlue};
	
	${FlexRowStyled}.subscription_plans {
		width: 100%;
    margin-top: 1rem;
    align-items: flex-start;
  }
	
	${SubscriptionCardStyled}:first-of-type {
		${ButtonStyled} {
      background: transparent;
      color: ${props => props.theme.lightGray};
			border: ${props => `1px solid ${props.theme.lightGray}`};
		}
	}
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		padding: 1rem;
		
		> ${Heading3} {
			text-align: center;
		}
		
    ${FlexRowStyled}.subscription_plans {
	    flex-direction: column;
    }
	}
	
	@media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${FlexRowStyled}.subscription_plans {
	    gap: 2rem 0;
      flex-wrap: wrap;
      justify-content: space-between;
    }
	};

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
	  > ${FlexRowStyled} {
      margin: 2rem 0;
		  
		  > svg {
			  width: 60px;
			  height: 60px;
		  }
		  
      > ${Paragraph} {
        font-size: 2.7rem;
      }
		  
	  }
  }
`


import {createGlobalStyle} from "styled-components";
import {ButtonStyled, InputStyled} from "./formStyles";
import {Heading, Paragraph} from "./textStyles";
import {ImageStyled} from "./utilStyles";


const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    line-height: 1.3rem;
    -webkit-tap-highlight-color: transparent;
  }

  div.grecaptcha-badge {
    visibility: hidden !important;
  }

  .text_bold {
    font-family: Regular, serif;
  }

  .text_black {
    color: ${props => props.theme.black} !important;
      font-family: Regular, serif;
  }
  
  .bg_red {
      background: ${props => props.theme.error};
  }

  td.success, td.error, td.pending, td.gray {
    ${Heading} {
      display: flex;
      align-items: center;
      font-family: Bold, serif;
    }
  }
  
  td.success {
    ${Heading} {
      color: ${props => props.theme.success} !important;
    }
  }
  
  td.pending {
    ${Heading} {
      color: ${props => props.theme.pending} !important;
    }
  }

  td.error {
    ${Heading} {
      color: ${props => props.theme.error} !important;
    }
  }

  .id_and_status {
      gap: 1.5rem;
    
      ${ImageStyled} {
        margin-bottom: 0;
        //padding-right: .3rem;
      }
    
      ${Heading} {
          font-size: 1.15rem !important;
          span {
              color: ${props => props.theme.menuGray};
          }
      }
    
      ${Paragraph} {
        gap: .5rem;
        display: flex;
        margin-top: -.3rem;
        font-size: 1.05rem !important;
        font-family: Medium, serif;
        align-items: center;
      }
  }

  .no_svg_border svg {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .group_order_disburse_svg {
    position: relative;
    bottom: -.3rem;
    right: -.4rem
  }

  .group_order_offline_svg {
    position: relative;
    right: -.4rem
  }

  @media print {
    .print_receipt {
      width: 80mm;
      height: 100%;
    }

    .print_barcode {
      width: 30mm;
      height: 40mm;
    }
  }

  a {
    color: #212121;
  }

  label {
    color: #9FA2B4;
    font-size: .9rem;
    font-family: Medium, serif;
  }

  .underline {
    cursor: pointer;
    text-decoration: underline;
  }

  .recharts-text {
    fill: #999999 !important;
  }

  line {
    stroke: white !important;
  }

  .dashboard_toast {
    margin-top: 8.5vh;

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      margin-top: 2vh;
    }
  }

  .Toastify__toast {
    width: 94% !important;

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      width: 95% !important;
    }
  }

  .Toastify__toast-container {
    display: flex;
    justify-content: center;
    z-index: 99999999999999999999999999999999999 !important;
  }

  .multiselect-container {
    .searchWrapper {
      border: none !important;
      border-radius: 0 !important;
      min-height: auto;
      padding: 0;
      position: relative;

      input {
        width: 100% !important;
      }
    }

    .highlightOption {
      color: ${props => props.theme.black};
      background: white !important;
    }

    li.option {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      color: ${props => props.theme.black};

      input {
        width: fit-content;
      }
    }

    li.option:hover {
      background: ${props => props.theme.disabled} !important;
    }

    .chip {
      background: ${props => props.theme.primary} !important;
    }
  }

  .recharts-responsive-container, .recharts-wrapper, .recharts-surface {
    max-width: 100% !important;

    .recharts-text {
	    font-family: Light, serif;
	    fill: ${props => props.theme.black} !important;
    }
  }

  .recharts-cartesian-axis-line {
    stroke: #cccaca !important;
  }

  .recharts-tooltip-item {
    span {
      font-size: 1.1rem;
      font-family: Light, serif;
    }

    span.recharts-tooltip-item-value {
      font-size: 1.1rem;
      font-family: Medium, serif;
    }
  }

  html {
    font-size: .8rem;

    @media screen and (min-height: ${props => props.theme.largeScreenByHeight}) {
      font-size: 1rem;
    }

    @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
      font-size: 1rem;
    }

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      font-size: .9rem;
    }
  }

  :root {
    --toastify-z-index: 9999999999999999999 !important;
  }

  .div_with_date_filter {
    width: 60% !important;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${ButtonStyled} {
      width: 24% !important;
    }

    form {
      width: 100% !important;

      .date_filter_fields {
        justify-content: flex-end;
      }
    }

    .date_filter_fields ${InputStyled} {
      margin-bottom: .6rem !important;
      width: 28%;
    }
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading_circle {
    animation: spin 2.5s linear infinite;
  }

  .x-spreadsheet {
    > div {
      max-width: 100% !important;
    }

    > div.x-spreadsheet-toolbar {
      max-width: calc(100% - 60px) !important;
    }

    div[data-tooltip="Print (Ctrl+P)"] {
      display: none;
    }
  }

  .pointer {
    cursor: pointer !important;
  }
`

export default GlobalStyles
import axios from "../../../axios";
import handleQueryParams from "../../../utils/handleQueryParams";
import {kycServiceRestructure} from "./kycServiceRestructure";


export const getKYCDocumentsService = async (queryParams) => {
	try {
		const url = handleQueryParams("/kyc_requests", queryParams)
		const { data } = await axios.get(url)

		return kycServiceRestructure(data)
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export const updateKycDocumentService = async (body) => {
	try {
		const { data } = await axios.patch("/settlement/", body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
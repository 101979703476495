import axios from "../../../axios";
import handleQueryParams from "../../../utils/handleQueryParams";

export const getAllUserService = async (queryParam) => {
  try {
    const url = handleQueryParams("/users_list", queryParam)
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const downloadAllUserService = async (queryParams) => {
  try {
    const url = handleQueryParams("/users_list", { ...queryParams, download: true })
    const { data } = await axios.get(url, {
      responseType: "blob"
    })
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getBankService = async (query = {}) => {
  try {
    const url = handleQueryParams("/banks", query)
    const { data } = await axios.get(url)

    return data.map(bank => ({ ...bank, bankInfo: `${bank.name} (${bank.account_no})` }))
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createBankService = async (body) => {
  try {
    const { data } = await axios.post(`/banks/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editBankService = async (bankId, body) => {
  try {
    const { data } = await axios.patch(`/banks/${bankId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteBankService = async (bankId) => {
  try {
    const { data } = await axios.delete(`/banks/${bankId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getBankService, createBankService, editBankService, deleteBankService }
import {db} from "../firebase/init";
import {doc, setDoc} from 'firebase/firestore/lite';
import moment from "moment/moment";

export const errorTypes = {
  createSales: "create_sales_error",
  adjustProductSellingPrice: "adjust_product_selling_price",
  createAddon: "create_addon_error",
  updateAddon: "update_addon_error",
  createBank: "create_bank_error",
  updateBank: "update_bank_error",
  updateBusinessSettings: "update_business_settings_error",
  createBusinessCustomerSubscription: "create_business_customer_subscription_error",
  updateBusinessCustomerSubscription: "update_business_customer_subscription_error",
  createCategory: "create_category_error",
  updateCategory: "update_category_error",
  createCustomer: "create_customer_error",
  updateCustomer: "update_customer_error",
  updateCustomerWallet: "update_customer_wallet_error",
  createDiscount: "create_discount_error",
  updateDiscount: "update_discount_error",
  payForGroupOrder: "group_order_pay_error",
  partialPayForGroupOrder: "partial_pay_for_group_order",
  refundForGroupOrder: "refund_for_group_order",
  confirmGroupOrder: "confirm_group_order_error",
  cancelGroupOrder: "cancel_group_order_error",
  returnGroupOrder: "return_group_order_error",
  modifyGroupOrder: "modify_group_order_error",
  disburseOrder: "disburse_order_error",
  updateKitchenGroupOrder: "update_kitchen_group_order_error",
  payInvoiceOrder: "pay_invoice_order_error",
  updateInventoryOrProductDistribution: "update_inventory_error",
  updateFaultyProductLog: "update_faulty_product_log_error",
  moveFaultyProductInventory: "move_faulty_product_log_error",
  updateProductExpiryInventory: "update_product_expiry_inventory_error",
  createProduct: "create_product_error",
  editProduct: "edit_product_error",
  uploadBulkProduct: "upload_bulk_product_error",
  moveProduct: "move_product_error",
  manageProductStock: "manage_product_stock_error",
  adjustProductCostPriceStock: "adjust_product_cost_price_error",
  addFaultyProduct: "add_faulty_product_error",
  depleteFaultyProduct: "deplete_faulty_product_error",
  productDistribution: "product_distribution_error",
  bulkDeleteProduct: "bulk_delete_product_error",
  exportAllProduct: "export_all_product_error",
  publishProductToStoreFront: "publish_product_to_store_front_error",
  createPurchaseOrder: "create_purchase_order_error",
  updatePurchaseOrder: "update_purchase_order_error",
  viewReport: "view_report_error",
  createTax: "create_tax_error",
  updateTax: "update_tax_error",
  desktopOfflineOrderSync: "desktop_offline_order_sync_error",
  createDesktopOfflineOrder: "create_desktop_offline_order_error",
  partialPayForOrderOffline: "partial_pay_for_order_offline_error",
  ipcGetDataFromDesktopDatabase: "desktop_database_fetch_error"
}


export const errorLogger = async ({
                                    errorType,
                                    business_name,
                                    business_id,
                                    error,
                                    payload,
                                    staff_name,
                                    staff_id,
                                    queryKey,
                                    event
                                  }) => {
  try {
    // generate unique ID for each document log in firebase
    const randomNumber = Math.floor(Math.random() * 954).toString()
    const docID = `${new Date().toISOString().slice(0, 10)}_${business_id}_${Date.now()}${randomNumber}`
  
    await setDoc(doc(db, errorType, docID), {
      error: JSON.stringify(error || ""),
      payload: JSON.stringify(payload || ""),
      timestamp: moment().format('D MMM YYYY [at] h:mma'),
    
      ...(event && {event: event || ""}),
      ...(staff_id && {staff_id: staff_id || ""}),
      ...(staff_name && {staff_name: staff_name || ""}),
      ...(business_id && {business_id: business_id || ""}),
      ...(business_name && {business_name: business_name || ""}),
      ...(queryKey && {queryKey: JSON.stringify(queryKey || "")})
    })
  } catch (_) {
  
  }
}
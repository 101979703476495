import {
  useDesktopAddonService,
  useDesktopAllUsersProfileService,
  useDesktopBankService,
  useDesktopBusinessService,
  useDesktopCategoryService,
  useDesktopCustomerService,
  useDesktopDiscountService,
  useDesktopGroupOrdersService,
  useDesktopNestedSubCategoryService,
  useDesktopProductsService,
  useDesktopSingleOrdersService,
  useDesktopStagesService,
  useDesktopSubCategoryService,
  useDesktopSupplierService,
  useDesktopTableService,
  useDesktopTaxService
} from "./useDesktopServices";

import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {useEffect, useRef} from "react";
import {useUserProfile} from "../../useServices/userHooks";
import useDesktopOnlineStatusContext from "../../useContexts/useDesktopOnlineStatusContext";
import {desktopOnlineStatusReducerActions} from "../../../reducers/DesktopOnlineStatusReducer";
import {getBusinessIdFromStorage} from "../../../utils";
import {
  getSyncStatusForOffline,
  setSyncStatusForOffline,
  syncRequestKeyEnum
} from "../../../utils/desktopUtils/desktopSyncUtils";

const useFetchAllDesktopData = () => {
  const businessId = getBusinessIdFromStorage()
  const {isSuccess: isUserProfileSuccess, data: userProfile} = useUserProfile()
  const {desktopOnlineReducer} = useDesktopOnlineStatusContext()
  
  useProductOfflineSyncing()
  useCustomersOfflineSyncing()
  
  const allTaxesRef = useRef()
  const allDiscountsRef = useRef()
  const allSingleOrdersRef = useRef()
  const allNestedSubCategoriesRef = useRef()
  const allBanksRef = useRef()
  const allSubCategoriesRef = useRef()
  const allGroupOrdersRef = useRef()
  const allCategoriesRef = useRef()
  const allAddonsRef = useRef()
  const allStagesRef = useRef()
  const userRef = useRef()
  const businessRef = useRef()
  const supplierRef = useRef()
  const tablesRef = useRef()
  
  
  const {
    data: allTaxes,
    isSuccess: isTaxSuccess,
    isFetching: isTaxFetching,
    refetch: refetchTaxes
  } = useDesktopTaxService()
  const {
    data: allSuppliers,
    isSuccess: isSupplierSuccess,
    isFetching: isSupplierFetching,
    refetch: refetchSuppliers
  } = useDesktopSupplierService()
  const {
    data: allDiscounts,
    isSuccess: isDiscountSuccess,
    isFetching: isDiscountFetching,
    refetch: refetchDiscounts
  } = useDesktopDiscountService()
  
  const {
    data: allSingleOrders,
    isSuccess: isSingleOrderSuccess, isFetching: isSingleOrderFetching,
    refetch: refetchSingleOrders
  } = useDesktopSingleOrdersService()
  
  const {
    data: allGroupOrders,
    isSuccess: isGroupOrdersSuccess, isFetching: isGroupOrderFetching,
    refetch: refetchGroupOrders
  } = useDesktopGroupOrdersService()
  
  const {
    data: allNestedSubCategories,
    isSuccess: isNestedSubCategoriesSuccess, isFetching: isNestedSubCategoryFetching,
    refetch: refetchNestedSubCategories
  } = useDesktopNestedSubCategoryService()
  
  const {
    data: allTables,
    isSuccess: isTablesSuccess, isFetching: isTablesFetching,
    refetch: refetchTables
  } = useDesktopTableService()
  
  const {
    data: allBanks,
    isSuccess: isBanksSuccess, isFetching: isBanksFetching,
    refetch: refetchBanks,
  } = useDesktopBankService()
  
  const {
    data: allSubCategories,
    isSuccess: isSubCategoriesSuccess, isFetching: isSubCategoryFetching,
    refetch: refetchSubCategories
  } = useDesktopSubCategoryService()
  const {
    data: allCategories,
    isSuccess: isCategoriesSuccess,
    isFetching: isCategoryFetching,
    refetch: refetchCategories
  } = useDesktopCategoryService()
  const {
    data: allAddons,
    isSuccess: isAddonsSuccess,
    isFetching: isAddonFetching,
    refetch: refetchAddons
  } = useDesktopAddonService()
  const {
    data: allStages,
    isSuccess: isStagesSuccess,
    isFetching: isStagesFetching,
    refetch: refetchStages
  } = useDesktopStagesService()
  const {
    data: user,
    isSuccess: isUserSuccess,
    isFetching: isAllUsersFetching,
    refetch: refetchAllUsers
  } = useDesktopAllUsersProfileService()
  const {
    data: business,
    isSuccess: isBusinessSuccess,
    isFetching: isBusinessFetching,
    refetch: refetchBusinesses
  } = useDesktopBusinessService()
  
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
      
      const handleRefetchAllData = () => {
        refetchTaxes().then().catch()
        refetchDiscounts().then().catch()
        refetchSingleOrders().then().catch()
        refetchGroupOrders().then().catch()
        refetchNestedSubCategories().then().catch()
        refetchBanks().then().catch()
        refetchSubCategories().then().catch()
        refetchCategories().then().catch()
        refetchAddons().then().catch()
        refetchStages().then().catch()
        refetchAllUsers().then().catch()
        refetchBusinesses().then().catch()
        refetchSuppliers().then().catch()
        refetchTables().catch()
      }
      
      ipcRenderer.on(IPC_EVENTS.SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL, handleRefetchAllData)
      
      return () => {
        ipcRenderer.removeListener(IPC_EVENTS.SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL, handleRefetchAllData)
      }
    }
  }, [])
  
  useEffect(() => {
    allTaxesRef.current = allTaxes
    allDiscountsRef.current = allDiscounts
    allSingleOrdersRef.current = allSingleOrders
    allNestedSubCategoriesRef.current = allNestedSubCategories
    allBanksRef.current = allBanks
    allSubCategoriesRef.current = allSubCategories
    allGroupOrdersRef.current = allGroupOrders
    allCategoriesRef.current = allCategories
    allAddonsRef.current = allAddons
    allStagesRef.current = allStages
    userRef.current = user
    businessRef.current = business
    supplierRef.current = allSuppliers
    tablesRef.current = allTables
  }, [
    allTaxes, allDiscounts, allSingleOrders, allNestedSubCategories, allBanks, allSubCategories, allGroupOrders,
    allCategories, allAddons, allStages, user, business, allSuppliers, allTables
  ])
  
  useEffect(() => {
    if (!window?.require || !isUserProfileSuccess || !userProfile) return () => {
    }
  
    const {ipcRenderer} = window.require("electron")
  
    if (isGroupOrdersSuccess && !isGroupOrderFetching && !getSyncStatusForOffline(syncRequestKeyEnum.GROUP_ORDER)) {
      desktopOnlineReducer({
        type: desktopOnlineStatusReducerActions.UPDATE_FETCH_DATA_STATUS,
        dataType: "isGroupOrderCompleted",
      })
    
      ipcRenderer.send(IPC_EVENTS.UPLOAD_GROUP_ORDERS, {
        businessId,
        results: allGroupOrdersRef.current?.results
      })
      setSyncStatusForOffline(syncRequestKeyEnum.GROUP_ORDER)
    }
  
    if (isBusinessSuccess && !isBusinessFetching && !getSyncStatusForOffline(syncRequestKeyEnum.BUSINESS)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_BUSINESS, {
        businessId,
        results: businessRef.current
      })
    
      setSyncStatusForOffline(syncRequestKeyEnum.BUSINESS)
    }
  
    if (isSingleOrderSuccess && !isSingleOrderFetching && !getSyncStatusForOffline(syncRequestKeyEnum.SINGLE_ORDER)) {
      desktopOnlineReducer({
        type: desktopOnlineStatusReducerActions.UPDATE_FETCH_DATA_STATUS,
        dataType: "isSingleOrderCompleted",
      })
      ipcRenderer.send(IPC_EVENTS.UPLOAD_SINGLE_GROUP_ORDERS, {
        businessId,
        results: allSingleOrdersRef.current?.results?.map(order => ({...order, business_id: businessId}))
      })
      setSyncStatusForOffline(syncRequestKeyEnum.SINGLE_ORDER)
    }
  
    if (isUserSuccess && !isAllUsersFetching && !getSyncStatusForOffline(syncRequestKeyEnum.USER)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_USER, {
        businessId,
        results: userRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.USER)
    }
  
    if (isDiscountSuccess && !isDiscountFetching && !getSyncStatusForOffline(syncRequestKeyEnum.DISCOUNTS)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_DISCOUNTS, {
        businessId,
        results: allDiscountsRef.current?.results
      })
      setSyncStatusForOffline(syncRequestKeyEnum.DISCOUNTS)
    }
  
    if (isTaxSuccess && !isTaxFetching && !getSyncStatusForOffline(syncRequestKeyEnum.TAXES)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_TAXES, {
        businessId,
        results: allTaxesRef.current?.results
      })
      setSyncStatusForOffline(syncRequestKeyEnum.TAXES)
    }
  
    if (isTablesSuccess && !isTablesFetching && !getSyncStatusForOffline(syncRequestKeyEnum.TABLES)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_TABLES, {
        businessId,
        results: tablesRef.current?.results
      })
      setSyncStatusForOffline(syncRequestKeyEnum.TABLES)
    }
  
    // HELLO THIS IS ME RUNNING FOR SUPPLIERS
    if (isSupplierSuccess && !isSupplierFetching && !getSyncStatusForOffline(syncRequestKeyEnum.SUPPLIERS)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_SUPPLIERS, {
        businessId,
        results: supplierRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.SUPPLIERS)
    }
  
    if (isNestedSubCategoriesSuccess && !isNestedSubCategoryFetching && !getSyncStatusForOffline(syncRequestKeyEnum.NESTED_SUB_CATEGORY)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_NESTED_SUB_CATEGORY, {
        businessId,
        results: allNestedSubCategoriesRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.NESTED_SUB_CATEGORY)
    }
  
    if (isBanksSuccess && !isBanksFetching && !getSyncStatusForOffline(syncRequestKeyEnum.BANKS)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_BANKS, {
        businessId,
        results: allBanksRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.BANKS)
    }
  
    if (isSubCategoriesSuccess && !isSubCategoryFetching && !getSyncStatusForOffline(syncRequestKeyEnum.SUB_CATEGORIES)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_SUB_CATEGORY, {
        businessId,
        results: allSubCategoriesRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.SUB_CATEGORIES)
    }
  
    if (isCategoriesSuccess && !isCategoryFetching && !getSyncStatusForOffline(syncRequestKeyEnum.CATEGORIES)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_CATEGORY, {
        businessId,
        results: allCategoriesRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.CATEGORIES)
    }
  
    if (isAddonsSuccess && !isAddonFetching && !getSyncStatusForOffline(syncRequestKeyEnum.ADDONS)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_ADDONS, {
        businessId,
        results: allAddonsRef.current
      })
      setSyncStatusForOffline(syncRequestKeyEnum.ADDONS)
    }
  
    if (isStagesSuccess && !isStagesFetching && !getSyncStatusForOffline(syncRequestKeyEnum.STAGES)) {
      ipcRenderer.send(IPC_EVENTS.UPLOAD_ADDON_STAGES, {
        businessId,
        results: allStagesRef.current?.results
      })
      setSyncStatusForOffline(syncRequestKeyEnum.STAGES)
    }
  
  }, [
    window?.require, isTaxFetching, isDiscountFetching, isSingleOrderFetching, isNestedSubCategoryFetching, isBanksFetching, isSubCategoriesSuccess,
    isGroupOrderFetching, isCategoryFetching, isAddonFetching, isStagesFetching, isAllUsersFetching, isBusinessFetching, isSupplierFetching, isTablesFetching
  ])
}

export default useFetchAllDesktopData


export const useProductOfflineSyncing = () => {
  const businessId = getBusinessIdFromStorage()
  const isManuallyEnabled = useRef(false)
  const {desktopOnlineReducer, desktopOnlineState} = useDesktopOnlineStatusContext()
  const {isSuccess: isUserProfileSuccess, data: userProfile} = useUserProfile()
  
  const {
    data: allProducts,
    isSuccess: isProductsSuccess,
    isFetching: isProductFetching
  } = useDesktopProductsService({offset: desktopOnlineState.productFetchCount}, isManuallyEnabled.current)
  
  useEffect(() => {
    if (!window?.require || !isUserProfileSuccess || !userProfile) return () => {
    }
  
    const {ipcRenderer} = window.require("electron")
  
    if (isProductsSuccess && !isProductFetching && !getSyncStatusForOffline(syncRequestKeyEnum.PRODUCT)) {
      if (!!allProducts.next) {
        desktopOnlineReducer({
          type: desktopOnlineStatusReducerActions.SET_PRODUCT_TO_SYNC,
          allProducts
        })
      
        return () => {
        }
      }
    
      desktopOnlineReducer({
        type: desktopOnlineStatusReducerActions.SET_PRODUCT_TO_SYNC,
        allProducts,
        isCompleted: true
      })
    
      ipcRenderer.send(IPC_EVENTS.UPLOAD_PRODUCTS, {
        businessId,
        results: [...desktopOnlineState.productsToSync, ...allProducts.results]
      })
    
      isManuallyEnabled.current = false
      setSyncStatusForOffline(syncRequestKeyEnum.PRODUCT)
    }
  }, [isProductFetching, allProducts, isUserProfileSuccess])
  
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
  
      const handleRefetchProducts = () => isManuallyEnabled.current = true
      ipcRenderer.on(IPC_EVENTS.SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL, handleRefetchProducts)
  
      return () => {
        ipcRenderer.removeListener(IPC_EVENTS.SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL, handleRefetchProducts)
      }
    }
  }, [])
}

export const useCustomersOfflineSyncing = () => {
  const businessId = getBusinessIdFromStorage()
  const isManuallyEnabled = useRef(false)
  const {isSuccess: isUserProfileSuccess, data: userProfile} = useUserProfile()
  const {desktopOnlineReducer, desktopOnlineState: {customerSyncingData}} = useDesktopOnlineStatusContext()
  
  const {
    data: allCustomers,
    isSuccess: isCustomersSuccess,
    error: allCustomerError,
    isFetching: isCustomerFetching,
  } = useDesktopCustomerService({offset: customerSyncingData.customerFetchCount}, isManuallyEnabled.current)
  
  useEffect(() => {
    if (!window?.require || !isUserProfileSuccess || !userProfile) return () => {
    }
    
    const {ipcRenderer} = window.require("electron")
    if (isCustomersSuccess && !isCustomerFetching && !getSyncStatusForOffline(syncRequestKeyEnum.CUSTOMER)) {
      if (!!allCustomers.next) {
        desktopOnlineReducer({
          type: desktopOnlineStatusReducerActions.SET_CUSTOMER_TO_SYNC,
          allCustomers
        })
  
        return () => {
        }
      }
  
      desktopOnlineReducer({
        type: desktopOnlineStatusReducerActions.SET_CUSTOMER_TO_SYNC,
        allCustomers,
        isCustomerCompleted: true
      })
  
      ipcRenderer.send(IPC_EVENTS.UPLOAD_CUSTOMERS, {
        businessId,
        results: [...customerSyncingData.customersToSync, ...allCustomers.results]
      })
      
      isManuallyEnabled.current = false
      setSyncStatusForOffline(syncRequestKeyEnum.CUSTOMER)
    }
  }, [isCustomerFetching, isCustomersSuccess, isUserProfileSuccess, allCustomerError, isManuallyEnabled.current])
  
  useEffect(() => {
    if (window?.require) {
      const {ipcRenderer} = window.require("electron")
  
      const handleRefetchCustomers = () => isManuallyEnabled.current = true
      ipcRenderer.on(IPC_EVENTS.SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL, handleRefetchCustomers)
    }
  }, [])
}

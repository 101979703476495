import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {nestedSubCategoryRestructure} from "./nestedSubCategoryRestructure";


export const getNestedSubCategoriesService = async (queryParams = {}) => {
  if(!queryParams?.sub_category && !queryParams?.parent && !queryParams?.no_paginate) {
    return []
  }
  
  try {
    const url = handleQueryParams("/nested_category", queryParams)
    const { data } = await axios.get(url)
    
    return nestedSubCategoryRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const createNestedSubCategoryService = async (body) => {
  try {
    const { data } = await axios.post("/nested_category/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const editNestedSubCategoryService = async (categoryId, body) => {
  try {
    const { data } = await axios.patch(`/nested_category/${categoryId}`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const deleteNestedSubCategoryService = async (categoryId) => {
  try {
    const { data } = await axios.delete(`/nested_category/${categoryId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


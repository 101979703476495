import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
const businessId = getBusinessIdFromStorage()

const key = "expenses"
const withBusinessId = [key, businessId]

const expensesQueryKey = {
	all: [key],
	mainListKey: [...withBusinessId, "main"],
	category: [...withBusinessId, "category"],
	analytics: (params) => [...withBusinessId, "analytics", getAllObjectKeysWithValues(params)],
	mainList: (params) => [...withBusinessId, "main", getAllObjectKeysWithValues(params)]
}

export default expensesQueryKey
import {useEffect} from "react";

const addStylesToHtmlAndBody = () => {
  document.documentElement.style.overscrollBehaviorX = "none";
  document.body.style.overscrollBehaviorX = "none";
}

const useDisableBrowserScrollNavigation = () => {
  useEffect(() => {
    document.addEventListener("mouseenter", addStylesToHtmlAndBody)
    document.addEventListener("mouseleave", addStylesToHtmlAndBody)
    
    return () => {
      document.documentElement.style.overscrollBehaviorX = "initial";
      document.body.style.overscrollBehaviorX = "initial";
      
      document.removeEventListener("mouseenter", addStylesToHtmlAndBody)
      document.removeEventListener("mouseleave", addStylesToHtmlAndBody)
    }
  }, [])
}

export default useDisableBrowserScrollNavigation
import styled from "styled-components";
import {FlexColumnStyled, FlexStyled, ImageStyled, SpaceBetweenStyled} from "./utilStyles";
import {Heading, Paragraph} from "./textStyles";
import {ButtonStyled} from "./formStyles";

const TableItemOrderAndWIPStyled = styled("div")`
  ${SpaceBetweenStyled}.order_item {
    padding: 0 1rem;
    margin: 1.5rem 0;
    align-items: flex-start;
    
    > ${FlexStyled} {
      gap: .3rem;
      width: calc(100% / 4 - 1rem);
      
      ${Heading}.pending {
        padding: .2rem .3rem .03rem;
        border-radius: .3rem;
        background-color: #FF9548;
      }

      ${Heading}.error {
        padding: .35rem .5rem;
        border-radius: .3rem;
        background-color: ${props => props.theme.error};
      }
    }

    >${FlexStyled}:nth-child(4) {
      gap: .6rem;
      width: calc(100% / 4 - 1rem + 3%);
    }
    
    svg {
      cursor: pointer;
    }

    ${FlexStyled}.image_and_name {
      width: 28% !important;
      gap: .6rem !important;
      flex-wrap: nowrap !important;
      align-items: flex-start !important;

      ${ImageStyled} {
        margin: .2rem 0 0;
      }
    }

    ${FlexStyled}.order_id {
      width: 16% !important;
    }

    ${FlexStyled}.quantity {
      width: 14% !important;
    }

    ${FlexColumnStyled}.total_amount {
      margin-top: .3rem;
    }
  }
`

const WIPDropDownStyled = styled("div")`
  padding: 0 0 0 1.5rem;
  
  ${FlexStyled}.wip_heading {
    ${Heading}.wip_title {
      gap: .3rem;
      display: flex;
      margin-top: -1rem;
      align-items: center;
      
      ${Paragraph} {
        margin: 0;
      }
    }
  }
`

const WIPTableStyled = styled("table")`
  width: 100%;
  
  tr {
    ${ButtonStyled} {
      width: 80%;
      padding: .4rem;
      font-size: .8rem;
      border-radius: .2rem;
      background-color: ${props => props.theme.secondary};
    }
  }
  
  th, td {
    padding-bottom: .8rem;
    text-align: left;
    width: calc(100% / 6); // 20% each
  }
  
  th.time, td.time, td.name {
    width: 12%;
  }
  
  
  td.success, td.error, td.pending, td.gray {
    ${Heading} {
      gap: .4rem;
      display: flex;
      align-items: center;
      font-family: Bold, serif;
    }
    
    ${ImageStyled} {
      margin-top: -.15rem;
      margin-bottom: 0;
    }
  }

  td.success {
    ${Heading} {
      color: ${props => props.theme.success} !important;
    }
  }

  td.gray {
    ${Heading} {
      color: ${props => props.theme.secondary} !important;
    }
  }
  
  td.pending {
    ${Heading} {
      color: ${props => props.theme.pending} !important;
    }
  }

  td.error {
    ${Heading} {
      color: ${props => props.theme.error} !important;
    }
  }
`

export { TableItemOrderAndWIPStyled, WIPDropDownStyled, WIPTableStyled }
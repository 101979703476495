import axios from "../axios";
import {getDeviceRegistrationId} from "../config/firebase_messaging";

const loginServices = async (body) => {
  try {
    let _body = {...body}
    if (!window?.require) {
      try {
        const device_info = await getDeviceRegistrationId()
        if (device_info?.device_id) {
          _body = {...body, ...device_info}
        }
      } catch (e) {
        _body = body
      }
    }
  
    const {data} = await axios.post("/auth/login/", _body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const registerServices = async (body) => {
  try {
    const {data} = await axios.post("/users/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const getOTPForEmailVerificationService = async (body) => {
  try {
    const { data } = await axios.post("/auth/re_verify/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const emailVerificationService = async (body) => {
  try {
    const {data} = await axios.post("/auth/verify/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const forgotPasswordService = async (body) => {
  try {
    const { data } = await axios.post("/auth/forgot-password/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const validateForgotPasswordService = async (body) => {
  try {
    const { data } = await axios.patch("/auth/forgot_password_validate/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const changePasswordService = async (body) => {
  try {
    const { data } = await axios.patch("/auth/change_password/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export {
  loginServices, registerServices, emailVerificationService,
  forgotPasswordService, validateForgotPasswordService, changePasswordService
}
import {DetailsStyled} from "./settingsStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, CheckBox, Form, Input, Line} from "../../../components";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {useBusinessByIdQuery, useEditStoreMutation} from "../../../hooks/useServices/useStoreServices";
import {useDebounce} from "use-debounce";
import {Fragment, useState} from "react";
import {
  useCreateStoreFrontMutation,
  useVerifyStoreFrontNameQuery
} from "../../../hooks/useServices/useStoreFrontServices";
import {WIPStatus} from "../../../utils/table";
import {ChartCircle} from "iconsax-react";
import env from "../../../utils/env";
import CropImage from "../../../components/Modal/CropImage";


const StoreFrontSettings = () => {
  const [searchStoreName, setSearchName] = useState("")
  const [debounceStoreNameState] = useDebounce(searchStoreName, 600)
  
  const { data: business } = useBusinessByIdQuery()
  const { data: verifyStore, isLoading: isVerifyStoreLoading, isSuccess: isVerifyStoreSuccess } = useVerifyStoreFrontNameQuery(debounceStoreNameState)
  
  const handleSuccess = () => {
    setSearchName("")
  }
  
  const createStoreFrontMutation = useCreateStoreFrontMutation({ successFn: handleSuccess })
  const updateBusinessMutation = useEditStoreMutation({ successFn: handleSuccess })
  
  const handleCreateStoreFront = (values) => {
    if(!business.store_front_name) {
      const body = { store_front_name: values.store_name?.split(" ")?.join("_") }
      createStoreFrontMutation.mutate(body)
      return
    }
  
    delete values.image_path
    const body = { ...values, id: business.id }
    updateBusinessMutation.mutate(body)
  }
  
  return (
    <DetailsStyled $avatarWidth={40} $formWidth={40}>
      <SpaceBetweenStyled className="pageHeader">
        <div>
          <Heading size={1.3} color="black">Store Front</Heading>
          <Paragraph color="secondary">Set-up your business store for the general public and make sales</Paragraph>
        </div>
        {business?.store_front_name && (
          <Paragraph color="secondary">
            Visit Your Store: <a target="_blank" href={`${env.STORE_FRONT_URL}/${business?.store_front_name}`}>{`${env.STORE_FRONT_URL}/${business?.store_front_name}`}</a>
          </Paragraph>
        )}
      </SpaceBetweenStyled>

      <Line />

      <FlexStyled className="avatarAndForm">
        <section className="form_section">
          <Form values={{ email: "", store_name: "", image_path: business?.image_path }} onSubmit={handleCreateStoreFront} enableReinitialize>
            <SectionStyled>
              <Input disabled={business?.store_front_name} onChange={(value) => setSearchName(value)} required label="Store Name" placeholder={business?.store_front_name || "Enter store name"} name="store_name" />
              
              {!!searchStoreName && (
                <Fragment>
                  {(isVerifyStoreSuccess && !verifyStore?.exists) && (
                    <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                      <Paragraph color="black" size={1} className="id_and_status">{WIPStatus("store_available")}</Paragraph>
                    </FlexStyled>
                  )}
  
                  {(isVerifyStoreSuccess && !verifyStore?.exists) && (
                    <FlexStyled $gap={.3} className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                      <Paragraph color="black" size={1} className="id_and_status">Your store URL will be: </Paragraph>
                      <Paragraph color="black" bold size={1} className="id_and_status">{`${env.STORE_FRONT_URL}/${verifyStore?.store_front_name}`}</Paragraph>
                    </FlexStyled>
                  )}
  
                  {(isVerifyStoreSuccess && !!verifyStore?.exists) && (
                    <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                      <Paragraph color="black" size={1} className="id_and_status">{WIPStatus("store_already_exist")}</Paragraph>
                    </FlexStyled>
                  )}
  
                  {isVerifyStoreLoading && (
                    <FlexStyled $gap={.5}>
                      <ChartCircle className="loading_circle" size={18} color="black" />
                      <Paragraph color="black">Searching...</Paragraph>
                    </FlexStyled>
                  )}
                </Fragment>
              )} <br/>
  
              {!!business?.store_front_name && (
                <Fragment>
                  <CheckBox checked={business?.is_store_published} name="is_store_published" labelProp="Publish store to the public" optional />
                  <CheckBox checked={business?.pay_on_delivery} name="pay_on_delivery" labelProp="Allow pay on delivery" optional />
  
                  <CropImage width={500} height={300} name="image" className="fullWidth" optional placeholder='Upload Business Logo' />

                </Fragment>
              )}
              
              <Button
                isLoading={createStoreFrontMutation.isLoading || updateBusinessMutation.isLoading}
                disabled={isVerifyStoreLoading}
                type="submit"
                text={!!business?.store_front_name ? "Update Store Settings" : "Create Store"}
              />
            </SectionStyled>
          </Form>
        </section>
      </FlexStyled>
    </DetailsStyled>
  )
}

export default StoreFrontSettings
import axios from "../../axios";


const customServices = async (customEndpoint, restructureResult, params) => {
	try {
		const {data} = await axios.get(customEndpoint)
		
		if (restructureResult) {
			return restructureResult(data, params)
		}
		
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export default customServices
import {
  CreateExpenseModal, EditExpenseModal,
  NoDataLayout,
  PageHeader,
  PageSummaryCard, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithStatus, TablePagination
} from "../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../hooks";
import {AddCircle} from "iconsax-react";
import {NoExpensesImage} from "../../assets/images";
import {SetUpLayoutStyled} from "./setUp/SetUpStyles";
import {PageSummaryStyled} from "../../styles/utilStyles";
import {
  useCreateExpenseMutation,
  useExpenseQuery, useUpdateExpenseMutation
} from "../../hooks/useServices/useExpenseServices";
import tableHeadersAndValueKeys from "../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../components/Utils/NoQueryData";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import {expenseFormDefaultValues} from "../../data/defaultFormValues";
import {actionsPermissions} from "../../data/permissions";
import expensesQueryKey from "../../hooks/useServices/useExpenseServices/expensesQueryKey";
import {useExpensesAnalyticsService} from "../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import PageHeaderWithButtonAndDateFilter from "../../components/Utils/PageHeaderWithButtonAndDateFilter";
import useOrderQueryWithSearchAndFilter from "../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum} from "../../data/selectField/dateFilter";


const Expenses = () => {
  const [showEditExpenseModal, setShowEditExpenseModal] = useModal()
  const [showCreateExpenseModal, setShowCreateExpenseModal] = useModal()
  const [editFormValues, setEditFormValues] = useState(expenseFormDefaultValues)
  
  const { currencyFormatter } = useDataTypeFormatter()
  const { expenseTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const { fromDate, toDate } = handleDateFilterValue(dateFilterValue.THIS_YEAR)
  const [dateFilterObj, setDateFilterObj] = useState({ from_date: fromDate, to_date: toDate })
  const { data: expensesAnalytics, isLoading: isExpenseAnalyticsLoading, refetch: refetchExpenseAnalytics } = useExpensesAnalyticsService(dateFilterObj)
  
  const query = { search: debounceState.searchValue, from_date: dateFilterObj.from_date, to_date: dateFilterObj.to_date }
  const { mainQuery, ...allExpenses } = useOrderQueryWithSearchAndFilter(query, useExpenseQuery)
  
  
  const createExpenseMutation = useCreateExpenseMutation({ successFn: setShowCreateExpenseModal })
  const editExpenseMutation = useUpdateExpenseMutation({ successFn: setShowEditExpenseModal })


  const handleCreateExpense = (values) => {
    createExpenseMutation.mutate(values)
  }

  const handleEditExpense = (values) => {
    editExpenseMutation.mutate(values)
  }

  const handleAction = (data) => {
    setEditFormValues(data)
    setShowEditExpenseModal()
  }
  
  const refetchQueries = async () => {
    await refetchExpenseAnalytics()
    await allExpenses.refetch()
  }


  return (
    <div>
      {showCreateExpenseModal && <CreateExpenseModal onClose={setShowCreateExpenseModal} handleSubmit={handleCreateExpense} mutation={createExpenseMutation} />}
      {showEditExpenseModal && <EditExpenseModal formValues={editFormValues} onClose={setShowEditExpenseModal} handleSubmit={handleEditExpense} mutation={editExpenseMutation} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}
      
      <Fragment>
        {allExpenses.isEmpty && (
          <Fragment>
            <PageHeader title="Expenses/Bills" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoExpensesImage}
              heading="No expenses yet"
              subHeading="All expenses/ Miscellaneous in and out of the store should be added here."
              buttonText="Add new expenses"
              buttonClick={setShowCreateExpenseModal}
              permissionCode={actionsPermissions.createExpense}
            />
          </Fragment>
        )}

        {allExpenses.showTable && (
          <Fragment>
            <PageHeaderWithButtonAndDateFilter
              title="Expenses/Bills"
              Icon={AddCircle}
              buttonText="Post"
              buttonClick={setShowCreateExpenseModal}
              searchValue={debounceState.searchValue}
              setDateFilter={setDateFilterObj}
              dateFilterDefaultValue={dateFilterValueEnum.THIS_YEAR}
            />

            <PageSummaryStyled>
              <PageSummaryCard isLoading={isExpenseAnalyticsLoading} title="Total Value Of Expenses" subText={`${expensesAnalytics?.approved_analytics?.total_expenses} Expenses`} value={currencyFormatter(expensesAnalytics?.approved_analytics?.total_expenses_value || 0)} />
              <PageSummaryCard isLoading={isExpenseAnalyticsLoading} title="Value Of Approved Expenses" subText={`${expensesAnalytics?.approved_analytics?.total_expenses} Expenses`} value={currencyFormatter(expensesAnalytics?.approved_analytics?.total_expenses_value || 0)} />
              <PageSummaryCard isLoading={isExpenseAnalyticsLoading} title="Value Of Pending Expenses" subText={`${expensesAnalytics?.pending_analytics?.total_expenses} Expenses`} value={currencyFormatter(expensesAnalytics?.pending_analytics?.total_expenses_value || 0)} />
              <PageSummaryCard isLoading={isExpenseAnalyticsLoading} title="Value Of Rejected Expenses" subText={`${expensesAnalytics?.rejected_analytics?.total_expenses} Expenses`} value={currencyFormatter(expensesAnalytics?.rejected_analytics?.total_expenses_value || 0)} />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search by category, remark or status"
              />

              <Table bg headers={expenseTable.headers} noCols={expenseTable.headers.length} >
                {allExpenses.isQueryWithData && (
                  allExpenses?.data?.results.map((items, k) =>
                    <TableItemWithStatus
                      count={k}
                      pageCount={allExpenses.currentCount}
                      onView={handleAction}
                      key={k}
                      data={{ ...items, custom_category_name: items?.custom_category_name || items?.category_name }}
                      statusPosition={3}
                      keys={expenseTable.values}
                    />
                  )
                )}

                {allExpenses.isQueryWithNoData && (
                  <NoQueryData text={allExpenses.emptyMessage("Expense with that filter")} />
                )}

                {allExpenses.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              {!!allExpenses.showPagination &&
                <TablePagination
                  query={allExpenses}
                  queryKey={expensesQueryKey.mainList(debounceState.searchValue)}
                />
              }
            </SetUpLayoutStyled>

            {!!allExpenses?.showPagination &&
              <TablePagination
                query={allExpenses}
                queryKey={expensesQueryKey.mainList(debounceState.searchValue)}
              />
            }
          </Fragment>
        )}

      </Fragment>
    </div>
  )
}

export default Expenses;
import {
  createPurchaseOrderService,
  deletePurchaseOrderService,
  getPurchaseOrderByIdService,
  getPurchaseOrderService,
  updatePurchaseOrderService
} from "../../../services/purchaseOrderService";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getBusinessIdFromStorage} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {useParams} from "react-router-dom";
import orderQueryKey from "../useOrderServices/orderQueryKey";
import inventoryQueryKey from "../useInventoryServices/inventoryQueryKey";
import productQueryKey from "../useProductServices/productQueryKey";
import queryKeys from "../queryKeys";
import {globalReducerActions} from "../../../reducers/globalReducer";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import {handleDeleteHeldPurchaseOrder} from "../../../utils/holdOrders";


const main = [getBusinessIdFromStorage(), "Purchase Order"]
export const purchaseOrderQueryKey = {
  all: main,
  order: (id) => [...main, id],
  orders: (query = {}) => [...main, getAllObjectKeysWithValues(query)],
  analytics: (query = {}) => [...main, "analytics", getAllObjectKeysWithValues(query)]
}

export const usePurchaseOrderQuery = (query = {}) => {
  const {getBusinessId} = useGlobalContext()
  
  const newQuery = {...query}
  
  if (query.status === "Payment Due Date") {
    newQuery.status = ''
  }
  
  return useQuery({
    queryKey: purchaseOrderQueryKey.orders(newQuery),
    queryFn: () => getPurchaseOrderService({business_id: getBusinessId(), ...newQuery})
  })
}

export const usePurchaseOrderByIdQuery = () => {
  const { purchaseOrderId } = useParams()
  
  return useQuery({
    queryKey: purchaseOrderQueryKey.order(purchaseOrderId),
    queryFn: () => getPurchaseOrderByIdService(purchaseOrderId)
  })
}

export const useCreatePurchaseOrderMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId, globalReducer } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createPurchaseOrderService({ ...data, business: getBusinessId() })
    },
  
    onSuccess: (_, variables) => {
      successFn()
      globalReducer({type: globalReducerActions.CLEAR_RESTOCK_PRODUCTS})
      globalReducer({
        type: globalReducerActions.CLEAR_MODAL_CONNECTOR
      })
    
      handleDeleteHeldPurchaseOrder(variables?.hold_purchase_id)
    
      toast.success("Purchase Order Created!!", "purchase order")
      queryClient.refetchQueries({queryKey: purchaseOrderQueryKey.all})
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createPurchaseOrder,
      })
    }
  })
}

export const useUpdatePurchaseOrderMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return updatePurchaseOrderService(data.id, data)
    },

    onSuccess: (data, variables) => {
      successFn()
      queryClient.refetchQueries({queryKey: purchaseOrderQueryKey.all})
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: [queryKeys.DASHBOARD_ANALYTICS]})
  
      if (variables?.edit) {
        toast.success("Edit Successful!!", "purchase order")
        queryClient.refetchQueries({queryKey: orderQueryKey.analytics})
        return
      }
  
      if (!!variables?.supplied_data) {
        toast.success("Purchase Received!!", "purchase receive")
        return
      }
  
      if (variables?.paid_amount && !!variables?.supplied_data) {
        toast.success("Payment Successful!!", "purchase pay")
        return
      }
  
      toast.success("Successful!!", "success")
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updatePurchaseOrder,
      })
    }
  })
}


export const useDeletePurchaseOrderMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return deletePurchaseOrderService(data)
    },
    
    onSuccess: () => {
      successFn()
      queryClient.refetchQueries({ queryKey: purchaseOrderQueryKey.all })
      queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
      queryClient.refetchQueries({ queryKey: productQueryKey.all })
      queryClient.refetchQueries({ queryKey: [queryKeys.DASHBOARD_ANALYTICS] })
      queryClient.refetchQueries({ queryKey: orderQueryKey.analytics })
      
      toast.success("Delete Successful!!", "purchase order delete")
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
import {CloseSquare} from "iconsax-react";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {Button, Form, Input, Select, TextArea} from "../../../index";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useParams} from "react-router-dom";


const EditSubCategoryModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { categoryName, categoryId } = useParams()
  const { data: allCategories } = useCategoriesQuery()
  
  const allowedCategories = () => {
    return allCategories?.results?.filter(category => category.id !== categoryId)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <Select
            optional
            options={allowedCategories()}
            label="Category"
            placeholder={categoryName}
            name="category"
            valueKey="id"
            displayKey="name"
            displayName="category_name"
          />
          
          <Input label="Name" placeholder="Name of sub-category" name="name"  />
          <TextArea optional label="Describe Sub-Category" placeholder="Enter a description for this sub-category" name="description" />

          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Save" type="submit" />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditSubCategoryModal
import {Heading} from "./textStyles";
import styled from "styled-components";
import {ButtonStyled, InputStyled, SelectStyled} from "./formStyles";
import {FlexStyled, ImageStyled, LineStyled, SearchAndFilterStyled, SpaceBetweenStyled} from "./utilStyles";
import {ListDropDownStyled, MenuDropDownStyled} from "./listStyles";


const TableTopBarStyled = styled(SpaceBetweenStyled)`
  width: calc(100% - 3rem);
  padding: 1.5rem 1.5rem 1rem;
  margin-top: 2rem;
  background: white;
  
  ${SearchAndFilterStyled} {
    width:  ${props => props.$showSortFilter ? "70%" : "50%"};
    padding: 0;
    bottom: -.25rem;

    ${InputStyled} {
      width: 50%;
      margin-bottom: 0;
    }

    ${SelectStyled} {
      width: 50% !important;
    }
  }
  
  ${FlexStyled} {
    width: ${props => props.$showSortFilter ? "30%" : "50%"};
    justify-content: flex-end;

    ${ButtonStyled} {
      font-size: .9rem;
      padding: .7rem;
      width: ${props => props.$showSortFilter ? "55%" : "25%"};;
      border-radius: .3rem;
      display: inline-block !important;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 1.5rem;
    flex-direction: column-reverse;
    
    ${FlexStyled} {
      width: 100%;
      justify-content: space-between;

      ${ButtonStyled} {
        font-size: .9rem;
        padding: .7rem;
        width: 46%;
        border-radius: .3rem;
        display: inline-block !important;
      }
    }

    ${SearchAndFilterStyled} {
      width: 100%;
    }

    ${InputStyled}.search_field {
      display: initial !important;
      width: 100%;
    }
    
    ${MenuDropDownStyled} div.container {
      ${ListDropDownStyled} {
        left: -60vw !important;
        min-width: 60vw !important;
        max-width: 600px !important;
      }
    }
  }
`

const TableHeadersStyled = styled("thead")`
  position: relative;
  width: 100%;
  
  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: 220%;
    }
    
    th {
      text-align: left;
      width: 100%;
      margin-right: 1rem;
    }

    th:last-child, td:last-of-type {
      display: flex;
      justify-content: ${props => props.lastHeaderPosition || "center"};
    }
  }

  ${LineStyled} {
    width: 100%;
    bottom: -.7rem;
    margin: 0;
    position: absolute;
    background-color: #EAEAEA;

    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: 220%;
    }
  }
`

const TableItemActionStyled = styled("td")`
  gap: .6rem;
  display: flex;
  justify-content: ${props => !!props.position ? `${props.position} !important` : "center"};
  
  svg {
    cursor: pointer;
  }
  
  .reverse {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`

const TableItemStyled = styled("tr")`
  position: relative;
  padding: 2.5rem 0 1.8rem;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  
  :has(.product_image) {
    padding: 1.3rem 0 1.3rem;
  }

  td {
    width: 100%;
    text-align: left;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }

  ${LineStyled} {
    width: 100%;
    bottom: 0;
    margin: 0;
    position: absolute;
    background-color: #EAEAEA;
  }

  ${ImageStyled}.product_image {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.menuGray};
  }

  :hover {
    cursor: pointer;
    background: ${props => props.theme.tableHover};
  }


  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: 220%;
  }
`

const CheckBoxAndPackageIconStyled = styled("td")`
  display: flex;
  gap: .8rem;
  align-items: center;
  position: relative;
  left: .7rem;
  
  input {
    margin-left: .3rem;
  }
`

const TableItemForOrderStyled = styled(TableItemStyled)`
  background: ${props => props.$bgColor && props.theme[props.$bgColor]};
`

const TableItemForWalletTransactionsStyled = styled(TableItemStyled)`
  
  
`

const TableItemWithQuantityAndStatusStyled = styled(TableItemStyled)`

`

const TableStyled = styled("table")`
  width: 100%;
  img {
    margin-bottom: 0;
  }
  
  td, th {
    overflow: clip;
    word-wrap: break-word;
    width: calc(100% / ${props => props.noCols}) !important;
  }

  tbody tr:first-child {
    margin-top: .5rem;
  }
  
  th:last-child, td:last-of-type {
    justify-content: center;
    max-width: ${props => !props.$equalWidth && "7% !important"};
  }
  
  td:last-of-type {
    max-width: ${props => !!props.$lastDataPosition && "12% !important"};
  }
  
  .counter {
    text-align: center;
    width: 7% !important;

    ${Heading} {
      width: fit-content;
    }
  }
  
  :has(${TableItemForWalletTransactionsStyled}) {
    th:last-child, td:last-of-type {
      width: 100% !important;
      max-width: 30% !important;
    }
  }
  
  td.center {
    text-align: center;
  }
  
  td.success, td.error, td.pending, td.gray {
    ${Heading} {
      gap: .4rem;
      display: flex;
      align-items: center;
      font-family: Bold, serif;
    }
    
    ${ImageStyled} {
      margin-top: -.15rem;
    }
  }

  td.success {
    ${Heading} {
      color: ${props => props.theme.success} !important;
    }
  }
  
  td.pending {
    ${Heading} {
      color: ${props => props.theme.pending} !important;
    }
  }

  td.error {
    ${Heading} {
      color: ${props => props.theme.error} !important;
    }
  }

  svg {
    cursor: pointer;
  }

`

const PaginationButton = styled("button")`
  cursor: pointer;
  font-size: 1.1rem;
  padding: .6rem 1rem;
  border-radius: .3rem;
  font-family: Bold, serif;
  border: none;
  color: ${props => props.active ? "white" : props.theme.white};
  background-color: ${props => props.active ? props.theme.primary : props.theme.disabled};
  
  :disabled {
    color: white !important;
    background-color: ${props => props.theme.disabled} !important;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    font-size: .8rem;
    padding: .4rem .5rem;
  }
`

const TablePaginationStyled = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  
  > div {
    gap: 1.5rem;
    display: flex;
    padding-top: 2rem;
    align-items: center;
  }
  
  //> div {
  //  width: 55%;
  //  padding: 1rem;
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //
  //  > div {
  //    display: flex;
  //    gap: 1rem;
  //  }
  //}

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    > div {
      width: 100%;
      padding: .8rem;
      
      > div ${PaginationButton}:nth-child(4), > div ${PaginationButton}:nth-child(5) {
        display: none;
      }
    }
  }
`

const TableComponentStyled = styled.div`
  padding: ${props => props.$noPadding ? "1.5rem 0" : "1.5rem"};
  width: ${props => !props.$noPadding && "calc(100% - 3rem) !important" };
  border-radius: .4rem;
  background-color: white;
  
  a {
    display: block;
    text-decoration: none;
  }
  
  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    overflow-x: scroll;

    ${TablePaginationStyled} {
      display: none !important;
    }
  }
`


export {
  TableStyled,
  TableItemStyled,
  TableTopBarStyled,
  TableItemActionStyled,
  TableHeadersStyled,
  PaginationButton,
  TableComponentStyled,
  TablePaginationStyled,
  TableItemForOrderStyled,
  CheckBoxAndPackageIconStyled,
  TableItemForWalletTransactionsStyled,
  TableItemWithQuantityAndStatusStyled
}
export const getAllObjectKeysWithValues = (obj = {}) => {
  const transformed = {}
  
  Object.keys(obj).forEach(key => {
    if(!!obj[key]) {
      transformed[key] = obj[key]
    }
  })
  
  return transformed
}

export const isAllKeysValueEmpty = (obj = {}) => {
  let state = true
  
  Object.values(obj).forEach(val => {
    if(!!val) state = false
  })
  
  return state
}


const handleQueryParams = (url = "", queryParams = {}, isQueryParamExisting) => {
  let transformURL = url
  
  Object.keys(getAllObjectKeysWithValues(queryParams)).forEach((key, index) => {
    if(index === 0 && !isQueryParamExisting) {
      if(key === "businessId") {
        transformURL =`${transformURL}?business_id=${queryParams[key]}`
        return
      }
      
      const queryValue = (queryParams[key]).toString().split("&").join("%26")
      transformURL =`${transformURL}?${key}=${queryValue}`
      return
    }
  
    if(key === "businessId") {
      transformURL = `${transformURL}&business_id=${queryParams[key]}`
      return
    }
  
    const queryValue = (queryParams[key]).toString().split("&").join("%26")
    transformURL = `${transformURL}&${key}=${queryValue}`
  })
  
  return transformURL
}

export const setWindowURLQueryParams = (query = {}) => {
  const url = new URL(window.location);
  
  const params = new URLSearchParams(url.search);
  
  // delete all existing keys first before adding the new query params
  params.forEach((_, key) => params.delete(key));
  
  Object.keys(query).forEach(key => {
    params.set(key, query[key]);
  });
  
  url.search = params.toString();
  window.history.pushState({}, '', url);
}

export const getWindowQueryParams = () => {
  const url = new URL(window.location);
  
  const params = new URLSearchParams(url.search);
  
  const queryParams = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });
  
  return queryParams;
};


export default handleQueryParams
import styled from "styled-components";
import {ButtonStyled, SelectDropDownStyled, SelectStyled} from "../formStyles";
import {FlexColumnStyled, FlexStyled, ImageStyled, SpaceBetweenStyled} from "../utilStyles";
import {CreateCategoryModalStyled} from "../modalStyles";
import {Heading} from "../textStyles";


export const StoreFrontPageHeaderStyled = styled("div")`
  display: flex;
  margin: 3rem 0;
  padding: ${props => props.$padding ? "1.7rem 0" : "1rem 0"};
  align-items: center;
  justify-content: space-between;
  
  border-top: 1px solid ${props => props.theme.shadow};
  border-bottom: 1px solid ${props => props.theme.shadow};

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    flex-direction: row-reverse;
    justify-content: center;
    
    form {
      display: none;
    }
    
    p {
      display: none;
    }
    
    .div_with_date_filter {
      display: none;
    }
  }
  
  form {
    width: 21%;
    
    ${SelectStyled} {
      gap: 0;
      margin: 0 !important;
      background-color: ${props => props.theme.primary};
      
      input {
        border: none;
        color: ${props => props.theme.white};
      }

      input::placeholder, textarea::placeholder {
        opacity: 1;
        color: ${props => props.theme.white};
      }
    }
    
    ${SelectDropDownStyled} {
      max-height: 300px !important;
    }
    
    i.select {
      color: white;
      bottom: calc(100% - 9%) !important;
    }

    i.select svg {
      color: ${props => props.theme.white};
    }
    
    svg.clear_filter_icon {
      top: 0.7rem;
    }

    svg.clear_filter_icon path {
      stroke: white !important;
    }
  }
`


export const StoreFrontCategoryCardStyled = styled("div")`
  cursor: pointer;
  position: relative;
  border-radius: .5rem;
  width: calc(25% - 2rem);
  min-height: calc(390px - 2rem);
  
  background-image: ${props => `url(${props.$backgroundImage})`};
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  display: flex;
  padding: 0 0 2rem 1rem;
  flex-direction: column;
  justify-content: flex-end;

  div {
    z-index: 2;
    position: relative;
  }
  
  ${ButtonStyled} {
    width: 55%;
    display: none;
    padding: .7rem;
    font-size: .9rem;
    background: white;
    border-radius: .3rem;
    font-family: Light, serif;
    color: ${props => props.theme.black};
  }

  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: .5rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.27), rgba(0, 0, 0, 0.37));
  }

  :hover&::before {
    top: 0;
    left: 0;
    z-index: 2;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.70)) !important;
  }
  
  :hover ${ButtonStyled} {
    display: inline;
    cursor: pointer;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(50% - 1.5rem);
  }
`


export const StoreFrontProductCardStyled = styled("div")`
  cursor: pointer;
  padding-bottom: .3rem;
  width: calc(20% - 2rem);
  max-width: calc(20% - 2rem);

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(50% - 1.5rem) !important;
    max-width: calc(50% - 1.5rem) !important;
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    div.image {
      height: calc(250px - 2rem) !important;
    }
  }
  
  ${Heading} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 36px !important;
  }

  div.image {
    overflow: hidden;
    position: relative;

    width: 100%;
    height: calc(330px - 2rem);
    max-height: calc(330px - 2rem);
  }

  ${ImageStyled} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    margin: auto;
    display: block;
    position: absolute;
    
    border-radius: .5rem;
    border: 1px solid rgba(236, 233, 233, 0.96);
  }

  ${FlexColumnStyled} {
    margin-top: .7rem;
    align-items: center;
  }

  ${ButtonStyled} {
    width: 100%;
    padding: .7rem;
    font-size: .9rem;
    margin-top: .3rem;
    background: white;
    border-radius: .3rem;
    font-family: Light, serif;
    color: ${props => props.theme.black};
    border: 1px solid ${props => props.theme.shadow};
  }

  ${ButtonStyled}:hover {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.black};
  }

`

export const StoreFrontAuthModalStyled = styled(CreateCategoryModalStyled)`
  u {
    cursor: pointer;
  }
  
  svg {
    position: relative !important;
    z-index: 9999999999 !important;
  }
  
  div.intro {
    margin-top: 1rem;
    text-align: center;
  }
  
  .img_div {
    top: -2rem;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    
    ${ImageStyled} {
      max-width: 25%;
    }
  }
  
  ${SpaceBetweenStyled}.logout {
    margin-top: 2rem;
    
    ${ButtonStyled} {
      width: 45%;
      font-family: Light, serif;
    }

    ${ButtonStyled}.cancel {
      color: ${props => props.theme.black};
      background-color: ${props => props.theme.white};
      border: 1px solid ${props => props.theme.shadow};
    }
  }
`


export const StoreFrontOrderHistoryCardStyled = styled("div")`
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: .5rem;
  border: 1px solid ${props => props.theme.shadow};
  //max-height: 200px;

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    padding: .7rem;
    width: calc(100% - 1.4rem);
    
    ${SpaceBetweenStyled} {
      gap: 1.5rem;
      flex-direction: column;
    }

    ${FlexStyled}.image_and_order_summary {
      width: 100% !important;
    }

    ${FlexColumnStyled}.address_and_other {
      max-width: 100% !important;
      
    }
  }
  
  u {
    cursor: pointer;
  }

  ${SpaceBetweenStyled} {
    align-items: flex-start;
  }

  ${FlexStyled}.image_and_order_summary {
    width: 70%;
    padding: .3rem;
    max-height: 100%;
    align-items: flex-start;

    > img {
      max-width: 26%;
      max-height: 150px;
      border-radius: .3rem;
      border: 1px solid ${props => props.theme.shadow};
    }
  }

  ${FlexColumnStyled} {
    margin-top: .5rem;
  }

  ${FlexColumnStyled}.address_and_other {
    //align-items: flex-end;
    max-width: 26%;
  }
`
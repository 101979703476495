import axios from "../../axios";

const getDeliveryService = async (businessId, searchValue = " ") => {
	try {
		const { data } = await axios.get(`/orders/?business_id=${businessId}&set_for_delivery=${true}&search=${searchValue}`)

		const results = data.results.map(item => {
			return {
				...item,
				customer_address: item?.customer.address?.[0]?.address,
				customer_name: `${item?.customer?.first_name} ${item?.customer?.last_name}`
			}
		})
		return {...data, results}
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const packageDeliveryService = async (body) => {
	try {
		const { data } = await axios.post(`/package_order/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export {
	getDeliveryService, packageDeliveryService,
}
import {InventoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Form} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {useCustomerByIdQuery} from "../../../../hooks/useServices/useCustomerServices";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";


const ViewCustomerWalletLogModal = ({ onClose, formValues }) => {
  const { data: customer } = useCustomerByIdQuery()
  const { nairaCurrencyFormatter, dateFormatter } = useDataTypeFormatter()

  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">View Settlement</Heading>
        
        <Form values={formValues} onSubmit={() => {}} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Customer Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{customer?.first_name} {customer?.last_name}</Paragraph>
          </FlexStyled>
  
          {formValues?.pay_mode && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Payment Method: </Paragraph>
              <Paragraph color="black" size={1.2}>{(formValues.pay_mode).toUpperCase()}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Amount Paid: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.amount)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Ovaloop Charge: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.ovaloop_charge)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Settled Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(Number(formValues.amount) - Number(formValues.ovaloop_charge))}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Previous Balance: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.previous_balance)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Current Balance: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.updated_balance)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Paid: </Paragraph>
            <Paragraph color="black" size={1}>{dateFormatter(formValues.date)}</Paragraph>
          </FlexStyled>
  
          {!!formValues.remark && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Remark: </Paragraph>
              <Paragraph color="black" size={1}>{formValues.remark}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled></FlexStyled>
        </Form>
      
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default ViewCustomerWalletLogModal
import {Fragment, useEffect} from "react";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading} from "../../styles/textStyles";
import {ArrowLeft, SearchNormal1, ShoppingCart} from "iconsax-react";
import {Link} from "react-router-dom";
import {Form, Input} from "../index";
import {isMobile} from "../../utils";
import {RiLayoutColumnLine, RiLayoutGridLine} from "react-icons/ri";
import {useTheme} from "styled-components";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {useModal} from "../../hooks";
import {debounceActions} from "../../hooks/useUtils/useQueryDebounce";
import useSellContext from "../../hooks/useContexts/useSellContext";
import {useFormikContext} from "formik";

const SellBar = ({ debounceReducer, debounceState }) => {
  const theme = useTheme()
  const { navigate } = useGlobalContext()
  const [isLayoutChange, setIsLayoutChange] = useModal()
  const { sellSearchRef } = useSellContext()
  
  const handleSearch = (searchValue) => {
    debounceReducer({
      searchValue,
      type: debounceActions.SET_SEARCH_FIELD
    })
  }
  
  return (
    <Fragment>
      <SectionStyled className="header_section" ref={sellSearchRef}>
        <SpaceBetweenStyled>
          <Heading color="black" size={1.5}>
            <ArrowLeft color={theme.black} size={18} onClick={() => navigate(-1)} className="sell_arrow" />
            Sell
          </Heading>
          <FlexStyled className="mobile-only">
            {/*<Link to="#"><SearchNormal1 /></Link>*/}
            <Link to="/dashboard_sell/cart"><ShoppingCart /></Link>
          </FlexStyled>
        </SpaceBetweenStyled>
    
        <Form values={{ search: "" }} onSubmit={() => {}}>
          <HandleSearchInput handleSearch={handleSearch} debounceState={debounceState} />
        </Form>
    
        {isMobile() && (
          <SpaceBetweenStyled className="layout_switch">
            <p></p>
            <FlexStyled>
              <RiLayoutColumnLine
                size={25}
                onClick={setIsLayoutChange}
                className={!isLayoutChange ? "active" : ""}
              />
              <RiLayoutGridLine
                size={25}
                onClick={setIsLayoutChange}
                className={isLayoutChange ? "active" : ""}
              />
            </FlexStyled>
          </SpaceBetweenStyled>
        )}
      </SectionStyled>
    </Fragment>
  )
}

export default SellBar

const HandleSearchInput = ({ handleSearch, debounceState }) => {
  const { setFieldValue } = useFormikContext()
  
  useEffect(() => {
    if(debounceState.searchValue === "") {
      setFieldValue("search", "")
    }
  }, [debounceState.searchValue])
  
  return (
    <Input
      name="search"
      type="text"
      placeholder="Search..."
      onChange={handleSearch}
      rightIcon={<SearchNormal1 size={15} />}
    />
  )
}
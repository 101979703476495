import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, Form, Input, PasswordInput, Select} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {useRoleQuery} from "../../../hooks/useServices/useRoleServices";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import CropImage from "../CropImage";
import {useAllStoresQuery} from "../../../hooks/useServices/useStoreServices";
import {useEffect, useState} from "react";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const EditStaffModal = ({ onClose, handleSubmit, mutation, formValues }) => {
	const {toast} = useGlobalContext()
	const {data: allStores, isSuccess: isStoresSuccess} = useAllStoresQuery()
	const [mainBusiness, setMainBusiness] = useState("")
	const [selectedBusinesses, setSelectedBusiness] = useState([])
	
	const {isSuccess: isRolesSuccess, data: allRoles} = useRoleQuery()
	
	useEffect(() => {
		if (!!formValues?.other_business && !!allStores) {
			const staffAvailableBusinesses = allStores.filter(business => {
				if (formValues?.other_business.includes(business.id)) {
					return business
				}
			})
			
			setSelectedBusiness(staffAvailableBusinesses || [])
		}
	}, [formValues?.other_business, allStores])

	const handleSubmission = (values) => {
		if (values.offline_pin.length > 4 || values.offline_pin.length < 4 || !Number(values.offline_pin)) {
			toast.error("Staff offline pin must be only 4 numbers", values.offline_pin)
			return
		}
		
		const other_business = (selectedBusinesses?.map(business => business.id) || []).filter(business => business !== values.business)
		
		handleSubmit({
			...values,
			other_business,
		})
	}

	const allOtherAvailableStores = () => {
		return allStores?.filter(store => store.id !== mainBusiness)
	}

	return (
		<ModalLayoutStyled onClick={onClose}>
			<InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Edit Employee</Heading>
				
				<Form values={formValues} onSubmit={handleSubmission}>
					<Input label="First Name" placeholder="Enter first name" name="first_name"/>
					<Input label="Last Name" placeholder="Enter last name" name="last_name"/>
					<Input label="Phone" type="number" placeholder="Enter phone number" name="phone"/>
					<Input label="Email" disabled type="email" placeholder="Enter a valid email" name="email"/>
					<Input
						label="Address" className="fullWidth" type="address" placeholder="Enter House address" name="address"/>
					
					<PasswordInput
						name="offline_pin"
						label="Staff Offline Pin"
						type="password"
						placeholder="Enter pin (4 numbers)"
					/>
					
					<Select
						options={isRolesSuccess ? allRoles : []}
						displayName="designation"
						displayKey="name"
						valueKey="id"
						name="role"
						label="Designation"
						placeholder="Select a designation"
					/>
					
					<Select
						options={allStores}
						displayName="business_details_name"
						displayKey="store_name"
						valueKey="id"
						name="business"
						label="Main Store"
						updateFn={(store) => setMainBusiness(store.id)}
						placeholder="Select staff main store"
						className="fullWidth"
					/>
					
					<MultiSelectInput
						optional
						selectedValues={selectedBusinesses}
						options={isStoresSuccess ? allOtherAvailableStores() : []}
						displayName="store_name"
						setSelected={setSelectedBusiness}
						label="Other Store"
						className="fullWidth"
						placeholder="Add staff to other stores"
					/>

					<CropImage cropShape="round" name="avatar" width={270} height={270} placeholder="Staff image" optional className="fullWidth"  />
					
					
					<FlexStyled>
						<Button text="Save" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>
			
			</InventoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default EditStaffModal
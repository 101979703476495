import {useReducer} from "react";
import { useDebounce } from "use-debounce";
import useBreadCrumbTrail from "../useBreadCrumbTrail";


const useCategoryBasedQuery = (initialValues) => {
  const breadCrumbTrail = useBreadCrumbTrail([{ name: "All Categories", id: "all-categories" }])
  const [value, debounceReducerFn] = useReducer(debounceReducer, initialValues || debounceInitialValues)
  const [debounceState] = useDebounce(value, 300)
  
  const selectFieldSearch = (searchValue) => {
    debounceReducerFn({
      type: debounceActions.SET_SEARCH_FIELD,
      searchValue
    })
  }
  
  return { debounceState, debounceReducer: debounceReducerFn, selectFieldSearch, breadCrumbTrail }
}


export default useCategoryBasedQuery

const debounceInitialValues = {
  search: "",
  category_id: "",
  sub_category_id: "",
  nested_category_1_id: "",
  nested_category_2_id: "",
  nested_category_3_id: "",
}

export const debounceActions = {
  SET_SEARCH_FIELD: "SET_SEARCH_FIELD",
  1: "SET_CATEGORY_ID",
  2: "SET_SUB_CATEGORY_ID",
  3: "SET_SUB_CAT_1_ID",
  4: "SET_SUB_CAT_2_ID",
  5: "SET_SUB_CAT_3_ID",
  CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS"
}

const debounceReducer = (state, action) => {
  switch (action.type) {
    case debounceActions["1"]:
      return {
        ...state, category_id: action.id,
        sub_category_id: "",
        nested_category_1_id: "",
        nested_category_2_id: "",
        nested_category_3_id: "",
      }
    
    case debounceActions["2"]:
      return {
        ...state, sub_category_id: action.id,
        nested_category_1_id: "",
        nested_category_2_id: "",
        nested_category_3_id: "",
      }
      
    case debounceActions["3"]:
      return {
        ...state, nested_category_1_id: action.id,
        nested_category_2_id: "",
        nested_category_3_id: "",
      }
      
    case debounceActions["4"]:
      return {
        ...state, nested_category_2_id: action.id,
        nested_category_3_id: "",
      }
      
    case debounceActions["5"]:
      return { ...state, nested_category_3_id: action.id }
    
    case debounceActions.CLEAR_ALL_FILTERS:
      return { ...debounceInitialValues }
    
    default:
      return state
  }
}
import {DetailsStyled} from "./settingsStyles";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, FileInput, Form, Input, Line, Select} from "../../../components";
import {
	useCreateSettlementAccountMutation,
	useSettlementBankQuery
} from "../../../hooks/useServices/useOvaloopPaymentServices";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {memo, useEffect, useState, Fragment} from "react";
import {useFormikContext} from "formik";
import {WIPStatus} from "../../../utils/table";


const formValues = {bank_name: "", account_number: "", account_name: ""}

const Settlement = () => {
	const { data: business, isSuccess } = useBusinessByIdQuery()
	const settlementAccountMutation = useCreateSettlementAccountMutation({ successFn: () => {} })

	const handleSettlement = (formValues) => {
		settlementAccountMutation.mutate(formValues)
	}

	const settlementAccount = () => {
		if(!business?.settlement_account?.is_verified) return formValues

		return {
			bank_code: business?.settlement_account?.account_number,
			bank_name: business?.settlement_account?.bank_name,
			account_number: business?.settlement_account?.account_number,
			account_number_format: business?.settlement_account?.account_number,
			account_name: business?.settlement_account?.account_name,
		}
	}

	const [initialValues, setInitialValues] = useState(settlementAccount())


	useEffect(() => {
		setInitialValues(settlementAccount())
		
	}, [isSuccess, business, business?.settlement_account?.is_verified])

	return (
		<DetailsStyled $avatarWidth={30} $formWidth={30}>
			<SpaceBetweenStyled className="pageHeader">
				<div>
					<Heading size={1.3} color="black">Settlement Account</Heading>
					<Paragraph color="secondary">Once you add an account for ovaloop transfer settlement, it cannot be changed</Paragraph>
				</div>
			</SpaceBetweenStyled>
			
			<Line />
			
			<FlexStyled className="avatarAndForm">
				<section className="form_section">
					<Form values={initialValues} onSubmit={handleSettlement}>
						<SectionStyled>
							
							{!business?.settlement_account?.is_verified && (
								<Fragment>
									<BankSelection business={business} />
								</Fragment>
							)}
							
							{business?.settlement_account?.is_verified && (
								<Input
									disabled
									name="bank_name"
									label="Select bank"
									placeholder="Enter your bank account number" />
							)}
							
							{business?.settlement_account?.account_name && (
								<Input
									disabled
									name="account_name"
									label="Account Number"
									placeholder="Enter your bank account number" />
							)}
							
							<Input
								type="number"
								name="account_number"
								label="Account Number"
								disabled={business?.settlement_account?.is_verified}
								placeholder="Enter your bank account number"
							/>
							
							{business?.settlement_account?.is_verified && (
								<FlexStyled>
									<Heading size={1.3} color="black">Status:</Heading>
									{WIPStatus("verified")}
								</FlexStyled>
							)}
							
							{!business?.settlement_account?.is_verified && (
								<Fragment>
									<FileInput accept="image/png, image/gif, image/jpeg, application/pdf" optional name="kyc_document" placeholder="Upload C.A.C Or Valid ID Card"  />
									
									<Button text="Save" type="submit" isLoading={settlementAccountMutation.isLoading} />
								</Fragment>
							)}
						</SectionStyled>
					</Form>
				</section>
			</FlexStyled>
		</DetailsStyled>
	)
}

export default Settlement

const BankSelection = memo(() => {
	const { setFieldValue } = useFormikContext()
	const { data: settlementBanks } = useSettlementBankQuery()
	
	const handleSelection = (bank) => {
		setFieldValue("bank_name", bank.bankName)
		setFieldValue("bank_code", bank.bankCode)

	}

	return (
		<Select
			name="beneficiary_bank"
			valueKey="bank_code"
			label="Select bank"
			displayKey="bankName"
			displayName="bank_name"
			placeholder="Select your bank"
			options={settlementBanks?.banks}
			updateFn={handleSelection}
		/>
	)
})
import {
  CreatePackageDeliveryModal,
  NoDataLayout,
  PageHeader, PageHeaderWithButton, PageSummaryCard,
  SearchAndFilter,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {EmptyBoxImage} from "../../../assets/images";
import {Fragment} from "react";
import {noPageData} from "../../../utils";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {useModal} from "../../../hooks";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useCreateSentItemMutation,
  useSentItemByBusinessIdQuery
} from "../../../hooks/useServices/useSentItemServices";
import {
  getAllApprovedTransactions,
  getAllPendingTransactions,
  getAllRejectedTransactions
} from "../../../utils/filters";
import {AddCircle} from "iconsax-react";
import {PageSummaryStyled} from "../../../styles/utilStyles";

const SentItems = () => {
  const [showCreateSentItemModal, setShowCreateSentItemModal] = useModal()

  const { deliverySentItemsTable } = tableHeadersAndValueKeys
  const { isSuccess, data: allSentItems } = useSentItemByBusinessIdQuery()
  const createSentItemMutation = useCreateSentItemMutation({ successFn: setShowCreateSentItemModal })

  const handleCreateSentItem = (values) => {
    createSentItemMutation.mutate(values)
  }

  const approvedSentItems = getAllApprovedTransactions(allSentItems?.results)
  const pendingSentItems = getAllPendingTransactions(allSentItems?.results)
  const rejectedSentItems = getAllRejectedTransactions(allSentItems?.results)


  return (
    <div>
      {showCreateSentItemModal && <CreatePackageDeliveryModal onClose={setShowCreateSentItemModal} handleSubmit={handleCreateSentItem} mutation={createSentItemMutation} />}

      {isSuccess && (
        <Fragment>

          {noPageData(allSentItems.results) && (
            <Fragment>
              <PageHeader title="Sent Items" />
              <NoDataLayout
                imgWidth={45}
                imgAlt="No sent item"
                imgSrc={EmptyBoxImage}
                heading="No sent item yet"
                subHeading="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less"
                buttonText="Send an item"
                buttonClick={setShowCreateSentItemModal}
              />
            </Fragment>
          )}

          {!noPageData(allSentItems.results) && (
            <Fragment>
              <PageHeaderWithButton title="Sent Items" buttonText="Add Sent Items" buttonClick={setShowCreateSentItemModal} Icon={AddCircle}  />

              <PageSummaryStyled>
                <PageSummaryCard title="Total Number Of Sent Items" value={allSentItems.results.length} />
                <PageSummaryCard title="Approved Sent Items" value={approvedSentItems.length} />
                <PageSummaryCard title="Pending Sent Items" value={pendingSentItems.length} />
                <PageSummaryCard title="Rejected Sent Items" value={rejectedSentItems.length} />
              </PageSummaryStyled>

              <SetUpLayoutStyled>
                <SearchAndFilter placeholder="Search deliveries by name, remark or status" />
                <Table bg headers={deliverySentItemsTable.headers} noCols={deliverySentItemsTable.headers.length} >
                  {allSentItems.results.map((items, k) =>
                    <TableItemWithStatus
                      key={k}
                      data={items}
                      statusPosition={9}
                      keys={deliverySentItemsTable.values}
                    />
                  )}
                </Table>
                {!!allSentItems.next && <TablePagination />}
              </SetUpLayoutStyled>

              {!!allSentItems.next && <TablePagination />}
            </Fragment>
          )}

        </Fragment>
      )}
    </div>
  )
}

export default SentItems;
import {SubscriptionCardStyled, SubscriptionLayout, SubscriptionSummaryStyled} from "../../styles/subscriptionStyles";
import {Fragment, useState, memo, useEffect} from "react";
import {Button, Chips, Form, Input, Line, ManageSubscriptionModal} from "../../components";
import {Heading, Paragraph} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {
	FlexCenterStyled,
	FlexColumnStyled,
	FlexStyled,
	SectionStyled,
	SpaceBetweenStyled
} from "../../styles/utilStyles";
import useModal from "../../hooks/useModal";
import {
	useSubscriptionPackagesQuery,
	useUpdateSubscriptionMutation,
	useSubPackageCalculation, useSubscribeForPackageMutation
} from "../../hooks/useServices/useSubscriptionServices";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {getNumberOfDaysForwardFromDate} from "../../utils/handleDateFilterValue";
import {IoIosAddCircleOutline} from "react-icons/io";
import { SlMinus } from "react-icons/sl"
import {useFormikContext} from "formik";
import { Link } from "react-router-dom";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {FlexRowStyled} from "../../styles/cardStyles";
import payWithMonnify from "../../utils/monnify";
import {clearAllDataInStorage} from "../../utils";


const NewSubscription = () => {
	const { data: userProfile, isSuccess: isProfileSuccess, error } = useUserProfile()
	const { package_id: packageId, package_duration: packagePeriod } = userProfile?.[0]?.package_details || {}
	const { navigate } = useGlobalContext()
	
	useEffect(() => {
		if(isProfileSuccess && (!packageId || !packagePeriod)) {
			navigate("/login")
		}
	}, [isProfileSuccess, packageId, packagePeriod])

	useEffect(() => {
		if(!!error) {
			clearAllDataInStorage()
			navigate("/login")
		}
	}, [error])
	
	const [extraAddons, setExtraAddons] = useState({
		user: 0,
		store: 0,
		product: 0,
	})

	const handleSubscriptionSuccess = () => {
		navigate("/on-boarding/setup-store/")
	}

	const [summaryData, setSummaryData] = useState(null)
	const { data: allPackages } = useSubscriptionPackagesQuery()
	const [isManageSubscription, setIsManageSubscription] = useModal()
	const { nairaCurrencyFormatter, shortDateFormatter } = useDataTypeFormatter()
	const calculateSubscriptionMutation = useSubPackageCalculation({ successFn: setSummaryData })
	const subscribeForPackageMutation = useSubscribeForPackageMutation({ successFn: handleSubscriptionSuccess })
	const updateSubscriptionMutation = useUpdateSubscriptionMutation({ successFn: setIsManageSubscription })
	
	const handleGetSubSummary = () => {
		const bodyObj = {
			package: packageId,
			package_duration: packagePeriod,
			extra_user: extraAddons.user,
			extra_product: extraAddons.product,
			extra_store: extraAddons.store,
			sub_type: "new_purchase"
		}

		calculateSubscriptionMutation.mutate(bodyObj)
	}

	const paymentConfig = {
		email: userProfile?.[0]?.email,
		phone: userProfile?.[0]?.phone,
		amount: summaryData?.total_sub_cost,
		full_name: `${userProfile?.[0]?.first_name} ${userProfile?.[0]?.last_name}`
	}

	const handleSuccess = (res) => {
		const bodyObj = {
			package: packageId,
			package_duration: packagePeriod,
			extra_user: extraAddons.user,
			extra_product: extraAddons.product,
			extra_store: extraAddons.store,
			sub_type: "new_purchase",
			payment_ref: res.transactionReference
		}

		subscribeForPackageMutation.mutate(bodyObj)
	}

	const handleCheckOut = () => {
		payWithMonnify(paymentConfig, handleSuccess)
	}
	
	const getSelectedPackage = () => {
		return allPackages?.find(subPackage => subPackage?.id === packageId)
	}
	
	const { name, max_store, max_user, max_products, amount, monthly_amount, modules = [], package_addon } = getSelectedPackage() || {}
	
	return (
		<Fragment>
			{isManageSubscription && (
				<ManageSubscriptionModal
					onClose={setIsManageSubscription}
					mutation={updateSubscriptionMutation}
					handleSubmit={updateSubscriptionMutation}
				/>
			)}
		
			<SubscriptionLayout>
				<FlexCenterStyled>
					<SpaceBetweenStyled className="top_actions">
						<Link to="/on-boarding/setup-store/">
							<Paragraph color="black" size={1.15}>Continue your 14 days free version</Paragraph>
						</Link>
					</SpaceBetweenStyled>

					<SubscriptionCardStyled>
						<SpaceBetweenStyled>
							<Heading color="black" size={1.3}>{(name)?.toUpperCase()} PLAN</Heading>
							<Chips title="Active" active />
						</SpaceBetweenStyled>
						
						<SpaceBetweenStyled>
							<FlexColumnStyled>
								<Heading color="black" size={1.6}>
									{packagePeriod === "monthly" ? nairaCurrencyFormatter(monthly_amount || 0) : nairaCurrencyFormatter(amount || 0)}
								</Heading>
								<Paragraph noTop color="black">Next renewal amount</Paragraph>
							</FlexColumnStyled>
							
							<FlexColumnStyled>
								<Heading color="black" size={1.4}>Expiry date - {shortDateFormatter(getNumberOfDaysForwardFromDate(packagePeriod === "monthly" ? 30 : 365))}</Heading>
								<Paragraph noTop color="black">
									Expires in {packagePeriod === "monthly" ? "30" : "365" } days
								</Paragraph>
							</FlexColumnStyled>
						</SpaceBetweenStyled>
						
						<SectionStyled>
							<FlexStyled className="info">
								<FlexColumnStyled>
									<Heading color="black" size={1.02}>Plan <br/> Duration</Heading>
									<Paragraph color="secondary" size={1.2} bold>{packagePeriod}</Paragraph>
								</FlexColumnStyled>
								
								<FlexColumnStyled>
									<Heading color="black" size={1.02}>Store <br/> Count</Heading>
									<Paragraph color="secondary" size={1.2} bold>{max_store}</Paragraph>
								</FlexColumnStyled>
								
								<FlexColumnStyled>
									<Heading color="black" size={1.02}>Product <br/> Count</Heading>
									<Paragraph color="secondary" size={1.2} bold>{Number(max_products) > 60 && "Unlimited"}</Paragraph>
								</FlexColumnStyled>
								
								<FlexColumnStyled>
									<Heading color="black" size={1.02}>User <br/> Count</Heading>
									<Paragraph color="secondary" size={1.2} bold>{max_user}</Paragraph>
								</FlexColumnStyled>
							</FlexStyled>

							<br/>
							<FlexRowStyled>
								{modules?.map(item => <Heading bold color="black" key={item}>&#9635; {item}</Heading>)}
							</FlexRowStyled>
							
							<div className="section_line">
								<Line />
								<Chips title="Add Ons" />
							</div>
							
							{Number(amount) < 10 && (
								<Fragment>
									<br/><br />
									<Paragraph color="black" >Addons cannot be used on a free plan</Paragraph>
									<br/>
								</Fragment>
							)}
							
							{Number(amount) > 10 && (
								<Fragment>
									<div className="addons">
										<SpaceBetweenStyled>
											<Heading color="black">Extra store</Heading>
											<Paragraph color="secondary">
												{nairaCurrencyFormatter(package_addon?.extra_store)}
												<b> /year</b>
											</Paragraph>
											<Form values={{ store: 0 }}>
												<ExtraStore name="store" setState={setExtraAddons} />
											</Form>
										</SpaceBetweenStyled>
										
										<SpaceBetweenStyled>
											<Heading color="black">Extra user</Heading>
											<Paragraph color="secondary">
												{nairaCurrencyFormatter(package_addon?.extra_user)}
												<b> /year</b>
											</Paragraph>
											
											<Form values={{ user: 0 }}>
												<ExtraStore name="user" setState={setExtraAddons} />
											</Form>
										
										</SpaceBetweenStyled>
									</div>
								
								</Fragment>
							)}
							
							<Button isLoading={calculateSubscriptionMutation.isLoading} text="Get Summary" onClick={handleGetSubSummary} />
						
						</SectionStyled>
					</SubscriptionCardStyled>
					
					<div>
					</div>
				</FlexCenterStyled>
			
				<SubscriptionSummaryStyled>
					<Heading color="black" size={1.5} bold>Summary</Heading>
					<div>
						<SpaceBetweenStyled>
							<Heading color="black" size={1}>Package cost:</Heading>
							<Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.package_cost || 0)}</Paragraph>
						</SpaceBetweenStyled>

						<SpaceBetweenStyled>
							<Heading color="black" size={1}>Store cost:</Heading>
							<Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.store_cost || 0)}</Paragraph>
						</SpaceBetweenStyled>

						<SpaceBetweenStyled>
							<Heading color="black" size={1}>User cost:</Heading>
							<Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.user_cost || 0)}</Paragraph>
						</SpaceBetweenStyled>

						<SpaceBetweenStyled>
							<Heading color="black" size={1}>Billable period:</Heading>
							<Paragraph color="black" size={1}>
								{packagePeriod === "monthly" ? "30" : "365" } days
							</Paragraph>
						</SpaceBetweenStyled>

						<SpaceBetweenStyled>
							<Heading color="black" size={1}>Subtotal Cost:</Heading>
							<Paragraph color="black" size={1}>
								{packagePeriod === "monthly" ? nairaCurrencyFormatter(summaryData?.total_sub_cost || monthly_amount || 0) : nairaCurrencyFormatter(summaryData?.total_sub_cost || amount || 0)}
							</Paragraph>
						</SpaceBetweenStyled>
					</div>

					<Button disabled={!summaryData} text="Checkout" onClick={handleCheckOut} isLoading={subscribeForPackageMutation.isLoading} />
				</SubscriptionSummaryStyled>
			</SubscriptionLayout>
		</Fragment>
	)
}

export default NewSubscription


const ExtraStore = memo(({ addCount = 1, name, validate, setState }) => {
	const { toast } = useGlobalContext()
	const { values, setFieldValue } = useFormikContext()

	const handleAddMore = () => {
		setFieldValue(`${name}_format`, Number(values[name]) + addCount)
		setFieldValue(name, Number(values[name]) + addCount)

		setState(prev => ({
			...prev,
			[name]: Number(values[name]) + addCount
		}))
	}
	
	const handleSubMore = () => {
		setFieldValue(`${name}_format`, Number(values[name]) - addCount)
		setFieldValue(name, Number(values[name]) - addCount)

		setState(prev => ({
			...prev,
			[name]: Number(values[name]) - addCount
		}))
	}
	
	const handleValidate = () => {
		setState(prev => ({
			...prev,
			[name]: Number(values[name])
		}))
		
		if(!validate) return;
		
		const num = Number(values[name])

		if(num % addCount !== 0) {
			toast.error("Please enter products in multiples of 50")
			setFieldValue(`${name}_format`, "")
			setFieldValue(name, "")
			
			setState(prev => ({
				...prev,
				[name]: 0
			}))
		}
	}
	
	return (
		<FlexCenterStyled>
			<SlMinus color="black" onClick={handleSubMore} size={20} />
			<Input onBlur={handleValidate} placeholder="0" name={name} formatNumber type="number" />
			<IoIosAddCircleOutline color="black" size={20} onClick={handleAddMore} />
		</FlexCenterStyled>
	)
})
import axios from "../../axios"
import {getDebtorsServiceStructure} from "./debtorServiceStructure";
import {getOrderServiceStructure} from "../orderServices/orderServiceStructure";

const getDebtorsServices = async (businessId, searchValue = ' ') => {
  try {
    const { data } = await axios.get(`/debtors/?business_id=${businessId}&search=${searchValue}`)

    return getDebtorsServiceStructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const getCustomerGroupOrderDebtsServices = async ({ businessId, fromDateValue, toDateValue, customerId }) => {
  let url = `/group_orders/?business_id=${businessId}&debt=true&customer_id=${customerId}`
  
  if(!!fromDateValue && !!toDateValue) {
    url = url + `&from_date=${fromDateValue}&to_date=${toDateValue}`
  }
  
  try {
    const { data } = await axios.get(url)
    return getOrderServiceStructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getAllGroupOrderDebtsServices = async ({ business_id, fromDateValue, toDateValue, customerId }) => {
  let url = `/group_orders/?business_id=${business_id}&debt=true`
  
  if(customerId) {
    url = url + `&customer_id=${customerId}`
  }
  
  if(!!fromDateValue && !!toDateValue) {
    url = url + `&from_date=${fromDateValue}&to_date=${toDateValue}`
  }
  
  try {
    const { data } = await axios.get(url)
    return getOrderServiceStructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getDebtorsServices }
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {expenseCategoryFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const CreateExpenseCategoryModal = ({ onClose, handleSubmit, mutation }) => {
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Expense Category</Heading>
        <Form values={expenseCategoryFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Name" placeholder="Enter expense category name" name="name"  />
          
          <FlexStyled>
            <Button text="Create" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateExpenseCategoryModal
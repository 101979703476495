import {DetailsStyled} from "./settingsStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Form, Input, Line} from "../../../components";
import {SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {useUserProfile} from "../../../hooks/useServices/userHooks";


const Profile = () => {
  const {data: user} = useUserProfile()
  const formValues = user?.[0] || {}
  
  
  const handleEditProfile = () => {
  
  }
  
  return (
    <DetailsStyled>
      <SpaceBetweenStyled className="pageHeader">
        <div>
          <Heading size={1.3} color="black">Profile</Heading>
          <Paragraph color="secondary">Update your profile and personal details here</Paragraph>
        </div>
      </SpaceBetweenStyled>
      
      <Line/>
      
      <SpaceBetweenStyled className="avatarAndForm">
        <div>
          <SpaceBetweenStyled>
            <div className="image_and_user_type">
              {/*<Image src={Avatar1} alt="user" />*/}
              {/*<Paragraph color="black">Admin</Paragraph>*/}
            </div>
            {/*<Line />*/}
          </SpaceBetweenStyled>
        </div>
        <section>
          <Form values={formValues} onSubmit={handleEditProfile}>
            <SectionStyled twoCol>
              <Input disabled name="first_name" label="First Name" placeholder={formValues.first_name}/>
              <Input disabled name="last_name" label="last Name" placeholder={formValues.last_name}/>
              <Input disabled name="email" label="Email" type="email" placeholder={formValues.email}/>
              <Input disabled name="phone" label="Phone" placeholder={formValues.phone}/>
              <Input disabled name="business_name" label="Store" placeholder={formValues.business_name}/>
              <Input disabled name="address" label="Location" placeholder={formValues.address}/>
            </SectionStyled>
    
            {/*<Button className="profile_button" type="submit" text="Edit"/>*/}
          </Form>
        </section>
      </SpaceBetweenStyled>
    </DetailsStyled>
  )
}

export default Profile
import {BarcodeModalStyled, CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Text} from "../../../../styles/textStyles";
import {SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {Button} from "../../../index";
import {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";


const ProductPriceTag = ({ onClose, product }) => {
	const barcodeRef = useRef(null)
	const {currencyFormatter, dateOnlyFormatter} = useDataTypeFormatter()

	const handlePrintBarcode = useReactToPrint({
		content: () => {
			const newBarcodeEl = barcodeRef.current.cloneNode(true)
			const actionEl = newBarcodeEl.querySelector(".barcode_actions")
			newBarcodeEl.removeChild(actionEl)

			return newBarcodeEl
		}
	})


	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose}/>
				<Heading size={1.3} color="black">Product Price Tag</Heading>
				
				<BarcodeModalStyled ref={barcodeRef}>
					<Text style={{fontSize: "1.7rem"}} color="black">{product.name}</Text>
					<Heading className='price_tag' bold color="black">{currencyFormatter(product.selling_price || 0)}</Heading>
					
					<Text style={{fontSize: "1.15rem"}} color="black">{dateOnlyFormatter(new Date())}</Text>
					
					<SpaceBetweenStyled className="barcode_actions">
						<Button className="fullWidth" text='Print' onClick={handlePrintBarcode}/>
					</SpaceBetweenStyled>
				</BarcodeModalStyled>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default ProductPriceTag
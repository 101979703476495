const modalNames = {
	CREATE_PRODUCT_MODAL: "CREATE_PRODUCT_MODAL",
	CREATE_PRODUCIBLE_MODAL: "CREATE_PRODUCIBLE_MODAL",
	CREATE_RAW_MATERIAL_MODAL: "CREATE_RAW_MATERIAL_MODAL",
	EDIT_RAW_MATERIAL_MODAL: "EDIT_RAW_MATERIAL_MODAL",
	EDIT_PRODUCT_MODAL: "EDIT_PRODUCT_MODAL",
	CREATE_CATEGORY_MODAL: "CREATE_CATEGORY_MODAL",
	EDIT_PRODUCIBLE_MODAL: "EDIT_PRODUCIBLE_MODAL",
	CREATE_SUBCATEGORY_MODAL: "CREATE_SUBCATEGORY_MODAL",
	CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
	CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL: "CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL",
	EDIT_PRODUCTION_UNIT_MEASUREMENT_MODAL: "EDIT_PRODUCTION_UNIT_MEASUREMENT_MODAL",
	CREATE_PURCHASE_ORDER: "CREATE_PURCHASE_ORDER",
	CREATE_SUPPLIER_ORDER: "CREATE_SUPPLIER_ORDER",
	CREATE_UNIT_MEASUREMENT: "CREATE_UNIT_MEASUREMENT"
}

export default modalNames
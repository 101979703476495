import {KitchenOrderCardStyled} from "../../styles/kitchenStyles";
import {Paragraph} from "../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Profile2User} from "iconsax-react";
import {FlexRowStyled} from "../../styles/cardStyles";
import {useEffect, useState} from "react";
import {
	useUpdateKitchenGroupOrderMutation
} from "../../hooks/useServices/useOrderServices";
import {useActionPermissionFn} from "../../hooks/usePermissions/useActionPermission";
import actionsPermissions from "../../data/permissions/actionsPermissions";

const KitchenOrderCard = ({ orders }) => {
	const actionPermission = useActionPermissionFn()

	const averagePrepareTime = () => {
		return orders.orders.reduce((prev, curr) => {
			return Number(curr?.product?.prepare_time || 0) + prev
		}, 0)
	}

	const handleMaxTime = () => {
		const orderCreatedAt = new Date(orders.orders[0].date_created).getTime()
		const prepareTimeToMilliseconds = (averagePrepareTime() * 60) * 1000

		return orderCreatedAt + prepareTimeToMilliseconds
	}

	const getRemainingTimeInMinutes = () => {
		const expectedTime = handleMaxTime()
		const currentTime = new Date().getTime()
		return Math.round((((expectedTime - currentTime) / 1000) / 60))
	}

	const [countDown, setCount] = useState(getRemainingTimeInMinutes())
	const updateKitchenOrderMutation = useUpdateKitchenGroupOrderMutation({ successFn: () => {} })

	const handleUpdate = () => {
		if(!updateKitchenOrderMutation.isLoading) {
			const body = { id: orders.orders[0].group_order.id, kitchen_status: "ready" }
			updateKitchenOrderMutation.mutate(body)
		}
	}

	useEffect(() => {
		setInterval(() => {
			const timeRemainingInMinutes = getRemainingTimeInMinutes()
			setCount(timeRemainingInMinutes)
		}, 60000)
	}, [])

	return (
		<KitchenOrderCardStyled $statusColor={getRemainingTimeInMinutes() < 4 ? "error" : "success"}>
			<SpaceBetweenStyled>
				<FlexRowStyled $gap={.6}>
					<Profile2User color="black" size={18} />
				</FlexRowStyled>
				<Paragraph color="black" bold>
					{!orders?.orders[0]?.customer && "Guest"}

					{orders?.orders[0]?.customer?.first_name} {" "}
					{orders?.orders[0]?.customer?.last_name}
				</Paragraph>
			</SpaceBetweenStyled>

			{orders.orders.map(order => (
				<FlexStyled $gap={1} key={order.name}>
					<Paragraph color="black" bold size={1.1}>({order.qty})  {order.product.name}</Paragraph>
				</FlexStyled>
			))}

			<SpaceBetweenStyled>
				<Paragraph color="black" noTop bold>{countDown} min Left</Paragraph>
				{actionPermission.check(actionsPermissions.confirmKitchenScheduleFood) && (
					<Paragraph onClick={handleUpdate} size={.8} className="done_button" noTop bold>Done</Paragraph>
				)}
				</SpaceBetweenStyled>
		</KitchenOrderCardStyled>
	)
}


export default KitchenOrderCard
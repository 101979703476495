import axios from "../../axios";

const getStoresService = async (searchValue = "") => {
  try {
    const { data } = await axios.get(`/business/?search=${searchValue}`)
    return data?.map(store => ({ ...store, currency_name: store.details.currency_field }))
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getAllStoresService = async (searchValue = "") => {
  try {
    const { data } = await axios.get(`/business_selection/?search=${searchValue}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getStoreByIdService = async (businessId) => {
  try {
    const { data } = await axios.get(`/business/${businessId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getStoresByCountryIdService = async (countryId, searchValue = "") => {
  if(!countryId || countryId === " ") return
  try {
    const { data } = await axios.get(`/business/?country_id=${countryId}&search=${searchValue}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createStoreService = async (body) => {
  try {
    const { data } = await axios.post("/business/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editStoreService = async (storeId, body) => {
  try {
    const { data } = await axios.patch(`/business/${storeId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteStoreService = async (storeId) => {
  try {
    const { data } = await axios.delete(`/business/${storeId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getStoresService, getStoresByCountryIdService, getStoreByIdService, createStoreService, editStoreService, deleteStoreService }
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input, TextArea} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {debitWalletFormDefaultValues } from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";

const DebitWallerModal = ({ onClose, handleSubmit, mutation }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Debit Wallet</Heading>
        <Form values={debitWalletFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Amount" type="number" formatNumber placeholder="Enter debit amount" name="amount" />
          <TextArea name="remark" label="Debit Remark" placeholder="Enter remark for this debit" />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default DebitWallerModal
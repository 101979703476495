import {useUserProfile} from "../../useServices/userHooks";
import {actionsPermissions} from "../../../data/permissions";


const useSettingOptions = () => {
  const {data: userProfile, isSuccess: isUserSuccess} = useUserProfile()
  
  return [
    {title: "Profile", link: "/dashboard/settings"},
    {title: "Password", link: "/dashboard/settings/password"},
    ...(isUserSuccess && !userProfile?.[0]?.is_verified ? [
      {title: "Verify Email", link: "/dashboard/settings/verify_email"}
    ] : []),
    // {
    //   title: "My Plan",
    //   link: "/dashboard/settings/subscription",
    //   permissionCode: actionsPermissions.setBusinessSettings
    // },
    {
      title: "Business Settings",
      link: "/dashboard/settings/business_settings",
      permissionCode: actionsPermissions.setBusinessSettings
    },
    {
      title: "Store Front",
      link: "/dashboard/settings/store_front",
      permissionCode: actionsPermissions.setBusinessSettings
    },
    {
      title: "Notifications",
      link: "/dashboard/settings/user_notification"
    },
    ...((isUserSuccess && (userProfile?.[0]?.user_type === "client_admin" || userProfile?.[0]?.account === "cb3589fc-ecf9-4f5c-bc0c-966a6be2b7a4")) ? [
      {title: "Receipt", link: "/dashboard/settings/receipt"}
    ] : []),
    ...(!!window?.require ? [
      {title: "Desktop", link: "/dashboard/settings/desktop"}
    ] : []),
  ]
}


export default useSettingOptions
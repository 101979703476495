import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import useSellContext from "../../../hooks/useContexts/useSellContext";
import { Button } from "../../index";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Fragment} from "react";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const PreviewOrderCheckoutModal = ({ onClose, handleCheckout, formData, cartCalculations }) => {
	const { cart } = useSellContext()
	const formattedFormData = handleFormatFormData(formData)

	const { isMobile } = useGlobalContext()
	const businessSettings = useBusinessSettingQuery()
	const { currencyFormatter } = useDataTypeFormatter()

	const {
		taxPercentage, discountPercentage, totalTax, totalDiscount, subTotal, totalDelivery, overallCalculation
	} = cartCalculations

	const handleCheckoutSubmission = () => {
		onClose()
		handleCheckout()
	}

	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={40}>
				{isMobile && <><br/><br/><br/></>}

				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Preview Order</Heading> <br />

				<section id="preview_order">
					{cart.length > 0 && (
						cart.map(product => (
							<Fragment>
								<FlexStyled>
									<Heading color="black">{product.name}</Heading>
									<Heading color="secondary" thin size={.85}>
										{currencyFormatter(product.selling_price)}
										<b>(x{product.unit})</b>
									</Heading>
								</FlexStyled>
								<br/>
							</Fragment>
						))
					)}

					<br/>
					{formattedFormData.pay_mode && (
						<FlexStyled>
							<Heading color="black">Payment Method:</Heading>
							<Heading color="black">
								{formData.pay_mode.toUpperCase() === "OTHERS" ? `${formData.other_payment_name.toUpperCase()}` : formData.pay_mode.toUpperCase()}
							</Heading>
						</FlexStyled>
					)}

					<br/>
					{(formattedFormData.pay_mode === "split" || formattedFormData.pay_mode === "partial") && (
						<Fragment>
							<FlexStyled>
								<Heading color="black">Pos Value:</Heading>
								<Heading color="black">{currencyFormatter(formattedFormData.split_pos_value)}</Heading>
							</FlexStyled> <br/>

							<FlexStyled>
								<Heading color="black">Cash Value:</Heading>
								<Heading color="black">{currencyFormatter(formattedFormData.split_cash_value)}</Heading>
							</FlexStyled> <br/>

							<FlexStyled>
								<Heading color="black">Transfer Value:</Heading>
								<Heading color="black">{currencyFormatter(formattedFormData.split_transfer_value)}</Heading>
							</FlexStyled> <br/>
						</Fragment>
					)}

					{formattedFormData.customer && (
						<Fragment>
							<FlexStyled>
								<Heading color="black">Customer:</Heading>
								<Heading color="black">{formData.customer_name}</Heading>
							</FlexStyled> <br/>
						</Fragment>
					)}

					{formattedFormData.banks && (
						<Fragment>
							<FlexStyled>
								<Heading color="black">Bank:</Heading>
								<Heading color="black">{formData.display_bank}</Heading>
							</FlexStyled> <br/>
						</Fragment>
					)}

					{formattedFormData.payment_date && (
						<Fragment>
							<FlexStyled>
								<Heading color="black">{!!businessSettings?.due_date ? "Order Ready By: " : "Payment Date: "}</Heading>
								<Heading color="black">{new Date(formData.payment_date).toString().slice(0, 16)}</Heading>
							</FlexStyled> <br/>
						</Fragment>
					)}



				{/*	CART SUMMARY */}
					<SpaceBetweenStyled>
						<Heading color="secondary">Subtotal:</Heading>
						<Heading color="secondary">{subTotal}</Heading>
					</SpaceBetweenStyled> <br/>

					{formattedFormData.delivery && (
						<Fragment>
							<SpaceBetweenStyled>
								<Heading color="secondary">Delivery Rate:</Heading>
								<Heading color="secondary">+{totalDelivery}</Heading>
							</SpaceBetweenStyled> <br/>
						</Fragment>
					)}

					{!!discountPercentage && (
						<Fragment>
							<SpaceBetweenStyled>
								<Heading color="secondary">
									Discount:
									{formData.discount_type !== "amount" && `${discountPercentage}%`}
								</Heading>
								<Heading color="secondary">
									-{totalDiscount}
								</Heading>
							</SpaceBetweenStyled> <br/>
						</Fragment>
					)}

					{!!taxPercentage && (
						<Fragment>
							<SpaceBetweenStyled>
								<Heading color="secondary">Tax: {taxPercentage}%</Heading>
								<Heading color="secondary">
									+{totalTax}
								</Heading>
							</SpaceBetweenStyled> <br/>
						</Fragment>
					)}

					<SpaceBetweenStyled>
						<Heading color="black" bold size={1.1}>Total:</Heading>
						<Heading color="black" bold size={1.1}>
							{overallCalculation}
						</Heading>
					</SpaceBetweenStyled>

					<br/> <br/>
					<Button text="Checkout" onClick={handleCheckoutSubmission} />
				</section>
			</CreateStoreModalStyled>
		</ModalLayoutStyled>
	)
}


export default PreviewOrderCheckoutModal


const handleFormatFormData = (formData) => {
	const formattedData = {}

	for (const field in formData) {
		if(!!formData[field]) {
			formattedData[field] = formData[field]
		}
	}

	return formattedData
}
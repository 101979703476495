import styled from "styled-components";

const ListDropDownStyled = styled("div")`
  display: none;
  top: 2.3rem;
  z-index: 9;
  left: .5rem;
  border-radius: .4rem;
  position: absolute;
  background-color: white;
  box-shadow: 1px 1px 3px 2px ${props => props.theme.shadow};
  
  > div, > a div {
    padding: .7rem 3rem .7rem 1.5rem;
    
    :hover {
      border: none;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    top: 2.5rem;
    
    > div, > a div {
      padding-right: 3.4rem; 
    }
  }
`

const MenuListStyled = styled("div")`
  position: relative;
  cursor: pointer;
  gap: .7rem;
  display: flex;
  align-items: center;
  padding: .58rem 2rem;
  color: ${props => props.theme.menuGray};
  
  // if link is active
  padding-left: ${props => props.active && "calc(2rem - 5px)"};
  border-left: ${props => props.active && `5px solid ${props.theme.primary} !important`};
  background-color: ${props => props.active && props.theme.inputBGDark};
  
  img {
    margin-bottom: 0;
  }
  
  img:last-child {
    margin-left: 1rem;
  }
  
  :hover {
    padding-left: calc(2rem - 5px);
    border-left: 5px solid ${props => props.theme.darkGray};
    background-color: ${props => props.theme.menuHover};

    ${ListDropDownStyled} {
      display: initial;
    }
  }

  .new_flag {
    //position: absolute;
    //right: 1rem;
    //top: .2rem;
  }
`


export const MenuDropDownStyled = styled(MenuListStyled)`
  padding: .6rem !important;

  a {
    text-decoration: none;
  }

  :hover {
    border-left: none;
    background: transparent;
  }

  div.container {
    position: relative;
    padding-top: 5rem;

    ${ListDropDownStyled} {
      top: initial;
      left: -13.5vw;
      //width: fit-content;
      min-width: 16vw;
      max-width: 300px;

      ${MenuListStyled} {
        padding: 1.5rem !important;

        .right-icon {
          position: absolute;
          right: 1rem;
        }
      }

      svg {
        margin-left: -.35rem;
      }

      img {
        padding: .4rem;
      }
    }
  }

  ${ListDropDownStyled} {
    top: 7vh;
    left: -12rem;
    width: fit-content;
  }
`

export {
  MenuListStyled,
  ListDropDownStyled
}
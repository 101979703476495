import {createContext} from "react";
import {globalReducerInitialValues} from "../reducers/globalReducer";

const defaultValues = {
	navigate: () => {},
	isMobile: () => {},
	isTablet: () => {},
	isLargeDesktop: () => {},
	globalReducer: () => {},
	getBusinessId: () => {},
	toast: {
		error: (text, toastId) => {
		},
		confirm: (text, fn, toastId) => {
		},
		success: (text, toastId) => {
		}
	},
	globalState: globalReducerInitialValues,
}

export default createContext(defaultValues)
import queryKeys from "../queryKeys";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
	createPackageItemService,
	getFareCalculatorService,
	getPackageItemByBusinessIdService
} from "../../../services/packageItemServices";
import errorHandler from "../../../utils/errorHandler";


const usePackageItemByBusinessIdQuery = (searchValue) => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery({
		queryKey: [queryKeys.PACKAGE_ITEMS, businessId, searchValue],
		queryFn: () => getPackageItemByBusinessIdService(businessId, searchValue)
		})
}

const useFareCalculatorForDelivery = (packageList = [], vehicleType="") => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useQuery(
		`${queryKeys.FARE_CALCULATOR}_${packageList.join("_")}_${vehicleType}`,
		getFareCalculatorService.bind(this, businessId, packageList, vehicleType)
	)
}

const useCreatePackageItemMutation = ({ successFn }) => {
	const { getBusinessId, toast } = useGlobalContext()

	const businessId = getBusinessId()
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: body => {
			return createPackageItemService({...body, business: businessId})
		},

		onSuccess: () => {
			successFn()
			toast.success("Packaged Created!!!")
			queryClient.invalidateQueries({ queryKey: `${queryKeys.PACKAGE_ITEMS}_${businessId}` })
		},

		onError: err => {
			successFn()
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export { usePackageItemByBusinessIdQuery, useFareCalculatorForDelivery, useCreatePackageItemMutation }
import {Fragment} from "react"
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {
  NoDataLayout,
  ScreenLoading,
  SearchAndFilter,
  TableItemWithEyeAction,
  TablePagination, Table, PageSummaryCard, PageHeaderWithButton
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
  getAccountsServiceStructure
} from "../../../services/staffBackendServices/accountServices/accountServiceStructure";
import accountQueryKey from "../../../hooks/useServices/useStaffBackendServices/useAccountServices/accountQueryKey";
import {
  useAccountBusinessesByIdQuery,
  useAccountByIdQuery, useSwitchAccountTypeByIdMutation
} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";
import {useSetBusinessMutation} from "../../../hooks/useServices/useStoreServices";
import {setBusinessAccountIdInStorage, setBusinessIdInStorage} from "../../../utils";
import {StoreImage} from "../../../assets/images";
import {useParams} from "react-router-dom";
import {PageSummaryStyled, FlexStyled} from "../../../styles/utilStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {getDaysDifferenceFromCurrentDate} from "../../../utils/handleDateFilterValue";
import {BsToggleOff, BsToggleOn} from "react-icons/bs";
import {Paragraph} from "../../../styles/textStyles";



const ViewAccountBusinesses = () => {
  const { accountId } = useParams()
  const { navigate } = useGlobalContext()
  const { storesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allBusinesses } = useSearchQuery(debounceState, useAccountBusinessesByIdQuery)
  const setBusinessMutation = useSetBusinessMutation()
  
  const { dateOnlyFormatter } = useDataTypeFormatter()
  const { data: accountDetails } = useAccountByIdQuery(accountId)
  const switchAccountTypeMutation = useSwitchAccountTypeByIdMutation()
  
  const handleViewBusiness = (businessId) => {
    if(setBusinessMutation.isLoading) return
    
    setBusinessIdInStorage(businessId)
    setBusinessAccountIdInStorage(accountId)
    setBusinessMutation.mutate(businessId)
    navigate("/dashboard")
  }
  
  const handleSwitchAccountType = () => {
    if(switchAccountTypeMutation.isLoading) return
    switchAccountTypeMutation.mutate({ accountId, is_test: !accountDetails?.results?.[0]?.is_test })
  }
  
  const handleBusinessSubscription = () => {
    navigate(`/staff_backend/accounts/${accountId}/subscription`)
  }
  
  return (
    <div>
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allBusinesses.refetch} />
      )}
      
      <Fragment>
        <PageHeaderWithButton
          isBack
          title="Businesses"
          buttonClick={handleBusinessSubscription}
          buttonText="Manage Subscription"
        />
        
        {allBusinesses.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No business"
              imgSrc={StoreImage}
              heading="No business yet"
            />
          </Fragment>
        )}
        
        {allBusinesses.showTable && (
          <Fragment>

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allBusinesses.isQueryLoading}
                title="Account Name" value={accountDetails?.results?.[0]?.business_name}
              />
              <PageSummaryCard
                title="Date Created"
                isLoading={allBusinesses.isQueryLoading}
                // subText={`${Math.abs(getDaysDifferenceFromCurrentDate(accountDetails?.results?.[0]?.last_login_date))} days ago`}
                value={dateOnlyFormatter(accountDetails?.results?.[0]?.date || new Date())}
              />
              <PageSummaryCard
                title="Last Login Date"
                isLoading={allBusinesses.isQueryLoading}
                subText={`${Math.abs(getDaysDifferenceFromCurrentDate(accountDetails?.results?.[0]?.last_login_date || new Date()))} days ago`}
                value={dateOnlyFormatter(accountDetails?.results?.[0]?.last_login_date || new Date())}
              />
              <PageSummaryCard
                title="Last Sales Date"
                isLoading={allBusinesses.isQueryLoading}
                subText={`${Math.abs(getDaysDifferenceFromCurrentDate(accountDetails?.results?.[0]?.last_transact_date || new Date()))} days ago`}
                value={dateOnlyFormatter(accountDetails?.results?.[0]?.last_transact_date || new Date())}
              />
            </PageSummaryStyled>
            
            <FlexStyled $gap={.5}>
              {!!accountDetails?.results?.[0]?.is_test ?
                <BsToggleOn size={28} color="green" onClick={handleSwitchAccountType} /> :
                <BsToggleOff size={28} onClick={handleSwitchAccountType} />}
              <Paragraph color="black">Test Account</Paragraph>
            </FlexStyled>
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search business by name"
              />
              
              <Table headers={storesTable.headers} noCols={5}>
                {allBusinesses.isQueryWithData && (
                  allBusinesses.data.results.map((items, k) =>
                    <TableItemWithEyeAction
                      key={k}
                      count={k}
                      data={items}
                      keys={storesTable.values}
                      onClick={handleViewBusiness}
                      pageCount={allBusinesses.currentCount}
                    />
                  )
                )}
                
                {allBusinesses.isQueryWithNoData && (
                  <NoQueryData text={allBusinesses.isSearchEmptyMessage("Business", "")} />
                )}
                
                {allBusinesses.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allBusinesses.showPagination && (
                <TablePagination
                  query={allBusinesses}
                  restructureFn={getAccountsServiceStructure}
                  queryKey={accountQueryKey.mainList(debounceState.searchValue)}
                />
              )}
            </SetUpLayoutStyled>
            {allBusinesses.showPagination && (
              <TablePagination
                query={allBusinesses}
                restructureFn={getAccountsServiceStructure}
                queryKey={accountQueryKey.mainList(debounceState.searchValue)}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default ViewAccountBusinesses
import {
  CreateRoleModal, EditRoleModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useRoleQuery,
} from "../../../hooks/useServices/useRoleServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {roleFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {NoStaffImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";


const StaffRoles = () => {
  const { toast } = useGlobalContext()
  const [showEditRoleModal, setShowEditRoleModal] = useModal()
  const [showCreateRoleModal, setShowCreateRoleModal] = useModal()

  const { roleTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allStaffRoles } = useSearchQuery(debounceState, useRoleQuery, true)
  const [editRoleFormValues, setEditRoleFormValues] = useState(roleFormDefaultValues)
  const deleteRoleMutation = useDeleteRoleMutation({ successFn: () => {} })
  const editRoleMutation = useEditRoleMutation({ successFn: setShowEditRoleModal })
  const createRoleMutation = useCreateRoleMutation({ successFn: setShowCreateRoleModal })

  const handleCreateRole = (values) => {
    createRoleMutation.mutate(values)
  }

  const handleEditRole = (values) => {
    editRoleMutation.mutate(values)
  }

  const handleEditAction = (role) => {
    setEditRoleFormValues(role)
    setShowEditRoleModal()
  }

  const handleDeleteAction = (role) => {
    toast.confirm(
      "Are you sure?",
      deleteRoleMutation.mutate.bind(this, role.id),
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateRoleModal && <CreateRoleModal onClose={setShowCreateRoleModal} handleSubmit={handleCreateRole} mutation={createRoleMutation} />}
      {showEditRoleModal && <EditRoleModal onClose={setShowEditRoleModal} handleSubmit={handleEditRole} mutation={editRoleMutation} formValues={editRoleFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allStaffRoles.isEmpty && (
          <Fragment>
            <PageHeader title="Staff Roles" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoStaffImage}
              heading="No role yet"
              subHeading="Add  role and assign permission to them."
              buttonText="Add new role"
              buttonClick={setShowCreateRoleModal}
              permissionCode={actionsPermissions.createDesignation}
            />
          </Fragment>
        )}

        {allStaffRoles.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateRoleModal} title="Staff Roles" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.createDesignation} />

            <SetUpLayoutStyled>

              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search role by name"
              />

              <Table headers={roleTable.headers} noCols={roleTable.headers.length}>
                {allStaffRoles.isQueryWithData && (
                  allStaffRoles.data.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allStaffRoles.currentCount}
                      editAction={handleEditAction}
                      keys={roleTable.values}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.updateDesignation}
                      deletePermission={actionsPermissions.deleteDesignation}
                    />
                  )
                )}

                {allStaffRoles.isQueryWithNoData && (
                  <NoQueryData text={allStaffRoles.isSearchEmptyMessage("Staff Role")} />
                )}

                {allStaffRoles.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
            </SetUpLayoutStyled>
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default StaffRoles
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Form, Input} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {Link} from "react-router-dom";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import { IoCopyOutline } from "react-icons/io5"


const ReferralModal = ({ onClose, formValues, handleSubmit, mutation }) => {
  const { toast } = useGlobalContext()
  
  const handleCopyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success("Copied", { toastId: "copied" }))
      .catch()
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">View Settlement</Heading>
        
        <Form values={formValues} onSubmit={handleSubmit} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Full Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.first_name} {formValues.last_name}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Email: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.email}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Phone: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.phone}</Paragraph>
          </FlexStyled>
  
          {!!formValues.referral_code && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Referral Link: </Paragraph>
              <Paragraph color="primary" size={1.2}>
                <Link to={`https://business.ovaloop.app/register/85c6ad60-5208-411c-941e-3eea4482635d/month?referral_code=${formValues?.referral_code}`}>
                  https://business.ovaloop.app/register/85c6ad60-5208-411c-941e-3eea4482635d/month?referral_code=${formValues?.referral_code}
                </Link>
                <IoCopyOutline onClick={() => handleCopyToClipBoard(`https://business.ovaloop.app/register/85c6ad60-5208-411c-941e-3eea4482635d/month?referral_code=${formValues?.referral_code}`)}/>
              </Paragraph>
            </FlexStyled>
          )}
          
          <Input disabled={!!formValues.referral_code} name="referral_code" className="fullWidth" placeholder="Enter Referral Code" label="Referral Code" />
          
          <FlexStyled>
            <Button disabled={!!formValues.referral_code} text="Approve User" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default ReferralModal
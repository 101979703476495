import {Fragment, useEffect} from "react";
import {NoDataLayout} from "../../components";
import {StoreImage} from "../../assets/images";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {isPackageExpired} from "../../utils/expiryCheck";

const PackageExpired = () => {
	const { navigate, toast, isOffline } = useGlobalContext()
	const { data: userProfile } = useUserProfile()
	
	useEffect(() => {
		if(!!userProfile?.[0]?.first_name && !isPackageExpired(userProfile?.[0]?.package_details)) {
			navigate("/dashboard")
		}
	}, [userProfile, isOffline])

	const handleRenewPackage = () => {
		if (userProfile?.[0]?.user_type !== "client_admin") {
			toast.error("Please contact the store owner to renew package", "renew-package")
			return
		}
		
		if (isOffline) {
			toast.error("You are offline, please connect to the internet to renew your subscription", "renew-package")
			return
		}
		
		toast.error("Please contact ovaloop tech support", "renew-package")
		// navigate("/dashboard/settings/subscription")
	}

	return (
		<Fragment>
			<NoDataLayout buttonText="Renew package now"
				imgWidth={45}
				imgAlt="package expired"
				imgSrc={StoreImage}
				buttonClick={handleRenewPackage}
				heading="Subscription plan has expired"
			/>
		</Fragment>
	)
}


export default PackageExpired
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";

export const getActivityLogService = async (queryParams = {}) => {
  const url = handleQueryParams("/activity_log", queryParams)
  
  try {
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
import styled from "styled-components";
import {FlexColumnStyled, LineStyled, SectionStyled, ImageStyled} from "../../../styles/utilStyles";
import {ButtonStyled, InputStyled} from "../../../styles/formStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";

const DetailsStyled = styled("div")`
  .pageHeader {
    margin-bottom: 1.7rem;

    ${Paragraph} {
      margin-top: .4rem;
    }
  }
  
  .profile_button {
    max-width: 20%;
  }
  
  .avatarAndForm {
    margin-top: 3rem;
    position: relative;
    align-items: flex-start;

    ${ButtonStyled} {
      margin-top: 1.5rem;
      border-radius: .3rem;
      width: 100%;
    }
    
    > div {
      width: ${props => props.$avatarWidth || 55}%;
      
      ${LineStyled} {
        position: relative;
        top: 0;
        bottom: 0;
        transform: rotate(90deg);
      }
    }
    
    form section {
      margin-top: 0 !important;
    }
    
    .form_section {
      width: ${props => props.$formWidth}%;
    }
  }
  
  .image_and_user_type {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .receipt_settings {
    ${FlexColumnStyled} {
      align-items: center;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .avatarAndForm {
      gap: 2rem;
      align-items: center;
      flex-direction: column;
      
      ${LineStyled} {
        display: none;
      }

      ${ButtonStyled} {
        width: 100% !important;
        margin-top: 2.5rem;
      }
  
      .form_section {
        width: 100%;
      }
      
      ${SectionStyled} {
        gap: .5rem;
      }

      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        ${ButtonStyled} {
          max-width: 100%;
        }
      }
    }
  }
`

const BusinessSettingsStyled = styled("div")`
  > ${Heading} {
    margin-bottom: 2rem;
  }
  
  ${ButtonStyled} {
    max-width: 25%;
    margin-top: 2.5rem;
  }
  
  ${InputStyled} {
    width: 30%;
  }
  
  td h2 {
    gap: .3rem;
    display: flex;
    align-items: center;

    ${ImageStyled} {
      margin-bottom: .3rem;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${ButtonStyled} {
      max-width: 100%;
    }
    
    ${InputStyled} {
      width: 100%;
    }
  }
`

export {
  DetailsStyled,
  BusinessSettingsStyled
}
import {countries} from "country-data-list"

const getAllCountries = () => {
  return countries.all
}

const searchCountryData = (data, value, displayKey, valueKey) => {
  return data.filter(optionData => {
    const stringValue = String(value).replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const regex = new RegExp(stringValue, "gi");
    return optionData[displayKey].match(regex) || `${optionData[valueKey]}`.match(regex);
  });
}


export {
  getAllCountries,
  searchCountryData
}
import {
  CreateExpenseCategoryModal, EditExpenseCategoryModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateExpenseCategoryMutation,
  useDeleteExpenseCategoryMutation,
  useEditExpenseCategoryMutation,
  useExpenseCategoryQuery, expenseCategoryQueryKeys
} from "../../../hooks/useServices/useExpenseCategoryServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {discountFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {TaxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";


const ExpenseCategory = () => {
  const { toast } = useGlobalContext()
  const [showCreateExpenseCategoryModal, setShowCreateExpenseCategoryModal] = useModal()
  const [showEditExpenseCategoryModal, setShowEditExpenseCategoryModal] = useModal()
  const {
    createDiscount: createExpenseCategoryPermission,
    updateDiscount: updateExpenseCategoryPermission,
    deleteDiscount: deleteExpenseCategoryPermission
  } = actionsPermissions

  const { expenseCategoryTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allExpenseCategories } = useSearchQuery(debounceState, useExpenseCategoryQuery)
  const [editExpenseCategoryFormValues, setEditExpenseCategoryFormValues] = useState(discountFormDefaultValues)
  const deleteExpenseCategoryMutation = useDeleteExpenseCategoryMutation({ successFn: () => {} })
  const editExpenseCategoryMutation = useEditExpenseCategoryMutation({ successFn: setShowEditExpenseCategoryModal })
  const createExpenseCategoryMutation = useCreateExpenseCategoryMutation({ successFn: setShowCreateExpenseCategoryModal })

  const handleCreateExpenseCategory = (values) => {
    createExpenseCategoryMutation.mutate(values)
  }

  const handleEditExpenseCategory = (values) => {
    editExpenseCategoryMutation.mutate(values)
  }

  const handleEditAction = (discount) => {
    setEditExpenseCategoryFormValues(discount)
    setShowEditExpenseCategoryModal()
  }

  const handleDeleteAction = (discount) => {
    toast.confirm(
      "Are you sure?",
      deleteExpenseCategoryMutation.mutate.bind(this, discount.id),
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateExpenseCategoryModal && <CreateExpenseCategoryModal onClose={setShowCreateExpenseCategoryModal} handleSubmit={handleCreateExpenseCategory} mutation={createExpenseCategoryMutation} />}
      {showEditExpenseCategoryModal && <EditExpenseCategoryModal onClose={setShowEditExpenseCategoryModal} handleSubmit={handleEditExpenseCategory} mutation={editExpenseCategoryMutation} formValues={editExpenseCategoryFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allExpenseCategories.isEmpty && (
          <Fragment>
            <PageHeader title="Expense Category" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense category"
              imgSrc={TaxImage}
              heading="No expense category yet"
              buttonText="Create New Category"
              buttonClick={setShowCreateExpenseCategoryModal}
              permissionCode={createExpenseCategoryPermission}
            />
          </Fragment>
        )}

        {allExpenseCategories.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Expense Category"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateExpenseCategoryModal}
              permissionCode={createExpenseCategoryPermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search discount by name"
              />

              <Table headers={expenseCategoryTable.headers} noCols={expenseCategoryTable.headers.length}>
                {allExpenseCategories.isQueryWithData && (
                  allExpenseCategories.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allExpenseCategories.currentCount}
                      keys={expenseCategoryTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateExpenseCategoryPermission}
                      deletePermission={deleteExpenseCategoryPermission}
                    />
                  )
                )}

                {allExpenseCategories.isQueryWithNoData && (
                  <NoQueryData text={allExpenseCategories.isSearchEmptyMessage("Expense Category", "")} />
                )}

                {allExpenseCategories.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allExpenseCategories.showPagination &&
                <TablePagination
                  query={allExpenseCategories}
                  queryKey={expenseCategoryQueryKeys.mainList(allExpenseCategories.queryValues)}
                />
              }
            </SetUpLayoutStyled>
            
            {allExpenseCategories.showPagination &&
              <TablePagination
                query={allExpenseCategories}
                queryKey={expenseCategoryQueryKeys.mainList(allExpenseCategories.queryValues)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ExpenseCategory
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import uuid from "react-uuid";


const getProductByIdService = async (productId, queryParam = {}) => {
  try {
    const url = handleQueryParams(`/product/${productId}`, queryParam)
    const { data } = await axios.get(url)
    return {
      ...data,
      meta_measurement: data?.meta_measurement?.map(measurement => ({
        ...measurement,
        base_quantity_name: `${measurement.base_quantity} ${data.unit_measurement}`,
        available_quantity: (Number(data?.stock_unit) / Number(measurement?.base_quantity)).toFixed(1),
        available_quantity_name: `${(Number(data?.stock_unit) / Number(measurement?.base_quantity)).toFixed(1)} ${measurement.name}`
      })),
      faulty_meta_measurement: data?.faulty_meta_measurement?.map(measurement => ({
        ...measurement,
        base_quantity_name: `${measurement.base_quantity} ${data.unit_measurement}`,
        available_quantity: (Number(data?.stock_unit) / Number(measurement?.base_quantity)).toFixed(1),
        available_quantity_name: `${(Number(data?.stock_unit) / Number(measurement?.base_quantity)).toFixed(1)} ${measurement.name}`
      }))
    }
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getProductByBusinessIdService = async (businessId, searchValue = "") => {
  try {
    const url = handleQueryParams("/product", { businessId, search: searchValue, type: "general_product" })
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getSellableProductByBusinessIdService = async (businessId, searchValue = " ") => {

  try {
    const url = handleQueryParams("/product", { businessId, sellable: true, search: searchValue, type: "general_product" })
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getSellableProductForCategoryBasedService = async (queryParam) => {
  try {
    const url = handleQueryParams("/product/?sellable=true", queryParam, true)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getProductByCategoryIdService = async (businessId, categoryId, searchValue = " ") => {
  if(!categoryId) return

  try {
    const url = handleQueryParams("/product", { businessId, category_id: categoryId, search: searchValue, type: "general_product" })
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getSellableProductByCategoryIdService = async (businessId, categoryId, searchValue = " ") => {
  if(!categoryId) return

  try {
    const url = handleQueryParams("/product", { businessId, sellable: true, category_id: categoryId, search: searchValue, type: "general_product" })
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getProductBySubCategoryIdService = async (subCategoryId) => {
  try {
    const { data } = await axios.get(`/product/?business_id=${subCategoryId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getProductService = async (query = {}) => {
  try {
    const url = handleQueryParams('/product', query)
    const { data } = await axios.get(url)
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createProductService = async (body) => {
  try {
    const { data } = await axios.post("/product/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editProductService = async (productId, body) => {
  try {
    const { data } = await axios.patch(`/product/${productId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteProductService = async (productId) => {
  try {
    const { data } = await axios.delete(`/product/${productId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const uploadBulkProductService = async (type, businessId, body) => {
    const upload = `/product_batch_upload/?business_id=${businessId}`
    const update = `/product_batch_upload/?business_id=${businessId}&upload_type=batch_update`
  
  try {
    const { data } = await axios.post(type === "update" ? update : upload, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const moveProductService = async (body) => {
  try {
    const { data } = await axios.post("/product_distribution/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const manageProductStockService = async (body) => {
  try {
    const {data} = await axios.post("/adjust_stock_unit/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const adjustProductCostPriceService = async (body) => {
  try {
    const {data} = await axios.post("/adjust_cost_price/?type=cost_price", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const addFaultyProductsService = async (body) => {
  try {
    const {data} = await axios.post("/faulty_product_move/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getFaultyProductService = async (query = {}) => {
  try {
    const url = handleQueryParams("/faulty_product", query)
    const {data} = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getProductsWithSameSKUService = async (productId, formatter) => {
  try {
    const {data} = await axios.get(`/product/${productId}/?fetch_all=true`)
    
    const products = [...(data.other_store || [])].map(product => ({
      ...product,
      frontend_measurements: [
        {
          id: uuid(),
          type: "main",
          product_id: product.id,
          business_id: product.business,
          name: product.unit_measurement,
          selling_price: product.selling_price,
          name_display: `${product.unit_measurement} (${formatter(product.selling_price)})`
        },
        ...(product.meta_measurement || []).map(measurement => ({
          ...measurement,
          id: uuid(),
          type: "meta",
          product_id: product.id,
          name: measurement.name,
          business_id: product.business,
          selling_price: measurement.selling_price,
          name_display: `${measurement.name} (${formatter(measurement.selling_price)})`
        }))
      ]
    }))
  
    return [
      ...products.filter(store => store.id === productId),
      ...products.filter(store => store.id !== productId),
    ]
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateProductSellingPriceAcrossAccountService = async (body) => {
  try {
    const {data} = await axios.post("/mult_store_adjust_s_p/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const depleteFaultyProductService = async (body) => {
  try {
    const {data} = await axios.post("/faulty_product_deplete/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const productDistributionService = async (body) => {
  try {
    const { data } = await axios.post("/product_distribution/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getProductNotifyService = async (businessId) => {
  if(!businessId) throw new Error("Business id not provided")
  
  try {
    await axios.get(`/product_notify/?business_id=${businessId}`)
    return ""
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const bulkDeleteProductService = async (body) => {
  try {
    const { data } = await axios.post(`/product_bulk_delete/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const exportAllProductService = async (query = {}) => {
  try {
    const url = handleQueryParams("/product_export", query)
    const { data } = await axios.get(url, {
      responseType: "blob"
    })
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const publishProductToStoreFrontService = async (body) => {
  try {
    const { data } = await axios.post(`/publish_products/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export {
  getProductByIdService, getProductService, getProductByBusinessIdService,
  getProductByCategoryIdService, getProductBySubCategoryIdService, createProductService,
  editProductService, deleteProductService, uploadBulkProductService, moveProductService,
  manageProductStockService, productDistributionService, getProductNotifyService
}
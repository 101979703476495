import React from 'react'
import ReactQuill from 'react-quill';
import { TextEditorStyled } from '../../styles/formStyles';
import { useFormikContext } from 'formik';

const TextEditor = ({ name }) => {
  const { values, setFieldValue } = useFormikContext()

  const handleChange = (editorValue) => {
    setFieldValue(name, editorValue)
  }

  return (
    <TextEditorStyled>
      <ReactQuill
      id="quill-container-input"
      theme="snow" 
      value={values?.[name] || ""} 
      onChange={handleChange}
      formats={formats}
      placeholder='Write product description...'
      modules={{ toolbar: toolBar }} 
      />
    </TextEditorStyled>
  )
}

export default TextEditor

const toolBar = [
  [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
  [{size: []}],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{'list': 'ordered'}, {'list': 'bullet'}, 
    {'indent': '-1'}, {'indent': '+1'}],
  ['link', 'image', 'video'],
  ['clean']
]

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]
import {useMutation, useQueryClient} from "react-query";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {desktopOfflineQueryKeys} from "../useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import {globalReducerActions} from "../../../reducers/globalReducer";


export const createEntryInDB = async (ipcEventName, data) => {
  const {ipcRenderer} = window.require("electron")
  
  return new Promise((resolve, reject) => {
    ipcRenderer.once(ipcEventName, (_, data) => {
      if (data.status === "SUCCESS") {
        resolve(data)
      } else {
        reject(data)
      }
    });
    
    ipcRenderer.send(ipcEventName, data);
  })
}

export const useDesktopCreateOrderOfflineMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const {toast, globalState: {tableId}, globalReducer, navigate} = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: data => {
      return createEntryInDB(IPC_EVENTS.CREATE_OFFLINE_ORDERS, data)
    },
    
    onSuccess: (data) => {
      successFn(data.data)
      toast.success("Order Created", "created")
      queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all}).catch()
      queryClient.prefetchQuery({queryKey: desktopOfflineQueryKeys.all}).catch()
  
      if (tableId) {
        globalReducer({
          type: globalReducerActions.CLEAR_TABLE_DATA
        })
        navigate("/dashboard/table/")
    
      }
    },
    
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
      
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createDesktopOfflineOrder,
      })
    }
  })
}


export const useDesktopPartialPayForOfflineMutation = ({successFn}) => {
  const {toast} = useGlobalContext()
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: data => {
      return createEntryInDB(IPC_EVENTS.UPDATE_OFFLINE_ORDERS_PAYMENT, data)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Payment updated", "created")
      queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all})
      queryClient.invalidateQueries({queryKey: desktopOfflineQueryKeys.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.partialPayForOrderOffline,
      })
    }
  })
}
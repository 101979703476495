import {
  NoDataLayout,
  PageHeader, PageSummaryCard, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithEyeAction, TablePagination
} from "../../../../components";
import {NoDebtorImage} from "../../../../assets/images";
import {Fragment} from "react";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import { useDebtorQuery } from "../../../../hooks/useServices/useDebtorServices";

import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import debtorQueryKey from "../../../../hooks/useServices/useDebtorServices/debtorQueryKey";
import {getDebtorsServiceStructure} from "../../../../services/debtorsServices/debtorServiceStructure";
import {PageSummaryStyled} from "../../../../styles/utilStyles";



const Debtor = () => {
  const { navigate } = useGlobalContext()
  const { debtorsTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allDebtors } = useSearchQuery(debounceState, useDebtorQuery)

  const handleViewOrder = (customerId) => {
    navigate(`dashboard/inventory/debtor/${customerId}`)
  }

  return (
    <div>
      <PageHeader title="Debtor List" />
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allDebtors.refetch} />
      )}

      <Fragment>

        {allDebtors.isEmpty && (
          <Fragment>
              <NoDataLayout
                imgWidth={45}
                imgAlt="No debtor"
                imgSrc={NoDebtorImage}
                heading="No debtor yet"
                subHeading="Any customer that buy items on credit or installment will be here"
              />
          </Fragment>
        )}

        {allDebtors.showTable && (
          <Fragment>
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allDebtors.isQueryLoading}
                only
                title="Total Number Of Debtors"
                value={allDebtors?.data?.count}
              />
            </PageSummaryStyled>
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search debtor by name"
              />

              <Table headers={debtorsTable.headers} noCols={debtorsTable.headers.length}>
                {allDebtors.isQueryWithData && (
                  allDebtors.data.results.map((items, k) =>
                    <TableItemWithEyeAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allDebtors.currentCount}
                      keys={debtorsTable.values}
                      onClick={handleViewOrder}
                      dataKey="customer_id"
                    />
                  )
                )}

                {allDebtors.isQueryWithNoData && (
                  <NoQueryData text={allDebtors.isSearchEmptyMessage("Debtor", "")} />
                )}


                {allDebtors.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allDebtors.showPagination &&
                <TablePagination
                  query={allDebtors}
                  restructureFn={getDebtorsServiceStructure}
                  queryKey={debtorQueryKey.mainList(debounceState.searchValue)}
                />
              }
            </SetUpLayoutStyled>
            {allDebtors.showPagination &&
              <TablePagination
                query={allDebtors}
                restructureFn={getDebtorsServiceStructure}
                queryKey={debtorQueryKey.mainList(debounceState.searchValue)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Debtor;
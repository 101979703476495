import styled from "styled-components";
import {FlexColumnStyled, FlexStyled, ImageStyled, SpaceBetweenStyled} from "./utilStyles";
import {CreditCardStyled, CustomerAndStaffReviewCardStyled} from "./cardStyles";
import {TableComponentStyled} from "./TableStyles";


const CustomerDetailsStyled =  styled(FlexStyled)`
  gap: .4rem;
  align-items: flex-start;
  flex-direction: column;
`

const CustomerInformationStyled = styled(FlexStyled)`
  gap: 1.5rem;
  align-items: flex-start;
  
  ${FlexColumnStyled}:first-child {
    width: calc(27% - 1.5rem - 2px);
  }
  
  .customer_bio_data {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: calc(100% - 2rem);
    border-radius: .3rem;
    border: 1px solid ${props => props.theme.inputBGDark};
  }
  
  ${CreditCardStyled} {
    width: 80% !important;
    max-height: 13rem !important;
  }
  
  .customer_activities {
    width: calc(100% - 27% - 1.5rem);
  }
  
  ${TableComponentStyled} {
    margin-top: 1.5rem;
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    .customer_bio_data {
      margin-bottom: 1.5rem;
      flex-wrap: wrap;
      flex-direction: row;
      width: calc(100% - 2rem);
      
      ${CustomerDetailsStyled} {
        width: 45%;
      }
    }
  
    .customer_activities {
      width: calc(100% - 1.5rem);
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .customer_bio_data {
      width: calc(100% - 2rem);
    }

    .customer_activities {
      width: 100%;
      
      > ${SpaceBetweenStyled} {
          margin-top: 1rem;
          gap: .8rem .5rem;
          justify-content: flex-start;
          > ${FlexStyled} {
            display: contents;
            gap: 1rem .4rem;
          }
      }
    }
  }
  
`

const SingleCustomerStyled = styled("div")`
  padding: 1rem 1.5rem;
  width: calc(100% - 3rem);
  background-color: white;
  
  ${SpaceBetweenStyled}.review_section {
    gap: 1.5rem 0;
    margin: 3rem 0;
    
    .staff_avatar_section {
      width: 17%;
      display: flex;
      align-items: center;
      flex-direction: column;
      ${ImageStyled} {
        margin-bottom: .8rem;
      }
    }
    
    ${FlexColumnStyled} {
      width: 79%;
      
      ${CustomerAndStaffReviewCardStyled} {
        width: calc(100% / 4 - 2.5rem)
      }
    }
    
    ${CustomerAndStaffReviewCardStyled}:last-of-type {
      border-right: none;
    }
    
    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      gap: 2rem 1rem;

      ${FlexColumnStyled} {
        gap: 0 !important;
        width: 100%;

        ${CustomerAndStaffReviewCardStyled} {
          width: calc(100% / 2 - 2.5rem)
        }
        
        ${CustomerAndStaffReviewCardStyled}.left {
          //align-items: flex-end;
        }
      }

      ${CustomerAndStaffReviewCardStyled}:nth-child(2), 
      ${CustomerAndStaffReviewCardStyled}:nth-child(4) {
        border-right: none;
        padding-right: 0;
      }
      
      .staff_avatar_section {
        width: 100%;
        ${ImageStyled} {
          margin-bottom: 1rem;
          width: 35% !important;
        }
      }
    }
  }
`


export {
  CustomerDetailsStyled,
  SingleCustomerStyled,
  CustomerInformationStyled
}
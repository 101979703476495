import {Heading, Paragraph} from "./textStyles";
import styled from "styled-components";
import {CartSummaryStyled} from "./sellStyles";
import {CategoryCardStyled, ProductCardCountStyled} from "./cardStyles";
import {
  AllCenterStyled,
  FlexColumnStyled,
  FlexStyled,
  ImageStyled,
  LineStyled,
  SectionStyled,
  SpaceBetweenStyled
} from "./utilStyles";
import {
  ButtonStyled,
  CheckBoxStyled,
  FileInputStyled,
  InputStyled,
  SelectStyled,
  SplitOrPartialPaymentStyled
} from "./formStyles";
import {ChoosePlansStyled, SubscriptionCardStyled} from "./subscriptionCardStyles";
import {ContainerStyled} from "./DashboardStyles";


const ModalLayoutStyled = styled("div")`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.$zIndex || 99999999};
  cursor: pointer;
  display: flex;
  position: ${props => props.$position || "fixed"};
  align-items: center;
  padding: ${props => props.$padding || "1.5rem 5.5rem"};
  justify-content: center;
  background-color: ${props => props.theme.modalBackground};

  > div {
    cursor: auto;
    border-radius: .3rem;
    padding: 1.5rem 4rem;
    position: relative;
    background: white;
    max-height: calc(100% - 3rem);
    overflow-y: ${props => props.$noScroll ? "auto" : "scroll"};
    max-width: 1500px;
  }
  
  .payment_modal {
    overflow: hidden;
  }
  
  .add_extra_fields {
    gap: .5rem;
    width: 100%;
    float: right;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: flex-end;
  }
  
  .modal_info {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    width: 100%;
    
    ${Heading} {
      font-size: 1rem !important;
      padding: .5rem .8rem;
      border-radius: .4rem !important;
      background: ${props => props.theme.modalInfo};
    }
  }
  
  .delete_extra_fields {
    gap: .5rem;
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: .5rem;
    justify-content: flex-start;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    position: fixed !important;
    bottom: initial;
    padding: 0;
    min-height: 100vh;
    max-height: 100vh;
    align-items: initial;
    
    > div {
      padding: 3rem 2rem !important;
      width: 100%;
    }
  }

  ${SpaceBetweenStyled}.method_options {
    gap: 1rem 0;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }
  
  
    td.success {
    ${Heading} {
      color: ${props => props.theme.success} !important;
    }
  }
  
  td.pending {
    ${Heading} {
      color: ${props => props.theme.pending} !important;
    }
  }

  td.error {
    ${Heading} {
      color: ${props => props.theme.error} !important;
    }
  }
`

const GeneralModalStyled = styled("div")`
  width: calc(${props => props.$width || "60"}% - 8rem);
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: 80% !important;
  }
  
  > form {
    position: relative;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    
    ${ContainerStyled} {
      width: 100%;
    }
    
    ${InputStyled}, ${FileInputStyled} {
      width: calc(50% - .5rem);
      margin-bottom: 1.5rem;
    }

    ${ButtonStyled} {
      width: 45%;
    }
    
    .fullWidth {
      width: 100%;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    form {
      display: block;

      ${InputStyled}, ${ButtonStyled} {
        width: 100% !important;
      }
    }
  }
`

const CreateStoreModalStyled = styled(GeneralModalStyled)`
  max-height: 85vh;
  min-height: 35vh;
  overflow-y: scroll;

  section#preview_order {
    ${Heading} {
      font-size: 1rem !important;
    }
  }

  td ${Heading} {
    gap: .3rem;
    display: flex;
    align-items: center;
    font-size: 1.3rem !important;
    font-family: Bold, serif;

    ${ImageStyled} {
      margin-bottom: .1rem;
    }
  }


  svg {
    cursor: pointer;
    display: inline;
    float: right;
    margin-top: -.2rem;
    color: ${props => props.theme.gray};
  }

  ${CheckBoxStyled} {
    margin-top: .7rem;
  }

  ${Heading} {
    font-size: 1.35rem !important;
  }

  ${Paragraph}.forecast_value {
    position: relative;
    top: -1rem;
  }


  //:has() not compatible with firefox browser
  form > ${FlexStyled}:has(${ButtonStyled}) {
    width: 100%;
    margin-top: 1.5rem;
    justify-content: flex-end;

    ${ButtonStyled} {
      width: 25% !important;
    }
  }

  // replace :has()
  form > ${FlexStyled}:last-child {
    width: 100%;
    margin-top: 1.5rem;
    justify-content: flex-end;

    ${ButtonStyled} {
      width: 25% !important;
    }
  }
  
  ${FlexStyled}.two_cols_with_trash_icon {
    > div {
      width: calc(100% / 2 - 2rem) !important;
    }
    
    ${FlexStyled}.production_raw_materials_preview {
      width: calc(100% - 4rem) !important;
      margin-top: -1.5rem;
      padding-bottom: .7rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid ${props => props.theme.inputBorder};
    }
  }


  ${FlexStyled}.three_cols {
    gap: 2rem 1rem;
    align-items: flex-start;

    > div {
      width: calc(100% / 3 - 1rem);
    }
  }

  .space_between {
    justify-content: space-between;
  !important;
  }

  ${FlexStyled}.three_form_cols {
    position: relative;
    gap: 2rem 1rem;
    align-items: flex-start;

    > div {
      width: calc(100% / 3 - 1.5rem);
    }

    .supplier_delete {
      position: absolute;
      right: 0;
      top: 40%;
    }
  }

  .add_extra_3 {
    padding-top: 1rem;
    margin-top: .5rem;
    margin-bottom: 2rem;
    border-top: 1px solid #c4bfbf;
    border-bottom: 1px solid #c4bfbf;

    ${ButtonStyled} {
      width: calc(100% / 3 - 1.5rem);
    }
  }

  ${FlexStyled}.four_cols {
    position: relative;
    gap: 2rem 1rem;
    align-items: flex-start;

    > div {
      width: calc(100% / 5 - 1.5rem);
    }

    .supplier_delete {
      position: absolute;
      right: 0;
      top: 40%;
    }
  }

  ${FlexStyled}.five_cols {
    position: relative;
    gap: 2rem 1rem;
    align-items: flex-start;

    > div {
      width: calc(100% / 6 - 1.5rem);
    }

    .supplier_delete {
      position: absolute;
      right: 0;
      top: 40%;
    }
  }


  ${FlexStyled}.supplier_cols {
    position: relative;
    gap: 2rem 1rem;
    align-items: flex-start;

    > div {
      width: calc(50% / 2 - 1rem);
    }

    > div:first-child {
      width: calc(45% - 1rem) !important;
    }

    .supplier_delete {
      position: absolute;
      right: 0;
      top: 40%;
    }
  }

  // Subscription Plan Modal
  ${ChoosePlansStyled} {
    padding: 0;
    gap: 2rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    ${SubscriptionCardStyled} {
      width: 43%;
    }

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      flex-direction: column;

      ${SubscriptionCardStyled} {
        width: calc(100% - 2rem);
      }
    }
  }


  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${FlexStyled}:has(${ButtonStyled}) {
      margin-top: 2.2rem;

      ${ButtonStyled} {
        width: 100% !important;
      }
    }
  }
`

const CreateCategoryModalStyled = styled("div")`
  padding: 2.5rem !important;
  width: calc(33% - 4rem);
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: 80%;
  }

  svg {
    cursor: pointer;
    display: inline;
    float: right;
    margin-top: -.2rem;
    color: ${props => props.theme.gray};
  }
  
  ${Heading} {
    font-size: 1.35rem !important;
  }
  
  form {
    margin-top: 2rem;
  }
  
  ${SplitOrPartialPaymentStyled} {
    margin-bottom: 3rem;
  
    ${Heading} {
      font-size: 1.2rem !important;
    }
  }

  ${SpaceBetweenStyled}.selectCategory {
    gap: 1rem;
    width: 100%;
  }

  ${CategoryCardStyled} {
    width: 38%;
    ${Heading} {
      font-size: 1rem !important;
    }
  }
`

const InventoryModalStyled = styled(CreateStoreModalStyled)`
  ${ButtonStyled}.outline {
    color: ${props => props.theme.black};
    border: 1px solid ${props => props.theme.black};
    background: transparent;
  }
  
  td ${Heading} {
    gap: .3rem;
    display: flex;
    align-items: center;
    font-size: 1.3rem !important;
    font-family: Bold, serif;
    
    ${ImageStyled} {
      margin-bottom: .1rem;
    }
  }
  
  ${SectionStyled}.request_material {
    width: 100%;
    padding: 1.5rem 1rem;
    border-radius: .6rem;
    border: 1px solid ${props => props.theme.inputBorder};

    > ${Heading} {
      font-size: 1.5rem !important;
    }
    
    ${Heading}.wip_status {
      font-size: 1.2rem !important;
    }

    ${ContainerStyled}.request_summary {
      ${SpaceBetweenStyled} {
        margin-bottom: .6rem;
        
        ${ButtonStyled} {
          padding: .4rem;
          font-size: .8rem;
          border-radius: .3rem;
          width: 15% !important;
          max-width: 120px !important;
        }
      }

      > ${ButtonStyled} {
        margin-top: 1.5rem;
      }
    }
  }

  div.purchase_section {
    width: 100%;
    padding: 1rem;
    margin-top: .6rem;
    border-radius: .5rem;
    margin-bottom: 1.5rem;
    border: 1px solid gray;
  }
`

const AddToCartModalStyled = styled(CreateCategoryModalStyled)`
  padding: 1.3rem 2.5rem !important;
  
  ${FlexStyled}.navigation_section {
    svg {
      padding: .4rem;
      border-radius: .3rem;
      border: 1px solid ${props => props.theme.menuGray};
    }
    
    ${FlexStyled} {
      gap: 0;
      align-items: flex-start;
      flex-direction: column;
      
      ${Heading} {
        font-size: 1.15rem !important;
      }
    }
  }

  ${FlexStyled}.phone_and_counter {
    margin-top: 2rem;
    
    ${ImageStyled} {
      max-width: 150px;
      max-height: 180px;
    }
    
    ${FlexStyled}.product_name_and_counter {
      width: 48%;
      gap: .3rem;
      align-items: flex-start;
      flex-direction: column;

      ${Heading}.price {
        flex-wrap: wrap;
        margin-top: .6rem;
        font-size: 1.2rem !important;
      }
      
      * {
        margin: 0;
      }
      
      ${ProductCardCountStyled} {
        margin-top: .6rem;
        
        ${ButtonStyled}:hover {
          color: ${props => props.theme.black};
          background: transparent !important;
        }
      }
    }
  }
`

const CheckOutModalStyled = styled("div")`
  padding: 1.5rem !important;
  position: relative;
  width: calc(100% - 2rem);
  height: calc(100% - 0rem);
  background-color: white;

  > ${Heading} {
    text-align: left;
  }
  
  svg {
    cursor: pointer;
    display: inline;
    float: right;
    margin-top: -.2rem;
    color: ${props => props.theme.gray};
  }
  
  form {
    margin-right: -.5rem;
    overflow-y: scroll;
    height: 62vh;
    padding-right: .5rem;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
    
    #submit_checkout {
      visibility: hidden;
      //display: none;
    }

    ${InputStyled} {
      width: 100%;
    }
    
    ${CheckBoxStyled} {
      margin-top: 1.5rem;
      margin-left: .2rem;
    }
  }
  
  ${CartSummaryStyled} {
    width: calc(28.4% - 3rem);
    right: 0;
    padding: 1.2rem 1rem .8rem;
  }
  
  .show_payment_info {
    margin-bottom: .8rem;
    
    ${Paragraph} {
      display: flex;
      gap: .4rem;

      svg {
        top: .15rem;
        position: relative;
      }
    }
  }
`


const PaymentModalStyled = styled(GeneralModalStyled)`
  padding: 1.5rem !important;
  
  ${SpaceBetweenStyled}.header {
    position: relative;
    
    svg {
      top: -52%;
      right: -13%;
      cursor: pointer;
      position: absolute;
    }
    
    margin-bottom: 1.5rem;
    ${ImageStyled} {
      margin-bottom: 0;
    }
    
    ${FlexStyled} {
      gap: .3rem;
      flex-direction: column;
      
      ${Paragraph} {
        span {
          color: ${props => props.theme.success};
        }
      }
    }
  }
`

const PaymentModalMainStyled = styled(FlexStyled)`
  margin-top: 2rem;
  flex-direction: column;
  
  > ${FlexStyled} {
    text-align: center;
    flex-direction: column;
    margin-top: 1rem;
    width: calc(100% - 2rem);
    padding: 2.5rem 1rem;
    border-radius: .3rem;
    background: ${props => props.theme.shadow};
    
    ${Paragraph} {
      margin-top: .7rem;
    }
  }
`

const ManageSubscriptionModalStyled = styled("div")`
  margin-top: 4rem;
  
  form {
    width: 100% !important;
  }
  
  ${SpaceBetweenStyled}.row {
    margin-bottom: 1rem;

    > * {
      font-size: 1.2rem !important;
      width: calc((100% / 3) - 1rem);
    }

    > *:nth-child(2) {
      text-align: center;
    }
    
    ${Heading}:last-of-type {
      text-align: right;
    }
    
    ${Paragraph} {
      font-size: 1.1rem !important;
    }
    
    div:has(${SelectStyled}) {
      display: flex;
      width: 22%;
      justify-content: flex-end;
      
      ${SelectStyled} {
        //width: 80%;
        margin-bottom: 0;
      }
    }
  }

  ${FlexStyled}:has(${ButtonStyled}) {
    margin-top: 2rem;
  }
`

const UploadBulkProductModalStyled = styled(FlexColumnStyled)`
  padding-top: 3rem;
  form {
    width: 100%;
  }

  ${SpaceBetweenStyled} {
    margin-top: 3rem;

    ${ButtonStyled}:last-of-type {
      font-family: Light, serif;
      color: ${props => props.theme.black};
      border: 1px solid ${props => props.theme.inputBorder};
      background: white;
    }
    
    ${ButtonStyled} {
      max-width: 40%;
      padding: .8rem;
    }
    
    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      gap: 1.5rem;
      flex-direction: column-reverse;
      
      ${ButtonStyled} {
        max-width: 100%;
      }
    }
  }
`

const ReceiptModalStyled = styled("div")`
  margin: -2.5rem;

  .receipt_action {
    gap: 1rem;
    margin: 1.5rem 0;

    ${ButtonStyled} {
      width: ${props => props.$isWayBill ? "47%" : "100%"}
    }
  }

  .barcode_div {
    max-width: 100%;
  }

  ${LineStyled} {
    margin-top: .5rem;
  }

  ${Paragraph} {
    margin-top: 0 !important;
  }

  ${Heading} {
    margin-bottom: 0 !important;
  }

  ${FlexColumnStyled}.header {
    gap: .4rem;
    padding: .7rem 1.5rem;
    align-items: center;
    text-align: center;
    background-color: #d7d5d5;

    ${Paragraph} {
      padding: 1.4rem 1.4rem;
      border-radius: 50%;
      font-size: 2rem !important;
      background: ${props => props.theme.black};
    }

    ${Heading}.phone {
      font-size: 1rem !important;
    }
  }

  ${LineStyled} {
    margin-bottom: .4rem !important;
  }

  ${SectionStyled} {
    height: fit-content !important;
    padding: 0 1rem 1rem 1.4rem;
    margin-top: .5rem;

    ${Heading} {
      font-size: 1.2rem !important;
    }

    ${Heading}.title {
      font-size: 1.4rem !important;
    }

    ${FlexColumnStyled}.order_details {
      margin-top: .1rem;

      ${SpaceBetweenStyled} {
        //max-height: 16px !important;
        justify-content: flex-start !important;
        flex-direction: row !important;
      }

      ${SpaceBetweenStyled} ${Paragraph} {
        width: calc(50% - .5rem);
      }
    }

    ${FlexColumnStyled}.items_purchased {
      margin-top: .4rem;

      ${Paragraph}:first-of-type {
        width: 45%;
      }

      div.addon {
        ${Heading} {
          margin-left: -.8rem;
          margin-bottom: .5rem;
          font-size: 1rem !important;
        }

        margin-left: 1.5rem;

        ${SpaceBetweenStyled} {
          margin-bottom: .5rem;
        }
      }
    }

    ${Paragraph} {
      //font-family: Medium, serif !important;
      font-size: .9rem;
    }

    ${Paragraph}.section_title {
      //margin-bottom: .3rem;
      font-size: .9rem;
    }

    ${AllCenterStyled}.thank_you_message {
      margin-top: 1rem;

      ${Paragraph} {
        width: 75%;
      }

      text-align: center;
    }

    ${SpaceBetweenStyled}.receipt_actions {
      flex-direction: row;
      justify-content: space-between !important;
      margin-top: 2rem;
      padding-bottom: 1rem;
      max-height: fit-content !important;

      ${ButtonStyled} {
        padding: .9rem;
        width: 45%;
      }

      ${ButtonStyled}:last-of-type {
        color: ${props => props.theme.black};
        border: 1px solid ${props => props.theme.black};
        background: transparent !important;
      }
    }
  }

  ${SpaceBetweenStyled} {
    max-height: 22px !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
  }

  ${SpaceBetweenStyled}#address_div {

    //max-height: fit-content !important;
  }

  ${SpaceBetweenStyled}.product_item {
    max-height: fit-content !important;
  }

  ${SpaceBetweenStyled} ${Paragraph} {
    width: calc(50% - .5rem);
  }

  ${FlexColumnStyled}.summary {
    gap: .1rem;
    margin-top: .4rem;
    text-align: right;

    ${SpaceBetweenStyled}, ${FlexStyled} {
      justify-content: flex-end !important;
    }

    ${SpaceBetweenStyled} ${Paragraph}:last-child {
      width: 32%;
    }
  }

  ${Paragraph}, ${Heading} {
    color: black;
  }


  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    margin: 0;
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }
`

export const ReceiptOrderTableStyled = styled("table")`
  margin-top: .7rem;
  width: 100%;

  tr {
    width: 100%;
  }

  tr:nth-of-type(2) td {
    padding-top: .25rem !important;
  }

  th, td {
    text-align: left;
    border-bottom: 1px solid black;
  }

  tr td:nth-child(2), tr th:nth-child(2) {
    position: relative;
    left: -1.5px;
  }

  tr td:nth-child(3), tr th:nth-child(3) {
    position: relative;
    left: -3px;
  }

  th:first-child, td:first-child {
    width: 50%;
  }

  td:nth-child(2), th:nth-child(2) {
    padding-left: 1.25rem;
    width: 22%;
  }

  th:last-child, td:last-child {
    text-align: right;
    width: 28%;
  }
`


const BarcodeModalStyled = styled(FlexColumnStyled)`
  gap: .2rem;
  padding-top: 2rem;
  align-items: center;

  ${SpaceBetweenStyled} {

    width: 100%;
    margin-top: 1rem;

    ${ButtonStyled} {
      margin-top: .7rem;
      width: 45%;
    }

    ${ButtonStyled}.fullWidth {
      width: 100%;
    }
  }

  .price_tag {
    margin: 1rem 0;
    font-size: 2.5rem !important;
  }
`


export {
  ReceiptModalStyled,
  ModalLayoutStyled,
  PaymentModalStyled,
  AddToCartModalStyled,
  CreateStoreModalStyled,
  CheckOutModalStyled,
  CreateCategoryModalStyled,
  InventoryModalStyled,
  PaymentModalMainStyled,
  BarcodeModalStyled,
  ManageSubscriptionModalStyled,
  UploadBulkProductModalStyled
}

import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Button, Form, Input, Select} from "../../index";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {Link} from "react-router-dom";
import {
  requestMaterialsModifyLogStatus,
  requestMaterialsModifyLogStatusEnum
} from "../../../data/selectField/actionFilters";
import {useFormikContext} from "formik";
import {Fragment, useState} from "react";
import {WIPStatus} from "../../../utils/table";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import {actionsPermissions} from "../../../data/permissions";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";


const RequestRawMaterialLogModal = ({ onClose, handleSubmit, mutation, formValues, handleStartProduction }) => {
  const { dateFormatter, numberFormatter } = useDataTypeFormatter()
  const permission = useActionPermissionFn()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={60} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Request Raw Material Log</Heading>

        <Form values={formValues} onSubmit={() => {}} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Group Request ID: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.group_request_id}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>No. of Products Requested: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.inventory_logs.length)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Requested By: </Paragraph>
            <Link to={`/dashboard/staff/${formValues.created_by}`}>
              <Paragraph color="black" size={1.2}>{formValues.performed_by_name}</Paragraph>
            </Link>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Requested: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.date_created)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth">
            <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
            <Paragraph color="black" size={.8}>{WIPStatus(formValues.group_request_status)}</Paragraph>
          </FlexStyled>
  
          {Object.keys(formValues.requested_products).map(requested_product_key => (
            <SectionStyled className="request_material" key={requested_product_key}>
              <Heading bold color="black">
                {numberFormatter(formValues.requested_products[requested_product_key][0].producing_quantity)} {formValues.requested_products[requested_product_key][0].producible_product_unit_measurement} of {formValues.requested_products[requested_product_key][0].producible_product_name}
              </Heading>
  
              <FlexStyled>
                <Paragraph bold color="black" size={1.2}>Batch Number: </Paragraph>
                <Paragraph color="black" size={1.2}>{formValues.requested_products[requested_product_key][0].tracking_number}</Paragraph>
              </FlexStyled>
              
              <Paragraph color="black" size={1.2}>Remark: {formValues.requested_products[requested_product_key][0].remark}</Paragraph>
              
              
              {formValues.requested_products[requested_product_key].map((inventory, k) => (
                <Fragment key={inventory.id}>
                  <FlexStyled style={{ marginBottom: "0.8rem", marginTop: "1.5rem" }} key={inventory.id}>
                    <Paragraph bold color="black" size={1.2}>Material {k + 1}: </Paragraph>
                    <Paragraph bold color="black" size={1.2}>{inventory.product_name}</Paragraph>
                  </FlexStyled>
  
                  {/*<FlexStyled style={{ marginBottom: "0.8rem" }}>*/}
                  {/*  <Paragraph bold color="black" size={1.2}>Previous Quantity: </Paragraph>*/}
                  {/*  <Paragraph color="black" size={1.2}>{numberFormatter(inventory.previous_qty)}</Paragraph>*/}
                  {/*</FlexStyled>*/}
  
                  <FlexStyled style={{ marginBottom: "0.8rem" }}>
                    <Paragraph color="black" size={1.2}>Current Quantity: </Paragraph>
                    <Paragraph color="black" size={1.2}>
                      {numberFormatter(inventory.current_qty)} {inventory.production_unit_measurement}
                    </Paragraph>
                  </FlexStyled>
  
                  <FlexStyled style={{ marginBottom: "0.8rem", ...(inventory.status === "pending" && { paddingBottom: "1rem" }) }}>
                    <Paragraph color="black" size={1.2}>Requested Quantity: </Paragraph>
                    <Paragraph color="black" size={1.2}>
                      {numberFormatter(inventory.quantity)} {inventory.production_unit_measurement}
                    </Paragraph>
                  </FlexStyled>
  
                  {!!inventory.approved_quantity && (
                    <FlexStyled style={{ marginBottom: "0.8rem" }}>
                      <Paragraph color="black" size={1.2}>Approved Quantity: </Paragraph>
                      <Paragraph color="black" size={1.2}>
                        {numberFormatter(inventory.approved_quantity)} {inventory.production_unit_measurement}
                      </Paragraph>
                    </FlexStyled>
                  )}
  
                  {inventory.status !== "pending" && (
                    <FlexStyled className="fullWidth" style={{ marginBottom: "2rem" }}>
                      <Paragraph color="black" size={1.2}>Status: </Paragraph>
                      <Paragraph color="black" size={.8}>{WIPStatus(inventory.status)}</Paragraph>
                    </FlexStyled>
                  )}
                </Fragment>
              ))}
  
              {((formValues.requested_products[requested_product_key][0].status === "pending") && permission.check(actionsPermissions.approveOrRejectRawMaterial)) && (
                <FlexStyled>
                  <Select
                    noBottomMargin
                    name={`action_type_${requested_product_key}`}
                    options={requestMaterialsModifyLogStatus(
                      `${numberFormatter(formValues.requested_products[requested_product_key][0].producing_quantity)} ${formValues.requested_products[requested_product_key][0].producible_product_unit_measurement} of ${formValues.requested_products[requested_product_key][0].producible_product_name}`,
                    )}
                    displayKey="name"
                    valueKey="value"
                    label="Select Action"
                    placeholder="Select action for request"
                    displayName={`action_type_${requested_product_key}_name`}
                  />
                  
                  <ModifyQuantityOfRequest product={formValues.requested_products[requested_product_key][0]} inventoryId={requested_product_key} />
                  <ProductRequestSummaryAndSubmission
                    raw_materials={formValues.requested_products[requested_product_key]}
                    productId={requested_product_key}
                    handleSubmit={handleSubmit}
                    mutation={mutation}
                  />
                </FlexStyled>
              )}
  
              {(formValues.requested_products[requested_product_key][0].status === "confirmed") && (
                <Button
                  type="button"
                  className="fullWidth"
                  text={`Start Production`}
                  permissionCode={actionsPermissions.startProduction}
                  onClick={() => handleStartProduction(formValues.requested_products[requested_product_key][0])}
                />
              )}
            </SectionStyled>
          ))}
          
          
          {!!formValues?.staff_action_name && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Action By: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.staff_action_name || ""}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled> </FlexStyled>
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

const ModifyQuantityOfRequest = ({ inventoryId, product }) => {
  const { values } = useFormikContext()
  const { numberFormatter } = useDataTypeFormatter()
  
  return (
    ((!!values[`action_type_${inventoryId}`] && (values[`action_type_${inventoryId}`] !== "reject_request"))) && (
      <Input
        disabled={values[`action_type_${inventoryId}`] === "approve_request"}
        noBottomMargin
        name={`approved_quantity_${inventoryId}`}
        formatNumber
        placeholder={values[`action_type_${inventoryId}`] === "approve_request" ? `${numberFormatter(product.producing_quantity)}` : "Enter quantity"}
        label={`Quantity of ${product.producible_product_name} to produce`}
        clearCondition={`action_type_${inventoryId}`}
      />
    )
  )
}

const ProductRequestSummaryAndSubmission = ({ productId, raw_materials = [], handleSubmit, mutation }) => {
  const { values } = useFormikContext()
  const { numberFormatter } = useDataTypeFormatter()
  const [rejectedRawMaterials, setRejectedRawMaterials] = useState([])
  
  const getRawMaterialQuantity = (material) => {
    const approvedQuantity = Number(values[`approved_quantity_${productId}`])
    if(!approvedQuantity) return Number(material.quantity)
    
    return approvedQuantity * Number(material.base_quantity)
  }
  
  const handleSubmitProductRawMaterialRequest = () => {
    const approval_data = raw_materials.map(material => {
      return {
        log_id: material.id,
        approved_quantity: getRawMaterialQuantity(material),
        producing_quantity: Number(values[`approved_quantity_${productId}`]) || Number(material.producing_quantity),
        status: rejectedRawMaterials.includes(material.id) ? requestMaterialsModifyLogStatusEnum.reject_request : requestMaterialsModifyLogStatusEnum[values[`action_type_${productId}`]] ,
      }
    })

    handleSubmit({ approval_data })
  }
  
  const handleRejectAndApproveRawMaterial = (materialID) => {
    if(rejectedRawMaterials.includes(materialID)) {
      const filterOut = rejectedRawMaterials.filter(material => material !== materialID)
      setRejectedRawMaterials(filterOut)
      return
    }
    
    setRejectedRawMaterials([...rejectedRawMaterials, materialID])
  }
  
  return (
    <Fragment>
      {values[`action_type_${productId}`] && (
        <ContainerStyled className="fullWidth request_summary">
          {raw_materials.map((material) => (
            <SpaceBetweenStyled key={material.id}>
              <Paragraph color="black" $strike={rejectedRawMaterials.includes(material.id) || (values[`action_type_${productId}`] === "reject_request")}>
                {numberFormatter(getRawMaterialQuantity(material))} {material.production_unit_measurement} of {material.product_name}
              </Paragraph>
  
              {values[`action_type_${productId}`] !== "reject_request" && (
                <Button
                  onClick={() => handleRejectAndApproveRawMaterial(material.id)}
                  type="button"
                  text={rejectedRawMaterials.includes(material.id) ? "Approve" : "Reject"}
                  bgColor={rejectedRawMaterials.includes(material.id) ? "success" : "warning"}
                  small
                />
              )}
            </SpaceBetweenStyled>
          ))}
    
          <Button
            onClick={handleSubmitProductRawMaterialRequest}
            type="button"
            className="fullWidth"
            text="Submit"
            permissionCode={actionsPermissions.approveOrRejectRawMaterial}
            isLoading={mutation.isLoading}
          />
        </ContainerStyled>
      )}
    </Fragment>
  )
}


export default RequestRawMaterialLogModal
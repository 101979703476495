import {SubscriptionCardStyled} from "../../styles/subscriptionCardStyles";
import {FlexColumnStyled, FlexRowStyled} from "../../styles/subscriptionCardStyles";
import {Heading4, Paragraph} from "../../styles/subscriptionCardStyles";
import {Button} from "../../styles/subscriptionCardStyles";
import { AiFillStar } from "react-icons/ai";
import {useTheme} from "styled-components";
import { basic, premium, starter } from "../../assets/images"
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {Fragment} from "react";
import {Heading} from "../../styles/textStyles";

const images = { basic, premium, starter }


export default function PlanCard({ data, period, selectSubscription }) {
	const theme = useTheme()
	const { numberFormatter, nairaCurrencyFormatter } = useDataTypeFormatter()
	const { name = "", id, max_store, max_user, max_products, description, amount, modules, monthly_amount, package_addon } = data
	
	const handleSelectPackage = () => {
		selectSubscription(id, period)
	}
	
	const Item = ({ title }) => (
		<FlexRowStyled $gap={.5}>
			<AiFillStar color={theme.gold} />
			<Paragraph>{title}</Paragraph>
		</FlexRowStyled>
	)
	
	return (
		<SubscriptionCardStyled>
			<FlexRowStyled className="header">
				<img src={images[name.toLowerCase()]} width={30} height={30} alt={name} />
				<FlexColumnStyled $gap={.5}>
					<Heading4 $noMargin>{name.toUpperCase()}</Heading4>
					<Paragraph $noMargin $color="gray">{description}</Paragraph>
				</FlexColumnStyled>
			</FlexRowStyled>

			<FlexColumnStyled className="subscription_summary" $gap={.2}>
				<Heading color="deepBlack">
					{amount < 1 ? "Free Plan" :
						`${nairaCurrencyFormatter(period === "year" ? amount : monthly_amount)}/${period}`
					}
				</Heading>
				{(period === "year" && amount > 1) && (
					<Paragraph $color="gray" $size={.8} className="discount">
						{Number(amount) === 100000 ? nairaCurrencyFormatter("20000") : nairaCurrencyFormatter("5000")} off
					</Paragraph>
				)}
			</FlexColumnStyled>

			<Button large onClick={handleSelectPackage}>Select package</Button>

			<FlexColumnStyled className="features">
				{amount < 1 && (
					<Fragment>
						<Item title={`${numberFormatter(max_store)} store`} />
						<Item title={`${numberFormatter(max_user)} user `} />
						<Item title={`${numberFormatter(max_products)} products`} />
					</Fragment>
				)}

				{amount > 1 && (
					<Fragment>
						<Item title={`${numberFormatter(max_store)} store (${nairaCurrencyFormatter(package_addon.extra_store)}/extra store)`} />
						<Item title={`${numberFormatter(max_user)} user (${nairaCurrencyFormatter(package_addon.extra_user)}/extra user) `} />
						<Item title="Unlimited products" />
					</Fragment>
				)}

				{modules?.map(features => (
					<FlexRowStyled $gap={.5} key={features}>
						<AiFillStar color={theme.gold} />
						<Paragraph>{features}</Paragraph>
					</FlexRowStyled>
				))}
			</FlexColumnStyled>
		</SubscriptionCardStyled>
	)
}
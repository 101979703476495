import { useState } from "react"
import {useLocation} from "react-router-dom";
import useGlobalContext from "./useContexts/useGlobalContext";


const shouldModalBeActive = (modalConnector, modalNameOrState, pathname) => {
  if(typeof modalNameOrState === "boolean") return modalNameOrState

  if((modalConnector.fromModalURL === pathname) && (modalConnector.fromModalName === modalNameOrState)) {
    return true
  }

  if((modalConnector.toModalURL === pathname) && (modalConnector.toModalName === modalNameOrState)) {
    return true
  }
}


// modalNameOrState: this argument is either the name of the modal or the state of the modal
// If the type is a boolean that means the state is being passed, else the name is passed

const useModal = (modalNameOrState) => {
  const { globalState: { modalConnector } } = useGlobalContext()
  const { pathname } = useLocation()

  const [showModal, setShowModal] = useState(!!shouldModalBeActive(modalConnector, modalNameOrState, pathname))

  const handleModal = () => setShowModal(!showModal)
  return [showModal, handleModal]
}

export default useModal
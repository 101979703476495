import {getModifyOrderGroupUUID, getModifyOrderToLocalStorage} from "../utils/sell";
import {formatDatetimeForBackend} from "../utils/desktopUtils/desktopSyncUtils";

class CreateOrderModel {
  orders = []
  deletedOrders = []
  
  /**
   * @constructor
   * @param {Array} cart - An array of all products/orders objects to be sold.
   * @param {Object} values - An object of all formik values from the cart & checkout component
   * @param {Object} globalContext - React global context
   * @param {Function} calculationForSingleOrderFn - Function that calculates values for each item in the order, gotten from useCartCalculation
   * */
  constructor(cart, values, globalContext, calculationForSingleOrderFn) {
    this.cart = cart
    this.values = values
    this.globalContext = globalContext
    this.calculationForSingleOrderFn = calculationForSingleOrderFn
  }
  
  #convertNumberToTwoDecimal(number) {
    return Number(Number(number).toFixed(2))
  }
  
  formatFormValuesForDiscount(checkoutDiscountInAmount) {
    if (!!this.values?.discount_type && Number(checkoutDiscountInAmount || 0) > 0) {
      if (this.values.discount_type === "percentage") {
        this.values.discount = {type: "percentage", value: this.values.discount_value}
        this.values.checkout_discount = this.values.discount
      } else {
        // if checkout discount type is in amount, convert it to percentage
        const overallCalculationAfterDiscount = this.values.overallCalculationValue
        const overallCalculationBeforeDiscount = overallCalculationAfterDiscount + Number(checkoutDiscountInAmount)
        
        const getDiscountInPercentage = ((overallCalculationBeforeDiscount - overallCalculationAfterDiscount) / overallCalculationBeforeDiscount) * 100
        
        this.values.discount = {type: "percentage", value: getDiscountInPercentage}
        this.values.checkout_discount = this.values.discount
      }
      
      delete this.values?.discount_type
      delete this.values?.discount_value
      delete this.values?.discount_type_name
    }
  }
  
  formatFormValuesForSplitOrPartialPaymentMethod() {
    if (this.values.pay_mode !== "split" && this.values.pay_mode !== "partial") {
      delete this.values.split_payment
      return
    }
    
    this.values.split_payment = [
      {method: "cash", amount: Number(this.values.split_cash_value || 0)},
      {method: "pos", amount: Number(this.values.split_pos_value || 0), bank: this.values.split_pos_bank},
      {method: "transfer", amount: Number(this.values.split_transfer_value || 0), bank: this.values.split_transfer_bank}
    ]
  }
  
  verifySplitAmountIsEqualToPayableAmount() {
    if (this.values.pay_mode === "split" && this.values.searchParamType !== "modify_order") {
      const total = Number(this.values.overallCalculationValue).toFixed(2)
      
      if (Number(this.values.splitOrPartialPaymentAmount).toFixed(2) !== total) {
        throw new Error("Split amount must match total payable")
      }
    }
  }
  
  verifyPartialAmountIsLessThenPayable() {
    if (this.values.pay_mode === "partial") {
      const total = Number(Number(this.values.overallCalculationValue).toFixed(2))
      
      if (Number(Number(this.values.splitOrPartialPaymentAmount).toFixed(2)) > total) {
        throw new Error("Partial amount cannot be greater than total payable")
      }
    }
  }
  
  validateOrderData() {
    this.verifyPartialAmountIsLessThenPayable()
    this.verifySplitAmountIsEqualToPayableAmount()
  }
  
  isModifyGroupOrder() {
    const modifyGroupOrderUUID = getModifyOrderGroupUUID()
    const groupOrderToModifyArr = getModifyOrderToLocalStorage()
    
    return (!!groupOrderToModifyArr && !!modifyGroupOrderUUID && this.cart.length > 0)
  }
  
  setDeletedOrdersFromGroupOrderWhenModifying() {
    if (!this.isModifyGroupOrder()) return []
    
    const allIdsOfOrdersInCart = this.cart.map(order => order.order_id)
    
    this.deletedOrders = getModifyOrderToLocalStorage().reduce((arr, SingleOrderInGroup) => {
      // if an item in the modify group order still exists in cart, pass
      if (allIdsOfOrdersInCart.includes(SingleOrderInGroup.order_id)) return arr
      
      arr.push({
        ...SingleOrderInGroup,
        action: "delete",
        return_type: "order",
        product: SingleOrderInGroup.id,
        order_uuid: SingleOrderInGroup.order_id,
        meta_measurement: SingleOrderInGroup?.selected_unit_measurement || null
      })
      return arr
    }, [])
    
    return this.deletedOrders
  }
  
  formatSingleOrderMetaMeasurementValue(singleOrder) {
    if (!singleOrder.selected_unit_measurement) return {meta_measurement: null}
    
    return {
      unit: 1,
      meta_measurement: singleOrder.selected_unit_measurement,
      // convert order qty from measurement quantity to base quantity
      qty: (Number(singleOrder.selected_unit_measurement.quantity) * Number(singleOrder.selected_unit_measurement.base_quantity)),
      ...(singleOrder.sales_price && {sales_price: this.#convertNumberToTwoDecimal(singleOrder.sales_price)})
    }
  }
  
  formatSingleOrderForModifyOrderValues(singleOrder) {
    if (!this.isModifyGroupOrder()) return {}
    
    return {
      action: "edit",
      return_type: "quantity",
      order_uuid: singleOrder.order_id,
      group_order_id: getModifyOrderGroupUUID()
    }
  }
  
  formatSingleOrderForOfflineSales({singleOrder, staff, customer, business, checkoutDiscountInAmount}) {
    if (!this.globalContext.isOffline) return {order_id: null}
    
    const singleOrderCalculation = this.calculationForSingleOrderFn(singleOrder)
    
    // business details for order receipt display
    const {phone, address, store_name, receipt_message, phone2 = "", tagline = ""} = business || {}
    
    const getSingleOrderDiscount = () => {
      if (singleOrderCalculation.discountAmount) return singleOrderCalculation.discountAmount
      if (checkoutDiscountInAmount) {
        return Number(((Number(singleOrderCalculation.productTotalValue) * Number(this.values.checkout_discount.value)) / 100).toFixed(2))
      }
      return 0
    }
    
    const getSingleOrderTotalCost = () => {
      if (!checkoutDiscountInAmount) return singleOrderCalculation.productTotalValue
      return Number(singleOrderCalculation.productTotalValue) - getSingleOrderDiscount()
    }
    
    return {
      date_created: new Date(),
      staff_id: `${staff?.[0]?.id}`,
      account_id: staff?.[0]?.account,
      discount_amount: getSingleOrderDiscount(),
      tax_amount: singleOrderCalculation.taxAmount,
      unit_cost: Number(singleOrder.selling_price),
      service_cost: singleOrderCalculation.serviceAmount,
      total_cost: getSingleOrderTotalCost(),
      all_product_total_cost: this.values.overallCalculationValue,
      staff_name: `${staff?.[0]?.first_name} ${staff?.[0]?.last_name}`,
      product_cost_before_tax: singleOrderCalculation.productOriginalCost,
      business_details: {phone, address, store_name, receipt_message, phone2, tagline},
      selected_customer: !!customer?.id ? customer : (this.cart[0]?.selected_customer || {}),
    }
  }
  
  structureOrderForSubmission({staff, customer, business, checkoutDiscountInAmount}) {
    const newCart = [...this.cart]
    
    this.validateOrderData()
    this.formatFormValuesForDiscount(checkoutDiscountInAmount)
    this.setDeletedOrdersFromGroupOrderWhenModifying()
    this.formatFormValuesForSplitOrPartialPaymentMethod()
    
    this.orders = newCart.map(item => {
      const modifyValues = this.formatSingleOrderForModifyOrderValues(item)
      const metaMeasurementValues = this.formatSingleOrderMetaMeasurementValue(item)
      const offlineSaleValues = this.formatSingleOrderForOfflineSales({
        singleOrder: item, customer, staff, business, checkoutDiscountInAmount
      })
  
      if (Number(item.unit) <= 0) {
        throw new Error("You can not sell below or a quantity of 0")
      }
  
      const order = {
        ...item,
        ...this.values,
        qty: item.unit,
        product: item.id,
        set_for_delivery: !!this.values.delivery,
        business: this.globalContext.getBusinessId(),
        service: item?.service?.map(service => ({...service, amount: service?.cost})),
        ...(!!this.globalContext.globalState.tableId && {table_id: this.globalContext.globalState.tableId}),
  
        ...modifyValues,
        ...offlineSaleValues,
        ...metaMeasurementValues,
        ...(this.values.table_staff_id && {staff_id: this.values.table_staff_id}),
        ...(!!this.values.date_created && {date_created: formatDatetimeForBackend(this.values.date_created)})
      }
  
      if (!this.isModifyGroupOrder()) delete order.id
      return order
    })
  }
  
  getOrderForSubmission() {
    return [...this.orders, ...this.deletedOrders]
  }
  
}


export default CreateOrderModel
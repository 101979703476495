import {
  CreateAddOnModal, EditAddOnModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateAddOnMutation,
  useDeleteAddOnMutation,
  useEditAddOnMutation,
  useAddOnQuery
} from "../../../../hooks/useServices/useAddOnServices";
import {NoExpensesImage} from "../../../../assets/images";
import {SetUpLayoutStyled, SetUpMainStyled} from "../SetUpStyles";
import {addOnFormDefaultValues} from "../../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../../data/permissions";
import generateId from "react-uuid";
import queryKeys from "../../../../hooks/useServices/queryKeys";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../../desktop/sendDesktopNotification";


const AddOns = () => {
  const queryClient = useQueryClient()
  const { toast, navigate } = useGlobalContext()
  const [showEditAddOnModal, setShowEditAddOnModal] = useModal()
  const [showCreateAddOnModal, setShowCreateAddOnModal] = useModal()

  const {
    createService: createServicePermission,
    updateService: updateServicePermission,
    deleteService: deleteServicePermission
  } = actionsPermissions

  const { addOnTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allAddOns } = useSearchQuery(debounceState, useAddOnQuery)
  const [editTaxFormValues, setEditAddOnFormValues] = useState(addOnFormDefaultValues)
  const deleteAddOnMutation = useDeleteAddOnMutation({ successFn: () => {} })
  const editAddOnMutation = useEditAddOnMutation({ successFn: setShowEditAddOnModal })
  const createAddOnMutation = useCreateAddOnMutation({ successFn: setShowCreateAddOnModal })

  const handleCreateAddOn = (values) => {
    createAddOnMutation.mutate(values)
  }

  const handleEditAddOn = (values) => {
    editAddOnMutation.mutate(values)
  }

  const handleEditAction = (addOn) => {
    setEditAddOnFormValues(addOn)
    setShowEditAddOnModal()
  }

  const handleDeleteAction = (addOn) => {
    toast.confirm(
      "Are you sure?",
      deleteAddOnMutation.mutate.bind(this, addOn.id),
      generateId()
    )
  }

  const handleViewAddOn = (addon) => {
    navigate(`/dashboard/set-up/stages/${addon.name}/${addon.id}`)
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.addon }).then(() => {
      queryClient.refetchQueries({ queryKey: desktopQueryKeys.stage }).then(() => {
        sendDesktopNotification({
          title: "Sync successful",
          body: "All addons has been synced offline"
        })
      })
    })
  }

  return (
    <SetUpMainStyled>
      {showCreateAddOnModal && <CreateAddOnModal onClose={setShowCreateAddOnModal} handleSubmit={handleCreateAddOn} mutation={createAddOnMutation} />}
      {showEditAddOnModal && <EditAddOnModal onClose={setShowEditAddOnModal} handleSubmit={handleEditAddOn} mutation={editAddOnMutation} formValues={editTaxFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allAddOns.isEmpty && (
          <Fragment>
            <PageHeader title="Add Ons" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoExpensesImage}
              heading="No add on yet"
              subHeading="Add services that can be attached or applied to products."
              buttonText="Add new addon"
              buttonClick={setShowCreateAddOnModal}
              permissionCode={createServicePermission}
            />
          </Fragment>
        )}

        {allAddOns.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Add-On List"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateAddOnModal}
              permissionCode={createServicePermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "addons", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search add-on by name"
              />

              <Table headers={addOnTable.headers} noCols={addOnTable.headers.length} equalWidth lastHeaderPosition="flex-end">
                {allAddOns.isQueryWithData && (
                  allAddOns.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allAddOns.currentCount}
                      keys={addOnTable.values}
                      actionPosition="flex-end"
                      onClick={handleViewAddOn}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateServicePermission}
                      deletePermission={deleteServicePermission}
                    />
                  )
                )}

                {allAddOns.isQueryWithNoData && (
                  <NoQueryData text={allAddOns.isSearchEmptyMessage("Add On", "")} />
                )}

                {allAddOns.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allAddOns.showPagination && (
                <TablePagination
                  query={allAddOns}
                  queryKey={[queryKeys.ADDONS, debounceState.searchValue ]}
                />
              )}
            </SetUpLayoutStyled>
            
            {!!allAddOns.showPagination && (
              <TablePagination
                query={allAddOns}
                queryKey={[queryKeys.ADDONS, debounceState.searchValue]}
              />
            )}
          </Fragment>
        )}

      </Fragment>
    </SetUpMainStyled>
  )
}

export default AddOns
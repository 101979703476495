import uuid from "react-uuid";

export const placeOrderOnHold = (cartItem = []) => {
  const cart = { key: uuid(), orders: cartItem }
  const existingOrdersOnHold = localStorage.getItem("ovaloop_orders_on_hold")
  
  if(!existingOrdersOnHold) {
    const orders = [cart]
    localStorage.setItem("ovaloop_orders_on_hold", JSON.stringify(orders))
    return
  }
  
  const allOrders = [...JSON.parse(existingOrdersOnHold), cart]
  localStorage.setItem("ovaloop_orders_on_hold", JSON.stringify(allOrders))
}

export const getAllOrdersOnHold = () => {
  const existingOrdersOnHold = localStorage.getItem("ovaloop_orders_on_hold")
  return JSON.parse(existingOrdersOnHold)
}

export const getOrderOnHold = (orderKey) => {
  const existingOrdersOnHold = localStorage.getItem("ovaloop_orders_on_hold")
  
  if(!existingOrdersOnHold) return []
  
  return (JSON.parse(existingOrdersOnHold).find(order => order.key === orderKey)).orders
}

export const replaceOrderOnHold = (cart, id) => {
  const existingOrdersOnHold = localStorage.getItem("ovaloop_orders_on_hold")
  if(!existingOrdersOnHold) return []
  
  const filterOutOrder = JSON.parse(existingOrdersOnHold).filter(order => order.key !== id)
  
  const allOrders = [...filterOutOrder, { key: uuid(), orders: cart }]
  localStorage.setItem("ovaloop_orders_on_hold", JSON.stringify(allOrders))
}

export const removeOrderOnHold = (id) => {
  const existingOrdersOnHold = localStorage.getItem("ovaloop_orders_on_hold")
  if (!existingOrdersOnHold) return []
  
  const filterOutOrder = JSON.parse(existingOrdersOnHold).filter(order => order.key !== id)
  localStorage.setItem("ovaloop_orders_on_hold", JSON.stringify(filterOutOrder))
}


export const getHeldPurchaseOrders = () => {
  const heldOrders = localStorage.getItem("held_purchase_orders")
  if (!heldOrders) return []
  return JSON.parse(heldOrders).reverse()
}


export const handleHoldPurchaseOrder = (newOrder) => {
  if (localStorage.getItem("held_purchase_orders")) {
    const heldOrders = JSON.parse(localStorage.getItem("held_purchase_orders"))
    
    const newHeldOrders = heldOrders.filter(order => order.hold_purchase_id !== newOrder.hold_purchase_id)
    localStorage.setItem("held_purchase_orders", JSON.stringify([...newHeldOrders, newOrder]))
    return
  }
  
  localStorage.setItem("held_purchase_orders", JSON.stringify([newOrder]))
}


export const handleDeleteHeldPurchaseOrder = (purchaseId) => {
  if (!purchaseId) return
  
  if (localStorage.getItem("held_purchase_orders")) {
    const heldOrders = JSON.parse(localStorage.getItem("held_purchase_orders"))
    const newOrders = heldOrders.filter(order => order.hold_purchase_id !== purchaseId)
    
    localStorage.setItem("held_purchase_orders", JSON.stringify(newOrders))
  }
}
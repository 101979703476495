import {WalletDetailsCardStyled} from "../../styles/cardStyles";
import {FlexStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import {Fragment} from "react";
import {WIPStatus} from "../../utils/table";
import {Copy} from "iconsax-react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {BsFillInfoCircleFill} from "react-icons/bs";
import {Tooltip} from "react-tooltip";


const WalletDetailsCard = ({ settlement_account }) => {
  const { toast } = useGlobalContext()
  
  const handleCopyToClipBoard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success("Account number copied"))
      .catch()
  }
  
  return (
    <Fragment>
      <WalletDetailsCardStyled>
        <FlexStyled>
          <Heading color="black" size={1.3}>Settlement Account</Heading>
          
          <Paragraph color="black" >Account Name: {settlement_account.account_name}</Paragraph>
          <Paragraph color="black" noTop>Bank Name: {settlement_account.bank_name}</Paragraph>
          <Paragraph color="black" noTop>
            Account Number: {settlement_account.account_number} <Copy color="black" size={18} variant="Bold" onClick={() => handleCopyToClipBoard(settlement_account.account_number)} />
          </Paragraph>
  
          <FlexStyled $gap={.4}>
            <Heading size={1} color="black">Status:</Heading>
            {WIPStatus(settlement_account.status === "pending" ? "pending_verification" : settlement_account.status)}

            {settlement_account.status === "rejected" && (
              <BsFillInfoCircleFill id="settlement_error" color="red" size={18} variant="Bold" />
            )}
  
            <Tooltip
              anchorId="settlement_error"
              place="top" variant="dark"
              content={settlement_account.remark}
            />
          
          </FlexStyled>
        </FlexStyled>
      </WalletDetailsCardStyled>
  
      {/*<WalletDetailsCardStyled>*/}
      {/*  <FlexStyled>*/}
      {/*    <Paragraph color="black" bold noTop>{title}</Paragraph>*/}
      {/*    <Heading color="black" size={2}>{value}</Heading>*/}
      {/*  </FlexStyled>*/}
      {/*  /!*<Image src={icon} alt={title} width={18} />*!/*/}
      {/*</WalletDetailsCardStyled>*/}
    </Fragment>
  )
}

export default WalletDetailsCard
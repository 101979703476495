import {Fragment, useEffect} from "react";
import {useCategoriesQuery} from "../../../hooks/useServices/useCategoryServices";
import {AddToCartModal, CategoryCard, NoDataLayout, ProductCard, ProductCard2, SellBar} from "../../../components";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import useCategoryBasedQuery, {debounceActions} from "../../../hooks/useUtils/useCategoryBasedQuery";
import {useSellableProductForCategoryBasedQuery} from "../../../hooks/useServices/useProductServices";
import {CategoryBasedLayoutStyled} from "../../../styles/sellStyles";
import {Heading2, Paragraph} from "../../../styles/textStyles";
import {useSubCategoriesByCategoryIdQuery} from "../../../hooks/useServices/useSubCategoryServices";
import {useNestedSubCategoriesQuery} from "../../../hooks/useServices/useNestedSubCategoryServices";
import {EmptyBoxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useModal} from "../../../hooks";
import {generateRandomIndexForColor} from "../../../utils";
import {RiLayoutColumnLine, RiLayoutGridLine} from "react-icons/ri";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {sellReducerActions} from "../../../reducers/sellReducer";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import useSellContext from "../../../hooks/useContexts/useSellContext";


const CategoryBasedLayout = () => {
  const { navigate, toast } = useGlobalContext()
  const businessSettings = useBusinessSettingQuery()
  const {reducer, selectedCustomer} = useSellContext()
  
  const [showAllProducts, setShowAllProducts] = useModal()
  const [isLayoutChange, setIsLayoutChange] = useModal()
  const [addToCartModal, setAddToCartModal] = useModal()
  
  
  const { data: allCategories, isSuccess: isCategoriesSuccess, isLoading: isCategoryLoading } = useCategoriesQuery()
  const { debounceState, debounceReducer, breadCrumbTrail } = useCategoryBasedQuery()
  
  
  const { data: allSubCategories, isSuccess: isSubCategoriesSuccess } = useSubCategoriesByCategoryIdQuery(debounceState.category_id)
  const { data: nestedSubCategory, isSuccess: isNestedSubCategoriesSuccess } = useNestedSubCategoriesQuery({
    level: breadCrumbTrail.length - 2,
    ...((breadCrumbTrail.length - 2) === 1 ? { sub_category: debounceState.sub_category_id } : { parent: debounceState[`nested_category_${breadCrumbTrail.length - 3}_id`] })
  })
  
  const { mainQuery, ...allProducts } = useOrderQueryWithSearchAndFilter({ ...debounceState, type: "general_product" }, useSellableProductForCategoryBasedQuery)
  
  useEffect(() => {
    if(allProducts?.data?.results?.length === 1 && debounceState.searchValue === allProducts?.data?.results?.[0]?.sku) {
      const product = allProducts?.data?.results?.[0]
      if(!businessSettings?.sell_below_stock && Number(allProducts?.data?.results?.[0]?.stock_unit) < 1 && product.use_stock) {
        toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
        return
      }
      
      reducer({
        product: {
          ...product,
          unit: Number(product.unit_increment) || 1,
          ...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
            applied_discount: selectedCustomer?.customer_discount,
            previous_applied_discount: product?.applied_discount
          })
        },
        type: sellReducerActions.ADD_PRODUCT_TO_CART,
      })
      
      debounceReducer({
        type: debounceActions.CLEAR_ALL_FILTERS
      })
    }
  }, [allProducts.data?.results, debounceState.searchValue])
  
  const handleSelectCategories = (category) => {
    const isUpdated = breadCrumbTrail.addToTrail(category)
    if(isUpdated) {
      debounceReducer({
        type: debounceActions[String(breadCrumbTrail.length)],
        id: category.id
      })
    }
  }
  
  const handleGoToTrail = (trail) => {
    const newTrail = breadCrumbTrail.goToTrail(trail.id)
    
    debounceReducer({
      type: newTrail.length === 1 ? debounceActions.CLEAR_ALL_FILTERS : debounceActions[String(newTrail.length-1)],
      id: trail.id
    })
  }
  
  const getCategoriesToShow = () => {
    if(breadCrumbTrail.length === 1) {
      return allCategories
    }
    
    if(breadCrumbTrail.length === 2) {
      return allSubCategories
    }
    
    return nestedSubCategory
  }
  
  const getIsSuccessToUse = () => {
    if(breadCrumbTrail.length === 1) {
      return isCategoriesSuccess
    }
  
    if(breadCrumbTrail.length === 2) {
      return isSubCategoriesSuccess
    }
  
    return isNestedSubCategoriesSuccess
  }
  
  return (
    <Fragment>
      {addToCartModal && <AddToCartModal onClose={setAddToCartModal} />}
  
      <SellBar debounceReducer={debounceReducer} debounceState={debounceState} />

      <CategoryBasedLayoutStyled>
        <Heading2 color="black">All Categories</Heading2>

        <SpaceBetweenStyled>
          <FlexStyled $gap={.7}>
            {breadCrumbTrail.trail.map(trail => (
              <Paragraph
                color="black"
                key={trail.id}
                onClick={() => handleGoToTrail(trail)}>
                /<u> {trail.name}</u>
              </Paragraph>
            ))}
          </FlexStyled>
          
          <Paragraph color="black" onClick={setShowAllProducts}>
            <u>{showAllProducts ? "Hide" : "Show"} products: {breadCrumbTrail.trail[breadCrumbTrail.length - 1].name}</u>
          </Paragraph>
        </SpaceBetweenStyled>
        <br/>
  
        {showAllProducts && (
          <Fragment>
            <SpaceBetweenStyled className="layout_switch">
              <p></p>
              <FlexStyled>
                <RiLayoutGridLine
                  size={28}
                  onClick={setIsLayoutChange}
                  className={!isLayoutChange ? "active" : ""}
                />
                <RiLayoutColumnLine
                  size={28}
                  onClick={setIsLayoutChange}
                  className={isLayoutChange ? "active" : ""}
                />
              </FlexStyled>
            </SpaceBetweenStyled>
            <br/>
          </Fragment>
        )}
        
        {!showAllProducts && (
          <FlexStyled>
            {isCategoryLoading && (
              <Fragment>
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
                <TableSkeleton count={1} width="185px" height={75} noTop />
              </Fragment>
            )}
            
            {
              isCategoriesSuccess && getCategoriesToShow()?.results?.map((item, k) => (
                <CategoryCard
                  randomColor
                  big
                  active={debounceState.filterValue === item.id}
                  key={k}
                  colorIndex={generateRandomIndexForColor(3)}
                  noOfItems={2}
                  title={item.name}
                  onClick={() => handleSelectCategories(item)}
                />
              ))
            }
          </FlexStyled>
        )}
  
        {((getIsSuccessToUse() && getCategoriesToShow()?.results?.length < 1) || showAllProducts) && (
          <Fragment>
            <FlexStyled className="products">
              {allProducts.isQueryWithData && (
                allProducts?.data?.results.map(item => {
                  return isLayoutChange ? (
                    <ProductCard2
                      key={item.id}
                      product={item}
                      setAddToCartModal={setAddToCartModal}
                    />
                  ) : (
                    <ProductCard
                      key={item.id}
                      product={item}
                      setAddToCartModal={setAddToCartModal}
                    />
                  )
                })
              )}
        
              {allProducts.isQueryWithNoData && (
                <NoDataLayout
                  imgWidth={12}
                  imgAlt="No product"
                  imgSrc={EmptyBoxImage}
                  heading="No product yet"
                  subHeading="You have no items yet, start updating your stock"
                  buttonText="Add new product"
                  buttonClick={() => navigate("/dashboard/inventory/product")}
                  // permissionCode={createProductPermission}
                />
              )}
            </FlexStyled>
          </Fragment>
        )}
      </CategoryBasedLayoutStyled>
    </Fragment>
  )
}


export default CategoryBasedLayout
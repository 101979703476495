import {
	NoDataLayout,
	PageHeader, PageHeaderWithButtonAndDateFilter,
	ScreenLoading, SearchAndFilter,
	Table,
	TableItemWithStatus, TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import { NoLogsImage } from "../../../assets/images";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
	useInventoryByExpiryQuery, useUpdateProductExpiryInventoryMutation,
} from "../../../hooks/useServices/useInventoryServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import inventoryQueryKey from "../../../hooks/useServices/useInventoryServices/inventoryQueryKey";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {dateFilterValue, dateFilterValueEnum, expiryDateFilter} from "../../../data/selectField/dateFilter";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const ExpiryProducts = () => {
	const { toast } = useGlobalContext()
	const { expiryProductTable } = tableHeadersAndValueKeys
	const { debounceState, debounceReducer } = useQueryDebounce()
	
	const { toDate, fromDate } = handleDateFilterValue(dateFilterValue.ALL_TIME)
	const [dateFilter, setDateFilter] = useState({ from_date: fromDate, to_date: toDate })
	const { mainQuery, ...allExpiryProducts } = useOrderQueryWithSearchAndFilter({ ...dateFilter, search: debounceState.searchValue }, useInventoryByExpiryQuery)

	const updateProductExpiryInventoryMutation = useUpdateProductExpiryInventoryMutation()

	const handleTurnOnNotification = (data) => {
		toast.confirm(
			`Are you sure you want to turn ${!!data.notify_expiry ? "off" : "on"} notification for this expiry?`,
			() => updateProductExpiryInventoryMutation.mutate({ notify_expiry: !data.notify_expiry, id: data.id }),
			"confirm"
		)
	}

	return (
		<div>
			{!!mainQuery.isError && (
				<ScreenLoading refetchFn={allExpiryProducts.refetch} />
			)}

			<Fragment>
				{allExpiryProducts.isEmpty && (
					<Fragment>
						<PageHeader title="Expiring Products" />
						<NoDataLayout
							imgWidth={45}
							imgAlt="No product"
							imgSrc={NoLogsImage}
							heading="No logs yet"
							subHeading="No products with expiry date yet"
						/>
					</Fragment>
				)}

				{allExpiryProducts.showTable && (
					<Fragment>
						<PageHeaderWithButtonAndDateFilter
							setDateFilter={setDateFilter}
							title="Expiring Products"
							dateFilterOptions={expiryDateFilter}
							dateFilterDefaultValue={dateFilterValueEnum.ALL_TIME}
						/>

						<SetUpLayoutStyled>
							<SearchAndFilter
								noFilter
								dispatch={debounceReducer}
								searchPlaceholder="Search products by name"
							/>

							<Table bg headers={expiryProductTable.headers} equalWidth noCols={expiryProductTable.headers.length}>
								{allExpiryProducts.isQueryWithData && (
									allExpiryProducts?.data?.results.map((items, k) =>
										<TableItemWithStatus
											isSwitchActive={items.notify_expiry}
											isSwitchAction
											count={k}
											pageCount={allExpiryProducts.currentCount}
											key={k}
											data={items}
											statusPosition={32}
											keys={expiryProductTable.values}
											onView={handleTurnOnNotification}
										/>
									)
								)}

								{allExpiryProducts.isQueryWithNoData && (
									<NoQueryData text={allExpiryProducts.emptyMessage("Inventory Log")} />
								)}

								{allExpiryProducts.isQueryLoading && (
									<TableSkeleton />
								)}
							</Table>

							{allExpiryProducts.showPagination &&
								<TablePagination
									query={allExpiryProducts}
									queryKey={inventoryQueryKey.mainList(debounceState.searchValue)}
								/>
							}

						</SetUpLayoutStyled>

						{allExpiryProducts.showPagination &&
							<TablePagination
								query={allExpiryProducts}
								queryKey={inventoryQueryKey.mainList(debounceState.searchValue)}
							/>
						}

					</Fragment>
				)}
			</Fragment>
		</div>
	)
}

export default ExpiryProducts;
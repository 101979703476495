import uuid from "react-uuid";

const formatProductFormForSubmission = (values = {}, isNotInArray) => {
	const newValues = {...values}
	
	if (!!newValues?.commission_type && isNotInArray) {
		newValues.commission = {type: newValues.commission_type, value: newValues.commission_value}
	}
	
	if (!!newValues?.commission_type && !isNotInArray) {
		newValues.commission = [{type: newValues.commission_type, value: newValues.commission_value}]
	}
	
	delete newValues?.commission_type
	delete newValues?.commission_value
	delete newValues?.commission_type_name
	
	return newValues
}

const formatProductFormForProductLookupEditing = (product) => {
	const formValues = {...product}
	
	const productLookUpUUIDs = []
	const lookupKeys = ["sku1", "sku2", "sku3", "sku4", "sku5"]
	
	// if product is already using sku1, sku2 format and has not used extra_sku
	if (!!product["sku1"] && !product["extra_sku"]) {
		lookupKeys.forEach((lookup) => {
			if (!formValues?.[lookup]) return
			
			const id = uuid()
			formValues[`sku_${id}`] = formValues[lookup]
			productLookUpUUIDs.push(id)
		})
	}
	
	if (!!product["extra_sku"] && product["extra_sku"].length > 0) {
		const allSKUs = product["extra_sku"].split(", ")
		
		allSKUs.forEach((sku) => {
			const id = uuid()
			formValues[`sku_${id}`] = sku
			productLookUpUUIDs.push(id)
		})
	}
	
	return {...formValues, productLookUpUUIDs}
}


const formatProductFormForEditing = (values = {}, isNotArray) => {
	const {commission, ...rest} = values
	
	if (isNotArray) {
		rest.commission_type = commission?.type || ""
		rest.commission_value = commission?.value ?? ""
		rest.commission_type_name = commission?.type || ""
	} else {
		rest.commission_type = commission?.[0]?.type || ""
		rest.commission_value = commission?.[0]?.value ?? ""
		rest.commission_type_name = commission?.[0]?.type || ""
	}
	
	
	return formatProductFormForProductLookupEditing(rest)
}


const handleEditProductAttachments = (formValues) => {
	const values = { ...formValues }
	
	const getSalesAttachment = () => {
		if(!!values.product_attachment && values.product_attachment.length > 0) {
			return values.product_attachment.filter(attachments => attachments.type === "sales_attachment" )
		}
		
		return []
	}
	
	const getProductionAttachment = () => {
		if(!!values.product_attachment && values.product_attachment.length > 0) {
			return values.product_attachment.filter(attachments => attachments.type === "production_attachment" )
		}
		
		return []
	}
	
	const salesAttachment = getSalesAttachment()
	const productionAttachment = getProductionAttachment()
	
	const productionIds = productionAttachment.map(item => {
		const id = uuid()
		
		values[`production_attachment_name_${id}`] = item.product_id
		values[`production_attachment_display_${id}`] = item.product_name
		values[`production_attachment_quantity_${id}`] = item.qty
		values[`production_attachment_quantity_${id}_format`] = item.qty
		
		return id
	})
	
	const salesIds = salesAttachment.map(item => {
		const id = uuid()
		
		values[`sales_attachment_name_${id}`] = item.product_id
		values[`sales_attachment_display_${id}`] = item.product_name
		values[`sales_attachment_quantity_${id}`] = item.qty
		values[`sales_attachment_quantity_${id}_format`] = item.qty
		
		return id
	})
	
	
	return { ...values, productionIds, salesIds }
}


export const comboProductAutomaticCostPriceCalculationAndPrefillInputField = ({ quantity, fieldName, formikValuesRef, addedProductsToCostPrice, setFieldValue, numberFormatter }) => {
	if(fieldName === "sales_quantity") {
		const productID = formikValuesRef.current["sales_attachment"]
		const existingProduct = addedProductsToCostPrice.current.find(product => product.product_id === productID)
		const filterOutExistingProduct = addedProductsToCostPrice.current.filter(product => product.product_id !== productID)
		let totalCostPriceEdited = null
		
		if(existingProduct) {
			const { quantity, product_cost_price } = existingProduct
			totalCostPriceEdited = Number(formikValuesRef.current.cost_price || 0) - (quantity * product_cost_price)
		}
		
		const productCost = Number(quantity) * Number(formikValuesRef.current[`${productID}_cost_price`])
		const setNewProductTotalCost = Number((totalCostPriceEdited !== null ? totalCostPriceEdited :formikValuesRef.current.cost_price) || 0) + productCost
		
		setFieldValue("cost_price", setNewProductTotalCost)
		setFieldValue("cost_price_format", numberFormatter(setNewProductTotalCost))
		
		addedProductsToCostPrice.current = [...(!!existingProduct ? filterOutExistingProduct : addedProductsToCostPrice.current), {
			product_id: productID,
			field_id: "main",
			quantity: Number(quantity),
			product_cost_price: Number(formikValuesRef.current[`${productID}_cost_price`])
		}]
		return
	}
	
	const productID = formikValuesRef.current[`sales_attachment_name_${fieldName.split("_")[3]}`]
	const existingProduct = addedProductsToCostPrice.current.find(product => product.product_id === productID)
	const filterOutExistingProduct = addedProductsToCostPrice.current.filter(product => product.product_id !== productID)
	let totalCostPriceEdited = null
	
	if(existingProduct) {
		const { quantity, product_cost_price } = existingProduct
		totalCostPriceEdited = Number(formikValuesRef.current.cost_price || 0) - (quantity * product_cost_price)
	}
	
	const productCost = Number(quantity) * Number(formikValuesRef.current[`${productID}_cost_price`])
	const setNewProductTotalCost = Number((totalCostPriceEdited !== null ? totalCostPriceEdited :formikValuesRef.current.cost_price) || 0) + productCost
	
	setFieldValue("cost_price", setNewProductTotalCost)
	setFieldValue("cost_price_format", numberFormatter(setNewProductTotalCost))
	
	addedProductsToCostPrice.current = [...(!!existingProduct ? filterOutExistingProduct : addedProductsToCostPrice.current), {
		product_id: productID,
		field_id: fieldName.split("_")[3],
		quantity: Number(quantity),
		product_cost_price: Number(formikValuesRef.current[`${productID}_cost_price`])
	}]
}

export const handleDeleteProductOnComboProductAutomaticCostPriceCalculationAndPrefill = ({ id, formikValuesRef, addedProductsToCostPrice, setFieldValue, numberFormatter }) => {
	const productToRemove = addedProductsToCostPrice.current.find(product => product.field_id === id)
	if(!productToRemove) return
	
	const { quantity, product_cost_price } = productToRemove
	const costPriceToRemove = Number(quantity * product_cost_price)
	
	const newProductCostPrice = Number(formikValuesRef.current.cost_price) - costPriceToRemove
	setFieldValue("cost_price", newProductCostPrice)
	setFieldValue("cost_price_format", numberFormatter(newProductCostPrice))
	
	addedProductsToCostPrice.current = addedProductsToCostPrice.current.filter(product => product.field_id !== id)
}


export const handleChangingOfProductOnComboProductAutomaticCostPriceCalculationAndPrefill = ({ addedProductsToCostPrice, setFieldValue, numberFormatter, productFieldID }) => {
	const filterAddedProducts = addedProductsToCostPrice.current.filter(product => product.field_id !== productFieldID)
	
	const getSumOfCostPrices = filterAddedProducts.reduce((prev, curr) => {
		return prev + (curr.quantity * curr.product_cost_price)
	}, 0)
	
	setFieldValue("cost_price", getSumOfCostPrices)
	setFieldValue("cost_price_format", numberFormatter(getSumOfCostPrices))
	
	addedProductsToCostPrice.current = filterAddedProducts
}


export const handleCalculationForProducibleProductCostPriceFromRawMaterials = ({ rawMaterialsRef, setFieldValue, numberFormatter, newRawMaterial }) => {
	const filterRawMaterialsIfDuplicateWithNewMaterial =
		[
			...rawMaterialsRef.current.filter(raw_material => (raw_material.unique_uuid !== newRawMaterial.unique_uuid)),
			newRawMaterial
		]
	
	const getSumOfRawMaterials = filterRawMaterialsIfDuplicateWithNewMaterial.reduce((prev, curr) => {
		return prev + ((curr.cost_price / curr.warehouse_to_production_count) * curr.qty)
	}, 0)
	
	setFieldValue("cost_price", getSumOfRawMaterials)
	setFieldValue("cost_price_format", numberFormatter(getSumOfRawMaterials))
	
	rawMaterialsRef.current = filterRawMaterialsIfDuplicateWithNewMaterial
}

export const handleDeleteRawMaterialAndCalculationForProducibleProductCostPriceFromRawMaterials = ({ rawMaterialsRef, setFieldValue, numberFormatter, unique_uuid }) => {
	const filterRawMaterialsIfDuplicateWithNewMaterial = rawMaterialsRef.current.filter(raw_material => raw_material.unique_uuid !== unique_uuid)
	
	const getSumOfRawMaterials = filterRawMaterialsIfDuplicateWithNewMaterial.reduce((prev, curr) => {
		return prev + ((curr.cost_price / curr.warehouse_to_production_count) * curr.qty)
	}, 0)
	
	setFieldValue("cost_price", getSumOfRawMaterials)
	setFieldValue("cost_price_format", numberFormatter(getSumOfRawMaterials))
	
	rawMaterialsRef.current = filterRawMaterialsIfDuplicateWithNewMaterial
}


export { formatProductFormForSubmission, formatProductFormForEditing, handleEditProductAttachments }
import {Fragment, memo, useEffect, useRef, useState} from "react";
import {
  Button,
  CheckBox,
  CreateProductModal,
  DatePicker,
  Form,
  Input,
  InvoiceCheckOutModal,
  PageHeader,
  Select,
  SupplierModal,
  TextArea
} from "../../../../components";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {useCreateProductMutation, useProductQuery} from "../../../../hooks/useServices/useProductServices";
import {useFormikContext} from "formik";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {calculatePercentageDifferenceOfUnitMeasurementSellingPrice} from "../../../../utils/products";
import uuid from "react-uuid";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {
  useCreateSupplierMutation,
  useSupplierByBusinessIdQuery
} from "../../../../hooks/useServices/useSupplierServices";
import {purchaseOrderFormDefaultValues} from "../../../../data/defaultFormValues";
import {actionsPermissions} from "../../../../data/permissions";
import RadioButton from "../../../../components/Forms/RadioButton";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation
} from "../../../../hooks/useServices/usePurchaseOrder";
import {CreatePurchaseOrderStyled, ProductSectionForPurchaseOrder} from "../../../../styles/purchaseOrderStyles";
import {useModal} from "../../../../hooks";
import {formatProductFormForSubmission} from "../../../../utils/formatForms/product";
import ManagePurchaseOrderProductModal
  from "../../../../components/Modal/InventoryModal/PurchaseOrderModals/ManagePurchaseOrderProductModal";
import {
  getPurchaseOrderTotalCost,
  getPurchaseOrderTotalPayableAmount,
  getTotalCostPriceForPurchaseProduct,
  handleCreatePurchaseOrderFormatting,
  handleFormattingOfPurchaseOrderProductToManage
} from "../../../../utils/formatForms/purchaseOrderFormatter";
import {Paragraph} from "../../../../styles/textStyles";
import {useParams} from "react-router-dom";
import {usePurchaseOrderPaymentMethods} from "../../../../hooks/useUtils/usePaymentMethods";
import {globalReducerActions} from "../../../../reducers/globalReducer";
import {handleHoldPurchaseOrder} from "../../../../utils/holdOrders";


const usePurchaseOrderFormValues = () => {
  const {purchaseOrderId} = useParams()
  const {globalState} = useGlobalContext()
  
  if (globalState?.restockProducts?.hold_purchase_id) {
    return {
      isUpdatePurchase: true,
      purchaseOrderFormValues: {
        ...purchaseOrderFormDefaultValues,
        ...globalState.restockProducts,
        isUpdatePurchase: true,
      }
    }
  }
  
  if (purchaseOrderId && globalState?.restockProducts?.id) {
    return {
      isUpdatePurchase: true,
      purchaseOrderFormValues: {
        ...purchaseOrderFormDefaultValues,
        ...globalState.restockProducts,
        isUpdatePurchase: true,
        orderPaidFor: globalState?.restockProducts?.payment_status === "paid"
      }
    }
  }
  
  if(globalState?.restockProducts?.isRestocking) {
    return {
      purchaseOrderFormValues: { ...purchaseOrderFormDefaultValues, ...globalState.restockProducts }
    }
  }

  return { purchaseOrderFormValues: { ...purchaseOrderFormDefaultValues } }
}

const CreatePurchaseOrder = () => {
  const {navigate, toast, getBusinessId, globalReducer} = useGlobalContext()
  
  useEffect(() => {
    return () => {
      globalReducer({
        type: globalReducerActions.CLEAR_RESTOCK_PRODUCTS
      })
    }
  }, [])
  
  const isPermitted = useActionPermissionFn()
  const {isUpdatePurchase, purchaseOrderFormValues} = usePurchaseOrderFormValues()
  
  const [searchSupplier, setSearchSupplier] = useState("")
  const {data: allSuppliers, isLoading: isAllSuppliersLoading} = useSupplierByBusinessIdQuery(searchSupplier)
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useModal()
  const [showManageProductModal, setShowManageProductModal] = useModal()
  const [productToManage, setProductToManage] = useState({
    product: {}, setFieldValue: () => {
    }
  })
  const [showCreateProductModal, setShowCreateProductModal] = useModal()
  
  // states to pay for purchase order
  const [showInvoiceCheckoutModal, setShowInvoiceCheckoutModal] = useModal()
  const [payFormValues, setPurchaseOrderValuesForPayment] = useState(purchaseOrderFormDefaultValues)
  const purchaseOrderPaymentMethods = usePurchaseOrderPaymentMethods(payFormValues)
  
  const createProductMutation = useCreateProductMutation({
    successFn: () => {
    }
  })
  const createSupplierMutation = useCreateSupplierMutation({successFn: setShowCreateSupplierModal})
  const createPurchaseOrderMutation = useCreatePurchaseOrderMutation({successFn: () => navigate(-1)})
  const updatePurchaseOrderMutation = useUpdatePurchaseOrderMutation({ successFn: () => navigate(-1) })
  
  const handleCreateSupplier = (values) => {
    createSupplierMutation.mutate(values)
  }
  
  const CreateSupplier = () => (
    <label className="underline" onClick={setShowCreateSupplierModal}>Create +</label>
  )
  
  const handleCreateProduct = (values) => {
    const formattedValues = formatProductFormForSubmission(values)
    createProductMutation.mutate(formattedValues)
  }
  
  const handlePayForNewPurchaseOrder = (values) => {
    const purchaseOrderTotalCost = getPurchaseOrderTotalCost(payFormValues)
    
    const formValues = {
      ...values,
      ...payFormValues,
      paid: true,
      supplied: false,
      paid_amount: Number(purchaseOrderTotalCost).toFixed(2),
      business_id: getBusinessId(),
    }
    
    if(values.pay_mode === "partial") {
      if(Number(values.total_partial) > purchaseOrderTotalCost) {
        toast.error("You can not pay more than the purchase amount")
        return
      }
      
      formValues.payment_mode = "partial"
      formValues.paid_amount = Number(values.total_partial).toFixed(2)
      createPurchaseOrderMutation.mutate(formValues)
      return
    }
  
    if(!!formValues?.supplied_data) {
      formValues.supplied = true
    }
    
    formValues.payment_mode = formValues.pay_mode
    createPurchaseOrderMutation.mutate(formValues)
  }
  
  const handlePayForExistingPurchaseOrder = (values) => {
    const totalPayable = getPurchaseOrderTotalPayableAmount(payFormValues)
    
    const formValues = {
      ...values,
      paid: true,
      supplied: false,
      id: values.id,
      paid_amount: Number(getPurchaseOrderTotalCost(payFormValues)).toFixed(2),
      business_id: getBusinessId(),
      ...(payFormValues?.supplied_data && payFormValues),
    }
    
    if(values.pay_mode === "partial") {
      if(Number(values.total_partial) > totalPayable) {
        toast.error("You can not pay more than your balance")
        return
      }
      
      formValues.payment_mode = "partial"
      formValues.paid_amount = Number(values.total_partial).toFixed(2)
      
      if(Number(payFormValues.total_amount) !== totalPayable) {
        formValues.pay_balance = true
        delete formValues.payment_mode
        delete formValues.paid
        
        if(!!formValues?.supplied_data) {
          delete formValues.paid
          formValues.supplied = true
        }
        
      }
      
      updatePurchaseOrderMutation.mutate(formValues)
      return
    }
    
    formValues.payment_mode = formValues.pay_mode
    updatePurchaseOrderMutation.mutate(formValues)
  }
  
  const onCreatePurchaseOrder = (values) => {
    if(values.submit_option === purchaseOrderRadioOptions.create_and_pay || values.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) {
      setPurchaseOrderValuesForPayment(values)
      setShowInvoiceCheckoutModal()
      return
    }
    
    createPurchaseOrderMutation.mutate(values)
  }
  
  const onUpdatePurchaseOrder = (values) => {
    if(!values?.receive) {
      updatePurchaseOrderMutation.mutate({ ...values, edit: true })
      return
    }
  
    if(values?.receive && !values["is_paid"]) {
      updatePurchaseOrderMutation.mutate({ ...values, supplied: true })
      return
    }
    
    setPurchaseOrderValuesForPayment({ ...values, supplied: true })
    setShowInvoiceCheckoutModal()
  }
  
  const onSubmit = (values) => {
    values["business_id"] = getBusinessId()
    const formValues = handleCreatePurchaseOrderFormatting(values)
  
    if (purchaseOrderFormValues.hold_purchase_id) {
      onCreatePurchaseOrder(formValues)
      return
    }
  
    isUpdatePurchase ? onUpdatePurchaseOrder(formValues) : onCreatePurchaseOrder(formValues)
  }
  
  const getButtonText = () => {
    if (purchaseOrderFormValues.hold_created_at) return "Save"
    if (isUpdatePurchase && purchaseOrderFormValues.receive) return "Receive"
    if (isUpdatePurchase) return "Update"
    return "Save"
  }
  
  const getPageText = () => {
    if (isUpdatePurchase && purchaseOrderFormValues.receive) return "Receive Purchase Order"
    if (isUpdatePurchase) return "Update Purchase Order"
    return "Create Purchase Order"
  }
  
  const handleHoldPurchase = (formValues) => {
    const uniqueId = uuid().split("-").join("")
    const formattedPurchase = handleCreatePurchaseOrderFormatting(formValues)
  
    handleHoldPurchaseOrder({
      ...formattedPurchase,
      hold_created_at: Date.now(),
      hold_business_id: getBusinessId(),
      hold_total_products: formattedPurchase.products.length,
      hold_total_cost: getPurchaseOrderTotalCost(formattedPurchase),
      hold_purchase_id: formValues.hold_purchase_id || uniqueId,
    })
  
    toast.success("Order hold successful", "hold_order")
    navigate('/dashboard/inventory/purchase_order/')
  }
  
  return (
    <Fragment>
      {showCreateSupplierModal &&
        <SupplierModal onClose={setShowCreateSupplierModal} handleSubmit={handleCreateSupplier}
                       mutation={createSupplierMutation}/>}
      {showManageProductModal &&
        <ManagePurchaseOrderProductModal product={productToManage.product} onClose={setShowManageProductModal}
                                         setFieldValue={productToManage.setFieldValue}/>}
      {showCreateProductModal &&
        <CreateProductModal onClose={setShowCreateProductModal} handleSubmit={handleCreateProduct}
                            mutation={createProductMutation}/>}
      {showInvoiceCheckoutModal &&
        <InvoiceCheckOutModal
          mutation={(isUpdatePurchase && !payFormValues.hold_purchase_id) ? updatePurchaseOrderMutation : createPurchaseOrderMutation}
          balance={getPurchaseOrderTotalCost(payFormValues)} onlyOnePartial orders={[payFormValues]}
          onClose={setShowInvoiceCheckoutModal} customPaymentMethods={purchaseOrderPaymentMethods}
          handlePaymentSubmission={(isUpdatePurchase && !payFormValues.hold_purchase_id) ? handlePayForExistingPurchaseOrder : handlePayForNewPurchaseOrder}/>}
      
      <PageHeader title={getPageText()} isBack backTo="purchase order"/>
      
      <CreatePurchaseOrderStyled>
        <Form values={purchaseOrderFormValues} onSubmit={onSubmit}>
          <Fragment>
            <PurchaseOrderActionOptions/>
            
            <FlexStyled className="purchase_details_row">
              <Select
                isSearchLoading={isAllSuppliersLoading}
                disabled={purchaseOrderFormValues.orderPaidFor}
                options={allSuppliers?.results || []}
                displayKey="name"
                valueKey="id"
                label="Supplier"
                placeholder="Select supplier of product"
                name="supplier"
                displayName="supplier_name"
                SideInfo={CreateSupplier}
                updateQueryResult={setSearchSupplier}
              />
  
              <Input disabled={purchaseOrderFormValues.orderPaidFor} optional type="number" label="Shipping Cost"
                     placeholder="Enter shipping cost (if any)" name="shipping_cost" formatNumber/>
              <Input disabled={purchaseOrderFormValues.orderPaidFor} optional label="Tax" type="number"
                     placeholder="Enter shipping cost (if any)" name="tax" formatNumber/>
              <Input disabled={purchaseOrderFormValues.orderPaidFor} optional label="Purchase ID"
                     placeholder="Enter purchase ID provided by supplier" name="purchase_id"/>
            </FlexStyled>
            
            <AttachProduct setProductToManage={setProductToManage} setShowManageProductModal={setShowManageProductModal}
                           setShowCreateProductModal={setShowCreateProductModal}/>
            <TextArea name="remark" optional label="Remark" className="fullWidth"
                      placeholder="Enter a remark for this purchase"/>
            
            <PurchaseOrderSummary/>
            
            {(isUpdatePurchase && purchaseOrderFormValues.receive && isPermitted.check(actionsPermissions.payForPurchaseOrder) && !purchaseOrderFormValues.orderPaidFor) && (
              <CheckBox name="is_paid" labelProp="Have you paid for this order" optional/>
            )}
            
            <ActionButtons
              buttonText={getButtonText()}
              handleHoldPurchase={handleHoldPurchase}
              updatePurchaseOrderIsLoading={updatePurchaseOrderMutation.isLoading}
              createPurchaseOrderIsLoading={createPurchaseOrderMutation.isLoading}
            />
          </Fragment>
        </Form>
      </CreatePurchaseOrderStyled>
    </Fragment>
  )
}

export default CreatePurchaseOrder


const ActionButtons = ({
                         createPurchaseOrderIsLoading,
                         updatePurchaseOrderIsLoading,
                         handleHoldPurchase,
                         buttonText
                       }) => {
  const {values} = useFormikContext()
  const {isOffline} = useGlobalContext()
  
  return (
    <FlexStyled>
      {!isOffline && (
        <Button
          type="submit"
          text={buttonText}
          isLoading={createPurchaseOrderIsLoading || updatePurchaseOrderIsLoading}
        />
      )}
      
      {/* Do not display hold purchase for order that has already been created */}
      {!Object.hasOwn(values, "created_at") && (
        <Button
          text="Hold Purchase"
          type="button" bgColor="black"
          onClick={() => handleHoldPurchase(values)}
        />
      )}
    </FlexStyled>
  )
}


export const purchaseOrderRadioOptions = {
  create_only: "create_only",
  create_and_pay: "create_and_pay",
  create_and_receive: "create_and_receive",
  create_pay_and_receive: "create_pay_and_receive"
}


const AttachProduct = memo(({setShowManageProductModal, setProductToManage, setShowCreateProductModal}) => {
  const {globalState} = useGlobalContext()
  const {purchaseOrderFormValues, isUpdatePurchase} = usePurchaseOrderFormValues()
  
  const [searchProduct, setSearchProduct] = useState("")
  const {data, isLoading: isProductLoading} = useProductQuery({
    type: ["general_product", "raw_material"],
    search: searchProduct
  })
  
  const activeProductField = useRef("main")
  const {setFieldValue, values} = useFormikContext()
  const {numberFormatter, currencyFormatter} = useDataTypeFormatter()
  const businessSettings = useBusinessSettingQuery()
  const allSelectedProductsWithIDRef = useRef([])
  const [moreProducts, setMoreProducts] = useState([])
  
  
  // If editing or managing of purchase order, structure and fill all products into the state
  useEffect(() => {
    if (!!globalState?.restockProducts?.productsIds && globalState?.restockProducts?.productsIds?.length > 0) {
      allSelectedProductsWithIDRef.current = globalState?.restockProducts?.productsIds?.map(product_uuid => {
        const productId = globalState?.restockProducts?.[`sales_attachment_name_${product_uuid}`]
        const product = globalState?.restockProducts?.products?.find(product => product?.product_id === productId)
        
        return {id: product_uuid, product}
      })
    }
  }, [purchaseOrderFormValues])
  
  
  // If editing or managing of purchase order, create each purchase product section card
  useEffect(() => {
    if (!!globalState?.restockProducts?.productsIds && globalState?.restockProducts?.productsIds?.length > 0) {
      
      const attachmentStructure = globalState?.restockProducts?.productsIds?.map(id => {
        return SalesAttachmentForm(id)
      })
      
      setMoreProducts(attachmentStructure)
    }
  }, [globalState?.restockProducts])
  
  const CreateProduct = () => (
    <label className="underline" onClick={setShowCreateProductModal}>Create +</label>
  )
  
  const handleGetProductToManage = (id = "main") => {
    handleFormattingOfPurchaseOrderProductToManage({
      id,
      values: values, setFieldValue,
      setProductToManage, setShowManageProductModal,
      selectedProducts: allSelectedProductsWithIDRef.current,
    })
  }
  
  const handleForeCastSellingPrice = (cost, id) => {
    const product = allSelectedProductsWithIDRef.current.find(product => product.id === (id || "main"))
    if(!businessSettings?.use_sales_margin || !product || !cost) return
    
    const salesMarginPercentage = product?.product?.sales_margin || businessSettings?.sales_margin
    const perValue = Number(cost) * (Number(salesMarginPercentage) / 100)
    const forecastValue = Number(cost) + perValue
    
    if(Number(product?.product?.selling_price) === forecastValue) return
    
    if(id) {
      setFieldValue(`sales_attachment_forecast_${id}`, `${product?.product?.name || product?.product?.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)`)
      return
    }
    
    setFieldValue(`sales_attachment_forecast`, `${product?.product?.name || product?.product?.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)`)
  }
  
  const handleSelectProduct = (product, id = "main") => {
    const getProductMeasurement = [
      {
        name: `${product.unit_measurement || "Piece"}`,
        full_name: `${product.unit_measurement || "Piece"} (1 qty)`,
        base_quantity: 1,
        selling_price: product.selling_price
      },
      ...((product?.meta_measurement || []).map(meta => ({
        ...meta,
        full_name: `${meta.name} (${meta.base_quantity} qty)`
      })))]
  
    const filterProducts = allSelectedProductsWithIDRef.current.filter(productObj => productObj?.id !== (id || "main"))
    allSelectedProductsWithIDRef.current = [...filterProducts, {
      id: (id || "main"),
      product: {...product, meta_measurement: getProductMeasurement}
    }]
  
    setFieldValue(`sales_attachment_available_quantity_${id}`, Number(product.stock_unit || 0))
    setFieldValue(`sales_attachment_measurement_product_previous_selling_price_${id}`, product.selling_price)
    setFieldValue(`sales_attachment_measurement_array_${id}`, getProductMeasurement)
  
    if (id) {
      if (!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) {
        handleForeCastSellingPrice(Number(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price), id)
      }
    
      const costPrice = product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price
    
      if (Number(costPrice)) {
        setFieldValue(`sales_attachment_cost_${id}`, costPrice || "")
        setFieldValue(`sales_attachment_cost_${id}_format`, !!costPrice ? numberFormatter(costPrice) : "")
      }
      return
    }
    
    if(!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) {
      handleForeCastSellingPrice(Number(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price), id)
    }
    
    setFieldValue("sales_attachment_cost", product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price || "")
    setFieldValue("sales_attachment_cost_format", (!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price ? numberFormatter(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) : ""))
  }
  
  const handleSelectUnitMeasurement = (measurement, id = "main", values) => {
    const sellingPricePerUnit = values[`sales_attachment_measurement_product_previous_selling_price_${id}`]
    const percentageDiff = calculatePercentageDifferenceOfUnitMeasurementSellingPrice(measurement, sellingPricePerUnit)
  
    const product = allSelectedProductsWithIDRef.current.find(prod => prod?.id === id)?.product
  
    if (product && !!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) {
      const costPrice = Number(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) * Number(measurement.base_quantity)
    
      setFieldValue(`sales_attachment_cost_${id}`, costPrice || "")
      setFieldValue(`sales_attachment_cost_${id}_format`, !!costPrice ? numberFormatter(costPrice) : "")
    }
  
    setFieldValue(`sales_attachment_measurement_real_name_${id}`, measurement.full_name)
    setFieldValue(`sales_attachment_measurement_percentage_diff_${id}`, percentageDiff)
    setFieldValue(`sales_attachment_measurement_name_${id}`, measurement.name)
    setFieldValue(`sales_attachment_measurement_base_${id}`, measurement.base_quantity)
  }
  
  const handlePurchaseOrderQuantityInput = (totalCostPrice, id = "main", values) => {
    if (totalCostPrice) {
      const costPerQuantity = Number((Number(totalCostPrice) / Number(values[`sales_attachment_quantity_${id}`])).toFixed(3))
      setFieldValue(`sales_attachment_cost_${id}`, costPerQuantity)
    }
  }

  const SalesAttachmentForm = (newID) => {
    const id = newID || uuid()
    
    const SelectProduct = ({data, isProductLoading, values}) => (
      <Select
        handleFocus={() => activeProductField.current = id}
        disabled={values.orderPaidFor || (values.receive && values.isUpdatePurchase)}
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_name_${id}`}
        label={`Product ${values[`sales_attachment_available_quantity_${id}`] ? `(${values[`sales_attachment_available_quantity_${id}`]} qty available)` : ""}`}
        displayName={`sales_attachment_display_${id}`}
        placeholder="Select product"
        options={data?.results}
        updateFn={(prod) => handleSelectProduct(prod, id)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
        SideInfo={CreateProduct}
      />
    )
    
    const SelectUnitMeasurement = ({ allSelectedProductsWithIDRef, values }) => (
      <Select
        disabled={values.orderPaidFor || (values.receive && values.isUpdatePurchase)}
        valueKey="id"
        displayKey="full_name"
        name={`sales_attachment_measurement_${id}`}
        label="Unit Measurement"
        displayName={`sales_attachment_measurement_display_${id}`}
        placeholder="Select Unit Measurement"
        options={allSelectedProductsWithIDRef.current?.find(pro => pro?.id === id)?.product?.meta_measurement || []}
        updateFn={(measurement) => handleSelectUnitMeasurement(measurement, id, values)}
        // clearCondition={`sales_attachment_name_${id}`}
      />
    )
  
    const InputQuantity = () => (
      <Input
        disabled={values.orderPaidFor || (values.receive && values.isUpdatePurchase)}
        type="number"
        formatNumber
        name={`sales_attachment_quantity_${id}`}
        label="Qty Ordered"
        placeholder="Enter quantity"
      />
    )
  
    const TotalCost = ({values}) => (
      <Input
        disabled={values.orderPaidFor}
        optional
        type="number"
        formatNumber
        name={`sales_attachment_total_cost_${id}`}
        label="Total Cost"
        placeholder="Enter total cost of purchase"
        onChange={(quantity) => handlePurchaseOrderQuantityInput(quantity, id, values)}
        clearCondition={`sales_attachment_quantity_${id}`}
      />
    )
  
    const InputCostPerQuantity = ({values}) => (
      <Input
        disabled={values.orderPaidFor}
        type="number"
        formatNumber
        name={`sales_attachment_cost_${id}`}
        label={`Cost ${(!!values?.[`sales_attachment_measurement_display_${id}`]) ? `per 1 ${values[`sales_attachment_measurement_display_${id}`]}` : "per quantity"}`}
        placeholder="Enter cost"
        onChange={(cost) => handleForeCastSellingPrice(cost, id)}
      />
    )
  
    const InputSupplierQuantity = () => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_supplied_quantity_${id}`}
        label="Supplied Quantity"
        placeholder="Enter supplied quantity"
      />
    )
    
    const InputBatchNumber = () => (
      <Input
        optional
        label="Batch Number"
        placeholder="Enter batch number"
        name={`sales_attachment_batch_number_${id}`}
      />
    )
    
    const InputExpiryDate = () => (
      <DatePicker className={`sales_attachment_name_${id}`} name={`sales_attachment_expiry_${id}`} label="Expiry Date"
                  optional/>
    )
    
    return {
      id,
      SelectProduct,
      InputSupplierQuantity,
      InputExpiryDate,
      InputQuantity,
      TotalCost,
      InputBatchNumber,
      InputCostPerQuantity,
      SelectUnitMeasurement
    }
  }
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
  
    allSelectedProductsWithIDRef.current = allSelectedProductsWithIDRef.current.filter(prod => prod.id !== id)
  
    setFieldValue(`sales_attachment_name_${id}`, "")
    setFieldValue(`sales_attachment_display_${id}`, "")
    setFieldValue(`sales_attachment_quantity_${id}`, "")
    setFieldValue(`sales_attachment_cost_${id}`, "")
    setFieldValue(`sales_attachment_expiry_${id}`, "")
    setFieldValue(`sales_attachment_batch_number_${id}`, "")
  }
  
  
  // Handle barcode scanning of products: auto select the first product after scan
  // useEffect(() => {
  //   if (data?.results?.length === 1) {
  //     const product = data.results[0]
  //
  //     setFieldValue(`sales_attachment_${activeProductField.current}`, product.id)
  //     setFieldValue(`sales_attachment_display_${activeProductField.current}`, product.name)
  //     handleSelectProduct(product, activeProductField.current)
  //   }
  // }, [data])
  
  
  return (
    <div className="fullWidth" style={{marginBottom: "2rem"}}>
      <Fragment>
        {!purchaseOrderFormValues.productsIds && (
          <ProductSectionForPurchaseOrder>
            <FlexStyled className="fullWidth">
              <Select
                clearFilter
                valueKey="id"
                displayKey="name"
                name="sales_attachment_main"
                label={`Product ${values["sales_attachment_available_quantity_main"] ? `(${values["sales_attachment_available_quantity_main"]} qty available)` : ""}`}
                displayName="sales_attachment_display_main"
                placeholder="Select product"
                options={data?.results}
                updateFn={(prod) => handleSelectProduct(prod)}
                updateQueryResult={setSearchProduct}
                isSearchLoading={isProductLoading}
                SideInfo={CreateProduct}
                handleFocus={() => activeProductField.current = "main"}
              />
              
              <Select
                valueKey="id"
                displayKey="full_name"
                name="sales_attachment_measurement_main"
                label="Unit Measurement"
                displayName="sales_attachment_measurement_display_main"
                placeholder="Select Unit Measurement"
                options={allSelectedProductsWithIDRef.current.find(pro => pro.id === "main")?.product?.meta_measurement || []}
                updateFn={(measurement) => handleSelectUnitMeasurement(measurement, "main", values)}
                clearCondition="sales_attachment_main"
              />
  
              <Input
                type="number"
                formatNumber
                name="sales_attachment_quantity_main"
                label="Quantity"
                placeholder="Enter quantity"
              />
  
              <Input
                optional
                type="number"
                formatNumber
                name="sales_attachment_total_cost_main"
                label="Total Cost"
                placeholder="Enter total cost of purchase"
                clearCondition="sales_attachment_quantity_main"
                onChange={(quantity) => handlePurchaseOrderQuantityInput(quantity, "main", values)}
              />
  
              <Input
                type="number"
                formatNumber
                name="sales_attachment_cost_main"
                label={`Cost ${(!!values[`sales_attachment_measurement_display_main`]) ? `per 1 ${values[`sales_attachment_measurement_display_main`]}` : "per quantity"}`}
                placeholder="Enter cost"
                onChange={(cost) => handleForeCastSellingPrice(cost)}
              />

              <Input
                optional
                label="Batch Number"
                placeholder="Enter batch number"
                name="sales_attachment_batch_number_main"
                // clearCondition="sales_attachment_main"
              />
              
              <DatePicker
                // clearCondition="sales_attachment_main"
                name="sales_attachment_expiry_main"
                label="Expiry Date"
                optional
              />
            </FlexStyled>
  
            <SpaceBetweenStyled>
              <FlexStyled style={{ marginTop: ".7rem" }}>
                <Paragraph bold color="black" size={1.2}>Total Cost:</Paragraph>
                <Paragraph bold color="black" size={1.2}>{currencyFormatter(getTotalCostPriceForPurchaseProduct(values, "main") || 0)}</Paragraph>
              </FlexStyled>
  
              <FlexStyled className="product_purchase_action">
                {(values.receive && values?.[`sales_attachment_measurement_array_main`]?.length > 0) && (
                  <Button permissionCode={actionsPermissions.adjustProductSellingPrice}
                          disabled={!values?.["sales_attachment_main"]} type="button" text="Adjust Prices" small
                          onClick={handleGetProductToManage}/>
                )}
              </FlexStyled>
            </SpaceBetweenStyled>
          </ProductSectionForPurchaseOrder>
        )}
  
        {moreProducts?.map(({
                              id,
                              SelectProduct,
                              InputExpiryDate,
                              InputQuantity,
                              TotalCost,
                              InputSupplierQuantity,
                              InputBatchNumber,
                              InputCostPerQuantity,
                              SelectUnitMeasurement
                            }, k) => (
          <ProductSectionForPurchaseOrder key={id}>
            <FlexStyled className="fullWidth five_cols">
              <SelectProduct data={data} isProductLoading={isProductLoading} values={values}/>
              <SelectUnitMeasurement allSelectedProductsWithIDRef={allSelectedProductsWithIDRef} values={values}/>
              <InputQuantity/>
              <TotalCost values={values}/>
              <InputCostPerQuantity values={values}/>
    
              {(values.orderPaidFor || (values.receive && values.isUpdatePurchase)) && (
                <InputSupplierQuantity/>
              )}
    
              {!(values.orderPaidFor) && (
                <InputBatchNumber/>
              )}
    
              <InputExpiryDate/>
            </FlexStyled>
            
            <SpaceBetweenStyled>
              <FlexStyled style={{ marginTop: ".7rem" }}>
                <Paragraph bold color="black" size={1.2}>Total Cost:</Paragraph>
                <Paragraph bold color="black" size={1.2}>{currencyFormatter(getTotalCostPriceForPurchaseProduct(values, id) || 0)}</Paragraph>
              </FlexStyled>
  
              <FlexStyled className="product_purchase_action">
                {(values.receive) && (
                  <Button permissionCode={actionsPermissions.adjustProductSellingPrice} disabled={!values?.[`sales_attachment_name_${id}`]} type="button" text="Adjust Prices" small onClick={() => handleGetProductToManage(id)} />
                )}
    
                {!(values.orderPaidFor) && (
                  <Button disabled={k === 0 && (allSelectedProductsWithIDRef.current.length === 1) && isUpdatePurchase}
                          type="button" text="Remove" bgColor="error" small onClick={() => handleDelete(id)}/>
                )}
              </FlexStyled>
            </SpaceBetweenStyled>
          </ProductSectionForPurchaseOrder>
        ))}
  
        {!(values.orderPaidFor) && (
          <Button onClick={addExtraProduct} type="button" text="Add more product" className="small add_more_product" small />
        )}
        <br/>
      </Fragment>
    
    </div>
  )
})


export const PurchaseOrderSummary = () => {
  const { values } = useFormikContext()
  const { currencyFormatter } = useDataTypeFormatter()
  const purchaseOrder = handleCreatePurchaseOrderFormatting(values, false)
  
  return (
    <Fragment>
      {([purchaseOrderRadioOptions.create_only, purchaseOrderRadioOptions.create_and_receive].includes(values?.submit_option) || values.isUpdatePurchase) && (
        <DatePicker name="due_date" label="Payment Due Date" optional/>
      )}
    
      <Paragraph bold color="black" size={1.1} style={{marginTop: "3.5rem"}}>Purchase Summary</Paragraph>
      <ProductSectionForPurchaseOrder style={{margin: ".6rem 0 3rem 0"}}>
        <FlexStyled style={{marginBottom: "1rem"}}>
          <Paragraph color="black" size={1.1}>No. of Products:</Paragraph>
          <Paragraph color="black" size={1.1}>{purchaseOrder?.productsToCalculate?.length || 0}</Paragraph>
        </FlexStyled>
      
        {!!purchaseOrder?.shipping_cost && (
          <FlexStyled style={{marginBottom: "1rem"}}>
            <Paragraph color="black" size={1.1}>Shipping Cost:</Paragraph>
            <Paragraph color="black" size={1.1}>{currencyFormatter(purchaseOrder?.shipping_cost || 0)}</Paragraph>
          </FlexStyled>
        )}
  
        {!!purchaseOrder?.tax && (
          <FlexStyled style={{marginBottom: "1rem"}}>
            <Paragraph color="black" size={1.1}>Tax Cost:</Paragraph>
            <Paragraph color="black" size={1.1}>{currencyFormatter(purchaseOrder?.tax || 0)}</Paragraph>
          </FlexStyled>
        )}
      
        <FlexStyled style={{marginTop: ".7rem"}}>
          <Paragraph bold color="black" size={1.2}>Total Cost:</Paragraph>
          <Paragraph bold color="black"
                     size={1.2}>{currencyFormatter(getPurchaseOrderTotalCost(purchaseOrder) || 0)}</Paragraph>
        </FlexStyled>
    
      </ProductSectionForPurchaseOrder>
    </Fragment>
  )
}

export const PurchaseOrderActionOptions = () => {
  const isPermitted = useActionPermissionFn()
  const {setFieldValue, values} = useFormikContext()
  const {isUpdatePurchase} = usePurchaseOrderFormValues()
  
  const handleChangeAction = (e) => {
    setFieldValue("receive", ((purchaseOrderRadioOptions.create_and_receive === e.target.value) || (purchaseOrderRadioOptions.create_pay_and_receive === e.target.value)))
  }
  
  return (
    <Fragment>
      {(!isUpdatePurchase || values) && (
        <SpaceBetweenStyled style={{marginBottom: "2rem"}}>
          {isPermitted.check(actionsPermissions.createPurchaseOrder) && (
            <RadioButton handleChange={handleChangeAction} id="1" className="fullWidth" name="submit_option"
                         value={purchaseOrderRadioOptions.create_only} labelProp="Create Order" required/>
          )}
          
          {isPermitted.check(actionsPermissions.payForPurchaseOrder) && isPermitted.check(actionsPermissions.createPurchaseOrder) && (
            <RadioButton handleChange={handleChangeAction} id="12" className="fullWidth" name="submit_option"
                         value={purchaseOrderRadioOptions.create_and_pay} labelProp="Create and Pay"/>
          )}
          
          {isPermitted.check(actionsPermissions.receivePurchaseOrder) && isPermitted.check(actionsPermissions.payForPurchaseOrder) && (
            <RadioButton handleChange={handleChangeAction} id="13" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_and_receive} labelProp="Create and Receive" />
          )}
      
          {isPermitted.check(actionsPermissions.receivePurchaseOrder) && isPermitted.check(actionsPermissions.payForPurchaseOrder) && isPermitted.check(actionsPermissions.createPurchaseOrder) && (
            <RadioButton handleChange={handleChangeAction} id="14" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_pay_and_receive} labelProp="Create, Pay and Receive" />
          )}
        </SpaceBetweenStyled>
      )}
    </Fragment>
  )
}
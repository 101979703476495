import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getExpenseCategoryService = async () => {
  try {
    const { data } = await axios.get(`/expenses_category/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getExpenseService = async (queryParams) => {
  try {
    const url = handleQueryParams("expenses/", queryParams)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createExpenseService = async (body) => {
  try {
    const { data } = await axios.post("/expenses/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editExpenseService = async (expenseId, body) => {
  try {
    const { data } = await axios.patch(`/expenses/${expenseId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export { getExpenseCategoryService, getExpenseService, createExpenseService, editExpenseService }
import { useReducer } from "react";
import dateFilter from "../../data/selectField/dateFilter";

export const globalReducerActions = {
	SET_TABLE_DATA: "SET_TABLE_DATA",
	CLEAR_TABLE_DATA: "CLEAR_TABLE_DATA",
	SET_PACKAGE_ID: "SET_PACKAGE_ID",
	SET_MODAL_CONNECTOR: "SET_MODAL_CONNECTOR",
	CLEAR_MODAL_CONNECTOR: "CLEAR_MODAL_CONNECTOR",
	SET_DASHBOARD_DATE_FILTER: "SET_DASHBOARD_DATE_FILTER",
	SET_MOBILE_NAVIGATION: "SET_MOBILE_NAVIGATION",
	SET_FORGOT_PASSWORD_EMAIL: "SET_FORGOT_PASSWORD_EMAIL",
	CLEAR_FORGOT_PASSWORD_EMAIL: "CLEAR_FORGOT_PASSWORD_EMAIL",
	SET_EMAIL_FOR_VERIFY_EMAIL_OTP: "SET_EMAIL_FOR_VERIFY_EMAIL_OTP",
	CLEAR_EMAIL_FOR_VERIFY_EMAIL_OTP: "CLEAR_EMAIL_FOR_VERIFY_EMAIL_OTP",
	SET_STAFF_DATE_FILTER: "SET_STAFF_DATE_FILTER",
	SET_EXPENSES_DATE_FILTER: "SET_EXPENSES_DATE_FILTER",
	SET_ORDER_DATE_FILTER: "SET_ORDER_DATE_FILTER",
	SET_WIP_DATE_FILTER: "SET_WIP_DATE_FILTER",
	SET_RESTOCK_PRODUCTS: "SET_RESTOCK_PRODUCTS",
	CLEAR_RESTOCK_PRODUCTS: "CLEAR_RESTOCK_PRODUCTS"
}


const globalReducer = (state, action) => {
	switch (action.type) {
		case globalReducerActions.SET_TABLE_DATA:
			return { ...state, tableId: action.tableId, tableTrackId: action.tableTrackId }

		case globalReducerActions.CLEAR_TABLE_DATA:
			return { ...state, tableId: "", tableTrackId: "" }

		case globalReducerActions.SET_MODAL_CONNECTOR:
			return {
				...state,
				modalConnector: {
					toModalName: action.toModalName,
					fromModalName: action.fromModalName,

					toModalURL: action.toModalURL,
					fromModalURL: action.fromModalURL,
					fromModalFormValues: action.fromModalFormValues
			}}

		case globalReducerActions.CLEAR_MODAL_CONNECTOR:
			return {
				...state,
				modalConnector: {
					toModalName: "",
					fromModalName: "",

					toModalURL: "",
					fromModalURL: "",
					fromModalFormValues: "",
				}
			}

		case globalReducerActions.SET_MOBILE_NAVIGATION:
			return { ...state, isMobileDrawer: !state.isMobileDrawer }
		
		case globalReducerActions.SET_FORGOT_PASSWORD_EMAIL:
			return { ...state, forgotPasswordEmail: action.email }
		
		case globalReducerActions.CLEAR_FORGOT_PASSWORD_EMAIL:
			return { ...state, forgotPasswordEmail: "" }
		
		case globalReducerActions.SET_EMAIL_FOR_VERIFY_EMAIL_OTP:
			return { ...state, emailForVerifyEmailOTP: action.email }
		
		case globalReducerActions.CLEAR_EMAIL_FOR_VERIFY_EMAIL_OTP:
			return { ...state, emailForVerifyEmailOTP: "" }

		case globalReducerActions.SET_PACKAGE_ID:
			return { ...state, packageId: action.packageId, packagePeriod: action.period, referralCode: action.referralCode }

		case globalReducerActions.SET_DASHBOARD_DATE_FILTER:
			return { ...state, dashboardDateFilterType: action.filterType }

		case globalReducerActions.SET_STAFF_DATE_FILTER:
			return { ...state, staffDateFilterType: action.filterType }

		case globalReducerActions.SET_EXPENSES_DATE_FILTER:
			return { ...state, expensesDateFilterType: action.filterType }
		
		case globalReducerActions.SET_ORDER_DATE_FILTER:
			return { ...state, orderAnalyticsDateFilterType: action.filterType }
		
		case globalReducerActions.SET_WIP_DATE_FILTER:
			return { ...state, wipDateFilterType: action.filterType }
		
		case globalReducerActions.SET_RESTOCK_PRODUCTS:
			return { ...state, restockProducts: action.products }
		
		case globalReducerActions.CLEAR_RESTOCK_PRODUCTS:
			return { ...state, restockProducts: [] }
		
		default:
			return state
	}
}

export const globalReducerInitialValues = {
	tableId: "",
	tableTrackId: "",
	isMobileDrawer: false,
	forgotPasswordEmail: "",
	emailForVerifyEmailOTP: "",
	packageId: "",
	packagePeriod: "",
	referralCode: "",
	modalConnector: {
		toModalName: "",
		fromModalName: "",

		toModalURL: "",
		fromModalURL: "",
		fromModalFormValues: "",
	},
	restockProducts: [],
	dashboardDateFilterType: dateFilter[3].name,
	staffDateFilterType: dateFilter[2].name,
	expensesDateFilterType: dateFilter[2].name,
	orderAnalyticsDateFilterType: dateFilter[2].name,
	wipDateFilterType: dateFilter[3].name,
}

const useGlobalReducer = () => {
	return useReducer(globalReducer, globalReducerInitialValues)
}

export default useGlobalReducer
import {BusinessSettingsStyled} from "./settingsStyles";
import {Heading} from "../../../styles/textStyles";
import {useUpdateUserNotificationMutation, useUserProfile} from "../../../hooks/useServices/userHooks";
import {Button, CheckBox, Form, ScreenLoading} from "../../../components";
import {useBusinessSettingMutation} from "../../../hooks/useServices/useBusinessSettingServices";
import {notificationPermissionCode, notificationPermissionCodeName} from "../../../data/permissions/actionsPermissions";


const UserNotifications = () => {
  const {data: userProfile, isSuccess: isUserSuccess, status, refetch} = useUserProfile()
  const updateBusinessSettingsMutation = useBusinessSettingMutation()
  const updateUserNotificationMutation = useUpdateUserNotificationMutation()
  
  const handleBusinessSettings = (values) => {
    updateUserNotificationMutation.mutate({id: userProfile?.[0]?.user_notification_id, body: values})
  }
  
  
  const getNotificationsList = () => {
    if (userProfile?.[0]?.user_type === "client_admin") {
      return Object.keys(userProfile?.[0]?.user_notification).map(notification_name => {
        return {
          notification_name,
          value: userProfile?.[0]?.user_notification[notification_name],
          name: notificationPermissionCodeName[notificationPermissionCode[notification_name]]
        }
      })
    }
    
    const allAvailableNotificationsCode = Object.values(notificationPermissionCode)
    const allNotificationsAssignedToUser = userProfile?.[0]?.user_permission.filter(userPermissionCode => allAvailableNotificationsCode.includes(userPermissionCode))
      .map(notificationCode => {
        for (let key in notificationPermissionCode) {
          if (notificationPermissionCode[key] === notificationCode) {
            return key
          }
        }
      })
    
    return allNotificationsAssignedToUser.map(notification_name => {
      return {
        notification_name,
        value: userProfile?.[0]?.user_notification[notification_name],
        name: notificationPermissionCodeName[notificationPermissionCode[notification_name]]
      }
    })
  }
  
  return (
    <BusinessSettingsStyled>
      <Heading size={1.45} color="black">{userProfile?.[0]?.business_name} Settings</Heading>
      
      {(status === "error") && (
        <ScreenLoading refetchFn={refetch}/>
      )}
      
      {(isUserSuccess) && (
        <Form values={{}} onSubmit={handleBusinessSettings}>
          {getNotificationsList().map((notification) => (
            <CheckBox
              key={notification.notification_name}
              optional
              name={notification.notification_name}
              checked={notification.value}
              labelProp={notification.name}
            />
          ))}
          
          <Button text="Update Settings" type="submit" isLoading={updateBusinessSettingsMutation.isLoading}/>
        </Form>
      )}
    </BusinessSettingsStyled>
  )
}

export default UserNotifications
const commissionAndDiscountTypeOptions = [
	{ name: "amount" },
	{ name: "percentage" }
]

const commissionAndDiscountTypeOptionsForStages = [
	{ name: "amount" },
	{ name: "percentage" }
]

export const depleteFaultyProductTypes = [
	{name: "Accept", value: "accept"},
	{name: "Reject", value: "reject"},
	{name: "Destroy", value: "destroy"}
]

export const productSaleType = [
	{name: "Normal", value: false},
	{name: "Faulty Product", value: true}
]

export const percentageAndDiscountTypeOptions = [
	{ name: "Amount", value: "amount" },
	{ name: "Percentage", value: "percentage" }
]

const unitMeasurementOptions = [
	{name: "Each", id: "each_id"},
	{name: "Box", id: "box_id" },
	{name: "Pack", id: "pack_id" },
	{name: "Pair", id: "pair_id" },
	{name: "Carton", id: "cartoon_id" },
	{name: "Crate", id: "crate_id" },
	{name: "Plate", id: "plate_id" },
]

export { commissionAndDiscountTypeOptions, commissionAndDiscountTypeOptionsForStages, unitMeasurementOptions }
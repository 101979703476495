import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createStaffService,
  getSingleStaffByIdService,
  getStaffByBusinessIdService,
  editStaffService, deleteStaffService, getStaffDashboardService, getAllStaffService, resetStaffPassword
} from "../../../services/staffServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import staffQueryKey from "./staffQueryKey";
import errorHandler from "../../../utils/errorHandler";



export const useAllStaffsQuery = (searchValue = "") => {
  return useQuery({
    queryKey: ["STAFFS", searchValue],
    queryFn: () => getAllStaffService(searchValue)
  })
}

const useStaffByBusinessIdQuery = (searchValue) => {
  const { getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()

  return useQuery({
    queryKey: staffQueryKey.mainList(searchValue),
    queryFn: () => getStaffByBusinessIdService(businessId, searchValue)
  })
}

const useSingleStaffByIdQuery = (staffId) => {
  // window.scrollTo(0, 0)

  return useQuery({
    queryKey: staffQueryKey.byId(staffId),
    queryFn: () => getSingleStaffByIdService(staffId)
  })
}


const useStaffDashboardQuery = (staffId) => {
  window.scrollTo(0, 0)
  
  return useQuery({
    queryKey: staffQueryKey.analyticsById(staffId),
    queryFn: () => getStaffDashboardService(staffId)
  })
}

const useCreateStaffMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createStaffService({ ...data })
    },

    onSuccess: () => {
      successFn()
      toast.success("Staff Created!!")
      queryClient.invalidateQueries({ queryKey: staffQueryKey.mainListKey })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditStaffMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  let staffId = ""
  
  return useMutation({
    mutationFn: data => {
      staffId = data.id
      return editStaffService(data.id, { ...data })
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Staff Updated!!")

      queryClient.invalidateQueries({ queryKey: staffQueryKey.mainListKey })
      queryClient.refetchQueries({ queryKey: staffQueryKey.byId(staffId) })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteStaffMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: staffId => {
      return deleteStaffService(staffId)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Staff Deleted!!")
      queryClient.invalidateQueries({ queryKey: staffQueryKey.mainListKey })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useResetStaffPasswordMutation = ({ successFn }) => {
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return resetStaffPassword(body)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Staff Password Reset!!")
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export {
  useStaffByBusinessIdQuery, useSingleStaffByIdQuery, useStaffDashboardQuery,
  useCreateStaffMutation, useEditStaffMutation,useDeleteStaffMutation
}
const transformObjectValuesToArray = (data, keys = []) => {
  return keys.map(({ name }) => {
    return data[name]
  })
}

const transformActivePermissionsToArray = (obj) => {
  const keysArray = []
  for (const keys in obj) {
    // this checks if the permission is set to true,
    // and if the last char in the permission code is a number (handle for when last char is 0 to avoid coercion)
    if((obj[keys] === true) && (parseInt(keys[keys.length - 1]) || keys[keys.length - 1] === "0" ) ) {
      keysArray.push(keys)
    }
  }

  return keysArray
}

const transformPermissionsToObject = (permissionArray = []) => {
  const permissionObj = {}
  permissionArray.forEach(item => {
    permissionObj[item] = true
  })

  return permissionObj
}

const transformObjKeyToValue = (obj) => {
  const keysArr = Array.from(Object.keys(obj))

  return keysArr.map(item => {
    const stripItem = item.split("_").join(" ")
    return { item:  stripItem, value: obj[item]}
  })
}

const transformStringToDate = (value) => {
  const newDate = new Date(value).toString()
  return newDate.slice(0, 15)
}

export {
  transformObjKeyToValue,
  transformStringToDate,
  transformPermissionsToObject,
  transformActivePermissionsToArray,
  transformObjectValuesToArray
}
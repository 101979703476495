import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";

const businessId = getBusinessIdFromStorage()

const key = "customers"
const withBusinessId = [key, businessId]

const customerQueryKey = {
	all: [...withBusinessId],
	byId: (id) => [...withBusinessId, "single customer", id],
	walletLog: (id, transactionType, fromDate, toDate) => [...withBusinessId, "wallet", id, {
		transactionType,
		fromDate,
		toDate
	}],
	byIdAnalytics: (id, params = {}) => [...withBusinessId, id, "analytics", getAllObjectKeysWithValues(params)],
	mainList: (searchValue) => [...withBusinessId, "all customers", searchValue],
	customerOrders: (id, params = {}) => [...withBusinessId, id, "orders", getAllObjectKeysWithValues(params)],
	allSubscriptions: [...withBusinessId, "all_subscriptions"],
	allSubscriptionsQuery: (search) => [...withBusinessId, "all_subscriptions", search],
	subscription: (customerId) => [...withBusinessId, "all_subscriptions", customerId],
}

export default customerQueryKey

import {ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {StoreFrontAuthModalStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Button, Form, Image, Input, PasswordInput} from "../../../../components";
import {Logo} from "../../../../assets/images";
import {useBusinessByIdQuery} from "../../../../hooks/useServices/useStoreServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {SpaceBetweenStyled} from "../../../../styles/utilStyles";


const StoreFrontRegisterModal = ({ onClose, handleSubmit, mutation, handleSwitchOtherAuthPage }) => {
  const { data: store } = useBusinessByIdQuery()
  const { toast } = useGlobalContext()
  
  const onSubmit = (formValues) => {
    const [first_name, ...last_name] = formValues.full_name.split(" ")
    
    if(last_name.length < 1) {
      toast.error("Enter your full name with a space in-between", "name_error")
      return
    }
    
    if(formValues.password !== formValues.confirm_password) {
      toast.error("Both password does not match", "password_error")
      return
    }
    
    handleSubmit({ ...formValues, first_name, last_name: last_name.join(" ") })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <StoreFrontAuthModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <div className="img_div">
          <Image src={store?.image_path || Logo} alt="Ovaloop logo" />
        </div>
        
        <div className="intro">
          <Heading size={1.1} color="black" bold>Create an account</Heading>
          <Paragraph color="black">Welcome! Please enter your details to proceed.</Paragraph>
        </div>
        
        <Form values={{ email: "", password: "", confirm_password: "", full_name: "", address: "", phone_number: "" }} onSubmit={onSubmit}>
          <Input placeholder="Enter your full name" label="Full Name" name="full_name" />
          <Input type="email" placeholder="Enter your email address" label="Email Address" name="email" />
          <PasswordInput placeholder="Enter your password" label="Password" name="password" required />
          <PasswordInput placeholder="Enter to confirm password" label="Confirm Password" name="confirm_password" required />
          <Input type="number" placeholder="Enter your phone number" label="Phone Number" name="phone" />
          <Input placeholder="Enter your address for delivery" label="Address" name="address" />
  
          <SpaceBetweenStyled>
            <p></p>
            <Paragraph color="black">Already have an account? <u onClick={handleSwitchOtherAuthPage}>Login</u></Paragraph>
          </SpaceBetweenStyled>
          <br/>
          
          <Button type="submit" text="Create Account" isLoading={mutation.isLoading} />
        </Form>
      </StoreFrontAuthModalStyled>
    </ModalLayoutStyled>
  )
}

export default StoreFrontRegisterModal
import styled from "styled-components";
import {ButtonStyled, CheckBoxStyled, InputStyled} from "./formStyles";
import {CreditCardBackgroundImage} from "../assets/images";
import {Heading, Heading2, Heading4, Paragraph} from "./textStyles";
import {ListDropDownStyled, MenuListStyled} from "./listStyles";
import {FlexStyled, ImageStyled, LineStyled, SpaceBetweenStyled} from "./utilStyles"


const QuoteCreatorStyled = styled(FlexStyled)`
  color: white;
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  h6 {
    margin-top: .3rem;
    color: ${props => props.theme.gray};
  }
`

const UserNavigationProfileStyled = styled(FlexStyled)`
  gap: 2rem;
  > div {
    text-align: right;
    ${Heading4} {
      margin-bottom: -.8rem;
      font-size: 1.1rem;
    }
    
    p {
      font-size: .95rem;
      color: ${props => props.theme.darkGray};
    }
  }

  svg.network_status {
    margin-bottom: -.4rem;
    padding: 0 !important;
  }
  
  > p {
    font-size: 1.1rem;
    color: white;
    padding: .5rem .8rem;
    border-radius: 20%;
    background-color: ${props => props.theme.darkGray};
  }
  
  ${Paragraph}.user_icon {
    display: none;
  }

  svg {
    top: .2rem;
    position: relative;
    cursor: pointer;
    background: transparent !important;
  }

  .tool_tip_chip_text {
    font-size: .79rem !important;
    padding: .04rem .175rem;
    color: white !important;
    background-color: red;
    border-radius: 50%;
  }

  ${FlexStyled} {
    > ${MenuListStyled} {
      padding: 0;
      
      a {
        text-decoration: none;
      }
      
      :hover {
        border-left: none;
        background: transparent;
      }
      
      div.container {
        position: relative;
        padding-top: 4.5rem;
        
        ${ListDropDownStyled} {
          top: initial;
          left: -18vw;
          width: 19vw;
          
          svg {
            margin-left: -.35rem;
          }
          
          img {
            padding: .4rem;
          }
        }
      }
      
      ${ListDropDownStyled} {
        top: 7vh;
        left: -12rem;
        width: fit-content;
      }
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    > div {
      display: none;
    }
    
    > p {
      background-color: ${props => props.theme.black};
    }
  
    ${Paragraph}.user_icon {
      display: initial;
    }
  }
`

const OverviewCardStyled = styled("div")`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  height: 7rem;
  border-radius: .7rem;
  width: calc(24% - 2rem);
  position: relative;
  background: ${props => props.bgColor};
  //transform: matrix(-1, 0, 0, 1, 0, 0);
  
  ${SpaceBetweenStyled} {
    gap: .3rem;
    align-items: center;
    transform: inherit;
    height: 100%;
    width: 100% !important;
    
    * {
      margin: 0;
      padding: 0;
    }
    
    ${Paragraph} {
      margin-top: .4rem;
    }
  }
  
  ${Heading2} {
    margin-bottom: 0 !important;
  }
  
  ${ImageStyled} {
    right: 0;
    opacity: .3;
    position: absolute;
  }
  
  img {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(80vw - 2rem) !important;
    margin-bottom: 1.5rem;
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(33vw - 1rem);
  }
`

const CategoryCardStyled = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: ${props => props.$big ? "4rem" : "2rem"};
  padding: 1rem .87rem;
  border-radius: .3rem;
  width: calc(21.25% - 1.2rem);
  color: ${props => props.$active ? props.theme.white : props.theme.black};
  background-color: ${props => props.$randomColor ? props.theme.categoryCards[props.$colorIndex] : props.$active ? props.theme.disabled : "#DDD6FC"};
  
  ${props => !!props.$icon && `
    gap: .6rem;
    flex-direction: row;    
  `}

  span.overlay {
    display: contents;
  }
  
  :hover {
    span.overlay {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      position: absolute;
      border-radius: .3rem;
      opacity: .1;
      background-color: ${props => props.theme.black};
    }
  }
`

const ProductCardStyled = styled("div")`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding: .6rem;
  margin-bottom: 1rem;
  border-radius: .3rem;
  background-color: white;
  width: calc(20% - .8rem - 1.2rem) !important;
  border: 1px solid ${props => props.theme.inputBGDark};
  
  > div > ${Heading} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${Heading} {
    line-height: 1.5rem;
  }
  
  .img_div {
    height: 155px;
    margin-bottom: 1rem;
    max-width: 100%;
    
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    ${ImageStyled} {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 1rem;
    
      // @media screen and (min-height: ${props => props.theme.largeScreenByHeight}) {
      //   height: 120px;
      //   max-height: 120px;
      // }
    }
  }

  ${SpaceBetweenStyled} {
    align-items: flex-start;
    flex-direction: column;
    margin-top: .7rem;
  }

  ${ButtonStyled}.add_to_cart {
    color: #6c6a6a;
    margin-top: 1rem;
    font-size: .85rem;
    border-radius: .2rem;
    padding: .6rem .5rem .4rem;
    background-color: transparent;
    border: 1px solid ${props => props.theme.shadow};

    :hover {
      color: white;
      background-color: ${props => props.theme.black};
    }
  }
  
  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(50% - 1.4rem - .5rem) !important;
  }
  
`


export const ProductCard2Styled = styled("div")`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  padding: .6rem;
  margin-bottom: 1rem;
  border-radius: .3rem;
  background-color: white;
  width: calc(48% - .8rem - 1.2rem) !important;
  border: 1px solid ${props => props.theme.inputBGDark};

  > ${FlexStyled} {
    margin-top: .5rem;
    align-items: flex-start;
  }
  
  .img_div {
    width: 90px;
    height: 75px;
    margin-bottom: 1rem;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    ${ImageStyled} {
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 1rem;
    }
  }
  
  section {
    gap: .5rem;
    display: flex;
    flex-direction: column;
    width: calc(100% - 120px);
    
    ${Heading} {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      white-space: normal;
    }
  }

  ${ButtonStyled}.add_to_cart {
    width: 40%;
    color: #6c6a6a;
    margin-top: 1rem;
    font-size: .85rem;
    border-radius: .2rem;
    padding: .6rem .5rem .4rem;
    background-color: transparent;
    border: 1px solid ${props => props.theme.shadow};

    :hover {
      color: white;
      background-color: ${props => props.theme.black};
    }
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(100% - 1.4rem - .5rem) !important;

    ${ButtonStyled}.add_to_cart {
      width: 60%;
    }
  }
`

export const NotificationCardStyled = styled("div")`
  padding: .6rem;
  margin-top: .8rem;
  border: 1px solid lightgray;
  background: white;
  cursor: pointer;
`

const ProductCardCountStyled = styled(FlexStyled)`
  gap: .3rem;
  justify-content: space-between;
  width: 100%;
  margin-top: .5rem;
  
  ${Paragraph} {
    font-family: Medium, serif;
  }
  
  ${ButtonStyled} {
    width: 30% !important;
    height: 2.2rem;
    border-radius: 0;
    padding: 0 .2rem !important;
    font-size: 1.2rem;
    border: 1px solid gray;
    font-family: Light, serif;
    background-color: transparent;
    color: ${props => props.theme.black};
    
    :disabled {
      color: lightgray !important;
      border: 1px solid lightgray !important;
    }
  }
  
  ${InputStyled} input {
      border: none !important;
      border-bottom: 1px solid black !important;
      border-radius: 0 !important;
      padding: .1rem !important;
      width: 1.7rem !important;
      -moz-appearance: textfield;

      ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  }
`

const CartProductCardStyled = styled("div")`
  padding: .5rem .5rem 0 .3rem;
  background: ${props => !!props.$returned && props.theme.inputBGDark};
  
  ${SpaceBetweenStyled}.image_and_details ${FlexStyled} {
    gap: .8rem;
    flex-wrap: nowrap;
    align-items: flex-start;
    
    ${ImageStyled} {
      max-width: 80px;
      max-height: 90px;
    }
    
    div.title_and_price {
      gap: .1rem;
      display: flex;
      flex-direction: column;
    }
  }
  
  ${SpaceBetweenStyled}.subtotal_and_remove_from_cart {
    padding-right: .7rem;
    
    ${FlexStyled}.subtotal {
      margin-top: .3rem;
      gap: .5rem;
    }
    
    svg {
      margin-bottom: -.2rem;
      cursor: pointer;
    }
  }
  
  ${LineStyled} {
    margin-top: 1rem;
  }
  
  ${FlexStyled}.add_ons {
    gap: .6rem;
    margin: .5rem 0 1rem;
  }
`

const PageSummaryCardStyled = styled("div")`
  padding: 1.3rem .7rem;
  border-radius: .5rem;
  border: 1px solid ${props => props.theme.shadow};
  background-color: white;
  width: calc(24% - 1.4rem);
  //min-height: 4.2rem;
  
  ${SpaceBetweenStyled}.space {
    width: 100%;
  }
  
  > ${FlexStyled} {
    gap: .5rem .2rem;
    flex-direction: column;
    align-items: flex-start;
    
    ${FlexStyled}.space_out {
      width: 80%;
      justify-content: flex-end;
      
      ${Paragraph} {
        width: 35%;
        padding: .2rem .2rem .2rem;
        border-radius: .8rem;
        // color: ${props => props.theme.success};
        //background-color: ${props => props.theme.lightSuccess};
      }
    }

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      gap: 1.7rem;
    }
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    width: calc(33% - 1rem) !important;
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: ${props => props.$only ? "calc(100% - 1.4rem - 2px)" : "calc(85% - 1.4rem - 2px)"} !important;
  }
`

const WalletDetailsCardStyled = styled(SpaceBetweenStyled)`
  align-items: center;
  padding: 0 1rem;
  height: 13.5rem;
  border-radius: 1.2rem;
  background-color: white;
  width: calc(32% - 2rem);

  > ${FlexStyled} {
    flex-direction: column;
    align-items: flex-start;
  }
  
  ${ImageStyled} {
    padding: .6rem;
    margin-bottom: 0;
    border-radius: 50%;
    //background-color: ${props => props.theme.black};
  }
`

const CreditCardStyled = styled(FlexStyled)`
  align-items: flex-start;
  flex-wrap: nowrap;
  //justify-content: space-between;
  flex-direction: column;
  padding: .8rem 1.5rem;
  border-radius: 1.2rem;
  width: calc(32% - 4rem);
  height: fit-content;
          //${props => props.$small ? "calc(10rem - 1.6rem)" : "fit-content"};
  background: url(${CreditCardBackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  
  * {
    margin: 0;
  }
  
  ${SpaceBetweenStyled} {
    width: 100%;
  }
  
  .card_icon {
    text-align: right;
    width: 100%;
  }
  
  .wallet_balance {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
  
  .card_details {
    width: 100%;
    gap: 0 !important;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: .6rem;
    
    svg {
      top: .2rem;
      left: .3rem;
      position: relative;
      cursor: pointer;
    }
  }

  .card_details_reload {
    margin-top: .3rem;
    
    .reload_icon {
      color: black;
      cursor: pointer;
      padding: .2rem;
      border-radius: 50%;
      background-color: white;
    }
  }
`

const OrderDetailsCardStyled = styled("div")`
  width: ${props => props.$half ? `calc(49% - 2rem)` : `calc(32% - 1.6rem)`};
  padding: 1.5rem .8rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.inputBGDark};

  ${LineStyled} {
    margin: 1rem 0;
  }

  ${SpaceBetweenStyled} {
    margin-top: 1.2rem;
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    margin-bottom: 1.5rem;
    width: 100% !important;
  }
`

const CustomerAndStaffReviewCardStyled = styled(FlexStyled)`
  gap: .3rem;
  padding-right: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  border-right: ${props => !props.$noBorder && `1px solid ${props.theme.inputBGDark}`};
  
  ${Heading2} {
    gap: .5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    
    span {
      margin-top: -.2rem;
      padding: .45rem .43rem;
      border-radius: 50%;
      background-color: ${props => props.$statusColor};
    }
  }
  
  ${Heading2}.value {
    font-size: 1.4rem;
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: calc(100% - .2rem);
    padding-right: 1rem;
    
    ${Heading2} {
      font-size: 1.4rem !important;
    }
  }
`

const SalesCardStyled = styled(SpaceBetweenStyled)`
  cursor: pointer;
  margin: 2rem 0;
  
  ${FlexStyled}.image_title_and_date {
    gap: .7rem;
    ${ImageStyled} {
      width: 45px !important;
      height: 42px !important;
      margin-bottom: 0;
      border-radius: 50%;
    }
  }
`

const StoreCardStyled = styled(FlexStyled)`
  gap: 2rem;
  height: 22vh;
  cursor: pointer;
  padding: 1rem .5rem;
  align-items: center;
  border-radius: .7rem;
  flex-direction: column;
  width: calc(30% - 1rem);
  max-width: 250px;
  max-height: 250px;
  justify-content: center;
  border: 1px solid ${props => props.theme.shadow};
  
  ${Heading2} {
    margin: 0 !important;
    font-size: 2rem;
    width: fit-content;
    border-radius: 50%;
    padding: .7em 1em;
    background: ${props => props.theme.black};
  }
  
  :hover {
    border: 1px solid ${props => props.theme.black};
  }
  
  @media screen and (max-width: ${props => props.theme.largeScreenByWidth}) {
    text-align: center;
    ${Heading2} {
      padding: .65em .82em;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 2rem .4rem;
    width: 43%;
    
    ${Heading2} {
      padding: 1rem 1.4rem;
    }
  }
`

const TableCardStyled = styled(FlexStyled)`
  gap: 2rem;
  padding: .8rem;
  cursor: pointer;
  border-radius: .3rem;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${props => props.theme.disabled};
  width: calc(23.5% - 1.6rem);
  background: ${props => props.$status === "occupied" ? props.theme.disabled : "white"};

  ${Paragraph}.status {
    top: -.1rem;
    position: relative;
  }
  
  ${SpaceBetweenStyled} {
    width: 100%;
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    width: 100%;
  }
`

const WipCardStyled = styled(FlexStyled)`
  gap: 2rem;
  padding: .8rem;
  cursor: pointer;
  border-radius: .3rem;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${props => props.theme.disabled};
  width: calc(23.5% - 1.6rem);
  background: ${props => props.theme.categoryCards[props.$colorIndex]};

  ${Paragraph}.status {
    top: -.1rem;
    position: relative;
  }

  ${SpaceBetweenStyled} {
    width: 100%;
  }
  
  td h2 {
    gap: .3rem;
    display: flex;
    align-items: center;
    
    ${ImageStyled} {
      margin-bottom: .3rem;
    }
  }
`

const StaffRolesPermissionCardStyled = styled("div")`
  ${CheckBoxStyled}.head {
    margin-bottom: 1.5rem;
  }
`

export const FlexColumnStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${props => props.$gap ? `${props.$gap}rem` : "1rem" }
`


export const FlexRowStyled = styled("div")`
  display: flex;
  align-items: center;
  gap: ${props => props.$gap ? `${props.$gap}rem` : "1rem" }
`


export const SubscriptionCardStyled = styled("div")`
	padding: 1rem;
	width: calc(100% / 4 - 2rem);
	border-radius: .3rem;
	background: ${props => props.theme.background};
	
	${FlexColumnStyled}.subscription_summary {
    margin-top: 2rem;
	}
	
	${ButtonStyled} {
		width: 100%;
		margin: 1.5rem 0;
	}
	
	@media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
		width: calc(100% - 2rem);
	}
	
	@media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
		width: calc(100% / 2 - 3rem);
	}

  @media screen and (min-height: ${props => props.theme.largeDesktopBreakPointHeight}) {
	  padding: 2rem 1rem 1rem;
		${FlexRowStyled}.header {
			img {
				width: 50px;
				height: 50px;
			}
		}

    ${FlexColumnStyled}.subscription_summary {
			${Heading4} {
			
			}
    }
    
    ${FlexColumnStyled}.features {
	    gap: 1.5rem;
	    
	    ${FlexRowStyled} {
		    gap: 1rem;
	    }
	    
	    svg {
		    font-size: 1.5rem !important;
	    }
    }

    ${ButtonStyled} {
      width: 100%;
	    font-size: 1.7rem;
	    padding: 1.5rem !important;
      margin: 2rem 0;
	    border-radius: .5rem;
    }
  }
`


export const HoldOrderCardStyled = styled("div")`
  cursor: pointer;
  padding: .8rem .8rem;
  background: lightgray;
  width: calc(100% - 1.6rem);
  margin-bottom: 1rem !important;
  
  > ${SpaceBetweenStyled}:first-of-type{
    margin-bottom: .8rem;
  }
  
  ${SpaceBetweenStyled}:last-of-type {
    margin-top: 1rem;
  }
`



export {
  StoreCardStyled,
  CreditCardStyled,
  PageSummaryCardStyled,
  CartProductCardStyled,
  ProductCardCountStyled,
  CategoryCardStyled,
  OverviewCardStyled,
  QuoteCreatorStyled,
  ProductCardStyled,
  SalesCardStyled,
  TableCardStyled,
  WipCardStyled,
  OrderDetailsCardStyled,
  WalletDetailsCardStyled,
  UserNavigationProfileStyled,
  StaffRolesPermissionCardStyled,
  CustomerAndStaffReviewCardStyled
}
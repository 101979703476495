import {CreateStoreModalStyled, ManageSubscriptionModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Button, Form, Line, Select} from "../../index";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";

const ManageSubscriptionModal = ({ onClose, handleSubmit, mutation }) => {
  const { currencyFormatter } = useDataTypeFormatter()

  const Item = () => (
    <SpaceBetweenStyled className="row header">
      <Paragraph size={1.1} color="black">Item</Paragraph>
      <Paragraph size={1.1} color="black">
        {currencyFormatter(1200)} /user/year
      </Paragraph>
      <div>
        <Select
          options={options}
          valueKey="value"
          displayKey="name"
          displayName="display_name"
          placeholder="No. of units"
        />
      </div>
    </SpaceBetweenStyled>
  )

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={25} onClick={onClose} />
        <Heading size={1.3} color="black">Manage Subscription</Heading>

        <ManageSubscriptionModalStyled>
          <Form values={{}} onSubmit={handleSubmit}>
            <SpaceBetweenStyled className="row header">
              <Heading size={1.1} color="black">Item</Heading>
              <Heading size={1.1} color="black">Unit Price</Heading>
              <Heading size={1.1} color="black">No. Of Units</Heading>
            </SpaceBetweenStyled>

            <Line />

            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />

            <FlexStyled>
              <Button text="Submit" isLoading={mutation.isLoading}/>
            </FlexStyled>
          </Form>
        </ManageSubscriptionModalStyled>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default ManageSubscriptionModal

const options = [
  { name: "1", value: "1"},
  { name: "2", value: "1"},
  { name: "3", value: "1"},
  { name: "4", value: "1"},
  { name: "5", value: "1"},
  { name: "6", value: "1"},
]

import {useMutation, useQuery, useQueryClient} from "react-query";
import {
	createProductExpiryService, deleteProductExpiryService,
	updateProductExpiryService,
	getProductExpiryServices
} from "../../../services/productExpiryServices";
import {getBusinessIdFromStorage, scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";


const productExpiryKey = ["Product Expiry Key", getBusinessIdFromStorage()]

const productExpiryQueryKeys = {
	all: [...productExpiryKey],
	byProduct: (productId, queryParam = {}) => [...productExpiryKey, productId, getAllObjectKeysWithValues(queryParam)]
}


export const useProductExpiryQuery = (query = {}) => {
	scrollToTop()
	
	return useQuery({
		enabled: !!query.product,
		queryKey: productExpiryQueryKeys.byProduct(query.product, query),
		queryFn: () => getProductExpiryServices(query)
	})
}

export const useCreateProductExpiryMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return createProductExpiryService(data)
		},

		onSuccess: (_, variables) => {
			successFn()
			toast.success("Payment Method Created!!", "created")
			queryClient.invalidateQueries({ queryKey: productExpiryQueryKeys.byProduct(variables.product) })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useEditProductExpiryMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return updateProductExpiryService(data.id, data)
		},

		onSuccess: (_, variables) => {
			successFn()
			toast.success("Edit Successful!!", "edit")
			queryClient.invalidateQueries({ queryKey: productExpiryQueryKeys.byProduct(variables.product) })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useDeleteProductExpiryMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: customPaymentMethodId => {
			return deleteProductExpiryService(customPaymentMethodId)
		},

		onSuccess: (_, variables) => {
			successFn()
			toast.success("Delete Successful!!", "delete")
			queryClient.invalidateQueries({ queryKey: productExpiryQueryKeys.byProduct(variables.product) })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}
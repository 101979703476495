import {CloseSquare} from "iconsax-react";
import {Button, Form, Select} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import {useState} from "react";


const StartProductionProcessModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { numberFormatter } = useDataTypeFormatter()
  const { data: allStaffs } = useStaffByBusinessIdQuery()
  
  const [assignedStaffs, setAssignedStaffs] = useState([])

  const handleSubmission = (values) => {
    handleSubmit({
      product: values.producible_product,
      projected_quantity: Number(values.producing_quantity),
      stage: values.stage,
      batch_number: values?.tracking_number || "",
      assigned_to: assignedStaffs.map(staff => ({ id: staff.id, name: staff.full_name })),
    })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Start Production</Heading>
        <Form values={formValues} onSubmit={handleSubmission}>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Product:</Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.producible_product_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Estimated Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.producing_quantity)} {formValues.producible_product_unit_measurement}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Batch Number:</Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.tracking_number}</Paragraph>
          </FlexStyled>
  
          <Select
            options={formValues.product_stages || []}
            name="stage"
            displayKey="name"
            valueKey="id"
            label="Production Stage"
            placeholder="Select production stage"
            displayName="stage_name"
          />
  
          <MultiSelectInput
            className="fullWidth"
            displayName="full_name"
            setSelected={setAssignedStaffs}
            options={allStaffs?.results || []}
            label="Assign staff"
            placeholder="Assign staffs to this stage"
          />
          
          <FlexStyled>
            <Button text="Start" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default StartProductionProcessModal
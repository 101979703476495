import {useLocation} from "react-router-dom";
import useGlobalContext from "../useContexts/useGlobalContext";
import { globalReducerActions } from "../../reducers/globalReducer";
import uuid from "react-uuid";



const useFromModalConnector = () => {
	const { pathname } = useLocation()
	const { globalReducer, navigate } = useGlobalContext()

	return ({ fromModalFormValues, toModalName, fromModalName, toModalURL }) => {
		globalReducer({
			toModalName,
			fromModalName: fromModalName || uuid(),

			toModalURL,
			fromModalURL: pathname,

			fromModalFormValues,
			type: globalReducerActions.SET_MODAL_CONNECTOR,
		})

		navigate(toModalURL)
	}
}

export const useClearModalConnector = () => {
	const { globalReducer } = useGlobalContext()
	
	return () => {
		globalReducer({
			type: globalReducerActions.CLEAR_MODAL_CONNECTOR
		})
	}
}


const useProgressModalConnector = () => {
	const { globalReducer, navigate, globalState: { modalConnector } } = useGlobalContext()

	return ({ toModalName, toModalURL }) => {
		globalReducer({
			...modalConnector,

			toModalName,
			toModalURL,
			type: globalReducerActions.SET_MODAL_CONNECTOR,
		})

		navigate(toModalURL)
	}
}


export { useFromModalConnector, useProgressModalConnector }
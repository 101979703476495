import axios from "../../axios";


export const getCustomPaymentMethodServices = async (businessId, searchValue = ' ') => {
	try {
		const { data } = await axios.get(`/other_payment/?business_id=${businessId}&search=${searchValue}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const createCustomPaymentMethodService = async (body) => {
	try {
		const { data } = await axios.post(`/other_payment/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const updateCustomPaymentMethodService = async (id, body) => {
	try {
		const { data } = await axios.patch(`/other_payment/${id}`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const deleteCustomPaymentMethodService = async (id) => {
	try {
		const { data } = await axios.delete(`/other_payment/${id}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
import {ChipStyled, FlexStyled} from "../../styles/utilStyles";
import {Fragment} from "react";
import {Link} from "react-router-dom";
import {Paragraph} from "../../styles/textStyles";
import {CloseCircle} from "iconsax-react";
import {useActionPermission} from "../../hooks/usePermissions";

const Chips = ({ title, link, active, isAddon, onClick, permissionCode, noAddonAction, Icon }) => {
  const showButton = useActionPermission(permissionCode)
  
  return (
    <Fragment>
      {(showButton || !permissionCode) && (
        <Fragment>
          {!!link && (
            <ChipStyled active={active}>
              <Link to={link}>
                <Paragraph noTop color="black">{title}</Paragraph>
              </Link>
            </ChipStyled>
          )}
  
          {!link && (
            <ChipStyled active={active} $addon={isAddon} onClick={onClick && onClick}>
              <FlexStyled $gap={.7}>
                {Icon && <Icon size={18} />}
                <Paragraph noTop color="black">{title}</Paragraph>
              </FlexStyled>
              {(isAddon && !noAddonAction) && <CloseCircle size={16} color="white" /> }
            </ChipStyled>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Chips
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  getProductionRequestRawMaterialService,
  updateProductionRequestRawMaterialService,
  createProductionRequestRawMaterialService
} from "../../../services/productionServices/productionRequestRawMaterialServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import errorHandler from "../../../utils/errorHandler";


const mainKey = ["Production Raw Material", getBusinessIdFromStorage()]
const productionRequestRawMaterialQueryKeys = {
  all: mainKey,
  mainList: (query) => [...mainKey, getAllObjectKeysWithValues(query)]
}


export const useProductionRequestRawMaterialQuery = (query = {}) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: productionRequestRawMaterialQueryKeys.mainList(query),
    queryFn: () => getProductionRequestRawMaterialService({ ...query, business: getBusinessId() })
  })
}


export const useCreateProductionRequestRawMaterialMutation = ({ successFn }) => {
  const { getBusinessId } = useGlobalContext()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: body => {
      return createProductionRequestRawMaterialService({ ...body, business_id: getBusinessId() })
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Create Successful!!", "create")
      await queryClient.refetchQueries({ queryKey: productionRequestRawMaterialQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useUpdateProductionRequestRawMaterialMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  
  return useMutation({
    mutationFn: (body) => {
      return updateProductionRequestRawMaterialService(body)
    },
    
    onSuccess: async () => {
      successFn()
      toast.success("Update Successful!!", "update")
      await queryClient.refetchQueries({ queryKey: productionRequestRawMaterialQueryKeys.all })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
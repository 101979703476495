import {
  CreateCategoryModalStyled,
  ModalLayoutStyled,
  ReceiptModalStyled,
  ReceiptOrderTableStyled
} from "../../../styles/modalStyles";
import {AllCenterStyled, FlexColumnStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button} from "../../index";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {Fragment, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useCustomerByIdQuery} from "../../../hooks/useServices/useCustomerServices";
import Barcode from "react-barcode";
import moment from "moment"
import {formatPhoneNumber} from "../../../utils/textTransformer";


const ReturnedOrderReceipt = ({onClose, data: _data, logId}) => {
  const {currencyFormatter, numberFormatter} = useDataTypeFormatter()
  
  const data = _data?.[0]?.group_order?.returned_orders?.filter(order => !!logId ? order.order_id === logId : true)?.map(returned_order => {
    const productSingleOrder = _data?.find(order => order.product.name === returned_order.product_name)
    return {
      ...returned_order,
      product_cost: Number(productSingleOrder.unit_cost * returned_order.quantity),
      qty_text: productSingleOrder?.meta_measurement?.name
        ? `${returned_order.adjust_type === "increase" ? "+" : "-"} ${numberFormatter(returned_order.quantity)} ${productSingleOrder?.meta_measurement?.name}`
        : `${returned_order.adjust_type === "increase" ? "+" : "-"} ${numberFormatter(returned_order.quantity)} ${productSingleOrder?.product?.unit_measurement || ""}`
    }
  })
  
  const receiptRef = useRef(null)
  const {isMobile} = useGlobalContext()
  const {data: customerData} = useCustomerByIdQuery(data?.[0]?.customer?.id)
  
  const availableOrders = data.filter(order => !(order.returned === true && order.return_type === "order"))
  const orderSummary = availableOrders.reduce((prev, curr) => {
    const values = {...prev}
    values.totalTax = values.totalTax + Math.abs(Number(curr.tax_amount))
    values.totalCost = values.totalCost + (Number(curr?.product_cost_before_tax) || Number(curr?.product_cost) || 0)
    values.totalService = values.totalService + Number(curr.service_cost)
    values.totalDiscount = values.totalDiscount + Number(curr.discount_amount)
    values.subTotal = values.subTotal + (Number(curr?.product_cost_before_tax) || Number(curr?.product_cost) || 0)
    values.totalExchangeAmountValue = values.totalExchangeAmountValue + Number(curr?.exchange_value || 0)
    
    return values
  }, {subTotal: 0, totalTax: 0, totalService: 0, totalDiscount: 0, totalCost: 0, totalExchangeAmountValue: 0})
  
  const handlePrintReceipt = useReactToPrint({
    content: () => {
      const newReceiptNode = receiptRef.current.cloneNode(true)
      const actionEl = newReceiptNode.querySelector(".receipt_action")
      newReceiptNode.firstElementChild.lastElementChild.removeChild(actionEl)
      
      if (isMobile) {
        const closeActionEl = newReceiptNode.querySelector(".close_action")
        newReceiptNode.firstElementChild.lastElementChild.removeChild(closeActionEl)
      }
      
      return newReceiptNode
    }
  })
  
  const getTotalAmountPaid = () => {
    return data?.[0]?.bulk_details?.split?.reduce((prev, curr) => {
      return prev + Number(curr.amount)
    }, 0)
  }
  
  const customer = data?.[0]?.selected_customer?.first_name ? data?.[0]?.selected_customer : customerData
  const business_details = typeof _data?.[0]?.business === "string" ? (_data?.[0]?.business_details || {}) : (_data?.[0]?.business || {})
  
  const getPaymentLogs = () => {
    const transactions = data?.[0]?.bulk_details?.pay_log || data?.[0]?.bulk_details?.split || []
    const payment_mode = {cash: 0, transfer: 0, pos: 0}
    
    transactions.forEach(transaction => {
      const method = transaction.method.toLowerCase()
      payment_mode[method] = payment_mode[method] + Number(transaction.amount)
    })
    
    return [
      ...(!!payment_mode.pos ? [{method: "Pos", amount: payment_mode.pos}] : []),
      ...(!!payment_mode.cash ? [{method: "Cash", amount: payment_mode.cash}] : []),
      ...(!!payment_mode.transfer ? [{method: "Transfer", amount: payment_mode.transfer}] : []),
    ]
  }
  
  return (
    <ModalLayoutStyled onClick={onClose} $noScroll>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()} ref={receiptRef} className="print_receipt">
        {isMobile && <><br/><br/><br/></>}
        
        <ReceiptModalStyled>
          <FlexColumnStyled className="header">
            <Heading color="black" bold>{business_details?.store_name}</Heading>
            {business_details?.tagline &&
              <Heading color="black" className="phone"><i>{business_details?.tagline}</i></Heading>}
            {business_details?.address &&
              <Heading color="black" className="phone">{business_details?.address}</Heading>}
            {business_details?.phone &&
              <Heading color="black" className="phone">
                TEL:
                {` ${formatPhoneNumber(business_details?.phone)}${!!formatPhoneNumber(business_details?.phone2) ? `, ${formatPhoneNumber(business_details?.phone2)}` : ''}`}
              </Heading>
            }
          </FlexColumnStyled>
          
          <SectionStyled $mb={0}>
            
            <FlexColumnStyled $gap={.1}>
              <SpaceBetweenStyled>
                <Paragraph bold color="black" className="section_title">Rep:</Paragraph>
                <Paragraph bold color="black">{_data?.[0]?.staff?.name || _data?.[0]?.staff_name}</Paragraph>
              </SpaceBetweenStyled>
              
              {_data?.[0]?.table_name && (
                <SpaceBetweenStyled>
                  <Paragraph bold color="black" className="section_title">Table Name:</Paragraph>
                  <Paragraph bold color="black">{_data?.[0]?.table_name}</Paragraph>
                </SpaceBetweenStyled>
              )}
              
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Date Created:</Paragraph>
                <Paragraph color="black"
                           bold>{moment(_data[0]?.date_created).format('D MMM YYYY [at] h:mm A')}</Paragraph>
              </SpaceBetweenStyled>
              
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">MODIFIED ORDERS</Paragraph>
                <Paragraph color="black" bold></Paragraph>
              </SpaceBetweenStyled>
            </FlexColumnStyled>
            
            <FlexColumnStyled $gap={.2} className="order_details">
              {!!customer?.first_name && (
                <Fragment>
                  <SpaceBetweenStyled>
                    <Paragraph color="black" bold className="section_title">Customer Name:</Paragraph>
                    <Paragraph color="black" bold>{customer?.first_name} {customer?.last_name}</Paragraph>
                  </SpaceBetweenStyled>
                  
                  <Fragment>
                    <SpaceBetweenStyled>
                      <Paragraph color="black" bold className="section_title">Customer Phone:</Paragraph>
                      <Paragraph color="black" bold>{customer?.phone}</Paragraph>
                    </SpaceBetweenStyled>
                    
                    {/*<SpaceBetweenStyled id="address_div">*/}
                    {/*  <Paragraph color="black" bold className="section_title">Customer Address:</Paragraph>*/}
                    {/*  <Paragraph color="black" bold>{handleMainAddress(customer?.address) || "No address"}</Paragraph>*/}
                    {/*</SpaceBetweenStyled>*/}
                    
                    {/*{handleMainAddress(customer?.address) && <Fragment><br/> <br/> <br/></Fragment>}*/}
                  </Fragment>
                </Fragment>
              )}
            
            </FlexColumnStyled>
            
            <ReceiptOrderTableStyled $headingCount={3}>
              <tr className="table_first_row">
                <th><Paragraph color="black" bold>Product Name</Paragraph></th>
                <th className="table_order_qty"><Paragraph color="black" bold>Qty</Paragraph></th>
                <th><Paragraph color="black" bold>Amount</Paragraph></th>
              </tr>
              
              {data.map(product => (
                <tr>
                  <td>
                    <Paragraph color="black" bold>{product?.product_name}</Paragraph>
                  </td>
                  <td className="table_order_qty">
                    <Paragraph color="black" bold>{product?.qty_text}</Paragraph>
                  </td>
                  <td>
                    <Paragraph color="black" bold>{currencyFormatter(product?.product_cost)}</Paragraph>
                  </td>
                </tr>
              ))}
            </ReceiptOrderTableStyled>
            
            <FlexColumnStyled className="summary">
              <SpaceBetweenStyled>
                <Paragraph color="black" bold className="section_title">Sub Total: </Paragraph>
                <Paragraph color="black" bold
                           className="section_title">{currencyFormatter(orderSummary.subTotal)}</Paragraph>
              </SpaceBetweenStyled>
            
            </FlexColumnStyled>
            
            <AllCenterStyled className="thank_you_message">
              <Paragraph size={1.1} color="black" bold>
                {business_details?.receipt_message || "Powered by Ovaloop, your everyday business solution..."}
              </Paragraph>
            </AllCenterStyled>
            
            <FlexColumnStyled $gap={.5}>
              <span></span>
              <AllCenterStyled className="barcode_div">
                <Barcode height={50} format="CODE128" value={_data?.[0]?.group_id}/>
              </AllCenterStyled>
            </FlexColumnStyled>
            
            <SpaceBetweenStyled className="receipt_action">
              <Button className="print_button" text="Print" onClick={handlePrintReceipt}/>
            </SpaceBetweenStyled>
            
            {isMobile && <Button text="Close" onClick={onClose} className="close_action"/>}
          </SectionStyled>
        </ReceiptModalStyled>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default ReturnedOrderReceipt

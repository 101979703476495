import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createDeliveryRateService, deleteDeliveryRateService,
  editDeliveryRateService,
  getDeliveryRateService
} from "../../../services/deliveryRateServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import errorHandler from "../../../utils/errorHandler";
import {getBusinessIdFromStorage} from "../../../utils";


const mainKey = ["Delivery", getBusinessIdFromStorage()]
export const deliveryQueryKeys = {
  main: [...mainKey],
  mainList: (search = "") => [...mainKey, search]
}

const useDeliveryRateQuery = (searchValue) => {
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    queryKey: deliveryQueryKeys.mainList(searchValue),
    queryFn: () => getDeliveryRateService(getBusinessId(), searchValue)
  })
}

const useCreateDeliveryRateMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createDeliveryRateService({ ...data, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Delivery Rate Created!!")
      queryClient.invalidateQueries({ queryKey: deliveryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditDeliveryRateMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editDeliveryRateService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({ queryKey: deliveryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteDeliveryRateMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: rateId => {
      return deleteDeliveryRateService(rateId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: deliveryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useDeliveryRateQuery, useCreateDeliveryRateMutation, useEditDeliveryRateMutation, useDeleteDeliveryRateMutation }
import {CategoryCardStyled} from "../../styles/cardStyles";
import {Heading} from "../../styles/textStyles";

const CategoryCard = ({ title, children, onClick, active, big, randomColor, colorIndex }) => {
  return (
    <CategoryCardStyled $icon={children} $active={active} onClick={onClick} $big={big} $randomColor={randomColor} $colorIndex={colorIndex}>
      <span className="overlay"></span>
      {children}
      <Heading color="black" size={1.15}>{title}</Heading>
      {/*{!!noOfItems && <Paragraph color="secondary">{noOfItems} items</Paragraph>}*/}
    </CategoryCardStyled>
  )
}

export default CategoryCard
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import {FlexStyled} from "../../../styles/utilStyles";
import {
	NoDataLayout,
	PageHeader,
	ScreenLoading,
	BusinessSubscriptionCard,
	BusinessSubscriptionDrawer, PageHeaderWithButton
} from "../../../components";
import {TablePageLayoutStyled} from "../../../styles/tablePageStyles";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {EmptyBoxImage} from "../../../assets/images";
import {useBusinessSubscriptionQuery} from "../../../hooks/useServices/useBusinessSubscriptionServices";
import {useFromModalConnector} from "../../../hooks/useModalConnector";
import modalNames from "../../../hooks/useModalConnector/modalNames";
import {AddCircle} from "iconsax-react";
import {actionsPermissions} from "../../../data/permissions";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const BusinessSubscription = () => {
	const { debounceState } = useQueryDebounce()
	const [showSubscriptionDrawer, setShowSubscriptionDrawer] = useModal()
	
	const fromModalConnector = useFromModalConnector()

	const [activeSubscriptionDataForDrawer, setActiveSubscriptionDataForDrawer] = useState({})
	const { mainQuery, ...allCustomerSubscriptions } = useSearchQuery(debounceState, useBusinessSubscriptionQuery, true)

	const handleSelectTableForDrawer = (table) => {
		setActiveSubscriptionDataForDrawer(table)
		setShowSubscriptionDrawer()
	}
	
	const handleCreateSubscription = () => {
		fromModalConnector({ toModalName: modalNames.CREATE_SUBSCRIPTION, toModalURL: "/dashboard/set-up/subscriptions/", fromModalFormValues: {}  })
	}
	
	return (
		<Fragment>
			{showSubscriptionDrawer && <BusinessSubscriptionDrawer subscription={activeSubscriptionDataForDrawer} onClose={setShowSubscriptionDrawer} />}
			
			{!!mainQuery.isError && (
				<ScreenLoading refetchFn={allCustomerSubscriptions.refetch} />
			)}
			
			<Fragment>
				{allCustomerSubscriptions.isEmpty && (
					<Fragment>
						<PageHeader title="Customer Packages" />
						
						<NoDataLayout
							imgWidth={45}
							imgAlt="No customer subscription"
							imgSrc={EmptyBoxImage}
							heading="No subscription yet"
							buttonText="Create Subscription"
							buttonClick={handleCreateSubscription}
						/>
					</Fragment>
				)}
				
				{allCustomerSubscriptions.showTable && (
					<Fragment>
						<PageHeaderWithButton  permissionCode={actionsPermissions.createCustomerSubscription} title="Customer Packages" Icon={AddCircle} buttonClick={handleCreateSubscription} buttonText="Create" />
						
						<TablePageLayoutStyled>
							<FlexStyled>
								{!allCustomerSubscriptions.isQueryLoading && allCustomerSubscriptions.data.map(item => (
									<BusinessSubscriptionCard openDrawer={handleSelectTableForDrawer} subscription={item} key={item.id} />
								))}
								
								{allCustomerSubscriptions.isQueryLoading && (
									<Fragment>
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
										<TableSkeleton count={1} height={140} width="240px" noTop />
									</Fragment>
								)}
							</FlexStyled>
						</TablePageLayoutStyled>
					</Fragment>
				)}
			</Fragment>
		</Fragment>
	)
}

export default BusinessSubscription
import { useReducer } from "react";
import { useDebounce } from "use-debounce";


const useQueryDebounce = (initialValues) => {
	const [value, debounceReducerFn] = useReducer(debounceReducer, initialValues || debounceInitialValues)
	const [debounceState] = useDebounce(value, 600)

	const selectFieldSearch = (searchValue) => {
		debounceReducerFn({
			type: debounceActions.SET_SEARCH_FIELD,
			searchValue
		})
	}

	return { debounceState, debounceReducer: debounceReducerFn, selectFieldSearch }
}


export default useQueryDebounce

const debounceInitialValues = {
	toDateValue: "",
	fromDateValue: "",
	searchValue: "",
	filterValue: "",
	customerValue: "",
	staffValue: "",
}

export const debounceActions = {
	SET_SEARCH_FIELD: "SET_SEARCH_FIELD",
	SET_FILTER_FIELD: "SET_FILTER_FIELD",
	SET_FILTER_BY_TO_DATE: "SET_FILTER_BY_TO_DATE",
	SET_FILTER_BY_FROM_DATE: "SET_FILTER_BY_FROM_DATE",
	SET_FILTER_BY_CUSTOMER_FIELD: "SET_FILTER_BY_CUSTOMER_FIELD",
	SET_FILTER_BY_STAFF_FIELD: "SET_FILTER_BY_STAFF_FIELD",
	CLEAR_ALL_FILTERS: "CLEAR_ALL_FILTERS"
}

const debounceReducer = (state, action) => {
	switch (action.type) {
		case debounceActions.SET_FILTER_BY_TO_DATE:
			return { ...state, toDateValue: action.toDateValue }

		case debounceActions.SET_FILTER_BY_FROM_DATE:
			return { ...state, fromDateValue: action.fromDateValue }

		case debounceActions.SET_SEARCH_FIELD:
			return { ...state, searchValue: action.searchValue }

		case debounceActions.SET_FILTER_FIELD:
			return { ...state, filterValue: action.filterValue }

		case debounceActions.SET_FILTER_BY_CUSTOMER_FIELD:
			return { ...state, customerValue: action.customerValue }
		
		case debounceActions.SET_FILTER_BY_STAFF_FIELD:
			return { ...state, staffValue: action.staffValue }
		
		case debounceActions.CLEAR_ALL_FILTERS:
			return { ...debounceInitialValues }

		default:
			return state
	}
}
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {businessSubscriptionFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import MultiSelectInput from "../../../Forms/MultiSelectInput";
import {useState} from "react";
import {useSellableProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";


const CreateBusinessSubscriptionModal = ({ onClose, handleSubmit, mutation }) => {
  const { data: allProducts, isSuccess: isProductSuccess } = useSellableProductByBusinessIdQuery()
  const [exemptedProducts, setExemptedProducts] = useState([])

  const handleSubmission = (values) => {
    const exceptions = exemptedProducts.map(product => ({ id: product.id, name: product.name }))
    handleSubmit({ ...values, exceptions })
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Subscription</Heading>

        <Form values={businessSubscriptionFormDefaultValues} onSubmit={handleSubmission}>
          <Input label="Name" placeholder="Enter subscription name" name="name"  />
          <Input label="Amount" type="number" formatNumber placeholder="Enter subscription amount" name="value"  />
          <Input label="Duration (in months)" type="number" placeholder="Enter subscription duration in months" name="duration"  />
          <Input label="Limit" type="number" placeholder="Enter maximum usage count" name="limit"  />

          {isProductSuccess && (
            <MultiSelectInput
              options={allProducts?.results}
              displayName="name"
              setSelected={setExemptedProducts}
              label="Exempted products"
              placeholder="Select exempted products for this subscription"
            />
          )}

          <FlexStyled>
            <Button text="Create" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>

      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateBusinessSubscriptionModal
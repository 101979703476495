import {CheckBox, Input, Select} from "../index";
import {FlexColumnStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Fragment, useEffect, useState} from "react";
import {SplitOrPartialPaymentStyled} from "../../styles/formStyles";
import {Heading} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {useFormikContext} from "formik";
import {useBankQuery} from "../../hooks/useServices/useBankServices";


const SplitOrPartialPayment = ({totalPayable}) => {
  const {data: allBanks} = useBankQuery()
  const {setFieldValue} = useFormikContext()
  const {currencyFormatter} = useDataTypeFormatter()
  const [paymentValue, setPaymentValue] = useState({
    split_cash_value: 0,
    split_transfer_value: 0,
    split_pos_value: 0
  })
  
  const [{split_cash_active, split_pos_active, split_transfer_active}, setShowPayment] = useState({
    split_cash_active: false,
    split_transfer_active: false,
    split_pos_active: false
  })

  const handleShowPayment = (value, type) => {
    setShowPayment(prevState => ({
      ...prevState,
      [type]: value
    }))

    if(type === "split_cash_active" && !value) {
      setFieldValue("split_cash_value", 0)
      setFieldValue("split_cash_value_format", "")
      setPaymentValue(prevState => ({ ...prevState, split_cash_value: 0 }))
    }

    if(type === "split_transfer_active" && !value) {
      setFieldValue("split_transfer_value", 0)
      setFieldValue("split_transfer_value", 0)
      setFieldValue("split_transfer_bank", "")
      setFieldValue("split_transfer_value_format", "")
      setPaymentValue(prevState => ({...prevState, split_transfer_value: 0}))
    }

    if(type === "split_pos_active" && !value) {
      setFieldValue("split_pos_value", 0)
      setFieldValue("split_pos_bank", "")
      setFieldValue("split_pos_value_format", "")
      setPaymentValue(prevState => ({ ...prevState, split_pos_value: 0 }))
    }
  }

  const handleChange = (value, type) => {
    setPaymentValue(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const getAmountPaying = () => {
    const { split_transfer_value, split_pos_value, split_cash_value } = paymentValue

    return [split_transfer_value, split_pos_value, split_cash_value].reduce((prev, curr) => {
      if (Number(Number(curr).toFixed(2))) {
        return prev + Number(Number(curr).toFixed(2))
      }
  
      return prev
    }, 0)
  }

  useEffect(() => {
    const amountPaying = getAmountPaying()
    setFieldValue("splitOrPartialPaymentAmount", amountPaying)
  }, [paymentValue])

  return (
    <SplitOrPartialPaymentStyled>
      <FlexColumnStyled>
        <CheckBox optional afterCheck={handleShowPayment} name="split_cash_active" labelProp="Cash"/>
        {split_cash_active && (
          <Input label="Cash" onChange={handleChange} noBottomMargin className="fullWidth" name="split_cash_value"
                 type="number"
                 placeholder="Enter amount"/>
        )}
      </FlexColumnStyled>
    
      <FlexColumnStyled>
        <CheckBox optional afterCheck={handleShowPayment} name="split_transfer_active" labelProp="Transfer"/>
        {split_transfer_active && (
          <Fragment>
            <Input label="Transfer" onChange={handleChange} noBottomMargin className="fullWidth"
                   name="split_transfer_value" type="number"
                   placeholder="Enter amount"/>
            <Select
              label="Select bank"
              name="split_transfer_bank"
              options={allBanks}
              displayKey="bankInfo"
              valueKey="id"
              displayName="display_transfer_bank"
              placeholder="Select bank to transfer"
            />
          </Fragment>
        )}
      </FlexColumnStyled>
    
      <FlexColumnStyled>
        <CheckBox optional afterCheck={handleShowPayment} name="split_pos_active" labelProp="POS"/>
        {split_pos_active && (
          <Fragment>
            <Input label="POS" onChange={handleChange} noBottomMargin className="fullWidth" name="split_pos_value"
                   type="number"
                   placeholder="Enter amount"/>
            <Select
              label="Select bank"
              name="split_pos_bank"
              options={allBanks}
              displayKey="bankInfo"
              valueKey="id"
              displayName="display_pos_bank"
              placeholder="Select bank to transfer"
            />
          </Fragment>
        )}
      </FlexColumnStyled>
  
      <SpaceBetweenStyled className="summary">
        <Heading color="secondary">Amount Paying: </Heading>
        <Heading color="black">{currencyFormatter(getAmountPaying())}</Heading>
      </SpaceBetweenStyled>
  
      <SpaceBetweenStyled className="summary">
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "secondary")}>
          Amount Remaining:
        </Heading>
        <Heading color={(getAmountPaying() > Number(totalPayable) ? "error" : "black")}>
          {currencyFormatter(Number(totalPayable) - getAmountPaying())}
        </Heading>
      </SpaceBetweenStyled>
  
      <SpaceBetweenStyled>
        <Heading color="secondary">Total Payable: </Heading>
        <Heading color="black">{currencyFormatter(totalPayable)}</Heading>
      </SpaceBetweenStyled>
    </SplitOrPartialPaymentStyled>
  )
}


export default SplitOrPartialPayment
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {Button, Form, Input, PasswordInput, Select} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import {staffFormDefaultValues} from "../../../data/defaultFormValues";
import {useRoleQuery} from "../../../hooks/useServices/useRoleServices";
import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import CropImage from "../CropImage";
import {isPasswordRequirementsValid, isStringLengthValid} from "../../../utils/validator";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useStoreQuery} from "../../../hooks/useServices/useStoreServices";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import {useState} from "react";


const CreateStaffModal = ({ onClose, handleSubmit, mutation }) => {
  const { toast } = useGlobalContext()
  
  const [mainBusiness, setMainBusiness] = useState("")
  const { data: allStores, isSuccess: isStoresSuccess } = useStoreQuery()
  const { isSuccess: isRolesSuccess, data: allRoles } = useRoleQuery()
  const [selectedBusinesses, setSelectedBusiness] = useState([])
  
  const handleSubmission = (values) => {
    if (!isStringLengthValid(values.password, 8)) {
      toast.error("Password length must be at least 8 characters", values.password)
      return
    }
  
    if (!isPasswordRequirementsValid(values.password)) {
      toast.error("Password must contain at least an uppercase and lowercase letter, number and a symbol", values.password)
      return
    }
  
    if (values.offline_pin.length > 4 || values.offline_pin.length < 4 || !Number(values.offline_pin)) {
      toast.error("Staff offline pin must be only 4 numbers", values.offline_pin)
      return
    }
  
    const other_business = selectedBusinesses.map(business => business.id)
    handleSubmit({...values, other_business})
  }

  const allOtherAvailableStores = () => {
    return allStores?.filter(store => store.id !== mainBusiness)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Employee</Heading>
  
        <Form values={staffFormDefaultValues} onSubmit={handleSubmission}>
          <Input label="First Name" placeholder="Enter first name" name="first_name"/>
          <Input label="Last Name" placeholder="Enter last name" name="last_name"/>
          <Input label="Phone" type="number" placeholder="Enter phone number" name="phone"/>
          <Input label="Email" type="email" placeholder="Enter a valid email" name="email"/>
          <Input label="Address" type="address" placeholder="Enter House address" name="address" className="fullWidth"/>
          <PasswordInput label="Staff Password" type="password" placeholder="Enter password for staff" name="password"/>
    
          <PasswordInput
            name="offline_pin"
            label="Staff Offline Pin"
            type="password"
            placeholder="Enter pin (4 numbers)"
          />
    
          <Select
            options={isRolesSuccess ? allRoles : []}
            displayName="name"
            displayKey="name"
            valueKey="id"
            name="role"
            label="Designation"
            className="fullWidth"
            placeholder="Select a designation"
          />
  
          <Select
            options={allStores}
            displayName="business_details_name"
            displayKey="store_name"
            valueKey="id"
            name="business"
            label="Main Store"
            updateFn={(store) => setMainBusiness(store.id)}
            placeholder="Select staff main store"
            className="fullWidth"
          />

          <MultiSelectInput
            optional
            className="fullWidth"
            hideSelectedList
            options={isStoresSuccess ? allOtherAvailableStores() : []}
            displayName="store_name"
            setSelected={setSelectedBusiness}
            label="Other Store"
            placeholder="Add staff to other stores"
          />

          <CropImage cropShape="round" name="avatar" width={270} height={270} placeholder="Staff image" optional className="fullWidth"  />
  
          <FlexStyled>
            <Button text="Create" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>

      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateStaffModal
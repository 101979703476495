import {IPC_EVENTS} from "../shared/ipcEvents";

const sendDesktopNotification = ({ title, body }) => {
  if(window?.require) {
    const { ipcRenderer } = window.require("electron")
    
    ipcRenderer.send(IPC_EVENTS.NOTIFICATION, {
      title: title,
      body: body
    })
  }
}

export default sendDesktopNotification
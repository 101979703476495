import styled from "styled-components";
import {ButtonStyled, InputStyled, SelectDropDownStyled, SelectStyled} from "../formStyles";
import {Heading} from "../textStyles";
import {MenuListStyled, ListDropDownStyled} from "../listStyles";
import {FlexColumnStyled, FlexStyled, ImageStyled, SectionStyled} from "../utilStyles";
import {ProductCardCountStyled} from "../cardStyles";

export const StoreFrontLayoutStyled = styled("div")`
  width: 100vw;
  max-width: 100vw;
  min-height: calc(100vh - 2vh);
  
  > section.main {
    padding: 4vh 20vw;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    > section.main {
      padding: 1vh 2vw;
    }
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    > section.main {
      padding: 2vh 4vw;
    }
  }
  
  ${SectionStyled}.page_header_mobile_form {
    display: none;

    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      margin-top: 0 !important;
      display: initial;
      position: relative;
      top: -2.5rem;
      
      .date_select_field, .div_with_date_filter {
        width: 100% !important;
      }
    }
    
  form {
    width: 100%;
    
    ${SelectStyled} {
      gap: 0;
      margin: 0 !important;
      background-color: ${props => props.theme.primary};
      
      input {
        border: none;
        color: ${props => props.theme.white};
      }

      input::placeholder, textarea::placeholder {
        opacity: 1;
        color: ${props => props.theme.white};
      }
    }
    
    ${SelectDropDownStyled} {
      max-height: 300px !important;
    }
    
    i.select {
      color: white;
      bottom: calc(100% - 9%) !important;
    }

    i.select svg {
      color: ${props => props.theme.white};
    }
    
    svg.clear_filter_icon {
      top: 0.7rem;
    }

    svg.clear_filter_icon path {
      stroke: white !important;
    }
  }
  
  }
  
`

export const StoreFrontNavigationSearchBarStyled = styled("div")`
  width: 40%;
  position: relative;

  form > div {
    gap: 0;
  }
  
  ${InputStyled} {
    input {
      border-radius: 1.2rem !important;
    }
    
    i {
      right: 1rem;
    }
  }
  
  ${ButtonStyled} {
    top: 0;
    width: 27%;
    right: .2rem;
    position: absolute;
    padding: 0.8rem 1.1rem !important;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
  }
`

export const StoreFrontNavigationStyled = styled("div")`
  width: calc(100% - 2rem);
  padding: .2rem 1rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img_div {
    width: 11%;
    cursor: pointer;
    
    img {
      margin: 0;
      max-height: 300px;
      max-width: 100%;
    }
    
    ${Heading} {
      text-align: center;
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    .img_div {
      gap: .7rem;
      min-width: 75%;
      max-width: 75%;
      display: flex;
      align-items: center;
      
      img {
        max-width: 30%;
      }
    }
    
    ${StoreFrontNavigationSearchBarStyled} {
      display: none;
    }
  }
`

export const StoreFrontNavigationUserProfileStyled = styled("div")`
  min-width: 20%;
  gap: .6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .mobile_user_icon {
    display: none;
  }
  
  .dropdown_icon, .cart_icon {
    cursor: pointer;
  }

  > ${MenuListStyled} {

    a {
      text-decoration: none;
    }

    :hover {
      border-left: none;
      background: transparent;
    }

    div.container {
      position: relative;
      padding-top: 5rem;

      ${ListDropDownStyled} {
        top: initial;
        left: -13.5vw;
        width: fit-content;
        min-width: 14vw;
        
        ${MenuListStyled} {
          padding: 1.5rem !important;
          
          .right-icon {
            position: absolute;
            right: 1rem;
          }
        }

        svg {
          margin-left: -.35rem;
        }

        img {
          padding: .4rem;
        }
      }

      @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
        ${ListDropDownStyled} {
          left: -22.5vw;
          min-width: 28vw;
        }
      }
    }

    ${ListDropDownStyled} {
      top: 7vh;
      left: -12rem;
      width: fit-content;
    }
  }


  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${FlexColumnStyled}.user_info {
      display: none;
    }

    ${MenuListStyled} {
      padding: 0;
    }

    .desktop_user_icon, .dropdown_icon {
      display: none;
    }

    .mobile_user_icon {
      top: -.15rem;
      position: relative;
      display: initial;
    }

    div.container {
      ${ListDropDownStyled} {
        width: 55vw !important;
        left: -50vw !important;
      }
    }
  }
`


export const StoreFrontCategoryLayoutStyled = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: ${props => props.$gap ? "2rem 2.5rem" : "2rem 1rem"};

  span[aria-live="polite"] {
    width: ${props => props.$gap ? "calc(20% - 1rem)" : "calc(25% - 1rem)"} !important;
    height: 320px;
  }
  
  .react-loading-skeleton {
    width: 100% !important;
    height: 100% !important;
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    justify-content: space-between;
    gap: 1rem;

    span[aria-live="polite"] {
      width: 48% !important;
      height: 320px;
    }
  }
`


export const StoreFrontViewProductLayoutStyled = styled("div")`
  padding-bottom: 1.5rem;
  
  a {
    margin-left: .5rem;
    color: ${props => props.theme.secondary};
  }
  
  ${FlexStyled} {
    align-items: flex-start;
    margin-top: 4rem;
    
    ${ImageStyled} {
      width: 40%;
      max-height: 600px;
    }
    
    ${FlexColumnStyled} {
      margin-top: 1rem;
      max-width: calc(60% - 6rem);
    }
  }

  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    ${FlexStyled} {
      ${ImageStyled} {
        width: 60%;
      }

      ${FlexColumnStyled} {
        max-width: 100%;
      }
    }
  }
  
  ${ProductCardCountStyled} {
    margin-top: 0;
    
    input {
      font-size: 1.7rem !important;
    }
    
    ${ButtonStyled} {
      font-size: 2rem;
      width: 25% !important;
      height: 3.5rem !important;
    }
  }
  
  ${Heading} {
    line-height: 40px;
  }
  
`
import {
	NoDataLayout,
	PageHeader, ScreenLoading,
	SearchAndFilter,
	Table,
	TableItemWithStatus,
	TablePagination
} from "../../../components";
import {Fragment} from "react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {useWipByStaffIdQuery, wipQueryKeys} from "../../../hooks/useServices/useWipServices";
import {EmptyBoxImage} from "../../../assets/images";
import {useParams} from "react-router-dom";


const StaffWip = () => {
	const { staffId } = useParams()
	const { staffWipTable } = tableHeadersAndValueKeys
	const { debounceState, debounceReducer } = useQueryDebounce()
	const { mainQuery, ...allStaffWip } = useSearchQuery(debounceState, useWipByStaffIdQuery)

	return (
		<SetUpMainStyled>
			{!!mainQuery.isError && (
				<ScreenLoading refetchFn={allStaffWip.refetch} />
			)}
			
				<Fragment>
					
					{allStaffWip.isEmpty && (
						<Fragment>
							<PageHeader title="Staff Wip" />
							
							<NoDataLayout
								imgWidth={45}
								imgAlt="No wip"
								imgSrc={EmptyBoxImage}
								heading="No wip for staff yet"
							/>
						</Fragment>
					)}

					{allStaffWip.showTable && (
						<Fragment>

							{allStaffWip.isQueryWithData && (
								<PageHeader title={`Staff: ${allStaffWip?.data?.results[0]?.staff_name}`} />
							)}

							<SetUpLayoutStyled>
								<SearchAndFilter
									noFilter
									dispatch={debounceReducer}
									searchPlaceholder="Search services or stages by name"
								/>

								<Table headers={staffWipTable.headers} noCols={staffWipTable.headers.length} equalWidth>
									{allStaffWip.isQueryWithData && (
										allStaffWip.data.results.map((items, k) =>
											<TableItemWithStatus
												key={k}
												data={items}
												noAction
												count={k}
												pageCount={allStaffWip.currentCount}
												statusPosition={2}
												keys={staffWipTable.values}
											/>
										)
									)}

									{allStaffWip.isQueryWithNoData && (
										<NoQueryData text={allStaffWip.isSearchEmptyMessage("Stage", "")} />
									)}

									{allStaffWip.isQueryLoading && (
										<TableSkeleton />
									)}
								</Table>

								{!!allStaffWip?.showPagination &&
									<TablePagination
										query={allStaffWip}
										queryKey={wipQueryKeys.wipAnalyticsForStaff(staffId, allStaffWip.queryValues)}
									/>
								}
							</SetUpLayoutStyled>
							
							{!!allStaffWip?.showPagination &&
								<TablePagination
									query={allStaffWip}
									queryKey={wipQueryKeys.wipAnalyticsForStaff(staffId, allStaffWip.queryValues)}
								/>
							}
						</Fragment>
					)}

				</Fragment>
		</SetUpMainStyled>
	)
}

export default StaffWip
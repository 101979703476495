const getAllApprovedTransactions = (data = []) => {
  return data.filter(item => item.status === "approved")
}

const getAllPendingTransactions = (data = []) => {
  return data.filter(item => item.status === "pending")
}

const getAllRejectedTransactions = (data = []) => {
  return data.filter(item => item.status === "rejected")
}

const getOrdersWithPaymentConfirmed = (data = []) => {
  return data.filter(item => item.status === "payment_confirmed")
}

const getOrdersWithPaymentPending = (data = []) => {
  return data.filter(item => item.status === "pending")
}

const getTablesByStatus = (tables = [], status) => {
  return tables.filter(table => table.status === status)
}

const getTotalTablesCapacity = (tables = []) => {
  return tables.reduce((previousValue, currentValue) => {
    return previousValue + parseInt(currentValue.capacity)
  }, 0)
}

const filterOutCancelledOrders = (orders = []) => {
  return orders.filter(item => item.status !== "cancelled")
}

const filterOnlyOrdersToCalculate = (orders = []) => {
  return orders.filter(item => item.status === "pay_later")
}

export {
  getTablesByStatus,
  getTotalTablesCapacity,
  filterOutCancelledOrders,
  getAllPendingTransactions,
  getAllApprovedTransactions,
  getAllRejectedTransactions,
  getOrdersWithPaymentPending,
  filterOnlyOrdersToCalculate,
  getOrdersWithPaymentConfirmed,
}
import {Field, useFormikContext} from "formik";
import {CheckBoxStyled} from "../../styles/formStyles";
import {childrenPropToArray} from "../../utils/forms";
import {useContext, useEffect, useState} from "react";
import {FormProvider} from "./Form";


const CheckBox = ({className, name, optional, labelProp, afterCheck, noBottomMargin, checked = false, alwaysCheck}) => {
  const {setFieldValue} = useFormikContext()
  const childrenArray = childrenPropToArray(labelProp)
  const [checkedState, setCheckedState] = useState(checked)
  const {allFieldsNotRequired} = useContext(FormProvider)
  
  useEffect(() => {
    setCheckedState(checked)
    setFieldValue(name, checked)
  }, [checked])
  
  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }
  
  const handleChange = (e) => {
    setFieldValue(name, e.target.checked)
    afterCheck?.(e.target.checked, name, setFieldValue)
    setCheckedState(e.target.checked)
  }

  return (
    <CheckBoxStyled className={className} $noBottomMargin={noBottomMargin}>
      <Field
        checked={alwaysCheck || checkedState}
        onChange={handleChange}
        type="checkbox"
        name={name}
        id={name}
        required={isRequired()}
      />
      <label htmlFor={name}>
        {childrenArray}
      </label>
    </CheckBoxStyled>
  )
}

export default CheckBox
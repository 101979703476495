import {
  Button,
  CreatePackageDeliveryModal,
  NoDataLayout,
  PageHeader,
  PageSummaryCard,
  SearchAndFilter,
  Table, TableItemWithCheckoutBoxAndImage, TablePagination
} from "../../../components";
import {EmptyBoxImage} from "../../../assets/images";
import {Fragment} from "react";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {useModal} from "../../../hooks";
import {FlexStyled, PageSummaryStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useDeliveryByBusinessIdQuery, usePackageDeliveryMutation} from "../../../hooks/useServices/useDeliveryServices";
import {
  getAllApprovedTransactions,
  getAllPendingTransactions,
  getAllRejectedTransactions
} from "../../../utils/filters";
import {TableTopBarStyled} from "../../../styles/TableStyles";
import useDeliveryReducer, {orderReducerActionTypes} from "../../../reducers/deliveryReducer";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const Items = () => {
  const [orderState, orderReducer] = useDeliveryReducer()
  const [showPackageDeliveryModal, setShowPackageDeliveryModal] = useModal()

  const { deliveryItemsTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allDeliveries } = useSearchQuery(debounceState, useDeliveryByBusinessIdQuery)
  const packageDeliveryMutation = usePackageDeliveryMutation({ successFn: setShowPackageDeliveryModal })

  const handlePackageItem = (values) => {
    packageDeliveryMutation.mutate({...values, order_list: orderState.batch})
  }

  const approvedDeliveries = getAllApprovedTransactions(allDeliveries?.data?.results)
  const pendingDeliveries = getAllPendingTransactions(allDeliveries?.data?.results)
  const rejectedDeliveries = getAllRejectedTransactions(allDeliveries?.data?.results)

  const handleAddItemToBatch = (itemId) => {
    orderReducer({
      itemId,
      type: orderReducerActionTypes.ADD_ITEM_TO_BATCH
    })
  }

  return (
    <div>
      {mainQuery.isSuccess && (
        <Fragment>
          {allDeliveries.isEmpty && (
            <Fragment>
              <PageHeader title="Deliveries" />
              <NoDataLayout
                imgWidth={45}
                imgAlt="No delivery"
                imgSrc={EmptyBoxImage}
                heading="No deliveries yet"
                subHeading="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less"
              />
            </Fragment>
          )}

          {!allDeliveries.isEmpty && (
            <Fragment>
              {showPackageDeliveryModal && (
                <CreatePackageDeliveryModal
                  handleSubmit={handlePackageItem}
                  mutation={packageDeliveryMutation}
                  onClose={setShowPackageDeliveryModal}
                />
              )}
              <PageHeader title="Deliveries" />

              <PageSummaryStyled>
                <PageSummaryCard title="Total Number Of Deliveries" value={allDeliveries?.data?.results.length} />
                <PageSummaryCard title="Approved Deliveries" value={approvedDeliveries.length} />
                <PageSummaryCard title="Pending Deliveries" value={pendingDeliveries.length} />
                <PageSummaryCard title="Rejected Deliveries" value={rejectedDeliveries.length} />
              </PageSummaryStyled>

              <SetUpLayoutStyled>
                <TableTopBarStyled>

                  <SearchAndFilter
                    noFilter
                    dispatch={debounceReducer}
                    searchPlaceholder="Search deliveries by Id"
                  />
                  <FlexStyled>
                    <Button text="Package Items" onClick={setShowPackageDeliveryModal} disabled={!orderState.itemToBatchAvailable} />
                  </FlexStyled>
                </TableTopBarStyled>
                <Table bg headers={deliveryItemsTable.headers} noCols={deliveryItemsTable.headers.length} >
                  {allDeliveries.isQueryWithData && (
                    allDeliveries?.data?.results.map((items, k) =>
                      <TableItemWithCheckoutBoxAndImage
                        key={k}
                        data={items}
                        checkId="order_id"
                        handleCheck={handleAddItemToBatch}
                        keys={deliveryItemsTable.values}
                      />
                    )
                  )}

                  {allDeliveries.isQueryWithNoData && (
                    <NoQueryData text={allDeliveries.isSearchEmptyMessage("Items", "id")} />
                  )}

                  {allDeliveries.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
                {!!allDeliveries?.data?.next && <TablePagination />}
              </SetUpLayoutStyled>

              {!!allDeliveries?.data?.next && <TablePagination />}
            </Fragment>
          )}

        </Fragment>
      )}
    </div>
  )
}

export default Items;
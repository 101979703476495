import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";

const getReportService = async (businessId, reportType, sortBy, fromDate, toDate, additionalState=" ") => {
  try {
    const { data } = await axios.get(`/report/?report_type=${reportType}&sort_by=${sortBy}&from_date=${fromDate}&to_date=${toDate}&business_id=${businessId}&param=${additionalState}`)

    return data?.map(item => {
      const total_cost = (item.unit_cost * item.quantity) + item.service_cost - item.discount

      return {
        ...item,
        cost_before_tax: total_cost,
        cost_after_tax: total_cost + item.tax,
        customer_name: item.customer_name || "Guest",
      }
    })
  } catch (e) {
    throw e?.response?.data || ""
  }
}


const downloadReportService = async (reportQuery) => {
  const url = handleQueryParams('/report', reportQuery)
  
  try {
    // if backend is sending me a format that I can display in Excel directly
    const {data} = await axios.get(url, {
      responseType: "arraybuffer",
      timeout: (5 * 60 * 1000),
    })
    
    return {type: "excel", data}
  } catch (e) {
    if (e.message === `Unexpected token 'o', "[object ArrayBuffer]" is not valid JSON`) {
      return {
        type: "download",
        data: "Your report is currently being processed, you will be notified as soon as your download is ready"
      }
    }
  
    throw e?.response?.data || e?.message || e
  }
}


export const downloadAllReportService = async (businessId, reportType, fromDate, toDate) => {
  try {
    const { data } = await axios.get(`/report/?report_type=${reportType}&from_date=${fromDate}&to_date=${toDate}&business_id=${businessId}&download=true`, {
      responseType: "blob",
      timeout: (5 * 60 * 1000)
    })
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


const downloadReceiptService = async (businessId, groupOrderId) => {
  try {
    const { data } = await axios.get(`/receipt/?business_id=${businessId}&group_id=${groupOrderId}`, {
      responseType: "arraybuffer",
      timeout: (5 * 60 * 1000)
    })
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const downloadPurchaseReceiptService = async (businessId, groupOrderId) => {
  try {
    const {data} = await axios.get(`/purchase_order_download/?business_id=${businessId}&purchase_id=${groupOrderId}`, {
      responseType: "blob"
    })
    
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const getReportDownloadableService = async () => {
  try {
    const {data} = await axios.get(`/report_download`)
    return [...(data || [])].reverse()
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const deleteReportDownloadableService = async (downloadableId) => {
  try {
    const {data} = await axios.delete(`/report_download/${downloadableId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export {getReportService, downloadReportService, downloadReceiptService}
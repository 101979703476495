import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {capitalizeFirstLetterInText} from "../../utils/textTransformer";


const getTaxService = async (queryParams = {}) => {
  try {
    const url = handleQueryParams("/tax", queryParams)
    const {data} = await axios.get(url)
  
    const taxes = data?.results?.map(tax => {
      const tax_type = tax?.tax_type || "general"
      const tax_calculation_type = tax?.calculation_type || "addition"
  
      return {
        ...tax,
        tax_type,
        calculation_type: tax_calculation_type,
        tax_type_name: capitalizeFirstLetterInText(tax_type),
        calculation_type_name: capitalizeFirstLetterInText(tax_calculation_type),
        format_value: tax_calculation_type === "addition" ? `+${tax.value}%` : `-${tax.value}%`,
      }
    })
    return {...data, results: taxes}
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createTaxService = async (body) => {
  try {
    const { data } = await axios.post(`/tax/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editTaxService = async (taxId, body) => {
  try {
    const { data } = await axios.patch(`/tax/${taxId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteTaxService = async (taxId) => {
  try {
    const { data } = await axios.delete(`/tax/${taxId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getTaxService, createTaxService, editTaxService, deleteTaxService }
import {Link} from "react-router-dom";
import {BackgroundPattern, RegisterLayout} from "./AuthStyles";
import {Button, CheckBox, Form, Icon, Input, QuoteCreator} from "../../components";

import {CenterContent, FlexStyled} from "../../styles/utilStyles";
import {Logo, LogoLight} from "../../assets/images";
import {Heading, Heading2, Paragraph} from "../../styles/textStyles";
import {QuoteIcon} from "../../assets/icons";
import {Eye, EyeSlash} from "iconsax-react";
import usePassword from "../../hooks/usePassword";
import {useLoginService} from "../../hooks/useServices/authenticationHooks";
import useNewUserProtection from "../../hooks/useRouteProtection/useAllowOnlyUnauthenticatedUsers";
import env from "../../utils/env";
import {useIsOfflineLoginAvailable} from "../../hooks/useDesktop/useUtils/useIsOfflineLoginAvailable";
import {useModal} from "../../hooks";
import DesktopDatabaseImportModal from "../../components/Modal/DesktopDatabaseImportModal";
import {Fragment} from "react";

const formValues = {email: "", password: "", remember: false}

const LoginRoute = () => {
  useNewUserProtection()
  const [isOfflineLoginView, setOfflineLoginView] = useModal()
  const isOfflineLoginAvailable = useIsOfflineLoginAvailable()
  const [isShowModalForDBImport, setShowModalForDBImport] = useModal()
  
  const loginMutation = useLoginService({
    successFn: () => {
    }
  })
  
  const handleLogin = async (values) => {
    loginMutation.mutate({...values, isOfflineLogin: isOfflineLoginView})
  }
  
  const [passwordState, setPasswordState] = usePassword()
  
  const changePasswordVisibility = () => {
    passwordState.visible ? setPasswordState.makeInVisible() : setPasswordState.makeVisible()
  }
  
  const CheckboxLabel = () => (
    <Paragraph>Remember me</Paragraph>
  )
  
  const handleRedirectDesktopToWebForRegistration = () => {
    const { shell } = window?.require("electron")
    shell.openExternal(`https://business.ovaloop.app/register/${env.FREE_SUBSCRIPTION_PLAN}/month`)
  }

  return (
    <RegisterLayout>
      {isShowModalForDBImport && (
        <DesktopDatabaseImportModal onClose={setShowModalForDBImport}/>
      )}
      {/* FIRST COLUMN - FORM SECTION */}
      <div className="logo_div">
        <img src={LogoLight} alt="Ovaloop logo"/>
      </div>
    
    
      <section className="email_and_password">
        <div className="email_and_password_div">
          <img src={Logo} alt="Ovaloop logo"/>
          <div>
            <Heading2 color="black">Welcome back to Ovaloop</Heading2>
            <Form values={formValues} onSubmit={handleLogin}>
              <Input type="email" required label="Email" placeholder="Email address" name="email"/>
              {!isOfflineLoginView && (
                <Input
                  required
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type={passwordState.type}
                  icon={passwordState.visible ?
                    <Eye size={15} onClick={changePasswordVisibility}/> :
                    <EyeSlash size={15} onClick={changePasswordVisibility}/>
                  }
                />
              )}
    
              {isOfflineLoginView && (
                <Input
                  required
                  name="offline_pin"
                  label="Offline Login Pin"
                  placeholder="Enter your pin"
                  type={passwordState.type}
                  icon={passwordState.visible ?
                    <Eye size={15} onClick={changePasswordVisibility}/> :
                    <EyeSlash size={15} onClick={changePasswordVisibility}/>
                  }
                />
              )}
              <FlexStyled className="login">
                <CheckBox checked={true} noBottomMargin optional name="remember" labelProp={<CheckboxLabel/>}/>
                <Link to="/auth/forgot-password"><Paragraph noTop size=".9" color="gray" bold>Forgot
                  Password?</Paragraph></Link>
              </FlexStyled>
              <Button type="submit" isLoading={loginMutation.isLoading} text="Sign in" shadow/>
              <br/>
            
              {(!!window?.require && !isOfflineLoginAvailable) && (
                <Fragment>
                  <FlexStyled className="login">
                    <p></p>
                    <Paragraph className="pointer" onClick={setShowModalForDBImport} size=".9" color="black" bold>
                      <u>Upload offline database</u>
                    </Paragraph>
                  </FlexStyled>
                  <br/>
                </Fragment>
              )}
            
              {(!!window?.require && isOfflineLoginAvailable) && (
                <Button
                  onClick={setOfflineLoginView}
                  bgColor="black"
                  type="button"
                  disabled={loginMutation.isLoading}
                  text={isOfflineLoginView ? "Use Online Login" : "Use Offline Login"}
                />
              )}
            </Form>
          </div>
  
          {!!window?.require && (
            <Paragraph color="gray" bold size={.9}>
              {/*Don’t have an account? <u onClick={handleRedirectDesktopToWebForRegistration}>Sign up now</u>*/}
            </Paragraph>
          )}
  
          {!window?.require && (
            <Paragraph color="gray" bold size={.9}>
              Don’t have an account? <Link to={`/register/${env.FREE_SUBSCRIPTION_PLAN}/month`}>Sign up now</Link>
            </Paragraph>
          )}
        </div>
      </section>


      {/* SECOND COLUMN - QUOTE SECTION */}
      <BackgroundPattern className="quote_section">
        <div>
          <CenterContent>
            <Icon iconImg={QuoteIcon} bgDark />
            <Heading size={1.4} spread>
              "I've always had the issue of knowing the stock level of each product, keeping up with orders and knowing when to stock up can all be challenging. That has changed since I started using Ovaloop, with just a few clicks I can view it all at a glance. I now have peace of mind when I'm at my business.
              <br/> <br/>
              I recommend Ovaloop to every SME that wants a seamless business operation."
            </Heading>
            <QuoteCreator />
          </CenterContent>
        </div>
      </BackgroundPattern>
    </RegisterLayout>
  )
}

export default LoginRoute
import {SubscriptionCardStyled, SubscriptionLayout, SubscriptionSummaryStyled} from "../../../styles/subscriptionStyles";
import {Fragment, useState, memo } from "react";
import {
  Button,
  Chips,
  Form,
  Input,
  Line,
  ManageSubscriptionModal, PageHeader,
  Table,
  TableItemWithStatus
} from "../../../components";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
  FlexCenterStyled,
  FlexColumnStyled,
  FlexStyled,
  SectionStyled,
  SpaceBetweenStyled
} from "../../../styles/utilStyles";
import useModal from "../../../hooks/useModal";
import {
  useSubscriptionPackagesQuery,
  useUpdateSubscriptionMutation,
  useSubPackageCalculation, useSubscribeForPackageMutation, useSubscriptionLogsQuery
} from "../../../hooks/useServices/useSubscriptionServices";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {
  getDaysDifferenceFromCurrentDate, getNumberOfDaysForwardFromDate,
} from "../../../utils/handleDateFilterValue";
import {IoIosAddCircleOutline} from "react-icons/io";
import { SlMinus } from "react-icons/sl"
import {useFormikContext} from "formik";
import ChooseSubscription from "../../../components/Modal/Subscription/ChooseSubscription";
import subscriptionTypes from "../../../utils/types/subscriptionTypes";
import {FlexRowStyled} from "../../../styles/cardStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import env from "../../../utils/env";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {useParams} from "react-router-dom";
import uuid from "react-uuid";
import {useAccountByIdQuery} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";


const AccountSubscription = () => {
  const { accountId } = useParams()
  
  const { subscriptionLogTable } = tableHeadersAndValueKeys
  
  const { data: accountDetails } = useAccountByIdQuery(accountId)
  
  const { mainQuery, ...allSubscriptionLogs } = useOrderQueryWithSearchAndFilter({ account_id: accountId }, useSubscriptionLogsQuery)
  const [changeSubscription, setChangeSubscription] = useState({ packageId: "", packagePeriod: "" })

  const getTruePackageDetails = () => {
    const userProfile = accountDetails?.results || {}
    
    const { id: packageId } = userProfile?.[0]?.package || {}
    const { package_duration: packagePeriod, trial_period: is_trial } = userProfile?.[0] || {}
    const packageWithOutUpgrade = { packageId, packagePeriod, is_trial, prevPackageId: packageId, prevPackagePeriod: packagePeriod }
    
    if(!changeSubscription.packageId || !changeSubscription.packagePeriod)
      return packageWithOutUpgrade

    if(changeSubscription.packageId === packageId && changeSubscription.packagePeriod === packagePeriod)
      return packageWithOutUpgrade

    return {
      is_trial,
      prevPackageId: packageId,
      prevPackagePeriod: packagePeriod,
      packageId: changeSubscription.packageId,
      packagePeriod: changeSubscription.packagePeriod === "month" ? "monthly" : "yearly"
    }
  }

  const { packagePeriod, prevPackageId, prevPackagePeriod, packageId, is_trial } = getTruePackageDetails()

  const [extraAddons, setExtraAddons] = useState({
    user: 0,
    store: 0,
    product: 0,
  })
  
  const [showSubscriptionModal, setShowSubscriptionModal] = useModal()
  const [summaryData, setSummaryData] = useState(null)
  const { data: allPackages } = useSubscriptionPackagesQuery()
  const [isManageSubscription, setIsManageSubscription] = useModal()
  const { nairaCurrencyFormatter, shortDateFormatter } = useDataTypeFormatter()
  const calculateSubscriptionMutation = useSubPackageCalculation({ successFn: setSummaryData })
  const subscribeForPackageMutation = useSubscribeForPackageMutation({ successFn: () => {} })
  const updateSubscriptionMutation = useUpdateSubscriptionMutation({ successFn: setIsManageSubscription })

  const handleSelectSubscription = (packageId, packagePeriod) => {
    setChangeSubscription({ packageId, packagePeriod })
    setShowSubscriptionModal()
  }

  const isPackageReadyForRenewal = () => {
    const readyForRenewalDate = new Date(next_pay_date).getTime()
    return readyForRenewalDate < (new Date().getTime() + (31 * 24 * 60 * 60 * 1000))
  }
  
  const subscriptionButtonText = () => {
    if(packageId === env.FREE_SUBSCRIPTION_PLAN) {
      return "Upgrade Package"
    }
    
    if(packageId !== prevPackageId) {
      return "Get summary"
    }
    
    return isPackageReadyForRenewal() ? "Renew package" : "Get summary"
  }

  const getPackageSubType = () => {
    if((prevPackageId !== packageId) || (packagePeriod !== prevPackagePeriod)) {
      return subscriptionTypes.PACKAGE_SWITCH
    }

    if (!!is_trial) return subscriptionTypes.NEW_PURCHASE

    if(isPackageReadyForRenewal()) return subscriptionTypes.RENEWAL

    return subscriptionTypes.ADDON_PURCHASE
  }

  const handleGetSubSummary = () => {
    const bodyObj = {
      package: packageId,
      package_duration: packagePeriod,
      extra_user: extraAddons.user,
      extra_product: extraAddons.product,
      extra_store: extraAddons.store,
      sub_type: getPackageSubType(),
      account_id: accountId
    }

    calculateSubscriptionMutation.mutate(bodyObj)
  }

  const handleSuccess = (res) => {
    const bodyObj = {
      package: packageId,
      account_id: accountId,
      package_duration: packagePeriod,
      extra_user: extraAddons.user,
      extra_product: extraAddons.product,
      extra_store: extraAddons.store,
      sub_type: getPackageSubType(),
      payment_ref: res.transactionReference
    }

    subscribeForPackageMutation.mutate(bodyObj)
  }

  const handleCheckOut = () => {
    handleSuccess({ transactionReference: uuid() })
  }
  
  const getPackage = () => {
    const userProfile = accountDetails?.results?.[0] || {}
    const { name, amount: package_amount, max_store: storeCount, max_user: userCount, max_products, modules } = userProfile?.package || {}
    const max_store = Number(storeCount) + Number(userProfile.extra_store)
    const max_user = Number(userCount) + Number(userProfile.extra_user)
  
    const { next_payment_date: next_pay_date } = userProfile || {}
    const currentPackage = allPackages?.find(subPackage => subPackage?.id === packageId)

    // const { package_name: name, next_pay_date, max_store, billable_amount: package_amount, max_user, max_products, modules } = userProfile?.[0]?.package_details || {}

    if(packageId !== prevPackageId || packagePeriod !== prevPackagePeriod) {
      return { ...currentPackage, next_pay_date: getNumberOfDaysForwardFromDate(packagePeriod === "monthly" ? 30 : 365), max_products, max_user, max_store  }
    }

    return { next_pay_date, name, max_store, max_user, max_products, amount: package_amount, monthly_amount: package_amount, modules, package_addon: currentPackage?.package_addon }
  }
  
  const { name, max_store, max_user, max_products, amount, monthly_amount, package_addon, next_pay_date, modules = [] } = getPackage()

  return (
    <Fragment>
      {showSubscriptionModal && <ChooseSubscription setSubscription={handleSelectSubscription} onClose={setShowSubscriptionModal} />}
      {isManageSubscription && (
        <ManageSubscriptionModal
          onClose={setIsManageSubscription}
          mutation={updateSubscriptionMutation}
          handleSubmit={updateSubscriptionMutation}
        />
      )}
      
      <PageHeader title={accountDetails?.results?.[0]?.business_name || ""} isBack />
  
      <br/>
      <Heading color="black" size={1.2}>Account Subscription Logs</Heading> <br />
      <Table headers={subscriptionLogTable.headers} noCols={subscriptionLogTable.headers.length} equalWidth >
        {allSubscriptionLogs.isQueryWithData && (
          allSubscriptionLogs?.data?.results?.map((log, k) => (
            <TableItemWithStatus
              count={k}
              noAction
              pageCount={allSubscriptionLogs.currentCount}
              key={k}
              data={log}
              statusPosition={5}
              keys={subscriptionLogTable.values}
            />
          ))
        )}
    
        {allSubscriptionLogs.isQueryWithNoData && (
          <NoQueryData text="No subscription log" />
        )}
    
        {allSubscriptionLogs.isQueryLoading && (
          <TableSkeleton />
        )}
      </Table>
      <br/> <br/>

      <SpaceBetweenStyled className="top_actions">
        <Paragraph color="black" size={1.15} onClick={setShowSubscriptionModal}>
          <u>Change subscription plan</u>
        </Paragraph>
      </SpaceBetweenStyled>

      <br/>
      
      <SubscriptionLayout $isDashboard>
        {/* <PageHeader title="Subscription" /> */}

        <FlexCenterStyled>
          <SubscriptionCardStyled $isDashboard $active={getDaysDifferenceFromCurrentDate(next_pay_date) > 0}>
            <SpaceBetweenStyled>
              <Heading color="black" size={1.3}>{(name)?.toUpperCase()} PLAN</Heading>
              <Chips title={is_trial ? "Active" : getDaysDifferenceFromCurrentDate(next_pay_date) < 0 ? "Inactive" : "Active"} active={getDaysDifferenceFromCurrentDate(next_pay_date) > 0} />
            </SpaceBetweenStyled>
            
            <SpaceBetweenStyled>
              <FlexColumnStyled>
                <Heading color="black" size={1.6}>
                  {packagePeriod === "monthly" ? nairaCurrencyFormatter(monthly_amount || 0) : nairaCurrencyFormatter(amount || 0)}
                </Heading>
                <Paragraph noTop color="black">Next renewal amount</Paragraph>
              </FlexColumnStyled>

              {!!next_pay_date && (
                <FlexColumnStyled>
                  <Heading color="black" size={1.4}>Expiry date - {shortDateFormatter(new Date(next_pay_date || Date.now()))}</Heading>
                  <Paragraph noTop color="black">
                    {getDaysDifferenceFromCurrentDate(next_pay_date) > 0 && `Expires in ${getDaysDifferenceFromCurrentDate(next_pay_date)} days`}
                    {getDaysDifferenceFromCurrentDate(next_pay_date) < 0 && `Expired ${getDaysDifferenceFromCurrentDate(next_pay_date) * -1} days ago`}
                  </Paragraph>
                </FlexColumnStyled>
              )}
            </SpaceBetweenStyled>
            
            <SectionStyled>
              <FlexStyled className="info">
                <FlexColumnStyled>
                  <Heading color="black" size={1.02}>Plan <br/> Duration</Heading>
                  <Paragraph color="secondary" size={1.2} bold>{packagePeriod}</Paragraph>
                </FlexColumnStyled>
                
                <FlexColumnStyled>
                  <Heading color="black" size={1.02}>Store <br/> Count</Heading>
                  <Paragraph color="secondary" size={1.2} bold>{max_store}</Paragraph>
                </FlexColumnStyled>
                
                <FlexColumnStyled>
                  <Heading color="black" size={1.02}>Product <br/> Count</Heading>
                  <Paragraph color="secondary" size={1.2} bold>{Number(max_products) < 60 ? Number(max_products) : "Unlimited"}</Paragraph>
                </FlexColumnStyled>
                
                <FlexColumnStyled>
                  <Heading color="black" size={1.02}>User <br/> Count</Heading>
                  <Paragraph color="secondary" size={1.2} bold>{max_user}</Paragraph>
                </FlexColumnStyled>
              </FlexStyled>

              <br/>
              <FlexRowStyled>
                {modules?.map(item => <Heading bold color="black" key={item}>&#9635; {item}</Heading>)}
              </FlexRowStyled>

              <div className="section_line">
                <Line />
                <Chips title="Add Ons" />
              </div>
              
              {Number(amount) < 10 && (
                <Fragment>
                  <br/><br />
                  <Paragraph color="black" >Addons cannot be used on a free plan</Paragraph>
                  <br/>
                </Fragment>
              )}
              
              {Number(amount) > 10 && (
                <Fragment>
                  <div className="addons">
                    <SpaceBetweenStyled>
                      <Heading color="black">Extra store</Heading>
                      <Paragraph color="secondary">
                        {nairaCurrencyFormatter(package_addon?.extra_store)}
                        <b> /year</b>
                      </Paragraph>
                      <Form values={{ store: 0 }}>
                        <ExtraStore name="store" setState={setExtraAddons} />
                      </Form>
                    </SpaceBetweenStyled>
                    
                    <SpaceBetweenStyled>
                      <Heading color="black">Extra user</Heading>
                      <Paragraph color="secondary">
                        {nairaCurrencyFormatter(package_addon?.extra_user)}
                        <b> /year</b>
                      </Paragraph>
                      
                      <Form values={{ user: 0 }}>
                        <ExtraStore name="user" setState={setExtraAddons} />
                      </Form>
                    
                    </SpaceBetweenStyled>
                  </div>
                
                </Fragment>
              )}
              
              <Button
                isLoading={calculateSubscriptionMutation.isLoading}
                text={subscriptionButtonText()}
                onClick={packageId === env.FREE_SUBSCRIPTION_PLAN ? setShowSubscriptionModal : handleGetSubSummary}
              />
            
            </SectionStyled>
          </SubscriptionCardStyled>
          
          <div>
          </div>
        </FlexCenterStyled>

        <SubscriptionSummaryStyled $isDashboard>
          <Heading color="black" size={1.5} bold>Summary</Heading>
          <div>
            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Package cost:</Heading>
              <Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.package_cost || 0)}</Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Previous Store cost:</Heading>
              <Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.prev_store_cost || 0)}</Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Previous User cost:</Heading>
              <Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.prev_user_cost || 0)}</Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Store cost:</Heading>
              <Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.store_cost || 0)}</Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>User cost:</Heading>
              <Paragraph color="black" size={1}>{nairaCurrencyFormatter(summaryData?.user_cost || 0)}</Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Billable period:</Heading>
              <Paragraph color="black" size={1}>
                {packagePeriod === "monthly" ? "30" : "365" } days
                {/*{numberFormatter(summaryData?.billable_period || 365)} days*/}
              </Paragraph>
            </SpaceBetweenStyled>

            <SpaceBetweenStyled>
              <Heading color="black" size={1}>Subtotal Cost:</Heading>
              <Paragraph color="black" size={1}>
                {packagePeriod === "monthly" ? nairaCurrencyFormatter(summaryData?.total_sub_cost || 0) : nairaCurrencyFormatter(summaryData?.total_sub_cost || 0)}
              </Paragraph>
            </SpaceBetweenStyled>
          </div>

          <Button disabled={!summaryData} text="Checkout" onClick={handleCheckOut} isLoading={subscribeForPackageMutation.isLoading} />
        </SubscriptionSummaryStyled>
      </SubscriptionLayout>
    </Fragment>
  )
}

export default AccountSubscription


const ExtraStore = memo(({ addCount = 1, name, validate, setState }) => {
  const { toast } = useGlobalContext()
  const { values, setFieldValue } = useFormikContext()
  
  const handleAddMore = () => {
    setFieldValue(`${name}_format`, Number(values[name]) + addCount)
    setFieldValue(name, Number(values[name]) + addCount)
    
    setState(prev => ({
      ...prev,
      [name]: Number(values[name]) + addCount
    }))
  }
  
  const handleSubMore = () => {
    setFieldValue(`${name}_format`, Number(values[name]) - addCount)
    setFieldValue(name, Number(values[name]) - addCount)
    
    setState(prev => ({
      ...prev,
      [name]: Number(values[name]) - addCount
    }))
  }
  
  const handleValidate = () => {
    setState(prev => ({
      ...prev,
      [name]: Number(values[name])
    }))
    
    if(!validate) return;
    
    const num = Number(values[name])
    
    if(num % addCount !== 0) {
      toast.error("Please enter products in multiples of 50")
      setFieldValue(`${name}_format`, "")
      setFieldValue(name, "")
      
      setState(prev => ({
        ...prev,
        [name]: 0
      }))
    }
  }
  
  return (
    <FlexCenterStyled>
      <SlMinus color="black" onClick={handleSubMore} size={20} />
      <Input onBlur={handleValidate} placeholder="0" name={name} formatNumber type="number" />
      <IoIosAddCircleOutline color="black" size={20} onClick={handleAddMore} />
    </FlexCenterStyled>
  )
})
import {ModalLayoutStyled, InventoryModalStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Button, Form, Input} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";

const ManagePurchaseOrderProductModal = ({ onClose, product, setFieldValue }) => {
  const { numberFormatter } = useDataTypeFormatter()
  
  const onSubmit = (formValues) => {
    const values = { ...formValues }
  
    if(values.meta_measurement && values.meta_measurement?.length) {
      const mainCustomMeasurements = product?.meta_measurement.map(measurement => {
        return { ...measurement, selling_price: values[`unit_measurement_${measurement.name}_selling_price`] }
      })
  
      setFieldValue(`sales_attachment_measurement_array_${product.product_form_id}`, mainCustomMeasurements)
      setFieldValue(`sales_attachment_measurement_array_persist_${product.product_form_id}`, mainCustomMeasurements)
      
      const customMeasurements = [...mainCustomMeasurements]
      const mainMeasurement = customMeasurements.shift()

      setFieldValue(`sales_attachment_measurement_stock_low_level_${product.product_form_id}`, values[`stock_low_level`])
      setFieldValue(`sales_attachment_main_measurement_selling_price_${product.product_form_id}`, mainMeasurement.selling_price)
    }
    
    onClose()
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Manage Product</Heading>
    
        <Form values={product} onSubmit={onSubmit}>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Product Name:</Paragraph>
            <Paragraph color="black" size={1.2}>{product?.name || product?.product_name}</Paragraph>
          </FlexStyled>
  
          {product?.stock_unit && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Available Quantity: </Paragraph>
              <Paragraph color="black" size={1.2}>{numberFormatter(product?.stock_unit)}</Paragraph>
            </FlexStyled>
          )}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Incoming Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{product?.["incomingQuantity"]}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }}></FlexStyled>
  
          <Paragraph bold color="black" size={1.1}>Adjust Selling Prices</Paragraph>
          <div className="purchase_section">
            {(product.meta_measurement && product.meta_measurement.length > 0) && (
              product.meta_measurement.map(measurement => (
                <FlexStyled key={measurement.name} className="fullWidth">
                  <Input name={`unit_measurement_${measurement.name}`} disabled placeholder={measurement.name} label="Unit Measurement" />
                  <Input name={`unit_measurement_${measurement.name}_selling_price`} label={`${measurement.name} Selling Price`} type="number" formatNumber />
                </FlexStyled>
              ))
            )}
          </div>
  
          <Input optional name="stock_low_level" className="fullWidth" label="Adjust Reorder Stock Level" type="number" formatNumber />
  
          <FlexStyled>
            <Button text="Save" type="submit" />
          </FlexStyled>
        </Form>
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default ManagePurchaseOrderProductModal
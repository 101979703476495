import queryKeys from "../queryKeys";
import {
	clearTableService,
	createTableService,
	deleteTableService,
	editTableService,
	getTableService
} from "../../../services/tableServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import errorHandler from "../../../utils/errorHandler";
import {
	desktopOfflineQueryKeys,
	useDesktopTablesServiceFromLocalDB
} from "../../useDesktop/useDesktopServicesFromLocalDB";
import {createEntryInDB} from "../../useDesktop/useDesktopOfflineMutation";
import {IPC_EVENTS} from "../../../shared/ipcEvents";


const useTableQuery = (searchValue) => {
	const {getBusinessId, isOffline} = useGlobalContext()
	const businessId = getBusinessId()
	
	const offlineData = useDesktopTablesServiceFromLocalDB(searchValue)
	
	const onlineData = useQuery({
		queryKey: [queryKeys.TABLES, searchValue],
		queryFn: getTableService.bind(this, businessId, searchValue)
	})
	
	const fetch = () => {
		return isOffline ? offlineData : onlineData
	}
	
	return fetch()
}

const useCreateTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()

	return useMutation({
		mutationFn: data => {
			return createTableService({ ...data, business: businessId })
		},

		onSuccess: () => {
			successFn()
			toast.success("Table Created!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useEditTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: (data) => {
			return editTableService(data.id, data)
		},

		onSuccess: () => {
			successFn()
			toast.success("Edit Successful!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useDeleteTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: (tableId) => {
			return deleteTableService(tableId)
		},

		onSuccess: () => {
			successFn()
			toast.success("Delete Successful!!")
			queryClient.invalidateQueries({ queryKey: queryKeys.TABLES })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useClearTableMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const {toast, isOffline} = useGlobalContext()

	return useMutation({
		mutationFn: (tableId) => {
			return isOffline ? createEntryInDB(IPC_EVENTS.CLEAR_TABLE, {tableId}) : clearTableService(tableId)
		},

		onSuccess: () => {
			successFn()
			toast.success("Table Cleared")
			queryClient.invalidateQueries({queryKey: queryKeys.TABLES})
			queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all}).catch()
			queryClient.prefetchQuery({queryKey: desktopOfflineQueryKeys.all}).catch()
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export {
	useTableQuery, useCreateTableMutation, useEditTableMutation,
	useDeleteTableMutation, useClearTableMutation
}
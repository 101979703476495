import { Link } from "react-router-dom";
import { BackgroundPattern, RegisterLayout } from "../AuthStyles";
import {Button, Form, Icon, Input, PasswordInput, QuoteCreator} from "../../../components";

import {CenterContent} from "../../../styles/utilStyles";
import {Logo, LogoLight} from "../../../assets/images";
import {Heading, Heading2, Paragraph} from "../../../styles/textStyles";
import {QuoteIcon} from "../../../assets/icons";
import {useValidateForgotPasswordMutation} from "../../../hooks/useServices/authenticationHooks";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useEffect} from "react";
import useAllowOnlyUnauthenticatedUsers from "../../../hooks/useRouteProtection/useAllowOnlyUnauthenticatedUsers";
import {isPasswordRequirementsValid, isStringLengthValid, isStringsMatch} from "../../../utils/validator";


const ValidateForgotPassword = () => {
	useAllowOnlyUnauthenticatedUsers()
	const { globalState, toast, navigate } = useGlobalContext()
	const forgotPasswordMutation = useValidateForgotPasswordMutation()
	const formValues = { email: globalState.forgotPasswordEmail, password: "", confirm_password: "", otp: "" }
	
	useEffect(() => {
		if(!globalState.forgotPasswordEmail) {
			navigate("/auth/forgot-password")
		}
	}, [])
	
	const handleValidateForgotPassword = (values) => {

		if(!isStringLengthValid(values.password, 8)) {
			toast.error("Password length must be at least 8 characters", values.password)
			return
		}

		if(!isPasswordRequirementsValid(values.password)) {
			toast.error("Password must contain at least an uppercase and lowercase letter, number and a symbol", values.password)
			return
		}

		if(!isStringsMatch(values.password, values.confirm_password)) {
			toast.error("Both password do not match", values.password)
			return
		}
		
		forgotPasswordMutation.mutate(values)
	}
	
	return (
		<RegisterLayout>
			{/* FIRST COLUMN - FORM SECTION */}
			<div className="logo_div">
				<img src={LogoLight} alt="Ovaloop logo" />
			</div>
			
			
			<section className="email_and_password">
				<div className="email_and_password_div">
					<img src={Logo} alt="Ovaloop logo" />
					<div>
						<Heading2 color="black">Choose a new password</Heading2>
						<Form values={formValues} onSubmit={handleValidateForgotPassword}>
							<Input type="email" label="Email" placeholder="Email address" name="email" disabled />
							<Input type="number" label="OTP" placeholder="Enter the OTP sent to your email" name="otp" />
							<PasswordInput name="password" placeholder="New password" label="New Password" />
							<PasswordInput name="confirm_password" placeholder="Enter password again" label="Confirm new password" />
							
							<Button type="submit" isLoading={forgotPasswordMutation.isLoading} text="Reset Password" shadow />
						</Form>
					</div>
					
					<Paragraph color="gray" bold size={.9}>
						Don’t have an account? <Link to="/register">Sign up now</Link>
					</Paragraph>
				</div>
			</section>
			
			
			{/* SECOND COLUMN - QUOTE SECTION */}
			<BackgroundPattern className="quote_section">
				<div>
					<CenterContent>
						<Icon iconImg={QuoteIcon} bgDark />
						<Heading size={1.4} spread>
							"I've always had the issue of knowing the stock level of each product, keeping up with orders and knowing when to stock up can all be challenging. That has changed since I started using Ovaloop, with just a few clicks I can view it all at a glance. I now have peace of mind when I'm at my business.
							<br/> <br/>
							I recommend Ovaloop to every SME that wants a seamless business operation."
						</Heading>
						<QuoteCreator />
					</CenterContent>
				</div>
			</BackgroundPattern>
		</RegisterLayout>
	)
}

export default ValidateForgotPassword
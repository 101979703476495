import {Fragment} from "react";
import {DashboardStyled} from "../../../styles/DashboardStyles";
import {SpaceBetweenStyled} from "../../../styles/utilStyles";
import {
  OvaloopDashboardAnalyticsHeader,
  OverviewCard,
  SearchAndFilter,
  Table, TableItemWithEyeAction, TablePagination
} from "../../../components";
import {BarIcon} from "../../../assets/icons";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
  ovaloopPayAnalyticsQueryKey,
  useOvaloopPayAnalyticsService,
} from "../../../hooks/useServices/useStaffBackendServices/useOvaloopPayAnalyticsServices";
import {useTheme} from "styled-components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import accountQueryKey from "../../../hooks/useServices/useStaffBackendServices/useAccountServices/accountQueryKey";
import {
  useBusinessesUsingOvaloopPayQuery
} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {getOvaloopPayAnalyticsService} from "../../../services/staffBackendServices/ovaloopPayAnalyticsServices";
import {useQueryClient} from "react-query";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const OvaloopPayAnalytics = () => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { nairaCurrencyFormatter } = useDataTypeFormatter()
  const { data: ovaloopPayAnalytics } = useOvaloopPayAnalyticsService()
  
  const { navigate } = useGlobalContext()
  const { storesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allBusinesses } = useOrderQueryWithSearchAndFilter({ search: debounceState.searchValue }, useBusinessesUsingOvaloopPayQuery)
  
  
  const handleViewBusiness = (businessId) => {
    navigate(`/staff_backend/ovaloop_pay_analytics/${businessId}`)
  }
  
  const refetchQueries = (from_date, to_date) => {
    queryClient.fetchQuery({
      queryKey: ovaloopPayAnalyticsQueryKey.dashboard,
      queryFn: () => getOvaloopPayAnalyticsService({ from_date, to_date })
    })
  }
  
  return (
    <Fragment>
      <OvaloopDashboardAnalyticsHeader title="Ovaloop Pay Analytics" refetchQueries={refetchQueries} />
      
      <DashboardStyled>
        <SpaceBetweenStyled>
          <OverviewCard
            icon={BarIcon}
            title="Total Transactions"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[0]?.total_transactions || 0)}
            bgColor={theme.overViewCard[3]}
          />
          
          <OverviewCard
            icon={BarIcon}
            title="Ovaloop Charges (Profit)"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[1]?.total_ovaloop_charge || 0)}
            bgColor={theme.overViewCard[2]}
          />
          
          <OverviewCard
            icon={BarIcon}
            title="Total Settled Amount"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[3]?.total_settlement || 0)}
            bgColor={theme.overViewCard[1]}
          />
          
          <OverviewCard
            icon={BarIcon}
            title="Total Pending Settlement"
            value={nairaCurrencyFormatter(ovaloopPayAnalytics?.data?.[2]?.pending_settlement || 0)}
            bgColor={theme.overViewCard[0]}
          />
        </SpaceBetweenStyled>
      </DashboardStyled>
      
      
      <Fragment>
        <SetUpLayoutStyled>
          <SearchAndFilter
            noFilter
            dispatch={debounceReducer}
            searchPlaceholder="Search business by name"
          />
    
          <Table headers={storesTable.headers} noCols={5}>
            {allBusinesses.isQueryWithData && (
              allBusinesses.data.results.map((items, k) =>
                <TableItemWithEyeAction
                  key={k}
                  count={k}
                  data={items}
                  keys={storesTable.values}
                  onClick={handleViewBusiness}
                  pageCount={allBusinesses.currentCount}
                />
              )
            )}
      
            {allBusinesses.isQueryWithNoData && (
              <NoQueryData text={allBusinesses.isSearchEmptyMessage("Business", "")} />
            )}
      
            {allBusinesses.isQueryLoading && (
              <TableSkeleton />
            )}
          </Table>
    
          {allBusinesses.showPagination && (
            <TablePagination
              query={allBusinesses}
              queryKey={accountQueryKey.byAccountId(undefined, debounceState.searchValue)}
            />
          )}
        </SetUpLayoutStyled>
        {allBusinesses.showPagination && (
          <TablePagination
            query={allBusinesses}
            queryKey={accountQueryKey.byAccountId(undefined, debounceState.searchValue)}
          />
        )}
      </Fragment>
    </Fragment>
  )
}

export default OvaloopPayAnalytics
import {Heading} from "../../../styles/textStyles";
import {NotificationDrawerStyled, NotificationModalStyled} from "../../../styles/drawerStyles";
import NotificationCard from "../../Card/NotificationCard";


const NotificationDrawer = ({ closeDrawer, data }) => {
	return (
		<NotificationModalStyled onClick={closeDrawer}>
			<NotificationDrawerStyled onClick={e => e.stopPropagation()}>
				<div className="heading">
					<Heading color="black" bold size={1.2}>Notifications</Heading>
				</div>

				{data?.map(notification => (
					<NotificationCard key={notification.id} notification={notification} onClose={closeDrawer} />
				))}

			</NotificationDrawerStyled>
		</NotificationModalStyled>
	)
}


export default NotificationDrawer
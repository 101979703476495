import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const getNotifications = async (query) => {
	try {
		const url = handleQueryParams("notification", query)
		const {data} = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

export const readNotificationService = async (id) => {
	try {
		const { data } = await axios.patch(`/notification/${id}/`, { is_read: true })
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  getFaultyProductLogByBusinessIdService,
  getInventoryByBusinessIdService,
  getInventoryByExpiryService,
  updateFacultyProductLogService,
  updateFaultyProductInventoryService,
  updateInventoryByExpiryService,
  updateInventoryService
} from "../../../services/inventoryServices";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import inventoryQueryKey from "./inventoryQueryKey";
import errorHandler from "../../../utils/errorHandler";
import productQueryKey from "../useProductServices/productQueryKey";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const useInventoryByBusinessIdQuery = (queryParams = {}) => {
  scrollToTop()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    queryKey: inventoryQueryKey.mainList(queryParams),
    queryFn: () => getInventoryByBusinessIdService({business_id: getBusinessId(), ...queryParams})
  })
}

export const useFaultyProductLogByBusinessIdQuery = (queryParams = {}) => {
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    queryKey: inventoryQueryKey.bad_and_damage(queryParams),
    queryFn: () => getFaultyProductLogByBusinessIdService({...queryParams, business_id: getBusinessId()})
  })
}

export const useInventoryByExpiryQuery = (query) => {
  scrollToTop()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    queryKey: inventoryQueryKey.expiry(query),
    queryFn: () => getInventoryByExpiryService({business_id: getBusinessId(), ...query})
  })
}

const useUpdateInventoryMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return updateInventoryService({ ...data, log: data.id })
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Inventory Update!!")
      queryClient.invalidateQueries({queryKey: inventoryQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateInventoryOrProductDistribution,
      })
    }
  })
}

export const useUpdateFacultyProductLogMutation = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const {toast} = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return updateFacultyProductLogService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Update Successful!!")
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateFaultyProductLog,
      })
    }
  })
}

export const useUpdateFaultyProductInventoryMutation = ({successFn}) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const {toast} = useGlobalContext()
  
  return useMutation({
    mutationFn: data => {
      return updateFaultyProductInventoryService({...data, log: data.id})
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Inventory Update!!")
      queryClient.refetchQueries({queryKey: productQueryKey.all})
      queryClient.invalidateQueries({queryKey: inventoryQueryKey.all})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.moveFaultyProductInventory,
      })
    }
  })
}


export const useUpdateProductExpiryInventoryMutation = () => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return updateInventoryByExpiryService(getBusinessId(), data.id, data)
    },
  
    onSuccess: () => {
      toast.success("Inventory Update!!", "update")
      queryClient.invalidateQueries({queryKey: inventoryQueryKey.expiry("")})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateProductExpiryInventory,
      })
    }
  })
}


export { useInventoryByBusinessIdQuery, useUpdateInventoryMutation }
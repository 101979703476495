import {useParams, useSearchParams} from "react-router-dom";
// import {scrollWindowToTop} from "../../../utils";

const useGroupOrderIdParams = (paramKey) => {
  // scrollWindowToTop()
  
  const params = useParams()
  const groupOrderId = params[paramKey || "groupOrderId"]
  
  const [searchParam] = useSearchParams()
  const isTrimmed = searchParam.get("trimmed")
  
  if(!isTrimmed) {
    return groupOrderId
  }
  
  const count = Number(searchParam.get("count"))
  const orderArr = groupOrderId.split("")
  orderArr.splice(count, 0, "/")
  
  return orderArr.join("")
}

export default useGroupOrderIdParams
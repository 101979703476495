import {Image, Line} from "../index";
import {useTheme} from "styled-components";
import {Edit, Eye, Trash} from "iconsax-react";
import {Heading} from "../../styles/textStyles";
import {NoProductImage} from "../../assets/images";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {TableItemActionStyled, TableItemStyled} from "../../styles/TableStyles";
import {useActionPermission} from "../../hooks/usePermissions";
import {NO_OF_RESULTS_FROM_BACKEND} from "../../utils/constants";
import {Fragment} from "react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const TableItemWithImageAndActions = ({ data, actionPosition, keys, onClick, editAction, deleteAction, editPermission, deletePermission, pageCount, count, noAction, onlyView }) => {
  const theme = useTheme()
  const { isOffline, toast } = useGlobalContext()
  const tableData = useTableValueTransformer(data, keys)
  const showEditButton = useActionPermission(editPermission)
  const showDeleteButton = useActionPermission(deletePermission)
  
  const handleEdit = (data) => {
    if(isOffline) {
      toast.error("You cannot edit when offline", "offline")
      return
    }
    
    editAction?.(data)
  }
  
  const handleDelete = (data) => {
    if(isOffline) {
      toast.error("You cannot delete when offline", "offline")
      return
    }
    
    deleteAction?.(data)
  }

  return (
    <TableItemStyled onClick={onClick?.bind(this, data.id)}>
      <td className="counter">
        <Heading color="black" thin size={1}>{(count+1) + ((pageCount-1) * NO_OF_RESULTS_FROM_BACKEND)}</Heading>
      </td>
      
      {tableData.map((item, k) =>
        k === 0 ?
          (<td key={k}> <Image className="product_image" src={item || NoProductImage} /> </td>) :
          (<td key={k}><Heading color="black" thin size={.9}>{item}</Heading></td>)
      )}
  
      {!noAction && (
        <TableItemActionStyled position={actionPosition} onClick={e => e.stopPropagation()}>
          <Eye color={theme.gray} onClick={onClick?.bind(this, data.id)}/>
          {!onlyView && (
            <Fragment>
              {showEditButton && <Edit color={theme.success} onClick={() => handleEdit(data)} />}
              {showDeleteButton && <Trash color={theme.error} onClick={() => handleDelete(data.id)} />}
            </Fragment>
          )}
        </TableItemActionStyled>
      )}
      <Line />
    </TableItemStyled>
  )
}

export default TableItemWithImageAndActions
const IPC_EVENTS = {
  // UPLOAD EVENTS
  UPLOAD_DISCOUNTS: "UPLOAD_DISCOUNTS",
  UPLOAD_TAXES: "UPLOAD_TAXES",
  UPLOAD_TABLES: "UPLOAD_TABLES",
  UPLOAD_SUPPLIERS: "UPLOAD_SUPPLIERS",
  UPLOAD_CUSTOMERS: "UPLOAD_CUSTOMERS",
  UPLOAD_BANKS: "UPLOAD_BANKS",
  UPLOAD_PRODUCTS: "UPLOAD_PRODUCTS",
  UPLOAD_CATEGORY: "UPLOAD_CATEGORY",
  UPLOAD_SUB_CATEGORY: "UPLOAD_SUB_CATEGORY",
  UPLOAD_NESTED_SUB_CATEGORY: "UPLOAD_NESTED_SUB_CATEGORY",
  UPLOAD_ADDONS: "UPLOAD_ADDONS",
  UPLOAD_ADDON_STAGES: "UPLOAD_ADDON_STAGES",
  UPLOAD_GROUP_ORDERS: "UPLOAD_GROUP_ORDERS",
  UPLOAD_SINGLE_GROUP_ORDERS: "UPLOAD_SINGLE_GROUP_ORDERS",
  UPLOAD_USER: "UPLOAD_USER",
  UPLOAD_BUSINESS: "UPLOAD_BUSINESS",
  
  
  // GET EVENTS
  GET_DISCOUNTS: "GET_DISCOUNTS",
  GET_TAXES: "GET_TAXES",
  GET_TABLES: "GET_TABLES",
  GET_SUPPLIERS: "GET_SUPPLIERS",
  GET_CUSTOMERS: "GET_CUSTOMERS",
  GET_BANKS: "GET_BANKS",
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_CATEGORY: "GET_CATEGORY",
  GET_SUB_CATEGORY: "GET_SUB_CATEGORY",
  GET_NESTED_SUB_CATEGORY: "GET_NESTED_SUB_CATEGORY",
  GET_ADDONS: "GET_ADDONS",
  GET_ADDON_STAGES: "GET_ADDON_STAGES",
  GET_GROUP_ORDERS: "GET_GROUP_ORDERS",
  GET_SINGLE_PRODUCT: "GET_SINGLE_PRODUCT",
  GET_OFFLINE_GROUP_ORDERS: "GET_OFFLINE_GROUP_ORDERS",
  GET_SINGLE_GROUP_ORDERS: "GET_SINGLE_GROUP_ORDERS",
  GET_USER: "GET_USER",
  GET_BUSINESS: "GET_BUSINESS",
  GET_ALL_BUSINESS: "GET_ALL_BUSINESS",
  GET_ALL_OFFLINE_ORDERS_FOR_SYNCING: "GET_ALL_OFFLINE_ORDERS_FOR_SYNCING",
  DELETE_ALREADY_SYNCED_ORDERS: "DELETE_ALREADY_SYNCED_ORDERS",
  
  // CREATE EVENTS
  CREATE_OFFLINE_ORDERS: "CREATE_OFFLINE_ORDERS",
  UPDATE_OFFLINE_ORDERS_PAYMENT: "UPDATE_OFFLINE_ORDERS_PAYMENT",
  PAY_FOR_ALL_ORDERS_IN_TABLE: "PAY_FOR_ALL_ORDERS_IN_TABLE",
  CLEAR_TABLE: "CLEAR_TABLE",
  CANCEL_GROUP_ORDER: "CANCEL_GROUP_ORDER",
  
  
  // UTILS EVENTS
  IS_OFFLINE: "IS_OFFLINE",
  RESTART_APP: "RESTART_APP",
  NOTIFICATION: "NOTIFICATION",
  CLEAR_DATABASE: "CLEAR_DATABASE",
  MODIFY_AUTO_SYNC: "MODIFY_AUTO_SYNC",
  IS_OFFLINE_READY: "IS_OFFLINE_READY",
  CLEAR_OFFLINE_ORDERS: "CLEAR_OFFLINE_ORDERS",
  MANUAL_DESKTOP_UPDATE: "MANUAL_DESKTOP_UPDATE",
  ALL_OFFLINE_DATA_HAS_SYNCED: "ALL_OFFLINE_DATA_HAS_SYNCED",
  MANUAL_DESKTOP_DOWNLOAD_PROGRESS: "MANUAL_DESKTOP_DOWNLOAD_PROGRESS",
  MANUAL_UPDATE_OF_ALL_OFFLINE_DATA: "MANUAL_UPDATE_OF_ALL_OFFLINE_DATA",
  EXPORT_OFFLINE_DATABASE: "EXPORT_OFFLINE_DATABASE",
  IMPORT_OFFLINE_DATABASE: "IMPORT_OFFLINE_DATABASE",
  
  // INTERVAL SYNC EVENTS
  SYNC_OFFLINE_ORDERS_INTERVAL: "SYNC_OFFLINE_ORDERS_INTERVAL",
  SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL: "SYNC_ONLINE_DATA_TO_OFFLINE_DATABASE_INTERVAL",
  
  // LOG ERRORS WHEN FETCHING DATA FROM DATABASE
  logDatabaseFetchError: function (ipcEventName) {
    return `GET_DATA_FROM_DATABASE_ERROR_LOGGER_${ipcEventName}`
  },
  
  // AUTH EVENTS
  LOGIN_USER_ON_DESKTOP: "LOGIN_USER_ON_DESKTOP",
  GET_NUMBER_OF_USER_IN_OFFLINE_DB: "GET_NUMBER_OF_USER_IN_OFFLINE_DB"
}

module.exports = { IPC_EVENTS }
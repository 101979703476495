import {CloseSquare} from "iconsax-react";
import {Button, Form, Input, Select} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {createCustomerSubscriptionFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useBusinessSubscriptionQuery} from "../../../../hooks/useServices/useBusinessSubscriptionServices";


const CreateCustomerSubscriptionModal = ({ onClose, handleSubmit, mutation, customer }) => {
  const defaultFormValues = {
    ...createCustomerSubscriptionFormDefaultValues,
    customer_name: `${customer.first_name} ${customer.last_name}`
  }

  const { data: businessSubscription } = useBusinessSubscriptionQuery()

  const handleSubmission = (formValues) => {
    handleSubmit({ ...formValues, customer: customer.id })
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Customer Subscription</Heading>

        <Form values={defaultFormValues} onSubmit={handleSubmission}>
          <Input disabled optional label="Customer name" placeholder="Enter customer name" name="customer_name"  />

          <Select
            options={businessSubscription}
            displayKey="name"
            valueKey="id"
            label="Subscription Package"
            placeholder="Select subscription package"
            name="subscription_service"
            displayName="subscription_service_name"
          />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>

      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateCustomerSubscriptionModal
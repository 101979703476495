import {IPC_EVENTS} from "../../shared/ipcEvents";

export const desktopOfflineLoginService = (body) => {
  if (!window?.require) throw new Error("You are not using a desktop")
  const {ipcRenderer} = window?.require("electron")
  
  return new Promise((resolve, reject) => {
    ipcRenderer.once(IPC_EVENTS.LOGIN_USER_ON_DESKTOP, (_, data) => {
      if (data?.err) {
        reject(data?.err)
        return
      }
      
      resolve(data)
    })
    
    ipcRenderer.send(IPC_EVENTS.LOGIN_USER_ON_DESKTOP, body)
  })
}
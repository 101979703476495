import axios from "../../axios";


const getBusinessSubscriptionService = async (businessId, searchValue = "") => {
  try {
    const { data } = await axios.get(`/subscription_service/?business_id=${businessId}&search=${searchValue}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createBusinessSubscriptionService = async (body) => {
  try {
    const { data } = await axios.post(`/subscription_service/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editBusinessSubscriptionService = async (subscriptionId, body) => {
  try {
    const { data } = await axios.patch(`/subscription_service/${subscriptionId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteBusinessSubscriptionService = async (subscriptionId) => {
  try {
    const { data } = await axios.delete(`/subscription_service/${subscriptionId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getBusinessSubscriptionService, createBusinessSubscriptionService, editBusinessSubscriptionService, deleteBusinessSubscriptionService }
import queryKeys from "../queryKeys";
import {
  createAddOnService,
  deleteAddOnService,
  editAddOnService,
  getAddOnService
} from "../../../services/addOnServices";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import generateId from "react-uuid";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopAddonsServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";


const useAddOnQuery = (searchValue = "") => {
  scrollToTop()
  const {getBusinessId, isOffline} = useGlobalContext()
  const offlineData = useDesktopAddonsServiceFromLocalDB(searchValue)
  
  const onlineData = useQuery({
    enabled: !isOffline,
    queryKey: [queryKeys.ADDONS, searchValue],
    queryFn: () => getAddOnService({ business_id: getBusinessId(), search: searchValue })
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCreateAddOnMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createAddOnService({...data, business: getBusinessId()})
    },
  
    onSuccess: () => {
      successFn()
      toast.success("AddOn Created!!", generateId())
      queryClient.invalidateQueries({queryKey: [queryKeys.ADDONS]})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.createAddon,
      })
    }
  })
}

const useEditAddOnMutation = ({ successFn }) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editAddOnService(data.id, data)
    },
  
    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!", generateId())
      queryClient.invalidateQueries({queryKey: [queryKeys.ADDONS]})
    },
  
    onError: (err, variables) => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.updateAddon,
      })
    }
  })
}

const useDeleteAddOnMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: addOnId => {
      return deleteAddOnService(addOnId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!", generateId())
      queryClient.invalidateQueries({ queryKey: [queryKeys.ADDONS] })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useAddOnQuery, useCreateAddOnMutation, useEditAddOnMutation, useDeleteAddOnMutation }
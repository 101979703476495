import {Fragment} from "react"
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {useAccountQuery} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";
import {
  NoDataLayout,
  PageHeader,
  ScreenLoading,
  SearchAndFilter,
  TableItemWithEyeAction,
  TablePagination, Table
} from "../../../components";
import {EmptyBoxImage} from "../../../assets/images";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
  getAccountsServiceStructure
} from "../../../services/staffBackendServices/accountServices/accountServiceStructure";
import accountQueryKey from "../../../hooks/useServices/useStaffBackendServices/useAccountServices/accountQueryKey";


const Accounts = () => {
  const { navigate } = useGlobalContext()
  const { accountsTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allAccounts } = useSearchQuery(debounceState, useAccountQuery)
  
  const handleViewAccount = (accountId) => {
    navigate(`/staff_backend/accounts/${accountId}`)
  }
  
  return (
    <div>
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allAccounts.refetch} />
      )}
      
      <Fragment>
        <PageHeader title="Accounts" />
        
        {allAccounts.isEmpty && (
          <Fragment>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No orders"
              imgSrc={EmptyBoxImage}
              heading="No orders yet"
            />
          </Fragment>
        )}
        
        {allAccounts.showTable && (
          <Fragment>
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search accounts by name"
              />
              
              <Table headers={accountsTable.headers} noCols={5}>
                {allAccounts.isQueryWithData && (
                  allAccounts.data.results.map((items, k) =>
                    <TableItemWithEyeAction
                      key={k}
                      count={k}
                      data={items}
                      keys={accountsTable.values}
                      onClick={handleViewAccount}
                      pageCount={allAccounts.currentCount}
                    />
                  )
                )}
                
                {allAccounts.isQueryWithNoData && (
                  <NoQueryData text={allAccounts.isSearchEmptyMessage("Account", "")} />
                )}
                
                {allAccounts.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {allAccounts.showPagination && (
                <TablePagination
                  query={allAccounts}
                  restructureFn={getAccountsServiceStructure}
                  queryKey={accountQueryKey.mainList(debounceState.searchValue)}
                />
              )}
            </SetUpLayoutStyled>
            {allAccounts.showPagination && (
              <TablePagination
                query={allAccounts}
                restructureFn={getAccountsServiceStructure}
                queryKey={accountQueryKey.mainList(debounceState.searchValue)}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}


export default Accounts
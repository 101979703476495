import {Fragment, useState} from "react";
import {StoreFrontOrderHistoryCard, StoreFrontPageHeader} from "../../Components";
import {
  useSingleGroupByBusinessIdAndGroupIdQuery
} from "../../../../hooks/useServices/useOrderServices";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import DateFilter from "../../../../components/Utils/DateFilter";
import handleDateFilterValue from "../../../../utils/handleDateFilterValue";
import {dateFilterValue, dateFilterValueEnum} from "../../../../data/selectField/dateFilter";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";

const StoreFrontSalesHistoryScreen = () => {
  const { toDate, fromDate } = handleDateFilterValue(dateFilterValue.THIS_MONTH)
  const [dateFilter, setDateFilter] = useState({ from_date: fromDate, to_date: toDate })
  
  const { mainQuery, ...allOrders } = useOrderQueryWithSearchAndFilter({ ...dateFilter }, useSingleGroupByBusinessIdAndGroupIdQuery.bind(this, "", dateFilter))
  
  return (
    <Fragment>
      <StoreFrontPageHeader pageTitle={`Sales History (${allOrders?.data?.results?.length || 0} Orders)`}>
        <DateFilter setDateFilter={setDateFilter} dateFilterDefaultValue={dateFilterValueEnum.THIS_MONTH} />
      </StoreFrontPageHeader>
  
      {allOrders.showTable && (
        <Fragment>
          {allOrders.isQueryWithData && (
            <Fragment>
              {allOrders.data.results.map(order => (
                <StoreFrontOrderHistoryCard
                  key={order.id}
                  order={order}
                />
              ))}
            </Fragment>
          )}
  
          {allOrders.isQueryLoading && (
            <TableSkeleton count={4} height={150} />
          )}
        </Fragment>
      )}
      
    </Fragment>
  )
}

export default StoreFrontSalesHistoryScreen

import moment from "moment";

export const currencyFormatterFn = (locale, currency) => {
	const formatter = new Intl.NumberFormat(locale, {
		currency,
		style: "currency",
	})
	
	return formatter.format
}

export const currencyFormatterWithNoFractionFn = (locale, currency) => {
	const formatter = new Intl.NumberFormat(locale, {
		currency,
		style: "currency",
		maximumFractionDigits: 0
	})
	
	return formatter.format
}

export const numberFormatterFn = (locale) => {
	const formatter = new Intl.NumberFormat(locale)
	
	return formatter.format
}

export const dateFormatterFn = (locale) => {
	return (date) => {
		const transformDate = new Date(date)
		return moment(transformDate, null, locale).format('Do MMM YYYY [at] h:mma')
	}
}

export const dateWithDayFormatterFn = (locale) => {
	return (date) => {
		const transformDate = new Date(date)
		return moment(transformDate, null, locale).format('ddd MMMM Do YYYY')
	}
}

export const dateOnlyFormatterFn = (locale) => {
	return (date) => {
		const transformDate = new Date(date)
		return moment(transformDate, null, locale).format('Do MMMM YYYY')
	}
}

export const shortDateFormatterFn = (locale) => {
	const formatter = new Intl.DateTimeFormat(locale, {
		dateStyle: "short",
	})
	
	return (date) => {
		const transformDate = new Date(date)
		return formatter.format(transformDate)
	}
}

export const timeFormatterFn = (locale) => {
	const formatter = new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	})
	
	return (date) => {
		const transformDate = new Date(date)
		// return formatter.format(transformDate)
		return moment(transformDate, null, locale).format('h:mm A')
	}
}
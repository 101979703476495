import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {capitalizeFirstLetterInText} from "../../utils/textTransformer";


const getDiscountService = async (queryParams = {}, currencyFormatter) => {
  try {
    const url = handleQueryParams("/discount", queryParams)
    const {data} = await axios.get(url)
    
    const discounts = data?.results?.map(discount => {
      return {
        ...discount,
        value_type: (discount?.value_type || "percentage"),
        discount_type: (discount?.discount_type || "general"),
        discount_type_name: capitalizeFirstLetterInText(discount?.discount_type || "general"),
        format_value: discount.value,
      }
    })
    return {...data, results: discounts}
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createDiscountService = async (body) => {
  try {
    const { data } = await axios.post(`/discount/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editDiscountService = async (discountId, body) => {
  try {
    const { data } = await axios.patch(`/discount/${discountId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteDiscountService = async (discountId) => {
  try {
    const { data } = await axios.delete(`/discount/${discountId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getDiscountService, createDiscountService, editDiscountService, deleteDiscountService }
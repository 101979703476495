import {MAX_IMAGE_SIZE} from "./constants";


const getCompressionRatioForImage = (size) => {
	const imageSize = size / 1000000
	let compressionRatio = 0.8
	
	if(imageSize > 15 && imageSize < 26) {
		compressionRatio = 0.6
	}
	
	if(imageSize > 25 && imageSize < MAX_IMAGE_SIZE) {
		compressionRatio = 0.4
	}
	
	return compressionRatio
};

export default getCompressionRatioForImage
import {Logo} from "../../assets/images";
import {Outlet} from "react-router-dom";
import {Heading2} from "../../styles/textStyles";
import {DashboardMobileNav, Image, MenuList} from "../../components";
import {DashboardLayoutStyled} from "../../styles/DashboardStyles";
import useAllowOnlyOvaloopStaff from "../../hooks/useRouteProtection/useAllowOnlyOvaloopStaff";
import {ovaloopStaffDashboardNavigationData} from "../../data/dashboardData";
import {Logout} from "iconsax-react";
import {clearAllDataInStorage} from "../../utils";
import {useQueryClient} from "react-query";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const StaffDashboardLayout = () => {
  useAllowOnlyOvaloopStaff()
  const queryClient = useQueryClient()
  const { navigate } = useGlobalContext()
  
  const handleLogout = () => {
    queryClient.clear()
    clearAllDataInStorage()
    navigate("/login")
  }
  
  return (
    <DashboardLayoutStyled>
      <DashboardMobileNav businessName="Staff Dashboard" navMenu={ovaloopStaffDashboardNavigationData} />
      
      <nav className="side_navigation">
        <div className="img_div">
          <Image src={Logo} alt="Ovaloop logo" />
        </div>
        
        <div className="nav_menu_list">
          <div>
            {ovaloopStaffDashboardNavigationData.map((item, k) => (
              <MenuList key={k} item={item} title={item.title} Icon={item.icon} link={item.link} dropDown={item.dropDown} options={item.options} />
            ))}
          </div>
  
          <div className="nav_menu_settings">
            <MenuList title="Logout" Icon={Logout} onClick={handleLogout} ignorePermission />
          </div>
          
        </div>
      </nav>
      
      <section>
        
        {/* Top Navigation */}
        <nav className="top_navigation">
          <Heading2 color="black">Staff Dashboard</Heading2>
        </nav>
        
        <main>
          <Outlet />
        </main>
      </section>
    
    </DashboardLayoutStyled>
  )
}

export default StaffDashboardLayout
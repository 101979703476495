import {CloseSquare} from "iconsax-react";
import {Button, Form, Input, Select} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import {useState} from "react";
import MultiSelectInput from "../../Forms/MultiSelectInput";


const AddProductionStageModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { toast } = useGlobalContext()
  const { numberFormatter } = useDataTypeFormatter()
  const { data: allStaffs } = useStaffByBusinessIdQuery()
  
  const [assignedStaffs, setAssignedStaffs] = useState([])
  
  const handleSubmission = (values) => {
    if(Number(values.quantity) > Number(formValues.available_quantity)) {
      toast.error("You can not move above the available quantity")
      return
    }
    
    handleSubmit({
      id: formValues.id,
      expected_quantity: Number(values.quantity),
      stage: values.stage,
      assigned_to: assignedStaffs.map(staff => ({ id: staff.id, name: staff.full_name })),
    })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Add Stage</Heading>
        <Form values={{ quantity: "", stage: "", stage_name: "" }} onSubmit={handleSubmission}>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Product:</Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.product_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Available Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.available_quantity)}</Paragraph>
          </FlexStyled>
  
          <Select
            options={formValues.product_stages}
            name="stage"
            displayKey="name"
            valueKey="id"
            label="Production Stage"
            placeholder="Select production stage"
            displayName="stage_name"
          />
  
          <Input label="Expected Quantity" placeholder="Enter quantity for this stage" name="quantity"  />
  
          <MultiSelectInput
            className="fullWidth"
            displayName="full_name"
            setSelected={setAssignedStaffs}
            options={allStaffs?.results || []}
            label="Assign staff"
            placeholder="Assign staffs to this stage"
          />
  
          <FlexStyled>
            <Button text="Start" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default AddProductionStageModal
import {
  NoDataLayout, PageHeader,
  PageHeaderWithButtonAndDateFilter, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {Fragment} from "react";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {CategoryImage} from "../../../assets/images";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {useProductionProcessQuery} from "../../../hooks/useServices/useProductionServices/useProductionService";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const ProductionProcess = () => {
  const { navigate } = useGlobalContext()
  const { productionTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allProductionProcess } = useOrderQueryWithSearchAndFilter({ search: debounceState.searchValue }, useProductionProcessQuery)
  
  const handleViewProductionProcess = (production) => {
    navigate(`/dashboard/production/process/${production.id}`)
  }
  
  return (
    <SetUpMainStyled>
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allProductionProcess.isEmpty && (
          <Fragment>
            <PageHeader title="Production Processes" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={CategoryImage}
              heading="No processes yet"
            />
          </Fragment>
        )}
        
        {allProductionProcess.showTable && (
          <Fragment>
            <PageHeaderWithButtonAndDateFilter title="Production Processes" />
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search unit measurement by name"
              />
              
              <Table headers={productionTable.headers} noCols={productionTable.headers.length}>
                {allProductionProcess.isQueryWithData && (
                  allProductionProcess.data.results.map((items, k) =>
                    <TableItemWithStatus
                      key={k}
                      data={items}
                      count={k}
                      statusPosition={4}
                      pageCount={allProductionProcess.currentCount}
                      keys={productionTable.values}
                      onView={handleViewProductionProcess}
                    />
                  )
                )}
                
                {allProductionProcess.isQueryWithNoData && (
                  <NoQueryData text={allProductionProcess.isSearchEmptyMessage("Production process", "")} />
                )}
                
                {allProductionProcess.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              
              {!!allProductionProcess?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allProductionProcess?.data?.next && <TablePagination />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ProductionProcess
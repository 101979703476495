import {useTaxQuery} from "../../useServices/useTaxServices";

const useTaxCalculation = () => {
	const { data: allTaxes } = useTaxQuery()

	return getTotalActiveTaxPercentage(allTaxes?.results)
}

export default useTaxCalculation


export const getTotalActiveTaxPercentage = (taxArr = []) => {
	return taxArr?.reduce((previousValues, currentValue) => {
		if(("active" in currentValue) && !currentValue.active) {
			return previousValues
		}
		
		return previousValues + currentValue.value
	}, 0)
}
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input, Select, TextArea} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {WIPStatus} from "../../../utils/table";
import {Fragment} from "react";
import {useFormikContext} from "formik";
import {productionStageActionFilters} from "../../../data/selectField/actionFilters";


const ViewProductionStageModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { toast } = useGlobalContext()
  const { numberFormatter, dateFormatter } = useDataTypeFormatter()
  
  const handleSubmission = (values) => {
    const body = {
      id: formValues.id,
      productionId: formValues.production,
      status: values.status === "in_progress" ? "completed" : values.new_status
    }
    
    if(body.status === "completed") {
      if(Number(values.new_completed_quantity) > Number(formValues.expected_quantity)) {
        toast.error("You can not move above the expected quantity")
        return
      }
      
      handleSubmit({
        ...body,
        completed_time: new Date().toISOString(),
        completed_quantity: values.new_completed_quantity,
        remark: values.new_remark
      })
      
      return
    }
  
    handleSubmit(body)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={45}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Add Stage</Heading>
        <Form values={formValues} onSubmit={handleSubmission}>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Stage:</Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.current_stage_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Expected Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.expected_quantity)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Completed Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.completed_quantity)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Incomplete Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.incomplete_quantity)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Created:</Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.date_created)}</Paragraph>
          </FlexStyled>
  
          {!!formValues.completed_time && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Time Completed:</Paragraph>
              <Paragraph color="black" size={1.2}>{dateFormatter(formValues.completed_time)}</Paragraph>
            </FlexStyled>
          )}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }} />
  
  
          {formValues?.assigned_to?.map((staff, k) => (
            <FlexStyled className="fullWidth" key={staff.id} style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Assigned Staff {k+1}:</Paragraph>
              <Paragraph color="black" size={1.2}>{staff.name}</Paragraph>
            </FlexStyled>
          ))}
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1rem" }} />
  
          {!!formValues.remark && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Remark:</Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.remark}</Paragraph>
            </FlexStyled>
          )}
          
          {!!formValues.status && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Status:</Paragraph>
              <Paragraph color="black" size={1.2}>{WIPStatus(formValues.status)}</Paragraph>
            </FlexStyled>
          )}
  
          {!!formValues.performed_by_name && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Performed By:</Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.performed_by_name}</Paragraph>
            </FlexStyled>
          )}
  
          {(formValues.status === "completed" || formValues?.productStatus === "completed") && (
            <FlexStyled className="fullWidth" />
          )}
  
          {(formValues.status !== "completed" && formValues?.productStatus !== "completed") && (
            <Fragment>
              <ModifyStageForms mutation={mutation} />
            </Fragment>
          )}
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default ViewProductionStageModal


const ModifyStageForms = ({ mutation }) => {
  const { values } = useFormikContext()
  
  return (
    <Fragment>
      {values.status !== "in_progress" && (
        <Select
          className="fullWidth"
          options={productionStageActionFilters}
          name="new_status"
          displayKey="name"
          valueKey="value"
          label="Production Stage"
          placeholder="Select production stage"
          displayName="new_status_name"
        />
      )}
  
      {(values.new_status === "completed" || values.status === "in_progress") && (
        <Fragment>
          <Input
            className="fullWidth"
            label="Completed Quantity"
            placeholder="Enter quantity for this stage"
            name="new_completed_quantity"
          />
  
          <TextArea
            className="fullWidth"
            label="Remark"
            placeholder="Enter your remark"
            name="new_remark"
          />
        </Fragment>
      )}
      
      <FlexStyled>
        <Button text={values.new_status === "completed" ? "Complete" : "Update"} type="submit" isLoading={mutation.isLoading} />
      </FlexStyled>
    </Fragment>
  )
}
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";
import {productionRequestRawMaterialsRestructure} from "./productionRestructureService";


export const getProductionRequestRawMaterialService = async (query) => {
  try {
    const url = handleQueryParams("/raw_material_request", query)
    const { data } = await axios.get(url)
    return productionRequestRawMaterialsRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const createProductionRequestRawMaterialService = async (body) => {
  try {
    const { data } = await axios.post(`/raw_material_request/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateProductionRequestRawMaterialService = async (body) => {
  try {
    const { data } = await axios.patch(`/raw_material_request/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
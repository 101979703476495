export default {
	DATE: "DATE",
	DATE_ONLY: "DATE_ONLY",
	STRING: "STRING",
	NUMBER: "NUMBER",
	CURRENCY: "CURRENCY",
	LOG_ACTION: "LOG_ACTION",
	NAIRA_CURRENCY: "NAIRA_CURRENCY",
	ACTIVE_STATUS: "ACTIVE_STATUS",
	NEGATIVE_NUMBER: "NEGATIVE_NUMBER",
	DATE_WITH_DAY_NAME: "DATE_WITH_DAY_NAME"
}
import {getAllObjectKeysWithValues} from "../../../../utils/handleQueryParams";

const key = "accounts"

const accountQueryKey = {
	all: [key],
	mainListKey: [key, "main"],
	mainList: (searchValue) => [key, "main", searchValue || undefined],
	analytics: [key, "analytics"],
	byAccountId: (id, search) => [key, "account", getAllObjectKeysWithValues({ id, search })],
	accountById: (id) => [key, "account_by_id", id],
	ovaloopPay: (query = {}) => ["ovaloop_pay", getAllObjectKeysWithValues(query)]
}

export default accountQueryKey
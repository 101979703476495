import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
	createCustomPaymentMethodService, deleteCustomPaymentMethodService,
	updateCustomPaymentMethodService,
	getCustomPaymentMethodServices
} from "../../../services/customPaymentMethodServices";
import {scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import useGlobalContext from "../../useContexts/useGlobalContext";


export const useCustomPaymentMethodQuery = (searchValue) => {
	scrollToTop()
	const { getBusinessId } = useGlobalContext()

	return useQuery({
		queryKey: [queryKeys.CUSTOM_PAYMENT_METHOD, searchValue],
		queryFn: getCustomPaymentMethodServices.bind(this, getBusinessId(), searchValue)
	})
}

export const useCreateCustomPaymentMethodMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return createCustomPaymentMethodService({ ...data, business: getBusinessId() })
		},

		onSuccess: () => {
			successFn()
			toast.success("Payment Method Created!!", "created")
			queryClient.invalidateQueries({ queryKey: queryKeys.CUSTOM_PAYMENT_METHOD })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useEditCustomPaymentMethodMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return updateCustomPaymentMethodService(data.id, data)
		},

		onSuccess: () => {
			successFn()
			toast.success("Edit Successful!!", "edit")
			queryClient.invalidateQueries({ queryKey: queryKeys.CUSTOM_PAYMENT_METHOD })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export const useDeleteCustomPaymentMethodMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: customPaymentMethodId => {
			return deleteCustomPaymentMethodService(customPaymentMethodId)
		},

		onSuccess: () => {
			successFn()
			toast.success("Delete Successful!!", "delete")
			queryClient.invalidateQueries({ queryKey: queryKeys.CUSTOM_PAYMENT_METHOD })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}
import {OverviewCardStyled} from "../../styles/cardStyles";
import {Heading, Heading2, Paragraph} from "../../styles/textStyles";
import { SpaceBetweenStyled } from "../../styles/utilStyles";
import {Image, PageSummaryCardSkeleton} from "../index";
import {Fragment} from "react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {useTheme} from "styled-components";
import {BarIcon} from "../../assets/icons";

const OverviewCard = ({ title, value, icon, bgColor, onClick, subText, isLoading }) => {
  const theme = useTheme()
  const { isOffline } = useGlobalContext()
  
  return (
    <Fragment>
      {isOffline && (
        <OverviewCardStyled bgColor={theme.overViewCard[3]}>
          <SpaceBetweenStyled>
            <div>
              <Heading color="white">No data available</Heading>
              <Heading2>Offline</Heading2>
            </div>
            <Image src={BarIcon} width={22} />
          </SpaceBetweenStyled>
        </OverviewCardStyled>
      )}
      
      {(isLoading && !isOffline) && (
        <PageSummaryCardSkeleton cardHeight={"6rem"} titleHeight={40} headingHeight={30} />
      )}
  
      {(!isLoading && !isOffline) && (
        <OverviewCardStyled bgColor={bgColor} onClick={onClick}>
          <SpaceBetweenStyled>
            <div>
              <Heading color="white">{title}</Heading>
              <Heading2>{value}</Heading2>
              <Paragraph color="black">{subText}</Paragraph>
            </div>
            <Image src={icon} width={22} />
          </SpaceBetweenStyled>
        </OverviewCardStyled>
      )}
    </Fragment>
  )
}

export default OverviewCard
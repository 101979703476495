import {CloseSquare} from "iconsax-react";
import {Button, FileInput, Form, Input, Select} from "../../index";
import { Heading } from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {memo} from "react";
import {useFormikContext} from "formik";
import {
  useSettlementBankQuery
} from "../../../hooks/useServices/useOvaloopPaymentServices";


const formValues = { bank_name: "", account_number: "", account_name: ""}

const CreateSettlementAccount = ({ onClose, handleSubmit, mutation }) => {
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Add Settlement Account</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <div className="modal_info">
            <Heading>To ensure convenient withdrawals, kindly provide your bank account details and a valid identification</Heading>
          </div>
          
          <BankSelection />
          
          <Input
            type="number"
            name="account_number"
            label="Account Number"
            placeholder="Enter your bank account number"
          />
          
          <FileInput accept="image/png, image/gif, image/jpeg, application/pdf" optional name="kyc_document" placeholder="Upload C.A.C Or Valid ID Card"  />
          
          <FlexStyled>
            <Button text="Add" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateSettlementAccount



const BankSelection = memo(() => {
  const { setFieldValue } = useFormikContext()
  const { data: settlementBanks } = useSettlementBankQuery()
  
  const handleSelection = (bank) => {
    setFieldValue("bank_name", bank.name)
    setFieldValue("bank_code", bank.code)
    
  }
  
  return (
    <Select
      name="beneficiary_bank"
      valueKey="code"
      label="Select bank"
      displayKey="name"
      displayName="bank_name"
      placeholder="Select your bank"
      options={settlementBanks?.banks}
      updateFn={handleSelection}
    />
  )
})
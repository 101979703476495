import {
  NoDataLayout, PageHeader,
  ScreenLoading,
  Table,
  TableItemWithStatus,
  TablePagination, Form, Select, DatePicker
} from "../../../components";
import {Fragment, memo, useEffect, useState} from "react";
import {
  activityLogKeys,
  useActivityLogQuery
} from "../../../hooks/useServices/useActivityLogServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {TaxImage} from "../../../assets/images";
import useQueryDebounce, {debounceActions} from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {SearchAndFilterStyled} from "../../../styles/utilStyles";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import {activitiesActionFilters} from "../../../data/selectField/actionFilters";


const ActivityLogs = () => {
  const { activityLogsTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const filterQuery = {
    user_id: debounceState.staffValue,
    activity: debounceState.filterValue,
    from_date: debounceState.fromDateValue,
    to_date: debounceState.toDateValue
  }
  
  const { mainQuery, ...allActivityLogs } = useOrderQueryWithSearchAndFilter(filterQuery, useActivityLogQuery)
  
  
  return (
    <SetUpMainStyled>
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
  
      <PageHeader title="Activity Log" />
      
      <Fragment>
        {allActivityLogs.isEmpty && (
          <Fragment>
            
            <NoDataLayout
              imgWidth={32}
              imgAlt="No activity log"
              imgSrc={TaxImage}
            />
          </Fragment>
        )}

        {allActivityLogs.showTable && (
          <Fragment>
            <SetUpLayoutStyled>
              <FilterOptions state={debounceState} dispatch={debounceReducer} />

              <Table headers={activityLogsTable.headers} noCols={activityLogsTable.headers.length} equalWidth>
                {allActivityLogs.isQueryWithData && (
                  allActivityLogs.data.results.map((items, k) =>
                    <TableItemWithStatus
                      noAction
                      key={k}
                      data={items}
                      count={k}
                      statusPosition={1}
                      keys={activityLogsTable.values}
                      pageCount={allActivityLogs.currentCount}
                    />
                  )
                )}

                {allActivityLogs.isQueryWithNoData && (
                  <NoQueryData text={allActivityLogs.emptyMessage("Activity Log")} />
                )}

                {allActivityLogs.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allActivityLogs.showPagination && (
                <TablePagination query={allActivityLogs} queryKey={activityLogKeys.main(filterQuery)} />
              )}
            </SetUpLayoutStyled>
            {!!allActivityLogs.showPagination && <TablePagination query={allActivityLogs} queryKey={activityLogKeys.main(filterQuery)} />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default ActivityLogs


const FilterOptions = memo(({ dispatch, state }) => {
  const [{ from_date, to_date }, setDates] = useState({from_date: state.fromDateValue, to_date: state.toDateValue})
  const { data: allStaffs } = useStaffByBusinessIdQuery()
  
  useEffect(() => {
    if(from_date && to_date) {
      dispatch({
        toDateValue: to_date,
        type: debounceActions.SET_FILTER_BY_TO_DATE
      })
      
      dispatch({
        fromDateValue: from_date,
        type: debounceActions.SET_FILTER_BY_FROM_DATE
      })
    }
  
    if(!from_date && !to_date) {
      dispatch({
        toDateValue: "",
        type: debounceActions.SET_FILTER_BY_TO_DATE
      })
    
      dispatch({
        fromDateValue: "",
        type: debounceActions.SET_FILTER_BY_FROM_DATE
      })
    }
  }, [from_date, to_date])
  
  const handleFilterByToDate = (date) => {
    setDates(prev => ({ ...prev, to_date: date }))
  }
  
  const handleFilterByFromDate = (date) => {
    setDates(prev => ({ ...prev, from_date: date }))
  }
  
  const handleStaffFilter = (staff) => {
    dispatch({
      staffValue: staff?.id || "",
      type: debounceActions.SET_FILTER_BY_STAFF_FIELD
    })
  }
  
  const handleActivityActionFilter = (action) => {
    dispatch({
      filterValue: action?.value || "",
      type: debounceActions.SET_FILTER_FIELD
    })
  }
  
  return (
    <SearchAndFilterStyled>
      <Form values={{ action: "", filter: "", to_date: "", from_date: "", staff: "" }}>
        <Select bgDark
          clearFilter
          options={allStaffs?.results}
          displayKey="full_name"
          valueKey="id"
          label="Filter by staff"
          placeholder="Filter activities by staff"
          name="staff"
          displayName="staff_name"
          updateFn={handleStaffFilter}

        />
        <Select bgDark
          clearFilter
          options={activitiesActionFilters}
          displayKey="name"
          valueKey="value"
          label="Filter by action"
          placeholder="Filter activities by action"
          name="action"
          displayName="name"
          updateFn={handleActivityActionFilter}
        />
  
        <DatePicker
          label="From Date"
          bgDark
          required
          labelBg="inputBGDark"
          name="from_date"
          className="date_field"
          placeholder="Filter From"
          onChange={handleFilterByFromDate}
        />
  
        <DatePicker
          label="To Date"
          bgDark
          required
          labelBg="inputBGDark"
          name="to_date"
          className="date_field"
          placeholder="Filter To"
          onChange={handleFilterByToDate}
        />
      </Form>
    </SearchAndFilterStyled>
  )
})
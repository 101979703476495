import axios from "../axios";
import countries from "../data/location/countries";


const getAllCountriesService = async () => {
  try {
    const { data } = await axios.get("/countries/")
    return data.map(country => ({ ...country, ...countries[country.code] }))
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getAllStatesByCountryIdService = async (countryId) => {
  try {
    const { data } = await axios.get(`/state/?country_id=${countryId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getAllLocalsByStateIdService = async (stateId) => {
  try {
    const { data } = await axios.get(`/local/?state_id=${stateId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getAllCountriesService, getAllStatesByCountryIdService, getAllLocalsByStateIdService }
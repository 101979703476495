import queryKeys from "./queryKeys";
import {useMutation, useQueryClient} from "react-query"
import {getUserProfileService} from "../../services/userProfileServices";
import {
  changePasswordService,
  emailVerificationService,
  forgotPasswordService,
  getOTPForEmailVerificationService,
  loginServices,
  registerServices,
  validateForgotPasswordService
} from "../../services/authenticationServices";
import useGlobalContext from "../useContexts/useGlobalContext";
import {globalReducerActions} from "../../reducers/globalReducer";
import {
  setAuthTokenInStorage,
  setBusinessAccountIdInStorage,
  setBusinessIdInStorage,
  setIsDesktopOnlineStatus,
  setUserIdInStorage
} from "../../utils";
import env from "../../utils/env";
import errorHandler from "../../utils/errorHandler";
import {desktopOfflineLoginService} from "../../services/desktopServices/desktopOfflineAuthServices";
import {desktopOfflineQueryKeys} from "../useDesktop/useDesktopServicesFromLocalDB";


const useLoginService = ({
                           successFn = () => {
                           }
                         }) => {
  const queryClient = useQueryClient()
  const {toast, navigate} = useGlobalContext()
  
  let isDesktopDBLogin = false
  
  return useMutation({
    mutationFn: body => {
      if (window?.require && body.isOfflineLogin) {
        isDesktopDBLogin = true
        return desktopOfflineLoginService(body)
      }
    
      return loginServices(body)
    },
  
    onSuccess: async (res, variables) => {
      toast.success("Login Successful")
      setAuthTokenInStorage(res.data.token, variables.remember)
    
      if (isDesktopDBLogin) {
        setUserIdInStorage(res.data.id)
        setIsDesktopOnlineStatus("offline")
        setBusinessAccountIdInStorage(res.data.account)
        localStorage.setItem("is_offline_login", "true")
      
        queryClient.setQueryData(desktopOfflineQueryKeys.user(), res.data)
        queryClient.setQueryData(desktopOfflineQueryKeys.allBusiness(), res.allStores)
      
        navigate("/select-store/")
        return
      }
      
      queryClient.prefetchQuery(queryKeys.USER_PROFILE, getUserProfileService)
        .finally(() => {
          if (variables.isStoreFront) {
            successFn()
            return;
          }
          
          if (res.data.user_type === "client_admin") {
            navigate("/select-store/")
            return
          }
          
          if (res.data.user_type === "staff") {
            navigate("/staff_backend")
            return
          }
          
          setBusinessIdInStorage(res.data.business_id, variables.remember)
          navigate("/dashboard/staff_dashboard/")
        })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })

}

const useRegisterService = () => {
  const queryClient = useQueryClient()
  const { toast, navigate, globalState: { packageId, packagePeriod, referralCode } } = useGlobalContext()

  return useMutation({
    mutationFn: body => {
      return registerServices({...body, package: packageId, package_duration: packagePeriod})
    },

    onSuccess: (res) =>  {
      setAuthTokenInStorage(res.token, true)
      toast.success("Account Created!!")
      queryClient.invalidateQueries({ queryKey: queryKeys.USER_PROFILE })

      // navigate(`/register/${packageId}/${packagePeriod}/verify_email/`)

      if(packageId === env.FREE_SUBSCRIPTION_PLAN) {
        navigate("/on-boarding/setup-store/")
        return;
      }

      navigate("/new_subscription")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
  
      let url = `/register/${packageId}/${packagePeriod}`
      if(referralCode) {
        url = `${url}?referral_code=${referralCode}`
      }

      navigate(url)
    }
  })
}

const useEmailVerificationService = () => {
  const queryClient = useQueryClient()
  const { toast, globalReducer } = useGlobalContext()

  return useMutation({
    mutationFn: body => {
      return emailVerificationService(body)
    },

    onSuccess: () =>  {
      toast.success("Email Verified", "success")
      queryClient.invalidateQueries({ queryKey: queryKeys.USER_PROFILE })
  
      globalReducer({
        type: globalReducerActions.CLEAR_EMAIL_FOR_VERIFY_EMAIL_OTP,
      })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useForgotPasswordMutation = () => {
  const { navigate, toast, globalReducer } = useGlobalContext()
  let userEmail = ""
  
  return useMutation({
    mutationFn: body => {
      userEmail = body.email
      return forgotPasswordService(body)
    },
    
    onSuccess: (res) => {
      toast.success(res.msg)
      
      globalReducer({
        email: userEmail,
        type: globalReducerActions.SET_FORGOT_PASSWORD_EMAIL,
      })
      
      navigate("/auth/validate-forgot-password")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useValidateForgotPasswordMutation = () => {
  const { navigate, toast, globalReducer } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return validateForgotPasswordService(body)
    },
    
    onSuccess: () => {
      toast.success("Password reset successful")
      
      globalReducer({
        type: globalReducerActions.CLEAR_FORGOT_PASSWORD_EMAIL,
      })
      
      navigate("/login")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useChangePasswordService = () => {
  const { toast } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return changePasswordService(body)
    },
    
    onSuccess: () =>  {
      toast.success("Password changed!!")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useGetOTPForEmailVerificationService = () => {
  const { toast, globalReducer } = useGlobalContext()
  
  return useMutation({
    mutationFn: body => {
      return getOTPForEmailVerificationService(body)
    },
    
    onSuccess: (res, variables) =>  {
      toast.success("The OTP has been sent to you email")
  
      globalReducer({
        email: variables.email,
        type: globalReducerActions.SET_EMAIL_FOR_VERIFY_EMAIL_OTP,
      })
    },
    
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export {
  useLoginService, useRegisterService, useEmailVerificationService,
  useForgotPasswordMutation, useValidateForgotPasswordMutation, useChangePasswordService
}
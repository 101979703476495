import {Fragment, memo} from "react";
import {StoreFrontPageHeader} from "../../Components";
import {StoreFrontCategoryLayoutStyled} from "../../../../styles/StoreFrontStyles/storeFrontLayoutStyles";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {useParams, useSearchParams} from "react-router-dom";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {
  useSellableProductForCategoryBasedQuery
} from "../../../../hooks/useServices/useProductServices";
import StoreFrontProductCard from "../../Components/Cards/StoreFrontProductCard";
import {AddToCartModal, Form, Select, TablePagination} from "../../../../components";
import {expensesSortBy} from "../../../../data/selectField/report";
import {sellReducerActions} from "../../../../reducers/sellReducer";
import useSellContext from "../../../../hooks/useContexts/useSellContext";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {findProductInArray} from "../../../../utils/sell";
import {useModal} from "../../../../hooks";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import productQueryKey from "../../../../hooks/useServices/useProductServices/productQueryKey";


const StoreFrontProductScreen = () => {
  const { storeName } = useParams()
  const [searchParams] = useSearchParams()
  const { reducer, cart } = useSellContext()
  const { toast, navigate } = useGlobalContext()
  
  const businessSettings = useBusinessSettingQuery()
  const [addToCartModal, setAddToCartModal] = useModal()
  
  const getQueryParams = () => {
    const query = {}
    
    if(searchParams.get("category_id") && searchParams.get("category_name")) {
      query.category_id = searchParams.get("category_id")
    }
    
    if(searchParams.get("search")) {
      query.search = searchParams.get("search").split("-").join(" ")
    }
  
    return query
  }
  
  const { mainQuery, ...allProducts } = useOrderQueryWithSearchAndFilter(getQueryParams(), useSellableProductForCategoryBasedQuery)
  
  const handlePageTitle = () => {
    if(searchParams.get("category_id") && searchParams.get("category_name")) {
      return `All Products in ${searchParams.get("category_name").split("-").join(" ")}`
    }
    
    return "All Products"
  }
  
  const handleViewProduct = (product) => {
    navigate(`/${storeName}/products/${product.id}`)
  }
  
  const handleRemoveProductFromCart = (product) => {
    reducer({
      productId: product.id,
      type: sellReducerActions.REMOVE_PRODUCT_FROM_CART,
    })
  
    toast.success("Product removed from cart", "removed_to_cart")
  }
  
  const handleAddToCartWithModal = (product) => {
    if(!businessSettings?.sell_below_stock && product.stock_unit < 1 && product.use_stock) {
      toast.error("You have exceeded the stock available", "out_of_stock")
      return
    }
    
    reducer({
      product,
      type: sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL
    })
    setAddToCartModal()
  }
  
  return (
    <Fragment>
      {addToCartModal && <AddToCartModal onClose={setAddToCartModal} />}
  
      <StoreFrontPageHeader LeftFilter={FilterProductField} RightFilter={SortProductField} pageTitle={handlePageTitle()} />
      
      <StoreFrontCategoryLayoutStyled $gap="1rem 2.5rem">
        {allProducts.showTable && (
          <Fragment>
            {allProducts.isQueryWithData && (
              allProducts?.data?.results?.map(product => {
                const isProductInCart = findProductInArray(cart, product.id)
                
                return (
                  <StoreFrontProductCard
                    key={product.id}
                    onView={handleViewProduct}
                    buttonText={isProductInCart ? "REMOVE FROM CART" : "ADD TO CART"}
                    handleClick={isProductInCart ? handleRemoveProductFromCart : handleAddToCartWithModal}
                    product={product}
                  />
                )
              })
            )}
          </Fragment>
        )}
        
        {allProducts.isQueryLoading && (
          [1, 2, 3, 4, 1, 2, 3, 4].map((_, k) => (
            <TableSkeleton height={200} count={1} noTop key={k} />
          ))
        )}
      </StoreFrontCategoryLayoutStyled>
  
      {allProducts.showPagination && (
        <TablePagination
          query={allProducts}
          queryKey={productQueryKey.sellableForCategoryBased(getQueryParams())}
        />
      )}
    </Fragment>
  )
}

export default StoreFrontProductScreen


const FilterProductField = memo(() => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: allCategories, isLoading: isCategoriesLoading } = useCategoriesQuery()
  
  const getFilterByData = () => {
    return { placeholder: "Filter by category", searchKey: "category_id", searchName: "category_name" }
  }
  
  const handleFilter = (result) => {
    setSearchParams((prevParams) => {
      const existingParam = new URLSearchParams(prevParams)
      
      if(!result.id) {
        existingParam.delete(getFilterByData().searchKey)
        existingParam.delete(getFilterByData().searchName)
        return existingParam.toString()
      }
      
      existingParam.set(getFilterByData().searchKey, result.id)
      existingParam.set(getFilterByData().searchName, result.name.split(" ").join("-"))
  
      return existingParam.toString()
    })
  }
  
  return (
    <Form values={{ filter_product: "", filter_product_display: searchParams.get("category_name")?.split("-")?.join(" ") }}>
      <Select
        displayName="filter_product_display"
        clearFilter
        updateFn={handleFilter}
        name="filter_product"
        displayKey="name"
        valueKey="id"
        options={allCategories?.results || []}
        isSearchLoading={isCategoriesLoading}
        placeholder={getFilterByData().placeholder}
      />
    </Form>
  )
})



const SortProductField = memo(() => {
  return (
    <Form values={{ sub_category_filter: "" }}>
      <Select  name="sub_category_filter" displayKey="name" valueKey="value" options={expensesSortBy} placeholder="Filter by sub category" />
    </Form>
  )
})
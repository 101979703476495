import queryKeys from "../queryKeys";
import {
	createStageService,
	deleteStageService,
	editStageService,
	getStagesByServiceIdService,
	getStageService
} from "../../../services/stagesServices";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useParams} from "react-router-dom";
import useDataTypeFormatter from "../../useUtils/useDataTypeFormatter";
import generateId from "react-uuid";
import errorHandler from "../../../utils/errorHandler";
import {useDesktopAddonStagesServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";


const useStageQuery = (searchValue) => {
	scrollToTop()

	const { getBusinessId } = useGlobalContext()
	return useQuery(
		[queryKeys.STAGES, searchValue],
		getStageService.bind(this, { business_id: getBusinessId(), search: searchValue })
	)
}

const useStagesByServiceIdQuery = (searchValue) => {
	const { serviceId } = useParams()
	const { getBusinessId, isOffline } = useGlobalContext()
	const businessId = getBusinessId()
	const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
	
	const offlineData = useDesktopAddonStagesServiceFromLocalDB({ service: serviceId, search: searchValue })

	const onlineData = useQuery({
		enabled: !isOffline,
		queryKey: [queryKeys.STAGES, businessId, serviceId, searchValue],
		queryFn: () => getStagesByServiceIdService(businessId, serviceId, searchValue, currencyFormatter, numberFormatter)
	})
	
	const fetch = () => {
		return isOffline ? offlineData : onlineData
	}
	
	return fetch()
}

const useCreateStageMutation = ({ successFn }) => {
	const { serviceId } = useParams()
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return createStageService({ ...data, business: getBusinessId(), service: serviceId })
		},

		onSuccess: () => {
			successFn()
			const toastId = generateId()
			toast.success("Stage Created!!", toastId)

			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES] })
			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES, undefined] })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useEditStageMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: data => {
			return editStageService(data.id, data)
		},

		onSuccess: () => {
			successFn()
			const toastId = generateId()
			toast.success("Edit Successful!!", toastId)
			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES] })
			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES, ""] })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

const useDeleteStageMutation = ({ successFn }) => {
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()

	return useMutation({
		mutationFn: addOnId => {
			return deleteStageService(addOnId)
		},

		onSuccess: () => {
			successFn()
			const toastId = generateId()
			toast.success("Delete Successful!!", toastId)
			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES] })
			queryClient.invalidateQueries({ queryKey: [queryKeys.STAGES, ""] })
		},

		onError: err => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export { useStageQuery, useStagesByServiceIdQuery, useCreateStageMutation, useEditStageMutation, useDeleteStageMutation }
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getDiscountService} from "../../../services/discountServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {getBusinessIdFromStorage} from "../../../utils";
import {getTaxService} from "../../../services/taxServices";
import {getCustomerService} from "../../../services/customerServices";
import {getBankService} from "../../../services/bankServices";
import {getProductService} from "../../../services/productServices";
import {getCategoriesService} from "../../../services/categoryServices";
import {getSubCategoriesService} from "../../../services/subCategoryServices";
import {getNestedSubCategoriesService} from "../../../services/nestedSubCategoryServices";
import {getAddOnService} from "../../../services/addOnServices";
import {
  getGroupOrderByBusinessIdService,
  getSingleGroupByBusinessIdAndGroupId,
  syncOfflineOrdersOnlineService
} from "../../../services/orderServices";
import {getStageService} from "../../../services/stagesServices";
import useDataTypeFormatter from "../../useUtils/useDataTypeFormatter";
import {getAllStaffUserProfileService} from "../../../services/userProfileServices";
import {getStoreByIdService} from "../../../services/storeServices";
import useDesktopOfflineModeAllowed from "../useUtils/useDesktopOfflineModeAllowed";
import sendDesktopNotification from "../../../desktop/sendDesktopNotification";
import {desktopOfflineQueryKeys, useDesktopBusinessSyncStatusFromLocalDB} from "../useDesktopServicesFromLocalDB";
import {PAGINATION_COUNT_FOR_OFFLINE_SYNCING} from "../../../utils/constants";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import errorHandler from "../../../utils/errorHandler";
import orderQueryKey from "../../useServices/useOrderServices/orderQueryKey";
import {formatDatetimeForBackend, isAllDesktopRequestSynced} from "../../../utils/desktopUtils/desktopSyncUtils";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import {getSupplierService} from "../../../services/supplierServices";
import {getTableService} from "../../../services/tableServices";

const all = ["DESKTOP"]
export const desktopQueryKeys = {
  all: all,
  productKey: [...all, "PRODUCTS"],
  customerKey: [...all, "CUSTOMERS"],
  userKey: [...all, "USER"],
  allUsers: [...all, "ALL USERS"],
  type: (name) => [...all, {name, business_id: getBusinessIdFromStorage()}],
  category: [...all, {name: "Category", business_id: getBusinessIdFromStorage()}],
  user: [...all, "USER", {name: "User Profile", business_id: getBusinessIdFromStorage()}],
  business: [...all, {name: "Business", business_id: getBusinessIdFromStorage()}],
  discount: [...all, {name: "Discount", business_id: getBusinessIdFromStorage()}],
  tax: [...all, {name: "Tax", business_id: getBusinessIdFromStorage()}],
  supplier: [...all, {name: "Supplier", business_id: getBusinessIdFromStorage()}],
  customer: (query) => [...all, "CUSTOMERS", {name: "Customer", business_id: getBusinessIdFromStorage(), ...query}],
  banks: [...all, {name: "Banks", business_id: getBusinessIdFromStorage()}],
  products: (query) => [...all, "PRODUCTS", {name: "Products", business_id: getBusinessIdFromStorage(), ...query}],
  subCategory: [...all, {name: "Sub Category", business_id: getBusinessIdFromStorage()}],
  nestedSubCategory: [...all, {name: "Nested Sub Category", business_id: getBusinessIdFromStorage()}],
  tables: (query) => [...all, {name: "Tables", business_id: getBusinessIdFromStorage(), ...query}],
  addon: [...all, {name: "Addon", business_id: getBusinessIdFromStorage()}],
  stage: [...all, {name: "Stages", business_id: getBusinessIdFromStorage()}],
  groupOrder: [...all, {name: "Group Order", business_id: getBusinessIdFromStorage()}],
  singleOrders: [...all, {name: "Single Orders", business_id: getBusinessIdFromStorage()}],
}


const useLastSyncDateIntervalSyncing = (query = {}) => {
  const businessId = getBusinessIdFromStorage()
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {data: businessSyncStatus} = useDesktopBusinessSyncStatusFromLocalDB()
  
  const getIsQueryEnabled = () => {
    return !(!window?.require || !businessId || !isDesktopOfflineModeAllowed || isAllDesktopRequestSynced());
  }
  
  if (!window?.require || !businessSyncStatus?.last_sync_date) return {
    businessId,
    queryObj: query,
    isQueryEnabled: getIsQueryEnabled()
  }
  
  const last_sync_date = businessSyncStatus.last_sync_date
  const last_pull_date = formatDatetimeForBackend(last_sync_date)
  const newQuery = {...query, last_pull_date}
  
  return {
    businessId,
    queryObj: newQuery,
    isQueryEnabled: getIsQueryEnabled()
  }
}

export const useDesktopAllUsersProfileService = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.allUsers,
    queryFn: () => getAllStaffUserProfileService()
  })
}

export const useDesktopBusinessService = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.business,
    queryFn: () => getStoreByIdService(getBusinessId())
  })
}

export const useDesktopDiscountService = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.discount,
    queryFn: () => getDiscountService({business_id: getBusinessId(), no_paginate: true})
  })
}

export const useDesktopTaxService = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.tax,
    queryFn: () => getTaxService({business_id: getBusinessId(), no_paginate: true})
  })
}

export const useDesktopSupplierService = () => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.supplier,
    queryFn: () => getSupplierService({business_id: getBusinessId(), no_paginate: true})
  })
}

export const useDesktopProductsService = (query = {}, isManuallyEnabled) => {
  const {
    queryObj, businessId, isQueryEnabled
  } = useLastSyncDateIntervalSyncing(query)
  
  return useQuery({
    retry: 4,
    enabled: isManuallyEnabled || isQueryEnabled,
    queryKey: desktopQueryKeys.products(queryObj),
    queryFn: () => getProductService({
      business_id: businessId,
      sellable: true,
      type: "general_product",
      offset: 0, ...queryObj,
      limit: PAGINATION_COUNT_FOR_OFFLINE_SYNCING,
    })
  })
}

export const useDesktopCustomerService = (query = {}, isManuallyEnabled) => {
  const {
    queryObj, isQueryEnabled
  } = useLastSyncDateIntervalSyncing(query)
  
  return useQuery({
    retry: 3,
    enabled: isManuallyEnabled || isQueryEnabled,
    queryKey: desktopQueryKeys.customer(queryObj),
    queryFn: () => getCustomerService({
      ...queryObj,
      limit: PAGINATION_COUNT_FOR_OFFLINE_SYNCING,
    })
  })
}

export const useDesktopBankService = (search) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.banks,
    queryFn: () => getBankService({business_id: getBusinessId(), no_paginate: true, search})
  })
}

export const useDesktopCategoryService = (search) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.category,
    queryFn: () => getCategoriesService({business_id: getBusinessId(), no_paginate: true, search})
  })
}

export const useDesktopSubCategoryService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.subCategory,
    queryFn: () => getSubCategoriesService({business_id: getBusinessId(), no_paginate: true, ...query})
  })
}

export const useDesktopNestedSubCategoryService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.nestedSubCategory,
    queryFn: () => getNestedSubCategoriesService({business_id: getBusinessId(), no_paginate: true, ...query})
  })
}

export const useDesktopTableService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.tables(query),
    queryFn: () => getTableService(getBusinessId())
  })
}

export const useDesktopAddonService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const {getBusinessId} = useGlobalContext()
  
  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.addon,
    queryFn: () => getAddOnService({business_id: getBusinessId(), no_paginate: true, ...query})
  })
}

export const useDesktopStagesService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  const { numberFormatter, currencyFormatter } = useDataTypeFormatter()

  return useQuery({
    retry: 4,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.stage,
    queryFn: () => getStageService({
      business_id: getBusinessId(),
      no_paginate: true, ...query,
      numberFormatter,
      currencyFormatter
    })
  })
}

export const useDesktopGroupOrdersService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  const { searchValue = "", status = "", fromDateValue = "", toDateValue = "", customerValue = "", invoice = false } = query || {}
  
  return useQuery({
    retry: 3,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.groupOrder,
    queryFn: () => getGroupOrderByBusinessIdService(getBusinessId(), searchValue, status, fromDateValue, toDateValue, customerValue, invoice)
  })
}

export const useDesktopSingleOrdersService = (query = {}) => {
  const isDesktopOfflineModeAllowed = useDesktopOfflineModeAllowed()
  const { getBusinessId } = useGlobalContext()
  
  return useQuery({
    retry: 3,
    enabled: isDesktopOfflineModeAllowed && !isAllDesktopRequestSynced(),
    queryKey: desktopQueryKeys.singleOrders,
    queryFn: () => getSingleGroupByBusinessIdAndGroupId({business_id: getBusinessId(), ...query})
  })
}


export const useDesktopSyncOrdersMutation = ({syncingActive, isMoreOrderAvailableToSync, isManualSyncClickedRef}) => {
  const errorLogger = useErrorLogger()
  const queryClient = useQueryClient()
  const {getBusinessId, toast} = useGlobalContext()
  
  const mutation = useMutation({
    retry: 2,
    
    mutationFn: ({groupOrders}) => {
      toast.info("Order syncing now. Please wait...", "order_syncing", 3000)
      return syncOfflineOrdersOnlineService(groupOrders)
    },
    
    onSuccess: async (_, {groupOrderIds}) => {
      const {ipcRenderer} = window.require("electron")
      ipcRenderer.send(IPC_EVENTS.DELETE_ALREADY_SYNCED_ORDERS, groupOrderIds)
  
      await queryClient.refetchQueries({
        queryKey: [...desktopOfflineQueryKeys.all, "group orders"]
      })
  
      try {
        const {
          searchValue = "",
          status = "",
          fromDateValue = "",
          toDateValue = "",
          customerValue = "",
          invoice = false
        } = {}
    
        const newOfflineGroupOrders = await queryClient.fetchQuery({
          retry: 2,
          queryKey: desktopQueryKeys.groupOrder,
          queryFn: () => getGroupOrderByBusinessIdService(getBusinessId(), searchValue, status, fromDateValue, toDateValue, customerValue, invoice)
        })
    
        const newOfflineSingleOrders = await queryClient.fetchQuery({
          retry: 2,
          queryKey: desktopQueryKeys.singleOrders,
          queryFn: () => getSingleGroupByBusinessIdAndGroupId({business_id: getBusinessId()})
        })
  
        ipcRenderer.send(IPC_EVENTS.UPLOAD_GROUP_ORDERS, {results: newOfflineGroupOrders?.results})
        ipcRenderer.send(IPC_EVENTS.UPLOAD_SINGLE_GROUP_ORDERS, {results: newOfflineSingleOrders?.results})
  
        await queryClient.refetchQueries({
          queryKey: [...desktopOfflineQueryKeys.all, "group orders"]
        })
  
        await queryClient.refetchQueries({
          queryKey: [...desktopOfflineQueryKeys.all, "offline group orders"]
        })
  
        await queryClient.refetchQueries({
          queryKey: orderQueryKey.all
        })
      } catch (e) {
    
      }
    },
  
    onError: (err, variables) => {
      syncingActive.current = false
      isMoreOrderAvailableToSync.current = false
      isManualSyncClickedRef.current = false
    
      const formatError = errorHandler(err)
    
      sendDesktopNotification({
        title: "Sync Failed",
        body: formatError
      })
    
      errorLogger.create({
        error: err, payload: variables,
        errorType: errorTypes.desktopOfflineOrderSync,
      })
    }
  })
  
  return { mutation }
}
import {TableComponentStyled, TableHeadersStyled, TableStyled} from "../../styles/TableStyles";
import { Heading } from "../../styles/textStyles";
import { Line } from "../index";


const Table = ({ headers = [], children, noCols, bg, lastHeaderPosition, noPadding, equalWidth, noCount, lastDataPosition, noHeader }) => {

  return (
    <TableComponentStyled $noPadding={noPadding}>
      <TableStyled noCols={noCols} bg={bg} $equalWidth={equalWidth} $lastDataPosition={lastDataPosition}  >
        <TableHeadersStyled lastHeaderPosition={lastHeaderPosition}>
          <tr>
            {(!noCount && !noHeader) && (
              <th className="counter">
                <Heading color="black" size={1.1}>S/N</Heading>
              </th>
            )}

            {!noHeader && (
              headers.map((item, k) => (
                <th key={k}><Heading color="black" size={1.1}>{item}</Heading></th>
              ))
            )}
          </tr>
          <Line fullWidth />
        </TableHeadersStyled>

        <tbody>
        {children}
        </tbody>
      </TableStyled>
    </TableComponentStyled>
  )
}

export default Table
export const capitalizeFirstLetterInText = (text = "") => {
  const textArr = text.split("")
  const transform1 = `${textArr[0].toUpperCase()}${textArr.slice(1, textArr.length).join("")}`
  
  if (transform1.split("_").length === 1) return transform1
  const textArr2 = transform1.split("_")
  return `${textArr2[0]} ${textArr2[1][0].toUpperCase()}${textArr2[1].split("").slice(1, textArr2[1].length).join("")}`
}

export const formatPhoneNumber = (phone) => {
  if (!phone) return ""
  if (Number(phone[0]) !== 0) return `0${phone}`
  return phone
}
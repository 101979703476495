import {Logo} from "../../assets/images";
import {Image, Line, MenuList} from "../index";
import {DashboardMobileNavDrawerStyled} from "../../styles/navigationStyles";
import {navBottomMenuLists} from "../../data/dashboardData";
import {Logout, Routing, User} from "iconsax-react";
import {useQueryClient} from "react-query";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {clearAllDataInStorage} from "../../utils";
import {isPackageBasic, isPackageExpired, isTimeForPackageRenewal, isUserEmailVerified} from "../../utils/expiryCheck";
import {Heading} from "../../styles/textStyles";
import useNavigationMenuList from "../../hooks/useUtils/useNavigationMenuList";


const DashboardMobileNavDrawer = ({ onClose, navMenu }) => {
  const queryClient = useQueryClient()
  const { mainDashboardNavMenuList } = useNavigationMenuList()
  
  const { navigate, toast, isOffline } = useGlobalContext()
  const { data: userProfile, isSuccess: isUserSuccess } = useUserProfile()

  const handleLogout = () => {
    clearAllDataInStorage()
    queryClient.clear()
    navigate("/login")
  }

  const handleSwitchStore = () => {
    if(userProfile?.[0]?.package_details?.package_name === "basic") {
      toast.error("Please upgrade your package to access this feature", "switch_store")
      return
    }

    queryClient.clear()
    navigate("/select-store")
  }

  const handleRenewPackage = () => {
    if (userProfile?.[0]?.user_type !== "client_admin") {
      toast.error("Please contact the store owner to renew package", "renew-package")
      return
    }
  
    // navigate("/dashboard/settings/subscription")
    toast.error("Please contact ovaloop tech support", "renew-package")
  }
  
  const handleEmailVerification = () => {
    navigate("/dashboard/settings/verify_email")
  }

  return (
    <DashboardMobileNavDrawerStyled onClick={onClose}>
      <div onClick={e => e.stopPropagation()}>
        {/*<CloseSquare size={28} onClick={onClose} />*/}
        <div className="logo_div">
          <Image src={Logo} alt="Ovaloop logo" />
        </div>

        <div className="items">
          {userProfile?.[0]?.user_type !== "client_admin" && (
            <MenuList ignorePermission title="Your Dashboard" Icon={User} link={`/dashboard/staff_dashboard`} />
          )}
  
          {[...((!!navMenu) ? navMenu : mainDashboardNavMenuList)].map((item, k) => (
            <MenuList isNew={item.isNew} key={k} item={item} title={item.title} Icon={item.icon} link={item.link} dropDown={item.dropDown} options={item.options} />
          ))}
          

          <div className="nav_menu_settings">
            {(isUserSuccess && !isUserEmailVerified(userProfile)) && (
              <div className="email_verification_warning" onClick={handleEmailVerification}>
                <Heading>Your email is not verified, <u>verify now</u></Heading>
              </div>
            )}
  
            {(isPackageExpired(userProfile?.[0]?.package_details) && !isPackageBasic(userProfile)) && (
              <div className="package_renewal_warning" onClick={handleRenewPackage}>
                <Heading>Your package subscription has expired, <u>renew now</u></Heading>
              </div>
            )}
  
            {isTimeForPackageRenewal(userProfile?.[0]?.package_details) && (
              <div className="package_renewal_warning" onClick={handleRenewPackage}>
                <Heading>Your package subscription is about to expire, <u>renew now</u></Heading>
              </div>
            )}
  
            {/*{userProfile?.[0]?.package_details?.package_name === "basic" && (*/}
            {/*  <div className="upgrade_plan">*/}
            {/*    <Link to="/#"><Heading>Upgrade your plan</Heading></Link>*/}
            {/*  </div>*/}
            {/*)}*/}
  
            <Line/>
  
            {navBottomMenuLists.map((item, k) => (
              <MenuList key={k} title={item.title} Icon={item.icon} link={item.link} ignorePermission/>
            ))}
  
            {(userProfile?.[0]?.user_type === "client_admin" && !isOffline) && (
              <MenuList title="Switch Store" Icon={Routing} onClick={handleSwitchStore}/>
            )}
  
            {(userProfile?.[0]?.other_business?.length > 0 && userProfile?.[0]?.user_type !== "client_admin" && !isOffline) && (
              <MenuList title="Switch Store" Icon={Routing} onClick={handleSwitchStore} ignorePermission/>
            )}
            <MenuList ignorePermission title="Logout" Icon={Logout} onClick={handleLogout} />

          </div>
        </div>
      </div>
    </DashboardMobileNavDrawerStyled>
  )
}

export default DashboardMobileNavDrawer
import {
  CreateStaffModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton, PageSummaryCard, ScreenLoading,
  SearchAndFilter,
  Table, TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {
  useCreateStaffMutation, useDeleteStaffMutation, useEditStaffMutation, useStaffByBusinessIdQuery,
} from "../../../hooks/useServices/useStaffServices";

import {Fragment, useState} from "react";
import {AddCircle} from "iconsax-react";
import {useModal} from "../../../hooks";
import {NoStaffImage} from "../../../assets/images";
import {PageSummaryStyled} from "../../../styles/utilStyles";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {staffFormDefaultValues} from "../../../data/defaultFormValues";
import EditStaffModal from "../../../components/Modal/StaffModals/EditStaffModal";
import {actionsPermissions} from "../../../data/permissions";
import staffQueryKey from "../../../hooks/useServices/useStaffServices/staffQueryKey";
import {getAllStaffRestructure} from "../../../services/staffServices/staffRestructure";


const Staff = () => {
  const { navigate, toast } = useGlobalContext()
  const [showEditStaffModal, setShowEditStaffModal] = useModal()
  const [showCreateStaffModal, setShowCreateStaffModal] = useModal()
  const [editStaffFormValues, setEditStaffFormValues] = useState(staffFormDefaultValues)


  const { staffTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const deleteStaffMutation = useDeleteStaffMutation({ successFn: () => {}  })
  const editStaffMutation = useEditStaffMutation({ successFn: setShowEditStaffModal })
  const { mainQuery, ...allStaffs } = useSearchQuery(debounceState, useStaffByBusinessIdQuery)
  const createStaffMutation = useCreateStaffMutation({ successFn: setShowCreateStaffModal })

  const handleCreateStaff = (values) => {
    createStaffMutation.mutate(values)
  }
  
  const handleEditStaff = (values) => {
    editStaffMutation.mutate(values)
  }
  
  const handleDeleteAction = (staff) => {
    toast.confirm(
      "Are you sure?",
      () => deleteStaffMutation.mutate(staff.id),
    )
  }
  
  const handleEditAction = (staff) => {
    setEditStaffFormValues(staff)
    setShowEditStaffModal()
  }

  const handleViewStaff = (staff) => {
    navigate(`/dashboard/staff/${staff.id}`)
  }

  return (
    <SetUpMainStyled>
      {showCreateStaffModal && <CreateStaffModal onClose={setShowCreateStaffModal} handleSubmit={handleCreateStaff} mutation={createStaffMutation} />}
      {showEditStaffModal && <EditStaffModal onClose={setShowEditStaffModal} handleSubmit={handleEditStaff} mutation={editStaffMutation} formValues={editStaffFormValues} /> }
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allStaffs.refetch} />
      )}
      
      <Fragment>
        {allStaffs.isEmpty && (
          <Fragment>
            <PageHeader title="Employees" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoStaffImage}
              heading="No staff yet"
              subHeading="Add staff to monitor and view their activities anytime"
              buttonText="Add new staff"
              buttonClick={setShowCreateStaffModal}
              permissionCode={actionsPermissions.createStaff}
            />
          </Fragment>
        )}

        {allStaffs.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateStaffModal} title="Employees" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.createStaff} />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allStaffs.isQueryLoading}
                only
                title="Total Number of Employees"
                value={allStaffs?.data?.count}
              />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search staff by name, phone, email"
              />

              <Table headers={staffTable.headers} noCols={staffTable.headers.length}>
                {allStaffs.isQueryWithData && (
                  allStaffs?.data?.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allStaffs.currentCount}
                      keys={staffTable.values}
                      onClick={handleViewStaff}
                      deleteAction={handleDeleteAction}
                      editAction={handleEditAction}
                      editPermission={actionsPermissions.updateStaff}
                      deletePermission={actionsPermissions.deleteStaff}
                    />
                  )
                )}

                {allStaffs.isQueryWithNoData && (
                  <NoQueryData text={allStaffs.isSearchEmptyMessage("Staff", "name, phone or email")} />
                )}

                {allStaffs.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allStaffs?.showPagination &&
                <TablePagination
                  query={allStaffs}
                  restructureFn={getAllStaffRestructure}
                  queryKey={staffQueryKey.mainList(allStaffs.queryValues)}
                />
              }
            </SetUpLayoutStyled>
  
            {!!allStaffs?.showPagination &&
              <TablePagination
                query={allStaffs}
                restructureFn={getAllStaffRestructure}
                queryKey={staffQueryKey.mainList(allStaffs.queryValues)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Staff;
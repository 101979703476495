import {ChartLayout, DataItemStyled} from "../../styles/chartStyles";
import {Heading, Paragraph} from "../../styles/textStyles";

import {PieChart, Pie, ResponsiveContainer, Tooltip} from "recharts";
import {renderActiveShape, renderInactiveShape} from "../../utils/charts";
import {useState} from "react";
import {FlexCenterStyled, FlexStyled} from "../../styles/utilStyles";


const PieCharts = ({ title, data, formatter }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const changeIndex = (_, index) => setActiveIndex(index)

  const DataItem = ({ color, name }) => {
    return (
      <DataItemStyled $color={color}>
        <span />
        <Paragraph noTop color="black">{name}</Paragraph>
      </DataItemStyled>
    )
  }

  
  return (
    <ChartLayout $isEmpty={data.length < 1}>
      <Heading color="black" size={1.25}>{title}</Heading>
      <FlexStyled>
        {data.length < 1 && (
          <FlexCenterStyled>
            <Heading size={1.2} color="secondary">No Chart Data <br/><br/> (Try generating another analysis based on date )</Heading>
          </FlexCenterStyled>
        )}

        <ResponsiveContainer width="100%" height={(data.length > 0) ? "100%" : "50%"}>
          <PieChart width={200} height={100}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape.bind(this, formatter)}
              inactiveShape={renderInactiveShape}
              data={data}
              cx="50%"
              cy="50%"
              isAnimationActive={false}
              innerRadius={70}
              outerRadius={105}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={changeIndex}
            />
            <Tooltip formatter={formatter}  />
          </PieChart>
        </ResponsiveContainer>

        <FlexStyled className="list">
          {data.map((item, k) => <DataItem key={k} name={item.name} color={item.color} />)}
        </FlexStyled>

      </FlexStyled>
    </ChartLayout>
  )
}

export default PieCharts
import {
  CreateBankModal,
  EditBankModal, NoDataLayout, PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateBankMutation,
  useDeleteBankMutation,
  useBankQuery,
  useEditBankMutation
} from "../../../hooks/useServices/useBankServices";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import {bankFormDefaultValues} from "../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {BankImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../data/permissions";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../desktop/sendDesktopNotification";


const Bank = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  const [showCreateBankModal, setShowCreateBankModal] = useModal()
  const [showEditBankModal, setShowEditBankModal] = useModal()
  const {
    createBank: createBankPermission,
    updateBank: updateBankPermission,
    deleteBank: deleteBankPermission
  } = actionsPermissions

  const { bankTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allBanks } = useSearchQuery(debounceState, useBankQuery, true)
  const [editBankFormValues, setEditBankFormValues] = useState(bankFormDefaultValues)
  const deleteBankMutation = useDeleteBankMutation({ successFn: () => {} })
  const editBankMutation = useEditBankMutation({ successFn: setShowEditBankModal })
  const createBankMutation = useCreateBankMutation({ successFn: setShowCreateBankModal })

  const handleCreateBank = (values) => {
    createBankMutation.mutate(values)
  }

  const handleEditBank = (values) => {
    editBankMutation.mutate(values)
  }

  const handleEditAction = (bank) => {
    setEditBankFormValues(bank)
    setShowEditBankModal()
  }

  const handleDeleteAction = (bank) => {
    toast.confirm(
      "Are you sure?",
      deleteBankMutation.mutate.bind(this, bank.id),
    )
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.banks }).then(() => {
      sendDesktopNotification({
        title: "Sync successful",
        body: "All banks has been synced offline"
      })
    })
  }

  return (
    <SetUpMainStyled>
      {showCreateBankModal && <CreateBankModal onClose={setShowCreateBankModal} handleSubmit={handleCreateBank} mutation={createBankMutation} />}
      {showEditBankModal && <EditBankModal onClose={setShowEditBankModal} handleSubmit={handleEditBank} mutation={editBankMutation} formValues={editBankFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
        <Fragment>

        {allBanks.isEmpty && (
          <Fragment>
            <PageHeader title="Banks" />
            <NoDataLayout
              imgWidth={32}
              imgAlt="No expense"
              imgSrc={BankImage}
              heading="No bank yet"
              subHeading="Add a bank to  monitor your transfer transactions."
              buttonText="Add new bank"
              buttonClick={setShowCreateBankModal}
              permissionCode={createBankPermission}
            />
          </Fragment>
        )}
 
        {allBanks.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Bank List"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateBankModal}
              permissionCode={createBankPermission}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "banks", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search bank by name"
              />

              <Table headers={bankTable.headers} noCols={bankTable.headers.length}>
                {allBanks.isQueryWithData && (
                  allBanks.data.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      noAction={!items.editable}
                      pageCount={allBanks.currentCount}
                      keys={bankTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateBankPermission}
                      deletePermission={deleteBankPermission}
                    />
                  )
                )}

                {allBanks.isQueryWithNoData && (
                  <NoQueryData text={allBanks.isSearchEmptyMessage("Bank / Account", "")} />
                )}

                {allBanks.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allBanks?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allBanks?.data?.next && <TablePagination />}
          </Fragment>
        )}

      </Fragment>
    </SetUpMainStyled>
  )
}

export default Bank
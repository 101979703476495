import {Heading, Paragraph} from "../../../styles/textStyles";
import {SpaceBetweenStyled, FlexStyled} from "../../../styles/utilStyles";
import {
	DrawerStyled,
	TableDrawerOrderStyled,
	TablePageDrawerStyled
} from "../../../styles/drawerStyles";
import {Trash} from "iconsax-react";
import {useTheme} from "styled-components";
import {
	useCancelSingleGroupOrderByGroupIdMutation,
	useGroupOrdersByBusinessIdAndTableTrackIdQuery
} from "../../../hooks/useServices/useOrderServices";
import {Fragment} from "react";
import {Button, Image} from "../../index";
import {EmptyBoxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {globalReducerActions} from "../../../reducers/globalReducer";
import {Link} from "react-router-dom";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
getBalanceOfAllGroupOrder
} from "../../../utils/sell";
import {filterOnlyOrdersToCalculate} from "../../../utils/filters";
import {getOrderStatus} from "../../../utils/table";
import TableCheckout from "./TableCheckout";
import {useModal} from "../../../hooks";
import TableOrderSummary from "./TableOrderSummary";
import {useClearTableMutation} from "../../../hooks/useServices/useTableServices";


const TablePageDrawer = ({ onClose, tableData }) => {
	const [showCheckOut, setShowCheckOut] = useModal()
	const { globalReducer, navigate, toast } = useGlobalContext()
	const { name, status, track_id, id } = tableData

	const clearTableMutation = useClearTableMutation({ successFn: onClose })
	const { isSuccess, data: allOrders } = useGroupOrdersByBusinessIdAndTableTrackIdQuery(track_id)

	const activeOrders = allOrders?.results
	const validOrdersToCalculate = filterOnlyOrdersToCalculate(activeOrders)
	const totalBalanceOfOrders = getBalanceOfAllGroupOrder(allOrders?.results)


	const addOrderToTable = () => {
		globalReducer({
			type: globalReducerActions.SET_TABLE_DATA,
			tableId: id,
			tableTrackId: track_id
		})

		navigate("/dashboard_sell")
	}

	const handleClearTable = () => {
		toast.confirm(
			`Clearing the table will cancel all orders and make the table available`,
			() => clearTableMutation.mutate(id),
		)
	}

	return (
		<DrawerStyled onClick={onClose}>
			<TablePageDrawerStyled onClick={e => e.stopPropagation()}>

				{!showCheckOut && (
					<Fragment>
						<SpaceBetweenStyled className="header">
							<Heading color="black" size={.95} className={status}>
								{status === "occupied" ? "Occupied" : "Available"}
							</Heading>
							{activeOrders?.length > 0 && (
								<Heading color="black" size={.95} className="clear" onClick={handleClearTable}>Clear Table</Heading>
							)}
						</SpaceBetweenStyled>

						<SpaceBetweenStyled>
							<Heading color="black" size={1.3}>{name}</Heading>
							<u onClick={addOrderToTable}>
								<Paragraph color="secondary">Add order to table</Paragraph>
							</u>
						</SpaceBetweenStyled>

						{isSuccess && (
							<Fragment>
								{activeOrders.length > 0 && (
									<div>
										{activeOrders.map(item => (
											<TableDrawerOrder key={item.id} orderDetails={item} tableTrackId={track_id} />
										))}

										{validOrdersToCalculate.length > 0 && (
											<TableOrderSummary
												buttonText="Place Order"
												onClick={setShowCheckOut}
												cart={validOrdersToCalculate}
											/>
										)}
									</div>
								)}

								{/* If there are no orders */}
								{activeOrders.length === 0 && (
									<FlexStyled className="no_order">
										<FlexStyled>
											<Image src={EmptyBoxImage} alt="No order" width={40} />
											<Paragraph color="black" bold>No Order for this table</Paragraph>
											<Button text="Add Order" onClick={addOrderToTable} />
										</FlexStyled>
									</FlexStyled>
								)}
							</Fragment>
						)}
					</Fragment>
				)}

				{showCheckOut && (
					<TableCheckout
						tableId={id}
						checkOut={showCheckOut}
						trackId={track_id}
						closeDrawer={onClose}
						onClose={setShowCheckOut}
						table_amount={totalBalanceOfOrders}
						cart={validOrdersToCalculate}
					/>
				)}

			</TablePageDrawerStyled>
		</DrawerStyled>
	)
}

export default TablePageDrawer



const TableDrawerOrder = ({ orderDetails, tableTrackId  }) => {
	const theme = useTheme()
	const { toast } = useGlobalContext()
	const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
	const cancelGroupOrderMutation = useCancelSingleGroupOrderByGroupIdMutation({ successFn: () => {}, tableTrackId })

	const availableToCancel = orderDetails.status === "pending" || orderDetails.status === "pay_later"

	const handleDeleteGroupOrder = () => {
		if(availableToCancel) {
			toast.confirm(
				"Are you sure you want to cancel this order?",
				cancelGroupOrderMutation.mutate.bind(this, orderDetails.id),
			)
		}
	}

	return (
		<TableDrawerOrderStyled>
			<SpaceBetweenStyled>
				<Link to={`/dashboard/orders/${orderDetails.group_id}`}>
					<Heading color="black">{orderDetails.group_id}</Heading>
				</Link>
				<Trash color={!availableToCancel ? theme.disabled : theme.error} size={20} onClick={handleDeleteGroupOrder} />
			</SpaceBetweenStyled>
			<Paragraph color="black">No. of Items: {numberFormatter(orderDetails.total_items)}</Paragraph>

			<Heading color="black">
				Status: {getOrderStatus(orderDetails.status)}
			</Heading>
			<Paragraph color="black">Total Amount: {currencyFormatter(orderDetails.total_amount)}</Paragraph>

		</TableDrawerOrderStyled>
	)
}
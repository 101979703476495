import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const getReferralService = async (query = {}) => {
  try {
    const url = handleQueryParams("/associate_user", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const approveReferralService = async (body = {}) => {
  try {
    const { data } = await axios.post("/approve_associate/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getRequestDemoService = async (query = {}) => {
  try {
    const url = handleQueryParams("/demo_request", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, Form, Input, Select} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {stagesFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {commissionAndDiscountTypeOptionsForStages} from "../../../../data/selectField/product";
import {useModal} from "../../../../hooks";
import {Fragment} from "react";
import {useParams} from "react-router-dom";


const CreateStagesModal = ({ onClose, handleSubmit, mutation }) => {
  const { serviceName } = useParams()
  const [isShowCommission, setIsShowCommission] = useModal()

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Stage</Heading>
        <Form values={stagesFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Add On" placeholder={serviceName} name="service" disabled  />
          
          <Input label="Name" placeholder="Stage name" name="name"  />

          <CheckBox optional name="show_commission" afterCheck={setIsShowCommission} labelProp="Add commission to stage" />

          {isShowCommission && (
            <Fragment>
              <Select
                options={commissionAndDiscountTypeOptionsForStages}
                displayKey="name"
                valueKey="name"
                label="Commission Type"
                placeholder="Enter commission type"
                name="commission_type"
                displayName="commission_type_name"
              />

              <Input
                type="number"
                formatNumber
                label="Commission Value"
                placeholder="Enter value"
                name="commission_value"
              />
            </Fragment>
          )}

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateStagesModal
import {Fragment} from "react";
import {FlexStyled} from "../../../styles/utilStyles";
import {NoDataLayout, PageHeaderWithButtonAndDateFilter, ScreenLoading, WipCard} from "../../../components";
import {TablePageLayoutStyled} from "../../../styles/tablePageStyles";
import {useWipAnalyticsQuery, wipQueryKeys} from "../../../hooks/useServices/useWipServices";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {NoLogsImage} from "../../../assets/images";
import {getWipAnalyticsService} from "../../../services/wipServices";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";

const Wip = () => {
	const { navigate } = useGlobalContext()
	const { data: allWip, isSuccess, status, refetch, isLoading } = useWipAnalyticsQuery()

	const handleWipByStaff = (staffId) => {
		navigate(`/dashboard/wip/${staffId}`)
	}
	
	const wipAnalyticsQueryKeysAndFunctionsForDateFilter = () => {
		return [
			{ key: wipQueryKeys.all, fn: getWipAnalyticsService },
		]
	}

	return (
		<div>
			<PageHeaderWithButtonAndDateFilter globalStateKey="wip" title="WIP Analytics"
			queryKeysAndFunctions={wipAnalyticsQueryKeysAndFunctionsForDateFilter()}/>
			
			{status === "error" && (
				<ScreenLoading refetchFn={refetch} />
			)}
			
				<Fragment>
					<TablePageLayoutStyled>
						{isSuccess && (
							<Fragment>
								<FlexStyled>
									{allWip?.map(item => (
										<WipCard wip={item} onClick={handleWipByStaff} key={item?.id} />
									))}
								</FlexStyled>
								
								{allWip?.length < 1 && (
									<Fragment>
										<NoDataLayout
											imgWidth={35}
											imgAlt="No wip"
											imgSrc={NoLogsImage}
											heading="No wip yet"
											subHeading="Monitor the process and teams  attached to each  production, to meet up with the deadline and avoid downtime always"
										/>
									</Fragment>
								)}
							</Fragment>
						)}
						
						{isLoading && (
							<Fragment>
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
								<TableSkeleton count={1} height={100} width="240px" noTop />
							</Fragment>
						)}
					</TablePageLayoutStyled>
				</Fragment>
		</div>
	)
}

export default Wip
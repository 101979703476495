import {useModal} from "../../hooks";
import {GetStartedImage} from "../../assets/images";
import {AllCenterStyled} from "../../styles/utilStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {Heading2, Paragraph} from "../../styles/textStyles";
import {GetStartedStyled} from "../../styles/DashboardStyles";
import {Button, CreateStoreModal, Image} from "../../components";
import { useCreateStoreMutation } from "../../hooks/useServices/useStoreServices";
import {useUserProfile} from "../../hooks/useServices/userHooks";
import {useEffect} from "react";
import {clearAllDataInStorage} from "../../utils";


const SetUpStore = () => {
  const { navigate } = useGlobalContext()
  const [showPopUp, setShowPopUp] = useModal()
  const { error, isLoading } = useUserProfile()

  useEffect(() => {
    if(!isLoading && !!error) {
      clearAllDataInStorage()
      navigate("/login")
    }
  }, [error])

  const onCreateStore = () => {
    setShowPopUp()
    navigate("/dashboard/inventory/product")
  }

  const createStoreMutation = useCreateStoreMutation({ successFn: onCreateStore })

  const handleCreateStore = (values) => {
    createStoreMutation.mutate(values)
  }

  return  (
    <GetStartedStyled style={{ height: "100vh" }}>
      {showPopUp && <CreateStoreModal onClose={setShowPopUp} handleSubmit={handleCreateStore} mutation={createStoreMutation} />}

      <AllCenterStyled>
        <Image src={GetStartedImage} width={80} alt="On boarding"/>
        <Heading2 color="black">Hi, let's get you set up</Heading2>
        <Paragraph color="textLightBlue" noTop>
          Hello partner, click here to create store now!
        </Paragraph>
        <Button text="Set Up Store" onClick={setShowPopUp}/>
      </AllCenterStyled>
    </GetStartedStyled>
  )
}

export default SetUpStore
import {PageSummaryCardStyled} from "../../styles/cardStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Fragment} from "react";
import {PageSummaryCardSkeleton} from "../index";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";

const PageSummaryCard = ({ title, value, percentage, only, subText, subText2, subText3, subText4, isLoading }) => {
  const { isOffline } = useGlobalContext()
  
  return (
    <Fragment>
      {isOffline && (
        <PageSummaryCardStyled $only={only}>
          <FlexStyled>
            <Paragraph color="secondary">{title}</Paragraph>
      
              <SpaceBetweenStyled className="space">
                <Heading color="black" size={1.7}>{value}</Heading>
              </SpaceBetweenStyled>
          </FlexStyled>
        </PageSummaryCardStyled>
      )}
      
      {(isLoading && !isOffline) && (
        <PageSummaryCardSkeleton />
      )}
  
      {(!isLoading && !isOffline) && (
        <PageSummaryCardStyled $only={only}>
          <FlexStyled>
            <Paragraph color="secondary">{title}</Paragraph>
      
            {((value || subText || value === 0) && !subText2) && (
              <SpaceBetweenStyled className="space">
                <Heading color="black" size={1.7}>{value}</Heading>
                {percentage && <Paragraph color="secondary" bold size={.85}>{percentage}%</Paragraph>}
                {subText && <Paragraph color="secondary" bold size={.85}>{subText}</Paragraph>}
              </SpaceBetweenStyled>
            )}
      
            {subText2 && (
              <FlexStyled style={{ width: "100%" }}>
                <Heading color="black" size={1.7}>{value}</Heading>
                {percentage && <Paragraph color="secondary" bold size={.85}>{percentage}%</Paragraph>}
          
                <FlexStyled className="space_out" $xGap={.01} $gap={.01}>
                  {subText && <Paragraph color="secondary" bold size={.85}>{subText}</Paragraph>}
                  {subText2 && <Paragraph color="secondary" bold size={.85}>{subText2}</Paragraph>}
                  {subText3 && <Paragraph color="secondary" bold size={.85}>{subText3}</Paragraph>}
                  {subText4 && <Paragraph color="secondary" bold size={.85}>{subText4}</Paragraph>}
                </FlexStyled>
              </FlexStyled>
            )}
          </FlexStyled>
        </PageSummaryCardStyled>
      )}
    </Fragment>
  )
}

export default PageSummaryCard
import {Fragment} from "react";
import {
	useConfirmCustomerSubscriptionPurchaseMutation,
	useCustomerSubscriptionPurchaseByIdQuery,
	useCancelCustomerSubscriptionPurchaseMutation
} from "../../../hooks/useServices/useCustomerSubscriptionServices";
import {SingleOrderHeaderStyled, SingleOrderLayoutStyled} from "../../../styles/singleOrderStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {ArrowLeft} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Chips, OrderDetailsCard, ScreenLoading} from "../../../components";
import {actionsPermissions} from "../../../data/permissions";
import {useTheme} from "styled-components";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useModal from "../../../hooks/useModal";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {getOrderStatus} from "../../../utils/table";
import CustomerSubscriptionReceipt from "../../../components/Modal/ReceiptModal/CustomerSubscriptionReceipt";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const ViewSubscriptionPurchase = () => {
	const theme = useTheme()
	const { navigate, toast } = useGlobalContext()
	const [showReceipt, setShowReceipt] = useModal()
	const { currencyFormatter, dateOnlyFormatter, numberFormatter } = useDataTypeFormatter()

	const [, setShowOvaloopTransferModal] = useModal()


	const { data: subscriptionPurchase, isSuccess: isPurchaseSuccess, status, refetch, isLoading } = useCustomerSubscriptionPurchaseByIdQuery()

	const cancelCustomerSubscriptionPurchaseMutation = useCancelCustomerSubscriptionPurchaseMutation({ successFn: () => {} })
	const confirmCustomerSubscriptionPurchaseMutation = useConfirmCustomerSubscriptionPurchaseMutation()


	const isPayModeTransferAndNotConfirmed = () => {
		return (!subscriptionPurchase?.paid && subscriptionPurchase?.pay_mode === "transfer")
	}

	const isOvaloopTransferAndNotConfirmed = () => {
		return (!subscriptionPurchase?.paid && subscriptionPurchase?.pay_mode === "ovaloop_transfer")
	}

	const isOrderStatusPending = () => {
		return subscriptionPurchase?.payment_status === "pending"
	}

	const handleCancelOrder = () => {
		toast.confirm(
			"Are you sure you want to cancel this order?",
			() => cancelCustomerSubscriptionPurchaseMutation.mutate(subscriptionPurchase.id)
		)
	}

	const handleConfirmedSubscription = () => {
		toast.confirm(
			"Are you sure you want to confirm this order?",
			() => confirmCustomerSubscriptionPurchaseMutation.mutate(subscriptionPurchase.id)
		)
	}


	const getPurchaseDetails = () => {
		return [
			{ key: "Customer Name", value: `${subscriptionPurchase?.customer.first_name} ${subscriptionPurchase?.customer?.last_name}` },
			{ key: "Purchase Time", value: dateOnlyFormatter(subscriptionPurchase?.created_at) },
			{ key: "Expiry Date", value: dateOnlyFormatter(subscriptionPurchase?.expiry_date) },
			{ key: "Payment Method", value: (subscriptionPurchase?.pay_mode)?.toUpperCase() }
		]
	}

	const getSubscriptionDetails = () => {
		return [
			{ key: "Name", value: subscriptionPurchase?.subscription_service_details?.subscription_service_name },
			{ key: "Amount", value: currencyFormatter(subscriptionPurchase?.subscription_service_details?.subscription_service_amount || 0) },
			{ key: "Usage Count", value: `${numberFormatter(subscriptionPurchase?.usage_count || 0)}` },
			{ key: "Status", value: (subscriptionPurchase?.status)?.toUpperCase() }
		]
	}

	const getBankDetails = () => {
		return [
			{ key: "Bank Name", value: subscriptionPurchase?.banks?.bank_name },
			{ key: "Acc Name", value: subscriptionPurchase?.banks?.account_name },
			{ key: "Acc Number", value: subscriptionPurchase?.banks.account_number }
		]
	}

	const getStaffDetails = () => {
		return [
			{ key: "Name", value: subscriptionPurchase?.created_by?.name },
			{ key: "Designation", value: subscriptionPurchase?.created_by?.role || "Admin" },
		]
	}


	return (
		<Fragment>
			{showReceipt && <CustomerSubscriptionReceipt subscription={subscriptionPurchase} onClose={setShowReceipt} />}

			{status === "error" && (
				<ScreenLoading refetchFn={refetch} />
			)}

			<Fragment>
				<SingleOrderHeaderStyled>
					<FlexStyled className="navigation_section">
						<ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
						<FlexStyled>
							<Paragraph noTop color="menuGray">Back to subscriptions</Paragraph>

							<FlexStyled className="id_and_status">
								<Heading color="black">Subscription Purchase</Heading>
								{getOrderStatus(subscriptionPurchase?.payment_status)}

							</FlexStyled>
						</FlexStyled>
					</FlexStyled>

					<FlexStyled className="chips">
						<Chips title={isOrderStatusPending() ? "Print Invoice" : "Print Receipt"} active onClick={setShowReceipt} />

						{isPayModeTransferAndNotConfirmed() && (
							<Chips title="Confirm Order" active onClick={handleConfirmedSubscription} permissionCode={actionsPermissions.confirmPaymentForOrder} />
						)}

						{isOvaloopTransferAndNotConfirmed() && (
							<Chips title="Confirm ovaloop transfer" active onClick={setShowOvaloopTransferModal} />
						)}

						<Chips title="Cancel Order" permissionCode={actionsPermissions.cancelOrder} active onClick={handleCancelOrder} />
					</FlexStyled>
				</SingleOrderHeaderStyled>
				
				
				{isPurchaseSuccess && (
					<SingleOrderLayoutStyled>
						<FlexStyled className="subscription_card_details">
							<OrderDetailsCard title="Purchase" data={getPurchaseDetails()} />
							<OrderDetailsCard title="Subscription" data={getSubscriptionDetails()} />
							{!!subscriptionPurchase?.banks && (
								<OrderDetailsCard title="Bank" data={getBankDetails()} />
							)}
							<OrderDetailsCard title="Staff" data={getStaffDetails()} />
						</FlexStyled>
					</SingleOrderLayoutStyled>
				)}
				
				{isLoading && (
					<SingleOrderLayoutStyled>
						<FlexStyled className="subscription_card_details">
							<TableSkeleton count={1} height={160} width="240px" noTop />
							<TableSkeleton count={1} height={160} width="240px" noTop />
							<TableSkeleton count={1} height={100} width="240px" noTop />
						</FlexStyled>
					</SingleOrderLayoutStyled>
				)}
			</Fragment>
		</Fragment>
	)
}


export default ViewSubscriptionPurchase
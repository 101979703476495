import axios from "../../axios";
import {getAllStaffRestructure} from "./staffRestructure";


const getStaffByBusinessIdService = async (businessId, searchValue = " ") => {
  try {
    const { data } = await axios.get(`/staff/?business_id=${businessId}&search=${searchValue}`)
    return getAllStaffRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getAllStaffService = async (searchValue = " ") => {
  try {
    const { data } = await axios.get(`/staff/?search=${searchValue}`)
    return getAllStaffRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getSingleStaffByIdService = async (staffId) => {
  try {
    const { data } = await axios.get(`/staff/${staffId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getStaffDashboardService = async () => {
  try {
    const { data } = await axios.get(`/staff`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createStaffService = async (body) => {
  try {
    const { data } = await axios.post("/staff/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editStaffService = async (staffId, body) => {
  try {
    const { data } = await axios.patch(`/staff/${staffId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteStaffService = async (staffId) => {
  try {
    const { data } = await axios.delete(`/staff/${staffId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const resetStaffPassword = async (body) => {
  try {
    const { data } = await axios.post(`/staff_password_update/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}



export {
  getSingleStaffByIdService, getStaffByBusinessIdService, getStaffDashboardService,
  createStaffService, editStaffService, deleteStaffService
}
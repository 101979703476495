import {Fragment, useState} from "react";
import {
  PageHeader,
  SearchAndFilter,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {useAllRequestDemoQuery, useReferralApprovalMutation} from "../../../hooks/useServices/useReferralServices";
import {useModal} from "../../../hooks";
import ReferralModal from "../../../components/Modal/StaffDasboardModals/ReferralModal";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const RequestDemo = () => {
  const { demoRequestListForBackendTable } = tableHeadersAndValueKeys
  
  const [showModal, setShowModal] = useModal()
  const [referralAccount, setReferralAccount] = useState({})
  const { debounceState, debounceReducer } = useQueryDebounce()
  
  const query = { search: debounceState.searchValue }
  const { mainQuery, ...allDemoRequest } = useOrderQueryWithSearchAndFilter(query, useAllRequestDemoQuery, true)
  const approveReferralMutation = useReferralApprovalMutation({ successFn: setShowModal })
  
  const handleViewReferral = (referral) => {
    setReferralAccount(referral)
  }
  
  const handleUpdateReferral = (user) => {
    approveReferralMutation.mutate({
      ...user, approved_status: true, associate_user: user.id,
      referral_code: user.referral_code?.split(" ").join("_")
    })
  }
  
  return (
    <Fragment>
      <PageHeader title="All Demo Request" />
      {showModal && <ReferralModal formValues={referralAccount} handleSubmit={handleUpdateReferral} onClose={setShowModal} mutation={approveReferralMutation} />}
      
        <Fragment>
          {allDemoRequest.showTable && (
            <Fragment>
              <SetUpLayoutStyled>
                <SearchAndFilter
                  noFilter
                  dispatch={debounceReducer}
                  searchPlaceholder="Search request by name"
                />
                
                <Table headers={demoRequestListForBackendTable.headers} noCols={demoRequestListForBackendTable.headers.length}>
                  {allDemoRequest.isQueryWithData && (
                    allDemoRequest?.data.map((log, k) => (
                      <TableItemWithStatus
                        count={k}
                        onView={handleViewReferral}
                        pageCount={allDemoRequest.currentCount}
                        key={k}
                        data={log}
                        statusPosition={30}
                        keys={demoRequestListForBackendTable.values}
                      />
                    ))
                  )}
      
                  {allDemoRequest.isQueryWithNoData && (
                    <NoQueryData text="No staffs" />
                  )}
      
                  {allDemoRequest.isQueryLoading && (
                    <TableSkeleton />
                  )}
  
                  {!!allDemoRequest?.data?.next && (
                    <TablePagination queryKey={["Demo Request", query]} query={allDemoRequest} />
                  )}
                </Table>
              </SetUpLayoutStyled>
  
              {!!allDemoRequest?.data?.next && (
                <TablePagination queryKey={["Demo Request", query]} query={allDemoRequest} />
              )}
            </Fragment>
          )}
          
        </Fragment>
    </Fragment>
  )
}


export default RequestDemo
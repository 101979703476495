import {ProductCard2Styled} from "../../styles/cardStyles";
import {FlexStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {Button, Image} from "../index";
import {NoProductImage} from "../../assets/images";
import {Heading} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import useSellContext from "../../hooks/useContexts/useSellContext";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {sellReducerActions} from "../../reducers/sellReducer";
import {disableSellBelowStockWhenOfflineForAccount} from "../../utils/manageDisplayOfFeatures";
import {useUserProfile} from "../../hooks/useServices/userHooks";

const ProductCard2 = ({product, setAddToCartModal}) => {
	const {data: userProfile} = useUserProfile()
	const {reducer, selectedCustomer} = useSellContext()
	const businessSettings = useBusinessSettingQuery()
	const {currencyFormatter} = useDataTypeFormatter()
	const {toast, isOffline} = useGlobalContext()
	const {image_path, name, selling_price, stock_unit} = product
	
	const disableSellBelowStockWhenOffline = (isOffline && disableSellBelowStockWhenOfflineForAccount(userProfile?.[0]?.account))
	
	const handleAddToCartWithModal = (product) => {
		if ((!isOffline || disableSellBelowStockWhenOffline) && (!businessSettings.sell_below_stock && stock_unit < 1 && product.use_stock)) {
			toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
			return
		}
		
		reducer({
			product: {
				...product,
				...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
					applied_discount: selectedCustomer?.customer_discount,
					previous_applied_discount: product?.applied_discount || null
				})
			},
			type: sellReducerActions.SET_PRODUCT_FOR_ADD_TO_CART_MODAL
		})

		setAddToCartModal()
		// isMobile ?
		//   navigate("/dashboard_sell/cart") :
		//   setAddToCartModal()
	}
	
	const handleAddToCartWithoutModal = (product) => {
		if ((!isOffline || disableSellBelowStockWhenOffline) && (!businessSettings.sell_below_stock && stock_unit < 1 && product.use_stock)) {
			toast.error("You are not allowed to sell below stock, please contact business owner", "out_of_stock")
			return
		}
		
		if (!!product?.meta_measurement && product?.meta_measurement?.length > 0) {
			handleAddToCartWithModal(product)
			return
		}
		
		const otherData = function () {
			if (!Number(product?.selling_price) && !!product?.meta_measurement && product?.meta_measurement?.length > 0) {
				const selling_price = Number(product?.meta_measurement?.[0]?.selling_price)
				const selected_unit_measurement = {...product?.meta_measurement?.[0], quantity: 1}
				
				return {selling_price, selected_unit_measurement}
			}
			
			return {selling_price: Number(product?.selling_price)}
		}()
		
		reducer({
			product: {
				...product,
				...otherData,
				unit: Number(product.unit_increment) || 1,
				isWithoutModal: true,
				...((selectedCustomer?.customer_discount && selectedCustomer?.customer_discount?.value) && {
					applied_discount: selectedCustomer?.customer_discount,
					previous_applied_discount: product?.applied_discount || null
				})
			},
			type: sellReducerActions.ADD_PRODUCT_TO_CART
		})
	}

	return (
		<ProductCard2Styled onClick={handleAddToCartWithModal.bind(this, product)}>
			<FlexStyled>
				<div className="img_div">
					<Image src={image_path || NoProductImage} alt={name} />
				</div>

				<section>
					<Heading color="black" size={1.05}>{name}</Heading>
					
					<SpaceBetweenStyled>
						<Heading thin color="black" size={1}>{currencyFormatter(selling_price)}</Heading>
						{(!!product.use_stock && (!isOffline || disableSellBelowStockWhenOffline)) && (
							<Heading thin color={stock_unit > 0 ? "success" : "error"} size={1}>
								{stock_unit} in stock
							</Heading>
						)}
						
						{(!product.use_stock || (isOffline && !disableSellBelowStockWhenOffline)) &&
							<Heading thin size={1} color="black">N/A</Heading>}
					</SpaceBetweenStyled>

					<SpaceBetweenStyled>
						<p></p>
						<Button
							className="add_to_cart"
							text="Add To Cart"
							onClick={() => handleAddToCartWithoutModal(product)}
						/>
					</SpaceBetweenStyled>
				</section>
			</FlexStyled>
		</ProductCard2Styled>
	)
}

export default ProductCard2
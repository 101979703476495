import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";

const keyName = "inventory"

const inventoryQueryKey = {
	all: [keyName],
	bad_and_damage: (query) => [keyName, "bad_and_damage", getAllObjectKeysWithValues(query)],
	expiry: (query) => ["expiry", keyName, getAllObjectKeysWithValues(query)],
	mainList: (query = {}) => [keyName, getAllObjectKeysWithValues(query)]
}


export default inventoryQueryKey
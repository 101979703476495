import {useMutation, useQuery, useQueryClient} from "react-query";
import {getKYCDocumentsService, updateKycDocumentService} from "../../../../services/staffBackendServices/KycDocumentsServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {getAllObjectKeysWithValues} from "../../../../utils/handleQueryParams";
import errorHandler from "../../../../utils/errorHandler";

export const kycQueryKeys = {
	all: ["kyc_documents"],
	logs: (queryParams) => ["kyc_documents", "logs", getAllObjectKeysWithValues(queryParams)]
}


export const useKycDocumentQuery = (queryParams = {}) => {
	return useQuery({
		queryKey: kycQueryKeys.logs(queryParams),
		queryFn: () => getKYCDocumentsService(queryParams)
	})
}


export const useKycDocumentMutation = ({ successFn }) => {
	const { toast } = useGlobalContext()
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (body) => updateKycDocumentService(body),

		onSuccess: (res, variables) => {
			successFn()
			variables.is_verified ? toast.success("KYC Verified") : toast.success("KYC Rejected")
			
			queryClient.refetchQueries({ queryKey: kycQueryKeys.all })
		},

		onError: (err) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}
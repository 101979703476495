import {useTheme} from "styled-components";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useAccountQuery} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";
import {Fragment} from "react";
import {Link} from "react-router-dom";
import {OverviewCard, ScreenLoading, TableItemWithEyeAction, Table} from "../../../components";
import {DashboardStyled} from "../../../styles/DashboardStyles";
import {DollarCircleIcon} from "../../../assets/icons";
import {SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {topNData} from "../../../utils";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";


const Home = () => {
  const theme = useTheme()
  const { navigate } = useGlobalContext()
  const { accountsTable } = tableHeadersAndValueKeys
  const { data: allAccounts, isSuccess: isAccountSuccess, refetch, status, isLoading } = useAccountQuery()
  
  const handleViewAccount = (accountId) => {
    navigate(`/staff_backend/accounts/${accountId}`)
  }
  
  return (
    <Fragment>
      
      {status === "error" && (
        <ScreenLoading refetchFn={refetch} />
      )}
      
        <DashboardStyled>
          <SpaceBetweenStyled>
            <OverviewCard
              isLoading={isLoading}
              icon={DollarCircleIcon}
              title="Total Accounts"
              bgColor={theme.black}
              value={allAccounts?.count}
            />
          </SpaceBetweenStyled>
          
          <SectionStyled>
            <SpaceBetweenStyled>
              <Heading size={1.25} color="black">New Accounts</Heading>
              <Link to="/staff_backend/accounts">
                <Paragraph color="secondary">View all accounts</Paragraph>
              </Link>
            </SpaceBetweenStyled>
            
              <Table bg headers={accountsTable.headers} noCols={accountsTable.headers.length}>
                {isAccountSuccess && (
                  topNData(allAccounts?.results, 15).map((items, k) =>
                    <TableItemWithEyeAction
                      count={k}
                      pageCount={1}
                      statusPosition={6}
                      key={k}
                      data={items}
                      onClick={handleViewAccount}
                      keys={accountsTable.values}
                    />
                  )
                )}
                
                {allAccounts?.results.length < 1 && (
                  <NoQueryData text="No recent orders" />
                )}
  
                {isLoading && (
                  <TableSkeleton />
                )}
              </Table>
          </SectionStyled>
          
          
          
          {/*<SectionStyled>*/}
          {/*	<SpaceBetweenStyled>*/}
          {/*		<Heading size={1.25} color="black">New Businesses</Heading>*/}
          {/*		<Link to="/dashboard/orders">*/}
          {/*			<Paragraph color="secondary">View all business</Paragraph>*/}
          {/*		</Link>*/}
          {/*	</SpaceBetweenStyled>*/}
          
          {/*{isOrderSuccess && (*/}
          {/*	<Table bg headers={accountsTable.headers} noCols={accountsTable.headers.length}>*/}
          {/*		{topNData(allAccounts?.results, 5).map((items, k) =>*/}
          {/*			<TableItemForGroupOrders*/}
          {/*				count={k}*/}
          {/*				pageCount={1}*/}
          {/*				statusPosition={6}*/}
          {/*				key={k}*/}
          {/*				data={items}*/}
          {/*				keys={accountsTable.values}*/}
          {/*			/>*/}
          {/*		)}*/}
          {/*		*/}
          {/*		{allAccounts?.results.length < 1 && (*/}
          {/*			<NoQueryData text="No recent orders" />*/}
          {/*		)}*/}
          {/*	</Table>*/}
          {/*)}*/}
          {/*</SectionStyled>*/}
        
        </DashboardStyled>
    </Fragment>
  )
}

export default Home
import {Field, useFormikContext} from "formik";
import {InputStyled} from "../../styles/formStyles";
import {CloseCircle} from "iconsax-react";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {useEffect, useRef} from "react";


const SearchField = (
	{className, name, placeholder, bgDark, rightIcon, setSearchValueState, label, allowScannerInput}
) => {
	
	const searchInputRef = useRef()
	const {values, setFieldValue} = useFormikContext()
	
	const handleSearch = (value) => {
		setSearchValueState(value)
		setFieldValue(name, value)
	}
	
	useEffect(() => {
		if (allowScannerInput) {
			searchInputRef?.current?.focus()
		}
	}, [searchInputRef.current])
	
	return (
		<InputStyled className={className} bgDark={bgDark} rightIcon={rightIcon}>
			<SpaceBetweenStyled>
				<label htmlFor={name}>
					{label}
				</label>
			</SpaceBetweenStyled>
			{!!rightIcon && <i className="right_icon">{rightIcon}</i>}
			{!!values[name].length &&
				<i><CloseCircle size={18} color="gray" onClick={handleSearch.bind(this, "")} /></i>
			}

			<Field
				id={name}
				type="text"
				name={name}
				innerRef={searchInputRef}
				onChange={e => handleSearch(e.target.value)}
				placeholder={placeholder}
			/>
		</InputStyled>
	)
}

export default SearchField
import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {customPaymentMethodFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const CreateCustomPaymentMethodModal = ({ onClose, handleSubmit, mutation }) => {

	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Create Payment Method</Heading>
				<Form values={customPaymentMethodFormDefaultValues} onSubmit={handleSubmit}>
					<Input label="Name" placeholder="Enter payment method name" name="payment_method"  />

					<FlexStyled>
						<Button text="Save" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default CreateCustomPaymentMethodModal
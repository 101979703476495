import {useContext, useState} from "react";
import {useFormikContext} from "formik";
import {Heading} from "../../styles/textStyles";
import {FlexStyled} from "../../styles/utilStyles";
import {FileInputStyled} from "../../styles/formStyles";
import {Image} from "../index";
import {FormProvider} from "./Form";

const getFileNameForDisplay = (fileName = "") => {
	const [name, type] = fileName.split(".")
	if(name.length > 13) {
		return `${name.slice(0, 12)}.${type}`
	}

	return fileName
}


const FileInput = ({ name, className, placeholder, accept, optional, isNotImage, onChange }) => {
	const { allFieldsNotRequired } = useContext(FormProvider)

	const { setFieldValue, values } = useFormikContext()
	const [{ fileName, fileBase64 }, setFileName] = useState({
		fileName: "",
		fileBase64: ""
	})

	const isRequired = () => {
		return !(allFieldsNotRequired ?? optional);
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0]
		onChange?.(file)
		
		const reader = new FileReader()
		reader.readAsDataURL(file)

		reader.onload = function () {
			const result = reader.result
			setFieldValue(name, result.split(",")[1])

			setFileName({
				fileName: getFileNameForDisplay(file.name),
				fileBase64: result
			})
		}
	}

	return (
		<FileInputStyled className={className}>
			<label htmlFor={name}>
				<FlexStyled>
					<div className="browse_box">
						<Heading className="browse" color="gray">{placeholder || "Browse"}</Heading>
					</div>
					<Heading className="secondary" color={!!fileName ? "black" : "gray" }>{fileName || "No File Selected"}</Heading>
				</FlexStyled>
			</label>
			
			{!isNotImage && <Image src={fileBase64 || values.image_path} alt={fileName} /> }
			<input
				onChange={handleFileChange}
				id={name}
				name={name}
				type="file"
				required={isRequired()}
				accept={accept || "image/jpeg image/png"}
			/>
		</FileInputStyled>
	)
}

export default FileInput
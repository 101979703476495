import {ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {StoreFrontAuthModalStyled} from "../../../../styles/StoreFrontStyles/storeFrontComponentStyles";
import {Button, Form, Image, Input, PasswordInput} from "../../../../components";
import {Logo} from "../../../../assets/images";
import {useBusinessByIdQuery} from "../../../../hooks/useServices/useStoreServices";
import {SpaceBetweenStyled} from "../../../../styles/utilStyles";


const StoreFrontLoginModal = ({ onClose, handleSubmit, mutation, handleSwitchOtherAuthPage }) => {
  const { data: store } = useBusinessByIdQuery()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <StoreFrontAuthModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <div className="img_div">
          <Image src={store?.image_path || Logo} alt="Ovaloop logo" />
        </div>
        
        <div className="intro">
          <Heading size={1.1} color="black" bold>Login in to your account</Heading>
          <Paragraph color="black">Welcome back! Please enter your details.</Paragraph>
        </div>
        
        <Form values={{ email: "", password: "" }} onSubmit={handleSubmit}>
          <Input type="email" placeholder="Enter your email address" label="Email Address" name="email" />
          <PasswordInput placeholder="Enter your password" label="Password" name="password" required />
          
          <SpaceBetweenStyled>
            <p></p>
            <Paragraph color="black">Don't have an account? <u onClick={handleSwitchOtherAuthPage}>Register</u></Paragraph>
          </SpaceBetweenStyled>
          <br/>
          
          <Button type="submit" text="Login" isLoading={mutation.isLoading} />
        </Form>
      </StoreFrontAuthModalStyled>
    </ModalLayoutStyled>
  )
}

export default StoreFrontLoginModal
import {initializeApp} from "firebase/app";
import {getDatabase, onValue, ref} from "firebase/database";
import {getFirestore} from "firebase/firestore/lite"
import firebaseConfig from "./config";
import {getMessaging, onMessage} from "firebase/messaging"
import sendNotification from "../sendNotification";

const firebaseApp = initializeApp(firebaseConfig)


const db = getFirestore(firebaseApp)
const firebaseDatabase = getDatabase(firebaseApp);
const firebaseMessaging = getMessaging(firebaseApp)

onMessage(firebaseMessaging, ({notification: {title, body}}) => {
  sendNotification({title, body})
})

export {firebaseDatabase, ref, onValue, db, firebaseMessaging, firebaseApp}
import {Fragment, useState} from "react"
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {
  PageHeader,
  SearchAndFilter,
  TablePagination, Table, Chips, TableItemWithStatus
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {useModal} from "../../../hooks";
import {
  kycQueryKeys,
  useKycDocumentQuery
} from "../../../hooks/useServices/useStaffBackendServices/useKycDocumentServices";
import KYCModals from "../../../components/Modal/StaffDasboardModals/KYCModals/KYCModal";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import DateFilter from "../../../components/Utils/DateFilter";
import {kycServiceRestructure} from "../../../services/staffBackendServices/KycDocumentsServices/kycServiceRestructure";


const chips = [{ name: "All", value: "" }, { name: "Pending", value: "pending" }, { name: "Confirmed", value: "confirmed" }, { name: "Rejected", value: "rejected" }]

const KYC = () => {
  const [openKycModal, setOpenKycModal] = useModal()
  const [transactionType, setTransactionType] = useState("")
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  
  
  const [previewKycState, setPreviewKycState] = useState({})
  const { kycTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allAccounts } = useSearchQuery(debounceState, useKycDocumentQuery.bind(this, { status: transactionType, from_date, to_date }))
  
  const handleViewAccount = (kyc) => {
    setPreviewKycState(kyc)
    setOpenKycModal()
  }
  
  return (
    <div>
      {openKycModal && <KYCModals onClose={setOpenKycModal} data={previewKycState} />}
      
        <Fragment>
          <PageHeader title="KYC Documents" />

          <SpaceBetweenStyled>
            <FlexStyled>
              {chips.map(item => (
                <Chips
                  key={item.name}
                  title={item.name}
                  active={item.value === transactionType}
                  onClick={() => setTransactionType(item.value)}
                />
              ))}
            </FlexStyled>

            <DateFilter labelBackground="background" placeholder="Filter by date" setDateFilter={setDateFilter} />
          </SpaceBetweenStyled> <br/>
          
          <SetUpLayoutStyled>
            <SearchAndFilter
              noFilter
              dispatch={debounceReducer}
              searchPlaceholder="Search by business name"
            />
            
            <Table headers={kycTable.headers} noCols={5}>
              {allAccounts.isQueryWithData && (
                allAccounts.data.results.map((items, k) =>
                  <TableItemWithStatus
                    key={k}
                    count={k}
                    data={items}
                    statusPosition={4}
                    onView={handleViewAccount}
                    keys={kycTable.values}
                    pageCount={allAccounts.currentCount}
                  />
                )
              )}
              
              {allAccounts.isQueryWithNoData && (
                <NoQueryData text={allAccounts.isSearchEmptyMessage("Account", "")} />
              )}
              
              {allAccounts.isQueryLoading && (
                <TableSkeleton />
              )}
            </Table>
            
            {allAccounts.showPagination && (
              <TablePagination
                query={allAccounts}
                restructureFn={kycServiceRestructure}
                queryKey={kycQueryKeys.logs({ from_date, to_date, status: transactionType })}
              />
            )}
          </SetUpLayoutStyled>
          
          {allAccounts.showPagination && (
            <TablePagination
              query={allAccounts}
              restructureFn={kycServiceRestructure}
              queryKey={kycQueryKeys.logs({ from_date, to_date, status: transactionType })}
            />
          )}
        </Fragment>
    </div>
  )
}


export default KYC
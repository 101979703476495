import {CloseSquare} from "iconsax-react";
import { Button, Form, Input } from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {addOnFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const CreateAddOnModal = ({ onClose, handleSubmit, mutation }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Add-On</Heading>
        <Form values={addOnFormDefaultValues} onSubmit={handleSubmit}>
          <Input label="Name" placeholder="Add-on name" name="name"  />
          <Input label="Cost" type="number" formatNumber placeholder="Enter add-on cost" name="cost"  />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateAddOnModal
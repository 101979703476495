import {useEffect, useState} from "react";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {useQueryClient} from "react-query";
import {desktopOfflineQueryKeys} from "../useDesktopServicesFromLocalDB";
import useIsOfflineDataReady from "./useIsOfflineDataReady";
import {getIsDesktopOnlineStatus} from "../../../utils";
import {isAllDesktopRequestSynced} from "../../../utils/desktopUtils/desktopSyncUtils";


const useDesktopOffline = () => {
  const isOfflineReady = useIsOfflineDataReady()
  const desktopManualStatus = getIsDesktopOnlineStatus()
  
  const queryClient = useQueryClient()
  const [isPreviouslyOffline, setIsPreviouslyOffline] = useState(false)
  const [isOffline, setIsOffline] = useState(false);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!!window?.require) {
        const {ipcRenderer} = window.require("electron")
        ipcRenderer.send(IPC_EVENTS.IS_OFFLINE)
        
        ipcRenderer.once(IPC_EVENTS.IS_OFFLINE, (_, data) => {
          setIsOffline(!!data)
          ipcRenderer.removeAllListeners(IPC_EVENTS.IS_OFFLINE)
        })
      }
    }, 3000);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [desktopManualStatus])
  
  useEffect(() => {
    if(desktopManualStatus === "offline") return
    
    // send notification when user is offline
    if(isOffline && !isPreviouslyOffline && !!window?.require) {
      setIsPreviouslyOffline(true)
      queryClient.invalidateQueries({ queryKey: desktopOfflineQueryKeys.all })
      queryClient.refetchQueries({ queryKey: desktopOfflineQueryKeys.all })
    }
  
    // send notification when user is back online
    if(!isOffline && isPreviouslyOffline && !!window?.require) {
      setIsPreviouslyOffline(false)
    }
  }, [isOffline, desktopManualStatus])
  
  const isDesktopOffline = () => {
    // prevent application from going offline when all data has not synced
    if (!isAllDesktopRequestSynced()) return false
  
    // if a user manually switch offline, nothing should take them online
    if (desktopManualStatus === "offline") return true
  
    if (!isOfflineReady) return false
    return isOffline
  }
  
  return !!window?.require && isDesktopOffline()
}

export default useDesktopOffline
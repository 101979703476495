import {
  createSubCategoryService, deleteSubCategoryService,
  editSubCategoryService, getSubCategoriesByCategoryIdService,
} from "../../../services/subCategoryServices";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLocation, useParams} from "react-router-dom";
import errorHandler from "../../../utils/errorHandler";
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import {useDesktopSubCategoriesServiceFromLocalDB} from "../../useDesktop/useDesktopServicesFromLocalDB";


const main = ["sub_category", getBusinessIdFromStorage()]
export const subCategoryQueryKeys = {
  main: [...main],
  mainList: (query = {}) => [...main, getAllObjectKeysWithValues(query)]
}


const useSubCategoriesByCategoryIdQuery = (categoryId, searchValue) => {
  const { isOffline } = useGlobalContext()
  
  const offlineData = useDesktopSubCategoriesServiceFromLocalDB({ category: categoryId, search: searchValue })
  
  const onlineData = useQuery({
    enabled: !isOffline && !!categoryId,
    queryKey: subCategoryQueryKeys.mainList({ categoryId, searchValue }),
    queryFn: () => getSubCategoriesByCategoryIdService(categoryId, searchValue)
  })
  
  const fetch = () => {
    return isOffline ? offlineData : onlineData
  }
  
  return fetch()
}

const useCreateSubCategoryMutation = ({ successFn }) => {
  const { categoryId } = useParams()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()
  const { toast, globalState, navigate, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createSubCategoryService({ ...data, category: categoryId, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Sub Category Created!!")
      queryClient.invalidateQueries({ queryKey: subCategoryQueryKeys.main })

      if(globalState.modalConnector.toModalURL === pathname) {
        navigate(globalState.modalConnector.fromModalURL)
      }
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditSubCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editSubCategoryService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({ queryKey: subCategoryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteSubCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: subCategoryId => {
      return deleteSubCategoryService(subCategoryId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: subCategoryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export {
  useSubCategoriesByCategoryIdQuery, useCreateSubCategoryMutation,
  useEditSubCategoryMutation, useDeleteSubCategoryMutation
}
import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {Button, Form, Input} from "../../index";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {Select} from "../../index";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";


const CreateTipModal = ({ onClose, handleSubmit, mutation }) => {
  const { data: allStaffs } = useStaffByBusinessIdQuery()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Tip</Heading>

        <Form values={{ amount: "", staff: "", staff_name: "" }} onSubmit={handleSubmit}>
          <Select
            options={allStaffs?.results}
            displayKey="full_name"
            valueKey="id"
            label="Staff To Tip"
            placeholder="Select staff to tip"
            name="staff"
            displayName="staff_name"
          />
          <Input label="Amount" type="number" formatNumber placeholder="Enter amount to tip staff" name="amount"  />

          <FlexStyled>
            <Button isLoading={mutation.isLoading} text="Add Tip" type="submit" />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateTipModal
import {useContext} from "react";
import queryKeys from "../queryKeys";
import GlobalProvider from "../../../providers/GlobalProvider";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createSentItemService, getSentItemByBusinessIdService} from "../../../services/sentItemServices";
import errorHandler from "../../../utils/errorHandler";


const useSentItemByBusinessIdQuery = () => {
	const { getBusinessId } = useContext(GlobalProvider)
	const businessId = getBusinessId()

	return useQuery(
		`${queryKeys.SENT_ITEMS}_${businessId}`,
		getSentItemByBusinessIdService.bind(this, businessId)
	)
}

const useCreateSentItemMutation = ({ successFn }) => {
	const { getBusinessId, toast } = useContext(GlobalProvider)

	const businessId = getBusinessId()
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: body => {
			return createSentItemService({...body, business: businessId})
		},

		onSuccess: () => {
			successFn()
			toast.success("Packaged Created!!!")
			queryClient.invalidateQueries({ queryKey: `${queryKeys.SENT_ITEMS}_${businessId}` })
		},

		onError: err => {
			successFn()
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
		}
	})
}

export { useSentItemByBusinessIdQuery, useCreateSentItemMutation }
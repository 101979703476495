import {
	currencyFormatterFn,
	currencyFormatterWithNoFractionFn,
	dateFormatterFn,
	dateOnlyFormatterFn,
	dateWithDayFormatterFn,
	numberFormatterFn,
	shortDateFormatterFn,
	timeFormatterFn
} from "../../../utils/dataTypeFormatter"
import {useBusinessByIdQuery} from "../../useServices/useStoreServices";


const useDataTypeFormatter = () => {
	const { data: businessDetails } = useBusinessByIdQuery()
	const locale = "en-NG", currency = businessDetails?.details?.currency_field || businessDetails?.details?.country_code || "NGN"
	
	const dateWithDayFormatter = dateWithDayFormatterFn(locale)
	const dateFormatter = dateFormatterFn(locale)
	const timeFormatter = timeFormatterFn(locale)
	const numberFormatter = numberFormatterFn(locale)
	const dateOnlyFormatter = dateOnlyFormatterFn(locale)
	const shortDateFormatter = shortDateFormatterFn(locale)
	const currencyFormatter = currencyFormatterFn(locale, currency)
	const nairaCurrencyFormatter = currencyFormatterFn("en-NG", "NGN")
	const currencyFormatterWithNoFraction = currencyFormatterWithNoFractionFn(locale, currency)
	
	return {
		dateWithDayFormatter,
		dateFormatter,
		numberFormatter,
		currencyFormatter,
		timeFormatter,
		shortDateFormatter,
		dateOnlyFormatter,
		currencyFormatterWithNoFraction,
		nairaCurrencyFormatter
	}
}

export default useDataTypeFormatter

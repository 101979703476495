import {
  StoreFrontNavigationSearchBarStyled,
  StoreFrontNavigationStyled, StoreFrontNavigationUserProfileStyled
} from "../../../../styles/StoreFrontStyles/storeFrontLayoutStyles";
import {Button, Cart, Form, Image, Input, Line} from "../../../../components";
import {Logo} from "../../../../assets/images";
import {
  ArrowRight2,
  SearchNormal1,
  User,
  Chart,
  Logout,
  Login,
  ProfileAdd,
  ShoppingCart,
} from "iconsax-react";
import { FaRegUser } from "react-icons/fa"
import { GoChevronDown } from "react-icons/go"
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {FlexColumnStyled} from "../../../../styles/utilStyles";
import {useBusinessByIdQuery} from "../../../../hooks/useServices/useStoreServices";
import {useSearchParams} from "react-router-dom";
import {ListDropDownStyled, MenuListStyled} from "../../../../styles/listStyles";
import {useUserProfile} from "../../../../hooks/useServices/userHooks";
import {Fragment} from "react";
import StoreFrontLoginModal from "../Modal/StoreFrontLoginModal";
import {useModal} from "../../../../hooks";
import {useLoginService} from "../../../../hooks/useServices/authenticationHooks";
import StoreFrontLogoutModal from "../Modal/StoreFrontLogoutModal";
import {clearAuthTokenFromStorage} from "../../../../utils";
import {useQueryClient} from "react-query";
import queryKeys from "../../../../hooks/useServices/queryKeys";
import StoreFrontRegisterModal from "../Modal/StoreFrontRegisterModal";
import {useCreateStoreFrontCustomerMutation} from "../../../../hooks/useServices/useStoreFrontServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import IconWithToolTip from "../../../../components/Utils/IconWithToolTip";
import useSellContext from "../../../../hooks/useContexts/useSellContext";
import StoreFrontUserProfileModal from "../Modal/StoreFrontUserProfileModal";


const StoreFrontNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  
  const { navigate } = useGlobalContext()
  const { data: userProfile } = useUserProfile()
  const { data: store } = useBusinessByIdQuery()
  
  const [showCart, setShowCart] = useModal()
  const { cart } = useSellContext()
  
  const [showLoginModal, setShowLoginModal] = useModal()
  const [showLogoutModal, setShowLogoutModal] = useModal()
  const [showRegisterModal, setShowRegisterModal] = useModal()
  const [showUserProfileModal, setShowUserProfileModal] = useModal()
  
  const handleSearch = (formValues) => {
    const existingParam = new URLSearchParams(searchParams)
    existingParam.set("search", formValues.search?.split(" ").join("-") || "")
    
    // if user is not on the product page - redirect to product page
    if(!window.location.pathname.split("/").includes("product")) {
      navigate(`/${store.store_front_name}/products?${existingParam.toString()}`)
      return
    }
    
    setSearchParams(existingParam.toString())
  }
  
  return (
    <StoreFrontNavigationStyled>
      {showCart && <Cart onClose={setShowCart} onShowLogin={setShowLoginModal} />}
      
      <div className="img_div" onClick={() => navigate(`/${store.store_front_name}`)}>
        <Image src={store?.image_path || Logo} alt="Ovaloop logo" />
        {!store?.image_path && (
          <Heading color="black" bold size={1.1}>{store?.store_front_name?.split("_")?.join(" ")}</Heading>
        )}
      </div>
      
      <StoreFrontNavigationSearchBarStyled>
        <Form values={{ search: searchParams.get("search")?.split("-")?.join(" ") }} onSubmit={handleSearch}>
          <Input noLabel placeholder="Search for products" name="search" noBottomMargin optional />
          <Button Icon={SearchNormal1} text="Search" type="submit" />
        </Form>
      </StoreFrontNavigationSearchBarStyled>
      
      <StoreFrontNavigationUserProfileStyled>
        <FaRegUser size={27} color="black" className="desktop_user_icon" />
        
        <FlexColumnStyled $gap={.05} className="user_info">
          <Paragraph color="black" size={.9} noTop>Good Morning</Paragraph>
          {!!userProfile && (
            <Paragraph bold color="black" noTop>{userProfile?.[0]?.first_name} {userProfile?.[0]?.last_name}</Paragraph>
          )}
  
          {!userProfile && (
            <Paragraph bold color="black" noTop>Hello, Guest</Paragraph>
          )}
        </FlexColumnStyled>
  
        <MenuListStyled>
          <FaRegUser size={27} color="black" className="mobile_user_icon" />
          <GoChevronDown size={20} color="black" className="dropdown_icon" />
          
          <div className="container">
            <ProfileDropDown
              setShowLoginModal={setShowLoginModal}
              setShowLogoutModal={setShowLogoutModal}
              setShowRegisterModal={setShowRegisterModal}
              showLoginModal={showLoginModal}
              showLogoutModal={showLogoutModal}
              showRegisterModal={showRegisterModal}
              showUserProfileModal={showUserProfileModal}
              setShowUserProfileModal={setShowUserProfileModal}
            />
          </div>
        </MenuListStyled>
  
        <IconWithToolTip chip={cart.length}>
          <ShoppingCart size={28} color="black" className="cart_icon" onClick={setShowCart} />
        </IconWithToolTip>
      </StoreFrontNavigationUserProfileStyled>
    </StoreFrontNavigationStyled>
  )
}


export default StoreFrontNavigation


const ProfileDropDown = ({ showLoginModal, setShowLoginModal, showLogoutModal, setShowLogoutModal, showRegisterModal, setShowRegisterModal, showUserProfileModal, setShowUserProfileModal }) => {
  const queryClient = useQueryClient()
  const { navigate } = useGlobalContext()
  const { data: userProfile } = useUserProfile()
  const { data: store } = useBusinessByIdQuery()
  
  
  const loginMutation = useLoginService({ successFn: setShowLoginModal })
  const createUserMutation = useCreateStoreFrontCustomerMutation({ successFn: setShowRegisterModal })
  
  const handleLoginUser = (formValues) => {
    loginMutation.mutate({ ...formValues, isStoreFront: true })
  }
  
  const handleRegisterUser = (formValues) => {
    createUserMutation.mutate(formValues)
  }
  
  const handleLogoutUser = () => {
    setShowLogoutModal()
    clearAuthTokenFromStorage()
    queryClient.removeQueries({ queryKey: queryKeys.USER_PROFILE })
  }
  
  const handleShowUserProfile = () => {
    if(!userProfile) {
      setShowLoginModal()
      return
    }
    
    setShowUserProfileModal()
  }
  
  const handleNavigateToSalesHistory = () => {
    if(!userProfile) {
      setShowLoginModal()
      return
    }
    
    navigate(`/${store.store_front_name}/sales_history`)
  }
  
  const handleUpdateUserProfile = () => {
  
  }
  
  const handleSwitchOtherAuthPage = () => {
    setShowLoginModal()
    setShowRegisterModal()
  }
  
  return (
    <Fragment>
      {showLogoutModal && <StoreFrontLogoutModal handleSubmit={handleLogoutUser} onClose={setShowLogoutModal} />}
      {showLoginModal && <StoreFrontLoginModal handleSwitchOtherAuthPage={handleSwitchOtherAuthPage} mutation={loginMutation} handleSubmit={handleLoginUser} onClose={setShowLoginModal} />}
      {showRegisterModal && <StoreFrontRegisterModal handleSwitchOtherAuthPage={handleSwitchOtherAuthPage} mutation={createUserMutation} handleSubmit={handleRegisterUser} onClose={setShowRegisterModal} />}
      {showUserProfileModal && <StoreFrontUserProfileModal onClose={setShowUserProfileModal} handleSubmit={handleUpdateUserProfile} mutation={{}} />}
      
      <ListDropDownStyled>
        <MenuListStyled onClick={handleShowUserProfile}>
          <User size={20} color="black" />
          <Heading size={1.1} color="black">Profile</Heading>
          <ArrowRight2  size={18} color="black" className="right-icon" />
        </MenuListStyled>
    
        <MenuListStyled onClick={handleNavigateToSalesHistory}>
          <Chart size={20} color="black" />
          <Heading size={1.1} color="black">Sales History</Heading>
          <ArrowRight2 size={18} color="black" className="right-icon" />
        </MenuListStyled>
    
        <Line />
    
        {!userProfile && (
          <Fragment>
            <MenuListStyled onClick={setShowLoginModal}>
              <Login size={20} color="black" />
              <Heading size={1.1} color="black">Login</Heading>
            </MenuListStyled>
        
            <MenuListStyled onClick={setShowRegisterModal}>
              <ProfileAdd size={20} color="black" />
              <Heading size={1.1} color="black">Register</Heading>
            </MenuListStyled>
          </Fragment>
        )}
    
        {!!userProfile && (
          <Fragment>
            <MenuListStyled onClick={setShowLogoutModal}>
              <Logout size={20} color="black" />
              <Heading size={1.1} color="black">Logout</Heading>
            </MenuListStyled>
          </Fragment>
        )}
      </ListDropDownStyled>
    </Fragment>
  )
}
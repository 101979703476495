import {useEffect, useReducer} from "react";
import {Outlet, useParams} from "react-router-dom";
import {useStoreFrontBusinessQuery} from "../../hooks/useServices/useStoreFrontServices";
import {setBusinessIdInStorage} from "../../utils";
import {StoreFrontLayoutStyled} from "../../styles/StoreFrontStyles/storeFrontLayoutStyles";
import StoreFrontNavigation from "./Components/StoreFrontNavigation";
import sellReducer, {sellReducerInitialValues} from "../../reducers/sellReducer";
import {SellProvider} from "../../providers/SellProvider";


const StoreFrontLayout = () => {
  const { storeName } = useParams()
  const { data: storeBusiness, error, isLoading } = useStoreFrontBusinessQuery()
  
  const [sellState, reducer] = useReducer(sellReducer, sellReducerInitialValues)
  
  useEffect(() => {
    if(!storeName) window.open(window.location, '_self').close()
  
    if(!isLoading && !!error && !storeBusiness) {
      window.open(window.location, '_self').close()
      return
    }
    
    if(!!storeBusiness) {
      if(!storeBusiness.is_published) {
        window.open(window.location, '_self').close()
        return
      }
      
      setBusinessIdInStorage(storeBusiness?.business_id)
    }
  }, [error, storeBusiness, isLoading])
  
  return (
    <StoreFrontLayoutStyled>
      <SellProvider.Provider value={{ ...sellState, reducer }}>
        <section className="main">
          <StoreFrontNavigation />
          <Outlet />
        </section>
      </SellProvider.Provider>
    </StoreFrontLayoutStyled>
  )
}

export default StoreFrontLayout
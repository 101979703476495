import {Fragment, useState} from "react";
import {DatePicker, Form, Select} from "../index";
import {FlexStyled} from "../../styles/utilStyles";
import dateFilter, {dateFilterValue} from "../../data/selectField/dateFilter";
import {SearchNormal1} from "iconsax-react";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";


const DateFilter = ({ placeholder, setDateFilter, labelBackground = "white", dateFilterDefaultValue }) => {
  const { toast } = useGlobalContext()
  const [filter, setFilter] = useState("")
  
  const [{ from_date, to_date }, setDate] = useState({
    from_date: "",
    to_date: ""
  })
  
  const handleChange = (type, value) => {
    setDate(prevState => ({
      ...prevState,
      [type]: value
    }))
  }
  
  const handleSearch = () => {
    if(!from_date) {
      toast.error("Input Start Date")
      return
    }
    
    if(!to_date) {
      toast.error("Input End Date")
      return
    }
    
    setDateFilter({ from_date, to_date })
  }
  
  const handleDateFilterSelect = (filterType) => {
    setFilter(filterType.value)
    
    if(filterType.value === dateFilterValue.CUSTOM_PERIOD) {
      return
    }
    
    const { toDate, fromDate } = handleDateFilterValue(filterType.value)
    setDateFilter({ from_date: fromDate, to_date: toDate })
  }
  
  return (
    <Fragment>
      <div className="div_with_date_filter">
        <Form values={{ from_date: "", to_date: "", filter: "", display_filter: filter || dateFilterDefaultValue }}>
          <FlexStyled className="date_filter_fields">
            <Select
              className="date_select_field"
              name="filter"
              valueKey="value"
              displayKey="name"
              options={dateFilter}
              displayName="display_filter"
              placeholder={placeholder || "Filter analytics by date"}
              updateFn={handleDateFilterSelect}
            />

            {filter === dateFilterValue.CUSTOM_PERIOD && (
              <Fragment>
                <DatePicker labelBg={labelBackground} isTodayMax required onChange={e => handleChange("from_date", e)} noBottomMargin name="from_date" placeholder="Date from" />
                <DatePicker labelBg={labelBackground} isTodayMax required onChange={e => handleChange("to_date", e)} noBottomMargin name="to_date" placeholder="Date to" />
                <SearchNormal1 type="submit" onClick={handleSearch} size={23} color="black" />
              </Fragment>
            )}
          </FlexStyled>
        </Form>
  
      </div>
    </Fragment>
  )
}


export default DateFilter
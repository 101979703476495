import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getSupplierService = async (query = {}) => {
  try {
    const url = handleQueryParams('/supplier/', query)
    const {data} = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getSupplierByIdService = async (supplierId) => {
  try {
    const { data } = await axios.get(`/supplier/${supplierId}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getSupplierByBusinessIdService = async (businessId, searchValue = " ") => {
  try {
    const { data } = await axios.get(`/supplier/?business_id=${businessId}&search=${searchValue}`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const getSupplierByCategoryIdService = async (businessId, categoryId) => {
  try {
    const { data } = await axios.get(`/supplier/?business_id=${businessId}&category_id=${categoryId}`)
    return data
  } catch (e) {
    throw e
  }
}

const createSupplierService = async (body) => {
  try {
    const { data } = await axios.post("/supplier/", body)
    return data
  } catch (e) {
    throw e
  }
}

const editSupplierService = async (supplierId, body) => {
  try {
    const { data } = await axios.patch(`/supplier/${supplierId}`, body)
    return data
  } catch (e) {
    throw e
  }
}

const deleteSupplierService = async (supplierId) => {
  try {
    const { data } = await axios.delete(`/supplier/${supplierId}`)
    return data
  } catch (e) {
    throw e
  }
}

const uploadBulkSupplierService = async (type, businessId, body) => {
  const upload = `/supplier_upload/?business_id=${businessId}`
  const update = `/supplier_upload/?business_id=${businessId}&upload_type=batch_update`
  
  try {
    const { data } = await axios.post(type === "update" ? update : upload, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export {
  getSupplierService, getSupplierByBusinessIdService, getSupplierByCategoryIdService,
  createSupplierService, editSupplierService, deleteSupplierService, uploadBulkSupplierService
}
import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, DatePicker, Form, Input} from "../../../../index";
import { Heading } from "../../../../../styles/textStyles";
import {FlexStyled} from "../../../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../../styles/modalStyles";


const CreateProductExpiryModal = ({ onClose, handleSubmit, mutation }) => {
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Expiry Product</Heading>
        <Form values={{ expiry_date: "", quantity: "", is_active: true }} onSubmit={handleSubmit}>
          <Input label="Quantity" placeholder="Enter product quantity to track" name="quantity" formatNumber />
          <DatePicker name="expiry_date" label="Expiry Date" />
          <CheckBox name="is_active" labelProp="Active expiry notification" checked optional />
          
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateProductExpiryModal
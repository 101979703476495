import {ModalLayoutStyled, CreateStoreModalStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Button, Form, Image, TextArea} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {useKycDocumentMutation} from "../../../../hooks/useServices/useStaffBackendServices/useKycDocumentServices";
import {Fragment, useState} from "react";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {WIPStatus} from "../../../../utils/table";



const KYCModals = ({ onClose, data: formValues }) => {
  const [remark, setRemark] = useState(null)
  const { toast } = useGlobalContext()
  const kycDocumentMutationTrue = useKycDocumentMutation({ successFn: onClose })
  const kycDocumentMutation = useKycDocumentMutation({ successFn: onClose })
  
  const handleSetRemark = (value) => {
    setRemark(value)
  }
  
  const handleKycResponse = (is_verified) => {
    if(is_verified) {
      kycDocumentMutationTrue.mutate({ is_verified, business: formValues.business.id })
      return
    }
    
    if(!is_verified && !remark) {
      toast.error("Remark is required for rejection")
      return;
    }
    
    kycDocumentMutation.mutate({ is_verified, business: formValues.business.id, remark })
  }
  
  const documentType = () => {
    const file = formValues?.kyc_path.split(".")
    return file[file.length - 1]
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">KYC Document</Heading>
        
        <Form values={{ remark: "" }} >
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Business Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.business_name}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_name}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Bank name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.bank_name}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Account Number: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.account_number}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
          
          {documentType() === "pdf" && (
            <a href={formValues?.kyc_path} target="_blank">
              <Paragraph color="black" size={1.2}>View Document</Paragraph>
            </a>
          )}
          
          {documentType() !== "pdf" && (
            <Image width={100} src={formValues?.kyc_path} alt={formValues.business_name} />
          )}
          
          <FlexStyled />
          
          {formValues.status === "pending" && (
            <Fragment>
              <TextArea onChange={handleSetRemark} className="fullWidth" name="remark" placeholder="Enter reason for rejection" optional label="Remark" />
  
              <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
                <Button text="Approve" onClick={handleKycResponse.bind(this, true)} isLoading={kycDocumentMutationTrue.isLoading} />
                <Button text="Reject" className="bg_red" onClick={handleKycResponse.bind(this, false)} isLoading={kycDocumentMutation.isLoading} />
              </FlexStyled>
            </Fragment>
          )}
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default KYCModals
import {getToken} from "firebase/messaging"
import {firebaseMessaging} from "../utils/firebase/init";

export const getDeviceRegistrationId = async () => {
  return new Promise(async (resolve, reject) => {
    let data = {}
    for (let i = 0; i < 3; i++) {
      try {
        await requestNotificationPermission()
        const deviceRegistrationId = await activeFirebaseMessaging()
        const deviceName = navigator.userAgent.slice(0, navigator.userAgent.indexOf(')') + 1)
  
        if (deviceRegistrationId) {
          window.firebase_registration_request_count = 0
          data = {device_id: deviceRegistrationId, device_name: deviceName}
          break
        }
      } catch (e) {
      }
    }
    
    if (data.device_id) {
      resolve(data)
      return
    }
    
    reject("Device registration ID not generated")
  })
}

const requestNotificationPermission = async () => {
  try {
    await Notification.requestPermission()
  } catch (e) {
    throw new Error("Permission not granted")
  }
}


export const activeFirebaseMessaging = async () => {
  try {
    const deviceRegistrationId = await getToken(firebaseMessaging, {vapidKey: "BFtqPu397PQJWIscAbNojnOKS6m7liH1cWjYXIwR3BWIm0iLvkiZCA1n4zdbEk97HRNP-_xBHYhmfu5dkYoczNg"})
    if (deviceRegistrationId) {
      return deviceRegistrationId
    }
    
    return Error("")
  } catch (_) {
    return new Error("")
  }
}


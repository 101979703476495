import {InventoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {Button, Form} from "../../index";
import {FlexStyled} from "../../../styles/utilStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useUserProfile} from "../../../hooks/useServices/userHooks";


const OvaloopPayTransactionModal = ({ onClose, formValues, handleSubmit = () => {}, mutation = {} }) => {
  const { data: userProfile } = useUserProfile()
  const { nairaCurrencyFormatter, dateFormatter } = useDataTypeFormatter()
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">View Transaction</Heading>
        
        <Form values={formValues} onSubmit={handleSubmit} allFieldsNotRequired>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Sender Account Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.sourceAccountName}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Sender Account Number: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.sourceAccountNumber}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Sender Bank Name: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues?.sourceBankName}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Transaction Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.transactionAmount)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Settled Amount: </Paragraph>
            <Paragraph color="black" size={1.2}>{nairaCurrencyFormatter(formValues.settledAmount)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Session ID: </Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.sessionID}</Paragraph>
          </FlexStyled>
  
          {((userProfile?.[0]?.user_type === "staff") && formValues.ovaloopStatus !== "confirmed") && (
            <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
              <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
              <Paragraph color="black" size={1.2}>{formValues.notificationStatus}</Paragraph>
            </FlexStyled>
          )}
          
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Date Created: </Paragraph>
            <Paragraph color="black" size={1.2}>{dateFormatter(formValues.createdAt)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled></FlexStyled>
          
          {((userProfile?.[0]?.user_type === "staff") && formValues.ovaloopStatus !== "confirmed") && (
            <FlexStyled>
              <Button text="Re-push" type="submit" isLoading={mutation.isLoading} />
            </FlexStyled>
          )}
        </Form>
      
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default OvaloopPayTransactionModal
import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, DatePicker, Form, Input, Select} from "../../../index";
import {Heading} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useCustomerQuery} from "../../../../hooks/useServices/useCustomerServices";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {useSellableProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";
import {Fragment, useEffect, useRef, useState} from "react";
import {discountAndTaxTypesEnum, discountSelectTypes} from "../../../../data/selectField/discountAndTaxSelectTypes";
import {useFormikContext} from "formik";
import {useSubCategoriesByCategoryIdQuery} from "../../../../hooks/useServices/useSubCategoryServices";
import MultiSelectInput from "../../../Forms/MultiSelectInput";


const EditDiscountModal = ({onClose, handleSubmit, mutation, formValues}) => {
  useCustomerQuery()
  useCategoriesQuery()
  useSellableProductByBusinessIdQuery()
  
  const [alwaysCheckEnforce, setAlwaysCheckEnforce] = useState(formValues.discount_type === "customer")
  const [selectedMappings, setSelectedMapping] = useState(formValues.selectedMapping)
  
  const onSubmit = (values) => {
    handleSubmit({
      ...values,
      mapping_id: selectedMappings,
      ...(alwaysCheckEnforce && {is_enforced: true})
    })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Edit: {formValues.title}</Heading>
        <Form values={formValues} onSubmit={onSubmit}>
          <Input label="Title" className="fullWidth" placeholder="Enter discount title" name="title"/>
          
          <Select
            options={discountSelectTypes}
            name="discount_type"
            placeholder="Select type of discount"
            label="Discount Type"
            displayKey="name"
            valueKey="value"
            displayName="discount_type_name"
            updateFn={({value}) => setAlwaysCheckEnforce(value === "customer")}
          />
          
          <MappingIDComp setSelectedMapping={setSelectedMapping} selectedMapping={selectedMappings}/>
  
          <Input clearCondition="discount_value_type" label="Discount Value" type="number"
                 placeholder="Enter discount value" name="value"/>
      
          <DatePicker name="start_date" label="Start Date"/>
          <DatePicker name="expiry" optional label="Expiry Date"/>
          
          <CheckBox checked={!!formValues.active} optional name="active" labelProp="Activate Discount"/>
          <CheckBox alwaysCheck={alwaysCheckEnforce} checked={!!formValues.is_enforced} optional name="is_enforced"
                    labelProp="Enforce discount"/>
      
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditDiscountModal


const MappingIDComp = ({setSelectedMapping, selectedMapping}) => {
  const {values} = useFormikContext()
  
  const isLoadRef = useRef(0)
  const [searchValue, setSearchValue] = useState("")
  const [categoryId, setCategoryId] = useState("")
  
  const {data: allProducts, isLoading: isProductsLoading} = useSellableProductByBusinessIdQuery(searchValue)
  const {data: allCategories, isLoading: isCategoriesLoading} = useCategoriesQuery(searchValue)
  const {
    data: allSubCategories,
    isLoading: isSubCategoriesLoading
  } = useSubCategoriesByCategoryIdQuery(categoryId)
  const {data: allCustomers, isLoading: isCustomersLoading} = useCustomerQuery(searchValue)
  
  useEffect(() => {
    if (isLoadRef.current === 2) {
      setSelectedMapping([])
      setSearchValue("")
      return
    }
    
    isLoadRef.current += 1
  }, [values.discount_type])
  
  return (
    <Fragment>
      {(values.discount_type === discountAndTaxTypesEnum.product) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isProductsLoading}
          options={allProducts?.results}
          displayName="name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select Product(s)"
          placeholder="Select products to add discount to"
        />
      )}
      
      {(values.discount_type === discountAndTaxTypesEnum.category) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isCategoriesLoading}
          options={allCategories?.results}
          displayName="name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select categories"
          placeholder="Select categories to add discount to"
        />
      )}
      
      {(values.discount_type === discountAndTaxTypesEnum.sub_category) && (
        <Fragment>
          <Select
            label="Select category"
            name="category_name"
            displayKey="name"
            valueKey="id"
            displayName="category_name_display"
            options={allCategories?.results}
            placeholder="Select Category"
            updateFn={(category) => setCategoryId(category.id)}
          />
          
          <MultiSelectInput
            className="fullWidth"
            onSearch={setSearchValue}
            isLoading={isSubCategoriesLoading}
            options={allSubCategories?.results}
            displayName="name"
            selectedValues={selectedMapping}
            setSelected={setSelectedMapping}
            label="Select Sub Categories"
            placeholder="Select sub categories to add discount to"
          />
        </Fragment>
      )}
      
      {(values.discount_type === discountAndTaxTypesEnum.customer) && (
        <MultiSelectInput
          className="fullWidth"
          onSearch={setSearchValue}
          isLoading={isCustomersLoading}
          options={allCustomers?.results}
          displayName="full_name"
          selectedValues={selectedMapping}
          setSelected={setSelectedMapping}
          label="Select customer(s)"
          placeholder="Select customer(s) to add discount to"
        />
      )}
    </Fragment>
  )
}
import {InventoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {CloseSquare} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Button, Form, Input, Select} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {depleteFaultyProductTypes} from "../../../../data/selectField/product";
import {WIPStatus} from "../../../../utils/table";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {Fragment} from "react";


const DepleteFaultyProductModal = ({onClose, handleSubmit, mutation, formValues}) => {
  const {numberFormatter} = useDataTypeFormatter()
  
  const handleSubmission = (values) => {
    handleSubmit({...values, id: formValues.id})
  }
  
  const getTotalActionQuantity = () => {
    return Number(formValues.accept_qty) + Number(formValues.revert_qty) + Number(formValues.destroyed_qty)
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <InventoryModalStyled $width={50} onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose}/>
        <Heading size={1.3} color="black">Bad & Damaged Log: {formValues.product_name}</Heading>
        
        <Form values={{qty: "", action: "", display_name: ""}}
              onSubmit={handleSubmission}>
          
          <FlexStyled className="fullWidth" style={{marginBottom: "1.5rem"}}>
            <Paragraph bold color="black" size={1.2}>Total Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.quantity)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{marginBottom: "1.5rem"}}>
            <Paragraph bold color="black" size={1.2}>Accepted Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.accept_qty)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{marginBottom: "1.5rem"}}>
            <Paragraph bold color="black" size={1.2}>Reverted Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.revert_qty)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{marginBottom: "1.5rem"}}>
            <Paragraph bold color="black" size={1.2}>Destroyed Quantity: </Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.destroyed_qty)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled className="fullWidth" style={{marginBottom: "1.5rem"}}>
            <Paragraph bold color="black" size={1.2}>Status: </Paragraph>
            <Paragraph color="black" size={1}>{WIPStatus(formValues.status)}</Paragraph>
          </FlexStyled>
          
          <FlexStyled></FlexStyled>
          
          {(getTotalActionQuantity() !== Number(formValues.quantity)) && (
            <Fragment>
              <Select
                required
                valueKey="value"
                name="action"
                label="Type"
                className="fullWidth"
                displayKey="name"
                displayName="display_name"
                options={depleteFaultyProductTypes}
                placeholder="Select type of depletion"
              />
              
              <Input type="number" name="qty" className="fullWidth" label="Quantity" placeholder="Enter quantity"/>
              
              <FlexStyled>
                <Button type="submit" text="Proceed" isLoading={mutation.isLoading}/>
              </FlexStyled>
            </Fragment>
          )}
        </Form>
      </InventoryModalStyled>
    </ModalLayoutStyled>
  )
}


export default DepleteFaultyProductModal
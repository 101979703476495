import {CloseSquare} from "iconsax-react";
import { Button, Form, Input } from "../../index";
import { Heading } from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";


const CreateProductionStageModal = ({ onClose, handleSubmit, mutation }) => {
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Production State</Heading>
        <Form values={{ name: "" }} onSubmit={handleSubmit}>
          <Input label="Stage name" placeholder="Enter stage name" name="name"  />

          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateProductionStageModal
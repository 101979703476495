import { ModalLayoutStyled, CreateCategoryModalStyled, ReceiptModalStyled } from "../../../styles/modalStyles";
import {useRef} from "react";
import {AllCenterStyled, FlexColumnStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {Button} from "../../index";
import {useReactToPrint} from "react-to-print";
import {useDownloadReceiptMutation} from "../../../hooks/useServices/useReportServices";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";

const CustomerSubscriptionReceipt = ({ onClose, subscription }) => {
	const receiptRef = useRef(null)
	const { isMobile } = useGlobalContext()
	const { data: userProfile } = useUserProfile()
	const { data: business } = useBusinessByIdQuery()
	const downloadReceiptMutation = useDownloadReceiptMutation({ successFn: onClose })
	const { dateOnlyFormatter, timeFormatter, currencyFormatter } = useDataTypeFormatter()

	const { customer } = subscription


	const isOrderStatusPending = () => {
		return subscription.payment_status === "pending"
	}

	const handlePrintReceipt = useReactToPrint({
		content: () => {
			const newReceiptNode = receiptRef.current.cloneNode(true)
			const actionEl = newReceiptNode.querySelector(".receipt_actions")
			newReceiptNode.firstElementChild.lastElementChild.removeChild(actionEl)

			if(isMobile) {
				const closeActionEl = newReceiptNode.querySelector(".close_action")
				newReceiptNode.firstElementChild.lastElementChild.removeChild(closeActionEl)
			}

			return newReceiptNode
		}
	})

	const handleDownloadReceipt = () => {
		downloadReceiptMutation.mutate(subscription.subscription_id)
	}

	return (
		<ModalLayoutStyled onClick={onClose} $noScroll>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()} ref={receiptRef} className="print_receipt">
				{isMobile && <><br/><br/><br/></>}

				<ReceiptModalStyled>
					<FlexColumnStyled className="header">
						<Paragraph color="white" bold>{userProfile?.[0]?.business_name[0]}</Paragraph>
						<Heading color="black" bold>{userProfile?.[0]?.business_name}</Heading>
					</FlexColumnStyled>

					<SectionStyled>
						<AllCenterStyled>
							<Heading color="black" size={1.3} className="title">Subscription {isOrderStatusPending() ? "Invoice" : "Receipt"}</Heading>
						</AllCenterStyled>

						<Heading color="black" bold>
							Hi {`${customer.first_name} ${customer.last_name},`}
						</Heading>
						{isOrderStatusPending() && (
							<Paragraph color="black" bold>Your subscription is being processed</Paragraph>
						)}

						{!isOrderStatusPending() && (
							<Paragraph color="black" bold>Your subscription has being processed</Paragraph>
						)}

						<br/>
						<FlexColumnStyled $gap={.4}>
							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Purchase Date:</Paragraph>
								<Paragraph color="black" bold>{dateOnlyFormatter(subscription.created_at)}</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Time:</Paragraph>
								<Paragraph color="black" bold>{timeFormatter(subscription.created_at)}</Paragraph>
							</SpaceBetweenStyled>
						</FlexColumnStyled>

						<br/>
						<FlexColumnStyled $gap={.2} className="order_details">
							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Customer Name:</Paragraph>
								<Paragraph color="black" bold>{customer.first_name} {customer.last_name}</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Payment Method:</Paragraph>
								<Paragraph color="black" noTop>
									{(subscription?.pay_mode).toUpperCase()}
								</Paragraph>
							</SpaceBetweenStyled>

							<br />
							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Subscription Name:</Paragraph>
								<Paragraph color="black" noTop>
									{subscription.subscription_service_details.subscription_service_name}
								</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Subscription Amount:</Paragraph>
								<Paragraph color="black" noTop>
									{currencyFormatter(subscription.subscription_service_details.subscription_service_amount)}
								</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Subscription Duration:</Paragraph>
								<Paragraph color="black" noTop>
									{subscription.subscription_service_details.subscription_service_duration} Months
								</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Subscription Limit:</Paragraph>
								<Paragraph color="black" noTop>
									{subscription.subscription_service_details.subscription_service_limit} Limit
								</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Subscription Id:</Paragraph>
								<Paragraph color="black" bold>{subscription.subscription_id}</Paragraph>
							</SpaceBetweenStyled>

							{/*{!!subscription?.banks && (*/}
							{/*	<SpaceBetweenStyled>*/}
							{/*		<Paragraph color="black" bold className="section_title">Bank:</Paragraph>*/}
							{/*		<Paragraph color="black" noTop>{subscription.banks.bank_name}</Paragraph>*/}
							{/*	</SpaceBetweenStyled>*/}
							{/*)}*/}
						</FlexColumnStyled>

						<br/>
						<FlexColumnStyled className="summary">
							<SpaceBetweenStyled>
								<Paragraph color="black" bold className="section_title">Sub Total: </Paragraph>
								<Paragraph color="black" bold className="section_title">
									{currencyFormatter(subscription.subscription_service_details.subscription_service_amount)}
								</Paragraph>
							</SpaceBetweenStyled>

							<SpaceBetweenStyled className="total">
								<Paragraph color="black" bold className="section_title">Total Cost: </Paragraph>
								<Paragraph color="black" bold>
									{currencyFormatter(subscription.subscription_service_details.subscription_service_amount)}
								</Paragraph>
							</SpaceBetweenStyled>
						</FlexColumnStyled>

						<br/>
						<AllCenterStyled className="thank_you_message">
							<Heading color="black" bold>
								{business?.receipt_message || "Powered by Ovaloop, your everyday business solution..."}
							</Heading>
						</AllCenterStyled>

						{/*<SpaceBetweenStyled className="receipt_actions">*/}
						{/*	<Button text="Download" onClick={handleDownloadReceipt} />*/}
						{/*	<Button text="Print" onClick={handlePrintReceipt} />*/}
						{/*</SpaceBetweenStyled>*/}

						<br /> <Button text="Print" onClick={handlePrintReceipt} />

						{isMobile && <Button text="Close" onClick={onClose} className="close_action" />}
					</SectionStyled>

				</ReceiptModalStyled>

			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}


export default CustomerSubscriptionReceipt
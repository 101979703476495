import {usePermissionCategoryQuery, usePermissionQuery} from "../useServices/usePermissionServices";

const useStructuredPermissions = () => {
	const { data: allPermissions } = usePermissionQuery()
	const { data: allPermissionCategories } = usePermissionCategoryQuery()

	if(!!allPermissions && !!allPermissionCategories) {
		const formatted = allPermissionCategories.map(permissionCategory => {
			const pc = allPermissions.filter(permission => {
				if(permission.category.includes(permissionCategory.id)) {
					return permission
				}
			})

			return { ...permissionCategory, category: pc }
		})

		const filterAllEmpty = formatted.filter(permission => permission.category.length > 0)

		return filterAllEmpty.sort((a, b) => {
			if(a.category.length < b.category.length) {
				return 1
			} else if(a.category.length === b.category.length) {
				return 0
			} else {
				return -1
			}
		})

	}
}

export default useStructuredPermissions
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  getAllOvaloopPayTransactionsService, rePushOvaloopPayTransactionService
} from "../../../../services/staffBackendServices/ovaloopTransactionServices";
import {getAllObjectKeysWithValues} from "../../../../utils/handleQueryParams";
import useGlobalContext from "../../../useContexts/useGlobalContext";
import errorHandler from "../../../../utils/errorHandler";


const allKey = ["ovaloop pay transactions"]

export const ovaloopPayTransactionsQueryKeys = {
  all: allKey,
  query: (queryParams = {}) => [...allKey, getAllObjectKeysWithValues(queryParams)]
}

export const useOvaloopPayTransactionServices = (query = {}) => {
  return useQuery({
    queryKey: ovaloopPayTransactionsQueryKeys.query(query),
    queryFn: () => getAllOvaloopPayTransactionsService(query)
  })
}


export const useRePushOvaloopPayTransactionMutation = () => {
  const { toast } = useGlobalContext()
  const queryClient = useQueryClient()
  
  return useMutation({
    mutationFn: (body) => {
      return rePushOvaloopPayTransactionService(body)
    },
    
    onSuccess: () => {
      toast.success("Transaction re-pushed")
      queryClient.refetchQueries({ queryKey: ovaloopPayTransactionsQueryKeys.all })
    },
  
    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}
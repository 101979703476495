const formatCheckOutFormForSubmission = (values = {}) => {
	const newValues = { ...values }

	if(!!newValues?.discount_type) {
		newValues.discount = { type: newValues.discount_type, value: newValues.discount_value }

		delete newValues?.discount_type
		delete newValues?.discount_value
		delete newValues?.discount_type_name
	}

	return handleSplitOrPartialPaymentMethod(newValues)
}


const handleSplitOrPartialPaymentMethod = (values) => {
	if(values.pay_mode !== "split" && values.pay_mode !== "partial") {
		delete values.split_payment
		return values
	}

	const split_payment = [
		{method: "cash", amount: Number(values.split_cash_value || 0)},
		{method: "pos", amount: Number(values.split_pos_value || 0), bank: values.split_pos_bank},
		{method: "transfer", amount: Number(values.split_transfer_value || 0), bank: values.split_transfer_bank}
	]

	return { ...values, split_payment }
}

export const handleInvoicePaymentMethod = (values) => {
	return [
		{method: "cash", amount: Number(values.split_cash_value || 0)},
		{method: "pos", amount: Number(values.split_pos_value || 0), bank: (values.split_pos_bank || "")},
		{method: "transfer", amount: Number(values.split_transfer_value || 0), bank: (values.split_transfer_bank || "")},
		{method: "others", amount: Number(values.split_other_value || 0), param: (values.split_other_value_id || "")}
	]
}


export { formatCheckOutFormForSubmission, handleSplitOrPartialPaymentMethod }
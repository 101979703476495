import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../../index";
import { Heading } from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const EditExpenseCategoryModal = ({ onClose, handleSubmit, mutation, formValues }) => {

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit: {formValues.name}</Heading>
        <Form values={formValues} onSubmit={handleSubmit}>
          <Input label="Name" placeholder="Enter expense category name" name="name"  />
    
          <FlexStyled>
            <Button text="Update" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditExpenseCategoryModal
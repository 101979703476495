import {CloseSquare} from "iconsax-react";
import {Button, CheckBox, Form, Input, Select} from "../../../index";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useStaffByBusinessIdQuery} from "../../../../hooks/useServices/useStaffServices";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useFormikContext} from "formik";
import {Fragment, useState} from "react";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../../data/permissions";


const CreateStagesModal = ({ onClose, handleSubmit, mutation, stage_name, values }) => {
	const permission = useActionPermissionFn()
	const { data: allStaffs } = useStaffByBusinessIdQuery()
	const { currencyFormatter } = useDataTypeFormatter()
	const [amount, setAmount] = useState(values.commission || 0)

	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Assign Staff To: {stage_name}</Heading>
				<Form values={{ staff: "" }} onSubmit={handleSubmit}>
					<Select
						options={allStaffs?.results}
						displayKey="full_name"
						valueKey="id"
						label="Assign Staff"
						placeholder="Assign staff to stage"
						name="staff"
						displayName="staff_name"
					/>
					
					{permission.check(actionsPermissions.wipCustomerCommission) && (
						<Fragment>
							{(!!amount || amount === 0) && (
								<Paragraph color="black">Staff will earn {currencyFormatter(amount)} as commission</Paragraph>
							)}
							
							<br/>
							<CheckBox optional labelProp="Alter commission for staff" name="is_commission" />
							
							<CustomCommissionForStaff setAmount={setAmount} />
						</Fragment>
					)}

					<FlexStyled>
						<Button text="Save" type="submit" isLoading={mutation.isLoading} />
					</FlexStyled>
				</Form>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default CreateStagesModal


export const CustomCommissionForStaff = ({ setAmount }) => {
	const { values } = useFormikContext()
	
	const handleAmount = (value) => {
		setAmount(Number(value))
	}
	
	return (
		<Fragment>
			{values.is_commission && (
				<Input onChange={handleAmount} name="commission" type="number" formatNumber placeholder="Enter customer commission value" label="Custom commission" />
			)}
		</Fragment>
	)
}
import {CloseSquare} from "iconsax-react";
import {Button, DatePicker, Form, Input, TextArea} from "../../index";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";


const CompleteProductionModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  const { toast } = useGlobalContext()
  const { numberFormatter } = useDataTypeFormatter()
  
  const handleSubmission = (values) => {
    if(Number(values.new_completed_quantity) > Number(formValues.available_quantity)) {
      toast.error("You can not produce above the available quantity", "limit")
      return
    }
    
    handleSubmit({
      id: values.id,
      status: "completed",
      remark: values.new_remark,
      expiry_date: values.new_expiry_date,
      produced_quantity: Number(values.new_completed_quantity)
    })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={45}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Complete Production</Heading>
        
        <Form values={formValues} onSubmit={handleSubmission}>
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Product:</Paragraph>
            <Paragraph color="black" size={1.2}>{formValues.product_name}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Projected Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.projected_quantity)}</Paragraph>
          </FlexStyled>
  
          <FlexStyled className="fullWidth" style={{ marginBottom: "1.5rem" }}>
            <Paragraph bold color="black" size={1.2}>Available Producible Quantity:</Paragraph>
            <Paragraph color="black" size={1.2}>{numberFormatter(formValues.available_quantity)}</Paragraph>
          </FlexStyled>
  
          <Input
            className="fullWidth"
            label="Final Produced Quantity"
            placeholder="Enter quantity to produce"
            name="new_completed_quantity"
          />
  
          <DatePicker className="fullWidth" optional name="new_expiry_date" label="Expiry Date" />
  
          <TextArea
            className="fullWidth"
            label="Remark"
            placeholder="Enter your remark"
            name="new_remark"
          />
  
          <Button className="fullWidth" text="Complete Production" type="submit" isLoading={mutation.isLoading} />
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default CompleteProductionModal
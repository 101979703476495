const firebaseConfig = {
	apiKey: "AIzaSyAAPU9kDu8dN3KbuM43OHJQMGRuPeSgCaE",
	authDomain: "ovaloop-23439.firebaseapp.com",
	databaseURL: "https://ovaloop-23439-default-rtdb.firebaseio.com",
	projectId: "ovaloop-23439",
	storageBucket: "ovaloop-23439.appspot.com",
	messagingSenderId: "355188781646",
	appId: "1:355188781646:web:a3677ce5d601ad40d1091f",
	measurementId: "G-5RTFE1KGMG"
};


export default firebaseConfig
import {HoldOrderCardStyled} from "../../styles/cardStyles";
import {Heading} from "../../styles/textStyles";
import {FlexColumnStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import useCartCalculations from "../../hooks/useUtils/useCartCalculations";
import {CloseSquare} from "iconsax-react";
import useHoldOrders from "../../hooks/useUtils/useHoldOrders";
import uuid from "react-uuid";


const HoldOrderCard = ({ orders, onClick, id, setTrigger }) => {
  const { removeOrderOnHoldById } = useHoldOrders()
  const { overallCalculation } = useCartCalculations(orders)
  
  const handleClearOrder = (e) => {
    e.stopPropagation()
    removeOrderOnHoldById(id)
    setTrigger(uuid())
  }
  
  return (
    <HoldOrderCardStyled onClick={() => onClick(id)}>
      <SpaceBetweenStyled>
        <Heading size={1} color="black" bold>{orders.length} Item(s)</Heading>
        <CloseSquare size={19} color="red" onClick={handleClearOrder} />
      </SpaceBetweenStyled>
      
      <FlexColumnStyled $gap={.8}>
        {orders.map(order => (
          <Heading color="black" key={order.id}>⊙ {order.name} (x{order?.unit || 1})</Heading>
        ))}
      </FlexColumnStyled>
      
      <SpaceBetweenStyled>
        <Heading color="black" bold size={1}>Total:</Heading>
        <Heading color="black" bold size={1}>
          {overallCalculation}
        </Heading>
      </SpaceBetweenStyled>
    </HoldOrderCardStyled>
  )
}


export default HoldOrderCard
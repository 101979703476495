import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  confirmCustomerSubscriptionPurchaseService,
  createCustomerSubscriptionService, cancelCustomerSubscriptionPurchaseService, getCustomerActiveSubscriptionByIdService,
  getCustomerSubscriptionByIdService, getCustomerSubscriptionPurchaseByIdService,
  getCustomerSubscriptionService
} from "../../../services/customerSubscriptionServices";
import {scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import useGlobalContext from "../../useContexts/useGlobalContext";
import customerQueryKey from "../useCustomerServices/customQueryKey";
import {useParams} from "react-router-dom";
import analyticsQueryKeys from "../useAnalyticsServices/analyticsQueryKeys";


const useCustomerSubscriptionQuery = (searchValue) => {
  scrollToTop()
  const { getBusinessId } = useGlobalContext()

  return useQuery({
    queryKey: customerQueryKey.allSubscriptionsQuery(searchValue),
    queryFn: getCustomerSubscriptionService.bind(this, getBusinessId(), searchValue)
  })
}

export const useCustomerSubscriptionByIdQuery = (customerId, searchValue) => {
  const { customerId: customerIdParam } = useParams()
  const { getBusinessId } = useGlobalContext()

  const customer_id = customerIdParam || customerId

  return useQuery({
    queryKey: customerQueryKey.subscription(customer_id),
    queryFn: () => getCustomerSubscriptionByIdService(getBusinessId(), customer_id, searchValue)
  })
}

export const useCustomerActiveSubscriptionByIdQuery = (customerId, searchValue) => {
  const { customerId: customerIdParam } = useParams()
  const { getBusinessId } = useGlobalContext()

  const customer_id = customerIdParam || customerId

  return useQuery({
    queryKey: customerQueryKey.subscription({ status: "active", id: customer_id }),
    queryFn: () => getCustomerActiveSubscriptionByIdService(getBusinessId(), customer_id, searchValue)
  })
}

export const useCustomerSubscriptionPurchaseByIdQuery = () => {
  const { subscriptionId } = useParams()

  return useQuery({
    queryKey: customerQueryKey.subscription(subscriptionId),
    queryFn: () => getCustomerSubscriptionPurchaseByIdService(subscriptionId)
  })
}


const useCreateCustomerSubscriptionMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createCustomerSubscriptionService({ ...data, business: getBusinessId() })
    },

    onSuccess: (res) => {
      successFn(res)
      toast.success("Customer Subscription Created!!", "created")
      queryClient.invalidateQueries({ queryKey: analyticsQueryKeys.subscription() })
      queryClient.invalidateQueries({ queryKey: customerQueryKey.allSubscriptions })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export const useCancelCustomerSubscriptionPurchaseMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: subscriptionId => {
      return cancelCustomerSubscriptionPurchaseService(subscriptionId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Subscription updated", "edit")
      queryClient.invalidateQueries({ queryKey: customerQueryKey.allSubscriptions })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export const useConfirmCustomerSubscriptionPurchaseMutation = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  let subscriptionID = ""

  return useMutation({
    mutationFn: subscriptionId => {
      subscriptionID = subscriptionId
      return confirmCustomerSubscriptionPurchaseService(subscriptionId)
    },

    onSuccess: () => {
      toast.success("Subscription confirmed", "confirmed")
      queryClient.invalidateQueries({ queryKey: analyticsQueryKeys.subscription() })
      queryClient.invalidateQueries({ queryKey: customerQueryKey.subscription(subscriptionID) })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


export { useCustomerSubscriptionQuery, useCreateCustomerSubscriptionMutation }
import {PriceCheckerLayoutStyled, PriceCheckerProductDetails} from "../../../styles/priceCheckerStyles";
import {Heading, Paragraph} from "../../../styles/textStyles";
import Barcode from "react-barcode";
import {DirectDown} from "iconsax-react";
import {Form, Image, Input} from "../../../components";
import {Logo, NoProductImage} from "../../../assets/images";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {AllCenterStyled, FlexColumnStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {useSellableProductByBusinessIdQuery} from "../../../hooks/useServices/useProductServices";
import {Fragment, useEffect, useRef, useState} from "react";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";

const handleLeavePriceCheckerMode = (e) => {
  if(e.key === "X" && e.shiftKey && e.ctrlKey) {
    window.location.replace("/dashboard")
  }
}

const PriceCheckerMode = () => {
  const { currencyFormatter } = useDataTypeFormatter()
  const { data: business } = useBusinessByIdQuery()
  const searchInputRef = useRef()
  const formikRef = useRef()
  const pageRef = useRef()
  
  const [barCodeSearchValue, setBarCodeSearchValue] = useState("")
  const { data: products, isSuccess: isProductSearchSuccess } = useSellableProductByBusinessIdQuery(barCodeSearchValue)
  
  useEffect(() => {
    searchInputRef?.current?.addEventListener("blur", function() {
      searchInputRef?.current?.focus()
    })
    
    window.addEventListener("keydown", handleLeavePriceCheckerMode)
  
    return () => {
      window.removeEventListener("keydown", handleLeavePriceCheckerMode)
    }
  }, [])
  
  
  useEffect(() => {
    searchInputRef?.current?.focus()
    // pageRef?.current?.requestFullscreen().then(() => {}).catch()
  }, [searchInputRef.current, pageRef])
  
  useEffect(() => {
    let timeoutID = ""
    
    if(products?.count === 1) {
      timeoutID = setTimeout(() => {
        setBarCodeSearchValue("")
        formikRef.current?.setFieldValue("product", "")
      }, 7000)
    }
  
    return () => {
      clearTimeout(timeoutID)
    }
  }, [products])
  
  return (
    <PriceCheckerLayoutStyled $isProduct={products?.count === 1} ref={pageRef}>
      <div className="img_div">
        <Image src={business?.image_path || Logo} alt="Ovaloop logo" />
        <Form values={{ product: "" }} enableReinitialize innerRef={formikRef}>
          <Input inputRef={searchInputRef} name="product" onChange={(value) => setBarCodeSearchValue(value)} />
        </Form>
      </div>
      
      <AllCenterStyled>
        {(!isProductSearchSuccess || products?.count !== 1) && (
          <Fragment>
            <Heading className="swinging-text" bold size={5.5} color="success">SCAN PRODUCT FOR PRICE</Heading>
    
            <Barcode height={90} fontSize={14} value="Scan Product" />
            <DirectDown size={70} color="black" />
          </Fragment>
        )}
  
        {(isProductSearchSuccess && products?.count === 1) && (
          <PriceCheckerProductDetails $isProductImage={products?.results?.[0]?.image_path}>
            <div className="product_img_div">
              {products?.results?.[0]?.image_path && (
                <Image src={products?.results?.[0]?.image_path || NoProductImage} alt={products?.results?.[0]?.name} />
              )}
            </div>
            <Heading bold size={2} color="black">{products?.results?.[0]?.name}</Heading>
  
            <div className="product_prices">
              <FlexColumnStyled>
                <SpaceBetweenStyled $gap={6} className="fullWidth">
                  <Paragraph bold color="black" size={3.5}>1 {products?.results?.[0]?.unit_measurement}: </Paragraph>
                  <Paragraph color="black" size={3.5}>{currencyFormatter(products?.results?.[0]?.selling_price)}</Paragraph>
                </SpaceBetweenStyled>
    
                {products?.results?.[0]?.meta_measurement?.map(measurement => (
                  <SpaceBetweenStyled key={measurement.name} $gap={6} className="fullWidth">
                    <Paragraph bold color="black" size={3.5}>1 {measurement.name}: </Paragraph>
                    <Paragraph color="black" size={3.5}>{currencyFormatter(measurement.selling_price)}</Paragraph>
                  </SpaceBetweenStyled>
                ))}
              </FlexColumnStyled>
            </div>
      
            {/*<Barcode height={90} fontSize={14} value="Scan Product" />*/}
            {/*<DirectDown size={70} color="black" />*/}
          </PriceCheckerProductDetails>
        )}
      </AllCenterStyled>
    </PriceCheckerLayoutStyled>
  )
}


export default PriceCheckerMode
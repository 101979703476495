export const activitiesActionFilters = [
  { name: "Create", value: "create" },
  { name: "Delete", value: "delete" },
  { name: "Update", value: "update" },
  { name: "Returned", value: "return" }
]

export const sortActionFilters = [
  { name: "Restock order level", id: "low_stock_level" },
  { name: "Stock Unit", id: "stock_unit_ascending" },
  { name: "Highest Stock Unit", id: "stock_unit_descending" },
  { name: "Lowest Selling Price", id: "selling_price_ascending" },
  { name: "Highest Selling Price", id: "selling_price_descending" },
  { name: "Lowest Cost Price", id: "cost_price_ascending" },
  { name: "Highest Cost Price", id: "cost_price_descending" }
]

export const inventoryActionFilters = [
  { name: "Pending", value: "pending" },
  { name: "Confirmed", value: "confirmed" },
  { name: "Rejected", value: "rejected" },
]

export const productionStageActionFilters = [
  { name: "In Progress", value: "in_progress" },
  { name: "Completed", value: "completed" },
  { name: "Cancelled", value: "cancelled" },
]

export const inventoryTypeFilters = [
  { name: "Transfer", value: "transfer" },
  { name: "Sales", value: "sales" },
  { name: "Increase", value: "increase" },
  { name: "Decrease", value: "decrease" },
]


export const requestMaterialsModifyLogStatus = (text1) => [
  { name: `Approve ${text1}`, value: "approve_request" },
  { name: `Modify Request`, value: "modify_and_approve_request" },
  { name: "Reject Request", value: "reject_request" },
]

export const requestMaterialsModifyLogStatusEnum = {
  approve_request: "confirmed",
  modify_and_approve_request: "confirmed",
  reject_request: "rejected"
}
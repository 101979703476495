import {StaffRolesPermissionCardStyled} from "../../styles/cardStyles";
import {Heading} from "../../styles/textStyles";
import {Fragment, useState} from "react";
import {CheckBox} from "../index";
import {useFormikContext} from "formik";
import RolesCheckBox from "../Forms/RolesCheckBox";


const StaffRolesPermissionCard = ({ permission }) => {
	const [checkedAll, setCheckedAll] = useState(false)
	const { setFieldValue, values } = useFormikContext()
	const { name, category: categories = [] } = permission

	const handleCheckAll = (value) => {
		categories.forEach(category => {
			setFieldValue(category.code, value)
		})
		setCheckedAll(value)
	}

	const isCategoryAllChecked = () => {
		return categories.every(permission => values[permission.code] === true)
	}

	const CategoryPermissions = ({ category }) => {
		return (
			<RolesCheckBox optional checked={checkedAll} labelProp={category.name} key={category.id} name={category.code} />
		)
	}

	return (
		<StaffRolesPermissionCardStyled>
			<CheckBox
				optional
				name={name}
				className="head"
				checked={isCategoryAllChecked()}
				afterCheck={handleCheckAll}
				labelProp={<Heading color="black" size={1.2}>{name}</Heading>}
			/>

			{categories.map(category => (
				<Fragment key={category.id}>
					<CategoryPermissions category={category} />
				</Fragment>
			))}
		</StaffRolesPermissionCardStyled>
	)
}

export default StaffRolesPermissionCard

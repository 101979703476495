import {Button} from "../index";
import {InfoCircle} from "iconsax-react";
import {FlexStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import {ConfirmationAlertBoxStyled, ConfirmationButtonStyled} from "../../styles/alertStyles";


const ConfirmationAlertBox = ({ text, closeToast, onConfirm }) => {
	const handleConfirm = () => {
		onConfirm()
		closeToast()
	}

	return (
		<ConfirmationAlertBoxStyled>
			<FlexStyled>
				<InfoCircle variant="Bold" color="#000000" size={17} />
				<Paragraph color="black" bold>Info</Paragraph>
			</FlexStyled>

			<div>
				<Heading color="black" size={1.1} className="message">{text}</Heading>
			</div>

			<ConfirmationButtonStyled>
				<Button text="CANCEL" className="cancel" onClick={closeToast} />
				<Button text="CONFIRM" onClick={handleConfirm} />
			</ConfirmationButtonStyled>
		</ConfirmationAlertBoxStyled>
	)
}

export default ConfirmationAlertBox
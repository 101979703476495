import {
  Button,
  CreateProductionRawMaterialModal,
  EditProductionRawMaterialModal,
  ExportProductModal,
  MoveProductsModal,
  NoDataLayout,
  PageHeader,
  PageSummaryCard,
  ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithImageAndActions,
  TableItemWithImageAndCheckBox,
  TablePagination,
  UploadBulkProductModal
} from "../../../components";
import {Fragment, useEffect, useState,} from "react";
import {useModal} from "../../../hooks";
import {EmptyBoxImage} from "../../../assets/images";
import {FlexStyled, PageSummaryStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useBulkDeleteProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useEditProductMutation,
  useExportAllProductMutation,
  useMoveProductService,
  useProductDistributionMutation,
  useProductQuery,
  useUploadBulkProductService
} from "../../../hooks/useServices/useProductServices";

import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {TableTopBarStyled} from "../../../styles/TableStyles";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {productFormDefaultValues} from "../../../data/defaultFormValues";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useCategoriesQuery} from "../../../hooks/useServices/useCategoryServices";
import {
  formatProductFormForEditing,
  formatProductFormForSubmission,
  handleEditProductAttachments
} from "../../../utils/formatForms/product";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {actionsPermissions, routesPermissions} from "../../../data/permissions";
import productQueryKey from "../../../hooks/useServices/useProductServices/productQueryKey";
import {useBusinessProductAnalyticsService} from "../../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import modalNames from "../../../hooks/useModalConnector/modalNames";
import {SlOptionsVertical} from "react-icons/sl"
import useProductContext from "../../../hooks/useContexts/useProductContext";
import DistributeMultipleProductModal
  from "../../../components/Modal/InventoryModal/ProductModals/DistributeMultipleProductModal";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {handleFormatCustomUnitMeasurementForFormEditing} from "../../../utils/products";
import {ListDropDownStyled, MenuDropDownStyled, MenuListStyled} from "../../../styles/listStyles";
import {Copy, ExportCircle, Shop, Trash} from "iconsax-react";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const RawMaterials = () => {
  const productContext = useProductContext()
  
  const actionPermission = useActionPermissionFn()
  const { navigate, toast } = useGlobalContext()
  const [editFormValues, setEditFormValues] = useState(productFormDefaultValues)
  
  const [isBulkDelete, setIsBulkDelete] = useModal()
  const [showExportProductModal, setShowExportProductModal] = useModal()
  const [isMultipleTransfer, setIsMultipleTransfer] = useModal()
  const [isPublishProductsToStoreFront, setIsPublishProductsToStoreFront] = useModal()
  const [showManageMultipleTransfer, setShowManageMultipleTransfer] = useModal()
  
  const [showCreateProductModal, setShowCreateProductModal] = useModal(modalNames.CREATE_RAW_MATERIAL_MODAL)
  const [showEditProductModal, setShowEditProductModal] = useModal(modalNames.EDIT_RAW_MATERIAL_MODAL)
  
  const [showUploadProductModal, setShowUploadProductModal] = useModal()
  const [showMoveProductModal, setShowMoveProductModal] = useModal()
  const { data: businessProductAnalytics, isLoading: isBusinessAnalyticsLoading, refetch: refetchBusinessAnalytics } = useBusinessProductAnalyticsService({ type: "raw_material" })
  const { currencyFormatterWithNoFraction, numberFormatter } = useDataTypeFormatter()
  
  const {
    createRawMaterial: createProductPermission,
    updateRawMaterial: updateProductPermission,
    deleteRawMaterial: deleteProductPermission,
  } = actionsPermissions
  
  const { rawMaterialsTable, productBulkDeleteTable } = tableHeadersAndValueKeys
  const { data: allCategories } = useCategoriesQuery()
  const { debounceState, debounceReducer } = useQueryDebounce()
  const query = { search: debounceState.searchValue, category_id: debounceState.filterValue, type: "raw_material" }
  const { mainQuery, ...allProducts } = useOrderQueryWithSearchAndFilter(query, useProductQuery)
  
  const handleCloseMultipleTransfer = () => {
    setShowManageMultipleTransfer()
    setIsMultipleTransfer()
  }
  
  const distributeProductMutation = useProductDistributionMutation({ successFn: handleCloseMultipleTransfer })
  const editProductMutation = useEditProductMutation({ successFn: setShowEditProductModal })
  const createProductMutation = useCreateProductMutation({ successFn: setShowCreateProductModal })
  const moveProductMutation = useMoveProductService({ successFn: setShowMoveProductModal })
  const uploadBulkProductMutation = useUploadBulkProductService({ successFn: setShowUploadProductModal })
  const deleteProductMutation = useDeleteProductMutation()
  
  const bulkDeleteSuccess = () => {
    setIsBulkDelete()
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
    })
  }
  
  useEffect(() => {
    return () => {
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
      })
      
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER
      })
      
      productContext.dispatch({
        type: productContext.actions.CLEAR_PRODUCT_FROM_PUBLISH_ORDER
      })
    }
  }, [])
  
  const bulkDeleteProductMutation = useBulkDeleteProductMutation({ successFn: bulkDeleteSuccess })
  const exportAllProductMutation = useExportAllProductMutation({ successFn: setShowExportProductModal })
  
  const handleCreateProduct = (values) => {
    const formattedValues = formatProductFormForSubmission(values)
    createProductMutation.mutate(formattedValues)
  }
  
  const handleEditProduct = (values) => {
    const newValues = { ...values }
    const formattedValues = formatProductFormForSubmission(values)
    
    const currentProject = allProducts.data.results.find(product => product.id === values.id)
    if(currentProject.image_path === newValues.image_path) {
      delete formattedValues.image_path
    }
    
    editProductMutation.mutate(formattedValues)
  }
  
  const handleEditAction = (product) => {
    const values = { ...product, prepare_time_format: product.prepare_time }
    
    const formattedValues = formatProductFormForEditing(values)
    const formattedValues2 = handleEditProductAttachments(formattedValues)
    const formattedValues3 = handleFormatCustomUnitMeasurementForFormEditing(formattedValues2, currencyFormatterWithNoFraction)
    
    setEditFormValues(formattedValues3)
    setShowEditProductModal()
  }
  
  const handleDeleteAction = (productId) => {
    toast.confirm(
      "Are you sure?",
      () => deleteProductMutation.mutate(productId),
      "delete"
    )
  }
  
  const handleUploadProduct = (type, values) => {
    uploadBulkProductMutation.mutate({ type, values })
  }
  
  const handleMoveProduct = (values) => {
    moveProductMutation.mutate(values)
  }
  
  const handleViewItem = (productId) => {
    navigate(`/dashboard/inventory/product/${productId}`)
  }
  
  const handleQueryKeyForPagination = () => {
    const query = {
      search: debounceState.searchValue,
      category_id: debounceState.filterValue,
      type: "raw_material",
      filter: debounceState.staffValue
    }
    return productQueryKey.byQueryParams(query)
  }
  
  const handleBulkDeleteSelect = (product) => {
    productContext.dispatch({
      type: productContext.actions.ADD_PRODUCT_TO_BULK_DELETE,
      itemId: product.id
    })
  }
  
  const handleMultipleTransferSelect = (product) => {
    productContext.dispatch({
      product,
      type: productContext.actions.ADD_PRODUCT_TO_MULTIPLE_TRANSFER,
    })
  }
  
  const handlePublishProductSelect = (product) => {
    productContext.dispatch({
      itemId: product.id,
      type: productContext.actions.ADD_PRODUCT_TO_PUBLISH_ORDER,
    })
  }
  
  const handleBulkDelete = (isDeleteAll) => {
    if(isDeleteAll) {
      toast.confirm(
        "Are you sure you want to delete all product in this business?",
        () => bulkDeleteProductMutation.mutate({ delete_all: true }),
        "delete all"
      )
      return
    }
    
    const product_list = productContext.state.bulkDelete
    toast.confirm(
      `Are you sure you want to ${product_list.length} delete selected products?`,
      () => bulkDeleteProductMutation.mutate({ delete_all: false, product_list }),
      "delete all"
    )
  }
  
  const handleMultipleTransfer = () => {
    setShowManageMultipleTransfer()
  }
  
  const handleCancelMultipleTransfer = () => {
    isMultipleTransfer && setIsMultipleTransfer()
    isBulkDelete && setIsBulkDelete()
    isPublishProductsToStoreFront && setIsPublishProductsToStoreFront()
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_MULTIPLE_TRANSFER
    })
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_BULK_DELETE
    })
    
    productContext.dispatch({
      type: productContext.actions.CLEAR_PRODUCT_FROM_PUBLISH_ORDER
    })
  }
  
  const handleProductDistribution = (values) => {
    distributeProductMutation.mutate({ multiple_data: values, multiple: true })
  }
  
  const refetchQueries = async () => {
    await refetchBusinessAnalytics()
    mainQuery.refetch()
  }
  
  const handleExportAllProductsToExcel = (query = {}) => {
    exportAllProductMutation.mutate(query)
  }
  
  return (
    <div>
      {showCreateProductModal && <CreateProductionRawMaterialModal onClose={setShowCreateProductModal} handleSubmit={handleCreateProduct} mutation={createProductMutation} />}
      {showEditProductModal && <EditProductionRawMaterialModal onClose={setShowEditProductModal} handleSubmit={handleEditProduct} mutation={editProductMutation} formValues={editFormValues} />}
      {showUploadProductModal && <UploadBulkProductModal onClose={setShowUploadProductModal} handleSubmit={handleUploadProduct} mutation={uploadBulkProductMutation} />}
      {showMoveProductModal && <MoveProductsModal onClose={setShowMoveProductModal} handleSubmit={handleMoveProduct} mutation={moveProductMutation} />}
      {showManageMultipleTransfer && <DistributeMultipleProductModal products={productContext.state.multipleTransfer} onClose={setShowManageMultipleTransfer} mutation={distributeProductMutation} handleSubmit={handleProductDistribution} />}
      {showExportProductModal && <ExportProductModal handleSubmit={handleExportAllProductsToExcel} mutation={exportAllProductMutation} onClose={setShowExportProductModal} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}
      
      <Fragment>
        {allProducts.isEmpty && (
          <Fragment>
            <PageHeader title="Raw Materials" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No product"
              imgSrc={EmptyBoxImage}
              heading="No raw materials yet"
              subHeading="You have no items yet, start updating your stock"
              buttonText="Add new raw material"
              button2Text="Upload Product"
              buttonClick={setShowCreateProductModal}
              button2Click={setShowUploadProductModal}
              permissionCode={createProductPermission}
            />
          </Fragment>
        )}
        
        {allProducts.showTable && (
          <Fragment>
            <PageHeader title="Raw Materials" />
            
            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={isBusinessAnalyticsLoading}
                only
                title="Total Number Of Products"
                value={numberFormatter(businessProductAnalytics?.number_of_product)}
                subText={actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && `Total stock units: ${businessProductAnalytics?.number_of_product_qty}`}
              />
  
              {actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (
                <PageSummaryCard
                  isLoading={isBusinessAnalyticsLoading}
                  title="Total cost in stock"
                  value={currencyFormatterWithNoFraction(businessProductAnalytics?.total_cost_price)}
                />
              )}
  
              {/*{actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (*/}
              {/*  <PageSummaryCard*/}
              {/*    isLoading={isBusinessAnalyticsLoading}*/}
              {/*    title="Total Stock Value"*/}
              {/*    value={currencyFormatterWithNoFraction(businessProductAnalytics?.total_selling_price)}*/}
              {/*  />*/}
              {/*)}*/}
  
              {/*{actionPermission.check(routesPermissions.viewProductSummaryAndAnalysis) && (*/}
              {/*  <PageSummaryCard*/}
              {/*    isLoading={isBusinessAnalyticsLoading}*/}
              {/*    title="Total Profit Value"*/}
              {/*    value={currencyFormatterWithNoFraction(businessProductAnalytics?.total_profit)}*/}
              {/*  />*/}
              {/*)}*/}
            </PageSummaryStyled>
            
            <SetUpLayoutStyled>
              
              <TableTopBarStyled>
                <SearchAndFilter
                  valueKey="id"
                  displayKey="name"
                  dispatch={debounceReducer}
                  filterOptions={allCategories?.results || []}
                  filterPlaceholder="Filter by category"
                  searchPlaceholder="Search by product name or SKU"
                />
                
                <FlexStyled>
                  {(!isBulkDelete && !isMultipleTransfer && !isPublishProductsToStoreFront) && (
                    <Fragment>
                      <Button
                        text="Create"
                        onClick={setShowCreateProductModal}
                        permissionCode={createProductPermission}
                      />
                      {/*<Button*/}
                      {/*  text="Delete"*/}
                      {/*  onClick={setIsBulkDelete}*/}
                      {/*  permissionCode={createProductPermission}*/}
                      {/*/>*/}
                    </Fragment>
                  )}
                  
                  {isBulkDelete && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.bulkDelete.length} Selected</Paragraph>
                      
                      <Button
                        text="Delete Selected"
                        disabled={!productContext.state.isItemToBulkDelete}
                        onClick={() => handleBulkDelete(false)}
                        permissionCode={createProductPermission}
                      />
                      
                      <Button
                        text="Delete All"
                        disabled={productContext.state.isItemToBulkDelete}
                        onClick={() => handleBulkDelete(true)}
                        permissionCode={createProductPermission}
                      />
                      
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
                  
                  {isMultipleTransfer && (
                    <Fragment>
                      <Paragraph color="black">{productContext.state.multipleTransfer.length} Selected</Paragraph>
                      <Button
                        text="Transfer Selected"
                        disabled={!productContext.state.isMultipleTransfer}
                        onClick={handleMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                      <Button
                        bgColor="black"
                        text="Cancel"
                        onClick={handleCancelMultipleTransfer}
                        permissionCode={createProductPermission}
                      />
                    </Fragment>
                  )}
                  
                  {(!isMultipleTransfer && !isBulkDelete && !isPublishProductsToStoreFront && false) && (
                    <MenuDropDownStyled>
                      <SlOptionsVertical size={25} color="black" />
                      
                      <div className="container">
                        <ListDropDownStyled>
                          <MenuListStyled onClick={setShowExportProductModal}>
                            <ExportCircle size={20} color="black" />
                            <Heading size={1.1} color="black">Export Products</Heading>
                          </MenuListStyled>
                          <MenuListStyled onClick={setIsBulkDelete}>
                            <Trash size={20} color="black" />
                            <Heading size={1.1} color="black">Delete Products</Heading>
                          </MenuListStyled>
                          <MenuListStyled onClick={setShowMoveProductModal}>
                            <Copy size={20} color="black" />
                            <Heading size={1.1} color="black">Duplicate Products</Heading>
                          </MenuListStyled>
                          <MenuListStyled onClick={setIsPublishProductsToStoreFront}>
                            <Shop size={20} color="black"/>
                            <Heading size={1.1} color="black">Publish Products</Heading>
                          </MenuListStyled>
                        </ListDropDownStyled>
                      </div>
                    </MenuDropDownStyled>
                  )}
                </FlexStyled>
              </TableTopBarStyled>
              
              {(!isBulkDelete && !isMultipleTransfer && !isPublishProductsToStoreFront) && (
                <Table bg headers={rawMaterialsTable.headers} noCols={rawMaterialsTable.headers.length}>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndActions
                          key={k}
                          data={items}
                          count={k}
                          pageCount={allProducts.currentCount}
                          keys={rawMaterialsTable.values}
                          onClick={handleViewItem}
                          editAction={handleEditAction}
                          deleteAction={handleDeleteAction}
                          editPermission={updateProductPermission}
                          deletePermission={deleteProductPermission}
                        />
                      )
                    )}
                  </Fragment>
                  
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
                  
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
              
              
              {isBulkDelete && (
                <Table bg headers={productBulkDeleteTable.headers} noCols={productBulkDeleteTable.headers.length} noCount equalWidth>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndCheckBox
                          key={k}
                          data={items}
                          keys={productBulkDeleteTable.values}
                          handleCheck={handleBulkDeleteSelect}
                          checked={productContext.state.bulkDelete.find(item => item === items.id)}
                        />
                      )
                    )}
                  </Fragment>
                  
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
                  
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
              
              
              {isMultipleTransfer && (
                <Table bg headers={productBulkDeleteTable.headers} noCols={productBulkDeleteTable.headers.length} noCount equalWidth>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndCheckBox
                          key={k}
                          data={items}
                          keys={productBulkDeleteTable.values}
                          handleCheck={handleMultipleTransferSelect}
                          checked={productContext.state.multipleTransfer.find(item => item.id === items.id)}
                        />
                      )
                    )}
                  </Fragment>
                  
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
                  
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
              
              {isPublishProductsToStoreFront && (
                <Table bg headers={productBulkDeleteTable.headers} noCols={productBulkDeleteTable.headers.length} noCount equalWidth>
                  <Fragment>
                    {allProducts.isQueryWithData && (
                      allProducts.data.results.map((items, k) =>
                        <TableItemWithImageAndCheckBox
                          key={k}
                          data={items}
                          keys={productBulkDeleteTable.values}
                          handleCheck={handlePublishProductSelect}
                          checked={productContext.state.publishProducts.find(item => item.id === items.id)}
                        />
                      )
                    )}
                  </Fragment>
                  
                  {allProducts.isQueryWithNoData && (
                    <NoQueryData text={allProducts?.emptyMessage("Product")} />
                  )}
                  
                  {allProducts.isQueryLoading && (
                    <TableSkeleton />
                  )}
                </Table>
              )}
              
              
              {allProducts.showPagination && (
                <TablePagination
                  query={allProducts}
                  queryKey={handleQueryKeyForPagination()}
                />
              )}
            </SetUpLayoutStyled>
            {allProducts.showPagination && (
              <TablePagination
                query={allProducts}
                queryKey={handleQueryKeyForPagination()}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default RawMaterials;
import {Fragment} from "react";
import {useFormikContext} from "formik";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading} from "../../styles/textStyles";
import {PaymentMethodStyled, PaymentMethodOptionStyled} from "../../styles/formStyles";
import { MdOutlineFiberNew } from "react-icons/md"


const PaymentMethod = ({ methods = [], name, label, setPaymentMethod }) => {
	const { values, setFieldValue } = useFormikContext()

	const handleSelectPaymentMethod = (data) => {
		delete values.banks
		delete values.display_bank
		delete values.split_payment

		setFieldValue(name, data.value)
		setPaymentMethod(data.title)
	}

	const handleValidity = (e) => {
		if(e.target.value.length < 1) {
			e.target.setCustomValidity('Select payment method')
		} else {
			e.target.setCustomValidity("")
		}
	}

	return (
		<Fragment>
			<SpaceBetweenStyled>
				<Heading color="secondary" size={1.05}>{label}:</Heading>
			</SpaceBetweenStyled>
			<PaymentMethodStyled>

				<input
					value={values?.[name]}
					type="text"
					required
					onChange={() => {}}
					onInvalid={handleValidity}
				/>

				{methods.map(({ Icon, ...restData }) => (
					<PaymentMethodOptionStyled key={restData.value} onClick={handleSelectPaymentMethod.bind(this, restData)} $active={values?.[name] === restData.value}>
						{restData.isNew && <MdOutlineFiberNew className="new_flag" color="red" size={33} />}
						<Icon size={25} />
						<Heading color="black">{restData.title}</Heading>
					</PaymentMethodOptionStyled>
				))}
			</PaymentMethodStyled>
		</Fragment>
	)
}

export default PaymentMethod
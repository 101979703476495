import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import {FlexStyled} from "../../../styles/utilStyles";
import {NoDataLayout, PageHeader, ScreenLoading, TableCard, TablePageDrawer} from "../../../components";
import {TablePageLayoutStyled} from "../../../styles/tablePageStyles";
import {useTableQuery} from "../../../hooks/useServices/useTableServices";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import {EmptyBoxImage} from "../../../assets/images";


const TablePage = () => {
	const { debounceState } = useQueryDebounce()
	const { mainQuery, ...allTables } = useSearchQuery(debounceState, useTableQuery)
	const [showTableDrawer, setShowTableDrawer] = useModal()
	const [activeTableDataForDrawer, setActiveTableDataForDrawer] = useState({})

	const handleSelectTableForDrawer = (table) => {
		setActiveTableDataForDrawer(table)
		setShowTableDrawer()
	}
	
	return (
		<Fragment>
			<PageHeader title="Tables" />
			
			{!mainQuery.isSuccess && (
				<ScreenLoading isLoading={mainQuery.isLoading} isError={mainQuery.isError} refetch={mainQuery.refetch}/>
			)}
			
			{mainQuery.isSuccess && (
				<Fragment>
					{showTableDrawer && <TablePageDrawer tableData={activeTableDataForDrawer} onClose={setShowTableDrawer} />}
					
					
					{allTables.isEmpty && (
						<Fragment>
							<NoDataLayout
								imgWidth={45}
								imgAlt="No orders"
								imgSrc={EmptyBoxImage}
								heading="No table orders yet"
							/>
						</Fragment>
					)}
					
					<TablePageLayoutStyled>
						
						{!allTables.isEmpty && (
							<Fragment>
								<FlexStyled>
									{allTables?.data?.results?.map(item => (
										<TableCard openDrawer={handleSelectTableForDrawer} tableData={item} key={item.id} />
									))}
								</FlexStyled>
							</Fragment>
						)}
					</TablePageLayoutStyled>
				</Fragment>
			)}
		</Fragment>
	)
}

export default TablePage
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createExpenseCategoryService, deleteExpenseCategoryService,
  editExpenseCategoryService,
  getExpenseCategoryService
} from "../../../services/expenseCategoryServices";
import {getBusinessIdFromStorage, scrollToTop} from "../../../utils";
import errorHandler from "../../../utils/errorHandler";
import useGlobalContext from "../../useContexts/useGlobalContext";


const mainKey = ["Expense Category", getBusinessIdFromStorage()]

export const expenseCategoryQueryKeys = {
  main: [...mainKey],
  mainList: (search = "") => [...mainKey, search]
}

const useExpenseCategoryQuery = (search) => {
  scrollToTop()
  const { getBusinessId } = useGlobalContext()
  

  return useQuery({
    queryKey: expenseCategoryQueryKeys.mainList(search),
    queryFn: () => getExpenseCategoryService({ business_id: getBusinessId(), search })
  })
}


const useCreateExpenseCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return createExpenseCategoryService({ ...data, business: getBusinessId() })
    },

    onSuccess: () => {
      successFn()
      toast.success("Expense Category Created!!")
      queryClient.invalidateQueries({ queryKey: expenseCategoryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useEditExpenseCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editExpenseCategoryService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Edit Successful!!")
      queryClient.invalidateQueries({ queryKey: expenseCategoryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useDeleteExpenseCategoryMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: discountId => {
      return deleteExpenseCategoryService(discountId)
    },

    onSuccess: () => {
      successFn()
      toast.success("Delete Successful!!")
      queryClient.invalidateQueries({ queryKey: expenseCategoryQueryKeys.main })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useExpenseCategoryQuery, useCreateExpenseCategoryMutation, useEditExpenseCategoryMutation, useDeleteExpenseCategoryMutation }
import {CloseSquare} from "iconsax-react";
import { Button, Form, Input } from "../../index";
import { Heading } from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import modalNames from "../../../hooks/useModalConnector/modalNames";


const EditProductionUnitMeasurementModal = ({ onClose, handleSubmit, mutation, formValues }) => {
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Edit Unit Measurement</Heading>
        <Form values={formValues} modalName={modalNames.EDIT_PRODUCTION_UNIT_MEASUREMENT_MODAL} onSubmit={handleSubmit}>
          <Input label="Unit Measurement Name" placeholder="Enter unit measurement name" name="name"  />
          
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateCategoryModalStyled>
    </ModalLayoutStyled>
  )
}

export default EditProductionUnitMeasurementModal
import styled from "styled-components";
import {FlexStyled, ImageStyled, LineStyled, SectionStyled, SpaceBetweenStyled} from "./utilStyles";
import {Heading, Paragraph} from "./textStyles";
import {TableComponentStyled} from "./TableStyles";
import {ButtonStyled} from "./formStyles";
import {OrderDetailsCardStyled} from "./cardStyles";

const SingleOrderLayoutStyled = styled(SpaceBetweenStyled)`
  align-items: flex-start;
  margin-top: 2rem;
  
  .main {
    padding: 1rem 0;
    width: calc(74% - 2rem);

    ${SpaceBetweenStyled}.order_staff_bank {
      align-items: flex-start;
    }
    
    @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: calc(66% - 1rem);
      
      ${SpaceBetweenStyled}.order_staff_bank {
        gap: 1rem;
  
        ${OrderDetailsCardStyled}:first-of-type {
          width: 100%;
        }
        
        ${OrderDetailsCardStyled} {
          width: 42%;
        }
      }
    }
  }
  
  .side {
    padding: 1rem 0;
    width: 26%;
    
    @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
      width: 33%
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    flex-direction: column;
    
    .main, .side {
      width: 100%;
    }
  }
  
  .subscription_card_details {
    width: 100%;
    align-items: flex-start !important;
    
    ${OrderDetailsCardStyled} {
      width: calc(23% - 1rem);
    }
  }
`

const CustomerDataStyled = styled("div")`
  width: calc(100% - 1.6rem);
  padding: 1.5rem .8rem 2rem;
  margin-bottom: 1.5rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.inputBGDark};
  
  >${Heading} {
    margin-bottom: 1.8rem;
  }
 
  > div.details {
    margin-top: 1rem;
    
    ${LineStyled} {
      margin-top: 1rem;
    }
  }
  
  
  // SUMMARY SECTION
  ${SpaceBetweenStyled} {
    padding: 0 0 1.5rem;
  }

  ${SpaceBetweenStyled}.total {
    padding-top: .8rem;
  }
  
  div {
    ${SpaceBetweenStyled} {
      padding-bottom: 0;
    }
  }
`

const SingleOrderHeaderStyled = styled(SpaceBetweenStyled)`
  align-items: center;
  
  svg {
    cursor: pointer;
  }
  
  ${FlexStyled}.navigation_section {
    svg.arrow {
      padding: .4rem;
      border-radius: .3rem;
      border: 1px solid ${props => props.theme.menuGray};
    }

    > ${FlexStyled} {
      gap: 0;
      align-items: flex-start;
      flex-direction: column;
      
      ${FlexStyled}.id_and_status {
        gap: 1.5rem;
        
        ${ImageStyled} {
          margin-bottom: 0;
        }
  
        ${Heading} {
          font-size: 1.15rem !important;
          span {
            color: ${props => props.theme.menuGray};
          }
        }
        
        ${Paragraph} {
          gap: .5rem;
          display: flex;
          margin-top: -.3rem;
          font-size: 1.05rem !important;
          font-family: Medium, serif;
          align-items: center;
        }
      }
    }
  }
  
  ${FlexStyled}.action_buttons {
    width: 77%;
    justify-content: flex-end;

    ${ButtonStyled} {
      font-size: .8rem;
      padding: .5rem;
      max-width: 16%;
      
      svg {
        font-size: .5rem !important;
      }
    }
  }
  
  @media screen and (min-width: ${props => props.theme.mobileBreakPoint}) and (max-width: ${props => props.theme.tabletBreakPoint}) {
    ${FlexStyled}.action_buttons {
      width: 65%;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    gap: 1.5rem;
    ${FlexStyled}.action_buttons {
      width: 100%;
      //margin-bottom: 1rem;
      justify-content: flex-start;

      ${ButtonStyled} {
        width: calc(100% / 3 - 1rem) !important;
      }
    }
  }
`

const OrdersTableStyled = styled(SectionStyled)`
  width: calc(100% - 2rem);
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.inputBGDark};
  
  ${TableComponentStyled} {
    margin-top: ${props => props.$noPadding ? 0 : "2rem"};
    padding: 0;
    width: 100% !important;
    background-color: transparent;
    
    th {
      ${Heading} {
        font-size: .9rem !important;
      }
    }
  }
  
  ${SpaceBetweenStyled}.product_expiry {
    ${ButtonStyled} {
      padding: .8rem !important;
      min-width: 12%;
      //min-width: 150px;
      max-width: 200px;
      // background: ${props => props.theme.primary} !important;
    }
    
    ${FlexStyled} {
      width: 60%;
      justify-content: flex-end;
    }
  }
`

export {
  CustomerDataStyled,
  OrdersTableStyled,
  SingleOrderLayoutStyled,
  SingleOrderHeaderStyled
}
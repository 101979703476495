import {useMutation, useQuery, useQueryClient} from "react-query";
import {
  createExpenseService, editExpenseService, getExpenseCategoryService,
  getExpenseService
} from "../../../services/expenseServices";
import {scrollToTop} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";
import expensesQueryKey from "./expensesQueryKey";
import errorHandler from "../../../utils/errorHandler";


const useExpenseCategoryQuery = () => {
  return useQuery({
    queryKey: expensesQueryKey.category,
    queryFn: getExpenseCategoryService
  })
}

const useExpenseQuery = (queryParams = {}) => {
  scrollToTop()
  const { getBusinessId } = useGlobalContext()
  const business_id = getBusinessId()

  return useQuery({
    queryKey: expensesQueryKey.mainList(queryParams),
    queryFn: () => getExpenseService({ business_id, ...queryParams })
  })
}

const useCreateExpenseMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast, getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()

  return useMutation({
    mutationFn: data => {
      return createExpenseService({ ...data, business: businessId })
    },

    onSuccess: () => {
      successFn()
      toast.success("Expense Created!!")
      queryClient.invalidateQueries({ queryKey: expensesQueryKey.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


const useUpdateExpenseMutation = ({ successFn }) => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: data => {
      return editExpenseService(data.id, data)
    },

    onSuccess: () => {
      successFn()
      toast.success("Expense Update!!")
      queryClient.invalidateQueries({ queryKey: expensesQueryKey.all })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useExpenseCategoryQuery, useExpenseQuery, useCreateExpenseMutation, useUpdateExpenseMutation }
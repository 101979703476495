import {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Field, useFormikContext} from "formik";
import {Heading, Paragraph} from "../../styles/textStyles";
import {ArrowDown2, ArrowUp2, CloseCircle} from "iconsax-react";
import {searchCountryData} from "../../utils/county";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {SelectDropDownStyled, SelectStyled} from "../../styles/formStyles";
import {isPropAvailableAndAString, isSelectedOptionAvailableInOptionsData} from "../../utils/forms";
import {FormProvider} from "./Form";


const Select = (
  {
    className,
    label,
    placeholder,
    name,
    displayName,
    options: selectOptions = [],
    disabled,
    isSearchLoading,
    noBottomMargin,
    id,
    displayKey,
    valueKey,
    updateFn,
    clearCondition,
    optional,
    SideInfo,
    bgDark,
    clearFilter,
    updateQueryResult,
    updateQueryResultName,
    uniqueKey, error, handleFocus
  }
) => {

  const { allFieldsNotRequired } = useContext(FormProvider)
  const options = selectOptions
  const initialMountRef = useRef(false)
  const { values, setFieldValue } = useFormikContext()
  
  const [showModal, setShowModal] = useState(false)
  const [optionSearchData, setOptionSearchData] = useState(selectOptions)
  
  const handleClearFilter = () => {
    updateFn?.({}, values)
    setFieldValue(name, "")
    setFieldValue(displayName, "")
  }
  
  useEffect(() => {
    if (!!selectOptions && selectOptions.length > 0) {
      setOptionSearchData(selectOptions)
    }
  }, [selectOptions])
  
  useEffect(() => {
    // do not clear the states when the component mounts the first time
    // This is to avoid the states from clearing when the component mounts with already populated values i.e. Edit Form
    if (!!clearCondition && !initialMountRef.current) {
      setFieldValue(displayName, "")
      setFieldValue(name, "")
    }
    
    if (initialMountRef.current) {
      initialMountRef.current = false
    }
  }, [values?.[clearCondition]])
  
  const isRequired = () => {
    return !(allFieldsNotRequired ?? optional);
  }
  
  const toggle = (text) => {
    if (disabled) return
    
    if (showModal) {
      const data = isSelectedOptionAvailableInOptionsData(options, (isPropAvailableAndAString(text) || values[displayName]), displayKey)
      
      if (!!data) {
        setFieldValue(name, data[valueKey])
        updateFn && updateFn(data, values, name)
      } else {
        setFieldValue(name, "")
        setFieldValue(displayName, "")
      }
      
      updateQueryResult && updateQueryResult("")
    }

    setOptionSearchData(options)
    setShowModal(!showModal)
  }
  const handleOpen = () => {
    setShowModal(true)
    handleFocus?.()
  }

  const handleSearch = (e) => {
    const text = e.target.value
    setFieldValue(displayName, text)

    // modify query by adding a search param to the endpoint
    updateQueryResult && updateQueryResult(text, updateQueryResultName || name)

    if(text.length < 1) {
      setOptionSearchData(options)
      updateQueryResult && updateQueryResult("")
    } else {
      setOptionSearchData(searchCountryData(options, text, displayKey, valueKey))
    }
  }

  const handleSelect = (item) => {
    setFieldValue(displayName, item)
    toggle(item)
  }
  
  const handleBlur = (e) => {
    setTimeout(() => {
      toggle(e.target.defaultValue)
    }, 200)
  }

  const SelectDropDown = () => {
    const { values } = useFormikContext()
    
    return (
      <SelectDropDownStyled onClick={e => e.stopPropagation()} $isLabel={!!label}>
        <ul>
          {isSearchLoading && (
            <li className="disabled"><Heading color="black">Searching...</Heading></li>
          )}
  
          {!isSearchLoading && (
            <Fragment>
              {selectOptions.length < 1 && (
                <li className="disabled"><Heading color="black">No options</Heading></li>
              )}
  
              {selectOptions.length > 0 && optionSearchData.length < 1 && (
                <li className="disabled"><Heading color="black">No option with "{values[displayName]}"</Heading></li>
              )}
  
              {optionSearchData?.map((item) => (
                <li key={item[uniqueKey || displayKey || "name"]} onClick={() => handleSelect(item[displayKey])}>
                  <Heading color="black">{item[displayKey]}</Heading></li>
              ))}
            </Fragment>
          )}
         </ul>
      </SelectDropDownStyled>
    )
  }

  return (
    <SelectStyled className={className} bgDark={bgDark || disabled} $isLabel={!!label} $noBottomMargin={noBottomMargin} id={id}>
      <SpaceBetweenStyled>
        <label htmlFor={name}>
          {label} {!!optional && "(Optional)"}
        </label>
        {!!SideInfo && <SideInfo />}
      </SpaceBetweenStyled>
  
      {!disabled && (
        <i className="select">
          {(clearFilter && !!values[name]) && <CloseCircle size={16} color="black" onClick={handleClearFilter} className="clear_filter_icon" />}
          {showModal ? <ArrowUp2 size={20} onClick={toggle} /> : <ArrowDown2 size={20} onClick={toggle} />}
        </i>
      )}


      <Field
        disabled={disabled}
        required={isRequired()}
        value={values?.[displayName]}
        onFocus={handleOpen}
        onChange={handleSearch}
        id={name}
        name={name}
        onBlur={handleBlur}
        placeholder={placeholder}
      />

      {!!error && <Paragraph noTop color="warning">{error}</Paragraph>}
      {showModal && <SelectDropDown />}
    </SelectStyled>
  )
}

export default Select
import {
  NoDataLayout,
  PageHeader,
  PageSummaryCard,
  ScreenLoading,
  Table,
  TableItemWithStatus,
  TablePagination,
  RequestRawMaterialLogModal,
  PageHeaderWithButtonAndDateFilter,
  Form,
  Select,
  SearchField, CreateRequestRawMaterialModal, StartProductionProcessModal
} from "../../../components";
import {Fragment, memo, useState} from "react";
import { NoLogsImage } from "../../../assets/images";
import {SetUpLayoutStyled} from "../setUp/SetUpStyles";
import {PageSummaryStyled, SearchAndFilterStyled} from "../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useCreateProductionRequestRawMaterialMutation,
  useProductionRequestRawMaterialQuery,
  useUpdateProductionRequestRawMaterialMutation
} from "../../../hooks/useServices/useProductionServices/useProductionRawMaterialServices"
import useQueryDebounce, {debounceActions} from "../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import inventoryQueryKey from "../../../hooks/useServices/useInventoryServices/inventoryQueryKey";
import {useModal} from "../../../hooks";
import {inventoryFormDefaultValues} from "../../../data/defaultFormValues";
import {dateFilterValue, dateFilterValueEnum} from "../../../data/selectField/dateFilter";
import handleDateFilterValue from "../../../utils/handleDateFilterValue";
import {useStaffByBusinessIdQuery} from "../../../hooks/useServices/useStaffServices";
import {inventoryActionFilters, inventoryTypeFilters} from "../../../data/selectField/actionFilters";
import {SearchNormal1} from "iconsax-react";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {useStartProductionMutation} from "../../../hooks/useServices/useProductionServices/useProductionService";
import {actionsPermissions} from "../../../data/permissions";


const RequestRawMaterial = () => {
  const { requestRawMaterialsLogsTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const [showRequestRawMaterialModal, setShowRequestRawMaterialModal] = useModal()
  const [showUpdateRequestMaterialModal, setShowUpdateRequestMaterialModal] = useModal()
  const [editFormValues, setEditFormValues] = useState(inventoryFormDefaultValues)
  const [showStartProductionModal, setShowStartProductionModal] = useModal()
  const [productionModalValues, setProductionModalValues] = useState({})
  
  
  const {
    requestRawMaterial: requestRawMaterialPermission
  } = actionsPermissions
  
  const { fromDate, toDate } = handleDateFilterValue(dateFilterValue.THIS_YEAR)
  const [dateFilterObj, setDateFilterObj] = useState({ from_date: fromDate, to_date: toDate })
  
  const query = {
    ...dateFilterObj,
    status: debounceState.filterValue,
    search: debounceState.searchValue,
    performed_by: debounceState.staffValue,
    filter: debounceState.customerValue
  }
  
  const { mainQuery, ...allRequestMaterialLogs } = useOrderQueryWithSearchAndFilter(query, useProductionRequestRawMaterialQuery)
  
  const createRawMaterialMutation = useCreateProductionRequestRawMaterialMutation({ successFn: setShowRequestRawMaterialModal })
  const updateRawMaterialRequestMutation = useUpdateProductionRequestRawMaterialMutation({ successFn: setShowUpdateRequestMaterialModal })
  const startProductionMutation = useStartProductionMutation({ successFn: setShowStartProductionModal })
  
  const handleCreateRequest = (values) => {
    createRawMaterialMutation.mutate(values)
  }
  
  const handleUpdateRawMaterialRequest = (values) => {
    updateRawMaterialRequestMutation.mutate(values)
  }
  
  const handleAction = (data) => {
    setEditFormValues(data)
    setShowUpdateRequestMaterialModal()
  }
  
  const setStartProductionForProduct = (product) => {
    setProductionModalValues(product)
    setShowUpdateRequestMaterialModal()
    setShowStartProductionModal()
  }
  
  const handleStartProductionForProduct = (values) => {
    startProductionMutation.mutate(values)
  }
  
  return (
    <div>
      {showRequestRawMaterialModal && <CreateRequestRawMaterialModal handleSubmit={handleCreateRequest} mutation={createRawMaterialMutation} onClose={setShowRequestRawMaterialModal} />}
      {showUpdateRequestMaterialModal && <RequestRawMaterialLogModal onClose={setShowUpdateRequestMaterialModal} formValues={editFormValues} handleSubmit={handleUpdateRawMaterialRequest} mutation={updateRawMaterialRequestMutation} handleStartProduction={setStartProductionForProduct} />}
      {showStartProductionModal && <StartProductionProcessModal onClose={setShowStartProductionModal} formValues={productionModalValues} mutation={startProductionMutation} handleSubmit={handleStartProductionForProduct} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allRequestMaterialLogs.refetch} />
      )}
      
      <Fragment>
        {allRequestMaterialLogs.isEmpty && (
          <Fragment>
            <PageHeader title="Request Material" />
            
            <NoDataLayout
              permissionCode={requestRawMaterialPermission}
              buttonText="Request Material"
              imgWidth={45}
              imgAlt="No history"
              imgSrc={NoLogsImage}
              heading="No logs yet"
              subHeading="Request for  raw material to see your logs"
            />
          </Fragment>
        )}

        {allRequestMaterialLogs.showTable && (
          <Fragment>
            <PageHeaderWithButtonAndDateFilter
              permissionCode={requestRawMaterialPermission}
              placeholder="Filter by date"
              title="Request Raw Materials"
              setDateFilter={setDateFilterObj}
              buttonText="Request"
              buttonClick={setShowRequestRawMaterialModal}
              dateFilterDefaultValue={dateFilterValueEnum.THIS_YEAR}
            />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allRequestMaterialLogs.isQueryLoading}
                only
                title="Total Number Of Requests"
                value={allRequestMaterialLogs?.data?.count} />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <FilterOptions dispatch={debounceReducer} />
              
              <Table bg headers={requestRawMaterialsLogsTable.headers} noCols={requestRawMaterialsLogsTable.headers.length}>
                {allRequestMaterialLogs.isQueryWithData && (
                  allRequestMaterialLogs?.data?.results.map((items, k) =>
                    <TableItemWithStatus
                      count={k}
                      pageCount={allRequestMaterialLogs.currentCount}
                      key={k}
                      data={items}
                      statusPosition={3}
                      keys={requestRawMaterialsLogsTable.values}
                      onView={handleAction}
                    />
                  )
                )}

                {allRequestMaterialLogs.isQueryWithNoData && (
                  <NoQueryData text={allRequestMaterialLogs.emptyMessage("Request Material Log")} />
                )}

                {allRequestMaterialLogs.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allRequestMaterialLogs.showPagination &&
                <TablePagination
                  query={allRequestMaterialLogs}
                  queryKey={inventoryQueryKey.mainList(query)}
                />
              }

            </SetUpLayoutStyled>
            
            {allRequestMaterialLogs.showPagination &&
              <TablePagination
                query={allRequestMaterialLogs}
                queryKey={inventoryQueryKey.mainList(query)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default RequestRawMaterial;



const FilterOptions = memo(({ dispatch }) => {
  const [staffSearch, setStaffSearch] = useState("")
  const { data: allStaffs } = useStaffByBusinessIdQuery(staffSearch)
  
  const handleStaffFilter = (staff) => {
    dispatch({
      staffValue: staff?.id || "",
      type: debounceActions.SET_FILTER_BY_STAFF_FIELD
    })
  }
  
  const handleActivityActionFilter = (action) => {
    dispatch({
      filterValue: action?.value || "",
      type: debounceActions.SET_FILTER_FIELD
    })
  }
  
  const handleActivityTypeFilter = (action) => {
    dispatch({
      customerValue: action?.value || "",
      type: debounceActions.SET_FILTER_BY_CUSTOMER_FIELD
    })
  }
  
  const handleSearch = (value) => {
    dispatch({
      searchValue: value,
      type: debounceActions.SET_SEARCH_FIELD
    })
  }
  
  return (
    <SearchAndFilterStyled>
      <Form values={{ action: "", filter: "", to_date: "", from_date: "", staff: "", search: "", type: "" }}>
        <SearchField
          label="Search"
          bgDark
          name="search"
          type="search"
          className="search_field"
          placeholder="Search log by remark, product.."
          setSearchValueState={handleSearch}
          rightIcon={<SearchNormal1 size={15} />}
        />
        
        <Select bgDark
          clearFilter
          options={allStaffs?.results}
          displayKey="full_name"
          valueKey="id"
          label="Filter by staff"
          placeholder="Filter log by staff"
          name="staff"
          displayName="staff_name"
          updateFn={handleStaffFilter}
          updateQueryResult={setStaffSearch}
        />
        <Select bgDark
          clearFilter
          options={inventoryActionFilters}
          displayKey="name"
          valueKey="value"
          label="Filter by status"
          placeholder="Filter log by status"
          name="action"
          displayName="name"
          updateFn={handleActivityActionFilter}
        />
  
        <Select bgDark
          clearFilter
          options={inventoryTypeFilters}
          displayKey="name"
          valueKey="value"
          label="Filter by log type"
          placeholder="Filter log by type"
          name="type"
          displayName="type_name"
          updateFn={handleActivityTypeFilter}
        />
        
      </Form>
    </SearchAndFilterStyled>
  )
})
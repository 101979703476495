import {
  DepleteFaultyProductModal,
  Form,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButtonAndDateFilter,
  PageSummaryCard,
  ScreenLoading,
  SearchField,
  Select,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../../components";
import {Fragment, memo, useState} from "react";
import {NoLogsImage} from "../../../../assets/images";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {PageSummaryStyled, SearchAndFilterStyled} from "../../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {
  useFaultyProductLogByBusinessIdQuery,
  useUpdateFacultyProductLogMutation,
} from "../../../../hooks/useServices/useInventoryServices";
import useQueryDebounce, {debounceActions} from "../../../../hooks/useUtils/useQueryDebounce";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import inventoryQueryKey from "../../../../hooks/useServices/useInventoryServices/inventoryQueryKey";
import {useModal} from "../../../../hooks";
import {inventoryFormDefaultValues} from "../../../../data/defaultFormValues";
import {dateFilterValue, dateFilterValueEnum} from "../../../../data/selectField/dateFilter";
import handleDateFilterValue from "../../../../utils/handleDateFilterValue";
import {useStaffByBusinessIdQuery} from "../../../../hooks/useServices/useStaffServices";
import {inventoryActionFilters} from "../../../../data/selectField/actionFilters";
import {SearchNormal1} from "iconsax-react";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";


const FaultyProductLogs = () => {
  const {inventoryTable} = tableHeadersAndValueKeys
  const {debounceState, debounceReducer} = useQueryDebounce()
  const [showEditInventoryModal, setShowEditInventoryModal] = useModal()
  const [editFormValues, setEditFormValues] = useState(inventoryFormDefaultValues)
  
  const {fromDate, toDate} = handleDateFilterValue(dateFilterValue.THIS_YEAR)
  const [dateFilterObj, setDateFilterObj] = useState({from_date: fromDate, to_date: toDate})
  
  const query = {
    is_faulty: true,
    ...dateFilterObj,
    status: debounceState.filterValue,
    search: debounceState.searchValue,
    performed_by: debounceState.staffValue,
  }
  
  const {
    mainQuery,
    ...allFaultyProductLogs
  } = useOrderQueryWithSearchAndFilter(query, useFaultyProductLogByBusinessIdQuery)
  
  const updateFaultyProductMutation = useUpdateFacultyProductLogMutation({successFn: setShowEditInventoryModal})
  
  
  const handleEditExpense = (values) => {
    updateFaultyProductMutation.mutate(values)
  }
  
  const handleAction = (data) => {
    setEditFormValues(data)
    setShowEditInventoryModal()
  }
  
  return (
    <div>
      {/*{showEditInventoryModal && <EditInventoryLogModal onClose={setShowEditInventoryModal} formValues={editFormValues} handleSubmit={handleEditExpense} mutation={editInventoryMutation} mutation2={updateFaultyProductMutation} />}*/}
      {showEditInventoryModal &&
        <DepleteFaultyProductModal onClose={setShowEditInventoryModal} formValues={editFormValues}
                                   handleSubmit={handleEditExpense} mutation={updateFaultyProductMutation}/>}
      
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allFaultyProductLogs.refetch}/>
      )}
      
      <Fragment>
        {allFaultyProductLogs.isEmpty && (
          <Fragment>
            <PageHeader title="Bad and Damaged History"/>
            <NoDataLayout
              imgWidth={45}
              imgAlt="No product"
              imgSrc={NoLogsImage}
              heading="No logs yet"
              subHeading="You need to perform a sale or update your stock to get a log"
            />
          </Fragment>
        )}

        {allFaultyProductLogs.showTable && (
          <Fragment>
            <PageHeaderWithButtonAndDateFilter
              placeholder="Filter by date"
              title="Bad and Damaged History"
              setDateFilter={setDateFilterObj}
              dateFilterDefaultValue={dateFilterValueEnum.THIS_YEAR}
            />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allFaultyProductLogs.isQueryLoading}
                only
                title="Total Number Of Inventories"
                value={allFaultyProductLogs?.data?.count} />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <FilterOptions dispatch={debounceReducer} />
              
              <Table bg headers={inventoryTable.headers} noCols={inventoryTable.headers.length}>
                {allFaultyProductLogs.isQueryWithData && (
                  allFaultyProductLogs?.data?.results.map((items, k) =>
                    <TableItemWithStatus
                      count={k}
                      pageCount={allFaultyProductLogs.currentCount}
                      key={k}
                      data={items}
                      statusPosition={3}
                      keys={inventoryTable.values}
                      onView={handleAction}
                    />
                  )
                )}

                {allFaultyProductLogs.isQueryWithNoData && (
                  <NoQueryData text={allFaultyProductLogs.emptyMessage("Inventory Log")} />
                )}

                {allFaultyProductLogs.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {allFaultyProductLogs.showPagination &&
                <TablePagination
                  query={allFaultyProductLogs}
                  queryKey={inventoryQueryKey.mainList(debounceState.searchValue)}
                />
              }

            </SetUpLayoutStyled>
            
            {allFaultyProductLogs.showPagination &&
              <TablePagination
                query={allFaultyProductLogs}
                queryKey={inventoryQueryKey.mainList(debounceState.searchValue)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default FaultyProductLogs;



const FilterOptions = memo(({ dispatch }) => {
  const { data: allStaffs } = useStaffByBusinessIdQuery()
  
  const handleStaffFilter = (staff) => {
    dispatch({
      staffValue: staff?.id || "",
      type: debounceActions.SET_FILTER_BY_STAFF_FIELD
    })
  }
  
  const handleActivityActionFilter = (action) => {
    dispatch({
      filterValue: action?.value || "",
      type: debounceActions.SET_FILTER_FIELD
    })
  }
  
  const handleSearch = (value) => {
    dispatch({
      searchValue: value,
      type: debounceActions.SET_SEARCH_FIELD
    })
  }
  
  return (
    <SearchAndFilterStyled>
      <Form values={{ action: "", filter: "", to_date: "", from_date: "", staff: "", search: "" }}>
        <SearchField
          label="Search"
          bgDark
          name="search"
          type="search"
          className="search_field"
          placeholder="Search log by remark, product.."
          setSearchValueState={handleSearch}
          rightIcon={<SearchNormal1 size={15} />}
        />
        
        <Select bgDark
          clearFilter
          options={allStaffs?.results}
          displayKey="full_name"
          valueKey="id"
          label="Filter by staff"
          placeholder="Filter log by staff"
          name="staff"
          displayName="staff_name"
          updateFn={handleStaffFilter}
        
        />
        <Select bgDark
          clearFilter
          options={inventoryActionFilters}
          displayKey="name"
          valueKey="value"
          label="Filter by status"
          placeholder="Filter log by status"
          name="action"
          displayName="name"
          updateFn={handleActivityActionFilter}
        />
      </Form>
    </SearchAndFilterStyled>
  )
})
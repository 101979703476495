import {
  CreateTaxModal,
  EditTaxModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton,
  ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {AddCircle} from "iconsax-react";
import {useModal} from "../../../hooks";
import {SetUpLayoutStyled, SetUpMainStyled} from "./SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {
  useCreateTaxMutation,
  useDeleteTaxMutation,
  useEditTaxMutation,
  useTaxQuery
} from "../../../hooks/useServices/useTaxServices";
import {Fragment, useState} from "react";
import {taxFormDefaultValues} from "../../../data/defaultFormValues";
import {TaxImage} from "../../../assets/images";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {actionsPermissions} from "../../../data/permissions";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../desktop/sendDesktopNotification";


const Tax = () => {
  const queryClient = useQueryClient()
  const { toast } = useGlobalContext()
  const [showCreateTaxModal, setShowCreateTaxModal] = useModal()
  const [showEditTaxModal, setShowEditTaxModal] = useModal()

  const { taxTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, searchQuery, ...allTaxes } = useSearchQuery(debounceState, useTaxQuery)
  const [editTaxFormValues, setEditTaxFormValues] = useState(taxFormDefaultValues)
  const deleteTaxMutation = useDeleteTaxMutation({ successFn: () => {} })
  const editTaxMutation = useEditTaxMutation({ successFn: setShowEditTaxModal })
  const createTaxMutation = useCreateTaxMutation({ successFn: setShowCreateTaxModal })
  
  const handleCreateTax = (values) => {
    const mapping_id = values.mapping_id.map(mapping => mapping.id)
    createTaxMutation.mutate({...values, mapping_id: mapping_id[0]})
  }
  
  const handleEditTax = (values) => {
    const mapping_id = values?.mapping_id?.map(mapping => mapping?.id)
    editTaxMutation.mutate({...values, mapping_id: mapping_id[0]})
  }
  
  const handleEditAction = (taxValue) => {
    const tax = {...taxValue}
    let selectedMapping = [tax?.tax_details]
  
    if (tax?.tax_details?.cat_id) {
      tax.category_name_display = tax.tax_details.cat_name
      tax.category_id = tax.tax_details.cat_id
    }
    
    setEditTaxFormValues({...tax, selectedMapping})
    setShowEditTaxModal()
  }
  
  const handleDeleteAction = (tax) => {
    toast.confirm(
      "Are you sure?",
      deleteTaxMutation.mutate.bind(this, tax.id),
      "delete_tax"
    )
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.tax }).then(() => {
      sendDesktopNotification({
        title: "Sync successful",
        body: "All taxes has been synced offline"
      })
    })
  }

  return (
    <SetUpMainStyled>
      {showCreateTaxModal && <CreateTaxModal onClose={setShowCreateTaxModal} handleSubmit={handleCreateTax} mutation={createTaxMutation} />}
      {showEditTaxModal && <EditTaxModal onClose={setShowEditTaxModal} handleSubmit={handleEditTax} mutation={editTaxMutation} formValues={editTaxFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allTaxes.refetch} />
      )}
      
      <Fragment>

        {allTaxes.isEmpty && (
          <Fragment>
            <PageHeader title="Tax" />
            <NoDataLayout
              imgWidth={35}
              imgAlt="No expense"
              imgSrc={TaxImage}
              heading="No tax yet"
              subHeading="Add tax that can be applied to products and services."
              buttonText="Add new tax"
              buttonClick={setShowCreateTaxModal}
              permissionCode={actionsPermissions.createTax}
            />
          </Fragment>
        )}

        {allTaxes.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateTaxModal} title="Tax List" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.createTax} />

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "taxes", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search tax by title"
              />

              <Table headers={taxTable.headers} noCols={taxTable.headers.length}>
                {allTaxes.isQueryWithData && (
                  allTaxes.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allTaxes.currentCount}
                      keys={taxTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.createTax}
                      deletePermission={actionsPermissions.createTax}
                    />
                  )
                )}

                {allTaxes.isQueryWithNoData && (
                  <NoQueryData text={allTaxes.isSearchEmptyMessage("Sub-Category", "")} />
                )}


                {allTaxes.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allTaxes?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allTaxes?.data?.next && <TablePagination />}
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Tax
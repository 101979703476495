import {
	CustomerDetailsStyled,
	CustomerInformationStyled,
	SingleCustomerStyled
} from "../../../styles/singleCustomerStyles";
import {
	Chips,
	CustomerAndStaffReviewCard,
	CustomerModal, Image,
	Line, PageHeader, ScreenLoading, Table, TableItemForGroupOrders, TableItemWithStatus
} from "../../../components";

import {Fragment, useState} from "react";
import {useModal} from "../../../hooks";
import {Avatar} from "../../../assets/images";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {useGroupOrderByStaffIdQuery} from "../../../hooks/useServices/useOrderServices";
import {topNData} from "../../../utils";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {useStaffDashboardAnalyticsByIdService} from "../../../hooks/useServices/useAnalyticsServices";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {FlexColumnStyled, FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import {useWipByStaffIdQuery} from "../../../hooks/useServices/useWipServices";
import {useStoreQuery} from "../../../hooks/useServices/useStoreServices";
import {useTipQuery} from "../../../hooks/useServices/useTipsServices";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import {actionsPermissions} from "../../../data/permissions";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";


const StaffDetail = ({ title, value }) => {
	return (
		<CustomerDetailsStyled>
			<Heading color="menuGray">{title}</Heading>
			<Heading color="black" size={1.1}>{value}</Heading>
		</CustomerDetailsStyled>
	)
}


const StaffDashboard = () => {
	const [showModal, setShowModal] = useModal()
	const [tabState, setTabState] = useState("orders")
	const permission = useActionPermissionFn()
	
	const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
	const { isSuccess, data: staffDetails, status, refetch } = useUserProfile()
	const { data: staffAnalytics, isLoading: isStaffAnalyticsLoading } = useStaffDashboardAnalyticsByIdService(staffDetails?.[0]?.id)

	const { data: allBusinesses } = useStoreQuery()
	const businessSettings = useBusinessSettingQuery()
	const { data: userProfile } = useUserProfile()

	const staffAvailableBusinesses = () => {
		return staffDetails?.[0]?.other_business?.map(businessId => {
			return allBusinesses?.find(business => business.id === businessId)
		})
	}

	return (
		<div>
			{showModal && <CustomerModal onClose={setShowModal}/>}
			
			<PageHeader title="My Dashboard" />
			
			{!isSuccess && (
				<ScreenLoading isError={status === "error"} refetch={refetch} />
			)}
			
			{isSuccess && (
				<SingleCustomerStyled>
					
					<SpaceBetweenStyled className="review_section">
						<div className="staff_avatar_section">
							<Image src={staffDetails?.[0]?.avatar_path || Avatar} alt="staff" width={65} />
							<Heading color="black" size={1.4}>{`${staffDetails?.[0]?.first_name} ${staffDetails?.[0]?.last_name}`}</Heading>
							<Paragraph color="secondary">{staffDetails?.[0]?.business_details.name}</Paragraph>
						</div>
						<FlexColumnStyled $gap={1.4}>
							<SpaceBetweenStyled>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									title="Total Volume"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? (numberFormatter(staffAnalytics?.total_volume || 0)) : "##"}
									noBorder
								/>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									className="left"
									title="Total Value"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_value?.amount || 0) : "##"}
									noBorder
								/>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									title="Total Profit"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_profit?.amount || 0) : "##"}
									noBorder />
								
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									className="left"
									title="Total Debt"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_debt?.amount || 0) : "##"}
									noBorder
								/>
							</SpaceBetweenStyled>
							
							<SpaceBetweenStyled>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									title="Total Commission"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_commission || 0) : "##"}
									noBorder
								/>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									className="left"
									title="Product Commission"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.product_commission || 0) : "##"}
									noBorder
								/>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									title="Service Commission"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.service_commission || 0) : "##"}
									noBorder
								/>
								<CustomerAndStaffReviewCard
									isLoading={isStaffAnalyticsLoading}
									className="left"
									title="Total Service Cost"
									value={permission.check(actionsPermissions.staffAnalyticsPermission) ? currencyFormatter(staffAnalytics?.total_service_cost || 0) : "##"}
									noBorder
								/>
							</SpaceBetweenStyled>
						</FlexColumnStyled>
					</SpaceBetweenStyled>
					
					<Line />
					
					<SectionStyled>
						<Heading color="black" size={1.3}>My Information</Heading>
						
						<CustomerInformationStyled>
							<div className="customer_bio_data">
								<StaffDetail title="Name" value={`${staffDetails?.[0]?.first_name} ${staffDetails?.[0]?.last_name}`} />
								<StaffDetail title="Email" value={staffDetails?.[0]?.email} />
								<StaffDetail title="Phone" value={staffDetails?.[0]?.phone} />
								<StaffDetail title="Address" value={staffDetails?.[0]?.address} />
								<StaffDetail title="Start Date" value="10-10-2022" />

								<CustomerDetailsStyled>
									<Heading color="menuGray">Other businesses</Heading>
									{staffAvailableBusinesses()?.map(business => (
										<Heading color="black" size={1.1} key={business?.id}>{business?.store_name}</Heading>
									))}
								</CustomerDetailsStyled>
							</div>
							
							<div className="customer_activities">
								<SpaceBetweenStyled>
									<FlexStyled>
										<Chips title="Recent Orders" active={tabState === "orders"} onClick={() => setTabState("orders")}/>
										
										{(businessSettings?.wip && [...userProfile?.[0]?.package_details?.modules].includes("WIP")) && (
											<Chips title="Wip" active={tabState === "wip"} onClick={() => setTabState("wip")} />
										)}
										
										<Chips title="Tips" active={tabState === "tips"} onClick={() => setTabState("tips")} />                    {/*<Chips title="Sales" />*/}
										{/*<Chips title="Customers" />*/}
										{/*<Chips title="Reports" />*/}
									</FlexStyled>
									
									{/*<Chips title="View all" />*/}
								</SpaceBetweenStyled>
								
								{tabState === "orders" && <OrderTable />}
								{tabState === "wip" && <WipTable />}
								{tabState === "tips" && <TipsTable />}
							
							
							</div>
						</CustomerInformationStyled>
					</SectionStyled>
				
				</SingleCustomerStyled>
			)}
		</div>
	)
}

export default StaffDashboard

const OrderTable = () => {
	const { data: staffDetails } = useUserProfile()
	const { customerOrderTable } = tableHeadersAndValueKeys
	const { debounceState } = useQueryDebounce()
	const { mainQuery, ...allStaffOrders } = useSearchQuery(debounceState, useGroupOrderByStaffIdQuery.bind(this, { ...debounceState, staffIdByProps: staffDetails?.[0]?.id || "" }))

	return (
		<Table headers={customerOrderTable.headers} noPadding equalWidth noCols={customerOrderTable.headers.length}  >
			{mainQuery.isSuccess && (
				<Fragment>
					{topNData(allStaffOrders?.data?.results, 10).map((item, k) => (
						<TableItemForGroupOrders
							statusPosition={4}
							count={k}
							pageCount={allStaffOrders.currentCount}
							key={k}
							data={item}
							keys={customerOrderTable.values}
							noAction
						/>
					))}

					{allStaffOrders.data.results.length === 0 && (
						<NoQueryData text="No staff order " />
					)}
				</Fragment>
			)}
		</Table>
	)
}

const WipTable = () => {
	const { debounceState } = useQueryDebounce()
	const { data: staffDetails } = useUserProfile()
	const { staffWipTable } = tableHeadersAndValueKeys
	const { mainQuery, ...allStaffWip } = useSearchQuery(debounceState, useWipByStaffIdQuery.bind(this, staffDetails?.[0]?.id))
	
	return (
		<Table headers={staffWipTable.headers} noCols={staffWipTable.headers.length} equalWidth>
			{allStaffWip.isQueryWithData && (
				allStaffWip.data.results.map((items, k) =>
					<TableItemWithStatus
						key={k}
						data={items}
						count={k}
						pageCount={allStaffWip.currentCount}
						noAction
						statusPosition={2}
						keys={staffWipTable.values}
					/>
				)
			)}
			
			{allStaffWip.isQueryWithNoData && (
				<NoQueryData text={allStaffWip.isSearchEmptyMessage("Stage", "")} />
			)}
		</Table>
	)
}


const TipsTable = () => {
	const { debounceState } = useQueryDebounce()
	const { data: staffDetails } = useUserProfile()
	const { staffTipsTable } = tableHeadersAndValueKeys
	const { mainQuery, ...allStaffTips } = useSearchQuery(debounceState, useTipQuery.bind(this, { staff_id: staffDetails?.[0]?.id }))
	
	return (
		<Table headers={staffTipsTable.headers} noCols={staffTipsTable.headers.length} equalWidth>
			{allStaffTips.isQueryWithData && (
				allStaffTips.data.results.map((items, k) =>
					<TableItemWithStatus
						key={k}
						count={k}
						pageCount={allStaffTips.currentCount}
						data={items}
						noAction
						statusPosition={2}
						keys={staffTipsTable.values}
					/>
				)
			)}
			
			{allStaffTips.isQueryWithNoData && (
				<NoQueryData text={allStaffTips.isSearchEmptyMessage("Tip", "")} />
			)}
		</Table>
	)
}
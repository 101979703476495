import {NotificationCardStyled} from "../../styles/cardStyles";
import {Heading, Paragraph} from "../../styles/textStyles";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {useReadNotificationMutation} from "../../hooks/useServices/useNotificationsServices";

const NotificationCard = ({ notification, onClose }) => {
	const { navigate } = useGlobalContext()
	const { dateFormatter } = useDataTypeFormatter()
	const readNotificationMutation = useReadNotificationMutation()

	const handleClick = () => {
		readNotificationMutation.mutate(notification.id)

		if(notification.notif_type === "expenses") {
			navigate("/dashboard/expenses")
		}

		if(notification.notif_type === "product_expiry") {
			navigate("/dashboard/inventory/expiry-products/")
		}
		
		if(notification.notif_type === "product_distribution") {
			navigate("/dashboard/inventory/inventory-logs/")
		}
		
		if(notification.notif_type === "order") {
			const messageArr = notification.msg.split(" ")
			const idIndex = messageArr.indexOf("ID") + 1
			navigate(`/dashboard/orders/${messageArr[idIndex]}`)
		}

		onClose()
	}

	return (
		<NotificationCardStyled onClick={handleClick}>
			<Heading bold color="black" size={1.1}>{notification?.title}</Heading>
			<Paragraph color="black" size={1}>{notification?.msg?.slice(0, 70)}...</Paragraph>
			<Paragraph color="gray" size={.9}>{dateFormatter(notification?.date)}</Paragraph>
		</NotificationCardStyled>
	)
}

export default NotificationCard
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


export const verifyStoreFrontNameService = async (query = {}) => {
  try {
    const url = handleQueryParams("/verify_store_front/", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const getStoreFrontBusinessService = async (query = {}) => {
  try {
    const url = handleQueryParams("/store_front_name/", query)
    const { data } = await axios.get(url)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const createStoreFrontService = async (body) => {
  try {
    const { data } = await axios.post("/store_front_name/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export const updateStoreFrontService = async (body) => {
  try {
    const { data } = await axios.patch(`/store_front_name/${body.business_id}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const createCustomerForStoreService = async (body) => {
  try {
    const { data } = await axios.post("/customer_user/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
import {useEffect} from "react";
import {getAuthTokenFromStorage, getBusinessIdFromStorage} from "../../../utils";
import useGlobalContext from "../../useContexts/useGlobalContext";

const useAllowOnlyUnauthenticatedUsers = () => {
	const { navigate } = useGlobalContext()

	useEffect(() => {
		const businessId = getBusinessIdFromStorage()
		const authToken = getAuthTokenFromStorage()
		
		if(!!businessId && !!authToken) {
			navigate("/dashboard")
		}
		
		if(!!authToken && !businessId) {
			navigate("/select-store")
		}
		
	}, [])
}

export default useAllowOnlyUnauthenticatedUsers
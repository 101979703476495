import {Fragment, useState} from "react";
import {RiToggleFill, RiToggleLine} from "react-icons/ri"

const SubscriptionToggle = ({ updateFn }) => {
	const [isToggleOn, setIsToggleOff] = useState(true)

	const handleToggle = () => {
		updateFn(!isToggleOn)
		setIsToggleOff(!isToggleOn)
	}

	return (
		<Fragment>
			{isToggleOn ?
				<RiToggleFill color="black" size={35} onClick={handleToggle} /> :
				<RiToggleLine color="black" size={35} onClick={handleToggle} />
			}
		</Fragment>
	)
}


export default SubscriptionToggle
import {getBusinessIdFromStorage} from "../../../utils";
const businessId = getBusinessIdFromStorage()

const key = "supplier"
const withBusinessId = [key, businessId]

const supplierQueryKey = {
	all: [key],
	id: (id) => [...withBusinessId, id],
	// byCategory: (categoryId) => [...withBusinessId, "category", categoryId],
	mainList: (searchValue) => [...withBusinessId, searchValue || undefined]
}

export default supplierQueryKey
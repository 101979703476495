import axios from "../../axios";


const getRoleService = async (businessId, searchValue = " ") => {
  try {
    const { data } = await axios.get(`/designation/?business_id=${businessId}&search=${searchValue}`)

    return data.map(role => ({ ...role, numberOfPermissions: role.permission.length }))
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const createRoleService = async (body) => {
  try {
    const { data } = await axios.post(`/designation/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const editRoleService = async (roleId, body) => {
  try {
    const { data } = await axios.patch(`/designation/${roleId}/`, body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

const deleteRoleService = async (roleId) => {
  try {
    const { data } = await axios.delete(`/designation/${roleId}/`)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}

export { getRoleService, createRoleService, editRoleService, deleteRoleService }
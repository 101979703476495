export const getCustomerServiceStructure = (data) => {
	// if customer does not have a discount
	if(!data?.results) {
		return data.map(item => {
			return {
				...item,
				id: item.id.toString(),
				main_address: handleMainAddress(item?.address),
				full_name: `${item.first_name} ${item.last_name} - (${item.phone})`
			}
		})
	}
	
	const results = data.results.map(item => {
		return {
			...item,
			id: item.id.toString(),
			main_address: handleMainAddress(item?.address),
			full_name: `${item.first_name} ${item.last_name} - (${item.phone})`
		}
	})

	return { ...data, results }
}


export const handleMainAddress = (addressField) => {
	if(Array.isArray(addressField)) {
		return addressField?.[0]?.address !== "null" ? addressField?.[0]?.address : ""
	}

	if(typeof addressField === "string") {
		return addressField
	}

	return  addressField?.address !== "null" ? addressField?.address : ""
}


export const getCustomerWalletLogStructure = (data) => {
	const results = data.results.map(item => {
		return {
			...item,
			transaction_type: item.transaction_type === "credit" ? "credit_green" : item.transaction_type
		}
	})
	
	return { ...data, results }
}
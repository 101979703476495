import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {Fragment, memo, useEffect, useRef, useState} from "react";
import {useProductByBusinessIdQuery} from "../../../../hooks/useServices/useProductServices";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import {Button, CheckBox, DatePicker, Form, Input, Select, TextArea} from "../../../index";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {purchaseOrderFormDefaultValues} from "../../../../data/defaultFormValues";
import {useSupplierByBusinessIdQuery} from "../../../../hooks/useServices/useSupplierServices";
import RadioButton from "../../../Forms/RadioButton";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {actionsPermissions} from "../../../../data/permissions";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {calculatePercentageDifferenceOfUnitMeasurementSellingPrice} from "../../../../utils/products";
import modalNames from "../../../../hooks/useModalConnector/modalNames";
import {useFromModalConnector} from "../../../../hooks/useModalConnector";


const CreatePurchaseOrderModal = ({ onClose, handleSubmit, mutation }) => {
  const { globalState } = useGlobalContext()
  const isPermitted = useActionPermissionFn()
  
  
  const onSubmit = (values) => {
    const formValues = { ...values }
  
    const { sales_attachment, sales_attachment_display, sales_attachment_quantity, sales_attachment_cost, sales_attachment_expiry, sales_attachment_use_sales_margin, sales_attachment_batch_number, sales_attachment_measurement_name_main, sales_attachment_measurement_base_main, sales_attachment_measurement_percentage_diff_main } = values
  
    const quantity = Number(sales_attachment_measurement_base_main || 1) * Number(sales_attachment_quantity)
    
    const products = []
    
    if(sales_attachment) {
      products.push({
        batch_number: sales_attachment_batch_number,
        product_name: sales_attachment_display,
        product_id: sales_attachment,
        requested_quantity: quantity,
        unit_price_difference: sales_attachment_measurement_percentage_diff_main,
        cost_price: Math.ceil(Number(sales_attachment_cost) / Number(sales_attachment_measurement_base_main || 1)),
        expiry_date: sales_attachment_expiry,
        unit_measurement: sales_attachment_measurement_name_main || "",
        base_quantity: sales_attachment_measurement_base_main || 1,
        use_sales_margin: sales_attachment_use_sales_margin,
        ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
          formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
          supplied_quantity: Number(quantity),
        })
      })
    }
  
    delete formValues.sales_quantity
    delete formValues.sales_attachment
    delete formValues.sales_attachment_quantity
    delete formValues.sales_attachment_cost
    delete formValues.sales_attachment_display
    delete formValues.sales_attachment_expiry
    delete formValues.sales_attachment_cost_format
    delete formValues.sales_attachment_quantity_format
    delete formValues.sales_attachment_use_sales_margin
    delete formValues.sales_attachment_batch_number
  
  
    for (const key in formValues) {
      if(key.slice(0, 21) === "sales_attachment_name") {
      
        const uuid = key.split("_")[3]
      
        const product_id = formValues[`sales_attachment_name_${uuid}`]
        const product_name = formValues[`sales_attachment_display_${uuid}`]
        const requested_quantity = Number(formValues[`sales_attachment_quantity_${uuid}`])
        const cost_price = Math.ceil(Number(formValues[`sales_attachment_cost_${uuid}`]) / Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1))
        const expiry_date = formValues[`sales_attachment_expiry_${uuid}`]
        const use_sales_margin = formValues[`sales_attachment_use_sales_margin_${uuid}`]
        const batch_number = formValues[`sales_attachment_batch_number_${uuid}`]
        const percentageDiff = formValues[`sales_attachment_measurement_percentage_diff_${uuid}`]
  
        const quantity = Number(formValues[`sales_attachment_measurement_base_${uuid}`] || 1) * Number(requested_quantity)
        
        products.push({
          product_id,
          product_name,
          requested_quantity: quantity,
          cost_price,
          expiry_date,
          batch_number,
          use_sales_margin,
          unit_price_difference: percentageDiff,
          unit_measurement: formValues[`sales_attachment_measurement_name_${uuid}`] || "",
          base_quantity: formValues[`sales_attachment_measurement_base_${uuid}`] || 1,
          ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
            formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
            supplied_quantity: Number(requested_quantity),
          })
        })
        
        delete formValues[`sales_attachment_name_${uuid}`]
        delete formValues[`sales_attachment_display_${uuid}`]
        delete formValues[`sales_attachment_quantity_${uuid}`]
        delete formValues[`sales_attachment_cost_${uuid}`]
        delete formValues[`sales_attachment_expiry_${uuid}`]
        delete formValues[`sales_attachment_quantity_${uuid}_format`]
        delete formValues[`sales_attachment_cost_${uuid}_format`]
        delete formValues[`sales_attachment_use_sales_margin_${uuid}`]
        delete formValues[`sales_attachment_batch_number_${uuid}`]
        delete formValues[`sales_attachment_measurement_name_${uuid}`]
        delete formValues[`sales_attachment_measurement_base_${uuid}`]
      }
    }
    
    handleSubmit({
      ...formValues,
      products,
      ...((formValues.submit_option === purchaseOrderRadioOptions.create_and_receive ||
        formValues.submit_option === purchaseOrderRadioOptions.create_pay_and_receive) && {
        supplied: true
      })
    })
  }
  
  return (
    <ModalLayoutStyled onClick={onClose} $zIndex={9999} $padding="0.5rem">
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={99}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Purchase Order</Heading>
        
        <Form modalName={modalNames.CREATE_PURCHASE_ORDER} values={{ ...purchaseOrderFormDefaultValues, submit_option: purchaseOrderRadioOptions.create_only, ...(globalState.restockProducts || {}) }} onSubmit={onSubmit}>
          <Fragment>
            <FlexStyled className="fullWidth">
              <Supplier />
              <Input optional type="number" label="Shipping Cost" placeholder="Enter shipping cost (if any)" name="shipping_cost" formatNumber />
              <Input optional label="Tax" type="number" placeholder="Enter shipping cost (if any)" name="tax" formatNumber />
              <Input optional label="Purchase ID" placeholder="Enter purchase ID provided by supplier" name="purchase_id" />
            </FlexStyled>
  
            <AttachProduct />
            <TextArea name="remark" optional label="Remark" className="fullWidth" placeholder="Enter a remark for this purchase" />
  
            {isPermitted.check(actionsPermissions.createPurchaseOrder) && (
              <RadioButton id="1" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_only} labelProp="Create Order" />
            )}
            
            {isPermitted.check(actionsPermissions.payForPurchaseOrder) && isPermitted.check(actionsPermissions.createPurchaseOrder) && (
              <RadioButton id="12" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_and_pay} labelProp="Create and Pay" />
            )}
            
            {isPermitted.check(actionsPermissions.receivePurchaseOrder) && isPermitted.check(actionsPermissions.payForPurchaseOrder) && (
              <RadioButton id="13" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_and_receive} labelProp="Create and Receive" />
            )}
            
            {isPermitted.check(actionsPermissions.receivePurchaseOrder) && isPermitted.check(actionsPermissions.payForPurchaseOrder) && isPermitted.check(actionsPermissions.createPurchaseOrder) && (
              <RadioButton id="14" className="fullWidth" name="submit_option" value={purchaseOrderRadioOptions.create_pay_and_receive} labelProp="Create, Pay and Receive" />
            )}
  
  
            <FlexStyled>
              <Button isLoading={mutation.isLoading} text="Create" type="submit" />
            </FlexStyled>
          </Fragment>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreatePurchaseOrderModal

export const purchaseOrderRadioOptions = {
  create_only: "create_only",
  create_and_pay: "create_and_pay",
  create_and_receive: "create_and_receive",
  create_pay_and_receive: "create_pay_and_receive"
}


const AttachProduct = memo(() => {
  const { globalState } = useGlobalContext()
  const [searchProduct, setSearchProduct] = useState("")
  const { data, isLoading: isProductLoading } = useProductByBusinessIdQuery(searchProduct)
  const { setFieldValue, values } = useFormikContext()
  const { numberFormatter, currencyFormatter } = useDataTypeFormatter()
  const businessSettings = useBusinessSettingQuery()
  const allSelectedProductsWithIDRef = useRef([])
  const [moreProducts, setMoreProducts] = useState([])
  
  
  useEffect(() => {
    if(globalState.restockProducts.productsIds) {
      allSelectedProductsWithIDRef.current = globalState.restockProducts?.productsIds?.map(product_uuid => {
        const productId = globalState.restockProducts[`sales_attachment_name_${product_uuid}`]
        const product = globalState.restockProducts?.products?.find(product => product?.product_id === productId)
    
        return {id: product_uuid, product }
      })
    }
  }, [globalState.restockProducts])
  
  useEffect(() => {
    if (!!globalState.restockProducts.productsIds && globalState.restockProducts.productsIds.length > 0) {
    
      const attachmentStructure = globalState.restockProducts.productsIds.map(id => {
        return SalesAttachmentForm(id)
      })
    
      setMoreProducts(attachmentStructure)
    }
  }, [globalState.restockProducts])
  
  const handleForeCastSellingPrice = (cost, id) => {
    const product = allSelectedProductsWithIDRef.current.find(product => product.id === (id || "main"))
    if(!businessSettings?.use_sales_margin || !product || !cost) return

    const salesMarginPercentage = product?.product?.sales_margin || businessSettings?.sales_margin
    const perValue = Number(cost) * (Number(salesMarginPercentage) / 100)
    const forecastValue = Number(cost) + perValue
    
    if(Number(product?.product?.selling_price) === forecastValue) return

    if(id) {
      setFieldValue(`sales_attachment_forecast_${id}`, `${product?.product?.name || product?.product?.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)`)
      return
    }

    setFieldValue(`sales_attachment_forecast`, `${product?.product?.name || product?.product?.product_name} selling price will now be: ${currencyFormatter(forecastValue)} (${Number(salesMarginPercentage)}% sales margin)`)
  }
  
  const handleSelectProduct = (product, id = "main") => {
    const filterProducts = allSelectedProductsWithIDRef.current.filter(productObj => productObj?.id !== (id || "main"))
    allSelectedProductsWithIDRef.current = [...filterProducts, {
      id: (id || "main"),
      product: { ...product, meta_measurement: [{ name: product.unit_measurement, base_quantity: 1, selling_price: product.selling_price }, ...(product?.meta_measurement || [])] }
    }]
    
    setFieldValue(`sales_attachment_measurement_product_previous_selling_price_${id}`, product.selling_price)
  
    if(id) {
      if(!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) {
        handleForeCastSellingPrice(Number(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price), id)
      }
      
      setFieldValue(`sales_attachment_cost_${id}`, product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price || "")
      setFieldValue(`sales_attachment_cost_${id}_format`, (!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price ? numberFormatter(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) : ""))
      return
    }
  
    if(!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) {
      handleForeCastSellingPrice(Number(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price), id)
    }
    
    setFieldValue("sales_attachment_cost", product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price || "")
    setFieldValue("sales_attachment_cost_format", (!!product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price ? numberFormatter(product?.cost_prices?.[product?.cost_prices?.length - 1]?.cost_price) : ""))
  }
  
  const handleSelectUnitMeasurement = (measurement, id = "main", values) => {
    const sellingPricePerUnit = values[`sales_attachment_measurement_product_previous_selling_price_${id}`]
    const percentageDiff = calculatePercentageDifferenceOfUnitMeasurementSellingPrice(measurement, sellingPricePerUnit)
    
    setFieldValue(`sales_attachment_measurement_percentage_diff_${id}`, percentageDiff)
    setFieldValue(`sales_attachment_measurement_name_${id}`, measurement.name)
    setFieldValue(`sales_attachment_measurement_base_${id}`, measurement.base_quantity)
  }
  
  const SalesAttachmentForm = (newID) => {
    const id = newID || uuid()
    
    const SelectProduct = ({ data, isProductLoading }) => (
      <Select
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_name_${id}`}
        label="Product"
        displayName={`sales_attachment_display_${id}`}
        placeholder="Select product"
        options={data?.results}
        updateFn={(prod) => handleSelectProduct(prod, id)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
      />
    )
  
    const SelectUnitMeasurement = ({ allSelectedProductsWithIDRef, values }) => (
      <Select
        optional
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_measurement_${id}`}
        label="Unit Measurement"
        displayName={`sales_attachment_measurement_display_${id}`}
        placeholder="Select Unit Measurement"
        options={allSelectedProductsWithIDRef.current?.find(pro => pro?.id === id)?.product?.meta_measurement || []}
        updateFn={(measurement) => handleSelectUnitMeasurement(measurement, id, values)}
        updateQueryResult={setSearchProduct}
        isSearchLoading={isProductLoading}
        clearCondition={`sales_attachment_name_${id}`}
      />
    )
  
    const InputCostPerQuantity = ({ values }) => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_cost_${id}`}
        label={`Cost ${(!!values?.[`sales_attachment_measurement_display_${id}`]) ? `per 1 ${values[`sales_attachment_measurement_display_${id}`]}` : "per quantity"}`}
        placeholder="Enter cost"
        onChange={(cost) => handleForeCastSellingPrice(cost, id)}
      />
    )
    
    const InputQuantity = () => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_quantity_${id}`}
        label="Quantity"
        placeholder="Enter quantity"
      />
    )
  
    const InputBatchNumber = () => (
      <Input
        optional
        label="Batch Number"
        placeholder="Enter batch number"
        name={`sales_attachment_batch_number_${id}`}
      />
    )
  
    const InputExpiryDate = () => (
      <DatePicker name={`sales_attachment_expiry_${id}`} label="Expiry Date" optional />
    )
    
    return { id, SelectProduct, InputExpiryDate, InputQuantity, InputBatchNumber, InputCostPerQuantity, SelectUnitMeasurement }
  }
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id) => {
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`sales_attachment_name_${id}`, "")
    setFieldValue(`sales_attachment_display_${id}`, "")
    setFieldValue(`sales_attachment_quantity_${id}`, "")
    setFieldValue(`sales_attachment_cost_${id}`, "")
    setFieldValue(`sales_attachment_expiry_${id}`, "")
    setFieldValue(`sales_attachment_batch_number_${id}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        {!globalState.restockProducts.productsIds && (
          <div>
            <FlexStyled className="fullWidth five_cols">
              <Select
                valueKey="id"
                displayKey="name"
                name="sales_attachment"
                label="Product"
                displayName="sales_attachment_display"
                placeholder="Select product"
                options={data?.results}
                updateFn={(prod) => handleSelectProduct(prod)}
                updateQueryResult={setSearchProduct}
                isSearchLoading={isProductLoading}
              />
  
              <Select
                optional
                valueKey="id"
                displayKey="name"
                name={`sales_attachment_measurement`}
                label="Unit Measurement"
                displayName={`sales_attachment_measurement_display`}
                placeholder="Select Unit Measurement"
                options={allSelectedProductsWithIDRef.current.find(pro => pro.id === "main")?.product?.meta_measurement || []}
                updateFn={(measurement) => handleSelectUnitMeasurement(measurement, "main", values)}
                updateQueryResult={setSearchProduct}
                isSearchLoading={isProductLoading}
                clearCondition="sales_attachment"
              />
  
              <Input
                type="number"
                formatNumber
                name="sales_attachment_cost"
                label={`Cost ${(!!values[`sales_attachment_measurement_display`]) ? `per 1 ${values[`sales_attachment_measurement_display`]}` : "per quantity"}`}
                placeholder="Enter cost"
                onChange={(cost) => handleForeCastSellingPrice(cost)}
              />
  
              <Input
                type="number"
                formatNumber
                name="sales_attachment_quantity"
                label="Quantity"
                placeholder="Enter quantity"
              />
      
              <Input
                optional
                label="Batch Number"
                placeholder="Enter batch number"
                name="sales_attachment_batch_number"
              />
      
              <DatePicker
                name={`sales_attachment_expiry`}
                label="Expiry Date"
                optional
              />
            </FlexStyled>
    
            {!!values?.sales_attachment_forecast && (
              <Fragment>
                <CheckBox checked={true} name="sales_attachment_use_sales_margin" labelProp={values?.sales_attachment_forecast} />
              </Fragment>
            )}
          </div>
        )}
        
        {moreProducts?.map(({ id, SelectProduct, InputExpiryDate, InputQuantity, InputBatchNumber, InputCostPerQuantity, SelectUnitMeasurement }) => (
          <div>
            <FlexStyled className="fullWidth five_cols" key={id}>
              <SelectProduct data={data} isProductLoading={isProductLoading} />
              <SelectUnitMeasurement allSelectedProductsWithIDRef={allSelectedProductsWithIDRef} values={values}/>
              <InputCostPerQuantity values={values} />
              <InputQuantity />
              <InputBatchNumber />
              <InputExpiryDate />
              <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
            </FlexStyled>
  
            {!!values?.[`sales_attachment_forecast_${id}`] && (
              <Fragment>
                <CheckBox optional checked={true} name={`sales_attachment_use_sales_margin_${id}`} labelProp={values?.[`sales_attachment_forecast_${id}`]} />
              </Fragment>
            )}
          </div>
        ))}
        
        <Button onClick={addExtraProduct} type="button" text="Add more product" className="small" small />
        <br/>
      </Fragment>
    
    </div>
  )
})

const Supplier = () => {
  const { values } = useFormikContext()
  const fromModalConnector = useFromModalConnector()
  const { data: allSuppliers } = useSupplierByBusinessIdQuery()
  
  const handleCreateSupplier = () => {
    fromModalConnector({
      fromModalFormValues: values,
      toModalURL: "/dashboard/inventory/supplier/",
      toModalName: modalNames.CREATE_SUPPLIER_ORDER,
      fromModalName: modalNames.CREATE_PURCHASE_ORDER,
    })
  }
  
  const CreateSupplier = () => (
    <label className="underline" onClick={handleCreateSupplier}>Create +</label>
  )
  
  return (
    <Select
      optional
      options={allSuppliers?.results || []}
      displayKey="name"
      valueKey="id"
      label="Supplier"
      placeholder="Select supplier of product"
      name="supplier"
      displayName="name"
      SideInfo={CreateSupplier}
    />
  )
}
import {Fragment, memo, useState} from "react";
import {
  Button,
  Form,
  PageHeader,
  Select,
  Table,
  TableItemWithStatus,
  TablePagination
} from "../../../components";
import {SetUpLayoutStyled} from "../../dashboard/setUp/SetUpStyles";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {
  allUsersQueryKey,
  useAllUserQuery,
  useDownloadAllUserQueryMutation
} from "../../../hooks/useServices/useStaffBackendServices/useUserServices";
import useOrderQueryWithSearchAndFilter from "../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import {userTypeFilter} from "../../../data/selectField/allUsersFilter";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import {
  useAccountBusinessesByIdQuery,
} from "../../../hooks/useServices/useStaffBackendServices/useAccountServices";
import {useFormikContext} from "formik";


const AllUsers = () => {
  const { userListForStaffBackend } = tableHeadersAndValueKeys
  
  const [userType, setUserType] = useState("")
  const [business, setBusiness] = useState("")
  
  const { mainQuery, ...allUsers } = useOrderQueryWithSearchAndFilter(
    { user_type: userType, business_id: business },
    useAllUserQuery
  )
  
  const downloadAllUserMutation = useDownloadAllUserQueryMutation()
  
  const handleSelectUserType = (user) => {
    setUserType(user.value)
  }
  
  const handleDownloadUsers = () => {
    downloadAllUserMutation.mutate({ user_type: userType, business_id: business })
  }
  
  return (
    <Fragment>
      <PageHeader title="All Users" />
    
        <Fragment>
            <Fragment>
              <SetUpLayoutStyled>
                <Form values={{ user_type: "", display_user_type: "", accounts: "", display_account: "" }}>
                  <SpaceBetweenStyled>
                    <FlexStyled>
                      <Select
                        label="User Type"
                        options={userTypeFilter}
                        displayKey="name"
                        valueKey="value"
                        name="user_type"
                        updateFn={handleSelectUserType}
                        displayName="display_user_type"
                        placeholder="Filter by user type"
                      />
    
                      <SelectBusiness setBusiness={setBusiness} />
                    </FlexStyled>
                    
                    <div>
                      <Button text="Download" type="button" onClick={handleDownloadUsers} />
                    </div>
                  </SpaceBetweenStyled>
                </Form>
              
                <Table headers={userListForStaffBackend.headers} noCols={userListForStaffBackend.headers.length}>
                  {allUsers.isQueryWithData && (
                    allUsers?.data?.results.map((log, k) => (
                      <TableItemWithStatus
                        count={k}
                        pageCount={allUsers.currentCount}
                        key={k}
                        data={log}
                        statusPosition={40}
                        keys={userListForStaffBackend.values}
                      />
                    ))
                  )}
                
                  {allUsers.isQueryWithNoData && (
                    <NoQueryData text="No user, check filters" />
                  )}
                
                  {allUsers.isQueryLoading && (
                    <TableSkeleton />
                  )}
                
                  {!!allUsers?.data?.next && (
                    <TablePagination
                      queryKey={allUsersQueryKey.main({ user_type: userType, business_id: business })}
                      query={allUsers}
                    />
                  )}
                </Table>
              </SetUpLayoutStyled>
            
              {!!allUsers?.data?.next && (
                <TablePagination
                  queryKey={allUsersQueryKey.main({ user_type: userType, business_id: business })}
                  query={allUsers}
                />
              )}
            </Fragment>
        </Fragment>
    </Fragment>
  )
}


export default AllUsers


const SelectBusiness = memo(({ setBusiness }) => {
  const { values } = useFormikContext()
  
  const [searchBusiness, setBusinessSearch] = useState("")
  const { data: allAccounts } = useAccountBusinessesByIdQuery(searchBusiness)
  
  const handleSelectBusiness = (business) => {
    setBusiness(business.id)
  }
  
  return (
    <Fragment>
      {values.user_type === "customer" && (
        <Select
          updateQueryResult={setBusinessSearch}
          label="Business"
          options={allAccounts?.results}
          displayKey="store_name"
          valueKey="id"
          name="account"
          updateFn={handleSelectBusiness}
          displayName="display_account"
          placeholder="Filter by business"
        />
      )}
    </Fragment>
  )
})
import {getBusinessIdFromStorage} from "../../../utils";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
const businessId = getBusinessIdFromStorage()

const key = "group_orders"
const withBusinessId = [key, businessId]


const orderQueryKey = {
	all: [key],
	mainListKey: [...withBusinessId, "main"],
	analytics: [...withBusinessId, "analytics"],
	dashboardAnalytics: (query = {}) => [...withBusinessId, "analytics", getAllObjectKeysWithValues(query)],
	orderAnalytics: (query = {}) => [...withBusinessId, "order_analytics", getAllObjectKeysWithValues(query)],
	byStaffId: (id, params) => [...withBusinessId, "staff", id, getAllObjectKeysWithValues(params)],
	byGroupOrderId: (id, params = {}) => [...withBusinessId, "orders", id, getAllObjectKeysWithValues(params)],
	byTableTrackId: (id) => [...withBusinessId, "table", id],
	mainList: (searchValue, ...rest) => [...withBusinessId, "main", searchValue || undefined, ...rest],
	main: (query = {}) => [...withBusinessId, "main", getAllObjectKeysWithValues(query)],
	withDueDate: (queryParams = {}) => [...withBusinessId, "main", "with_due_date", queryParams],
	kitchenDisplay: (searchValue, ...rest) => [...withBusinessId, "main", searchValue || undefined, ...rest],
}

export default orderQueryKey
import {Heading, Paragraph} from "./textStyles";
import styled from "styled-components";


const InputStyled = styled("div")`
  gap: .5rem;
  display: flex;
  position: relative;
  margin-bottom: ${props => !props.$noBottomMargin && "1.5rem"};
  margin-bottom: ${props => props.$noBottomMargin && "0rem !important"};
  flex-direction: column;
  color: ${props => props.theme.darkGray};
  
  input, textarea {
    padding: .8rem;
    outline: none;
    border-radius: .6rem;
    width: calc(100% - 2rem);
    font-family: Medium, serif;
    border: 1px solid ${props => props.theme.inputBorder};
    padding-left: ${props => props.rightIcon ? "2.2rem !important" : "1rem"};
    background-color: ${props => props.bgDark ? props.theme.inputBGDark : "transparent"};
  }
  
  textarea {
    min-height: 2rem;
    max-width: calc(100% - 2rem);
    min-width: calc(100% - 2rem);
  }
  
  input::placeholder, textarea::placeholder {
    opacity: .4;
    color: ${props => props.theme.placeholder};
  }
  
  i {
    cursor: pointer;
    right: .5rem;
    bottom: calc(100% - 82%);
    position: absolute;
  }
  
  svg.password {
    top: -.1rem;
    right: .2rem;
    position: relative;
  }
  
  i.right_icon {
    left: .7rem;
    right: auto;
    bottom: calc(100% - 85%);
  }
  
  i.select svg {
    cursor: pointer;
    position: absolute;
    right: .8rem;
    top: .6rem;
    color: ${props => props.theme.gray};
  }
  
  i.select svg.clear_filter_icon {
    right: 2.6rem;
  }
  
  a {
    cursor: pointer;
    text-decoration: underline #9FA2B4;
    
    label {
      cursor: pointer;
    }
  }
  
  ${Paragraph}.date_placeholder {
    left: 1rem;
    width: 60%;
    z-index: 99;
    padding: .4rem 0;
    position: absolute;
    background: inherit;
    font-size: 1.1rem !important;
    font-family: Medium, serif !important;
    color: rgba(75, 80, 109, 0.4);
    top: ${props => props.$isLabel ? "42%" : "22%"};
    background: ${props => props.theme[props.$labelBg]};
  }
  
  @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
    margin-bottom: 2rem;
  }
`

const PhoneInputStyled = styled(InputStyled)`
  .right_icon {
    width: fit-content;
    position: absolute;
    top: 2.7rem;
    display: flex;
    gap: .4rem;
    
    span:last-of-type {
      font-size: .9rem;
      font-family: Bold, serif;
    }
  }
  
  input {
    width: ${props => props.$code && "calc(100% - 6rem)"};
    padding-left: ${props => props.$code && "5rem"};
  }
`

const CheckBoxStyled = styled("div")`
  display: flex;
  gap: .8rem;
  align-items: center;
  margin-bottom: ${props => !props.$noBottomMargin && "1.5rem"};
  
  label, p {
    color: #9FA2B4;
    font-size: .95rem;
    position: relative;
    cursor: pointer;
    font-family: Medium, serif;

    a {
      font-family: Medium, serif !important;
      color: ${props => props.theme.black};
    }
  }
  
  input {
    transform: scale(1.3);
    accent-color: ${props => props.theme.black};
  }
  
`

const ButtonStyled = styled("button")`
  display: flex;
  gap: .7rem;
  justify-content: center;
  align-items: center;
  border: 1px;
  width: 100%;
  color: white;
  cursor: pointer;
  padding: .95rem 1.1rem;
  border-radius: .5rem;
  font-size: 1.1rem;
  font-family: Bold, serif;
  background-color: ${props => props.$bgColor ? `${props.theme[props.$bgColor]} !important` : `${props.theme.button}`};
  box-shadow: ${props => props.shadow ? "0 6.17828px 18.5348px 8px rgba(244, 168, 80, 0.3)" : "none"};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
    animation: spin 2.5s linear infinite;
  }

  :disabled {
    cursor: default;
    background-color: ${props => props.theme.darkGray} !important;
  }
  
  ${props => props.$small && `
    padding: .6rem;
    font-size: .9rem;
    width: 80%;
    background-color: black;
  `}
`

const SelectStyled = styled(InputStyled)`
  margin-bottom: ${props => props.$noBottomMargin && "0rem !important"};

  i.select {
    right: 0;
    bottom: ${props => props.$isLabel ? "calc(100% - 44%)" : "calc(100% - 18%)"};
  }
  
  i.multi_select {
    bottom: .8rem;
  }
`


const SelectDropDownStyled = styled("div")`
  z-index: 9999;
  right: 0;
  left: 0;
  position: absolute;
  overflow-y: scroll !important;
  height: fit-content;
  max-height: 500px !important;
  border-radius: .3rem;
  background-color: white;
  box-shadow: 1px 2px 4px ${props => props.shadow};
  top: ${props => props.$isLabel ? "5.2rem" : "4rem"};

  ul {
    list-style-type: none;
    
    ${Heading} {
      font-size: 1rem !important;
      text-align: left !important;
    }
    
    li {
      padding: .8rem 1rem;
      cursor: pointer;
      
      :hover {
        background-color: ${props => props.theme.disabled};
      }
    }
    
    li.disabled {
      :hover {
        cursor: initial;
        background-color: transparent;
      }
    }
    
    li.with_check_box {
      gap: .2rem;
      display: flex;
      align-items: center;
      
      input {
        width: 1rem;
      }
    }
  }
`

const FileInputStyled = styled("div")`
  cursor: pointer;
  display: flex;
  position: relative;
  margin-top: 1.8rem;
  flex-direction: column;
  justify-content: center;
  
  img.preview {
    margin-top: 1rem;
    max-width: 250px;
  }

  input {
    position: absolute;
    z-index: -999;
    //visibility: hidden;
    border: none;
    padding: .1rem;
  }
  
  .browse_box {
    cursor: pointer;
    padding: .8rem 1rem;
    border-radius: .3rem;
    border: 1px dashed ${props => props.theme.menuGray};
  }
  
  ${Heading}.browse {
    font-size: 1.1rem !important;
  }

  ${Heading}.secondary {
    font-size: 1.1rem !important;
  }
`

const PaymentMethodStyled = styled("div")`
  gap: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0 1.2rem;
  justify-content: space-between;
  
  input {
    height: 1px;
    border: none;
    margin: -1rem 0;
    position: absolute;
    color: transparent;
    
    :focus {
      outline: none;
    }
  }
`

const PaymentMethodOptionStyled = styled("div")`
  display: flex;
  padding: .5rem 0;
  cursor: pointer;
  position: relative;
  width: 45%;
  gap: 1rem;
  flex-direction: column;
  height: 70px;
  border-radius: .4rem;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.shadow};
  background-color: white;
  
  ${props => props.$active && `
    background-color: ${props.theme.shadow};
  `}
  
  .new_flag {
    position: absolute;
    right: .3rem;
    top: .2rem;
  }
`

const AddressInputStyled = styled(InputStyled)`
  ${SelectDropDownStyled} {
    top: ${props => props.$isLabel ? "4.7rem" : "5.rem"};
  }
`

const SplitOrPartialPaymentStyled = styled("div")`
  > div {
    margin-bottom: 1rem;
  }
  
  ${CheckBoxStyled} {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  
  ${InputStyled} {
    //width: 55% !important;
    input {
      border-radius: .4rem;
      padding: .8rem .8rem .6rem;
    }
  }

  .summary {
    margin-top: 1.5rem;
  }
`

const CustomSellingPriceStyled = styled("div")`
  margin-bottom: 1rem;
    ${CheckBoxStyled} {
      margin-bottom: .5rem;
    }
    
    ${InputStyled} {
      margin-bottom: 0;
    }
`

const ExtraFieldsStyled = styled("div")`
  width: 100%;
  
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  
    ${InputStyled} {
      width: 47%;
    }
  
    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      flex-direction: column;
    
      ${InputStyled} {
        width: 100%;
      }
    }
  }
`

export const TextEditorStyled = styled("div")`
  width: 100%;
`

export {
  InputStyled,
  ButtonStyled,
  PhoneInputStyled,
  ExtraFieldsStyled,
  SelectStyled,
  CheckBoxStyled,
  FileInputStyled,
  AddressInputStyled,
  PaymentMethodStyled,
  SelectDropDownStyled,
  CustomSellingPriceStyled,
  PaymentMethodOptionStyled,
  SplitOrPartialPaymentStyled
}
import {useMutation, useQuery, useQueryClient} from "react-query";
import useGlobalContext from "../../useContexts/useGlobalContext";
import {
  calculateSubscriptionPackages,
  getAllSubscriptionPackages, getSubscriptionLogService,
  subscribeForPackageService,
  updateSubscriptionService
} from "../../../services/subscriptionServices";
import errorHandler from "../../../utils/errorHandler";
import queryKeys from "../queryKeys";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";


export const subscriptionLogKeys = {
  subscriptionLog: (query = {}) => ["subscription_logs", getAllObjectKeysWithValues(query)]
}

const useSubscriptionPackagesQuery = () => {
  return useQuery({
    queryKey: "packages",
    queryFn: getAllSubscriptionPackages
  })
}

const useSubscriptionLogsQuery = (query = {}) => {
  return useQuery({
    queryKey: subscriptionLogKeys.subscriptionLog(query),
    queryFn: () => getSubscriptionLogService(query)
  })
}

const useUpdateSubscriptionMutation = ({ successFn }) => {
  const { toast } = useGlobalContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: data => {
      return updateSubscriptionService(data)
    },

    onSuccess: () => {
      successFn()
      toast.confirm("Subscription Updated!!!")
      queryClient.invalidateQueries({ queryKey: queryKeys.USER_PROFILE })
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}


const useSubPackageCalculation = ({ successFn }) => {
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: (body) => {
      return calculateSubscriptionPackages(body)
    },

    onSuccess: res => {
      successFn(res)
      toast.success("Summary calculated", "calculation")
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

const useSubscribeForPackageMutation = ({ successFn }) => {
  const { toast } = useGlobalContext()

  return useMutation({
    mutationFn: (body) => {
      return subscribeForPackageService(body)
    },
    
    onSuccess: () => {
      successFn()
      toast.success("Subscription updated", "subscription")
      window.location.reload()
    },

    onError: err => {
      const formatError = errorHandler(err)
      toast.error(formatError, formatError)
    }
  })
}

export { useUpdateSubscriptionMutation, useSubscriptionPackagesQuery, useSubPackageCalculation, useSubscribeForPackageMutation, useSubscriptionLogsQuery }

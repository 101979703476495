import {CloseSquare} from "iconsax-react";
import {Button, Form, Input} from "../../../index";
import {Heading} from "../../../../styles/textStyles";
import {FlexStyled} from "../../../../styles/utilStyles";
import {tableFormDefaultValues} from "../../../../data/defaultFormValues";
import {CreateCategoryModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";


const CreateTableModal = ({ onClose, handleSubmit, mutation }) => {
	return (
		<ModalLayoutStyled onClick={onClose}>
			<CreateCategoryModalStyled onClick={e => e.stopPropagation()}>
				<CloseSquare size={28} onClick={onClose} />
				<Heading size={1.3} color="black">Create Table</Heading>
				<Form values={tableFormDefaultValues} onSubmit={handleSubmit}>
					<Input required label="Table Name" placeholder="Name of table" name="name"  />
					<Input required type="number" formatNumber label="Capacity" placeholder="How many people can sit on this table" name="capacity" />

					<FlexStyled>
						<Button isLoading={mutation.isLoading} text="Save" type="submit" />
					</FlexStyled>
				</Form>
			</CreateCategoryModalStyled>
		</ModalLayoutStyled>
	)
}

export default CreateTableModal
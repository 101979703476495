import {BiError} from "react-icons/bi";
import {Button} from "../../index";
import {Heading} from "../../../styles/textStyles";
import {FlexColumnStyled, ScreenLoadingStyled} from "../../../styles/utilStyles";
import {Fragment} from "react";
import TableSkeleton from "../../Skeleton/TableSkeleton";


const ScreenLoading = ({refetchFn, isLoading}) => {
	const refetchQuery = () => {
		if (refetchFn) {
			refetchFn()
			return
		}
		
		window.location.reload()
	}
	
	return (
		<ScreenLoadingStyled>
			{!isLoading && (
				<FlexColumnStyled>
					<BiError size={120}/>
					<Heading size={1.5} color="black">Something went wrong, please try again</Heading>
					
					<Button text="Retry" onClick={refetchQuery}/>
				</FlexColumnStyled>
			)}
			
			{isLoading && (
				<Fragment>
					<TableSkeleton count={2} height={60} width="100%"/>
					<TableSkeleton count={2} height={60} width="100%"/>
				</Fragment>
			)}
		</ScreenLoadingStyled>
	)
}


export default ScreenLoading
import {SpaceBetweenStyled, TabStyled, FlexStyled} from "../../styles/utilStyles";
import {Heading} from "../../styles/textStyles";
import {FlexRowStyled} from "../../styles/cardStyles";
import {Button} from "../index";


const Tab = (
	{
		tabs = [], activeTab, setActiveTab, actionButtonText,
		actionButtonOnClick, actionButtonText2, actionButtonOnClick2 , actionButtonPermission1, actionButtonPermission2
	}
) => {

	const handleSwitchTab = (tab) => {
		setActiveTab(tab)
	}

	return (
		<TabStyled $gap={2}>
			<SpaceBetweenStyled>
				<FlexRowStyled $gap={2}>
					{tabs.map(tab => (
						<Heading
							key={tab}
							size={1.2}
							color="black"
							onClick={() => handleSwitchTab(tab)}
							className={activeTab === tab && "active"}
						>{tab}</Heading>
					))}
				</FlexRowStyled>
				
				<FlexStyled>
					{actionButtonText && (
						<Button text={actionButtonText} onClick={actionButtonOnClick} permissionCode={actionButtonPermission1} />
					)}
					
					{actionButtonText2 && (
						<Button text={actionButtonText2} onClick={actionButtonOnClick2} permissionCode={actionButtonPermission2} />
					)}
				</FlexStyled>
			</SpaceBetweenStyled>
		</TabStyled>
	)
}


export default Tab
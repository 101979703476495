import {useState} from "react";

const useBreadCrumbTrail = (defaultValues = []) => {
  const [trail, setTrail] = useState([...defaultValues])
  
  const addToTrail = (item) => {
    const isTrailExist = trail.find(trail => trail.id === item.id)
    
    if(!isTrailExist) {
      const newTrail = [...trail, item]
      setTrail(newTrail)
      return true
    }
  }
  
  const goToTrail = (itemId) => {
    const newTrail = []
    for (const item of trail) {
      if(item.id === itemId) {
        newTrail.push(item)
        break
      }
      
      newTrail.push(item)
    }
    setTrail(newTrail)
    return newTrail
  }
  
  const goBack = () => {
    const newTrail = [...trail]
    newTrail.pop()
    setTrail(newTrail)
  }
  
  return { trail, addToTrail, goBack, goToTrail, length: trail.length }
}


export default useBreadCrumbTrail
import {Logo} from "../../../assets/images";
import {Button, CheckBox, Form, Input, PasswordInput} from "../../../components";
import {Link, useOutletContext} from "react-router-dom";
import {Heading2, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {isPasswordRequirementsValid, isStringLengthValid} from "../../../utils/validator";


const EmailAndPassword = () => {
  const { toast, globalState: { packageId, packagePeriod, referralCode } } = useGlobalContext()
  const { setRegisterData, navigate, formValues, registerMutation } = useOutletContext()


  const handleSubmit = (values) => {
    if(!isStringLengthValid(values.password, 8)) {
      toast.error("Password length must be at least 8 characters", values.password)
      return
    }

    if(!isPasswordRequirementsValid(values.password)) {
      toast.error("Password must contain at least an uppercase and lowercase letter, number and a symbol", values.password)
      return
    }

    setRegisterData(prevState => ({
      ...prevState,
      ...values
    }))

    registerMutation.reset()
  
    let url = `/register/${packageId}/${packagePeriod}/person_info`
  
    if(values?.referralCode) {
      url = `${url}?referral_code=${values?.referralCode}`
    }
  
    navigate(url)
  }

  const CheckboxLabel = () => (
    <Paragraph>
      I agree to the <Link to="#">Terms & Conditions</Link>
    </Paragraph>
  )

  return (
    <section className="email_and_password">
      <div className="email_and_password_div">
        <img src={Logo} alt="Ovaloop logo" />
        <div>
          <Heading2 color="black">Get Started With Ovaloop</Heading2>
          <Form values={{ ...formValues, ...(!!referralCode && { referralCode }) }} enableReinitialize onSubmit={handleSubmit}>
            <Input type="email" required label="Email" placeholder="Email address" name="email" />
            <PasswordInput
              required
              name="password"
              label="Password"
              placeholder="Password"
            />
            
            <Input optional label="Referral Code" placeholder="Enter referral code (if any)" name="referralCode" />
  
            <FlexStyled>
              <CheckBox required name="agreeToTermsAndConditions" labelProp={<CheckboxLabel />} />
            </FlexStyled>
            <Button type="submit" text="Continue" shadow />
          </Form>
        </div>

        <Paragraph color="gray" bold size={.9}>
          Already have an account? <Link to="/login">Log in</Link>
        </Paragraph>
      </div>
    </section>
  )
}

export default EmailAndPassword
import axios from "../../axios";
import handleQueryParams from "../../utils/handleQueryParams";


const getWipAnalyticsService = async (queryParam) => {
	try {
		const url = handleQueryParams("/wip_analytics/", queryParam)
		
		const { data } = await axios.get(url)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const getWipByStaffIdService = async (staffId, searchValue = " ") => {
	try {
		const { data } = await axios.get(`/wip/?staff_id=${staffId}&search=${searchValue}`)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const assignStaffToWipService = async (stageId, body) => {
	try {
		const { data } = await axios.patch(`/wip/${stageId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}

const completeWipService = async (stageId, body) => {
	try {
		const { data } = await axios.patch(`/wip/${stageId}/`, body)
		return data
	} catch (e) {
		throw e?.response?.data || ""
	}
}


export { getWipAnalyticsService, getWipByStaffIdService, assignStaffToWipService, completeWipService }
import styled from "styled-components";
import {Heading, Heading2, Paragraph} from "./textStyles";
import {ButtonStyled, InputStyled} from "./formStyles";
import {AllCenterStyled, FlexStyled, LineStyled, SpaceBetweenStyled} from "./utilStyles";
import {TableComponentStyled, TablePaginationStyled} from "./TableStyles";
import {OverviewCardStyled} from "./cardStyles";
import {SetUpLayoutStyled} from "../routes/dashboard/setUp/SetUpStyles";


const DashboardLayoutStyled = styled("div")`
  min-height: 100vh;
  background-color: ${props => props.theme.background};

  .upgrade_plan {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;
    width: 100%;
    ${Heading} {
      text-decoration: underline;
      padding: .7rem 2rem;
      background: ${props => props.theme.black};
    }
  }

  .package_renewal_warning {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;
    width: 100%;
    ${Heading} {
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.error};
    }
  }

  .email_verification_warning {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;
    width: 100%;
    ${Heading} {
      width: 100%;
      text-align: center;
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.warning};
    }
  }

  .download_desktop {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;
    width: 100%;

    ${Heading} {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.primary};
    }
  }

  .update_desktop {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;

    ${Heading} {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.gray};
    }
  }

  .update_desktop_sync_data {
    display: flex;
    margin-bottom: .5rem;
    justify-content: center;

    ${Heading} {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer !important;
      padding: .7rem 2rem;
      background: ${props => props.theme.primary};
    }
  }

  .side_navigation {
    top: 0;
    left: 0;
    bottom: 0;
    width: 20vw;
    position: fixed;
    box-shadow: 2px 2px 3px ${props => props.theme.shadow};
    background: ${props => props.theme.backgroundSecondary};

    @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
      width: 20vw;
    }

    .img_div {
      padding: 1rem 2rem 0;
    }

    img {
      max-width: 100%;
    }

    .nav_menu_list {
      height: calc(100vh - 6rem);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow-y: scroll;
    }

    .nav_menu_settings {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
    }
  }

  .top_navigation {
    display: flex;
    gap: 3rem;
    position: fixed;
    z-index: 9999;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 20.5vw;
    right: 1.5vw;
    height: 8vh;
    padding: .3rem 1rem .3rem 3rem;
    background-color: ${props => props.theme.backgroundSecondary};

    @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
      left: 21.5vw;
    }

    ${Heading2} {
      width: 50%;
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    ${InputStyled} {
      margin-bottom: .7rem !important;
      input {
        padding: .6rem;
        border: none !important;
      }
    }

    .navigation_control {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        padding: .4rem;
        border-radius: 50%;
        background-color: ${props => props.theme.darkGray};
      }
    }
  }

  main {
    padding-top: 17vh;
    margin-left: 21vw;
    min-height: 80vh;
    padding-bottom: 1.5rem;
    background-color: ${props => props.theme.background};

    @media screen and (min-width: ${props => props.theme.largeScreenByWidth}) {
      margin-left: 21.5vw;
    }

    @media screen and (min-width: ${props => props.theme.tabletBreakPoint}) {
      ${SetUpLayoutStyled} + ${TablePaginationStyled} {
        display: none;
      }
    }

    > div {
      width: calc(100% - 1.5vw);
    }

    @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
      padding: 6.3rem 1rem 1rem;
      margin: 0;

      > div {
        width: 100%;
      }
    }
  }


  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    .side_navigation, .top_navigation {
      display: none;
    }
  }
`

const GetStartedStyled = styled(AllCenterStyled)`
  min-height: ${props => props.noData ? "70vh" : "83%"};
  flex-direction: column;

  > ${AllCenterStyled} {
    gap: 1rem;
    text-align: center;
    flex-direction: column;
    width: ${props => props.noData ? "40%" : "35%"};

    ${Heading2} {
      margin-top: -1rem;
      margin-bottom: 0 !important;
    }

    ${ButtonStyled}, ${Paragraph} {
      width: 75%;
    }
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    > ${AllCenterStyled} {
      margin-top: 10%;
      width: 90%;
    }
  }
`

const DashboardStyled = styled("div")`
  position: relative;

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {

    ${SpaceBetweenStyled}:has(${OverviewCardStyled}) {
      display: -webkit-box;
      gap: 1rem;
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
    }

    ${TablePaginationStyled} {
      display: none !important;
    }
  }
`

const SettingsStyled = styled("div")`
  padding: 1.5rem;
  border-radius: .4rem;
  background-color: white;
  width: calc(100% - 3rem) !important;

  > ${FlexStyled}, > ${LineStyled} {
    margin-bottom: 1.7rem;
  }
`


const ContainerStyled = styled("div")`
  padding: ${props => props.padding && "1.5rem"};
  background: ${props => props.theme[props.bg] || "transparent" };

  > ${TablePaginationStyled} {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.tabletBreakPoint}) {
    > ${TablePaginationStyled} {
      display: flex;
    }

    ${TableComponentStyled} + ${TablePaginationStyled} {
      display: none !important;
    }
  }

  ${ButtonStyled}#submit_checkout {
    display: none;
  }
`

export {
  ContainerStyled,
  DashboardStyled,
  SettingsStyled,
  DashboardLayoutStyled,
  GetStartedStyled
}
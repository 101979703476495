import {FlexColumnStyled, FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {
  CustomerDetailsStyled,
  CustomerInformationStyled,
  SingleCustomerStyled
} from "../../../../styles/singleCustomerStyles";
import {
  Chips,
  CustomerAndStaffReviewCard,
  CustomerModal,
  Line,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
  TablePagination,
  TableItemWithStatus,
  Tab,
  CustomerWalletDrawer,
  DebitWalletModal,
  CreditCard,
  ViewCustomerWalletLogModal,
} from "../../../../components";
import {Fragment, useState} from "react";
import {ArrowLeft} from "iconsax-react";
import {useModal} from "../../../../hooks";
import {SingleOrderHeaderStyled} from "../../../../styles/singleOrderStyles";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {useTheme} from "styled-components";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {
  useCustomerByIdQuery,
  useCustomerOrdersByIdQuery,
  useCustomerWalletLogByIdQuery, useUpdateCustomerWalletMutation
} from "../../../../hooks/useServices/useCustomerServices";
import {useCustomerDashboardAnalyticsByIdService} from "../../../../hooks/useServices/useAnalyticsServices";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {topNData} from "../../../../utils";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import {
  getCustomerServiceStructure, getCustomerWalletLogStructure,
  handleMainAddress
} from "../../../../services/customerServices/customerServiceStructure";
import {
  useCustomerSubscriptionByIdQuery
} from "../../../../hooks/useServices/useCustomerSubscriptionServices";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import customerQueryKey from "../../../../hooks/useServices/useCustomerServices/customQueryKey";
import {useParams} from "react-router-dom";
import DateFilter from "../../../../components/Utils/DateFilter";
import {useUserProfile} from "../../../../hooks/useServices/userHooks";
import {isPremiumOrStarterPackage} from "../../../../utils/manageDisplayOfFeatures";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {useCreateCustomerReservedAccountMutation} from "../../../../hooks/useServices/useOvaloopPaymentServices";
import {isStoreUsingNGN} from "../../../../hooks/useUtils/useNavigationMenuList";
import {useBusinessByIdQuery} from "../../../../hooks/useServices/useStoreServices";
import {useBusinessSubscriptionQuery} from "../../../../hooks/useServices/useBusinessSubscriptionServices";
import queryParamKeys from "../../../../utils/queryParamKeys";


const CustomerDetail = ({ title, value }) => {
  return (
    <CustomerDetailsStyled>
        <Heading color="menuGray">{title}</Heading>
        <Heading color="black" size={1.1}>{value}</Heading>
    </CustomerDetailsStyled>
  )
}

const TABS = ["Orders"]
const TABS_WITH_LOG = ["Orders", "Wallet Log"]


const SingleCustomer = () => {
  const { customerId } = useParams()
  const theme = useTheme()
  const { data: userProfile } = useUserProfile()
  const permission = useActionPermissionFn()
  
  const [activeTab, setActiveTab] = useState(TABS[0])
  
  const { data: businessSubscription } = useBusinessSubscriptionQuery()
  
  const [dateFilterObj, setDateFilterObj] = useState({ from_date: "", to_date: "" })
  const [statusState, setStatusState] = useState("")
  const [isDebitWalletOpen, setIsDebitWalletOpen] = useModal()
  const [isShowCreditWalletModal, setIsShowCreditWalletModal] = useModal()

  const { customerOrderTable } = tableHeadersAndValueKeys
  const { currencyFormatter, numberFormatter, nairaCurrencyFormatter } = useDataTypeFormatter()
  const { data: business } = useBusinessByIdQuery()
  const { data: customer, isLoading: isCustomerQueryLoading, status, refetch } = useCustomerByIdQuery()
  const { navigate } = useGlobalContext()
  const [showModal, setShowModal] = useModal()

  const { debounceState } = useQueryDebounce()
  const { mainQuery: mainCustomerOrderQuery, ...allCustomerOrderQuery } = useSearchQuery(debounceState, useCustomerOrdersByIdQuery.bind(this, statusState, dateFilterObj.from_date, dateFilterObj.to_date))
  
  const {
    isLoading: isCustomerAnalyticsLoading,
    data: customerAnalyticsData,
    refetch: refetchCustomerAnalytics
  } = useCustomerDashboardAnalyticsByIdService(dateFilterObj)

  const chipsText = [
    { name: "All Orders", value: "" },
    { name: "Pending", value: "pending" },
    { name: "Confirmed", value: "confirmed" },
    { name: "Cancelled", value: "cancelled" }
  ]
  
  const updateCustomerWalletMutation = useUpdateCustomerWalletMutation({ successFn: setIsDebitWalletOpen })
  const createCustomerAccountNumberMutation = useCreateCustomerReservedAccountMutation({ successFn: () => {} })
  
  const handleDebitWallet = (values) => {
    const body = {
      ...values,
      id: customerId,
      action: "debit"
    }
    
    updateCustomerWalletMutation.mutate(body)
  }
  
  const handleCreateCustomerAccountNumber = () => {
    if(!createCustomerAccountNumberMutation.isLoading) {
      createCustomerAccountNumberMutation.mutate(Number(customerId))
    }
  }
  
  const refetchQueries = async () => {
    await refetch()
    await refetchCustomerAnalytics()
  }
  
  const customerFullName = `${customer?.first_name || ""} ${customer?.last_name || ""}`
  
  return (
    <div>
      {showModal && <CustomerModal onClose={setShowModal}/> }
      {isShowCreditWalletModal && <CustomerWalletDrawer customer={customer} onClose={setIsShowCreditWalletModal} /> }
      
      {status === "error" && (
        <ScreenLoading refetchFn={refetchQueries} />
      )}
  
      {isDebitWalletOpen && <DebitWalletModal onClose={setIsDebitWalletOpen} handleSubmit={handleDebitWallet} mutation={updateCustomerWalletMutation} />}

        <Fragment>
          <SingleCustomerStyled>
            <SingleOrderHeaderStyled>
              <FlexStyled className="navigation_section">
                <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)} />
                <FlexStyled>
                  <Paragraph noTop color="menuGray">Back to customers</Paragraph>
                  <Heading color="black">
                    {customerFullName}
                  </Heading>
                </FlexStyled>
              </FlexStyled>
              
              <DateFilter placeholder="Filter analytics" setDateFilter={setDateFilterObj} />
            </SingleOrderHeaderStyled>

            <SpaceBetweenStyled className="review_section">
              <CustomerAndStaffReviewCard
                isLoading={isCustomerAnalyticsLoading}
                title="Total Value"
                value={currencyFormatter(customerAnalyticsData?.total_value?.amount || 0)}
                subTitle="Value lasts until next order"
              />
              <CustomerAndStaffReviewCard
                isLoading={isCustomerAnalyticsLoading}
                title="Total Profit"
                value={currencyFormatter(customerAnalyticsData?.total_profit?.amount || 0)}
                subTitle="Profit lasts until next order"
                statusColor="#FF9548"
              />
              <CustomerAndStaffReviewCard
                isLoading={isCustomerAnalyticsLoading}
                title="Total Orders"
                value={numberFormatter(customerAnalyticsData?.total_volume || 0)}
                subTitle="Total orders lasts until next order"
                statusColor="#17E383"
              />
              <CustomerAndStaffReviewCard
                isLoading={isCustomerAnalyticsLoading}
                title="Total Debt"
                value={currencyFormatter(customerAnalyticsData?.total_debt?.amount || 0)}
                subTitle="Debt lasts until next order"
                statusColor="#F24E30"
              />
            </SpaceBetweenStyled>

            <Line />

            <SectionStyled>
              <Heading color="black" size={1.3}>Customer Information</Heading>
              
              <CustomerInformationStyled>
                
                <FlexColumnStyled>
                  {!isCustomerQueryLoading && (
                    (isPremiumOrStarterPackage(userProfile) && isStoreUsingNGN(business)) && (
                      <CreditCard handleCreateCustomerAccount={handleCreateCustomerAccountNumber} name={customer?.account_name} number={customer?.account_number} isCustomerWallet balance={nairaCurrencyFormatter(customer?.account_balance || 0)} />
                    )
                  )}
  
                  {isCustomerQueryLoading && (
                    <TableSkeleton height={170} count={1} />
                  )}
                  
                  <div className="customer_bio_data">
                    {!isCustomerQueryLoading && (
                      <Fragment>
                        <CustomerDetail title="Name" value={`${customer?.first_name} ${customer?.last_name}`} />
                        <CustomerDetail title="Email" value={customer?.email} />
                        <CustomerDetail title="Phone" value={customer?.phone} />
                        <CustomerDetail title="Address" value={handleMainAddress(customer?.address)} />
  
                        {customer?.extra_fields?.map(field => (
                          <CustomerDetail key={field?.id} title={field?.name} value={field?.value} />
                        ))}
                      </Fragment>
                    )}
  
                    {isCustomerQueryLoading && (
                      <Fragment>
                        <TableSkeleton count={4} height={35} />
                      </Fragment>
                    )}
                  </div>
                </FlexColumnStyled>

                <div className="customer_activities">
                  {(isPremiumOrStarterPackage(userProfile) && isStoreUsingNGN(business)) && (
                    <Tab
                      tabs={[...TABS_WITH_LOG, businessSubscription?.length > 0 && "Subscriptions"]}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      actionButtonText="Credit Wallet"
                      actionButtonText2="Debit Wallet"
                      actionButtonOnClick={setIsShowCreditWalletModal}
                      actionButtonOnClick2={setIsDebitWalletOpen}
                      actionButtonPermission1="CUS005"
                      actionButtonPermission2="CUS007"
                    />
                  )}
                  
                  {(isPremiumOrStarterPackage(userProfile) && !isStoreUsingNGN(business)) && (
                    <Tab
                      tabs={[...TABS, businessSubscription?.length > 0 && "Subscriptions"]}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
  
                  {!isPremiumOrStarterPackage(userProfile) && (
                    <Tab
                      tabs={[...TABS, businessSubscription?.length > 0 && "Subscriptions"]}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}

                  {activeTab === "Orders" && (
                    <Fragment>
                      <SpaceBetweenStyled>
                        <FlexStyled>
                          {chipsText.map(item => (
                            <Chips
                              key={item.name}
                              title={item.name}
                              active={item.value === statusState}
                              onClick={() => setStatusState(item.value)}
                            />
                          ))}
                        </FlexStyled>

                        <Chips title="View all" onClick={() => navigate(`/dashboard/orders/?${queryParamKeys.customerID}=${customerId}&${queryParamKeys.customerName}=${customerFullName}`)} />
                      </SpaceBetweenStyled>

                      <Table headers={customerOrderTable.headers} noPadding noCols={customerOrderTable.headers.length}  >
                        {mainCustomerOrderQuery.isSuccess && (
                          <Fragment>
                            {topNData(allCustomerOrderQuery?.data?.results, 50).map((item, k) => (
                              <TableItemForGroupOrders
                                statusPosition={4}
                                key={k}
                                count={k}
                                pageCount={allCustomerOrderQuery.currentCount}
                                data={item}
                                keys={customerOrderTable.values}
                              />
                            ))}
                          </Fragment>
                        )}
  
                        {allCustomerOrderQuery?.data?.results.length === 0 && (
                          <NoQueryData text={`No Order ${!!statusState ? `for status: "${statusState}"` : ""}`} />
                        )}
  
                        {allCustomerOrderQuery.isQueryLoading && (
                          <TableSkeleton />
                        )}
                      </Table>
                    </Fragment>
                  )}

                  {activeTab === "Subscriptions" && <CustomSubscription />}

                  {(isPremiumOrStarterPackage(userProfile) && permission.check("CUS006") && activeTab === "Wallet Log") && (
                    <CustomerWalletLogs />
                  )}

                </div>
              </CustomerInformationStyled>
            </SectionStyled>

          </SingleCustomerStyled>
        </Fragment>
    </div>
  )
}

export default SingleCustomer


const CustomSubscription = () => {
  const { navigate } = useGlobalContext()
  const { customerSubscriptionTable } = tableHeadersAndValueKeys
  const { debounceState } = useQueryDebounce()
  const { mainQuery, ...allCustomerSubscriptions } = useSearchQuery(debounceState, useCustomerSubscriptionByIdQuery)

  const handleViewPurchase = (subscription) => {
    navigate(`/dashboard/purchased_subscriptions/${subscription.id}`)
  }

  return (
    <Fragment>
      <Table bg headers={customerSubscriptionTable.headers} noPadding noCols={customerSubscriptionTable.headers.length} equalWidth>
        {allCustomerSubscriptions.isQueryWithData && (
          allCustomerSubscriptions?.data.results.map((items, k) =>
            <TableItemWithStatus
              key={k}
              data={items}
              count={k}
              statusPosition={2}
              onView={handleViewPurchase}
              pageCount={allCustomerSubscriptions.currentCount}
              keys={customerSubscriptionTable.values}
              noAction
            />
          )
        )}

        {allCustomerSubscriptions.isQueryWithNoData && (
          <NoQueryData text="No customer subscription" />
        )}

        {allCustomerSubscriptions.isQueryLoading && (
          <TableSkeleton />
        )}
      </Table>
      {allCustomerSubscriptions.showPagination &&
        <TablePagination
          query={allCustomerSubscriptions}
          restructureFn={getCustomerServiceStructure}
          queryKey={customerQueryKey.mainList(debounceState.searchValue)}
        />
      }
    </Fragment>
  )
}



const CustomerWalletLogs = () => {
  const { customerId } = useParams()
  const { customerWalletLogTable } = tableHeadersAndValueKeys
  
  const { debounceState } = useQueryDebounce()
  const [transactionType, setTransactionType] = useState("")
  
  const [showLogDetails, setShowLogDetails] = useModal()
  const [walletLog, setWalletLog] = useState({})
  const [{ from_date, to_date }, setDateFilter] = useState({ from_date: "", to_date: "" })
  const { mainQuery, ...allCustomerWalletLogs } = useSearchQuery(debounceState, useCustomerWalletLogByIdQuery.bind(this, { transactionType, from_date, to_date }))
  
  const handleViewPurchase = (walletLog) => {
    setWalletLog(walletLog)
    setShowLogDetails()
  }
  
  const chips = [{ name: "Credit Logs", value: "credit" }, { name: "Debit Logs", value: "debit" }]
  return (
    <Fragment>
      {showLogDetails && <ViewCustomerWalletLogModal formValues={walletLog} onClose={setShowLogDetails} />}
      
      <SpaceBetweenStyled>
        <FlexStyled>
          {chips.map(item => (
            <Chips
              key={item.name}
              title={item.name}
              active={item.value === transactionType}
              onClick={() => setTransactionType(item.value)}
            />
          ))}
        </FlexStyled>
        
        <DateFilter placeholder="Filter logs" setDateFilter={setDateFilter} />
      </SpaceBetweenStyled>
      
      <Table bg headers={customerWalletLogTable.headers} noPadding noCols={customerWalletLogTable.headers.length} equalWidth>
        {allCustomerWalletLogs.isQueryWithData && (
          allCustomerWalletLogs?.data.results.map((items, k) =>
            <TableItemWithStatus
              key={k}
              data={items}
              count={k}
              statusPosition={1}
              onView={handleViewPurchase}
              pageCount={allCustomerWalletLogs.currentCount}
              keys={customerWalletLogTable.values}
            />
          )
        )}
        
        {allCustomerWalletLogs.isQueryWithNoData && (
          <NoQueryData text="No wallet log" />
        )}
        
        {allCustomerWalletLogs.isQueryLoading && (
          <TableSkeleton />
        )}
      </Table>
      
      {allCustomerWalletLogs.showPagination &&
        <TablePagination
          query={allCustomerWalletLogs}
          restructureFn={getCustomerWalletLogStructure}
          queryKey={customerQueryKey.walletLog(customerId, transactionType, from_date, to_date)}
        />
      }
    </Fragment>
  )
}
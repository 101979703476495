import {CloseSquare} from "iconsax-react";
import {Heading} from "../../../../styles/textStyles";
import {Button, Form, Input, StaffRolesPermissionCard} from "../../../index";
import {roleFormDefaultValues} from "../../../../data/defaultFormValues";
import {FlexStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import useStructuredPermissions from "../../../../hooks/useStructuredPermissions";
import MultiSelectInput from "../../../Forms/MultiSelectInput";
import {useCategoriesQuery} from "../../../../hooks/useServices/useCategoryServices";
import {Fragment, useState} from "react";
import {transformActivePermissionsToArray} from "../../../../utils/dataManipulation";


const CreateRoleModal = ({onClose, handleSubmit, mutation}) => {
  const structuredPermissions = useStructuredPermissions()
  
  const [exemptedProducts, setExemptedProducts] = useState([])
  const {data: allCategories, isSuccess: isCategorySuccess} = useCategoriesQuery()
  
  const formatValuesForSubmission = (values) => {
    const {name, permission, ...rest} = values
    const permissionArray = transformActivePermissionsToArray(rest)
    
    handleSubmit({name, permission: permissionArray, exempted_category: exemptedProducts})
  }
  
  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={75}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Create Role</Heading>
        <Form values={roleFormDefaultValues} onSubmit={formatValuesForSubmission}>
          <Input label="Role Title" placeholder="Enter role title" className="fullWidth" name="name"/>
    
          {isCategorySuccess && (
            <Fragment>
              <MultiSelectInput
                optional
                className="fullWidth"
                options={allCategories?.results}
                displayName="name"
                setSelected={setExemptedProducts}
                label="Exempted product categories"
                placeholder="Select categories to prevent staff role from selling"
              />
            </Fragment>
          )}
    
          <FlexStyled className="three_cols">
            {(!!structuredPermissions && structuredPermissions.length > 0) &&
              structuredPermissions.map(item => (
                <StaffRolesPermissionCard permission={item} key={item.id}/>
              ))
            }
          </FlexStyled>
    
          <FlexStyled>
            <Button text="Save" type="submit" isLoading={mutation?.isLoading}/>
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}

export default CreateRoleModal
import handleQueryParams from "../../../utils/handleQueryParams";
import axios from "../../../axios";
import {ovaloopTransactionRestructure} from "../../ovaloopPaymentServices/ovaloopPaymentRestrucuture";


export const getAllOvaloopPayTransactionsService = async (queryParams) => {
  const url = handleQueryParams("/fetch_transactions", queryParams)
  
  try {
    const { data } = await axios.get(url)
    return ovaloopTransactionRestructure(data)
  } catch (e) {
    throw e?.response?.data || ""
  }
}


export const rePushOvaloopPayTransactionService = async (body) => {
  try {
    const { data } = await axios.post("/re_push_notification/", body)
    return data
  } catch (e) {
    throw e?.response?.data || ""
  }
}
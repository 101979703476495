const groupAllRequestMaterialByTheSameProducibleProduct = (data) => {
  const groupedData = {};
  
  for (const item of data) {
    const group = item?.producible_product;
    
    if (!groupedData[group]) {
      groupedData[group] = [];
    }
    
    groupedData[group].push(item);
  }
  
  return groupedData
}


export const productionRequestRawMaterialsRestructure = (data) => {
  const results = data.results.map(request_log => {
    const newLog = {
      ...request_log,
      allowed_to_perform_action: false,
      group_request_status: "cancelled",
      number_of_product_requested: request_log.inventory_logs.length,
      performed_by_name: request_log.inventory_logs?.[0]?.performed_by_name,
      requested_products: groupAllRequestMaterialByTheSameProducibleProduct(request_log.inventory_logs)
    }
    
    if(!request_log.inventory_logs || request_log.inventory_logs?.length === 0) return newLog
    
    const isAnInventoryPending = request_log.inventory_logs.find(inventory => inventory.status === "pending")
    if(isAnInventoryPending) {
      newLog.group_request_status = "pending"
      newLog.allowed_to_perform_action = true
    }
    
    const isAllInventoryConfirmed = request_log.inventory_logs.find(inventory => inventory.status !== "confirmed")
    if(!isAllInventoryConfirmed) {
      newLog.group_request_status = "confirmed"
    }
  
    const isAllInventoryRejected = request_log.inventory_logs.find(inventory => inventory.status !== "rejected")
    if(!isAllInventoryRejected) {
      newLog.group_request_status = "rejected"
    }
    
    const isAllInventoryPending = request_log.inventory_logs.filter(inventory => inventory.status !== "pending")
    if((isAllInventoryPending.length === request_log.inventory_logs?.length) && (isAllInventoryConfirmed || isAllInventoryRejected)) {
      newLog.group_request_status = "confirmed"
    }
    
    return newLog
  })
  
  return { ...data, results }
}
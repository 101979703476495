import {useContext, useEffect, useState} from "react";
import {Field, useFormikContext} from "formik";
import {InputStyled} from "../../styles/formStyles";
import {FormProvider} from "./Form";
import {Paragraph} from "../../styles/textStyles";


const DatePicker = ({
	                    name,
	                    label,
	                    optional,
	                    bgDark,
	                    className,
	                    placeholder,
	                    onChange,
	                    isTodayMax,
	                    noBottomMargin,
	                    labelBg,
	                    clearCondition,
	                    datetime
                    }) => {
	const [initialMount, setInitialMount] = useState(true)
	const {values, setFieldValue} = useFormikContext()
	const maxDate = new Date().toLocaleDateString().split("/").reverse().join("-")
	
	const {allFieldsNotRequired} = useContext(FormProvider)
	
	useEffect(() => {
		// do not clear the states when the component mounts the first time
		// This is to avoid the states from clearing when the component mounts with already populated values i.e. Edit Form
		if (!!clearCondition && !initialMount) {
			setFieldValue(name, "")
		}
		
		if (initialMount) {
			setInitialMount(false)
		}
	}, [values[clearCondition]])

	const isRequired = () => {
		return !(allFieldsNotRequired ?? optional);
	}
	

	const handleChange = (e) => {
		const value = e.target.value
		onChange?.(value)
		setFieldValue(name, value)
	}

	return (
		<InputStyled bgDark={bgDark} $labelBg={labelBg} className={className} $noBottomMargin={noBottomMargin}
		             $isLabel={!!label}>
			<label htmlFor={name}>
				{label} {!!optional && "(Optional)"}
			</label>
			
			{!values[name] && (
				<Paragraph className="date_placeholder" color="placeholder">{placeholder}</Paragraph>
			)}
			
			<Field onChange={handleChange} required={isRequired()} type={datetime ? getDateFieldByBrowser() : "date"}
			       max={isTodayMax && maxDate}
			       name={name}
			       placeholder={placeholder}/>
		</InputStyled>
	)
}

export default DatePicker


const getDateFieldByBrowser = () => {
	if (navigator.userAgent.toLowerCase().includes("firefox")) return "date"
	return "datetime-local"
}
import {
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton, PageSummaryCard,
  SearchAndFilter, CustomerModal,
  Table,
  TablePagination, EditCustomerModal, TableItemWithDeleteAndEditAction, ScreenLoading, UploadBulkProductModal
} from "../../../../components";
import {
  useCreateCustomerMutation,
  useCustomerQuery, useDeleteCustomerMutation, useEditCustomerMutation, useUploadBulkCustomerService
} from "../../../../hooks/useServices/useCustomerServices";

import {Fragment, useState} from "react";
import {AddCircle} from "iconsax-react";
import {useModal} from "../../../../hooks";
import {NoCustomerImage} from "../../../../assets/images";
import {SetUpLayoutStyled} from "../../setUp/SetUpStyles";
import {PageSummaryStyled} from "../../../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {actionsPermissions} from "../../../../data/permissions";
import {customerFormDefaultValues} from "../../../../data/defaultFormValues";
import customerQueryKey from "../../../../hooks/useServices/useCustomerServices/customQueryKey";
import {getCustomerServiceStructure} from "../../../../services/customerServices/customerServiceStructure";
import {useQueryClient} from "react-query";
import {desktopQueryKeys} from "../../../../hooks/useDesktop/useFetchAllDesktopDataFromServer/useDesktopServices";
import sendDesktopNotification from "../../../../desktop/sendDesktopNotification";


const Customer = () => {
  const queryClient = useQueryClient()
  const { navigate, toast } = useGlobalContext()
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useModal()
  const [showEditCustomerModal, setShowEditCustomerModal] = useModal()
  const [showUploadCustomerModal, setShowUploadCustomerModal] = useModal()

  
  const {
    createCustomer: createCustomerPermission,
    updateCustomer: updateCustomerPermission,
    deleteCustomer: deleteCustomerPermission
  } = actionsPermissions

  const { customerTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const [editCustomerFormValues, setEditCustomerFormValues] = useState(customerFormDefaultValues)
  const { mainQuery, ...allCustomers } = useSearchQuery(debounceState, useCustomerQuery)
  const createCustomerMutation = useCreateCustomerMutation({ successFn: setShowCreateCustomerModal })
  const editCustomerMutation = useEditCustomerMutation({ successFn: setShowEditCustomerModal })
  const deleteCustomerMutation = useDeleteCustomerMutation({ successFn: () => {} })
  const uploadCustomerMutation = useUploadBulkCustomerService({ successFn: setShowUploadCustomerModal })

  const handleCreateCustomer = (values) => {
    createCustomerMutation.mutate(values)
  }

  const handleEditCategory = (values) => {
    editCustomerMutation.mutate(values)
  }

  const handleDeleteAction = (customer) => {
    toast.confirm(
      "Are you sure?",
      () => deleteCustomerMutation.mutate(customer.id),
      deleteCustomerMutation
    )
  }

  const handleEditAction = (customer) => {
    setEditCustomerFormValues(customer)
    setShowEditCustomerModal()
  }

  const handleViewCustomer = (customer) => {
    navigate(`/dashboard/inventory/customer/${customer.id}`)
  }
  
  const handleManualSync = () => {
    queryClient.refetchQueries({ queryKey: desktopQueryKeys.customer }).then(() => {
      sendDesktopNotification({
        title: "Sync successful",
        body: "All customers has been synced offline"
      })
    })
  }
  
  const handleUploadCustomer = (type, values) => {
    uploadCustomerMutation.mutate({ type, values })
  }

  return (
    <div>
      {showCreateCustomerModal && <CustomerModal onClose={setShowCreateCustomerModal} handleSubmit={handleCreateCustomer} mutation={createCustomerMutation} />}
      {showEditCustomerModal && <EditCustomerModal onClose={setShowEditCustomerModal} formValues={editCustomerFormValues} handleSubmit={handleEditCategory} mutation={editCustomerMutation} />}
      {showUploadCustomerModal && <UploadBulkProductModal downloadTemplateLink="https://ovaloopbucket1.s3.amazonaws.com/media/Customer_templates.xlsx" noType={true} file_name="customer_file" modalText="Upload Customers" onClose={setShowUploadCustomerModal} handleSubmit={handleUploadCustomer} mutation={uploadCustomerMutation} />}
  
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allCustomers.refetch} />
      )}
      
      <Fragment>
        {allCustomers.isEmpty && (
          <Fragment>
            <PageHeader title="Customer" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No product"
              imgSrc={NoCustomerImage}
              heading="No customer yet"
              subHeading="Save customer information (Add name, email, phone number, etc.)"
              buttonText="Add new customer"
              buttonClick={setShowCreateCustomerModal}
              button2Text="Upload"
              button2Click={setShowUploadCustomerModal}
              permissionCode={actionsPermissions.createCustomer}
            />
          </Fragment>
        )}

        {!allCustomers.isEmpty && (
          <Fragment>
            <PageHeaderWithButton
              buttonText2="Upload"
              buttonClick2={setShowUploadCustomerModal}
              title="Customer"
              buttonText="Create"
              Icon={AddCircle}
              buttonClick={setShowCreateCustomerModal}
              permissionCode={createCustomerPermission}
            />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allCustomers.isQueryLoading}
                only
                title="Total Number Of Customers"
                value={allCustomers?.data?.count} />
            </PageSummaryStyled>

            <SetUpLayoutStyled>
              <SearchAndFilter
                sync={{ title: "customers", fn: handleManualSync }}
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search customer by name or phone"
              />

              <Table bg headers={customerTable.headers} noCols={customerTable.headers.length}>
                {allCustomers.isQueryWithData && (
                  allCustomers?.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allCustomers.currentCount}
                      keys={customerTable.values}
                      onClick={handleViewCustomer}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={updateCustomerPermission}
                      deletePermission={deleteCustomerPermission}
                    />
                  )
                )}

                {allCustomers.isQueryWithNoData && (
                  <NoQueryData text={allCustomers.isSearchEmptyMessage("Customer", "name, or phone")} />
                )}

                {allCustomers.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
              {allCustomers.showPagination &&
                <TablePagination
                  query={allCustomers}
                  restructureFn={getCustomerServiceStructure}
                  queryKey={customerQueryKey.mainList(debounceState.searchValue)}
                />
              }
            </SetUpLayoutStyled>
            {allCustomers.showPagination &&
              <TablePagination
                query={allCustomers}
                restructureFn={getCustomerServiceStructure}
                queryKey={customerQueryKey.mainList(debounceState.searchValue)}
              />
            }
          </Fragment>
        )}
      </Fragment>
    </div>
  )
}

export default Customer;
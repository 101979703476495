import styled from "styled-components";
import {ButtonStyled, InputStyled, SelectDropDownStyled, SelectStyled} from "./formStyles";
import {FlexColumnStyled, FlexStyled, SpaceBetweenStyled} from "./utilStyles";
import {Heading} from "./textStyles";

const ReportStyled = styled("div")`
  form ${FlexStyled} {
    gap: 1rem .5rem;
    min-width: 100%;
    margin-bottom: .6rem;


    ${SelectStyled}, ${InputStyled} {
      width: calc((100% / 3.9) - .6rem - .8rem - .35rem);

      svg {
        right: .6rem;
        top: .4rem;
      }

      @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
        width: calc(100% - 1rem) !important;
        margin-bottom: 0;
      }
    }
    
    @media screen and (max-width: ${props => props.theme.mobileBreakPoint}) {
      ${SelectStyled}:last-of-type, ${InputStyled}:last-of-type {
        width: 80% !important;
      }
  
      > label > svg {
        top: initial !important;
        bottom: -1rem !important;
      }
    }
  
    ${SelectDropDownStyled} {
      max-height: fit-content;
      overflow-y: hidden;
    }
    
    ${ButtonStyled}#submit_button {
      position: absolute;
      visibility: hidden;
      width: fit-content;
    }

    > label > svg {
      top: 1rem;
      cursor: pointer;
      position: relative;
      padding: .6rem .8rem;
      border-radius: .5rem;
      background: ${props => props.theme.primary};
    }
  }
  
  ${ButtonStyled}.download_report {
    margin-left: 2rem;
    width: fit-content;
    margin-bottom: -1rem;
  }
`

const ReportChartStyled = styled("div")`
  padding: 1rem;
  
  ${SpaceBetweenStyled} > div ${Heading} {
    margin: .7rem 0 1.4rem;
  }
`

const ReportAnalyticsStyled = styled(SpaceBetweenStyled)`
  align-items: flex-start;
  margin: 2rem 0 2rem;
  
  .report_summary {
    width: 48%;
    padding-top: 1rem;
      
      > ${FlexColumnStyled} {
        gap: .7rem;
        margin: 2rem 0 1.7rem;
      }
      
      > ${FlexStyled}.details {
          margin-top: 2rem;
          ${FlexColumnStyled} {
              gap: .7rem;
              width: calc((100% / 3) - 1rem);
          }
      }
  }
  
  ${ReportChartStyled} {
    width: calc(47% - 2rem);
  }
`



export { ReportStyled, ReportChartStyled, ReportAnalyticsStyled }
import {SetUpLayoutStyled, SetUpMainStyled} from "./setUp/SetUpStyles";
import {
  CreateTipModal,
  NoDataLayout, OrderSearchAndFilter, PageHeader,
  PageHeaderWithButton,
  ScreenLoading, Table, TableItemWithStatus, TablePagination
} from "../../components";
import {Fragment} from "react";
import {EmptyBoxImage} from "../../assets/images";
import NoQueryData from "../../components/Utils/NoQueryData";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import tableHeadersAndValueKeys from "../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../hooks/useUtils/useQueryDebounce";
import {
  tipsQueryKey,
  useCancelTipMutation,
  useCreateTipMutation,
  useTipQuery
} from "../../hooks/useServices/useTipsServices";
import {useModal} from "../../hooks";
import {AddCircle} from "iconsax-react";
import {staffAndDateRangeFilter} from "../../data/selectField/orderFilter";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import useOrderQueryWithSearchAndFilter from "../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import {restructureGetTips} from "../../services/tipsServices/restructureTips";
import {actionsPermissions} from "../../data/permissions";
import {useActionPermissionFn} from "../../hooks/usePermissions/useActionPermission";


const Tips = () => {
  const { toast } = useGlobalContext()
  const checkPermission = useActionPermissionFn()
  const { tipsTable } = tableHeadersAndValueKeys
  
  const [isTipOpen, setIsTipOpen] = useModal()
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allTips } = useOrderQueryWithSearchAndFilter(debounceState, useTipQuery)
  
  const createTipMutation = useCreateTipMutation({ successFn: setIsTipOpen })
  const cancelTipMutation = useCancelTipMutation({ successFn: setIsTipOpen })
  
  const handleCreateTip = (formValues) => {
    createTipMutation.mutate(formValues)
  }
  
  const handleCancelStaffTip = (tip) => {
    toast.confirm(
      "Are you sure you want to cancel that tip?",
      () => cancelTipMutation.mutate(tip.id)
    )
  }
  
  return (
    <SetUpMainStyled>
      {isTipOpen && <CreateTipModal onClose={setIsTipOpen} handleSubmit={handleCreateTip} mutation={createTipMutation} />}
      
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allTips.refetch} />
      )}
    
      <Fragment>
        {allTips.isEmpty && (
          <Fragment>
            <PageHeader title="Tips" />
            
            <NoDataLayout
              imgWidth={32}
              imgAlt="No tips"
              imgSrc={EmptyBoxImage}
              heading="No tips yet"
              buttonText="Create Tip"
              buttonClick={setIsTipOpen}
            />
          </Fragment>
        )}
      
        {allTips.showTable && (
          <Fragment>
            <PageHeaderWithButton
              title="Tips"
              buttonText="Create"
              buttonClick={setIsTipOpen}
              Icon={AddCircle}
              permissionCode={actionsPermissions.createTip}
            />
            
            <SetUpLayoutStyled>
              <OrderSearchAndFilter noSearch filterPlaceholder="Filter tips" customFilters={staffAndDateRangeFilter} dispatch={debounceReducer} searchPlaceholder="Search" state={debounceState} />

              <Table headers={tipsTable.headers} noCols={tipsTable.headers.length}>
                {allTips.isQueryWithData && (
                  allTips.data.results.map((item, k) =>
                    <TableItemWithStatus
                      statusPosition={3}
                      noAction
                      key={k}
                      data={item}
                      count={k}
                      isDeleteActive={item.status === "confirmed" && checkPermission.check(actionsPermissions.deleteTip)}
                      deleteAction={handleCancelStaffTip}
                      pageCount={allTips.currentCount}
                      keys={tipsTable.values}
                    />
                  )
                )}
              
                {allTips.isQueryWithNoData && (
                  <NoQueryData text={allTips.emptyMessage("Tips")} />
                )}
              
                {allTips.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>
            
              {!!allTips?.showPagination &&
                <TablePagination
                  query={allTips}
                  queryKey={tipsQueryKey.mainList(allTips.queryValues)}
                  restructureFn={restructureGetTips}
                />
              }
              
            </SetUpLayoutStyled>

            {!!allTips?.showPagination &&
              <TablePagination
                query={allTips}
                queryKey={tipsQueryKey.mainList(allTips.queryValues)}
                restructureFn={restructureGetTips}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Tips
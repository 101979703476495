import {navMenuLists, sellMenuNav, setupNavMenu} from "../../../data/dashboardData";
import {useBusinessSettingQuery} from "../../useServices/useBusinessSettingServices";
import {useUserProfile} from "../../useServices/userHooks";
import {useBusinessByIdQuery} from "../../useServices/useStoreServices";

const useNavigationMenuList = () => {
  const { data: userProfile } = useUserProfile()
  const businessSettings = useBusinessSettingQuery()
  const { data: business } = useBusinessByIdQuery()
  
  const mainDashboardNavMenuList = handleMainDashboardMenu(navMenuLists, businessSettings, userProfile, business)
  const sellDashboardNavMenuList = handleMainDashboardMenu(sellMenuNav, businessSettings, userProfile, business)
  const setupNavMenuList = handleSetupNavMenu(setupNavMenu, businessSettings, userProfile)
  
  return { mainDashboardNavMenuList, sellDashboardNavMenuList, setupNavMenuList }
}


export default useNavigationMenuList


const handleMainDashboardMenu = (navList = [], businessSettings,  userProfile, business) => {
  const packageModules = userProfile?.[0]?.package_details?.modules
  const filtered = []
  
  navList.forEach(item => {
    if(item.title === "Sales With Due Date" && !businessSettings?.due_date) {
      return
    }
  
    if(item.title === "Customer Packages" && !businessSettings?.subscription_services) {
      return
    }
  
    if(item.title === "Bad and Damage" && !businessSettings?.faulty_products) {
      return
    }
  
    if(item.title === "Ovaloop Pay" && !isStoreUsingNGN(business)) {
      return
    }
    
    if(item.title === "Table" && (!businessSettings?.tables || !(packageModules?.includes("TABLE") || packageModules?.includes("TABLES")))) {
      return
    }
  
    if(item.title === "Wip" && (!businessSettings?.wip || !packageModules?.includes("WIP"))) {
      return
    }
  
    if(item.title === "Kitchen Schedule" && (!businessSettings?.tables || !(packageModules?.includes("TABLE") || packageModules?.includes("TABLES")))) {
      return
    }
    
    filtered.push(item)
  })
  
  return filtered
}


const handleSetupNavMenu = (navList, businessSettings, userProfile) => {
  const packageModules = userProfile?.[0]?.package_details?.modules
  const filtered = []
  
  navList.forEach(item => {
    if(item.title === "Customer Packages" && !businessSettings?.subscription_services) {
      return
    }
  
    if(item.title === "Tables" && (!businessSettings?.tables || !(packageModules?.includes("TABLE") || packageModules?.includes("TABLES")))) {
      return
    }
    
    filtered.push(item)
  })
  
  return filtered
}


export const isStoreUsingNGN = (business) => {
  return ((business?.details?.currency_field === "NGN" || business?.details?.currency_field === null) || business?.settings?.ex_currency === "NGN")
}

export const isStoreMainCurrencyNaira = (business) => {
  return (business?.details?.currency_field === "NGN" || business?.details?.currency_field === null)
}
import { ListDropDownStyled } from "../../styles/listStyles";
import {MenuList} from "../index";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import {useUserProfile} from "../../hooks/useServices/userHooks";
// import {onlyShowModulesBasedOnAccountsId, SUBSCRIPTION_MODULES_ACCOUNTS} from "../../utils/manageDisplayOfFeatures";

const ListDropDown = ({ options = [] }) => {
  const { data: userProfile } = useUserProfile()
  const businessSettings = useBusinessSettingQuery()

  const filterOptions = () => {
    const newOptions = []
    options.forEach(item => {
      
      // if(item.title === "Subscriptions" && !onlyShowModulesBasedOnAccountsId(userProfile, SUBSCRIPTION_MODULES_ACCOUNTS)) return

      if(item.title !== "Table") {
        newOptions.push(item)
        return
      }

      if(!!businessSettings?.tables && !!userProfile?.[0]?.package_details.modules?.includes("TABLE")) {
        newOptions.push(item)
      }
    })
    
    return newOptions
  }
  
  return (
    <ListDropDownStyled>
      {filterOptions().map((item, k) => (
        <MenuList item={item} key={k} title={item.title} Icon={item.icon} link={item.link} noBorder />
      ))}
    </ListDropDownStyled>
  )
}

export default ListDropDown
import {
  CreateStagesModal,
  EditStagesModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton, ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../../components";
import {Fragment, useState} from "react";
import {useModal} from "../../../../hooks";
import { AddCircle } from "iconsax-react";
import {
  useCreateStageMutation,
  useDeleteStageMutation,
  useEditStageMutation,
  useStagesByServiceIdQuery
} from "../../../../hooks/useServices/useStagesServices";
import {NoExpensesImage} from "../../../../assets/images";
import {SetUpLayoutStyled, SetUpMainStyled} from "../SetUpStyles";
import {addOnFormDefaultValues} from "../../../../data/defaultFormValues";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import useQueryDebounce from "../../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {formatProductFormForEditing, formatProductFormForSubmission} from "../../../../utils/formatForms/product";
import {useParams} from "react-router-dom";
import generateId from "react-uuid";
import {actionsPermissions} from "../../../../data/permissions";


const ViewAddon = () => {
  const { toast } = useGlobalContext()
  const { serviceName } = useParams()
  const [showCreateStageModal, setShowCreateStageModal] = useModal()
  const [showEditStageModal, setShowEditStageModal] = useModal()

  const { stagesTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const { mainQuery, ...allStages } = useSearchQuery(debounceState, useStagesByServiceIdQuery)
  const [editTaxFormValues, setEditStageFormValues] = useState(addOnFormDefaultValues)
  const deleteStageMutation = useDeleteStageMutation({ successFn: () => {} })
  const editStageMutation = useEditStageMutation({ successFn: setShowEditStageModal })
  const createStageMutation = useCreateStageMutation({ successFn: setShowCreateStageModal })

  const handleCreateStage = (values) => {
    const formattedValues = formatProductFormForSubmission(values, true)
    createStageMutation.mutate(formattedValues)
  }

  const handleEditStage = (values) => {
    const formattedValues = formatProductFormForSubmission(values, true)
    editStageMutation.mutate(formattedValues)
  }

  const handleEditAction = (addOn) => {
    const formattedValues = formatProductFormForEditing(addOn, true)
    setEditStageFormValues(formattedValues)
    setShowEditStageModal()
  }

  const handleDeleteAction = (addOn) => {
    toast.confirm(
      "Are you sure?",
      deleteStageMutation.mutate.bind(this, addOn.id),
      generateId()
    )
  }

  return (
    <SetUpMainStyled>
      {showCreateStageModal && <CreateStagesModal onClose={setShowCreateStageModal} handleSubmit={handleCreateStage} mutation={createStageMutation} />}
      {showEditStageModal && <EditStagesModal onClose={setShowEditStageModal} handleSubmit={handleEditStage} mutation={editStageMutation} formValues={editTaxFormValues} />}
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={mainQuery.refetch} />
      )}
      
      <Fragment>
        {allStages.isEmpty && (
          <Fragment>
            <PageHeader isBack title={`Stages in ${serviceName}`} />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoExpensesImage}
              heading="No stage found"
              buttonText="Add new stage"
              buttonClick={setShowCreateStageModal}
              permissionCode={actionsPermissions.createService}
            />
          </Fragment>
        )}

        {allStages.showTable && (
          <Fragment>
            <PageHeaderWithButton
              buttonClick={setShowCreateStageModal}
              isBack
              title={`Stages in ${serviceName}`}
              buttonText="Create"
              Icon={AddCircle}
              permissionCode={actionsPermissions.createService}
            />

            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search add-on by name"
              />

              <Table headers={stagesTable.headers} noCols={stagesTable.headers.length}>
                {allStages.isQueryWithData && (
                  allStages.data.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allStages.currentCount}
                      keys={stagesTable.values}
                      editAction={handleEditAction}
                      deleteAction={handleDeleteAction}
                      editPermission={actionsPermissions.updateService}
                      deletePermission={actionsPermissions.deleteService}
                    />
                  )
                )}

                {allStages.isQueryWithNoData && (
                  <NoQueryData text={allStages.isSearchEmptyMessage("Stage", "")} />
                )}

                {allStages.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allStages?.data?.next && <TablePagination />}
            </SetUpLayoutStyled>
            {!!allStages?.data?.next && <TablePagination />}
          </Fragment>
        )}

      </Fragment>
    </SetUpMainStyled>
  )
}

export default ViewAddon
import Routes from "./routes/Index"
import useToast from "./hooks/useUtils/useToast";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import GlobalProvider from "./providers/GlobalProvider";
import useGlobalReducer from "./reducers/globalReducer";
import {
  getBusinessIdFromStorage,
  getNotificationCountFromStorage,
  isLargeDesktop,
  isMobile,
  isTablet,
  setNotificationCountFromStorage
} from "./utils";
import GlobalStyles from "./styles/GlobalStyles";
import {Fragment, useEffect, useState} from "react";
import {firebaseDatabase, onValue, ref} from "./utils/firebase/init";
import {useQueryClient} from "react-query";
import {useUserProfile} from "./hooks/useServices/userHooks";
import useDesktopOffline from "./hooks/useDesktop/useUtils/useDesktopOffline";
import DesktopOnlineProvider from "./providers/DesktopOnlineProvider";
import useDesktopOnlineStatusReducer from "./reducers/DesktopOnlineStatusReducer";
import {useIsOfflineLoginAvailable} from "./hooks/useDesktop/useUtils/useIsOfflineLoginAvailable";
import autoSyncOfflineOnFirebaseEventDispatch from "./utils/desktopUtils/firebaseSync";
import {useDesktopBusinessSyncStatusFromLocalDB} from "./hooks/useDesktop/useDesktopServicesFromLocalDB";


function App() {
  // call this hook to get all synced business to prepare the desktop for offline
  useIsOfflineLoginAvailable()
  const {data: businessSyncStatus, isSuccess} = useDesktopBusinessSyncStatusFromLocalDB()
  const [desktopOnlineState, desktopOnlineReducer] = useDesktopOnlineStatusReducer()
  
  useEffect(() => {
    if (!isSuccess) return () => {
    }
    
    autoSyncOfflineOnFirebaseEventDispatch({desktopOnlineReducer, businessSyncStatus})
  }, [isSuccess, businessSyncStatus?.last_sync_date])
  
  return (
    <Fragment>
      <div className="App">
        <GlobalStyles/>
        <ToastContainer autoClose={2500} limit={1}/>
        
        <DesktopOnlineProvider.Provider value={{desktopOnlineState, desktopOnlineReducer}}>
          <GlobalProviderWrapper/>
        </DesktopOnlineProvider.Provider>
      </div>
    </Fragment>
  );
}

export default App;


const GlobalProviderWrapper = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isDesktopOffline = useDesktopOffline()
  const [globalState, globalReducer] = useGlobalReducer()
  
  const [notificationCount, setNotificationCount] = useState({
    actionCount: 0,
    viewCount: 0
  })
  
  const {data: userProfile} = useUserProfile()
  
  const values = {
    toast,
    navigate,
    globalState,
    globalReducer,
    isMobile: isMobile(),
    isTablet: isTablet(),
    isOffline: isDesktopOffline,
    isLargeDesktop: isLargeDesktop(),
    getBusinessId: getBusinessIdFromStorage,
    businessId: getBusinessIdFromStorage()
  }
  
  useEffect(() => {
    if(!isDesktopOffline) {
      const notificationCounter = ref(firebaseDatabase, ('users/' + userProfile?.[0]?.id + '/counter'));
  
      onValue(notificationCounter, (counter) => {
        if (Number(counter._node.value_) !== getNotificationCountFromStorage()) {
          queryClient.refetchQueries({queryKey: "Notifications"})
          // playNotification()
        }
  
        setNotificationCountFromStorage(counter._node.value_)
        setNotificationCount({...notificationCount, viewCount: counter._node.value_})
      });
    }
  }, [userProfile])
  
  return (
    <GlobalProvider.Provider value={{...values, notificationCount}}>
      <Routes/>
    </GlobalProvider.Provider>
  )
}
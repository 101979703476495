import {WipCardStyled} from "../../styles/cardStyles";
import {SpaceBetweenStyled} from "../../styles/utilStyles";
import {Heading, Paragraph} from "../../styles/textStyles";


const WipCard = ({ wip, onClick }) => {
	const { name, pending, completed } = wip

	const generateRandomNumber = () => Number((Math.random() * 3).toFixed())

	return (
		<WipCardStyled $colorIndex={generateRandomNumber()} onClick={onClick.bind(this, wip.id)}>
			<SpaceBetweenStyled>
				<Heading size={1.2} color="black">{name}</Heading>
			</SpaceBetweenStyled>

			<SpaceBetweenStyled>
				<Paragraph bold color="secondary">Pending: {pending}</Paragraph>
				<Paragraph bold color="secondary">Completed: {completed}</Paragraph>
			</SpaceBetweenStyled>
		</WipCardStyled>
	)
}

export default WipCard